import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { withLoading, sites, siteActive, deleteSite } from "../../../../../../utils";
import { Grid, Button, CircularProgress, Menu, MenuItem, Typography, useMediaQuery } from "@material-ui/core";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import { CEllipsisIcon } from "../../../../../Icons/Icons";
import StatusTag from "./../../../../../TagComponents/StatusTag";
import MapContainer from "../../../../../MapContainer/MapContainer";
import EditSiteForm from "./EditSiteForm";
import LightTooltip from "../../../../../Form/LightTooltip";
const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px"
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px"
  },
  fieldColumnOne: {
    width: "90px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  sdCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "25%",
    maxWidth: "300px",
    padding: "10px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingThree: {
    fontSize: "20px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  sdCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
    paddingLeft: "0px"
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  media: {
    height: 500,
    objectFit: "cover"
  }
}));

const Site = () => {
  const bigEnough = useMediaQuery('(min-width: 750px)');
  const classes = useStyles();
  const [_, updateState] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const [siteDetails, setSiteDetails] = useState(null)
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const [eOpen, setEOpen] = useState(false);
  const [aDOpen, setADOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const handleClose = () => {
    setEOpen(false);
    setADOpen(false);
  };
  const handleMenuClose = () => {
    setAnchorEl(false);
  }

  const { siteIdURL } = useParams();

  useEffect(async () => {
    let data = await withLoading(sites, {
      businessId: businessId,
      siteId: siteIdURL,
    }, true)
    if (data && Array.isArray(data.sites) && data.sites.length === 1) {
      setSiteDetails(data.sites[0])
    }
  }, [_]);

  if (siteDetails !== null) {
    return (
      <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
        <React.Fragment>
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            keepMounted

            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => { setEOpen(true); }}>
              <Typography >
                Edit Site
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => { setADOpen(true); }}>
              <Typography >
                {siteDetails.isActive ? "Deactivate Site" : "Activate Site"}
              </Typography>
            </MenuItem>
            {!siteDetails.isActive ?
              <MenuItem onClick={() => { setDeleteOpen(true); setAnchorEl(false) }}>
                <Typography >
                  Delete Site
                </Typography>
              </MenuItem> : null}
          </Menu>
          <EditSiteForm siteDetails={siteDetails} open={eOpen} setOpen={setEOpen} refresh={() => { updateState(_ => !_) }} />
          <GeneralDialog
            open={aDOpen}
            close={handleClose}
            title={siteDetails.isActive ? "Deactivate Site" : "Activate Site"}
            text={siteDetails.isActive ? "Are you sure you want to deactivate this site?" : "Are you sure you want to activate this site?"}
            action={async () => {
              let data = await withLoading(siteActive,
                {
                  businessId: businessId,
                  siteId: siteDetails.siteId,
                  isActive: !siteDetails.isActive
                })
              if (data) {
                handleMenuClose();
                setADOpen(false)
                updateState(_ => !_)
              }

            }}
          />
          <GeneralDialog
            open={deleteOpen}
            close={() => { setDeleteOpen(false) }}
            title="Delete Site"
            text="Are you sure you want to delete this site parmanently?"
            action={async () => {
              dispatch({
                type: 'UPDATE', payload: {
                  loading: true
                }
              });
              let success = await deleteSite({
                businessId: businessId,
                siteId: siteDetails.siteId
              })
              dispatch({
                type: 'UPDATE', payload: {
                  loading: false
                }
              });
              if (success && success.error === false) {
                history.replace(`/dashboard/${businessId}/Sites`);
              }

            }}
          />
        </React.Fragment>
        <Grid item xs={12}>
          <div className={classes.topBlock}>
            <span className={classes.topHeading}>SITE</span>
            {!siteDetails.isDeleted ?
              <Button onClick={(event) => { setAnchorEl(event.target); }} className={[classes.buttonBasic, classes.rightElem].join(" ")}><CEllipsisIcon /></Button> : null}
          </div>
        </Grid>

        <Grid item style={(bigEnough ? { maxWidth: "50%" } : { width: "100%" })}>
          <div className={classes.headerPadding}>
            <LightTooltip title={siteDetails.name} arrow>
              <Typography className={(bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne)} noWrap>
                {siteDetails.name}
              </Typography>
            </LightTooltip>
          </div>
        </Grid>
        {(bigEnough ?
          <Grid item>
            <div className={classes.headerPadding} style={{ paddingLeft: "20px" }}>
              <span className={classes.topHeadingFour}>
                <StatusTag wrapperName={siteDetails.isActive ? "greenWrapper" : "redWrapper"} value={siteDetails.isActive ? "Active" : "Inactive"} />
              </span>
            </div>
          </Grid>
          :
          <Grid item>
            <div style={{ paddingLeft: "10px" }}>
              <StatusTag wrapperName={siteDetails.isActive ? "greenWrapper" : "redWrapper"} value={siteDetails.isActive ? "Active" : "Inactive"} />
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.contentRow}>
                <div className={classes.fieldRows}>
                  <div className={classes.fieldColumnOne}>
                    <Typography noWrap className={classes.labelText} style={(!bigEnough && siteDetails.address2 ? { paddingBottom: 20 } : null)}>Address</Typography>
                  </div>
                  <div className={classes.fieldColumnTwo} style={{ maxWidth: "calc(100% - 80px)" }}>

                    {(bigEnough ?
                      <LightTooltip arrow title={(siteDetails.address2 ? `${siteDetails.address1}, ${siteDetails.address2}` : `${siteDetails.address1}`)}>
                        <Typography className={classes.valueTextBold} noWrap >
                          {((siteDetails.address2 ? `${siteDetails.address1}, ${siteDetails.address2}` : `${siteDetails.address1}`))}
                        </Typography>
                      </LightTooltip>
                      :
                      <React.Fragment>
                        <Typography className={classes.valueTextBold} noWrap>
                          {`${siteDetails.address1}`}
                        </Typography>
                        {(siteDetails.address2 ?
                          <Typography className={classes.valueTextBold} noWrap>
                            {`${siteDetails.address2}`}
                          </Typography>
                          :
                          null)}
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div className={classes.fieldRows}>
                  <div className={classes.fieldColumnOne}>
                    <span className={classes.labelText}>Time Zone</span>
                  </div>
                  <div className={classes.fieldColumnTwo} style={{ maxWidth: "calc(100% - 70px)" }}>
                    <span className={classes.valueText}>
                      {siteDetails.timeZone}
                    </span>
                  </div>
                </div>
                <div className={classes.fieldRows}>
                  <div className={classes.fieldColumnOne}>
                    <span className={classes.labelText}>Public name</span>
                  </div>
                  <div className={classes.fieldColumnTwo} style={{ maxWidth: "calc(100% - 70px)" }}>
                    <span className={classes.valueText}>
                      {(siteDetails.publicName && siteDetails.publicName.length > 0) ? siteDetails.publicName : 'Not provided'}
                    </span>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.media}>
              <MapContainer
                siteDetails={siteDetails}
                options={{
                  disableDefaultUI: true,
                  zoomControl: false,
                  gestureHandling: 'none'
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return (null);
  }
};

export default Site;
