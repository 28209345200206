import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Analytics from './Analytics';


const AnalyticsHandler = () => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const basePath = `/dashboard/${businessId}/Analytics`
    return (
        <Switch>
            <Route exact path = {basePath} render = {()=>(<Analytics businessId = {businessId}/>)}/>
            <Route path = '*' render = {() => {return(<Redirect to='/404'/>)}}/>
        </Switch>
    );
}

export default AnalyticsHandler;