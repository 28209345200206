import React from "react";
import { LinearProgress, CircularProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'absolute',
        zIndex:theme.zIndex.drawer + 3
    },
    circular:{
        position: "fixed",
        left: "50vw",
        top:"50vh",
        zIndex:theme.zIndex.drawer + 3
    }
}));

const Loader = () => {
    const loading = useSelector((state) => state.loading);
    const circular = useSelector((state)=>state.circular)
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {loading? (
                circular
                ?
                <CircularProgress className = {classes.circular}/>
                :
                <LinearProgress />
            ): null}
        </div>
    );
}

export default Loader;