import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { getStaffCalenders, deleteCalendar, deleteTimeOff, getStaffList, withLoading, deleteStaffScheduleLine } from "../../../../../../utils";
import {
  Grid, Button,
  Menu, MenuItem, TableCell,
  TableBody, Table, TableHead,
  TableContainer, TableRow,
  useMediaQuery, Typography, ButtonGroup, Tabs, Tab
} from "@material-ui/core";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import { CAddIcon, CEllipsisIcon} from "../../../../../Icons/Icons"

import ListTable from "../../../../../TableComponents/ListTable";
import TimeOffForm from "./TimeOffForm";
import Assignment from "./Assignment"
import CalenderForm from "./CalendarForm";
import ListCard from "../../../../../MobileCards/ListCard";
import LightTooltip from "../../../../../Form/LightTooltip";

const useStyles = makeStyles((theme) => ({
  cellPadding: {
    padding: "10px",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  cardBasic: {
    backgroundColor: "#ffffff",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px"
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
    height: "100%"
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
    maxWidth: "160px",
    height: "100%"
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none"
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px"
  },
  fieldColumnOne: {
    width: "100px",
    display: "inline-block",

  },
  fieldColumnTwo: {
    display: "inline-block",
    maxWidth: "250px",

  },
  sdCustomCellOne: {
    border: "none",
    width: "5%",
    maxWidth: "200px",
    padding: "0px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingThree: {
    fontSize: "20px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "35%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "60%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
    paddingLeft:"0px"
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  media: {
    height: 200,
    width: 250,
    objectFit: "cover"
  }
}));

const Singlecalendar = (props) => {
  const classes = useStyles();
  const history = useHistory()
  const [_, updateState] = useState(0);
  const [delTO, setDelTO] = useState(false)
  const [editTO, setEditTO] = useState(false)
  const [addTO, setAddTO] = useState(false)
  const [delS, setDelS] = useState(false)
  const [editS, setEditS] = useState(false)
  const [addS, setAddS] = useState(false)
  const [choice, setChoice] = useState(0)
  const [calendarDetails, setCalendarDetails] = useState(null)
  const [staffData, setStaffData] = useState(null)
  const [delC, setDelC] = useState(false)
  const [editC, setEditC] = useState(false)
  const role = useSelector((state) => {
    return state.buisnessDetails.role;
  });
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const { staffIdURL, calendarId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null)
  const matches = useMediaQuery('(min-width: 1140px)');
  const bigEnough = useMediaQuery('(min-width: 750px)')

  const scheduleHeaders = [
    {
      name: "Day",
      columnWidth: "100px",
    },
    {
      name: "Start Time",
      columnWidth: "100px",
    },
    {
      name: "End Time",
      columnWidth: "100px",
    },
    {
      name: "Services",
      columnWidth: "100%",
    },
    {
      name: "Actions",
      columnWidth: "100px",
    },

  ];

  const timeOffHeaders = [
    {
      name: "Start Date",
      columnKey: "startDate",
      columnWidth: "100px",
    },
    {
      name: "End Date",
      columnKey: "endDate",
      columnWidth: "100px",
    },
    {
      name: "Description",
      columnKey: "description",
      columnWidth: "100%",
      tooltip: true
    },
    {
      name: "Actions",
      columnKey: "actions",
      columnWidth: "100px",
      type: "Normal"
    }
  ];

  const redirectToList = async () => {
    history.replace(`/dashboard/${businessId}/Staff-Schedules/${staffIdURL}`)
  }
  const refresh = () => {
    updateState(_ => !_)
  }

  useEffect(async () => {
    setUp()
  }, [_])

  const setUp = async () => {
    let calendarData = await withLoading(getStaffCalenders, {
      businessId: businessId,
      staffId: staffIdURL,
      calendarId: calendarId,
    })
    let staffPayload = await withLoading(getStaffList, {
      businessId: businessId,
      staffId: staffIdURL,
    })

    if (Array.isArray(calendarData) && calendarData.length === 1) {
      setCalendarDetails(calendarData[0])
    }
    if (Array.isArray(staffPayload) && staffPayload.length === 1) {
      setStaffData(staffPayload[0])
    }
  }

  if (calendarDetails !== null && staffData !== null) {

    const days = ["sun", "mon", "tue", "wed", "thr", "fri", "sat"]

    const dayMapping = {
      sun: ["Sunday", []],
      mon: ["Monday", []],
      tue: ["Tuesday", []],
      wed: ["Wednesday", []],
      thr: ["Thursday", []],
      fri: ["Friday", []],
      sat: ["Saturday", []]
    }

    calendarDetails.scheduleInfo.forEach((schedule) => {
      dayMapping[schedule.day][1].push(schedule)
    })

    const rowPlaceholder = [<TableCell />, <TableCell />, <TableCell />, <TableCell />]

    return (
<React.Fragment>
        <Assignment open={addS} setOpen={setAddS} role={role} calendarDetails={calendarDetails} refresh={() => { updateState(_ => !_) }} />
        <Assignment open={editS} setOpen={setEditS} edit role={role} calendarDetails={calendarDetails} refresh={() => { updateState(_ => !_) }} />
        <TimeOffForm open={addTO} setOpen={setAddTO} role = {role} calendarDetails={calendarDetails} refresh={() => { updateState(_ => !_) }} />
        <TimeOffForm open={editTO} setOpen={setEditTO} edit role={role} calendarDetails={calendarDetails} refresh={() => { updateState(_ => !_) }} />
        <CalenderForm staffData={staffData} open={editC} role={role} setOpen={setEditC} edit refresh={() => { updateState(_ => !_) }} />
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={Boolean(anchorEl)}
          onClose={() => { setAnchorEl(false) }}
        >
          <MenuItem onClick={() => { setEditC(calendarDetails); setAnchorEl(false) }}>Edit</MenuItem>
          <MenuItem onClick={() => { setDelC(calendarDetails); setAnchorEl(false) }}>Delete</MenuItem>
        </Menu>
        <GeneralDialog
          open={delC}
          close={() => { setDelC(false) }}
          title={"Delete calendar"}
          text={"Are you sure you want to delete this calendar?"}
          action={async () => {
            let data = await withLoading(deleteCalendar,
              {
                businessId: businessId,
                calendarId: calendarDetails.calendarId
              })
            if (data) {
              redirectToList()
            }
          }}
        />
        <GeneralDialog
          open={delS}
          close={() => { setDelS(false) }}
          title={"Delete assignment"}
          text={"Are you sure you want to delete this assignment?"}
          action={async () => {
            let data = await withLoading(deleteStaffScheduleLine,
              {
                businessId: businessId,
                calendarId: calendarDetails.calendarId,
                scheduleId: delS
              })
            if (data) {
              setDelS(false)
              refresh()
            }
          }}
        />
        <GeneralDialog
          open={delTO}
          close={() => { setDelTO(false) }}
          title={"Delete time off"}
          text={"Are you sure you want to delete time off?"}
          action={async () => {
            let data = await withLoading(deleteTimeOff,
              {
                businessId: businessId,
                calendarId: calendarDetails.calendarId,
                timeOffId: delTO
              })
            if (data) {
              setDelTO(false)
              refresh()
            }
          }}
        />
        <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
          <Grid item xs={12}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>STAFF SCHEDULE DETAILS</span>
              <span className={classes.rightElem}>
                <span className={classes.rightElem}><Button aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => { setAnchorEl(event.target); }} className={classes.buttonBasic}><CEllipsisIcon /></Button></span>
              </span>
            </div>
          </Grid>
          <Grid item style={(bigEnough ? { maxWidth: "50%" } : { width: "100%" })}>
            <div className={classes.headerPadding}>
              <LightTooltip title={calendarDetails.name} arrow>
                <Typography className={(bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne)} noWrap>
                  {calendarDetails.name}
                </Typography>
              </LightTooltip>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <div className={matches ? classes.contentColumnFirst : classes.fieldRows}>
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography className={classes.labelText}>Staff</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>
                  <LightTooltip title={`${staffData.firstName} ${staffData.lastName}`} arrow>
                    <Link to={`/dashboard/${businessId}/Staffs/${staffData.staffId}`} style={{ textDecoration: "none" }}>
                      <Typography className={classes.valueTextLink}>
                        {`${staffData.firstName} ${staffData.lastName}`}
                      </Typography>
                    </Link>
                  </LightTooltip>
                </div>
              </div>
              <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography className={classes.labelText}>Site</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>
                  <LightTooltip title={calendarDetails.siteInfo.name} arrow>
                    <Link to={`/dashboard/${businessId}/Sites/${calendarDetails.siteInfo.siteId}`} style={{ textDecoration: "none" }}>
                      <Typography className={classes.valueTextLink} noWrap style={{ maxWidth: "100px" }}>
                        {calendarDetails.siteInfo.name}
                      </Typography>
                    </Link>
                  </LightTooltip>
                </div>
              </div>
              <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography className={classes.labelText}>Time Zone</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>
                  <Typography className={classes.valueText}>
                    {calendarDetails.siteInfo.timeZone}
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <Grid container alignItems="center" display="flex">
                <Grid item style={{ flex: 1 }}>
                  <Tabs
                    value={choice}
                    onChange={(event, value) => { setChoice(value) }}
                    indicatorColor="primary"
                  >
                    <Tab label="Schedule" />
                    <Tab label="Off Time" />
                  </Tabs>
                </Grid>
                <Grid item className={classes.rightElem}>
                  <Button className={`${classes.buttonBasic}`} onClick={() => { if (choice === 0) { setAddS(true) } else { setAddTO(true) } }}><CAddIcon /></Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            {(bigEnough ?
              <TableContainer style={{ display: (choice === 0 ? null : "none") }}>
                <Table stickyHeader style={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      {scheduleHeaders.map((header, index) => {
                        return (
                          <TableCell
                            align="left"
                            className={classes.cellPadding}
                            key={`${props.identifier}_header_${index}`}
                            style={{ width: `calc(${header.columnWidth})` }}
                          >
                            <Typography noWrap className={classes.valueText}>
                              {header.name.toUpperCase()}
                            </Typography>

                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {days.map(day => {
                      return (
                        <React.Fragment key={day}>
                          {(dayMapping[day][1].length === 0 ?
                            <TableRow key={`${day}-scheduleNone`}>
                              <TableCell >
                                <Typography noWrap className={classes.valueText}>
                                  <b>{dayMapping[day][0]}</b>
                                </Typography>
                              </TableCell>
                              {rowPlaceholder}
                            </TableRow>
                            : null)}
                          {dayMapping[day][1].map((schedule, index) => {
                            return (
                              <TableRow key={`${day}-schedule-${index}`}>
                                {(index === 0 ?
                                  <TableCell rowSpan={dayMapping[day][1].length}>
                                    <Typography noWrap className={classes.valueText}>
                                      <b>{dayMapping[day][0]}</b>
                                    </Typography>
                                  </TableCell>
                                  : null)}
                                <TableCell>
                                  <Typography className={classes.valueText} noWrap>
                                    {schedule.startTimeStr}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography className={classes.valueText} noWrap>
                                    {schedule.endTimeStr}
                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  <Typography className={classes.valueText} noWrap >
                                    <ul style={{ margin: 0, paddingLeft: 20 }}>
                                      {schedule.serviceInfo.map((service) => {
                                        return (
                                          <li key={`${day}-schedule-${index}-service-${service.name}`}>
                                            <Typography noWrap className={classes.valueText}>
                                              {service.name}
                                            </Typography>
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  <ButtonGroup variant="text" size="small">
                                    <Button
                                      style={{ color: "#0079fc", paddingTop: "2px", paddingBottom: "5px", textTransform: "none" }}
                                      onClick={() => { setEditS(schedule) }}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      style={{ color: "red", paddingTop: "2px", paddingBottom: "5px", textTransform: "none" }}
                                      onClick={() => { setDelS(schedule.scheduleId) }}>
                                      Remove
                                    </Button>
                                  </ButtonGroup>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </React.Fragment>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              :
              <div style={{ display: (choice === 0 ? null : "none") }}>
                {
                  days.map((day, index) => {
                    return (
                      dayMapping[day][1].map(
                        (schedule, index) => {
                          return (
                            <div className={classes.cardBasic} style={{ paddingTop: "10px", paddingBottom: "15px" }}>
                              <Grid container spacing={1} alignItems="center">
                                <Grid item xs={6}>
                                  <Typography className={classes.valueTextBold} noWrap>
                                    {dayMapping[day][0]}
                                  </Typography>
                                  <Typography className={classes.valueText} noWrap>
                                    Start: {schedule.startTimeStr}
                                  </Typography>
                                  <Typography className={classes.valueText} noWrap>
                                    End: {schedule.endTimeStr}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <ButtonGroup variant="text" size="small" className={classes.rightElem}>
                                    <Button
                                      style={{ color: "#0079fc", paddingTop: "2px", paddingBottom: "5px", textTransform: "none" }}
                                      onClick={() => { setEditS(schedule) }}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      style={{ color: "red", paddingTop: "2px", paddingBottom: "5px", textTransform: "none" }}
                                      onClick={() => { setDelS(schedule.scheduleId) }}>
                                      Remove
                                    </Button>
                                  </ButtonGroup>
                                </Grid>
                                <Grid item xs={12}>
                                  <LightTooltip arrow title={<ul style={{ margin: 0, paddingLeft: 20 }}>{schedule.serviceInfo.map(service => <li><Typography noWrap>{service.name}</Typography></li>)}</ul>}>
                                    <Typography className={classes.valueText} noWrap>
                                      <b>Services:</b> {schedule.serviceInfo.map(service => service.name).join(", ")}
                                    </Typography>
                                  </LightTooltip>
                                </Grid>
                              </Grid>
                            </div>
                          )
                        }
                      )
                    )
                  }
                  )}
              </div>
            )}
            {(bigEnough
              ?
              <ListTable
                styling={{ display: (choice === 1 ? null : "none") }}
                identifier={"timeOff"}
                tableHeaders={timeOffHeaders}
                tableRows={calendarDetails.timeOffInfo.map((timeOff) => {
                  return ({
                    startDate: timeOff.startDateStr,
                    endDate: timeOff.endDateStr,
                    description: timeOff.description,
                    actions: (
                      <ButtonGroup variant="text" size="small">
                        <Button style={{ color: "#0079fc", paddingTop: "2px", paddingBottom: "5px", textTransform: "none" }} onClick={() => { setEditTO(timeOff) }}>Edit</Button>
                        <Button style={{ color: "red", paddingTop: "2px", paddingBottom: "5px", textTransform: "none" }} onClick={() => { setDelTO(timeOff.timeOffId) }}>Remove</Button>
                      </ButtonGroup>
                    )
                  })
                })}
                rowClick={null}
              />
              :
              <div style={{ display: (choice === 1 ? null : "none") }}>
                {
                  calendarDetails.timeOffInfo.map((timeOff) => {
                    return (
                      <ListCard
                        key={timeOff.timeOffId}
                        action={null}
                        cardName={timeOff.description}
                        statusReplace={
                          <React.Fragment>
                            <Typography className={classes.valueText} noWrap>
                              Start: {timeOff.startDateStr}
                            </Typography>
                            <Typography className={classes.valueText} noWrap>
                              End: {timeOff.endDateStr}
                            </Typography>
                          </React.Fragment>
                        }
                        other={
                          <ButtonGroup variant="text" size="small">
                            <Button style={{ color: "#0079fc", paddingTop: "2px", paddingBottom: "5px", textTransform: "none" }} onClick={() => { setEditTO(timeOff) }}>Edit</Button>
                            <Button style={{ color: "red", paddingTop: "2px", paddingBottom: "5px", textTransform: "none" }} onClick={() => { setDelTO(timeOff.timeOffId) }}>Remove</Button>
                          </ButtonGroup>
                        } />
                    )
                  })}
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </Grid>
      </React.Fragment >
    );
  } else {
    return (
      null
    );
  }
};

export default Singlecalendar;
