import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router'
import { customerSessionDetails, operatingUnits, withLoading } from '../../../../utils'
import Completed from "./Details/Completed"
import Upcoming from './Details/Upcoming'
import Canceled from './Details/Canceled'
import { useDispatch } from 'react-redux'


const isUTCPassed = (utcTime) => {
    const curMoment = new Date()
    const refMomemt = new Date(utcTime)
    return curMoment > refMomemt
  };

const calcType = (sessionDetails) => {
    if(sessionDetails.isCanceled){
        return("Canceled")
    }
    else{
        if(isUTCPassed(sessionDetails.endDateUTC)){
            return("Completed")
        }
        else{
            return("Upcoming")
        }
    }
}

const Schedule = (props)=>{
    const dispatch = useDispatch()
    const [_, updateState] = useState(null)
    const [sessionDetails, setSessionDetails] = useState(null)
    const {sessionIdURL} = useParams()

    const setUp = async () => {
        dispatch({
            type: "UPDATE",
            payload: {
              loading: true,
            },
          });
        let success = await operatingUnits();
        let data = await withLoading(customerSessionDetails, {
            sessionId: sessionIdURL
        }, true)
        if(data && data.sessionDetails){
            setSessionDetails(data.sessionDetails)
        }
        dispatch({
            type: "UPDATE",
            payload: {
              loading: false,
              operatingUnitInfo: success ? success.operatingUnitDetails : null,
            },
          });
    }
    useEffect(() =>{
        setUp();
    },[_])

    const refresh = () => {
        updateState(_=>!_)
    }

    if(sessionDetails){
        const type = calcType(sessionDetails)
        if(type === "Upcoming"){
            return(<Upcoming basePath = {props.basePath} refresh = {refresh} sessionDetails = {sessionDetails}/>)
        }
        else if(type === "Completed"){
            return(<Completed basePath = {props.basePath} refresh = {refresh} sessionDetails = {sessionDetails}/>)
        }
        else if(type === "Canceled"){
            return (<Canceled basePath = {props.basePath} refresh = {refresh} sessionDetails = {sessionDetails}/>)
        }
        else{return null}
    }
    else{
        return null
    }

}

export default Schedule