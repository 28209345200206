import { Container, makeStyles } from "@material-ui/core";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import maxTextArea from "../../../../../../FormValidation/maxTextArea";
import maxTextField from "../../../../../../FormValidation/maxTextField";
import noWhiteSpace from "../../../../../../FormValidation/noWhiteSpace";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import { createCatalog, editCatalog, safePush } from "../../../../../../utils";
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    width: "100vw",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflowY: "auto",
  },
}));

const CatalogForm = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Container className={classes.formContainer}>
        <Form
          moreData={{
            businessId: props.businessId,
            catalogId: props.catalog?.catalogId,
          }}
          title={`${props.catalog ? "Edit" : "Create"} Catalog`}
          cancelEnabled
          cancelAction={() => {
            props.setOpen(false);
          }}
          submitText={props.catalog ? "Edit" : "Create"}
          action={async (payload) => {
            if (props.catalog) {
              return await editCatalog(payload);
            } else {
              return await createCatalog(payload);
            }
          }}
          postAction={(res) => {
            if (res?.error === false) {
              if (props.refetch) {
                props.setOpen(false);
                props.refetch();
              } else {
                safePush(
                  `/dashboard/${props.businessId}/Catalog/${res.catalogId}`,
                  history,
                  location
                );
              }
            }
          }}
          inputFields={[
            {
              type: "TextField",
              name: "name",
              label: "Name",
              validator: [notEmpty, noWhiteSpace, maxTextField],
              initialvalue: props.catalog?.name
                ? props.catalog.name
                : undefined,
            },
            {
              type: "TextArea",
              name: "description",
              label: "Description",
              validator: [notEmpty, noWhiteSpace, maxTextArea],
              initialvalue: props.catalog?.description
                ? props.catalog.description
                : undefined,
            },
          ]}
        />
      </Container>
    </CModal>
  );
};

export default CatalogForm;
