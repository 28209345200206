import React, { useState, useEffect } from 'react';
import Form from "../../../../../Form/Form";
import CModal from "../../../../../Form/CModal";
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getDiscountList, addPricingDiscount } from '../../../../../../utils';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px",
    width: "100vw",
    maxHeight: "80vh",
    overflowY: "auto"

  }
}))


const AddDiscountsForm = (props) => {

  const [allDiscounts, setAllDiscounts] = useState([])
  const dispatch = useDispatch()
  const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });

  const generateChoices = (allOptions) => {
    const temp = []
    const currentChosen = new Set()
    props.optionDetails.discounts.forEach((discount) => {
      currentChosen.add(discount.discountId)
    })
    allOptions.forEach((discount) => {
      if (!currentChosen.has(discount.discountId) && discount.isActive) {
        if (!(props.optionDetails.offeringType === "Private" && props.optionDetails.type === "Unit" && discount.type === "Multi Purchase")) {
          temp.push([discount.name, discount.discountId])
        }
      }
    })
    return (temp)
  }

  const setUp = async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let data = await getDiscountList({
      businessId: businessId
    })
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (data) {
      setAllDiscounts(data)
    }
  }

  useEffect(() => {
    if (props.open) {
      setAllDiscounts([])
      setUp()
    }
  }, [props.open])

  const classes = useStyles()
  const refresh = (success) => {
    if (success) {
      props.setOpen(false)
      props.refresh()
    }
  }
  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Container component="main" className={classes.formContainer}>
        <Form
          moreData={{ businessId: businessId, optionId: props.optionDetails.optionId }}
          title="Add Discounts"
          cancelEnabled
          cancelAction={() => { props.setOpen(false) }}
          submitText="Add"
          action={addPricingDiscount}
          postAction={refresh}
          inputFields={[
            {
              type: "MultiSelect",
              name: "discountIds",
              label: "Discounts",
              choices: generateChoices(allDiscounts)
            }

          ]}
        />
      </Container>
    </CModal>
  )
}

export default AddDiscountsForm