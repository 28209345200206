import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Container, makeStyles } from "@material-ui/core";
import React from "react";
import CModal from "../Form/CModal";
import CModalError from "../Form/CModalError";

const useStyles = makeStyles((theme) => ({
    formContainer:{
      border: '20px',
      backgroundColor: 'white',
      borderRadius: '4px',
      padding:"20px",
      minWidth:"325px",
      maxWidth: "900px"
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
      },
    labelText: {
        fontSize: "16px",
        color: "#697386",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    subHeading: {
        fontSize: "24px",
        color: "#1a1f36",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    buttonBasic: {
        backgroundColor: "#0079fc",
        color: "white",
        fontSize: "14px",
        boxShadow:
          " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
      },
    fieldRows: {
        padding: "10px",
        backgroundColor : "#F7FAFC",
        marginBottom : "5px",
        borderRadius : "3px"
      },
    fieldColumnOne: {
        width: "100%",
        paddingBottom : 5
    },
    fieldColumnTwo: {
        width: "100%",
    },

}));

export const AttendeeInfoModal = (props) => {
    const classes = useStyles();
    return(
        <CModal open={props.open} setOpen={props.setOpen} children ={
            <Container component="main" className={classes.formContainer}>
                <Grid container item xs={12}>
                    <CModalError/>
                    <Grid item xs={12} className={[classes.subHeading, classes.headingWrapper]}>
                        Attendee Information
                    </Grid>
                    <Grid item xs={12}>
                        {props.attendeeQuestions.map((qs) => {
                            return (
                                <div className={classes.contentRow}>
                                    <div className={classes.fieldRows}>
                                        <div className={classes.fieldColumnOne}>
                                            <span className={classes.labelText} style={{whiteSpace:"normal"}}>
                                                {qs.question}  
                                            </span>
                                        </div>
                                        <div className={classes.fieldColumnTwo}>
                                            <span className={classes.valueText}>
                                                {qs.answer}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" className={classes.buttonBasic} style={{float:"right"}} onClick={() => {props.setOpen(false);}}>Close</Button>
                    </Grid>
                </Grid>
            </Container>
        }  />
    );
}