import React, { useState, useEffect } from 'react';
import CModal from '../../Form/CModal'
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import RBLogin from './RBAccountForms/RBLogin';
import RBSignUp from './RBAccountForms/RBSignUp'
import RBForgot from './RBAccountForms/RBForgot'
const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
    width: "100%",
    maxHeight: "80vh",
    overflowY: "auto",
  }
}))

function RBAccount(props) {
  const classes = useStyles()
  const [form, setForm] = useState("login")

  useEffect(() => {
    if (props.open) {
      setForm('login')
    }
  }, [props.open])

  const calcForm = (currFormChoice) => {
    if (currFormChoice === "login") {
      return (<RBLogin setForm={setForm} />)
    }
    else if (currFormChoice === "signUp") {
      return (<RBSignUp setForm = {setForm}/>)
    }
    else {
      return (<RBForgot setForm = {setForm}/>)
    }
  }


  return (
    <CModal
      open={props.open}
      setOpen={props.setOpen}
    >
      <Container className={classes.formContainer}>
        {calcForm(form)}
      </Container>
    </CModal>
  );
}

export default RBAccount;