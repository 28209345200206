import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withLoading, customerProfileDetails } from "../../../../utils";
import { Grid, Button, Menu, MenuItem, Typography, useMediaQuery } from "@material-ui/core";
import { CEllipsisIcon } from "../../../Icons/Icons";
import UpdateProf from "./UpdateProf";
import ChangePassword from "./ChangePassword";
import { useDispatch } from 'react-redux'
import ChangeEmail from "./ChangeEmail";
const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "700px"
    },
    media: {
        height: 200,
        maxWidth: 200,
        objectFit: "cover"
    },
    avatar: {
        height: theme.spacing(20),
        width: theme.spacing(20),
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    topBlock: {
        paddingBottom: "15px",
    },
    recordRow: {
        paddingLeft: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRoght: "5px",
        width: "550px"
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    rightElem: {
        float: "right",
    },
    rightElemPad: {
        float: "right",
        marginRight: "10px",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeadingOne: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    topHeadingTwo: {
        fontSize: "28px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    topHeadingThree: {
        backgroundColor: "#eb5757",
        opacity: "50%",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    topHeadingFour: {
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    buttonBasic: {
        backgroundColor: "#ffffff",
        color: "#1a1f36",
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    contentColumnFirst: {
        paddingRight: "20px",
        boxShadow: "inset -1px 0 #e3e8ee",
        display: "inline-block",
        height: "100%"
    },
    contentColumnMiddle: {
        boxShadow: "inset -1px 0 #e3e8ee",
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "inline-block",
        height: "100%"
    },
    contentColumnLast: {
        paddingLeft: "20px",
        display: "inline-block",
    },
    labelText: {
        fontSize: "14px",
        color: "#697386",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText2: {
        fontSize: "14px",
        color: "#3c4257",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextNeg: {
        fontSize: "14px",
        color: "#009211",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBG: {
        fontSize: "16px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBold: {
        fontSize: "14px",
        color: "#1a1f36",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextPad: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingLeft: "10px",
    },
    valueTextLink: {
        fontSize: "14px",
        color: "#3d5af1",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textDecoration: "none",
        textTransform: "none"
    },
    errorText: {
        fontSize: "14px",
        color: "#ff0000",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    blockLabel: {
        paddingTop: "10px",
        paddingBottom: "5px",
    },
    blockText: {
        paddingLeft: "10px",
    },
    subHeading: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    fieldRows: {
        paddingTop: "8px"
    },
    fieldColumnOne: {
        width: "100px",
        display: "inline-block",

    },
    fieldColumnTwo: {
        display: "inline-block",
        maxWidth: "250px",

    },
    sdCustomCellOne: {
        border: "none",
        width: "5%",
        maxWidth: "200px",
        padding: "0px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingThree: {
        fontSize: "20px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    sdCustomCellTwo: {
        border: "none",
        width: "35%",
        maxWidth: "300px",
        padding: "10px",
    },
    sdCustomCellThree: {
        border: "none",
        width: "60%",
        maxWidth: "200px",
        padding: "10px",
    },
    sdCustomCellFour: {
        border: "none",
        padding: "10px",
    },
    headerPadding: {
        padding: "10px",
        paddingLeft: "0px",
    },
    bottomSpacer: {
        height: "50px",
    },
    topMarginBlock: {
        marginTop: "10px",
    },
    loaderWrapper: {
        width: "100%",
        height: "calc(100vh - 64px)"
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    },
    media: {
        height: 200,
        width: 250,
        objectFit: "cover"
    }
}));

const Profile = () => {
    const classes = useStyles();
    const bigEnough = useMediaQuery('(min-width: 750px)')
    const [_, updateState] = useState(0);
    const [UP, setUP] = useState(false)
    const [CP, setCP] = useState(false)
    const [CE, setCE] = useState(false)
    const [customerDetails, setCustomerDetails] = useState(null)
    const [anchorEl, setAnchorEl] = useState(false);
    const dispatch = useDispatch();

    const setUp = async () => {
        let data = await withLoading(customerProfileDetails, {}, true)
        if (data && data.userProfile) {
            setCustomerDetails(data.userProfile)
            dispatch({
                type: "UPDATE",
                payload: {
                    userDetails: {
                        firstName: data.userProfile.firstName,
                        lastName: data.userProfile.lastName
                    }
                }
            })
        }
    }

    useEffect(() => {
        setUp()
    }, [_])


    if (customerDetails !== null) {
        return (
            <React.Fragment>
                <UpdateProf open={UP} setOpen={setUP} customerDetails={customerDetails} refresh={() => { updateState(_ => !_) }} />
                <ChangePassword open={CP} setOpen={setCP} refresh={() => { updateState(_ => !_) }} />
                <ChangeEmail open={CE} setOpen={setCE} />
                <Menu
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={Boolean(anchorEl)}
                    onClose={() => { setAnchorEl(false) }}
                >
                    <MenuItem onClick={() => { setUP(true); setAnchorEl(false) }}>Update Profile</MenuItem>
                    <MenuItem onClick={() => { setCP(true); setAnchorEl(false) }}>Change Password</MenuItem>
                    <MenuItem onClick={() => { setCE(true); setAnchorEl(false) }}>Change Email</MenuItem>
                </Menu>
                <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>

                    <Grid item xs={12}>
                        <div className={classes.topBlock}>
                            <span className={classes.topHeading}>PROFILE</span>
                            <Button onClick={(event) => { setAnchorEl(event.target); }} className={[classes.buttonBasic, classes.rightElem].join(" ")}><CEllipsisIcon /></Button>
                        </div>
                    </Grid >
                    <Grid item style={(bigEnough ? { maxWidth: "50%" } : { width: "100%" })}>
                        <div className={classes.headerPadding}>
                            <Typography className={(bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne)} noWrap>
                                {`${customerDetails.firstName} ${customerDetails.lastName}`}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.contentRow}>
                            <div className={classes.fieldRows}>
                                <div className={classes.fieldColumnOne} style={{ paddingBottom: "5px" }}>
                                    <Typography noWrap className={classes.labelText}>Account #</Typography>
                                </div>
                                <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
                                    <Typography noWrap className={classes.valueText}>
                                        {customerDetails.accountNumber}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.fieldRows}>
                                <div className={classes.fieldColumnOne} style={{ paddingBottom: "2px" }}>
                                    <Typography noWrap className={classes.labelText}>Email</Typography>
                                </div>
                                <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
                                    <Typography noWrap className={classes.valueText}>
                                        {customerDetails.email}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.fieldRows}>
                                <div className={classes.fieldColumnOne} style={{ paddingBottom: "2px" }}>
                                    <Typography noWrap className={classes.labelText}>Phone Number</Typography>
                                </div>
                                <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
                                    <Typography noWrap className={classes.valueText}>
                                        {`${customerDetails.phoneNumber.slice(0, 2)} (${customerDetails.phoneNumber.slice(2, 5)}) ${customerDetails.phoneNumber.slice(5, 8)}-${customerDetails.phoneNumber.slice(8, 12)}`}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>

            </React.Fragment>
        );
    } else {
        return (null);
    }
};

export default Profile;
