import { makeStyles, Typography, Grid, useMediaQuery } from "@material-ui/core"
import React from "react"
import { Link, useLocation } from "react-router-dom"

import CopyrightIcon from '@material-ui/icons/Copyright';

const useStyles = makeStyles(theme => ({
    change: {
        color:"#FFFFFF",
        "&:hover": {
            fontWeight:"bold"
        }
    },
    root: {
        backgroundColor: theme.palette.accent2.main,
        zIndex: theme.zIndex.drawer + 1,
        position: "relative",
        width: "100%",
        paddingTop: "30px",
        paddingBottom: "12px"
    },
    link: {
        textDecoration: "none",
    },
    icon: {
        height: 30,
        width: 30,
        padding: 5
    }
}))

const Footer = (props) => {
    const classes = useStyles()
    const bigEnough = useMediaQuery("(min-width: 750px)")
    return (
        <div className={classes.root} style={{backgroundColor: "#1A213D"}}>
            <div style={bigEnough ? { padding: 20, paddingTop: 0, maxWidth: 1440, margin: "0 auto", marginTop: -20 } : {paddingBottom: 20, paddingLeft: 8, paddingRight: 8, maxWidth: 1440, margin: "0 auto",  marginTop: -20 }}>
                <Grid container alignItems="center" spacing={1}>
                    <Grid container item xs={bigEnough ? 4: 12} direction={bigEnough ? "column":null} justifyContent= {bigEnough ? "flex-start" : "center"}>
                        <Typography variant={bigEnough ? "h2" : "h4"} style={{color:"#FFFFFF", fontFamily:"'Work Sans', sans-serif", fontWeight:"400"}}>
                            Caritra
                        </Typography>
                        {bigEnough ? <Typography justifyContent= {bigEnough ? "flex-start" : "center"} style={{color:"#FFFFFF", maxWidth:"250px", fontFamily:"'Work Sans', sans-serif", fontWeight:"400"}}>
                            Giving every professional service business a platform to be successful
                        </Typography> : null}
                    </Grid>
                    {bigEnough ? <Grid item xs={2}>
                        <Typography style={{color:"#FFFFFF", paddingBottom:10, fontWeight:"bold"}}>
                            Company
                        </Typography>
                            <Link className={classes.link} to="/aboutUs">
                                <Typography style={{ paddingRight: 10}} className={classes.change} color="primary" variant={bigEnough ? "body1" : "body2"}>
                                    About Us
                                </Typography>
                            </Link>
                            <a href={"mailto:" + "contact@caritra.com"} style={{ textDecoration: "none" }}>
                                <Typography color="primary" className={classes.change} variant={bigEnough ? "body1" : "body2"}>
                                    Contact Us
                                </Typography>
                            </a>
                    </Grid> : <Grid container item xs={12} justifyContent="center">
                            <Link className={classes.link} to="/aboutUs">
                                <Typography style={{ paddingRight: 10, color:"#FFFFFF"}} color="primary" variant={bigEnough ? "body1" : "body2"}>
                                    About Us
                                </Typography>
                            </Link>
                            <a href={"mailto:" + "contact@caritra.com"} style={{ textDecoration: "none" }}>
                                <Typography style={{color:"#FFFFFF"}} color="primary" className={classes.link} variant={bigEnough ? "body1" : "body2"}>
                                    Contact Us
                                </Typography>
                            </a>
                            <a target="_blank" href={`${window.location.origin}/document/cpp`} rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                <Typography color="primary" style={!bigEnough ? { paddingLeft: 5, paddingRight: 10, color:"#FFFFFF"} : { paddingRight: 10, color:"#FFFFFF"}} variant={bigEnough ? "body1" : "body2"}>
                                    Privacy Policy
                                </Typography>
                            </a>
                            <a target="_blank" href={`${window.location.origin}/document/cctou`} rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                <Typography style={{ paddingRight: 10, color:"#FFFFFF"}} color="primary" variant={bigEnough ? "body1" : "body2"}>
                                    Terms of Use
                                </Typography>
                            </a>
                        </Grid>}
                    {bigEnough ? <Grid item xs={2}>
                    <Typography style={{color:"#FFFFFF", paddingBottom:10, fontWeight:"bold"}}>
                            Information
                        </Typography>
                            <a target="_blank" href={`${window.location.origin}/document/cpp`} rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                <Typography color="primary" className={classes.change} variant={bigEnough ? "body1" : "body2"}>
                                    Privacy Policy
                                </Typography>
                            </a>
                            <a target="_blank" href={`${window.location.origin}/document/cctou`} rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                <Typography className={classes.change} color="primary" variant={bigEnough ? "body1" : "body2"}>
                                    Terms of Use
                                </Typography>
                            </a>
                    </Grid> : null}
                    <Grid item xs={bigEnough ? 4 : 12}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <a href="https://www.facebook.com/Caritra-107328640895127/ " target="_blank" rel="noopener noreferrer" style = {bigEnough?{marginTop:10}:undefined}>
                                <img src="https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/social-media/fb-logo.png" alt="fb" className={classes.icon} />
                            </a>
                            <a href="https://www.instagram.com/caritrainc/ " target="_blank" rel="noopener noreferrer" style = {bigEnough?{marginTop:10}:undefined}>
                                <img src="https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/social-media/instagram-logo.png" alt="ig" className={classes.icon} />
                            </a>
                            <a href="https://twitter.com/CaritraInc" target="_blank" rel="noopener noreferrer" style = {bigEnough?{marginTop:10}:undefined}>
                                <img src="https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/social-media/twitter-logo.png" alt="twitter" className={classes.icon} />
                            </a>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CopyrightIcon style={{ paddingRight: 10, color:"#FFFFFF"}} />
                            <Typography display="inline" variant="body2" style={{color:"#FFFFFF"}}>
                                Caritra 2024. All Rights Reserved
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Footer
