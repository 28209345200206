import { Button, Grid, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { listTemplates, withLoading } from '../../../../../../utils';
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import LightTooltip from "../../../../../Form/LightTooltip";
import ListCard from "../../../../../MobileCards/ListCard";
import StatusTag from "../../../../../TagComponents/StatusTag";
import ListTable from "./../../../../../TableComponents/ListTable";
import TemplateForm from './TemplateForm';

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    rightElem: {
        float: "right",
    },
    buttonBasic: {
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    topHeading: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    bottomSpacer: {
        height: "50px",
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    }
}));

const templateHeaders = [
    {
        name: "status",
        columnKey: "status",
        columnWidth: "150px",
        type: "Normal"
    },
    {
        name: "name",
        columnKey: "name",
        columnWidth: "100%",
        tooltip: true
    },
];

const processTemplateData = (templateData) => {
    return templateData.map((template) => {
        let {
            templateId,
            name,
            isActive,
        } = template;

        let status = <StatusTag wrapperName={isActive ? "greenWrapper" : "redWrapper"} value={isActive ? "Active" : "Inactive"} />;
        let id = templateId;

        return {
            id,
            templateId,
            status,
            statusName: isActive ? "Active" : "Not Active",
            name,
            isActive
        };
    });
};

const TemplateList = (props) => {
    const { basePath } = props
    const classes = useStyles();
    const history = useHistory();
    const [add, setAdd] = useState(false);
    const businessId = useSelector((state) => {
        return state.buisnessDetails.businessId;
    });
    const [templates, setTemplates] = useState(null);
    const bigEnough = useMediaQuery("(min-width:750px)")



    const ViewChanger = () => {
        if (bigEnough) {
            return (<React.Fragment>
                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        <ListTable
                            identifier={"template"}
                            tableHeaders={templateHeaders}
                            tableRows={templates}
                            rowClick={navigateToTemplate}
                        />

                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bottomSpacer}></div>
                </Grid>
            </React.Fragment>)
        }
        else {
            return (<Grid item xs={12} style={{ paddingTop: "10px" }}>
                {templates.map((template) => {
                    return (
                        <ListCard
                            action={() => { history.push(`${props.basePath}/${template.id}`); }}
                            cardName={template.name}
                            statusReplace={
                                <Typography
                                    noWrap
                                    style={!template.isActive ? { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "red" } : { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "green" }}>
                                    {template.isActive ? "Active" : "Inactive"}
                                </Typography>
                            } />
                    )
                })}
            </Grid>)
        }

    }

    const getTemplateList = async () => {
        let templateResponse = await listTemplates({
            businessId: businessId,
        });
        if (templateResponse && templateResponse.templateList) {
            setTemplates(processTemplateData(templateResponse.templateList));
        };
    };

    const navigateToTemplate = (templateId) => {
        history.push(`${props.basePath}/${templateId}`);
    };

    useEffect(async () => {
        withLoading(getTemplateList, {}, true)
    }, []);

    if (templates === null) {
        return (null)
    }
    else {
        return (
            <React.Fragment>
                <TemplateForm open={add} setOpen={setAdd} basePath={basePath} />
                <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                    <Grid item xs={12}>
                        <div className={classes.headerPadding}>
                            <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Resource Type</span>
                            <LightTooltip title="Create New Resource Type" arrow>
                                <span className={classes.rightElem}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={{ marginLeft: '10px', textTransform: "none" }}
                                        onClick={() => { setAdd(true); }}
                                    >Add
                                    </Button>
                                </span>
                            </LightTooltip>
                        </div>
                    </Grid>

                    {(Array.isArray(templates) && templates.length > 0 ?
                        <ViewChanger />
                        :
                        <EmptyListImage name="Resource Type" />)}
                </Grid>
            </React.Fragment>
        );
    }
}



export default TemplateList