import React from 'react';
import { useSelector} from 'react-redux';
import Form from '../../../../../Form/Form'
import CModal from '../../../../../Form/CModal'
import { Container } from '@material-ui/core';
import { makeStyles} from '@material-ui/core';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import { editWebConfInfo } from '../../../../../../utils';

const useStyles = makeStyles((theme)=>({
    formContainer:{
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))


const validChoices = (choices) => {
    if(Array.isArray(choices)){
        if(choices.length!==0)
        {
            choices.forEach((choice)=>{
                if(!Array.isArray(choice)||choice.length!==2){
                    return(false)
                }
            });

            return(true)
        }
        else{
            return(true)
        }
    }
    return(false)
}


const WebConfForm = (props) => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()

    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main"  className = {classes.formContainer}>
                <Form
                    moreData = {{
                        businessId:businessId,
                        classId: props.groupClassDetails.classId
                    }}
                    title="Edit Web Conference Info"
                    cancelEnabled
                    cancelAction = {()=>{props.setOpen(false)}}
                    submitText="Edit"
                    action={editWebConfInfo}
                    postAction = {(data)=>{
                        if(data){
                            props.setOpen(false)
                            props.refresh()
                        }
                    }}
                    inputFields={[
                        {
                            type:"TextArea",
                            name:"onlineConferenceInfo",
                            label: "Web Conference Info",
                            validator: noWhiteSpace,
                            resizeEnabled:true,
                            initialvalue:props.groupClassDetails.onlineConferenceInfo,
                            moreProps:{
                                rowsMin: 4
                            }
                        }
                    ]}
                />
            </Container>
        </CModal>
    );
};

export default WebConfForm