import { Button, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { priceGroupList, withLoading } from "../../../../../../utils";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import { CAddIcon } from "../../../../../Icons/Icons";
import ListCard from "../../../../../MobileCards/ListCard";
import ListTable from "./../../../../../TableComponents/ListTable";
import PricingGroupForm from "./PricingGroupForm";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  buttonBasic: {
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
}));

const pricingGroupHeaders = [
  {
    name: "name",
    columnKey: "name",
    columnWidth: "30%",
    tooltip: true,
  },
  {
    name: "description",
    columnKey: "description",
    columnWidth: "70%",
    tooltip: true,
  },
];

const PricingGroupsList = (props) => {
  const mountedRef = useRef(false);
  const bigEnough = useMediaQuery("(min-width:750px)");
  const history = useHistory();
  const classes = useStyles();
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const [pricingGroups, setPricingGroups] = useState(null);
  const [add, setAdd] = useState(false);

  const fetchPricingGroups = async () => {
    const res = await withLoading(priceGroupList, {
      businessId,
    });
    if (
      res.error === false &&
      Array.isArray(res.priceGroups) &&
      mountedRef.current
    ) {
      setPricingGroups(res.priceGroups);
    }
  };

  useEffect(() => {
    mountedRef.current = true;
    fetchPricingGroups();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const navigateToPricingGroup = (priceGroupId) => {
    history.push(`/dashboard/${businessId}/Price-Groups/${priceGroupId}`);
  };

  const ViewChanger = () => {
    if (bigEnough) {
      return (
        <React.Fragment>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <ListTable
                identifier={"pricingGroups"}
                tableHeaders={pricingGroupHeaders}
                tableRows={pricingGroups.map((priceGroup) => ({
                  ...priceGroup,
                  id: priceGroup.priceGroupId,
                }))}
                rowClick={navigateToPricingGroup}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          {pricingGroups.map((pricing) => {
            return (
              <ListCard
                action={() => {
                  history.push(
                    `/dashboard/${businessId}/Price-Groups/${pricing.priceGroupId}`
                  );
                }}
                cardName={pricing.name}
                statusReplace={pricing.description}
                other={pricing.type}
              />
            );
          })}
        </Grid>
      );
    }
  };

  if (pricingGroups !== null) {
    return (
      <React.Fragment>
        <PricingGroupForm open={add} setOpen={setAdd} businessId={businessId} />
        <Grid
          container
          className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
        >
          <Grid item xs={12}>
            <div className={classes.headerPadding}>
              <span
                className={
                  bigEnough ? classes.topHeading : classes.mobileTopHeadingOne
                }
              >
                Price Groups
              </span>
              <span title="Email Customers" className={classes.rightElem}>
                <Button
                  variant="outlined"
                  style={{
                    float: "right",
                    marginLeft: "10px",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setAdd(true);
                  }}
                >
                  <CAddIcon />
                </Button>
              </span>
            </div>
          </Grid>
          {Array.isArray(pricingGroups) && pricingGroups.length > 0 ? (
            <ViewChanger />
          ) : (
            <EmptyListImage name="Price Groups" />
          )}
        </Grid>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default PricingGroupsList;
