import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useMediaQuery } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import LightTooltip from "../Form/LightTooltip"
const useStyles = makeStyles((theme) => ({
  cellPadding: {
    padding: "10px",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",

  },
  rowHover: {
    "&:hover": {
      backgroundColor: "#f7fafc",
      cursor: "pointer",
    },
  },
}));
const ListTable = (props) => {
  const classes = useStyles();



  return (
    <TableContainer style={props.styling ? props.styling : { maxHeight: "calc(100vh - 250px)", width: "100%" }} {...props.moreProps}>
      <Table stickyHeader aria-label="simple table" style={{ tableLayout: 'fixed' }} >
        {props.noHead !== true ?
          <TableHead>
            <TableRow>
              {props.tableHeaders.map((header, index) => {
                return (
                  <TableCell
                    align="left"
                    className={classes.cellPadding}
                    key={`${props.identifier}_header_${index}`}

                    style={{ width: `calc(${header.columnWidth})`, backgroundColor: props.inheritTHeadColor ? "inherit" : undefined }}
                  >
                    <Typography noWrap className={classes.valueText} >
                      {header.name.toUpperCase()}
                    </Typography>

                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          : null}
        <TableBody>
          {props.tableRows.map((row, index) => {
            return (
              <TableRow
                key={`${props.identifier}_row_${index}`}
                onClick={() => {
                  if (props.rowClick && window.getSelection().type === 'Caret') {
                    return props.rowClick(row.id)
                  }
                }}
                className={(props.rowClick ? classes.rowHover : null)}
              >
                {props.tableHeaders.map((header, index) => {
                  let comp = null
                  if (!header.type || header.type === "Typography") {
                    if (header.tooltip) {
                      comp = (


                        <LightTooltip arrow title={row[header.columnKey]}>
                          <Typography className={classes.valueText} {...header.ToTypography} noWrap>
                            {row[header.columnKey]}
                          </Typography>
                        </LightTooltip>


                      )
                    }
                    else {
                      comp = (
                        <Typography className={classes.valueText} {...header.ToTypography} noWrap>
                          {row[header.columnKey]}
                        </Typography>)
                    }
                  }
                  else if (header.type === "Normal") {
                    comp = row[header.columnKey]
                  }
                  let cell = (

                    <TableCell
                      style={{ borderBottom: props.noDivider ? "none" : undefined, width: `calc(${header.columnWidth})` }}
                      align="left"
                      className={classes.cellPadding}
                      key={`${props.identifier}_row_column_${row.id}_${index}`}
                    >
                      {comp}
                    </TableCell>
                  )
                  return cell;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer >
  );
};

export default ListTable;
