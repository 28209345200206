import { useState, useRef, useEffect } from 'react'
import { Button, Collapse, Container, Grid, Typography } from "@material-ui/core"
import { CSVLink } from "react-csv";
import CModal from '../../../../../Form/CModal';
import Form from '../../../../../Form/Form';
import { generateReport, withLoading, getReportSchema } from '../../../../../../utils';
import { convertToDate } from '../../../../../../FormValidation/validDates'
const typeMapping = {
    'Single Select': 'SingleSelect',
    'Multi Select': 'MultiSelect',
    'Precentage': 'Percent',
    'Number': 'Number',
    'Text': 'TextField'
}

const transformField = (schemaField) => {
    const validators = []
    const inputField = {
        name: schemaField.name,
        label: schemaField.label,
        type: typeMapping[schemaField.type]

    }
    if (schemaField.required) {
        validators.push((value) => (value != undefined || value != null ? null : 'Field is required'))
    }
    switch (inputField.type) {
        case ('SingleSelect'):
            inputField.choices = schemaField.options.map(option => [option.label, option.value])
            break;
        case ('MultiSelect'):
            inputField.choices = schemaField.options.map(option => [option.label, option.value])
            break;
        case ('TextField'):
            if (schemaField.limit) {
                validators.push((value) => (value && value.length <= schemaField.limit ? null : `Text must be at most ${schemaField.limit} characters`))
            }
            break;
        case ('Number'):
            if (schemaField.min) {
                validators.push((value) => (value != null && value >= schemaField.min ? null : `Number must be at least ${schemaField.min}`))
            }
            if (schemaField.max) {
                validators.push((value) => (value != null && value <= schemaField.max ? null : `Number must be at most  ${schemaField.max}`))
            }
            break;
        case ('Percent'):
            break;
    }
    return inputField
}

//Credentials is an object where key is a requirment and value  is bool if they have it or not
// Requriments is list of keys that must be true for the given report.
const ReportCard = (props) => {
    const { classes, reportId, name, description, longDescription, businessId } = props
    const [open, setOpen] = useState(false);
    const [downloadedData, setDownloadedData] = useState([]);
    const [downloadedHeaders, setDownloadedHeaders] = useState([]);
    const [showMore, setShowMore] = useState(false)
    const csvLinkEl = useRef();
    const [schema, setSchema] = useState(null)
    const [download, setDownload] = useState(false)
    const setup = async () => {
        const response = await withLoading(getReportSchema, {
            businessId: businessId,
            reportId: reportId
        })
        if (response && !response.error) {
            setSchema(response.schema)
        }
    }

    useEffect(() => {
        setup()
    }, [])

    useEffect(() => {
        if (csvLinkEl?.current?.link && download) {
            csvLinkEl.current.link.click()
            setDownload(false)
        }
    }, [csvLinkEl, download])

    const handleDownload = async (endpoint, payload) => {
        let success = await withLoading(endpoint, payload);
        if (success && !success.error) {
            setDownloadedHeaders(success.reportHeader);
            setDownloadedData(success.report);
            setDownload(true)
            setOpen(false)
        }
    }


    if (schema) {
        return (
            <Grid item xs={12} style={{ maxWidth: '1440px' }}>
                <CSVLink
                    filename={`${name}.csv`}
                    data={downloadedData}
                    headers={downloadedHeaders}
                    ref={csvLinkEl}
                />
                <div className={classes.mainContainer}>
                    <Grid container spacing={2} display='flex' justifyContent='center'>
                        <Grid item style={{ flex: 1 }}>
                            <div className={classes.nameHolder}>
                                <Typography className={classes.name} noWrap>
                                    {name}
                                </Typography>
                            </div>
                            <div className={classes.descriptionHolder}>
                                <Typography className={classes.description}>
                                    {description}
                                </Typography>
                            </div>
                            <div className={classes.showMoreHolder}>

                            </div>
                        </Grid>
                        <Grid item>
                            <div className={classes.cardButtonHolder}>
                                <Button
                                    variant="contained"
                                    color='primary'
                                    onClick={() => { setOpen(true) }}
                                >
                                    <Typography
                                        className={classes.buttonText}
                                        style={{
                                            fontSize: '20px'
                                        }}

                                    >
                                        Download
                                    </Typography>

                                </Button>

                                <CModal
                                    open={open}
                                    setOpen={setOpen}
                                >
                                    <Container className={classes.formContainer}>
                                        <Form
                                            inputFields={[{
                                                name: ['startDate', 'endDate'],
                                                label: ['Start Date', 'End Date'],
                                                type: 'DateRange',
                                                validators: (value) => {
                                                    if (!value[0]) {
                                                        return ['Start date is required', null]
                                                    }
                                                    else if (!value[1]) {
                                                        return [null, 'End date is required']
                                                    }
                                                    else if (convertToDate(value[0]) > convertToDate(value[1])) {
                                                        return ["Start cannot be after end", null]
                                                    }
                                                    else {
                                                        return [null, null]
                                                    }
                                                }
                                            },
                                            ...(schema.map(schemaField => transformField(schemaField)))
                                            ]}
                                            action={async (payload) => { handleDownload(generateReport, { ...payload, businessId: businessId, reportId }) }}
                                            title={`Download ${name}`}
                                            cancelEnabled
                                            cancelAction={() => { setOpen(false) }}
                                            submitText="Download"
                                        />
                                    </Container>
                                </CModal>
                            </div>
                        </Grid>
                        <Grid xs={12}>
                            <Collapse in={showMore}>
                                <Typography>
                                    {longDescription}
                                </Typography>
                            </Collapse>
                        </Grid>
                        <Typography
                            color="primary"
                            className={classes.buttonText}
                            onClick={() => { setShowMore(more => !more) }}
                            style={{ cursor: 'pointer' }}
                        >
                            {showMore ? 'Show Less' : 'Show More'}
                        </Typography>
                    </Grid>
                </div>
            </Grid>
        )
    }
    else {
        return null
    }

}

export default ReportCard
