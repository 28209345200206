import React, { useEffect } from 'react'

import { Container, makeStyles, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CModalError from '../Form/CModalError';
import CTitle from '../Form/CTitle';
import CModal from '../Form/CModal';
import CButtonPair from '../Form/CButtonPair';

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "#1a1f36",
    paddingBottom: 5,
    boxShadow: "inset 0 -1px #e3e8ee",
    fontFamily: "Rubik, sans-serif",
    fontSize: "20px"
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "500px",
    width: "100%",
    maxHeight: "80vh",
    overflowY: "auto",
  }
}))

const GeneralDialog = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.open) {
      dispatch({
        type: "UPDATE",
        payload: {
          insideModal: true
        }
      })
    }

    return (() => {
      dispatch({
        type: "UPDATE",
        payload: {
          insideModal: false
        }
      })
    })
  }, [props.open, dispatch])




  return (
    <CModal
      open={Boolean(props.open)}
      //  setOpen always closes modal
      setOpen={(bool) => { props.close(false) }}
    >
      <Container className={classes.formContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CTitle title={props.title} />
          </Grid>
          <Grid xs={12}>
            <CModalError />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {props.text}
            </Typography>

          </Grid>
          <Grid xs={12}>
            <CButtonPair
              errors={[]}
              notSubmit
              submitText={(props.agreeText ? props.agreeText : "Yes")}
              action={props.action}
              cancelEnabled cancelText={(props.cancelText ? props.cancelText : "No")}
              cancelAction={(bool) => { props.close(false)}}
            />
          </Grid>

        </Grid>
      </Container>
    </CModal>
  );
}

export default GeneralDialog