import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, makeStyles } from "@material-ui/core";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import Form from "../../../../../Form/Form";
import { updatePayoutBank } from "../../../../../../utils";
import { loadStripe } from "@stripe/stripe-js";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import CModalError from "../../../../../Form/CModalError";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
  },
  headerPadding: {
    padding: "10px",
  },
  headingWrapper: {
    paddingBottom: "10px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeadingOne: {
    fontSize: "20px",
    color: "#1a1f36",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const PayoutBankForm = (props) => {
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const classes = useStyles();
  const [num, setNum] = useState(null);
  const [stripe, setStripe] = useState();
  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      let stripeObject = await loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);
      setStripe(stripeObject);
    } catch (error) {
      console.error(error);
    }
  }, []);

  async function handleSubmit(event) {
    let bankAccountStripeToken = null;

    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });

    try {
      bankAccountStripeToken = await stripe.createToken("bank_account", {
        country: props.country,
        currency: props.currency.toLowerCase(),
        account_holder_type: event.accountType,
        routing_number: event.routingNumber,
        account_number: event.accountNumber,
      });
      if (bankAccountStripeToken.error) {
        dispatch({
          type: "UPDATE",
          payload: {
            loading: false,
            error: true,
            errorMessage: bankAccountStripeToken.error.message
          },
        });
      } else {
        let success = null;
        if (bankAccountStripeToken) {
          success = await updatePayoutBank({
            businessId: businessId,
            token: bankAccountStripeToken.token.id,
          });
        }

        if (success) {
          props.success();
        };
      }
    } catch (error) {
      dispatch({
        type: "UPDATE",
        payload: {
          loading: false,
          error: true,
          errorMessage: "Unable to change bank details."
        },
      });
    }
  }

  return (
    <Container component="main" className={classes.formContainer}>
      <Grid item xs={12} className={classes.headingWrapper}>
        <span
          className={classes.topHeadingOne}
          style={{ fontFamily: "Rubik, sans-serif" }}
        >
          {props.preAdd
            ? "Update Payout Bank Details"
            : "Add Bank for Payouts"}
        </span>
      </Grid>
      <Grid container spacing={1} item xs={12} style={{ paddingTop: "10px" }}>
        <Grid item xs={6}>
          <Typography className={classes.valueText}>Country of Bank</Typography>
          <TextField
            variant="outlined"
            multiline={true}
            defaultValue={props.country}
            rowsMin={1}
            rows={1}
            fullWidth={true}
            inputProps={{
              readOnly: true,
              style: {
                marginBottom: -10,
                marginTop: -10,
                fontSize: 14,
                color: "#1a1f36",
                fontFamily: "Rubik, sans-serif",
                whiteSpace: "normal",
              },
            }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.valueText}>Currency</Typography>
          <TextField
            variant="outlined"
            multiline={true}
            defaultValue={props.currency.toUpperCase()}
            rowsMin={1}
            rows={1}
            fullWidth={true}
            inputProps={{
              readOnly: true,
              style: {
                marginBottom: -10,
                marginTop: -10,
                fontSize: 14,
                color: "#1a1f36",
                fontFamily: "Rubik, sans-serif",
                whiteSpace: "normal",
              },
            }}
            readOnly={true}
          />
        </Grid>
      </Grid>
      <Form
        action={handleSubmit}
        postAction={null}
        cancelEnabled={props.cancelEnabled ? true : false}
        cancelAction={() => {
          props.setOpen(false);
        }}
        cancelText="Cancel"
        submitText={props.preAdd ? "Submit" : "Verify"}
        inputFields={[
          {
            type: "RadioSet",
            name: "accountType",
            label: "Account Type",
            initialvalue: "",
            validator: (value) => {
              if (value === null) {
                return "Cannot empty";
              }
            },
            choices: [
              ["Individual", "Individual"],
              ["Corporation", "Company"],
            ],
          },
          {
            type: "TextField",
            validator: [
              notEmpty,
              (value) => {
                setNum(value);
                if (value.length < 10 || value.length > 13) {
                  return "Please enter valid account number";
                }
              },
            ],
            initialvalue: "",
            label: "Account Number",
            name: "accountNumber",
          },
          {
            type: "TextField",
            initialvalue: "",
            validator: [
              (value) => {
                if (value !== num) {
                  return "Account numbers don't match"
                }
              }
            ],
            label: "Confirm Account Number",
            name: "confirmAccountNumber",
          },
          {
            type: "TextField",
            validator: [
              notEmpty,
              (value) => {
                if (value.length < 9 || value.length > 13) {
                  return "Please enter valid routing number";
                }
              },
            ],
            initialvalue: "",
            label: "Routing Number",
            name: "routingNumber",
          },
        ]}
      ></Form>
    </Container>
  );
};

export default PayoutBankForm;
