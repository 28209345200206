import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    comp:
    {
        width: "100%"
    },
    formLabel: {
        fontSize: 14,
        color: theme.palette.textColor2.main
    },
    fieldText: {
        fontSize: 16,
        color: theme.palette.primaryText.main,
        padding:"10px"
    }
}));
/*
Required params: onchange, name
Optional: label, intialvalue
*/
const CDate = (props) => {
    const classes = useStyles();
    const intoInputProps = (props.moreProps&& props.moreProps.InputProps? props.moreProps.InputProps: {})
    intoInputProps.classes = {input: classes.fieldText};
    const otherProps = {...props.moreProps, InputProps:intoInputProps}
    return (
        <div>
            <Typography className={classes.formLabel}>
                {props.label}
            </Typography>
            <TextField
                type = "date"
                error={(typeof props.errortext == "string" && props.errortext !== "" ? true : false)}
                className={classes.comp}
                onChange={props.onchange}
                defaultValue={(typeof props.initialvalue == "string" ? props.initialvalue.replaceAll('/', '-') : "")}
                helperText={(props.errortext !== "" ? props.errortext : null)}
                variant="outlined"
                name={props.name}
                {...otherProps}
            ></TextField>
        </div>
    );
}

export default CDate;