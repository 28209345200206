import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import SingleStaff from './SingleStaff';
import StaffsList from './StaffsList';


const StaffsHandler = () => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const basePath = `/dashboard/${businessId}/Staff`;

    return(
        <Switch>
            <Route path = {basePath}  exact render = {()=>{return(<StaffsList businessId = {businessId} basePath = {basePath}/>);}}/>
            <Route path = {`${basePath}/:staffIdURL`}  exact render = {()=>{return(<SingleStaff businessId = {businessId}/>);}}/>
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
        </Switch>
    );
}

export default StaffsHandler;