const startAfterEnd = (dates) => {
    const date1 = dates[0]
    const date2 = dates[1]
    let error1 = null
    let error2 = null
    let start, end;
    if (!date1) {
        error1 = "Date is required"
    }
    if (!date2) {
        error2 = "Date is required"
    }

    if (date1 && date2) {
        let [end_year, end_month, end_day] = date2.split('/');
        let [start_year,start_month, start_day] = date1.split('/');
        start = new Date(start_year, (start_month - 1) % 12, start_day);
        end = new Date(end_year, (end_month - 1) % 12, end_day);
        if (end < start) {
            error2 = "End cannot be before start"
        }
    }

    return ([error1, error2])
}

export default startAfterEnd