import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardActionArea,
  CardContent,
  Button,
  CardMedia,
  Collapse,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
  Container,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import map from "./mapicon.png";
import Tennis from "@material-ui/icons/SportsTennis";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import Tune from "@material-ui/icons/Tune";
import { Rating } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  businessSinglePage,
  encodeGetParams,
  safeQuery,
  withLoading,
} from "../../utils";
import LightTooltip from "../Form/LightTooltip";
import TwoColumn from "../TwoColumn/TwoColumn";
import CModal from "../Form/CModal";
const useStyles = makeStyles((theme) => ({
  locationIcon: {
    fontSize: "2rem",
    marginLeft: 10,
  },
  formContainer: {
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "20px",
    maxWidth: "600px",
    width: "60%",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  accordionSummary: {
    backgroundColor: theme.palette.accent2.main,
    paddingLeft: "0px !important",
    paddingBottom: "0px !important",
    width: "100%-20px",
  },
  accordionContent: {
    display: "flex",
    flexDirection: "column",
  },
  accordion: {
    border: `1px solid ${theme.palette.accent1.main}`,
    boxSizing: "border-box",
    width: "100%",
    marginTop: 20,
  },
  offeringMedia: {
    maxWidth: "100%",
    height: 200,
    objectFit: "contain",
  },
  title: {
    marginTop: 20,
  },
  headingWrapper: {
    paddingBottom: "10px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  textColor3: {
    color: `${theme.palette.textColor3.main} !important`,
  },
  listText: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    cursor: "pointer",
  },
  root: {
    paddingTop: 20,
    paddingBottom: 30,
    paddingLeft: 10,
    paddingRight: 10,
    maxWidth: 1440,
    margin: "0 auto",
  },
  offeringRoot: {
    padding: 10,
  },
  media: {
    height: 200,
    width: 200,
    objectFit: "contain",
    backgroundColor: "#F7FAFC",
    borderRadius: 4,
    border: "1px solid rgb(233, 236, 237)",
  },
  labelText: {
    color: "#697386",
  },
  buttonGroup: {
    "& .MuiButtonGroup-contained": {
      color: "red",
    },
  },
  filterBar: {
    backgroundColor: `${theme.palette.primaryText.main}`,
    height: 100,
    "overflow-x": "hidden",
    marginBottom: 1,
  },
  filterBarText: {
    color: `${theme.palette.accent2.main}`,
    fontSize: 14,
  },
  sidePanel: {
    backgroundColor: "#e4ebf0",
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  titleText: {
    fontWeight: "bold",
    fontSize: "30px",
    color: `${theme.palette.primaryText.main}`,
  },
  titleSubText: {
    color: `${theme.palette.textColor3.main}`,
    fontSize: 14,
  },
  classTitleButton: {
    marginLeft: 10,
    marginTop: 10,
    color: `${theme.palette.primary.main}`,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: `${theme.palette.accent2.main}`,
      color: `${theme.palette.primary.main}`,
    },
  },
  radio: {
    color: `${theme.palette.textColor3.main}`,
    "& .Mui-checked": {
      color: `${theme.palette.primaryText.main}`,
    },
  },
}));

const Title = (props) => {
  const { classes, details, topSpacing, moreProps, onclick, bigEnough } = props;
  return (
    <Grid
      item
      xs={12}
      className={topSpacing ? classes.title : null}
      style={{
        paddingBottom: bigEnough ? 20 : 10,
        paddingTop: bigEnough ? 0 : 10,
      }}
    >
      <Button color="light" onclick={onclick} style={{ maxWidth: "100%" }}>
        <Typography
          variant="h5"
          onClick={onclick}
          style={{ fontSize: bigEnough ? 28 : 24, textTransform: "none" }}
          noWrap
        >
          <b>{details.businessDetails.name}</b>
        </Typography>
      </Button>
    </Grid>
  );
};

const calcSize = (sizeBreakDown) => {
  if (sizeBreakDown.xl) {
    return 3;
  } else if (sizeBreakDown.lg) {
    return 4;
  } else if (sizeBreakDown.md) {
    return 6;
  } else if (sizeBreakDown.sm) {
    return 6;
  } else if (sizeBreakDown.xs) {
    return 12;
  }
};

const FilterButton = (props) => {
  const {
    value,
    buttonLabel,
    setSessionType,
    sessionType,
    maxValue,
    setSeeMore,
    setActiveCategory,
    activeCategory,
    prevValue,
    setPrevValue,
    setMainContent,
    offset,
  } = props;
  const [color, setColor] = useState("#F7FAFC");
  useEffect(() => {
    if (100 - offset > 43) {
      setColor("#F7FAFC");
    } else {
      setColor("#DBDDDF");
    }
  });
  return (
    <>
      {value <= 4 ? (
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            if (value === -1) setPrevValue(sessionType);
            setSessionType(value);
            setActiveCategory(value);
            if (value === -1) setSeeMore(true);
            if (value === -1) setMainContent(6);
          }}
          value={value}
          style={{
            textTransform: "capitalize",
            borderTopLeftRadius: value === 0 ? "15px" : "0px",
            borderBottomLeftRadius: value === 0 ? "15px" : "0px",
            borderTopRightRadius:
              (value === maxValue && maxValue <= 4) || maxValue === -1
                ? "15px"
                : "0px",
            borderBottomRightRadius:
              (value === maxValue && maxValue <= 4) || maxValue === -1
                ? "15px"
                : "0px",
            backgroundColor: activeCategory === value ? "#FFBF00" : color,
            color: "#1A1F36",
            width: maxValue === 0 ? 400 : "undefined",
          }}
        >
          {buttonLabel}
        </Button>
      ) : null}
    </>
  );
};

const CategoryServices = (props) => {
  const {
    bigEnough,
    categoryValue,
    details,
    classes,
    prevValue,
    setPrevValue,
  } = props;
  const value = categoryValue === -1 ? prevValue : categoryValue;
  const catalogOfferings = details.catalogDetails[value];
  if (
    details.catalogDetails.length &&
    catalogOfferings.offeringDetails.length
  ) {
    return (
      <>
        {catalogOfferings.offeringDetails.map((offering) => {
          return (
            <>
              <Link
                style={{ textDecoration: "none" }}
                className={classes.fullWidth}
                to={`/Market-Place/${catalogOfferings.catalogId}/${offering.offeringId}`}
              >
                <Button variant="text" className={classes.classTitleButton}>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "bolder",
                      fontSize: bigEnough ? 24 : 20,
                    }}
                    align="left"
                  >
                    {offering.serviceName}
                  </Typography>
                </Button>
              </Link>
              <div style={{ marginRight: 20, marginLeft: 20, marginTop: -5 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    variant="subtitle1"
                    style={{ color: "#697386", fontSize: 15 }}
                  >
                    {"Site: " + offering.sitePublicName}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ color: "#697386", fontSize: 15, paddingLeft: 20 }}
                  >
                    {"Duration: " + offering.duration + " mins"}
                  </Typography>
                </div>
                <LightTooltip arrow title={offering.description}>
                  <Typography
                    variant="subtitle1"
                    noWrap="true"
                    style={{
                      color: "#1A1F36",
                      marginTop: 10,
                      fontSize: 15,
                      maxWidth: "100%",
                    }}
                  >
                    {offering.description}
                  </Typography>
                </LightTooltip>
              </div>
              <div
                style={{
                  borderTop: "1px solid #DBDDDF ",
                  marginTop: 20,
                }}
              ></div>
            </>
          );
        })}
      </>
    );
  } else {
    return (
      <Typography
        variant="h5"
        style={{ fontSize: bigEnough ? "24px" : "20px" }}
      >
        No Services Found
      </Typography>
    );
  }
};

const LocationServices = (props) => {
  const {
    bigEnough,
    categoryValue,
    details,
    classes,
    prevValue,
    setPrevValue,
  } = props;
  const value = categoryValue === -1 ? prevValue : categoryValue;
  const locationOffering =
    value === details.offeringDetails.inperson.length
      ? details.offeringDetails.virtual
      : details.offeringDetails.inperson[value];
  return (
    <>
      {locationOffering.groupServices.map((offering) => {
        return (
          <>
            <Link
              style={{ textDecoration: "none" }}
              className={classes.fullWidth}
              to={`/Market-Place/${offering.offeringId}`}
            >
              <Button variant="text" className={classes.classTitleButton}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bolder",
                    fontSize: bigEnough ? 24 : 20,
                  }}
                  align="left"
                >
                  {offering.serviceName}
                </Typography>
              </Button>
            </Link>
            <div style={{ marginRight: 20, marginLeft: 20, marginTop: -5 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "#697386", fontSize: 15 }}
                >
                  {"Type : Group"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ color: "#697386", fontSize: 15, paddingLeft: 20 }}
                >
                  {"Duration: "}
                  {offering.duration}
                  {" mins"}
                </Typography>
              </div>
              <LightTooltip arrow title={offering.description}>
                <Typography
                  variant="subtitle1"
                  noWrap="true"
                  style={{
                    color: "#1A1F36",
                    marginTop: 10,
                    fontSize: 15,
                    maxWidth: "100%",
                  }}
                >
                  {offering.description}
                </Typography>
              </LightTooltip>
            </div>
            <div
              style={{
                borderTop: "1px solid #DBDDDF ",
                marginTop: 20,
              }}
            ></div>
          </>
        );
      })}
      {locationOffering.privateServices.map((offering) => {
        return (
          <>
            <Link
              style={{ textDecoration: "none" }}
              className={classes.fullWidth}
              to={`/Market-Place/${offering.offeringId}`}
            >
              <Button variant="text" className={classes.classTitleButton}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bolder",
                    fontSize: bigEnough ? 24 : 20,
                  }}
                  align="left"
                >
                  {offering.serviceName}
                </Typography>
              </Button>
            </Link>
            <div style={{ marginRight: 20, marginLeft: 20, marginTop: -5 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "#697386", fontSize: 15 }}
                >
                  {"Type : Private"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ color: "#697386", fontSize: 15, paddingLeft: 20 }}
                >
                  {"Duration: "}
                  {offering.duration}
                  {" mins"}
                </Typography>
              </div>
              <Typography
                variant="subtitle1"
                style={{ color: "#1A1F36", marginTop: 10, fontSize: 15 }}
              >
                {offering.description
                  ? offering.description.substring(0, 80)
                  : null}
                {offering.description.length > 80 ? "..." : null}
              </Typography>
            </div>
            <div
              style={{
                borderTop: "1px solid #DBDDDF ",
                marginTop: 20,
              }}
            ></div>
          </>
        );
      })}
      {!locationOffering.groupServices.length &&
      !locationOffering.privateServices.length ? (
        <Typography
          variant="h5"
          style={{ fontSize: bigEnough ? "24px" : "20px" }}
        >
          No Services Found
        </Typography>
      ) : null}
    </>
  );
};

const CategoryRadio = (props) => {
  const {
    value,
    setCategorySelection,
    categorySelection,
    categoryTitle,
    activeCategory,
    setActiveCategory,
  } = props;

  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          onClick={() => {
            setCategorySelection(value);
            if (value <= 4) setActiveCategory(value);
            else setActiveCategory(-1);
          }}
          checked={categorySelection === value ? true : false}
        />
      }
      label={categoryTitle}
      style={{ margin: -3 }}
    />
  );
};

const CategoryRadioGroup = (props) => {
  const {
    details,
    setCategorySelection,
    classes,
    categorySelection,
    marginBottom,
    activeCategory,
    setActiveCategory,
    prevValue,
    setPrevValue,
  } = props;
  let i = 0;
  return (
    <FormControl
      style={{ paddingTop: 5, margin: 10, marginBottom: { marginBottom } }}
    >
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={0}
        value={categorySelection}
        name="radio-buttons-group"
        className={classes.radio}
      >
        {details.catalogDetails.map((catalog) => {
          return (
            <CategoryRadio
              value={i++}
              setCategorySelection={setCategorySelection}
              categoryTitle={catalog.catalogName}
              categorySelection={categorySelection}
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
              prevValue={prevValue}
              setPrevValue={setPrevValue}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

const LocationRadioGroup = (props) => {
  const {
    details,
    setCategorySelection,
    classes,
    categorySelection,
    marginBottom,
    activeCategory,
    setActiveCategory,
    prevValue,
    setPrevValue,
  } = props;
  let i = 0;
  return (
    <FormControl
      style={{ paddingTop: 5, margin: 10, marginBottom: { marginBottom } }}
    >
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={0}
        value={categorySelection}
        name="radio-buttons-group"
        className={classes.radio}
      >
        {details.offeringDetails.inperson.map((catalog) => {
          return (
            <CategoryRadio
              value={i++}
              setCategorySelection={setCategorySelection}
              categoryTitle={catalog.publicName}
              categorySelection={categorySelection}
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
              prevValue={prevValue}
              setPrevValue={setPrevValue}
            />
          );
        })}
        {details.offeringDetails.virtual.groupServices.length ||
        details.offeringDetails.virtual.privateServices.length ? (
          <CategoryRadio
            value={i++}
            setCategorySelection={setCategorySelection}
            categoryTitle={"Virtual"}
            categorySelection={categorySelection}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
            prevValue={prevValue}
            setPrevValue={setPrevValue}
          />
        ) : null}
      </RadioGroup>
    </FormControl>
  );
};

const CategoryCard = (props) => {
  const {
    open,
    setOpen,
    details,
    categorySelection,
    setCategorySelection,
    marginBottom,
    classes,
    bigEnough,
    activeCategory,
    setActiveCategory,
    prevValue,
    setPrevValue,
    setMainContent,
  } = props;
  return (
    <Card
      variant="outlined"
      style={{
        width: bigEnough ? "80%" : "undefined",
        maxWidth: bigEnough ? "undefined" : "100%",
        height: "auto",
        borderRadius: 10,
        boxShadow: "0px 4px 4px rgb(0 0 0 / 0.25)",
        marginTop: bigEnough ? 30 : 0,
        margin: 20,
      }}
    >
      <div
        style={{
          height: "15%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#e4ebf0",
          padding: 10,
        }}
      >
        <Typography
          variant="h6"
          align="center"
          style={{ fontWeight: "bold", fontSize: 20 }}
        >
          Categories
        </Typography>
      </div>
      <div style={{ padding: 10 }}>
        <CategoryRadioGroup
          classes={classes}
          details={details}
          setCategorySelection={setCategorySelection}
          categorySelection={categorySelection}
          marginBottom={marginBottom}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          prevValue={prevValue}
          setPrevValue={setPrevValue}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingBottom: "20px",
          paddingRight: "5px",
        }}
      >
        <Button
          variant="small"
          onClick={() => {
            setCategorySelection(prevValue);
            setOpen(false);
            setMainContent(9);
          }}
        >
          Close
        </Button>
      </div>
    </Card>
  );
};

const LocationCard = (props) => {
  const {
    open,
    setOpen,
    details,
    categorySelection,
    setCategorySelection,
    marginBottom,
    classes,
    bigEnough,
    activeCategory,
    setActiveCategory,
    prevValue,
    setPrevValue,
    setMainContent,
  } = props;
  return (
    <Card
      variant="outlined"
      style={{
        width: bigEnough ? "80%" : "100%",
        height: "auto",
        borderRadius: 10,
        boxShadow: "0px 4px 4px rgb(0 0 0 / 0.25)",
        marginTop: bigEnough ? 30 : 0,
        margin: 20,
      }}
    >
      <div
        style={{
          height: "15%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#e4ebf0",
          padding: 10,
        }}
      >
        <Typography
          variant="h6"
          align="center"
          style={{ fontWeight: "bold", fontSize: 20 }}
        >
          Locations
        </Typography>
        {/* <IconButton
          variant="text"
          size="small"
          style={{
            position: "absolute",
            right: bigEnough ? "3%" : "27%",
            margin: 10,
            color: "#697386",
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close fontSize="small" />
        </IconButton> */}
      </div>
      <div style={{ padding: 10 }}>
        <LocationRadioGroup
          classes={classes}
          details={details}
          setCategorySelection={setCategorySelection}
          categorySelection={categorySelection}
          marginBottom={marginBottom}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          prevValue={prevValue}
          setPrevValue={setPrevValue}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingBottom: "20px",
          paddingRight: "5px",
        }}
      >
        <Button
          variant="small"
          onClick={() => {
            setCategorySelection(prevValue);
            setOpen(false);
            setMainContent(9);
          }}
        >
          Close
        </Button>
      </div>
    </Card>
  );
};

const BusinessPage = (props) => {
  const classes = useStyles();
  const bigEnough = useMediaQuery("(min-width: 750px)");
  const { businessId } = useParams();
  const [details, setDetails] = useState(null);
  const [showDesc, setShowDesc] = useState(false);
  const [categorySelection, setCategorySelection] = useState(0);
  const [seeMore, setSeeMore] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [activeCategory, setActiveCategory] = useState(0);
  const [prevValue, setPrevValue] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [showMoreMobile, setShowMoreMobile] = useState(false);
  const [mainContent, setMainContent] = useState(9);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  let i = 0;
  const setUp = async () => {
    if (businessId) {
      let data = await withLoading(businessSinglePage, { businessId }, true);
      if (data) {
        setDetails(data);
      }
    }
  };

  useEffect(() => {
    setUp();
  }, []);

  if (details !== null) {
    if (details.businessDetails.singlePageType == "Catalog") {
      return (
        <>
          {details.catalogDetails.length ? (
            <Grid
              container
              className={classes.filterBar}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                position: "fixed",
                zIndex: 100,
                backgroundColor: "#1A1F36",
              }}
            >
              <CModal open={showCategories} setOpen={setShowCategories}>
                <CategoryCard
                  details={details}
                  open={showCategories}
                  setOpen={setShowCategories}
                  marginBottom={20}
                  classes={classes}
                  categorySelection={categorySelection}
                  setCategorySelection={setCategorySelection}
                  setActiveCategory={setActiveCategory}
                  activeCategory={activeCategory}
                  bigEnough={bigEnough}
                  prevValue={prevValue}
                  setPrevValue={setPrevValue}
                  setMainContent={setMainContent}
                />
              </CModal>
              {bigEnough ? null : (
                <CModal
                  open={showMore}
                  setOpen={setShowMore}
                  style={{ zIndex: 150 }}
                >
                  <Container className={classes.formContainer}>
                    <Typography variant="h5" style={{ width: 500 }}>
                      About Business
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      className={[classes.wrapIcon, classes.titleSubText]}
                    >
                      {details.businessDetails.about}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingTop: "15px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          setShowMore(false);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  </Container>
                </CModal>
              )}
              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonGroup style={{ padding: offset == 0 ? 10 : 0 }}>
                  {bigEnough ? (
                    details.catalogDetails.map((catalog) => {
                      return (
                        <FilterButton
                          value={i++}
                          buttonLabel={catalog.catalogName}
                          sessionType={categorySelection}
                          setSessionType={setCategorySelection}
                          setActiveCategory={setActiveCategory}
                          activeCategory={activeCategory}
                          maxValue={details.catalogDetails.length - 1}
                          setSeeMore={setSeeMore}
                          prevValue={prevValue}
                          setPrevValue={setPrevValue}
                          setMainContent={setMainContent}
                          offset={offset}
                        />
                      );
                    })
                  ) : (
                    <Grid
                      container
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Grid item>
                        <Card
                          style={{
                            minHeight: "40px",
                            minWidth: "200px",
                            maxWidth: "250px",
                            borderRadius: 15,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 5,
                            fontSize: 14,
                          }}
                        >
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <Typography nowrap>
                              {details.catalogDetails.length
                                ? details.catalogDetails[categorySelection]
                                    .catalogName
                                : "See Services Below"}
                            </Typography>
                          </div>
                        </Card>
                      </Grid>
                      <Grid item>
                        {details.catalogDetails.length ? (
                          <IconButton
                            size="large"
                            onClick={() => {
                              setShowCategories(true);
                            }}
                            style={{
                              color: "#FFBF00",
                              position: "absolute",
                              right: "3%",
                            }}
                          >
                            <Tune />
                          </IconButton>
                        ) : null}
                      </Grid>
                    </Grid>
                  )}
                  {bigEnough && !details.catalogDetails.length ? (
                    <FilterButton
                      value={0}
                      buttonLabel={"See Services Below"}
                      sessionType={categorySelection}
                      setSessionType={setCategorySelection}
                      maxValue={0}
                      setSeeMore={setSeeMore}
                      activeCategory={activeCategory}
                      setActiveCategory={setActiveCategory}
                      prevValue={prevValue}
                      setPrevValue={setPrevValue}
                      setMainContent={setMainContent}
                      offset={offset}
                    />
                  ) : null}
                  {details.catalogDetails.length > 5 && bigEnough ? (
                    <FilterButton
                      value={-1}
                      buttonLabel={"See more"}
                      sessionType={categorySelection}
                      setSessionType={setCategorySelection}
                      maxValue={-1}
                      setSeeMore={setSeeMore}
                      activeCategory={activeCategory}
                      setActiveCategory={setActiveCategory}
                      prevValue={prevValue}
                      setPrevValue={setPrevValue}
                      setMainContent={setMainContent}
                      offset={offset}
                    />
                  ) : null}
                </ButtonGroup>
              </Grid>
            </Grid>
          ) : null}
          {details.catalogDetails.length ? null : (
            <div style={{ minHeight: 40, backgroundColor: "#1A1F36" }}></div>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: bigEnough ? "col" : "row",
                maxWidth: 1440,
                paddingTop: details.catalogDetails.length && 100,
              }}
            >
              <Grid
                item
                xs={bigEnough ? 3 : 12}
                style={{ minHeight: bigEnough ? "670px" : "150px" }}
                className={classes.sidePanel}
              >
                <Grid
                  container
                  justifyContent="center"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingLeft: 20,
                    paddingTop: 30,
                    paddingBottom: 30,
                  }}
                  spacing={2}
                >
                  <img
                    src={details.businessDetails.logo}
                    style={{ width: bigEnough ? "90%" : "50%", height: "auto" }}
                  />
                </Grid>
                <Grid
                  container
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: bigEnough ? "start" : "center",
                    alignItems: bigEnough ? "start" : "center",
                  }}
                >
                  <Grid item style={{ marginBottom: 10 }}>
                    <Typography
                      variant="h4"
                      className={classes.titleText}
                      style={{ fontSize: bigEnough ? 30 : 24 }}
                    >
                      {details.businessDetails.name}
                    </Typography>
                  </Grid>
                  <Grid item direction="column" alignItems="baseline">
                    <Typography
                      variant="subtitle1"
                      className={[classes.wrapIcon, classes.titleSubText]}
                    >
                      <Phone fontSize="small" style={{ paddingRight: 5 }} />{" "}
                      {details.businessDetails.phoneNumber}
                    </Typography>
                  </Grid>
                  <Grid item direction="column" paddingBottom={10}>
                    <Typography
                      variant="subtitle1"
                      className={[classes.wrapIcon, classes.titleSubText]}
                    >
                      <Email fontSize="small" style={{ paddingRight: 5 }} />{" "}
                      {details.businessDetails.email}
                    </Typography>
                  </Grid>
                  {bigEnough ? (
                    <Grid item style={{ paddingTop: 10 }}>
                      <div>
                        <Typography variant="h6">About Business</Typography>
                        <Typography
                          variant="subtitle1"
                          className={[classes.wrapIcon, classes.titleSubText]}
                        >
                          {showMore
                            ? details.businessDetails.about
                            : `${details.businessDetails.about.substring(
                                0,
                                250
                              )}`}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: 20,
                        }}
                      >
                        {details.businessDetails.about.length > 250 ? (
                          <Button
                            onClick={() => {
                              setShowMore(!showMore);
                            }}
                          >
                            {showMore ? "Show Less" : "Show More"}
                          </Button>
                        ) : null}
                      </div>
                    </Grid>
                  ) : (
                    <Button
                      onClick={() => {
                        setSeeMore(false);
                        setShowMore(true);
                      }}
                    >
                      Learn More
                    </Button>
                  )}
                </Grid>
              </Grid>
              {details.catalogDetails.length ? (
                <Grid
                  item
                  xs={bigEnough ? mainContent : 12}
                  style={{
                    minHeight: "100vh",
                    paddingRight: 50,
                    paddingLeft: 50,
                    paddingTop: 20,
                    backgroundColor: "#F7FAFC",
                  }}
                >
                  {details.catalogDetails.length ? (
                    <Grid item>
                      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <Typography
                          variant="h4"
                          style={{ fontWeight: "bold", color: "#1A1F36" }}
                        >
                          Additional Information
                        </Typography>
                        <Typography style={{ paddingTop: 10 }}>
                          {categorySelection != -1
                            ? details.catalogDetails[categorySelection]
                                .catalogDescription
                            : details.catalogDetails[prevValue]
                                .catalogDescription}
                        </Typography>
                      </div>
                      <div
                        style={{
                          borderTop: "1px solid #DBDDDF ",
                          marginTop: 20,
                          paddingLeft: "-20px",
                        }}
                      ></div>
                    </Grid>
                  ) : null}
                  <CategoryServices
                    details={details}
                    categoryValue={categorySelection}
                    bigEnough={bigEnough}
                    classes={classes}
                    prevValue={prevValue}
                    setPrevValue={setPrevValue}
                  />
                </Grid>
              ) : null}
              {!details.catalogDetails.length ? (
                <Grid
                  item
                  xs={bigEnough ? 9 : 12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh",
                  }}
                >
                  <Tennis
                    color="disabled"
                    style={{ height: 300, width: 300 }}
                  />
                  <Typography
                    variant="h3"
                    style={{ fontWeight: "bold", color: "#bdbdbd" }}
                  >
                    No Services Currently Offered
                  </Typography>
                </Grid>
              ) : null}
              {seeMore && bigEnough ? (
                <Grid
                  item
                  xs={3}
                  style={{
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  className={classes.sidePanel}
                >
                  {seeMore ? (
                    <CategoryCard
                      details={details}
                      open={seeMore}
                      setOpen={setSeeMore}
                      categorySelection={categorySelection}
                      setCategorySelection={setCategorySelection}
                      classes={classes}
                      marginBottom={25}
                      bigEnough={bigEnough}
                      setActiveCategory={setActiveCategory}
                      activeCategory={activeCategory}
                      prevValue={prevValue}
                      setPrevValue={setPrevValue}
                      setMainContent={setMainContent}
                    ></CategoryCard>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </div>
        </>
      );
    } else {
      return (
        <>
          {details.offeringDetails.inperson.length ||
          details.offeringDetails.virtual.length ? (
            <Grid
              container
              className={classes.filterBar}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                position: "fixed",
                zIndex: 100,
                backgroundColor: "#1A1F36",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <CModal open={showCategories} setOpen={setShowCategories}>
                  <LocationCard
                    details={details}
                    open={showCategories}
                    setOpen={setShowCategories}
                    marginBottom={20}
                    classes={classes}
                    categorySelection={categorySelection}
                    setCategorySelection={setCategorySelection}
                    setActiveCategory={setActiveCategory}
                    activeCategory={activeCategory}
                    bigEnough={bigEnough}
                    prevValue={prevValue}
                    setPrevValue={setPrevValue}
                    setMainContent={setMainContent}
                  />
                </CModal>
                {bigEnough ? null : (
                  <CModal
                    open={showMore}
                    setOpen={setShowMore}
                    style={{ zIndex: 150 }}
                  >
                    <Container className={classes.formContainer}>
                      <Typography variant="h5" style={{ width: 500 }}>
                        About Business
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        className={[classes.wrapIcon, classes.titleSubText]}
                      >
                        {details.businessDetails.about}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          paddingTop: "15px",
                        }}
                      >
                        <Button
                          onClick={() => {
                            setShowMore(false);
                          }}
                        >
                          Close
                        </Button>
                      </div>
                    </Container>
                  </CModal>
                )}
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonGroup style={{ padding: 10 }}>
                  {bigEnough ? (
                    <>
                      {details.offeringDetails.inperson.map((catalog) => {
                        return (
                          <FilterButton
                            value={i++}
                            buttonLabel={catalog.publicName}
                            sessionType={categorySelection}
                            setSessionType={setCategorySelection}
                            setActiveCategory={setActiveCategory}
                            activeCategory={activeCategory}
                            maxValue={
                              details.offeringDetails.inperson.length >= 5
                                ? details.offeringDetails.inperson.length
                                : details.offeringDetails.inperson.length - 1
                            }
                            setSeeMore={setSeeMore}
                            prevValue={prevValue}
                            setPrevValue={setPrevValue}
                            setMainContent={setMainContent}
                            offset={offset}
                          />
                        );
                      })}
                      {details.offeringDetails.virtual.groupServices.length &&
                      details.offeringDetails.virtual.privateServices.length ? (
                        <FilterButton
                          value={details.offeringDetails.inperson.length}
                          buttonLabel={"Virtual"}
                          sessionType={categorySelection}
                          setSessionType={setCategorySelection}
                          maxValue={details.offeringDetails.inperson.length}
                          setSeeMore={setSeeMore}
                          activeCategory={activeCategory}
                          setActiveCategory={setActiveCategory}
                          prevValue={prevValue}
                          setPrevValue={setPrevValue}
                          setMainContent={setMainContent}
                          offset={offset}
                        />
                      ) : null}
                    </>
                  ) : (
                    <Grid
                      container
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Grid item>
                        <Card
                          style={{
                            minHeight: "40px",
                            minWidth: "200px",
                            maxWidth: "250px",
                            borderRadius: 15,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 5,
                            fontSize: 14,
                          }}
                        >
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <Typography nowrap>
                              {categorySelection <
                                details.offeringDetails.inperson.length &&
                              categorySelection != -1
                                ? details.offeringDetails.inperson[
                                    categorySelection
                                  ].publicName
                                : "Virtual"}
                              {details.offeringDetails.inperson.length ||
                              details.offeringDetails.virtual.length
                                ? null
                                : "See Services Below"}
                            </Typography>
                          </div>
                        </Card>
                      </Grid>
                      <Grid item>
                        <IconButton
                          size="large"
                          onClick={() => {
                            setShowCategories(true);
                          }}
                          style={{
                            color: "#FFBF00",
                            position: "absolute",
                            right: "3%",
                          }}
                        >
                          <Tune />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                  {bigEnough &&
                  !details.offeringDetails.inperson.length &&
                  !details.offeringDetails.virtual.length ? (
                    <FilterButton
                      value={0}
                      buttonLabel={"See Services Below"}
                      sessionType={categorySelection}
                      setSessionType={setCategorySelection}
                      maxValue={0}
                      setSeeMore={setSeeMore}
                      activeCategory={activeCategory}
                      setActiveCategory={setActiveCategory}
                      prevValue={prevValue}
                      setPrevValue={setPrevValue}
                      setMainContent={setMainContent}
                      offset={offset}
                    />
                  ) : null}
                  {details.offeringDetails.inperson.length >= 5 && bigEnough ? (
                    <FilterButton
                      value={-1}
                      buttonLabel={"See more"}
                      sessionType={categorySelection}
                      setSessionType={setCategorySelection}
                      maxValue={-1}
                      setSeeMore={setSeeMore}
                      activeCategory={activeCategory}
                      setActiveCategory={setActiveCategory}
                      prevValue={prevValue}
                      setPrevValue={setPrevValue}
                      setMainContent={setMainContent}
                      offset={offset}
                    />
                  ) : null}
                </ButtonGroup>
              </Grid>
            </Grid>
          ) : null}
          {details.offeringDetails.inperson.length ||
          details.offeringDetails.virtual.length ? (
            <div style={{ minHeight: 40, backgroundColor: "#1A1F36" }} />
          ) : null}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: bigEnough ? "col" : "row",
                maxWidth: "1440px",
                paddingTop: 60,
              }}
            >
              <Grid
                item
                xs={bigEnough ? 3 : 12}
                style={{ minHeight: bigEnough ? "600px" : "150px" }}
                className={classes.sidePanel}
              >
                <Grid
                  container
                  justifyContent="center"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingLeft: 20,
                    paddingTop: 30,
                    paddingBottom: 30,
                  }}
                  spacing={2}
                >
                  <img
                    src={details.businessDetails.logo}
                    style={{ width: bigEnough ? "90%" : "50%", height: "auto" }}
                  />
                </Grid>
                <Grid
                  container
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: bigEnough ? "start" : "center",
                    alignItems: bigEnough ? "start" : "center",
                  }}
                >
                  <Grid item style={{ marginBottom: 10 }}>
                    <Typography
                      variant="h4"
                      className={classes.titleText}
                      style={{ fontSize: bigEnough ? 30 : 24 }}
                    >
                      {details.businessDetails.name}
                    </Typography>
                  </Grid>
                  <Grid item direction="column" alignItems="baseline">
                    <Typography
                      variant="subtitle1"
                      className={[classes.wrapIcon, classes.titleSubText]}
                    >
                      <Phone fontSize="small" style={{ paddingRight: 5 }} />{" "}
                      {details.businessDetails.phoneNumber}
                    </Typography>
                  </Grid>
                  <Grid item direction="column" paddingBottom={10}>
                    <Typography
                      variant="subtitle1"
                      className={[classes.wrapIcon, classes.titleSubText]}
                    >
                      <Email fontSize="small" style={{ paddingRight: 5 }} />{" "}
                      {details.businessDetails.email}
                    </Typography>
                  </Grid>
                  {bigEnough ? (
                    <Grid item style={{ paddingTop: 10 }}>
                      <div>
                        <Typography variant="h6">About Business</Typography>
                        <Typography
                          variant="subtitle1"
                          className={[classes.wrapIcon, classes.titleSubText]}
                        >
                          {showMore
                            ? details.businessDetails.about
                            : `${details.businessDetails.about.substring(
                                0,
                                250
                              )}`}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: 20,
                        }}
                      >
                        {details.businessDetails.about.length > 250 ? (
                          <Button
                            onClick={() => {
                              setShowMore(!showMore);
                            }}
                          >
                            {showMore ? "Show Less" : "Show More"}
                          </Button>
                        ) : null}
                      </div>
                    </Grid>
                  ) : (
                    <Button
                      onClick={() => {
                        setSeeMore(false);
                        setShowMore(true);
                      }}
                    >
                      Learn More
                    </Button>
                  )}
                </Grid>
              </Grid>
              {details.offeringDetails.inperson.length ||
              details.offeringDetails.virtual.length ? (
                <Grid
                  item
                  xs={bigEnough ? mainContent : 12}
                  style={{
                    minHeight: "100vh",
                    paddingRight: 50,
                    paddingLeft: 50,
                    paddingTop: 20,
                    backgroundColor: "#F7FAFC",
                  }}
                >
                  {details.offeringDetails.inperson.length ||
                  details.offeringDetails.virtual.length ? (
                    <Grid item>
                      <div
                        style={{
                          paddingLeft: 5,
                          paddingRight: 20,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={
                            categorySelection != -1 &&
                            categorySelection !=
                              details.offeringDetails.inperson.length
                              ? (event) => {
                                  event.stopPropagation();
                                  window.open(
                                    "https://www.google.com/maps/search/?api=1&" +
                                      encodeGetParams({
                                        query:
                                          details.offeringDetails.inperson[
                                            categorySelection
                                          ].address1,
                                      }),
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                }
                              : null
                          }
                          style={{ color: "#3366FF" }}
                        >
                          <img src={map} width={50} height={50} />
                        </IconButton>
                        <div style={{ paddingLeft: 2 }}>
                          <Typography style={{ paddingTop: 10 }}>
                            {categorySelection ==
                            details.offeringDetails.inperson.length
                              ? "Virtual"
                              : null}
                            {categorySelection != -1 &&
                            categorySelection !=
                              details.offeringDetails.inperson.length
                              ? details.offeringDetails.inperson[
                                  categorySelection
                                ].address1
                              : categorySelection !=
                                  details.offeringDetails.inperson.length &&
                                details.offeringDetails.inperson[prevValue]
                                  .address1}
                          </Typography>
                          <Typography>
                            {categorySelection != -1 &&
                            categorySelection !=
                              details.offeringDetails.inperson.length
                              ? details.offeringDetails.inperson[
                                  categorySelection
                                ].address2
                              : categorySelection !=
                                  details.offeringDetails.inperson.length &&
                                details.offeringDetails.inperson[prevValue]
                                  .address2}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{
                          borderTop: "1px solid #DBDDDF ",
                          marginTop: 20,
                          paddingLeft: "-20px",
                        }}
                      ></div>
                    </Grid>
                  ) : null}

                  <LocationServices
                    details={details}
                    categoryValue={categorySelection}
                    bigEnough={bigEnough}
                    classes={classes}
                    prevValue={prevValue}
                    setPrevValue={setPrevValue}
                  />
                </Grid>
              ) : null}
              {!details.offeringDetails.inperson.length &&
              !details.offeringDetails.virtual.length ? (
                <Grid
                  item
                  xs={bigEnough ? 9 : 12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh",
                  }}
                >
                  <Tennis
                    color="disabled"
                    style={{ height: 300, width: 300 }}
                  />
                  <Typography
                    variant="h3"
                    style={{ fontWeight: "bold", color: "#bdbdbd" }}
                  >
                    No Services Currently Offered
                  </Typography>
                </Grid>
              ) : null}
              {seeMore && bigEnough ? (
                <Grid
                  item
                  xs={3}
                  style={{
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  className={classes.sidePanel}
                >
                  {seeMore ? (
                    <LocationCard
                      details={details}
                      open={seeMore}
                      setOpen={setSeeMore}
                      categorySelection={categorySelection}
                      setCategorySelection={setCategorySelection}
                      classes={classes}
                      marginBottom={25}
                      bigEnough={bigEnough}
                      setActiveCategory={setActiveCategory}
                      activeCategory={activeCategory}
                      prevValue={prevValue}
                      setPrevValue={setPrevValue}
                      setMainContent={setMainContent}
                    ></LocationCard>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </div>
        </>
      );
    }
  } else {
    return null;
  }
};

const ServiceSection = (props) => {
  const { service, classes, bigEnough } = props;
  const [choice, setChoice] = useState(null);
  useEffect(() => {
    if (service.groupServices.length > 0) {
      setChoice(0);
    } else if (service.privateServices.length > 0) {
      setChoice(1);
    }
  }, []);

  if (
    service.groupServices.length === 0 &&
    service.privateServices.length === 0
  ) {
    return null;
  } else {
    return (
      <Accordion
        className={classes.accordion}
        elevation={0}
        square
        defaultExpanded
        TransitionComponent={Collapse}
      >
        <AccordionSummary
          className={`${classes.accordionSummary} ${classes.headingWrapper}`}
          expandIcon={<ExpandMoreIcon />}
        >
          <div style={{ display: "flex" }}>
            {bigEnough ? (
              <LocationOnIcon className={classes.locationIcon} />
            ) : null}
            <LightTooltip
              arrow
              title={
                service.address1 && service.address2
                  ? `${service.address1}${
                      service.address2 ? ` ${service.address2}` : null
                    }`
                  : "Virtual Sessions"
              }
            >
              <Typography
                variant={"h5"}
                noWrap
                style={{
                  cursor: service.address1 ? "pointer" : undefined,
                  width: `calc(100vw - ${bigEnough ? 126 : 90}px)`,
                  maxWidth: 1314,
                  marginLeft: 10,
                }}
              >
                {service.publicName ? ` ${service.publicName}: ` : null}
                {service.address1 ? (
                  <span
                    onClick={(event) => {
                      event.stopPropagation();
                      window.open(
                        "https://www.google.com/maps/search/?api=1&" +
                          encodeGetParams({ query: service.address1 }),
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    {`${service.address1}${
                      service.address2 ? ` ${service.address2}` : ""
                    }`}
                  </span>
                ) : (
                  <span>Virtual Sessions</span>
                )}
              </Typography>
            </LightTooltip>
          </div>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.accordionContent,
          }}
        >
          {choice !== null &&
          (service.groupServices.length > 0 ||
            service.privateServices.length > 0) ? (
            <Tabs
              value={choice}
              onChange={(event, newValue) => {
                setChoice(newValue);
              }}
            >
              <Tab
                value={0}
                label="Clinics / Camps"
                disabled={service.groupServices.length === 0}
              />
              <Tab
                value={1}
                label="Private"
                disabled={service.privateServices.length === 0}
              />
            </Tabs>
          ) : null}
          {choice === 0 ? (
            <div style={{ padding: 12, paddingTop: 24 }}>
              <Grid container spacing={3}>
                {service.groupServices.map((offering, index) => (
                  <OfferingCard
                    offering={offering}
                    key={`${service.addres1}-${service.address2}-${index} group`}
                    classes={classes}
                  />
                ))}
              </Grid>
            </div>
          ) : choice === 1 ? (
            <div style={{ padding: 12, paddingTop: 24 }}>
              <Grid container spacing={3}>
                {service.privateServices.map((offering, index) => (
                  <OfferingCard
                    offering={offering}
                    key={`${service.addres1}-${service.address2}-${index} private`}
                    classes={classes}
                    bigEnough={bigEnough}
                  />
                ))}
              </Grid>
            </div>
          ) : null}
        </AccordionDetails>
      </Accordion>
    );
  }
};

const OfferingCard = (props) => {
  const { offering, classes, bigEnough } = props;
  const gridSize = calcSize({
    xs: bigEnough,
    sm: useMediaQuery("(min-width: 900px)"),
    md: useMediaQuery("(min-width: 1100px)"),
    lg: useMediaQuery("(min-width: 1300px)"),
    xl: useMediaQuery("(min-width: 1600px)"),
  });
  return (
    <Grid item xs={gridSize} style={{ width: !bigEnough ? "100%" : undefined }}>
      <Link
        style={{ textDecoration: "none" }}
        className={classes.fullWidth}
        to={`/Market-Place/${offering.offeringId}`}
      >
        <Card elevation={0}>
          <CardActionArea disableRipple disableTouchRipple>
            <CardMedia
              className={classes.offeringMedia}
              image={offering.image}
              component="img"
            />
            <CardContent
              classes={{
                root: classes.offeringRoot,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5" color="primary">
                    {offering.serviceName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {offering.rating !== null ? (
                      <Rating
                        name="read-only"
                        value={offering.rating}
                        readOnly
                        precision={0.1}
                      />
                    ) : null}
                    {offering.totalReviews === 0 ? null : (
                      <Typography
                        display="inline"
                        style={{
                          paddingLeft: offering.rating !== null ? 10 : 0,
                        }}
                      >
                        {offering.totalReviews === 1
                          ? `${offering.totalReviews} Review`
                          : `${offering.totalReviews} Reviews`}
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Grid>
  );
};

const BusinessDetails = (props) => {
  const { details, classes } = props;
  return (
    <TwoColumn
      first={[
        <Typography key="left phone" className={classes.labelText}>
          Phone Number
        </Typography>,
        <Typography key="left email" className={classes.labelText}>
          Email Address
        </Typography>,
      ]}
      second={[
        <Typography key="right phone" noWrap>
          {details.businessDetails.phoneNumber}
        </Typography>,
        <Typography key="right email" noWrap>
          {details.businessDetails.email}
        </Typography>,
      ]}
      secondItemProps={{
        style: { maxWidth: "calc(100% - 136px)" },
      }}
      extProps={{
        style: {
          width: "calc( 100% -10px)",
          paddingLeft: "10px",
        },
      }}
    />
  );
};

export default BusinessPage;
