import {
  Button,
  ButtonGroup,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { convertToDate } from "../../FormValidation/validDates";
import { encodeGetParams, retrieveGetParams } from "../../utils";
import CModal from "../Form/CModal";
import Form from "../Form/Form";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
    width: "100vw",
  },
  buttonGroup: {
    float: "right",
  },
  button: {
    height: "36.5px",
  },
  buttonText: {
    fontSize: "14px",
    textTransform: "none",
  },
  icon: {
    height: "20px",
  },
}));

const ProvidedFilter = (props) => {
  const { filterFields } = props;
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState();
  // Filter fields will now be an object with type and options which will be a tuple of id and name

  const filterFieldMap = new Map();
  for (let i = 0; i < filterFields.length; i++) {
    filterFieldMap.set(filterFields[i].type, filterFields[i].options);
  }
  const queryParams = retrieveGetParams(location);
  const active = [
    "service",
    "site",
    "start",
    "end",
    "cId",
    "aId",
    "hId",
    "status",
    "staffId",
  ].some((param) => queryParams[param]);

  return (
    <React.Fragment>
      <ButtonGroup className={classes.buttonGroup}>
        <Button
          variant="contained"
          color={active ? "primary" : undefined}
          className={classes.button}
          onClick={() => {
            setOpen(true);
          }}
        >
          <Typography className={classes.buttonText}>Filter</Typography>
        </Button>
        {active ? (
          <Button
            style={{ width: 0, padding: 0 }}
            color="primary"
            variant="outlined"
            className={classes.button}
            onClick={async () => {
              history.push(
                location.pathname +
                  "?" +
                  encodeGetParams({
                    pg: queryParams.pg ? Number(queryParams.pg) : 1,
                  })
              );
            }}
          >
            <CloseIcon className={classes.icon} />
          </Button>
        ) : null}
      </ButtonGroup>

      <CModal open={open} setOpen={setOpen}>
        <Container className={classes.formContainer}>
          <Form
            title="Update Filter"
            cancelEnabled
            clearEnabled
            clearAction={async () => {
              setOpen(false);
              history.push(
                location.pathname +
                  "?" +
                  encodeGetParams({
                    pg: queryParams.pg ? Number(queryParams.pg) : 1,
                  })
              );
            }}
            cancelAction={async () => {
              setOpen(false);
            }}
            submitText="Filter"
            action={async (newParams) => {
              setOpen(false);
              history.push(
                location.pathname +
                  "?" +
                  encodeGetParams({
                    pg: 1,
                    service: newParams.service ? newParams.service : undefined,
                    site: newParams.site ? newParams.site : undefined,
                    start: newParams.start ? newParams.start : undefined,
                    end: newParams.end ? newParams.end : undefined,
                    cId: newParams.customer ? newParams.customer[1] : undefined,
                    cName: newParams.customer
                      ? newParams.customer[0]
                      : undefined,
                    aId: newParams.attendee ? newParams.attendee[1] : undefined,
                    aName: newParams.attendee
                      ? newParams.attendee[0]
                      : undefined,
                    hId: newParams.host ? newParams.host[1] : undefined,
                    hName: newParams.host ? newParams.host[0] : undefined,
                    staffId: newParams.staff ? newParams.staff[1] : undefined,
                    staffName: newParams.staff ? newParams.staff[0] : undefined,
                    status: newParams.status ? newParams.status : undefined,
                  })
              );
            }}
            inputFields={[
              {
                type: "SingleSelect",
                name: "service",
                label: "Service",
                initialvalue: queryParams.service
                  ? queryParams.service
                  : undefined,
                enabled: filterFieldMap.has("service"),
                choices: filterFieldMap.get("service") ?? [],
              },
              {
                type: "SingleSelect",
                name: "site",
                label: "Site",
                initialvalue: queryParams.site ? queryParams.site : undefined,
                enabled: filterFieldMap.has("site"),
                choices: filterFieldMap.get("site") ?? [],
              },
              {
                type: "DateRange",
                name: ["start", "end"],
                label: ["Start Date", "End Date"],
                initialvalue:
                  queryParams.start && queryParams.end
                    ? [queryParams.start, queryParams.end]
                    : undefined,
                enabled: filterFieldMap.has("dateRange"),
                validator: [
                  (value) =>
                    Boolean(!value[0] && value[1])
                      ? ["Both dates must be provided", null]
                      : [null, null],
                  (value) =>
                    Boolean(!value[1] && value[0])
                      ? [null, "Both dates must be provided"]
                      : [null, null],
                  (value) => {
                    if (!value[1] && !value[0]) {
                      return [null, null];
                    } else if (
                      convertToDate(value[0]) > convertToDate(value[1])
                    ) {
                      return ["Start cannot be after end", null];
                    } else {
                      return [null, null];
                    }
                  },
                ],
              },
              {
                type: "AutoComplete",
                name: "customer",
                label: "Customer",
                initialvalue:
                  queryParams.cId && queryParams.cName
                    ? [queryParams.cName, queryParams.cId]
                    : undefined,
                enabled: filterFieldMap.has("customer"),
                choices: filterFieldMap.get("customer") ?? [],
                validator: [],
              },
              {
                type: "AutoComplete",
                name: "attendee",
                label: "Attendee",
                initialvalue:
                  queryParams.aId && queryParams.aName
                    ? [queryParams.aName, queryParams.aId]
                    : undefined,
                enabled: filterFieldMap.has("attendee"),
                choices: filterFieldMap.get("attendee") ?? [],
                validator: [],
              },
              {
                type: "AutoComplete",
                name: "host",
                label: "Host",
                initialvalue:
                  queryParams.hId && queryParams.hName
                    ? [queryParams.hName, queryParams.hId]
                    : undefined,
                enabled: filterFieldMap.has("host"),
                choices: filterFieldMap.get("host") ?? [],
                validator: [],
              },
              {
                type: "AutoComplete",
                name: "staff",
                label: "Staff",
                initialvalue:
                  queryParams.staffId && queryParams.staffName
                    ? [queryParams.staffName, queryParams.staffId]
                    : undefined,
                enabled: filterFieldMap.has("staff"),
                choices: filterFieldMap.get("staff") ?? [],
                validator: [],
              },
              {
                type: "SingleSelect",
                name: "status",
                label: "Status",
                initialvalue: queryParams.status
                  ? queryParams.status
                  : undefined,
                enabled: filterFieldMap.has("status"),
                choices: [
                  ["Canceled", "Canceled"],
                  ["Not Canceled", "Not Canceled"],
                ],
                validator: [],
              },
            ]}
          />
        </Container>
      </CModal>
    </React.Fragment>
  );
};

export default ProvidedFilter;
