import axios from 'axios';
import api from './api';
import store from './store/store';

// Give duration as a whole number of minutes
export const formatDuration = (duration) => {
    if (duration % 60 === 0) {
        return `${parseInt(duration / 60)} hr`
    }
    else if (duration > 60) {
        return `${parseInt(duration / 60)} hr ${duration % 60} min`
    }
    else {
        return `${duration} min`
    }
}


/**
 * @param {React.MutableRefObject<null | string>} ref 
 * @param {functon(...any):any[]} fn 
 * @param {number} delay 
 * @returns {function(...any):any[]}
 */
export const debounce = (ref, fn, delay) => {
    const timeoutId = ref.current;
    return function (...args) {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        ref.current = setTimeout(() => {
            fn(...args);
        }, delay);
    };
};

export const sendToCheckout = (data, history, location) => {
    if (data) {
        store.dispatch({
            type: "UPDATE",
            payload: {
                customerSelection: data
            },
        });
        const getParams = retrieveGetParams(location)
        const newUrl = `/checkout?${encodeGetParams({ utcDate: getParams.utcDate, classId: getParams.classId })}`
        safePush(newUrl, history, location)
    }
}

export const safeQuery = (obj, keys, failOnError) => {
    let currObj = obj;
    keys.forEach(key => {
        if (typeof currObj === "object" && currObj !== null && currObj.hasOwnProperty(key)) {
            currObj = currObj[key]
        }
        else {
            if (failOnError) {
                throw new Error()
            }
            else {
                return (undefined)
            }
        }
    })
    return (currObj)
}

export const createList = (length, filler) => {
    const temp = []
    if (typeof length === "number") {
        for (let i = 0; i < length; i++) {
            temp.push(filler !== undefined ? filler() : null)
        }
    }
    return temp
}

export const encodeGetParams = p => {
    const tempData = []
    Object.entries(p).forEach(kv => {
        if (kv[1] !== undefined) {
            tempData.push(kv)
        }
    })
    return (tempData.map(kv => (kv.map(encodeURIComponent).join("="))).join("&"))
}

export const retrieveGetParams = location => {
    const searchParams = new URLSearchParams(location.search);
    const data = {}
    for (let pair of searchParams) {
        data[pair[0]] = pair[1]
    }
    return (data)
}

export const safePush = (newPath, history, location) => {
    if (location.pathname + location.search !== newPath) {
        history.push(newPath)
    }
}
export const withLoading = async (fn, arg, circ) => {
    let temp = null;
    store.dispatch({
        type: "UPDATE",
        payload: {
            loading: true,
            circular: circ
        },
    });
    temp = await fn(arg)
    store.dispatch({
        type: "UPDATE",
        payload: {
            loading: false,
            circular: false
        },
    });
    return (temp)
}
const queryWithKeys = (data, keys) => {
    if (keys) {
        let tempData = data
        for (let i = 0; i < keys.length; i++) {
            if (typeof tempData === "object") {
                tempData = tempData[keys[i]]
            }
            else {
                return (null)
            }
        }
        return tempData
    }
    else {
        return data
    }
}
const functionGenerator = (endPoint, type, keys) => {
    if (type === "post") {
        return async (creds) => {
            let response;
            try {
                response = await api.post(endPoint, creds, { headers: { 'x-auth': store.getState().token } });
                return queryWithKeys(response, ["data", ...(keys ? keys : [])]);
            }
            catch (error) {
                handleError(error);
            }
        }
    }
    else if (type === "patch") {
        return async (creds) => {
            let response
            try {
                response = await api.patch(endPoint, creds, { headers: { 'x-auth': store.getState().token } });
                return queryWithKeys(response, ["data", ...(keys ? keys : [])]);
            }
            catch (error) {
                handleError(error);
            }
        }
    }
    else if (type === "delete") {
        return async (creds) => {
            let response;
            try {
                response = await api.delete(endPoint, { headers: { 'x-auth': store.getState().token }, data: creds });
                return queryWithKeys(response, ["data", ...(keys ? keys : [])]);
            } catch (error) {
                handleError(error);
            }
        }
    }
    else {
        return async () => { }
    }
}
const handleError = (error) => {
    if (error.response) {
        store.dispatch(
            {
                type: 'UPDATE',
                payload:
                {
                    error: error.response.data.error,
                    errorMessage: error.response.data.errorMessage
                }
            }
        );
    }
};
export async function login(creds) {
    let response;
    try {
        response = await api.post('/api/users/login', { ...creds });
        store.dispatch(
            {
                type: 'UPDATE',
                payload:
                {
                    token: response.data.token,
                    userDetails: {
                        firstName: response.data.userDetails.firstName,
                        lastName: response.data.userDetails.lastName,
                        emailVerified: response.data.userDetails.emailVerified,
                        accountNumber: response.data.userDetails.accountNumber
                    },
                    dashboards: response.data.userDetails.dashboardDetails
                }
            }
        );
        return true;
    }
    catch (error) {
        handleError(error);
    }
};
export async function signUp(creds) {
    let response;
    try {
        response = await api.post('/api/users/signup', { ...creds });
        store.dispatch(
            {
                type: 'UPDATE',
                payload:
                {
                    token: response.data.token,
                    userDetails: {
                        firstName: response.data.userDetails.firstName,
                        lastName: response.data.userDetails.lastName,
                        emailVerified: response.data.userDetails.emailVerified,
                        accountNumber: response.data.userDetails.accountNumber
                    },
                    dashboards: response.data.userDetails.dashboardDetails
                }
            }
        );
        return true;
    }
    catch (error) {
        handleError(error);
    }
};

export async function refreshToken() {
    let response;
    try {
        response = await api.post('/api/users/refresh', {});
        store.dispatch(
            {
                type: 'UPDATE',
                payload:
                {
                    token: response.data.token,
                }
            }
        );
        return response.data.token
    }
    catch (err) {
        handleError(err)
    }
}

export async function firstTimeLoading() {

    let response;
    try {
        response = await api.post('/api/users/refresh', {
        });
        if (response && response.data && response.data.token) {
            store.dispatch(
                {
                    type: 'UPDATE',
                    payload:
                    {
                        token: response.data.token,
                        userDetails: {
                            firstName: response.data.userDetails.firstName,
                            lastName: response.data.userDetails.lastName,
                            emailVerified: response.data.userDetails.emailVerified,
                            accountNumber: response.data.userDetails.accountNumber
                        },
                        dashboards: response.data.userDetails.dashboardDetails
                    }
                }
            );
        }
    }
    catch (error) {
        handleError(error);
    }
};




export const handleRefreshError = () => {
    if (store.loaded) {
        logout()
    }
    else {
        clearState()
    }
}

export const clearState = () => {
    store.dispatch({
        type: "CLEAR",
        payload: {}
    })
}




export const tokenExpired = (accessToken) => {
    // use moment.js
    const base64Payload = accessToken.split('.')[1];
    const payload = Buffer.from(base64Payload, 'base64');
    const jwtObj = JSON.parse(payload.toString());
    const expires = jwtObj.exp * 1000
    return Date.now() > expires - (30 * 1000)
}


export async function forceClear() {
    store.dispatch({
        type: 'CLEAR',
        payload: {}
    });
    store.dispatch({
        type: 'UPDATE',
        payload: {
            error: true,
            errorMesage: "Session has expired. Please login again."
        }
    });
}

export async function logout() {
    try {
        await api.post('/api/users/logout', {}, { headers: { 'x-auth': store.getState().token } });
    }
    catch (error) { }
    store.dispatch({
        type: 'UPDATE',
        payload: {
            logout: true
        }
    });
};




export async function serverError() {
    store.dispatch({
        type: 'UPDATE',
        payload: {
            serverError: true,
        }
    })
}
export async function unauthorizedError() {
    store.dispatch({
        type: 'UPDATE',
        payload: {
            unauthorizedError: true,
        }
    })
}

export function clear() {
    store.dispatch(
        {
            type: 'CLEAR',
        }
    );
};
export const registerBusiness = functionGenerator('/api/onboard/register', "post")
export const dashboardList = functionGenerator('/api/users/dashboard/list', 'post', ['dashboardDetails'])
export const buisnessDashboard = functionGenerator('/api/users/dashboard/access', 'post', ['dashboardDetail'])
export const services = functionGenerator('/api/service/list', 'post')
export const serviceCategories = functionGenerator('/api/generic/details/admin', 'post', ['categorieDetails'])
export const addService = functionGenerator('/api/service/add', 'post')
export const editService = functionGenerator('/api/service/update', 'patch')
export const serviceActive = functionGenerator('/api/service/serviceStatus', 'post')
export const serviceAgeReq = functionGenerator('/api/service/ageRequired', 'post')
export const serviceGenderReq = functionGenerator('/api/service/genderRequired', 'post')
export const serviceWaiver = functionGenerator('/api/service/waiver', 'patch')
export const changeOfferingActive = functionGenerator('/api/offerings/offeringStatus', 'post')
export const removeCustomerQualification = functionGenerator('/api/service/removecustomerqualifications', 'delete')
export const removeAttendeeQualification = functionGenerator('/api/service/removeattendeequalifications', 'delete')
export const listQualifications = functionGenerator('/api/generic/details/admin', 'post', ['genericDetails', 'qualificationDetails'])
export const addCustomerQualification = functionGenerator('/api/service/addcustomerqualifications', 'post')
export const addAttendeeQualification = functionGenerator('/api/service/addattendeequalifications', 'post')
export const getOfferings = functionGenerator('/api/offerings/map', 'post')
export const sites = functionGenerator('/api/site/list', 'post')
export const addSite = functionGenerator('/api/site', 'post')
export const editSite = functionGenerator('/api/site', 'patch')
export const siteActive = functionGenerator('/api/site/siteStatus', 'post')
export const offeringDetails = functionGenerator('/api/offerings/offeringDetails', 'post', ['offeringDetails'])
export const optionStatus = functionGenerator('/api/pricing/option/optionStatus', 'post')
export const getOptionDetails = functionGenerator('/api/pricing/option/optionDetails', 'post', ['optionDetails'])
export const getDiscountList = functionGenerator('/api/discounts/list', 'post', ['discountDetails'])
export const addMembership = functionGenerator('/api/memberships/add', 'post')
export const listMemberships = functionGenerator('/api/memberships/list', 'post')
export const payoutVerification = functionGenerator("https://api.stripe.com/v1/tokens", 'post')
export const updateMembership = functionGenerator('/api/memberships/update', 'patch')
export const assignDiscountToOption = functionGenerator('/api/pricing/option/discount', 'post')
export const removeDiscountFromOption = functionGenerator('/api/pricing/option/removediscounts', 'delete')
export const addPricingOption = functionGenerator('/api/pricing/option', 'post')
export const profileList = functionGenerator('/api/business/profile', 'post')
export const listCustomerQuali = functionGenerator('/api/qualifications/list', 'post')
export const updateCustomerQuali = functionGenerator('/api/qualifications/update', 'patch')
export const addCustomerQuali = functionGenerator('/api/qualifications/add', 'post')
export const updateProfile = functionGenerator('/api/business/profile', 'patch')
export const statusMembership = functionGenerator('/api/memberships/membershipStatus', 'post')
export const removeStaffFromOption = functionGenerator('/api/pricing/option/removestaffs', 'delete')
export const addStaffToOption = functionGenerator('/api/pricing/option/addstaffs', 'post')
export const getStaffList = functionGenerator('/api/staff/list', 'post', ['staffs'])
export const staffActive = functionGenerator('/api/staff/staffStatus', 'post')
export const listDiscounts = functionGenerator('/api/discounts/list', 'post')
export const addDiscount = functionGenerator('/api/discounts/add', 'post')
export const editDiscount = functionGenerator('/api/discounts/update', 'patch')
export const statusDiscount = functionGenerator('/api/discounts/discountStatus', 'post')
export const addCustomer = functionGenerator('/api/customers', 'post')
export const listCustomers = functionGenerator('/api/customers/list', 'post')
export const editPricingOption = functionGenerator('/api/pricing/option', 'patch')
export const addPricingDiscount = functionGenerator('/api/pricing/option/adddiscounts', 'post')
export const generateGroupSessions = functionGenerator('/api/group/gen', 'post', ['classRecords', 'classRecords'])
export const getGroupSessionFormDetails = async (payload) => { let data = await functionGenerator('/api/generic/details/admin', 'post', ['genericDetails'])(payload); if (typeof data === 'object') { return ({ serviceDetails: data.serviceDetails, siteDetails: data.siteDetails }) } }
export const addCustomerEC = functionGenerator('/api/customers/ec', 'post')
export const editCustomerNotes = functionGenerator('/api/customers/notes', 'patch')
export const createGroupSessions = functionGenerator('/api/group', 'post')
export const editWebConfInfo = functionGenerator('/api/group/onlineconferenceinfo', 'patch')
export const editPrivateWebConfInfo = functionGenerator('/api/businesssessions/onlineconferenceinfo', 'patch')
export const addHost = functionGenerator('/api/group/addhosts', 'post')
export const rescheduleClass = functionGenerator('/api/group/rescheduleclass', 'patch')
export const cancelClass = functionGenerator('/api/group/cancel', 'patch')
export const classAttendanceSheetAdmin = functionGenerator('/api/group/classAttendance', 'post')
export const markClassAttendanceAdmin = functionGenerator('/api/group/markAttendance', 'post')
export const classAttendanceSheetStaff = functionGenerator('/api/staffgroup/classAttendance', 'post')
export const markClassAttendanceStaff = functionGenerator('/api/staffgroup/markAttendance', 'post')
export const removeHost = functionGenerator('/api/group/removehosts', 'delete')
export const editCustomerEC = functionGenerator('/api/customers/ec', 'patch')
export const removeCustomerEC = functionGenerator('/api/customers/ec', 'delete')
export const getStaffCalenders = functionGenerator('/api/calendars/list', 'post', ["calenderDetails"])
export const removeCustQual = functionGenerator('/api/customers/removecustomerqualifications', 'delete')
export const addCustomerDocument = functionGenerator('/api/customers/doc', 'post')
export const updateStaffActive = functionGenerator('/api/staff/staffStatus', 'post')
export const addCustQualification = functionGenerator('/api/customers/addcustomerqualifitations', 'post')
export const listcustomerDetails = functionGenerator('/api/customers/customerdetails', 'post')
export const addStaffCalender = functionGenerator('/api/calendars', 'post')
export const editStaffCalender = functionGenerator('/api/calendars', 'patch')
export const addCustMembership = functionGenerator('/api/customers/addmemberships', 'post')
export const removeCustMembership = functionGenerator('/api/customers/removememberships', 'delete')
export const deleteCalendar = functionGenerator('/api/calendars', 'delete')
export const addStaffSchedule = functionGenerator('/api/calendars/schedule', 'post')
export const deleteStaffScheduleLine = functionGenerator('/api/calendars/schedule', 'delete')
export const listOrders = functionGenerator('/api/businessorders/list', 'post')
export const editStaffSchedule = functionGenerator('/api/calendars/schedule', 'patch')
export const listOrderDetails = functionGenerator('/api/businessorders/orderDetails', 'post')
export const addTimeOff = functionGenerator('/api/calendars/timeoff', 'post')
export const editTimeOff = functionGenerator('/api/calendars/timeoff', 'patch')
export const deleteTimeOff = functionGenerator('/api/calendars/timeoff', 'delete')
export const addStaff = functionGenerator('/api/staff', 'post')
export const listPacks = functionGenerator('/api/businesspacks/list', 'post')
export const updateStaff = functionGenerator('/api/staff', 'patch')
export const listPackageDetails = functionGenerator('/api/businesspacks/packdetails', 'post')
export const genOTP = functionGenerator('/api/users/genemailotp', 'post')
export const refundOrder = functionGenerator('/api/businessorders/refundorder', 'post')
export const businessCalendarEvents = functionGenerator('/api/businesscalendar/events', 'post')
export const getGenericDetails = functionGenerator('/api/generic/details/admin', 'post')
export const blockCustomer = functionGenerator('/api/customers/blockcustomer', 'patch')
export const downloadCustomerList = functionGenerator('/api/customers/downloadCustomers', 'post')
export const cancelPack = functionGenerator('/api/businesspacks/cancelpack', 'patch')
export const scheduleClass = functionGenerator('/api/businesspacks/schedulepack', 'post')
export const forgetOTP = functionGenerator('/api/users/genfpotp', 'post')
export const listPackDetails = functionGenerator('/api/businesspacks/packDetails', 'post')
export const resetPassword = functionGenerator('/api/users/resetpassword', 'post')
export const listSessions = functionGenerator('/api/businesssessions/sessionslist', 'post')
export const listSessionDetails = functionGenerator('/api/businesssessions/sessiondetails', 'post')
export const pricing = functionGenerator('/api/pricing/option/list', 'post')
export const getPricingOptionsDetails = functionGenerator('/api/pricing/option/optionDetails', 'post')
export const operatingUnits = functionGenerator('/api/generic/details/operatingunits', 'post', ['operatingUnitDetails', 0])
export const statusQualification = functionGenerator('/api/qualifications/qualificationStatus', 'post')
export const verificationDets = functionGenerator('/api/verification/details', 'post')
export const verificationInitiate = functionGenerator('/api/verification/initiate', 'post')
export const verificationUpdate = functionGenerator('/api/verification/update', 'post')
export const listClasses = functionGenerator('/api/group/classlist', 'post', ['classes'])
export const getClassDetails = functionGenerator('/api/group/classdetails', 'post', ['classDetails'])
export const issueRefund = functionGenerator('/api/businessorders/refundorder', 'post')
export const addCustPricing = functionGenerator('/api/customers/customprice', 'post')
export const editCustPricing = functionGenerator('/api/customers/customprice', 'patch')
export const deleteCustPricing = functionGenerator('/api/customers/customprice', 'delete')
export const payoutBankInfo = functionGenerator('/api/businesspayouts/payoutbankinfo', 'post')
export const updatePayoutBank = functionGenerator('/api/businesspayouts/updatepayoutbank', 'patch')
export const listPayouts = functionGenerator('/api/businesspayouts/payouts', 'post')
export const statementDetails = functionGenerator('/api/businesspayouts/payoutdetails', 'post')
export const docUrlGet = functionGenerator('/api/s3/docsignedurlget', 'post')
export const signedUrlUpload = functionGenerator('/api/s3/docsignedurlupload', 'post')
export const editCustomerDocument = functionGenerator('/api/customers/doc', 'patch')
export const deleteCustomerDocument = functionGenerator('/api/customers/doc', 'delete')
export const saveS3Doc = functionGenerator('https://s3.us-west-1.amazonaws.com/caritra-upgrade-docs', 'post')
export const cancelSession = functionGenerator('/api/businesssessions/cancelsession', 'patch')
export const rescheduleSession = functionGenerator('/api/businesssessions/reschedulesession', 'patch')
export const profileStaff = functionGenerator('/api/staffprofile', 'post')
export const editProfileStaff = functionGenerator('/api/staffprofile', 'patch')
export const staffProfileDetails = functionGenerator('/api/staffprofile', 'post')
export const updateStaffProfile = functionGenerator('/api/staffprofile', 'patch')
export const genericStaffInfo = functionGenerator('/api/generic/details/staff', 'post')
export const staffSessionsList = functionGenerator('/api/staffsessions/sessionslist', 'post')
export const staffSessionDetails = functionGenerator('/api/staffsessions/sessiondetails', 'post')
export const cancelStaffSession = functionGenerator('/api/staffsessions/cancelsession', 'patch')
export const rescheduleStaffSession = functionGenerator('/api/staffsessions/reschedulesession', 'patch')
export const virtualStaffSessionInfo = functionGenerator('/api/staffsessions/onlineconferenceinfo', 'patch')
export const staffClassList = functionGenerator('/api/staffgroup/classlist', 'post')
export const staffClassDetails = functionGenerator('/api/staffgroup/classdetails', 'post')
export const staffCalendarEvents = functionGenerator('/api/staffcalendar/events', 'post')
export const staffPackList = functionGenerator('/api/staffpacks/list', 'post')
export const staffPackDetails = functionGenerator('/api/staffpacks/packdetails', 'post')
export const schedulePackStaff = functionGenerator('/api/staffpacks/schedulepack', 'post')
export const cancelPackStaff = functionGenerator('/api/staffpacks/cancelpack', 'patch')
export const addFeePricing = functionGenerator('/api/pricing/option/additionalFee', 'post')
export const editFeePricing = functionGenerator('/api/pricing/option/additionalFee', 'patch')
export const deleteFeePricing = functionGenerator('/api/pricing/option/additionalFee', 'delete')
export const listAgreements = functionGenerator('/api/agreements/list', 'post', ['agreementDetails'])
export const updateAgreement = functionGenerator('/api/agreements/update', 'patch')
export const agreementStatus = functionGenerator('/api/agreements/agreementStatus', 'post')
export const createAgreement = functionGenerator('/api/agreements/add', 'post')
export const addServiceQuestion = functionGenerator('/api/service/addservicequestions', 'post')
export const removeServiceQuestion = functionGenerator('/api/service/removeservicequestions', 'delete')
export const addPricingAgreement = functionGenerator('/api/pricing/option/addagreements', 'post')
export const removePricingAgreement = functionGenerator('/api/pricing/option/removeagreements', 'delete')
export const addTaxPricing = functionGenerator('/api/pricing/option/taxLines', 'post')
export const editTaxPricing = functionGenerator('/api/pricing/option/taxLines', 'patch')
export const deleteTaxPricing = functionGenerator('/api/pricing/option/taxLines', 'delete')
export const listQuestions = functionGenerator('/api/questions/list', 'post')
export const addQuestion = functionGenerator('/api/questions/add', 'post')
export const editQuestion = functionGenerator('/api/questions/update', 'patch')
export const questionStatus = functionGenerator('/api/questions/questionStatus', 'post')
export const customerOrders = functionGenerator('/api/customerorders/list', 'post')
export const customerOrderDetail = functionGenerator('/api/customerorders/orderdetails', 'post')
export const customerPackages = functionGenerator('/api/customerpacks/list', 'post')
export const customerCards = functionGenerator('/api/cards/list', 'post')
export const customerCardPrimary = functionGenerator('/api/cards', 'patch')
export const customerDeleteCard = functionGenerator('/api/cards', 'delete')
export const customerAddCard = functionGenerator('/api/cards', 'post')
export const customerUpcomingSessions = functionGenerator('/api/customersessions/upcoming', 'post')
export const customerCompletedSessions = functionGenerator('/api/customersessions/completed', 'post')
export const customerCreateReview = functionGenerator('/api/customersessions/submitreview', 'patch')
export const staffScheduleList = functionGenerator('/api/calendarsstaff/list', 'post')
export const staffAddSchedule = functionGenerator('/api/calendarsstaff', 'post')
export const staffAddScheduleEvent = functionGenerator('/api/calendarsstaff/schedule', 'post')
export const staffAddScheduleTimeOff = functionGenerator('/api/calendarsstaff/timeoff', 'post')
export const staffUpdateSchedule = functionGenerator('/api/calendarsstaff', 'patch')
export const staffUpdateScheduleEvent = functionGenerator('/api/calendarsstaff/schedule', 'patch')
export const staffUpdateScheduleTimeOff = functionGenerator('/api/calendarsstaff/timeoff', 'patch')
export const staffDeleteSchedule = functionGenerator('/api/calendarsstaff', 'delete')
export const staffDeleteScheduleEvent = functionGenerator('/api/calendarsstaff/schedule', 'delete')
export const staffDeleteScheduleTimeOff = functionGenerator('/api/calendarsstaff/timeoff', 'delete')
export const staffEditOnlineConferenceInfo = functionGenerator('/api/staffsessions/onlineconferenceinfo', 'patch')
export const staffGenericDetails = functionGenerator('/api/generic/details/staff', 'post')
export const customerGetReview = functionGenerator('/api/customersessions/reviewdetails', 'post')
export const customerPackDetails = functionGenerator('/api/customerpacks/packdetails', 'post')
export const customerProfileDetails = functionGenerator('/api/users/myprofile', 'post')
export const customerUpdateProfile = functionGenerator('/api/users/myprofile', 'patch')
export const customerUpdatePassword = functionGenerator('/api/users/changepassword', 'post')
export const adminReviewsList = functionGenerator('/api/customers/listCustomerReviews', 'post')
export const adminReviewDetails = functionGenerator('/api/customers/customerReviewDetails', 'post')
export const customerCanceledSessions = functionGenerator('/api/customersessions/canceled', 'post')
export const customerCancelSession = functionGenerator('/api/customersessions/cancelsession', 'patch')
export const customerSessionDetails = functionGenerator('/api/customersessions/sessiondetails', 'post')
export const customerPackOpenings = functionGenerator('/api/availabilities/packslots', 'post')
export const customerPackClasses = functionGenerator('/api/availabilities/packclasses', 'post')
export const customerUsePack = functionGenerator('/api/customerpacks/schedulepack', 'post')
export const categoryList = functionGenerator('/api/generic/details/categories', 'post')
export const signedStaffImageUrlUpload = functionGenerator('/api/s3/imagesignedurlstaff', 'post')
export const searchRecords = functionGenerator('/api/search', 'post')
export const emailPOCustomers = functionGenerator('/api/pricing/option/pricingOptionMessage', 'post')
export const emailPOMassCustomers = functionGenerator('/api/pricing/option/pricingOptionMultiMessage', 'post')
export const emailClassCustomers = functionGenerator('/api/group/classMessage', 'post')
export const emailServiceCustomers = functionGenerator('/api/service/serviceMessage', 'post');
export const pricingOptionSignAgreement = functionGenerator('/api/pricing/option/pricingOptionSignAgreement', 'post');
export const signOrderAgreement = functionGenerator('/api/customerorders/signOrderAgreement', 'post');
export const emailAllCustomers = functionGenerator('/api/customers/sendMessageToAllCustomers', 'post');
export const reverseGeoCode = async (payload) => {
    try {
        let response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?place_id=${payload.placeId}&key=${process.env.REACT_APP_GOOGLE_KEY}`
        )
        if (response.data.status === "OK") {
            return response.data.results[0]
        }
        return null
    }
    catch (error) {
        return null
    }
}
export const geoCodeLatLng = async (payload) => {
    try {
        let response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${payload.lat},${payload.lng}&key=${process.env.REACT_APP_GOOGLE_KEY}`
        )
        if (response.data.status === "OK") {
            return response.data.results[0]
        }
        return null
    }
    catch (error) {
        return null
    }
}
export const searchDetails = functionGenerator('/api/search/details', 'post')
export const offeringReviews = functionGenerator('/api/search/listreviews', 'post')
export const getAccountBalanceDetails = functionGenerator('/api/businesspayouts/balanceinfo', 'post')
export const getWebLinkList = functionGenerator('/api/business/weblinks', 'post')
export const getMyBusinesses = functionGenerator('/api/users/listmyproviders', 'post')
export const myBusinessDetails = functionGenerator('/api/users/myproviderdetails', 'post')
export const checkoutCard = functionGenerator('/api/purchases/checkout', 'post')
export const listDisputes = functionGenerator('/api/disputes/list', 'post')
export const disputeDetails = functionGenerator('/api/disputes/disputeDetails', 'post')
export const addDispute = functionGenerator('/api/disputes/addfile', 'post')
export const customerPrivateSlots = functionGenerator('/api/availabilities/slots', 'post')
export const customerGroupSlots = functionGenerator('/api/availabilities/classes', 'post')
export const bookOrder = functionGenerator('/api/purchases/book', 'post')
export const downloadDispute = functionGenerator('/api/disputes/downloadFile', 'post')
export const deleteDispute = functionGenerator('/api/disputes/removeFile', 'delete')
export const submitDispute = functionGenerator('/api/disputes/submit', 'post')
export const signedAdminImageUrlUpload = functionGenerator('/api/s3/imagesignedurl', 'post')
export const saveS3Image = functionGenerator('https://s3.us-west-1.amazonaws.com/caritra-upgrade-docs', 'post')
export const updateBusinessImage = functionGenerator('/api/business/profile', 'post')
export const adminPackSearch = functionGenerator('/api/businesspacks/packdetailsnumber', 'post')
export const staffPackSearch = functionGenerator('/api/staffpacks/packdetailsnumber', 'post')
export const updateDispute = functionGenerator('/api/disputes/updateDispute', 'patch')
export const updateService = functionGenerator('/api/service/update', 'patch')
export const listNotifications = functionGenerator('/api/business/listNotifications', 'post')
export const notificationCount = functionGenerator('/api/business/notificationCount', 'post')
export const addServiceImage = functionGenerator('/api/service/addServiceImage', 'post')
export const deleteServiceImage = functionGenerator('/api/service/deleteServiceImage', 'delete')
export const listOperatingUnits = functionGenerator('/api/generic/details/operatingunits', 'post')
export const cOBusinessInfo = functionGenerator('/api/business/obinfo', 'post')
export const cOBusinessInfoAuth = functionGenerator('/api/business/obinfoauth', 'post')
export const onboardCustomer = functionGenerator('/api/users/accept/onboard', 'post')
export const recommendedBusiness = functionGenerator("/api/search/recommendedbusiness", 'post')
export const businessSinglePage = functionGenerator("/api/singlepage", "post")
export const caritraAgreement = functionGenerator('/api/business/businessPricePlan', 'post')
export const getDocument = functionGenerator('/api/caritradocs', 'post')
export const listWidgets = functionGenerator('/api/widgets/list', 'post')
export const widgetStatus = functionGenerator('/api/widgets/widgetStatus', 'post')
export const updateWidget = functionGenerator('/api/widgets/update', 'patch')
export const deleteWidget = functionGenerator('/api/widgets/delete', 'delete')
export const addWidget = functionGenerator('/api/widgets/add', 'post')
export const listAddOns = functionGenerator('/api/addon/list', 'post')
export const addOnStatus = functionGenerator('/api/addon/addOnStatus', 'post')
export const updateAddOn = functionGenerator('/api/addon/update', 'patch')
export const addAddOn = functionGenerator('/api/addon/add', 'post')
export const assignAddon = functionGenerator('/api/pricing/option/addaddons', 'post')
export const removeAddon = functionGenerator('/api/pricing/option/removeaddons', 'delete')
export const classListFS = functionGenerator('/api/pricing/option/seasonPassClasses', 'post')
export const addFS = functionGenerator('/api/pricing/option/seasonPassAddClasses', 'post')
export const delFS = functionGenerator('/api/pricing/option/seasonPassRemoveClasses', 'delete')
export const deleteSite = functionGenerator('/api/site/deleteSite', 'delete')
export const deleteService = functionGenerator('/api/service/deleteService', 'delete')
export const deleteOption = functionGenerator('/api/pricing/option/deleteOption', 'delete')
export const deleteDiscount = functionGenerator('/api/discounts/deleteDiscount', 'delete')
export const deleteQualification = functionGenerator('/api/qualifications/deleteQualification', 'delete')
export const deleteMembership = functionGenerator('/api/memberships/deleteMembership', 'delete')
export const deleteQuestion = functionGenerator('/api/questions/deleteQuestion', 'delete')
export const deleteAgreement = functionGenerator('/api/agreements/deleteAgreement', 'delete')
export const deleteAddOn = functionGenerator('/api/addon/deleteAddOn', 'delete')
export const installmentOption = functionGenerator('/api/pricing/option/installmentOption', 'post')
export const cancelInstallmentPayment = functionGenerator('/api/businessorders/cancelPlanPayment', 'post')
export const makePayment = functionGenerator('/api/customerorders/payFailedPayment', 'post')
export const refundTransaction = functionGenerator('/api/businessorders/refundorder', 'post')
export const changePaymentMethod = functionGenerator('/api/customerorders/changeCardOnFile', 'post')
export const editClassCap = functionGenerator('/api/group/editClassCapacity', 'post')
export const editFSCap = functionGenerator('/api/pricing/option/editClassCapacityFS', 'post')
export const cancelAddon = functionGenerator('/api/addon/cancelAddOn', 'post')
export const getAnalyticsInfo = functionGenerator('/api/analytics/ad/info', 'post')
export const setFacebookID = functionGenerator('/api/analytics/ad/fbPixel', 'post')
export const removeFacebookID = functionGenerator('/api/analytics/ad/fbPixel', 'delete')
export const setGoogleID = functionGenerator('/api/analytics/ad/gaTracking', 'post')
export const removeGoogleID = functionGenerator('/api/analytics/ad/gaTracking', 'delete')
export const changeEmail = functionGenerator('/api/users/changeemail', 'post')
export const listReports = functionGenerator('/api/reports/list', 'post')
export const getReportSchema = functionGenerator('/api/reports/getReportSchema', 'post')
export const generateReport = functionGenerator('/api/reports/generateReport', 'post')
export const getTrendingPrograms = functionGenerator('/api/search/recentlyBooked', 'post')
export const businessInquiry = functionGenerator('/api/businessinquirie/newInquirie', 'post')
export const pricingOfflineBooking = functionGenerator('/api/pricing/option/changeBookingType', 'post')
export const editManualPayment = functionGenerator('/api/businessorders/editManualPayment', 'patch')
export const createManualPayment = functionGenerator('/api/businessorders/captureManualPayment', 'post')
export const removeManualPayment = functionGenerator('/api/businessorders/deleteManualPayment', 'delete')
export const customerAttendeeList = functionGenerator('/api/attendee/listAttendees', 'post')
export const customerCreditList = functionGenerator('/api/customer/credit/listCreditByBusiness', 'post')
export const createAttendee = functionGenerator('/api/attendee/addAttendee', 'post')
export const editAttendee = functionGenerator('/api/attendee/updateAttendee', 'patch')
export const customerAttendeeDetails = functionGenerator('/api/attendee/attendeeDetails', 'post')
export const customerBusinessCreditDetails = functionGenerator('/api/customer/credit/listBusinessCredits', 'post')
export const businessCustomerCreditDetails = functionGenerator('/api/customers/listCustomerCredits', 'post')
export const adminAttendeeList = functionGenerator('/api/customers/attendee/list', 'post')
export const adminAttendeeDetails = functionGenerator('/api/customers/attendee/details', 'post')
export const signedCustomerImageUrlUpload = functionGenerator('/api/s3/imagesignedurlcustomer', 'post')
export const listTemplates = functionGenerator('/api/template/listTemplates', 'post')
export const createTemplate = functionGenerator('/api/template/createTemplate', 'post')
export const editTemplate = functionGenerator('/api/template/editTemplate', 'patch')
export const getTemplateDetails = functionGenerator('/api/template/templateDetails', 'post')
export const changeTemplateStatus = functionGenerator('/api/template/templateStatus', 'post')
export const removeTemplate = functionGenerator('/api/template/deleteTemplate', 'delete')
export const addTemplateField = functionGenerator('/api/template/addField', 'post')
export const deleteTemplateField = functionGenerator('/api/template/deleteField', 'delete')
export const getResourceList = functionGenerator('/api/resource/listResources', 'post')
export const createResource = functionGenerator('/api/resource/createResource', 'post')
export const getResourceDetails = functionGenerator('/api/resource/resourceDetails', 'post')
export const setResourceStatus = functionGenerator('/api/resource/resourceStatus', 'post')
export const editResourceDetails = functionGenerator('/api/resource/editResource', 'patch')
export const deleteResourceDetails = functionGenerator('/api/resource/deleteResource', 'delete')
export const addSessionResource = functionGenerator('/api/businesssessions/addsessionresources', 'post')
export const removeSessionResource = functionGenerator('/api/businesssessions/removesessionresources', 'post')
export const addClassResource = functionGenerator('/api/group/addclassresources', 'post')
export const removeClassResource = functionGenerator('/api/group/removeclassresources', 'post')
export const resourceOrder = functionGenerator('/api/businesscalendar/getResources', 'post')
export const resourceCalendarEvents = functionGenerator('/api/businesscalendar/resourceevents', 'post')
export const getCalendarPrefs = functionGenerator('/api/preferences/getPreferences', 'post')
export const updateCalendarPrefs = functionGenerator('/api/preferences/calendarPreferences', 'patch')
export const availableOptions = functionGenerator('/api/businessorders/availableChangeOptions', 'post')
export const changeOption = functionGenerator('/api/businessorders/changeOrderOption', 'post')
export const dashboardPreferences = functionGenerator('/api/preferences/getPreferences', 'post')
export const staffCalOrder = functionGenerator('/api/businesscalendar/getResourcesStaff', 'post')
export const staffRCalendarEvents = functionGenerator('/api/businesscalendar/staffevents', 'post')
export const massEditGroupClass = functionGenerator('/api/group/massEditClass', 'post')
export const getInternalEventsListAdmin = functionGenerator('/api/internalevents/eventlist', 'post')
export const getInternalEventsListStaff = functionGenerator('/api/staffinternalevents/eventlist', 'post')
export const getInternalEventsDetailAdmin = functionGenerator('/api/internalevents/eventdetails', 'post')
export const createInternalEvents = functionGenerator('/api/internalevents/addEvents', 'post')
export const editInternalEvent = functionGenerator('/api/internalevents/editEvent', 'patch')
export const editInternalSeries = functionGenerator('/api/internalevents/editEventSeries', 'patch')
export const cancelInternalEvent = functionGenerator('/api/internalevents/cancelevent', 'delete')
export const cancelInternalSeries = functionGenerator('/api/internalevents/cancelseries', 'delete')
export const addStaffInternalSeries = functionGenerator('/api/internalevents/addstaffsseries', 'post')
export const addStaffInternalEvent = functionGenerator('/api/internalevents/addstaffsevent', 'post')
export const removeStaffInternalSeries = functionGenerator('/api/internalevents/removestaffsseries', 'delete')
export const removeStaffInternalEvent = functionGenerator('/api/internalevents/removestaffsevent', 'delete')
export const addResourceInternalSeries = functionGenerator('/api/internalevents/addresourcesseries', 'post')
export const addResourceInternalEvent = functionGenerator('/api/internalevents/addresourcesevent', 'post')
export const removeResourceInternalSeries = functionGenerator('/api/internalevents/removeresourcesseries', 'delete')
export const removeResourceInternalEvent = functionGenerator('/api/internalevents/removeresourcesevent', 'delete')
export const rescheduleInternalEvent = functionGenerator('/api/internalevents/rescheduleevent', 'post')
export const getInternalEventsDetailStaff = functionGenerator('/api/staffinternalevents/eventdetails', 'post')
export const listOfCheckInSites = functionGenerator('/api/sitecheckins/getCheckInSites', 'post')
export const listOfCheckInSessions = functionGenerator('/api/sitecheckins/getCheckInSessions', 'post')
export const performCheckIn = functionGenerator('/api/sitecheckins/performCheckIn', 'post')
export const performCheckOut = functionGenerator('/api/sitecheckins/performCheckOut', 'post')
export const performCheckInClear = functionGenerator('/api/sitecheckins/clearCheckInData', 'post')
export const addQualificationToAttendee = functionGenerator('/api/customers/addattendeequalifitations', 'post')
export const removeQualificationFromAttendee = functionGenerator('/api/customers/removeattendeequalifications', 'delete')
export const customerSearch = functionGenerator('/api/customers/customersearch', 'post')
export const attendeeSearch = functionGenerator('/api/customers/attendee/attendeesearch', 'post')
export const createCatalog = functionGenerator('/api/pricing/option/catalog/create', 'post')
export const editCatalog = functionGenerator('/api/pricing/option/catalog/edit', 'patch')
export const deleteCatalog = functionGenerator('/api/pricing/option/catalog/delete', 'delete')
export const listCatalog = functionGenerator('/api/pricing/option/catalog/list', 'post')
export const catalogDetails = functionGenerator('/api/pricing/option/catalog/details', 'post')
export const editCatalogStatus = functionGenerator('/api/pricing/option/catalog/status', 'post')
export const attachCatalogsToOption = functionGenerator('/api/pricing/option/catalog/attachCatalogToPO', 'post')
export const detachCatalogsFromOption = functionGenerator('/api/pricing/option/catalog/removeCatalogFromPO', 'post')
export const attachOptionsToCatalog = functionGenerator('/api/pricing/option/catalog/attachPOToCatalog', 'post')
export const detachOptionsFromCatalog = functionGenerator('/api/pricing/option/catalog/removePOFromCatalog', 'post')
export const attachPriceGroupToCatalog = functionGenerator('/api/pricing/option/catalog/attachPGToCatalog', 'post')
export const detachPriceGroupFromCatalog = functionGenerator('/api/pricing/option/catalog/removePGFromCatalog', 'post')
export const createPriceGroup = functionGenerator('/api/pricing/option/pricegroup/create', 'post')
export const editPriceGroup = functionGenerator('/api/pricing/option/pricegroup/edit', 'patch')
export const priceGroupList = functionGenerator('/api/pricing/option/pricegroup/list', 'post')
export const priceGroupDetails = functionGenerator('/api/pricing/option/pricegroup/details', 'post')
export const deletePriceGroup = functionGenerator('/api/pricing/option/pricegroup/delete', 'delete')
export const assignPriceGroupToOptions = functionGenerator("/api/pricing/option/pricegroup/attachPOToPG", 'post')
export const removePriceGroupFromOptions = functionGenerator("/api/pricing/option/pricegroup/removePOFromPG", 'post')
export const attachOptionToPriceGroup = functionGenerator('/api/pricing/option/pricegroup/attachPGToPO', 'post')
export const detachOptionFromPriceGroup = functionGenerator('/api/pricing/option/pricegroup/removePGFromPO', 'post')
export const editSinglePageType = functionGenerator("/api/business/setSinglePageType", 'post')
export const creditDebitTransaction = functionGenerator("/api/customers/amount/transaction", 'post')
export const editAdditionalInfo = functionGenerator("/api/customers/amount/additionalInfo", 'patch')
export const getFormTemplateList = functionGenerator('/api/formtemplate/listFormTemplates', 'post')
export const deleteFormTemplate = functionGenerator("/api/formtemplate/deleteFormTemplate", 'delete')
export const createFormTemplate = functionGenerator("/api/formtemplate/addFormTemplate", 'post')
export const editFormTemplate = functionGenerator('/api/formtemplate/editFormTemplate', 'patch')
export const getFormTemplateListStaff = functionGenerator("/api/formtemplate/listFormTemplatesStaff", 'post')
export const submitFeedback = functionGenerator('/api/dashboard/feedback/giveFeedbackAdmin', 'post')
export const submitFeedbackStaff = functionGenerator('/api/dashboard/feedback/giveFeedbackStaff', 'post')
export const getCustomerFeedback = functionGenerator('/api/account/feedback/listCustomerFeedbacks', 'post')