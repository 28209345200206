import { Container, makeStyles, useMediaQuery } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux"
import { useHistory } from 'react-router'
import maxTextField from '../../../FormValidation/maxTextField'
import notEmpty from '../../../FormValidation/notEmpty'
import noWhiteSpace from '../../../FormValidation/noWhiteSpace'
import validPhoneNumber from '../../../FormValidation/phonenumber'
import validEmail from '../../../FormValidation/validEmail'
import { dashboardList, listOperatingUnits, registerBusiness, withLoading } from '../../../utils'
import Form from '../../Form/Form'



const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "550px",
    paddingTop: '40px',
    paddingBottom: '40px'
  },
}));

const RBForm = (props) => {
  const classes = useStyles()
  const [operatingUnits, setOperatingUnits] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const desktop = useMediaQuery('(min-width: 750px)')
  const setUp = async () => {
    let data = await withLoading(listOperatingUnits, {});
    if (data && data.operatingUnitDetails) {
      setOperatingUnits(
        data.operatingUnitDetails.map(operatingUnitData => [operatingUnitData.currencyLongName, operatingUnitData.operatingUnitId])
      )
    }
  }



  useEffect(() => {
    setUp();
  }, []);

  return (
    <div style={{ minHeight: "calc(100vh - 64px)", width: "100vw", backgroundColor: "#F7FAFC", display: 'flex', alignItems: desktop ? 'center' : undefined }}>
      <Container component="main" className={classes.container}>
        <div style={{ border: "1px solid #e9eced", padding: "20px", backgroundColor: "#FFFFFF" }}>
          <Form
            titleCenter
            title={"Register Business"}
            submitText="Register"
            action={registerBusiness}
            postAction={async (response) => {
              if (response) {
                let newDash = await withLoading(dashboardList, {});
                if (newDash) {
                  dispatch({
                    type: "UPDATE",
                    payload: {
                      dashboards: newDash
                    }
                  })
                  history.push(`/dashboard/${response.businessId}/Business-Status`)
                }

              }
            }}
            inputFields={[
              {
                type: "TextField",
                name: "name",
                label: "Name",
                validator: [notEmpty, noWhiteSpace, maxTextField]
              },
              {
                type: "TextField",
                name: "email",
                label: "Email",
                validator: [notEmpty, validEmail]
              },
              {
                type: "PhoneNumber",
                name: "phoneNumber",
                label: "Phone Number",
                validator: validPhoneNumber,
              },
              {
                type: "SingleSelect",
                name: "operatingUnitId",
                label: "Operating Currency",
                choices: operatingUnits,
                validator: value => !value ? "Must select an operating currency" : null
              },
              {
                type: "TextArea",
                name: "about",
                label: "About",
                validator: noWhiteSpace,
                resizeEnabled: true,
                moreProps: {
                  rowsMin: 4,
                  rowsMax: 8
                }
              },
              {
                type: "CheckBox",
                name: "tos",
                label:
                  <span>By clicking the box, I agree to <a target="_blank" href={`${window.location.origin}/document/csptou`} rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                    Caritra Service Provider Agreement
                  </a> and <a target="_blank" href={`${window.location.origin}/document/csppt`} rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                      Pricing Agreement
                    </a>
                  </span>,
                validator: (value) => { if (value === false) { return ("Please agree to Terms and Conditions") } }
              }
            ]}

          />
        </div>
      </Container>
    </div>
  )
}

export default RBForm;
