import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { cancelInternalEvent, cancelInternalSeries } from '../../../../../../utils';
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto"

    }
}))

const CancelInternalEventForm = (props) => {

    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()

    return (
        <React.Fragment>
            <CModal open={props.open} setOpen={props.setOpen}>
                <Container component="main" className={classes.formContainer}>
                    <Form
                        moreData={{
                            businessId: businessId,
                            eventId: props.internalEventDetails.eventId,
                            editTag: props.internalEventDetails.editTag,
                            seriesId: props.internalEventDetails.seriesId
                        }}
                        title="Cancel Internal Event(s)"
                        cancelEnabled
                        cancelAction={() => { props.setOpen(false) }}
                        submitText="Confirm"
                        action={async (payload) => {
                            const finalPayload = {
                                businessId,
                                eventId: props.internalEventDetails.eventId,
                                editTag: props.internalEventDetails.editTag,
                                seriesId: props.internalEventDetails.seriesId,
                            }

                            if (payload.applyAll === true) {
                                return await cancelInternalSeries(finalPayload)
                            }
                            else {
                                return await cancelInternalEvent(finalPayload)
                            }
                        }}
                        postAction={(res) => {
                            if (res && !res.error) {
                                props.setOpen(false)
                                props.fetchInternalEventDetails()
                            }
                        }}
                        inputFields={[
                            {
                                type: "Custom",
                            },
                            {
                                type: "CheckBox",
                                name: "applyAll",
                                label: "Cancel all events in this series",
                                enabled: props.internalEventDetails.seriesId !== null
                            },

                        ]}
                        comp={
                            <Grid item xs={12}>
                                <Typography>
                                    Are you sure you want to cancel this internal event?
                                </Typography>
                            </Grid>
                        }
                    />
                </Container>
            </CModal>
        </React.Fragment>
    )
}

export default CancelInternalEventForm