import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { staffSessionsList, withLoading, retrieveGetParams, encodeGetParams } from "../../../../../../utils";
import { Grid, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import ListTable from "./../../../../../TableComponents/ListTable";
import ListTableNavigation from "./../../../../../TableComponents/ListTableNavigation";
import StatusTag from "./../../../../../TagComponents/StatusTag";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Filter from "../../../../../Filter/Filter";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none"
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  cardBasic: {
    backgroundColor: "#ffffff",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    height: 70,
    paddingTop: "10px",
    backgroundColor: "#ffffff"
  }
}));

const isUTCPassed = (utcTime) => {
  const curMoment = new Date()
  const refMomemt = new Date(utcTime)
  return curMoment > refMomemt
};

const StaffSessionList = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width: 750px)');
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const sessionHeaders = [
    {
      name: "session #",
      columnKey: "sessionNumber",
      columnWidth: "100px",
    },
    {
      name: "status",
      columnKey: "status",
      columnWidth: "100px",
      type: "Normal"
    },
    {
      name: "attendee",
      columnKey: "attendeeName",
      columnWidth: "25%",
      tooltip: true
    },

    {
      name: "service",
      columnKey: "serviceName",
      columnWidth: "25%",
      tooltip: true
    },
    {
      name: "site",
      columnKey: "siteName",
      columnWidth: "25%",
      tooltip: true
    },
    {
      name: "start time",
      columnKey: "startTimeTZ",
      columnWidth: "25%",
      tooltip: true
    },

  ];
  const currentLimit = 20
  const location = useLocation()
  const [sessionList, setSessionList] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const queryParams = retrieveGetParams(location)
  const currentPage = queryParams.pg ? Number(queryParams.pg) : 1
  const service = queryParams.service
  const site = queryParams.site
  const start = queryParams.start
  const end = queryParams.end
  const cId = queryParams.cId
  const status = queryParams.status

  const processSessionData = (sessionData) => {
    return sessionData.map((session) => {
      let {
        sessionId,
        sessionNumber,
        serviceName,
        siteName,
        attendeeFirstName,
        attendeeLastName,
        startDate,
        startTime,
        timeZone,
        isCanceled,
        startDateUTC,
      } = session;

      let id = sessionId;
      let status = null
      if (isCanceled === true) {
        status = <StatusTag wrapperName={'redWrapper'} value={'Canceled'} />
      }
      else {
        if (isUTCPassed(startDateUTC) === true) {
          status = <StatusTag wrapperName={'grayWrapper'} value={'Completed'} />
        }
        else {
          status = <StatusTag wrapperName={'greenWrapper'} value={'Scheduled'} />
        }
      }
      let attendeeName = `${attendeeFirstName} ${attendeeLastName}`;
      let startTimeTZ = `${startDate} ${startTime} (${timeZone})`;

      return {
        id,
        sessionNumber,
        isCanceled,
        startTimeTZ,
        attendeeName,
        startDate,
        startTime,
        timeZone,
        serviceName,
        siteName,
        startDateUTC,
        status,
      };
    });
  };

  const getSessionsDetails = async () => {
    let sessionsPayload = await staffSessionsList({
      businessId: businessId,
      limit: currentLimit,
      page: currentPage,
      serviceIdList: service ? [service] : undefined,
      siteList: site ? [site] : undefined,
      startDate: start,
      endDate: end,
      customerIdList: cId ? [cId] : undefined,
      statusOption:status
    });
    if (sessionsPayload && sessionsPayload.sessions) {
      setSessionList(processSessionData(sessionsPayload.sessions.docs));
      setTotalPages(sessionsPayload.sessions.totalPages);
      setTotalDocs(sessionsPayload.sessions.totalDocs);
      setHasNext(sessionsPayload.sessions.hasNextPage);
      setHasPrevious(sessionsPayload.sessions.hasPrevPage);
    }
    else {
      setSessionList(null);
      setTotalPages(0);
      setTotalDocs(0);
      setHasNext(false);
      setHasPrevious(false);
    }
  };

  const navigateToSession = (sessionId) => {
    history.push(`/dashboard/${businessId}/Sessions/${sessionId}`);
  };

  const ViewChanger = () => {
    if (matches) {
      return (
        <ListTable
          identifier={"admin_orders"}
          tableHeaders={sessionHeaders}
          tableRows={sessionList}
          rowClick={navigateToSession}
        />
      );
    } else {
      return (
        sessionList.map((session) => {
          return (
            <div className={classes.cardBasic} style={{ paddingTop: "10px", paddingBottom: "15px" }} onClick={() => { navigateToSession(session.id) }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography className={classes.valueTextBold} noWrap>
                    #{session.sessionNumber}
                  </Typography>
                  <Typography className={classes.valueText} noWrap>
                    <b>{session.attendeeName}</b>
                  </Typography>
                  <Typography className={classes.valueText} noWrap>
                    <b>Date: </b>{session.startDate}
                  </Typography>
                  <Typography className={classes.valueText} noWrap>
                    <b>Time: </b>{session.startTime} {session.timeZone}
                  </Typography>
                </Grid>
                <Grid item xs={6}>

                  <Typography className={classes.valueText} noWrap style={{ paddingTop: "14px" }}>
                    <b>Service:</b> {session.serviceName}
                  </Typography>
                  <Typography className={classes.valueText} noWrap>
                    <b>Site:</b> {session.siteName}
                  </Typography>

                </Grid>
                <Grid item xs={12}>
                  {(session.isCanceled
                    ?
                    <Typography
                      noWrap
                      style={{ fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "red" }}>
                      Canceled
                    </Typography>

                    : null)}
                  {(!session.isCanceled && isUTCPassed(session.startDateUTC)
                    ?
                    <Typography
                      noWrap
                      style={{ fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "gray" }}>
                      Completed
                    </Typography>

                    : null)}

                  {(!session.isCanceled && !isUTCPassed(session.startDateUTC)
                    ?
                    <Typography
                      noWrap
                      style={{ fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "green" }}>
                      Scheduled
                    </Typography>

                    : null)}
                </Grid>

              </Grid>
            </div>
          )
        })
      );
    };
  };

  const getNextPage = () => {
    if (hasNext) {
      history.push(location.pathname + '?' + encodeGetParams({ ...queryParams, pg: currentPage + 1 }))

    }
  };

  const getPrevPage = () => {
    if (hasPrevious) {
      history.push(location.pathname + '?' + encodeGetParams({ ...queryParams, pg: currentPage - 1 }))
    }
  };

  useEffect(async () => {
    await withLoading(getSessionsDetails, {}, true)
  }, [currentPage, service, site, start, end, cId, status]);

  if (sessionList !== null) {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12} className={matches ? null : classes.stickyHeader}>
          <div>
            <span className={classes.topHeading}>Sessions</span>
            <Filter businessId={businessId} filterFields={['service', 'site', 'dateRange', 'customer', 'status']} staff />
          </div>
        </Grid>
        {sessionList.length > 0 ?
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <ViewChanger />
            </div>
          </Grid> : <EmptyListImage name="Sessions" />}
        {sessionList.length > 0 ?
          <Grid item xs={12}>
            <ListTableNavigation
              currentPage={currentPage}
              currentLimit={currentLimit}
              totalDocs={totalDocs}
              tableRows={sessionList}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              getPrevPage={getPrevPage}
              getNextPage={getNextPage}
            />
          </Grid> : null}
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  }
  else {
    return null
  }
};

export default StaffSessionList;
