import { Container, makeStyles } from "@material-ui/core"
import { createAttendee, editAttendee, withLoading } from "../../../../utils"
import CModal from "../../../Form/CModal"
import Form from "../../../Form/Form"
import { generateAttendeeFormFields, generateFilteredPayload } from "./attendeeFormFields"

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100%",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))


const AttendeeForm = (props) => {
    const { reloadPage, redirect, attendee, open, setOpen } = props
    const classes = useStyles()
    return (
        <CModal open={open} setOpen={setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    moreData={{
                        attendeeId: attendee?.attendeeId
                    }}
                    title={attendee ? "Edit Attendee" : "Add Attendee"}
                    cancelEnabled
                    cancelAction={() => { setOpen(false) }}
                    submitText={attendee ? "Edit" : "Add"}
                    action={async (payload) => {
                        const filteredPayload = generateFilteredPayload(payload, attendee)
                        if (attendee) {
                            return await withLoading(editAttendee, filteredPayload)
                        }
                        else {
                            return await withLoading(createAttendee, filteredPayload)
                        }
                    }}
                    postAction={(response) => {
                        if (response && !response.error) {
                            if (attendee) {
                                // Close and reload details page
                                setOpen(false)
                                reloadPage()
                            }
                            else {
                                // Close and redirect
                                setOpen(false)
                                redirect(response.attendeeId)
                            }
                        }
                    }}
                    inputFields={generateAttendeeFormFields(attendee)}
                />
            </Container>
        </CModal>
    )
}

export default AttendeeForm