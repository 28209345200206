import { Button, Container, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, errorInfo) {}

  render() {
    if (this.state.hasError) {
      return (
        <Container
          style={{
            maxWidth: 600,
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container style={{borderColor: "#e9eced" , backgroundColor: "#f7fafc" , padding: 30 , borderRadius: 4}}>
            <Grid item xs={12}>
              <img
                style={{
                  height: 200,
                  width: "100%",
                  objectFit: "contain",
                  margin: "0 auto",
                }}
                src={
                  "https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/error-logo/error-any.png"
                }
                alt="smth"
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
              <Typography
                style={{
                  whiteSpace: "normal",
                  fontSize: 25,
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Unfortunately, something has gone wrong.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
              <Typography style={{ whiteSpace: "normal", textAlign: "center" }}>
                We're unable to fulfill your request. Rest assured we have been
                notified and are looking into the issue. Please refresh your
                browser. If the error continues, please contact our support
                team at <a style={{ color: "#0079fc" }} href={"mailto:contact@caritra.com"}>contact@caritra.com</a>.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
            >
              <Button color="primary" variant="contained" href="/">
                Go To Home
              </Button>
            </Grid>
          </Grid>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
