import React, { useState, useEffect } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import parse from 'html-react-parser';
import { withLoading, getDocument } from '../../utils';
const Documents = (props) => {
    const { documentName } = useParams()
    const [document, setDocument] = useState(null)
    const setUp = async () => {
        let data = await withLoading(getDocument, {documentCode:documentName}, true);
        if(data && data.document){
            setDocument(data)
        }
    }
    useEffect(() => {
        if (["cctou", "cpp", "cccprn", "csppt", "csptou"].includes(documentName)) {
            setUp()
        }
    }, [documentName])

    if(document){
        if(document.document){
            return(
                <div style = {{padding:30, maxWidth:1440, margin:"50px auto", backgroundColor :"#F7FAFC"}}>
                    {parse(document.document)}
                </div>
            )
        }
        else{
            return <Redirect to = "/404"/>
            
        }
    }
    else{
        return null
    }


};

export default Documents