import React, { useState, useEffect } from 'react'
import { useMediaQuery, Drawer, makeStyles, Toolbar, IconButton, Radio, Grid, Collapse, Button } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router'
import filters from './filter'
import FilterListIcon from '@material-ui/icons/FilterList';
import { retrieveGetParams, safePush, encodeGetParams } from '../../utils';
import { Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const drawerWidth = "230px"

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        paddingLeft: 10,
        paddingTop: 10
    },
    icon: {
        color: theme.palette.primaryText.main
    },
    iconButton: {

        position: "fixed",
        zIndex: theme.zIndex.drawer + 2,
        top: 68,
        left: 7
    },
    filterContainer: {
        padding: 5,
        paddingLeft: 10
    },
    formLabel: {
        fontSize: 16,
        color: theme.palette.primaryText.main,
        paddingBottom: 5,
    },
    fieldText: {
        fontSize: 14,
        color: theme.palette.primaryText.main,
    },
    headerText: {
        color: theme.palette.primaryText.main,
        paddingTop: 10,
        fontSize: 18
    },
    noPadding: {
        padding: 0
    },
    radioLabel: {
        width: "calc(100% - 34px)",
        paddingLeft: 10
    },
    openIcon: {
        color: theme.palette.primaryText.main,
        paddingBottom: 5,
        fontWeight: "bold"
    }
}))




const MPFilter = (props) => {
    const [open, setOpen] = useState(false)
    const [filterChoices, setFilterChoices] = useState(null)
    const history = useHistory()
    const location = useLocation(props)
    const bigEnough = useMediaQuery("(min-width: 750px)")
    const classes = useStyles()
    useEffect(() => {
        const tempChoices = []
        const currentParams = retrieveGetParams(location)
        filters.forEach((filter) => {
            tempChoices.push(currentParams[filter.key])
        })
        setFilterChoices(tempChoices)
    }, [location.search, filters])

    useEffect(() => {
        setOpen(false)
    }, [location])


    return (
        <div style={{ display: 'flex' }}>

            <Drawer
                className={classes.drawer}
                open={open}
                onClose={() => { setOpen(false) }}
                variant={bigEnough ? "permanent" : 'temporary'}
                classes={{
                    paper: classes.drawer
                }}
            >
                {(bigEnough ? <Toolbar /> : null)}
                {filterChoices !== null ?
                    filters.map((filter, index) => (
                        <CreateFilter
                            filter={filter}
                            key={index}
                            value={filter.dataParser ? filter.dataParser(filterChoices[index]) : filterChoices[index]}
                            history={history}
                            location={location}
                        />
                    ))
                    : null}
            </Drawer>
            {!bigEnough ?
                <IconButton className={classes.iconButton} onClick={() => { setOpen(true) }}>
                    <FilterListIcon />
                </IconButton>
                : null}
        </div>
    )

}

const updateLocation = (newParams, location, history) => {
    const currentParams = retrieveGetParams(location)
    const finalParams = { ...currentParams, ...newParams, pn: undefined }
    safePush(`/Market-Place?` + encodeGetParams(finalParams), history, location)
}

const CreateFilter = (props) => {
    const classes = useStyles()
    const [open, setOpen] = useState(true)
    let comp = null
    useEffect(() => {
        if (props.value) {
            setOpen(true)
        }
    }, [props.value])
    switch (props.filter.type) {
        case "Radio":
            comp = (
                <React.Fragment>
                    {props.filter.options.map((option, index) => (
                        <Grid key={`${option.key}-${index}`} style = {{paddingTop:5}}>
                            <Radio
                                className={classes.noPadding}
                                onClick={() => {
                                    const data = {}
                                    data[props.filter.key] = option.val
                                    updateLocation(data, props.location, props.history)
                                }}
                                checked={option.val === props.value}
                                color="primary"
                            />
                            {
                                option.labelType === "Normal" ?
                                    option.label
                                    :
                                    <Typography className={`${classes.fieldText} ${classes.radioLabel}`} display="inline">
                                        {option.label}
                                    </Typography>
                            }

                        </Grid>
                    ))}
                </React.Fragment>
            )
            break
        case "PairButtons":
            comp = (
                <Grid container spacing={2} style={{ padding: 8, width: "100%", paddingTop:13 }}>
                    {props.filter.options.map((option, index) => (
                        <Grid item xs={6} key={`${option.key}-${index}`} >
                            <Button
                                fullWidth
                                onClick={() => {
                                    const data = {}
                                    data[props.filter.key] = option.val
                                    updateLocation(data, props.location, props.history)
                                }}
                                variant={option.val === props.value ? "contained" : "outlined"}
                                color={option.val === props.value ? "primary" : "inherit"}
                                style={{ padding: 2 }}
                            >
                                <Typography className={`${classes.fieldText}`} noWrap style={{ textTransform: "none", ...(option.val === props.value ? { color: "white" } : {}) }}>
                                    {option.label}
                                </Typography>
                            </Button>

                        </Grid>
                    ))
                    }
                </Grid >
            )
            break
        default:
    }


    return (
        <div className={classes.filterContainer} style = {{paddingBottom:20}}>
            <div style={{ display: "inline-flex" }}>
                <Typography className={classes.formLabel} display="inline" >
                    {props.filter.title}
                </Typography>
            </div>
            {comp}
        </div>
    )
}


export default MPFilter


