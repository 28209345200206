import FormHelperText from "@material-ui/core/FormHelperText";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  comp: {
    width: "100%",
  },
  tArea: {
    boxSizing: "border-box",
    borderRadius: "4px",
    color: "#1a1f36",
    borderColor: "#c0c0c0",
    fontFamily: "Rubik, sans-serif",
    fontSize: "16px",
    background: "transparent",
    width: "100%",
    padding: "10px",
    "&:focus": {
      outlineColor: theme.palette.primary.main,
    },
  },
  resize: {
    resize: "vertical",
  },
  noResize: {
    resize: "none",
  },
  formLabel: {
    fontSize: 14,
    color: theme.palette.textColor2.main,
  },
  helperText: {
    marginRight: 14,
    marginLeft: 14,
  },
}));

const CTextArea = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.comp}>
      <Typography className={classes.formLabel}>{props.label}</Typography>
      <TextareaAutosize
        defaultValue={props.initialvalue}
        name={props.name}
        onChange={(event) => {
          props.onchange(event);
        }}
        className={`${classes.tArea} ${
          props.resizeEnabled === true ? classes.resize : classes.noResize
        }`}
        {...props.moreProps}
      />
      <FormHelperText
        className={classes.helperText}
        error={
          typeof props.errortext == "string" && props.errortext !== ""
            ? true
            : false
        }
      >
        {props.errortext}
      </FormHelperText>
    </div>
  );
};

export default CTextArea;
