import { Container } from "@material-ui/core"
import CModal from "../../../../../Form/CModal"
import Form from '../../../../../Form/Form'
import { withLoading, editClassCap } from "../../../../../../utils"


const Capacity = (props) => {
    const { businessId, groupClassDetails, refresh, open, setOpen, classes } = props

    const handleSubmit = async (payload) => {
        const response = await withLoading(editClassCap, payload);
        if (response && !response.error) {
            setOpen(false)
            refresh()
        }
    }


    return (

        <CModal
            open={open}
            setOpen={setOpen}
        >
            <Container className={classes.formContainer}>
                <Form
                    title="Change Capacity"
                    moreData={{ businessId: businessId, classId: groupClassDetails.classId }}
                    action={handleSubmit}
                    cancelEnabled
                    cancelAction={() => { setOpen(false) }}
                    inputFields={[
                        {
                            type: "Number",
                            label: "New Capacity",
                            name: "totalSeats",
                            initialvalue: groupClassDetails.totalSeats,
                            validator: [
                                value => !value || value === 0 ? 'Please enter a valid capacity' : null,
                                value => value < groupClassDetails.sessionDetails.filter(canc => canc.isCanceled == false).length ?
                                    `Capacity must be at least ${groupClassDetails.sessionDetails.filter(canc => canc.isCanceled == false).length}` :
                                    null
                            ]
                        }
                    ]}
                />
            </Container>
        </CModal>

    )
}

export default Capacity