import AssessmentIcon from '@material-ui/icons/Assessment';
import GroupIcon from '@material-ui/icons/Group';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import TodayIcon from '@material-ui/icons/Today';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import React from 'react';
import LogPresenceHandler from '../../../LogPresence/LogPresenceHandler';
import AddOnHandler from './AdminOptions/AddOns/AddOnHandler';
import AgreementsHandler from './AdminOptions/Agreements/AgreementsHandler';
import AnalyticsHandler from './AdminOptions/Analytics/AnalyticsHandler';
import AttendeeHandler from './AdminOptions/Attendees/AttendeeHandler';
import BusinessCalendarHandler from './AdminOptions/BusinessCalendar/BusinessCalendarHandler';
import BusinessProfile from "./AdminOptions/BusinessSettings/BusinessProfile";
import BusinessVerify from "./AdminOptions/BusinessSettings/EditForms/BankVerification";
import PayoutInformation from './AdminOptions/BusinessSettings/PayoutInformation';
import CalendarPreferencesHandler from './AdminOptions/CalendarPreferences/CalendarPreferencesHandler';
import CaritraPricing from './AdminOptions/CaritraPricing/CaritraPricing';
import CatalogHandler from './AdminOptions/Catalog/CatalogHandler';
import CustomersHandler from "./AdminOptions/Customers/CustomersHandler";
import DiscountsHandler from "./AdminOptions/Discounts/DiscountsHandler";
import DisputesHandler from './AdminOptions/Disputes/DisputesHandler';
import FormTemplateHandler from './AdminOptions/Feedback/FormTemplateHandler';
import FeedbackIcon from '@material-ui/icons/Feedback';
import GroupClassesHandler from './AdminOptions/GroupClasses/GroupClassesHandler';
import InternalEventsHandler from './AdminOptions/InternalEvents/InternalEventsHandler';
import MembershipHandler from './AdminOptions/Memberships/MembershipsHandler';
import NotificationsList from './AdminOptions/Notifications/NotificationsList';
import OrdersHandler from "./AdminOptions/Orders/OrderHandler";
import PackagesHandler from "./AdminOptions/Packages/PackageHandler";
import PayoutsHandler from "./AdminOptions/Payouts/PayoutHandler";
import PricingGroupsHandler from './AdminOptions/PricingGroup/PricingGroupHandler';
import PricingOptionsHandler from "./AdminOptions/PricingOptions/PricingOptionsHandler";
import CustomerQualiHandler from "./AdminOptions/Qualifications/CustomerQualiHandler";
import QuestionsHandler from './AdminOptions/Questions/QuestionsHandler';
import ReportsHandler from './AdminOptions/Reports/ReportsHandler';
import ResourceHandler from './AdminOptions/Resource/ResourceHandler';
import ResourceWorkbenchHandler from './AdminOptions/ResourceWorkbench/ResourceWorkbenchHandler';
import ReviewsHandler from './AdminOptions/Reviews/ReviewsHandler';
import ServiceHandler from './AdminOptions/Services/ServiceHandler';
import SessionsHandler from "./AdminOptions/Sessions/SessionHandler";
import SiteHandler from './AdminOptions/Sites/SiteHandler';
import StaffCalendarHandler from './AdminOptions/Staff Calendar/StaffCalendarHandler';
import StaffsHandler from "./AdminOptions/Staffs/StaffsHandler";
import TemplateHandler from './AdminOptions/Template/TemplateHandler';
import WebLinksList from "./AdminOptions/Weblinks/WeblinksList";
import WidgetsHandler from './AdminOptions/Widgets/WidgetHandler';
import WorkAvailabilityHandler from './AdminOptions/Work Availabilities/WorkAvailabilityHandler';

const routes = [
  {
    type: "route",
    route: 'Calender',
    comp: <BusinessCalendarHandler />,
    icon: <TodayIcon />
  },
  {
    type: "bucket",
    route: "Bookings",
    comp: [
      { type: "route", route: 'Orders', comp: <OrdersHandler />, icon: null },
      { type: "route", route: 'Packages and Passes', comp: <PackagesHandler />, icon: null },
      { type: "route", route: 'Sessions', comp: <SessionsHandler />, icon: null },
      { type: "route", route: 'Log Presence', comp: <LogPresenceHandler />, icon: null },
      { type: "route", route: 'Disputes', comp: <DisputesHandler />, icon: null },
    ],
    icon: <LibraryBooksIcon />
  },
  {
    type: "bucket",
    route: "Customer",
    comp: [
      { type: "route", route: 'Customers', comp: <CustomersHandler />, icon: null },
      { type: "route", route: 'Attendees', comp: <AttendeeHandler />, icon: null },
      { type: "route", route: 'Qualifications', comp: <CustomerQualiHandler />, icon: null },
      { type: "route", route: 'Memberships', comp: <MembershipHandler />, icon: null },
      { type: "route", route: 'Reviews', comp: <ReviewsHandler />, icon: null }
    ],
    icon: <GroupIcon />
  },
  {
    type: "bucket",
    route: "Marketplace",
    comp: [
      { type: "route", route: 'Sites', comp: <SiteHandler />, icon: null },
      { type: "route", route: 'Services', comp: <ServiceHandler />, icon: null },
      { type: "route", route: 'Pricings', comp: <PricingOptionsHandler />, icon: null },
      { type: "route", route: "Price Groups", comp: <PricingGroupsHandler />, icon: null },
      { type: "route", route: 'Discounts', comp: <DiscountsHandler />, icon: null },
      { type: "route", route: 'Catalog', comp: <CatalogHandler />, icon: null },
    ],
    icon: <StorefrontOutlinedIcon />
  },
  {
    type: "bucket",
    route: "Resource Management",
    comp: [
      { type: "route", route: 'Resource Type', comp: <TemplateHandler />, icon: null },
      { type: "route", route: 'Resource', comp: <ResourceHandler />, icon: null },
      { type: "route", route: 'Resource Workbench', comp: <ResourceWorkbenchHandler />, icon: null },

    ],
    icon: <GroupIcon />,
    req: (dashboardDetail) => {
      return dashboardDetail.role === "Admin"
    },
  },
  {
    type: "bucket",
    route: "Settings",
    comp: [
      { type: "route", route: 'Business Profile', comp: <BusinessProfile />, icon: null },
      { type: "route", route: 'Questions', comp: <QuestionsHandler />, icon: null },
      { type: "route", route: 'Agreements', comp: <AgreementsHandler />, icon: null },
      { type: "route", route: 'Web Links', comp: <WebLinksList />, icon: null },
      { type: "route", route: 'Widgets', comp: <WidgetsHandler />, icon: null },
      { type: "route", route: 'Add Ons', comp: <AddOnHandler />, icon: null },
      { type: "route", route: 'Analytics', comp: <AnalyticsHandler />, icon: null },
      { type: "route", route: 'Feedback Forms', comp: <FormTemplateHandler />, icon:null },
    ],
    icon: <SettingsIcon />
  },
  {
    type: "bucket",
    route: "Scheduling",
    comp: [
      { type: "route", route: 'Staff Schedules', comp: <WorkAvailabilityHandler />, icon: null },
      { type: "route", route: 'Group Classes', comp: <GroupClassesHandler />, icon: null },
      { type: "route", route: 'Staff Calendar', comp: <StaffCalendarHandler />, icon: null },
      { type: "route", route: 'Internal Events', comp: <InternalEventsHandler />, icon: null }
    ],
    icon: <WatchLaterIcon />
  },
  { type: "route", route: 'Staff', comp: <StaffsHandler />, icon: <GroupWorkIcon /> },
  {
    type: "bucket",
    route: "Financial",
    req: (businessDetails) => {
      return businessDetails.financeAccess
    },
    comp: [
      { type: "route", route: 'Business Status', comp: <BusinessVerify />, icon: null },
      { type: "route", route: 'Bank Details', comp: <PayoutInformation />, icon: null },
      { type: "route", route: 'Payout Statements', comp: <PayoutsHandler />, icon: null },
      { type: "route", route: 'Caritra Pricing', comp: <CaritraPricing />, icon: null },
    ],
    icon: <MonetizationOnIcon />
  },
  { type: "route", route: 'Reports', comp: <ReportsHandler />, icon: <AssessmentIcon />, req: (businessDetails) => { return businessDetails.reportAccess } },
  { type: "route", route: 'Notifications', comp: <NotificationsList />, icon: <NotificationsIcon /> },
  { type: "route", route: 'Dashboard Preferences', comp: <CalendarPreferencesHandler />, icon: <LineStyleIcon /> },



];

export default routes