import React from 'react'
import Form from "../../../../../Form/Form"
import CModal from "../../../../../Form/CModal";
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import maxTextField from '../../../../../../FormValidation/maxTextField';
import { useSelector } from 'react-redux';
import { editTemplate, createTemplate, withLoading, safePush } from '../../../../../../utils';
import { useLocation, useHistory } from 'react-router';

// import { editService} from '../../../../../../utils';
const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        width: "100%",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))

const handleSubmit = async (payload, template) => {
    const response = await withLoading(template ? editTemplate : createTemplate, payload)
    return response

}


const TemplateForm = (props) => {

    const { template, refresh, open, setOpen, basePath } = props
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()

    return (
        <CModal open={open} setOpen={setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    moreData={{
                        businessId: businessId,
                        templateId: template?.templateId,
                        editTag: template?.editTag
                    }}
                    title={`${template ? 'Edit' : 'Create'} Resource Type`}
                    cancelEnabled
                    cancelAction={() => { props.setOpen(false) }}
                    submitText={template ? 'Save' : 'Create'}
                    action={handleSubmit}
                    postAction={async (response) => {
                        if (response && !response.error) {
                            if (template) {
                                setOpen(false)
                                refresh()
                            }
                            else {
                                safePush(`${basePath}/${response.templateId}`, history, location)
                            }
                        }

                    }}
                    inputFields={[
                        {
                            type: "TextField",
                            name: "name",
                            label: "Name",
                            validator: [notEmpty, noWhiteSpace, maxTextField],
                            initialvalue: template?.name

                        },
                    ]}
                />
            </Container>
        </CModal>
    )
}

export default TemplateForm