import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import validDates from '../../../../../../FormValidation/validDates';
import { validTime, validTimeRange } from '../../../../../../FormValidation/validTime';
import { createInternalEvents, getGenericDetails, withLoading } from '../../../../../../utils';
import { CColor } from '../../../../../Form/CColorPicker';
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";
const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        width: "100vw",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    },
    buttonGroup: {
        float: "right",

    },
    button: {
        height: '36.5px'
    },
    buttonText: {
        fontSize: '14px',
        textTransform: 'none'
    },
}))

const formatPayload = (payload, color) => {


    return {
        businessId: payload.businessId,
        name: payload.name,
        siteId: payload.siteId,
        eventType: payload.eventType,
        staffIds: payload.staffIds?.length > 0 ? payload.staffIds : [],
        resourceIds: payload.resourceIds?.length > 0 ? payload.resourceIds : [],
        primaryColor: color,
        hardBlock: Boolean(payload.hardBlock),
        eventDate: payload.eventDate,
        startDate: payload.startDate,
        endDate: payload.endDate,
        startTime: payload.startTime,
        endTime: payload.endTime,
        dayObj: payload.dayObj,
        description: payload.description?.length > 0 ? payload.description : undefined
    }
}

const CreateInternalEventForm = (props) => {
    const [open, setOpen] = useState(false)
    const [color, setColor] = useState("#3366FF")
    const [eventType, setEventType] = useState(null)
    const [siteSelectedId, setSiteSelectedId] = useState(null)
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const mountedRef = useRef(false)
    const classes = useStyles()
    const [genericDetails, setGenericDetails] = useState(null);

    const fetchGenericDetails = async () => {
        const genericResponse = await withLoading(getGenericDetails, {
            businessId
        })
        if (!genericResponse.error) {
            setGenericDetails(genericResponse.genericDetails)
        }
    }

    useEffect(() => {
        mountedRef.current = true
        fetchGenericDetails()
        return () => mountedRef.current = false
    }, [])

    useEffect(() => {
        if (!open && genericDetails) {
            setGenericDetails(null)
            setColor("#FFFFFF")
            setSiteSelectedId(null)
            setEventType(null)
        }
        else if (open && mountedRef.current) {
            fetchGenericDetails()
        }
    }, [open])

    return (
        <React.Fragment>
            <CModal open={open} setOpen={setOpen}>
                <Container component="main" className={classes.formContainer}>
                    <Form
                        moreData={{ businessId: businessId }}
                        title="Create Internal Events"
                        cancelEnabled
                        cancelAction={() => { setOpen(false) }}
                        submitText="Create"
                        action={async (payload) => {
                            const finalPayload = formatPayload(payload, color)
                            return await createInternalEvents(finalPayload)
                        }}
                        postAction={(res) => {
                            if (res && !res.error) {
                                setOpen(false)
                                props.refetch()
                            }
                        }}
                        inputFields={[
                            {
                                type: "TextField",
                                name: "name",
                                label: "Name",
                                validator: [notEmpty, noWhiteSpace]
                            },
                            {
                                type: "SingleSelect",
                                name: "siteId",
                                label: "Site",
                                validator: [
                                    value => (value ? setSiteSelectedId(value) : null),
                                    value => (!value ? "Select a site" : null)
                                ],
                                choices: genericDetails !== null ?
                                    genericDetails.siteDetails.map(site => [site.name, site.siteId])
                                    : []
                            },
                            {
                                type: "MultiSelect",
                                name: "staffIds",
                                label: "Staff",
                                choices: genericDetails !== null ?
                                    genericDetails.staffDetails.map(staff => [`${staff.firstName} ${staff.lastName}`, staff.staffId])
                                    : []
                            },
                            {
                                type: "MultiSelect",
                                name: "resourceIds",
                                label: "Resources",
                                choices: genericDetails !== null && siteSelectedId !== null ?
                                    genericDetails.resourceDetails
                                        .filter(resource => resource.siteId === siteSelectedId)
                                        .map(resource => [resource.name, resource.resourceId])
                                    : [],
                            },
                            {
                                type: "SingleSelect",
                                name: "eventType",
                                label: "Type",
                                validator: [
                                    value => (value ? setEventType(value) : null),
                                    value => (!value ? "Select a type" : null)
                                ],
                                choices: [["Recurring", "Recurring"], ["Single", "Single"]]
                            },
                            {
                                type: "Date",
                                name: "eventDate",
                                label: "Event Date",
                                validator: value => (!value ? "Enter date" : null),
                                enabled: eventType === "Single"
                            },
                            {
                                type: "DateRange",
                                name: ["startDate", "endDate"],
                                label: ["Start Date", "End Date"],
                                validator: validDates,
                                enabled: eventType === "Recurring"
                            },
                            {
                                type: "TimeRange",
                                name: ["startTime", "endTime"],
                                label: ["Start Time", "End Time"],
                                validator: [
                                    times => times.map(validTime),
                                    validTimeRange
                                ],
                                enabled: eventType !== null
                            },
                            {
                                type: "DaysPicker",
                                name: "dayObj",
                                label: "Days",
                                validator: (value) => {
                                    const allDays = [
                                        "sun",
                                        "mon",
                                        "tue",
                                        "wed",
                                        "thr",
                                        "fri",
                                        "sat",
                                    ];
                                    return allDays.every((day) => value[day] === false)
                                        ? "A day must be selected"
                                        : null;
                                },
                                enabled: eventType === "Recurring"
                            },
                            {
                                type: "TextArea",
                                name: "description",
                                label: "Description",
                                initialvalue: "",
                                moreProps: {
                                    rowsMin: 1,
                                    maxRows: 4
                                },
                            },
                            {
                                type: "Custom",
                            },
                            {
                                type: "CheckBox",
                                name: "hardBlock",
                                label: "Block staff time"
                            },


                        ]}
                        comp={
                            <Grid item xs={12}>
                                <CColor
                                    label="Display Color"
                                    onchange={setColor}
                                    color={color}
                                />
                            </Grid>
                        }
                    />
                </Container>
            </CModal>
            <Button
                onClick={() => setOpen(true)}
                color="primary"
                variant='contained'
                className={`${classes.button} ${classes.buttonGroup}`}
                style={{ marginLeft: "10px" }}
            >
                <Typography className={classes.buttonText}>
                    Add
                </Typography>
            </Button>
        </React.Fragment>
    )
}

export default CreateInternalEventForm