import { Container, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import maxTextField from '../../../../../../FormValidation/maxTextField';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import { addTemplateField } from '../../../../../../utils';
import CModal from '../../../../../Form/CModal';
import Form from '../../../../../Form/Form';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100%",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))


const FieldForm = (props) => {
    const { open, refetch, setOpen, templateDetails } = props
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()
    const [type, setType] = useState(null)

    useEffect(() => {
        if (open) {
            setType(null)
        }
    }, [open])


    return (
        <CModal open={open} setOpen={setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    moreData={{ businessId: businessId, templateId: templateDetails.templateId, editTag: templateDetails.editTag }}
                    title={`Add Attribute`}
                    cancelEnabled
                    cancelAction={() => { setOpen(false) }}
                    submitText={`Add`}
                    action={addTemplateField}
                    postAction={() => {
                        setOpen(false)
                        refetch()
                    }}
                    inputFields={[
                        {
                            type: "TextField",
                            name: "fieldName",
                            label: "Attribute Name",
                            validator: [notEmpty, noWhiteSpace, maxTextField],
                        },
                        {
                            type: "SingleSelect",
                            name: "type",
                            label: "Attribute Type",
                            validator: [(value) => setType(value), (value) => (!value ? "Choose a type" : null)],
                            choices: [
                                ["Text", "text"],
                                ["Single Select", "singleSelect"],
                                ["Yes/No", "yes/no"]
                            ],
                        },
                        {
                            type: "MultiField",
                            enabled: type === "singleSelect",
                            name: "options",
                            validator: (value) => {
                                const errors = []
                                const checks = [noWhiteSpace, notEmpty, maxTextField]
                                value.forEach((val) => {
                                    let err = null
                                    for (let i = 0; i < checks.length; i++) {
                                        err = checks[i](val)
                                        if (err) {
                                            break;
                                        }
                                    }
                                    errors.push(err)
                                })
                                return (errors)
                            },
                        }
                    ]}
                />
            </Container>
        </CModal>
    );
};

export default FieldForm