import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress } from "@material-ui/core";
import ListTable from "./../../../../../TableComponents/ListTable";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListCard from "../../../../../MobileCards/ListCard";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import { listNotifications, notificationCount } from "../../../../../../utils";
import ListTableNavigation from "../../../../../TableComponents/ListTableNavigation";
import Moment from "react-moment";
import parse from 'html-react-parser';
import PageNotFound from "../../../../../ErrorPages/PageNotFound";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px"
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },

  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  headerPadding: {
    paddingBottom: "10px"
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "inset 0 -1px #e3e8ee"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px"
  }
}));

const NotificationsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const small = useMediaQuery('(min-width: 750px)');
  const large = useMediaQuery('(min-width: 960px)');
  const matches = useMediaQuery('(min-width: 750px)');

  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const notificationsHeaders = [
    {
      name: "Date",
      columnKey: "date",
      columnWidth: "100px",
    },
    {
      name: "Notification",
      columnKey: "notific",
      type: "Normal",
      columnWidth: "100%"
    }
  ];

  const [notificationsList, setNotificationsList] = useState([]);
  const [count, setCount] = useState(null);
  const [initialState, setInitialState] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  const processNotifData = (notifData) => {
    return notifData.map((notif) => {
      let {
        notificationId,
        notificationDate,
        notification
      } = notif;

      let id = notificationId;
      let date = <Moment format="MM/DD/YY">{notificationDate}</Moment>;
      let notific = parse(notification.replace(/\\"/g, '"'));

      return {
        id,
        date,
        notific
      };
    });
  };
  const getNotifDetails = async () => {
    let notifPayload = await listNotifications({
      businessId: businessId,
      limit: currentLimit,
      page: currentPage
    });
    if (notifPayload && notifPayload.notifications.docs) {
      setNotificationsList(processNotifData(notifPayload.notifications.docs));
      setTotalDocs(notifPayload.notifications.totalDocs);
      setTotalPages(notifPayload.notifications.totalPages);
      setHasNext(notifPayload.notifications.hasNextPage);
      setHasPrevious(notifPayload.notifications.hasPrevPage);
    };
    setInitialState(true);
  };

  const ViewChanger = () => {
    if (small) {
      return (
        <ListTable
          identifier={"admin_orders"}
          tableHeaders={notificationsHeaders}
          tableRows={notificationsList}
          rowClick={null}
        />
      );
    } else {
      return (
        notificationsList.map((mobileNotif) => {
          return (
            <ListCard
              action={null}
              cardName={mobileNotif.date}
              statusReplace={mobileNotif.notific}
              other={null}
            />
          )
        })
      );
    };
  };

  const getNextPage = () => {
    if (hasNext) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPrevPage = () => {
    if (hasPrevious) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    await getNotifDetails();
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
  }, [currentPage, currentLimit]);

  if (!initialState) {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  } else if(notificationsList.length > 0) {
    return (
      <Grid container className={large ? classes.mainWrapper : classes.mobilePadding} >
        <Grid item xs={12} className={classes.headerPadding} style={small ? null : {paddingTop:"10px"}}>
          <div>
            <span className={matches ? classes.topHeading : classes.mobileTopHeadingOne}>Notifications</span>
          </div>
      </Grid>
        {notificationsList.length > 0 ?
          <Grid item xs={12} className={small ? null : classes.mobilePadding}>
            <div>
              <ViewChanger />
            </div>
          </Grid> : <EmptyListImage name = "Notifications"/>}
        {notificationsList.length > 0 ?
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <ListTableNavigation
              currentPage={currentPage}
              currentLimit={currentLimit}
              totalDocs={totalDocs}
              tableRows={notificationsList}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              getPrevPage={getPrevPage}
              getNextPage={getNextPage}
            />
          </Grid> : null}
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return(<PageNotFound />)
  }
};

export default NotificationsList;