import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'


const useStyles = makeStyles((theme) => ({
    heading2: {
        color: theme.palette.primaryText.main,
        paddingBottom: 5,
        // boxShadow: "inset 0 -1px #e3e8ee",
        fontFamily: "Rubik, sans-serif"
    },
    heading1: {
        color: theme.palette.primaryText.main,
        fontFamily: "Rubik, sans-serif"
    }
}))


const CTitle = (props) => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            {props.title ? <Grid container alignItems="center">
                <Grid item xs={12}>
                    <Typography className={props.center === true ? classes.heading1 : classes.heading2} variant="h6" align={(props.center === true ? "center" : "left")}>
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                </Grid>
            </Grid> : null}
        </Grid>
    )
}

export default CTitle