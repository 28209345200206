export const extractDate = (data) => {
    const [year, month, day] = data.split('/');
    return new Date(year, month - 1, day);
}

const generateCurrentDay = () => {
    const currentTime = new Date()
    const day = currentTime.getUTCDate();
    const month = currentTime.getUTCMonth()
    const year = currentTime.getUTCFullYear();
    return new Date(year, month, day)
}

export const futureDate = (data) => {
    if (data && extractDate(data) <= generateCurrentDay()) {
        return ("Date must be in the future")
    };
};

export const pastDate = (data) => {
    if (data && extractDate(data) >= generateCurrentDay()) {
        return ("Date must be in the past")
    };
}