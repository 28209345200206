import { Grid, makeStyles, useMediaQuery } from "@material-ui/core"
import { useEffect, useState } from 'react'
import { listReports, withLoading } from "../../../../../../utils"
import ReportCard from "./ReportCard"


const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: '100vw'
    },
    mainContainer: {
        backgroundColor: theme.palette.accent2.main,
        padding: '20px',
        borderRadius: '8px',
        width: 'calc(100% - 40px)',
        height: 'calc(100% - 40px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    title: {
        paddingBottom: '20px !important'
    },
    topHeading: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    root: {
        margin: '30px',
        backgroundColor: 'white'
    },
    cardButtonHolder: {
        display: ' flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        paddingBottom: '5px',
        flexGrow: 1,
        paddingTop: '20px'
    },
    name: {
        fontSize: '24px',
    },

    description: {
        fontSize: '16px',
    },
    nameHolder: {
        paddingBottom: '10px'
    },
    descriptionHolder: {
        maxHeight: '144px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    buttonText: {
        textTransform: 'none'
    }
}))



const Reports = (props) => {

    const [availableReports, setAvailableReports] = useState([])


    const setup = async () => {
        const response = await withLoading(listReports, {
            businessId: businessId
        })
        if (response && !response.error) {
            setAvailableReports(response.reports)
        }
    }
    useEffect(() => {
        setup()
    }, [])
    const classes = useStyles()
    const { businessId } = props
    const bigEnough = useMediaQuery("(min-width:750px)")
    return (
        <div className={classes.root}>
            <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={12} className={classes.title}>
                    <div className={classes.headerPadding}>
                        <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Reports</span>
                    </div>
                </Grid>
                {availableReports.map((availableReport, index) => (
                    <ReportCard classes={classes} businessId={businessId} {...availableReport} />
                ))}
            </Grid>
        </div>
    )
}

export default Reports