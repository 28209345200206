import React from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  useJsApiLoader,
  useLoadScript,
} from "@react-google-maps/api";
// import MapStyles from "./MapStyles";
import { makeStyles } from "@material-ui/core/styles";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = (props) => {
  return {
    lat: props.siteDetails.geoPoint.lat,
    lng: props.siteDetails.geoPoint.lon,
  };
};

const options = {
  // styles : MapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  fullscreenControl: true,
};

const useStyles = makeStyles((theme) => ({
  errorBlock: {
    width: "100%",
    height: "100%",
    color: "red",
  },
  loadingBlock: {
    width: "100%",
    height: "100%",
    color: "blue",
  },
}));

const libraries = ["places"];
const encodeGetParams = p => Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
function MyComponent(props) {
  
  const classes = useStyles();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries,
  });

  if (loadError)
    return (
      <div className={classes.errorBlock}>
        <h1>Unable to load maps</h1>
      </div>
    );

  return isLoaded ? (
    <GoogleMap
      onDblClick = {()=>{
        window.open("https://www.google.com/maps/search/?api=1&" + encodeGetParams({ query: props.siteDetails.address1 }),'_blank', 'noopener,noreferrer')}} 
      mapContainerStyle={containerStyle}
      center={center(props)}
      zoom={14}
      options={(props.options?props.options:options)}
    >
      <Marker title={props.siteDetails.name} position={center(props)} />
      {/* Child components, such as markers, info windows, etc. */}
    </GoogleMap>
  ) : (
    <div className={classes.loadingBlock}>
      <h1>Loading Maps</h1>
    </div>
  );
}

export default React.memo(MyComponent);
