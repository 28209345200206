import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { staffClassList, retrieveGetParams, encodeGetParams } from "../../../../../../utils";
import Grid from "@material-ui/core/Grid";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ListTable from "./../../../../../TableComponents/ListTable";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListCard from "../../../../../MobileCards/ListCard";
import moment from "moment";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import ListTableNavigation from "../../../../../TableComponents/ListTableNavigation";
import StatusTag from "../../../../../TagComponents/StatusTag";
import { Typography } from "@material-ui/core";
import Filter from "../../../../../Filter/Filter";

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    cardBasic: {
        backgroundColor: "#ffffff",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText2: {
        fontSize: "14px",
        color: "#3c4257",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextNeg: {
        fontSize: "14px",
        color: "#009211",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBG: {
        fontSize: "16px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBold: {
        fontSize: "14px",
        color: "#1a1f36",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextPad: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingLeft: "10px",
    },
    valueTextLink: {
        fontSize: "14px",
        color: "#3d5af1",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textDecoration: "none"
    },
    rightElem: {
        float: "right",
    },
    buttonBasic: {
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeading: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    bottomSpacer: {
        height: "50px",
    },
}));

const StaffClassList = (props) => {
    const [_, refresh] = useState(null)
    const bigEnough = useMediaQuery("(min-width: 750px)")
    const [add, setAdd] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const businessId = useSelector((state) => {
        return state.buisnessDetails.businessId;
    });

    const groupClassesHeaders = [
        {
            name: "class #",
            columnKey: "classNumber",
            columnWidth: "80px",
        },
        {
            name: "service",
            columnKey: "serviceName",
            columnWidth: "30%",
            tooltip: true
        },
        {
            name: "site",
            columnKey: "siteName",
            columnWidth: "30%",
            tooltip: true
        },
        {
            name: "start time",
            columnKey: "startTimeUTC",
            columnWidth: "15%",
            tooltip: true
        },
        {
            name: "end time",
            columnKey: "endTimeUTC",
            columnWidth: "15%",
            tooltip: true
        },
        {
            name: "time zone",
            columnKey: "timeZone",
            columnWidth: "10%",
            tooltip: true
        },
        {
            name: "",
            columnKey: "status",
            columnWidth: "100px"
        }
    ];

    const [groupClassesList, setGroupClassesList] = useState(null);

    const location = useLocation()
    const queryParams = retrieveGetParams(location)

    const currentPage = queryParams.pg ? Number(queryParams.pg) : 1
    const service = queryParams.service
    const site = queryParams.site
    const start = queryParams.start
    const end = queryParams.end
    const hId = queryParams.hId
    const status = queryParams.status
    const currentLimit = 20;
    const [totalPages, setTotalPages] = useState(0);
    const [totalDocs, setTotalDocs] = useState(0);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrevious, setHasPrevious] = useState(false);


    const processGroupClassesData = (groupClassesData) => {
        return groupClassesData.map((groupClass) => {
            let {
                classId,
                classNumber,
                siteName,
                serviceName,
                startTimeUTC,
                endTimeUTC,
                timeZone,
                isCanceled
            } = groupClass;
            startTimeUTC = moment(startTimeUTC).format("MMM, Do, YYYY : h:mm A ")
            endTimeUTC = moment(endTimeUTC).format("MMM, Do, YYYY : h:mm A ")
            let status = (isCanceled ? <StatusTag wrapperName="redWrapper" value="Canceled" /> : null)
            let id = classId
            return {
                id,
                classNumber,
                siteName,
                serviceName,
                endTimeUTC,
                startTimeUTC,
                timeZone,
                status,
                isCanceled
            };
        });
    };

    const getGroupClassesDetails = async () => {
        let classesPayload = await staffClassList({
            businessId: businessId,
            limit: currentLimit,
            page: currentPage,
            serviceIdList: service ? [service] : undefined,
            siteList: site ? [site] : undefined,
            startDate: start,
            endDate: end,
            hId: hId ? [hId] : undefined,
            statusOption: status

        });
        if (classesPayload) {
            setGroupClassesList(processGroupClassesData(classesPayload.classes.docs));
            setTotalPages(classesPayload.totalPages);
            setTotalDocs(classesPayload.totalDocs);
            setHasNext(classesPayload.hasNextPage);
            setHasPrevious(classesPayload.hasPrevPage);
        }
        else {
            setGroupClassesList(null);
            setTotalPages(0);
            setTotalDocs(0);
            setHasNext(false);
            setHasPrevious(false);
        }
    };

    const navigateToGroupClass = (groupClassId) => {
        history.push(`${props.basePath}/${groupClassId}`);
    };

    useEffect(async () => {
        dispatch({
            type: "UPDATE",
            payload: {
                loading: true,
            },
        });
        await getGroupClassesDetails();
        dispatch({
            type: "UPDATE",
            payload: {
                loading: false,
            },
        });
    }, [currentPage, site, service, start, end, hId, status]);


    const ViewChanger = () => {
        if (bigEnough) {
            return (


                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        <ListTable
                            identifier={"admin_orders"}
                            tableHeaders={groupClassesHeaders}
                            tableRows={groupClassesList}
                            rowClick={navigateToGroupClass}
                        />
                    </div>
                </Grid>




            )
        }
        else {
            return (
                <Grid item xs={12} style={{ paddingTop: "10px" }}>
                    <div className={classes.contentRow}>
                        {groupClassesList.map((groupClass) => {
                            return (
                                <div className={classes.cardBasic} style={{ paddingTop: "10px", paddingBottom: "15px" }} onClick={() => { navigateToGroupClass(groupClass.id) }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={6}>
                                            <Typography className={classes.valueTextBold} noWrap>
                                                {groupClass.classNumber}
                                            </Typography>
                                            <Typography className={classes.valueText} noWrap>
                                                <b>Start:</b> {groupClass.startTimeUTC}
                                            </Typography>
                                            <Typography className={classes.valueText} noWrap>
                                                <b>End:</b> {groupClass.endTimeUTC}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>

                                            <Typography className={classes.valueText} noWrap style={{ paddingTop: "14px" }}>
                                                <b>Service:</b> {groupClass.serviceName}
                                            </Typography>
                                            <Typography className={classes.valueText} noWrap>
                                                <b>Site:</b> {groupClass.siteName}
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            {(groupClass.isCanceled
                                                ?
                                                <Typography
                                                    noWrap
                                                    style={{ fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "red" }}>
                                                    Canceled
                                                </Typography>

                                                : null)}
                                        </Grid>

                                    </Grid>
                                </div>
                            )
                        })}
                    </div>
                </Grid>)
        }

    }
    const getNextPage = () => {
        if (hasNext) {
            history.push(location.pathname + '?' + encodeGetParams({ ...queryParams, pg: currentPage + 1 }))

        }
    };

    const getPrevPage = () => {
        if (hasPrevious) {
            history.push(location.pathname + '?' + encodeGetParams({ ...queryParams, pg: currentPage - 1 }))
        }
    };

    if (groupClassesList !== null) {
        return (
            <React.Fragment>
                <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding} >
                    <Grid item xs={12}>
                        <div className={classes.headerPadding}>
                            <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Group Classes</span>
                            <Filter businessId={businessId} filterFields={['service', 'site', 'dateRange', 'status']} staff />
                        </div>
                    </Grid>
                    {(Array.isArray(groupClassesList) && groupClassesList.length > 0 ? <ViewChanger /> : <EmptyListImage name="Group Classes" />)}

                    <Grid item xs={12}>
                        <ListTableNavigation
                            currentPage={currentPage}
                            currentLimit={currentLimit}
                            totalDocs={groupClassesList.length}
                            tableRows={groupClassesList}
                            hasNext={hasNext}
                            hasPrevious={hasPrevious}
                            getPrevPage={getPrevPage}
                            getNextPage={getNextPage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.bottomSpacer}></div>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    } else {
        return (null);
    }

};

export default StaffClassList;