import styled from "@emotion/styled";

export const StyleWrapper = styled.div`
.fc-toolbar h2{
    display:inline;
}
.fc-toolbar-title{
    font-size: 15px;
    color: #1a1f36;
}
.fc .fc-button-primary:disabled{
    background: #E0E0E0;
    color: #0079fc;
}
.fc-col-header{
    font-size:14px;
}
.fc-col-header-cell-cushion {
    font-size:14px;
    color: #697386;
}
.fc-daygrid-day-number{
    font-size:14px;
    color: #697386;
}
.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    font-size:14px;
    color: #697386;
}
.fc-list-day-text {
    color:#1a1f36;
}
.fc-list-day-side-text {
    color: #1a1f36;
}
.fc-list-event-time{
    color:#697386;
}
.fc-button{
    font-size:14px;
    width:35px;
    padding:3px 0px;
    background: white;
    color:#0079fc;
    &:active{
        background:grey;
    };
}
.fc-button *:after {
    outline: none;
}
.fc-filterButton-button.fc-button.fc-button-primary{
    padding-bottom:3px;
    padding-top:4px;
    padding-left:2px;
    background:white;
    color: #0079fc;
}
.MuiList-root.MuiMenu-list.MuiList-padding {
    padding: 0;
}
.fc-event{
    font-size:12px;
    color:#1a1f36;
}
.fc-button.fc-prev-button, .fc-button.fc-next-button{
    background: white;
    border:white;
    color: #0079fc;
    width:18px;
    padding-bottom:1px;
    background-image: none;
    &:active{
        background:white;
        color:#0079fc;
    }
    &:focus{
        background:white;
        color:#0079fc;
    }
}
.fc-button.fc-dropdownButton-button {
    background: white;
    color: #0079fc;
    &:active{
        background:white;
        color:#0079fc;
    }
    &:focus{
        background:white;
        color:#0079fc;
    }
}
.fc-datagrid-cell-main{
    font-size:12px;
}
.fc-button.fc-today-button{
    width:35px;
    padding-top:6px;
    padding-bottom:1px;
    background:white;
    color:#0079fc;
}
.fc-button.fc-listWeek-button{
    width:35px;
    padding:0px 0px;
    padding-top:1px;
    padding-bottom:0px;
}
.fc-button.fc-timeGridWeek-button, .fc-button.fc-timeGridDay-button, .fc-button.fc-resourceTimelineDay-button, .fc-button.fc-resourceTimelineDay-button{
    padding-left: 1px;
    padding-bottom:0px;
    padding-top:2px;
    width:35px;
    background-image: none;
}

.fc-event-title-container{
    font-size: 12px;
    line-height: 12px;
    max-height:100%;
}
.fc-event-title.fc-sticky{
    overflowY:hidden;
    text-overflow:ellipsis;
}
.fc .fc-popover {
    z-index: 999
}
`

export const StyleWrapper2 = styled.div`
.fc .fc-popover {
    z-index: 999
}
.fc-timeline-event-harness {
    height: 30px;
    padding: 0px;
    align-items: center;
}
td .fc-timeline-lane.fc-resource {
    &:hover{
        background: #f0eded;
    }
}
.fc-event-title.fc-sticky{
    overflow: hidden;
    text-overflow:ellipsis;
}
.fc-toolbar h2{
    display:inline;
}
.fc-toolbar-title{
    font-size: 24px;
    margin: 0px 0px 0px 0px;
    color: #1a2f36;
}
.fc-col-header{
    font-size:14px;
}
.fc-clearFilterButton-button.fc-button.fc-button-primary {
    width:20px;
    font-weight:bold;
    font-size:11px;
    color:white;
    background-color:#0079fc;
    padding: 0 0;
    &:hover{
        opacity:0.5;
    }
}
.fc .fc-button-primary:disabled{
    background: #E0E0E0;
    color: #0079fc;
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
    background: #E0E0E0;
    color: #0079fc;
}
.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    font-size:14px;
    color: #697386;
}
.fc-col-header-cell-cushion {
    font-size:14px;
    color: #697386;
    font-weight:normal;
}
.fc-daygrid-day-number{
    font-size:14px;
    color: #697386;
}
.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    font-size:14px;
    color: #697386;
}
.fc-filterAButton-button.fc-button.fc-button-primary {
    background-color: #0079fc;
    color:white;
    &:hover{
        opacity:0.5;
    }
}
.fc-filterButton-button{
    padding-bottom:3px;
    padding-top:4px;
    padding-left:2px;
    background:white;
    color:#0079fc;
}
.fc-button-primary:not(:disabled):active:focus, .fc-button-primary:focus, .fc-button-primary:not(:disabled).fc-button-active:focus, .fc-filterButton-button:not(:disabled).fc-filterButton-button:focus  {
    box-shadow: 0 0 0 0;
}
.fc .fc-button:focus {
    outline: none;
    outline: none;
}
.fc, .fc *, .fc *:before, .fc *:after {
    box-sizing: none;
}
.fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-past, 
.fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end, 
.fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-today, 
.fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-future {
    background: #white;
    color: #1a1f36;  
    font-size: 11px;
    white-space: normal;
    font-weight: normal;
    align-items:flex-start;
    overflow: hidden;
    line-height: 12px;
   max-height: 24px;
   display: flex;
}
.fc-daygrid-day-frame.fc-scrollgrid-sync-inner{
    height:100px;
    max-height:100px;
}
.fc-event-title-container{
    font-size: 12px;
    line-height: 12px;
    max-height:calc(95%);
}
.fc-button{
    font-size:14px;
    padding: 3px 0px;
    background: white;
    color: #0079fc;
    &:hover{
        background: #E8E8E8;
        color: #0079fc;
    }
}
.fc .fc-button-primary{
    background: white;
    color: #0079fc;
}
.fc-button.fc-prev-button, .fc-button.fc-next-button{
    width:50px;
    padding: 5px 0px;
    padding-bottom:0px;
    background-image: none;
    &:active{
        background:white;
        color:#0079fc;
    }
    &:focus{
        background:white;
        color:#0079fc;
    }
}
.fc-button.fc-today-button{
    width:50px;
    padding:4px 0px;
}
.fc-button.fc-listWeek-button{
    width:35px;
    padding:4px 0px;
}
.fc-button.fc-timeGridWeek-button, .fc-button.fc-dayGridMonth-button, .fc-button.fc-timeGridDay-button, .fc-button, .fc-button.fc-resourceTimelineDay-button, .fc-button.fc-resourceTimelineDay-button{
    width:55px;
    padding: 4px 0px;
    background-image: none;
}
`

export const StyleWrapper3 = styled.div`
.fc-toolbar h2{
    display:inline;
}
.fc-toolbar-title{
    font-size: 16px;
    color: #1a1f36;
}
.fc .fc-button-primary:disabled{
    background: #E0E0E0;
    color: #0079fc;
}
.fc-col-header{
    font-size:14px;
}
.fc-col-header-cell-cushion {
    font-size:14px;
    color: #697386;
}
.fc-daygrid-day-number{
    font-size:14px;
    color: #697386;
}
.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    font-size:14px;
    color: #697386;
}
.fc-button{
    font-size:14px;
    width:35px;
    padding:3px 0px;
    background: white;
    color: #0079fc;
    &:active{
        background:grey;
    };
}
.fc-button *:after {
    outline: none;
}
.fc-filterButton-button.fc-button.fc-button-primary{
    padding-bottom:3px;
    padding-top:4px;
    padding-left:2px;
    background:white;
    color:#0079fc;
}
.MuiList-root.MuiMenu-list.MuiList-padding {
    padding: 0;
}
.fc-event{
    font-size:12px;
    color:#1a1f36;
}
.fc-button.fc-prev-button, .fc-button.fc-next-button{
    background: white;
    border:white;
    width:18px;
    padding-bottom:1px;
    background-image: none;
    &:active{
        background:white;
        color:#0079fc;
    }
    &:focus{
        background:white;
        color:#0079fc;
    }
}
.fc-button.fc-dropdownButton-button {
    background: white;
    color: #0079fc;
    &:active{
        background:white;
        color:#0079fc;
    }
    &:focus{
        background:white;
        color:#0079fc;
    }
}
.fc-button.fc-today-button{
    width:35px;
    padding-top:6px;
    padding-bottom:1px;
}
.fc-button.fc-listWeek-button{
    width:35px;
    padding:0px 0px;
    padding-top:1px;
    padding-bottom:0px;
}
.fc-button.fc-timeGridWeek-button, .fc-button.fc-timeGridDay-button, .fc-button.fc-resourceTimelineDay-button, .fc-button.fc-resourceTimelineDay-button{
    padding-left: 1px;
    padding-bottom:0px;
    padding-top:2px;
    width:35px;
    background-image: none;
}
`
