import {
	Button,
	CircularProgress,
	Container,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from "@material-ui/icons/Publish";
import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import validPhoneNumber from "../../../../../../FormValidation/phonenumber";
import validEmail from "../../../../../../FormValidation/validEmail";
import { dashboardList, editSinglePageType, profileList, signedAdminImageUrlUpload, updateProfile, withLoading } from "../../../../../../utils";
import CDisplayArea from "../../../../../Form/CDisplayArea";
import CModal from "../../../../../Form/CModal";
import CModalError from "../../../../../Form/CModalError";
import Form from "../../../../../Form/Form";
import LightTooltip from "../../../../../Form/LightTooltip";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import { CCloseIcon, CEllipsisIcon } from "../../../../../Icons/Icons";

const useStyles = makeStyles((theme) => ({
	mainWrapper: {
		width: "100%",
		paddingLeft: "50px",
		paddingRight: "50px",
		paddingTop: "15px",
	},
	mobilePadding: {
		width: "100%",
		paddingLeft: "10px",
		paddingRight: "10px",
		paddingTop: "10px",
	},
	topBlock: {
		paddingBottom: "15px",
	},
	recordRow: {
		paddingLeft: "5px",
		paddingTop: "10px",
		paddingBottom: "10px",
		paddingRoght: "5px",
		width: "550px"
	},
	headingWrapper: {
		paddingBottom: "10px",
		boxShadow: "inset 0 -1px #e3e8ee",
	},
	rightElem: {
		float: "right",
	},
	rightElemPad: {
		float: "right",
		marginRight: "10px",
	},
	topHeading: {
		fontSize: "13px",
		color: "#1a1f36",
	},
	topHeadingOne: {
		fontSize: "28px",
		fontWeight: "bold",
		color: "#1a1f36",
	},
	mobileTopHeadingOne: {
		fontSize: "22px",
		fontWeight: "bold",
		color: "#1a1f36",
	},
	mobileTopHeadingTwo: {
		fontSize: "20px",
		fontWeight: "bold",
		color: "#1a1f36",
	},
	topHeadingTwo: {
		fontSize: "28px",
		fontWeight: "light",
		color: "#1a1f36",
	},
	topHeadingThree: {
		backgroundColor: "#eb5757",
		opacity: "50%",
		paddingLeft: "15px",
		paddingRight: "15px",
		borderRadius: "2px",
		marginLeft: "10px",
		position: "absolute",
		marginTop: "6px",
		paddingTop: "2px",
		paddingBottom: "2px",
		fontSize: "14px",
	},
	buttonBasic: {
		backgroundColor: "#ffffff",
		color: "#1a1f36",
		fontSize: "14px",
		boxShadow:
			" 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
		paddingLeft: "15px",
		paddingRight: "15px",
		paddingTop: "3px",
		paddingBottom: "3px",
		textTransform: "none",
	},
	contentRow: {
		paddingTop: "15px",
		paddingBottom: "25px",
	},
	contentColumnFirst: {
		paddingRight: "20px",
		boxShadow: "inset -1px 0 #e3e8ee",
		display: "inline-block",
	},
	contentColumnMiddle: {
		boxShadow: "inset -1px 0 #e3e8ee",
		paddingLeft: "20px",
		paddingRight: "20px",
		display: "inline-block",
	},
	contentColumnLast: {
		paddingLeft: "20px",
		display: "inline-block",
	},
	labelText: {
		fontSize: "14px",
		color: "#697386",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	valueText: {
		fontSize: "14px",
		color: "#1a1f36",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	parText: {
		fontSize: "14px",
		color: "#3c4257",
		fontFamily: "Karla",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	valueText2: {
		fontSize: "14px",
		color: "#3c4257",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	valueTextNeg: {
		fontSize: "14px",
		color: "#009211",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	valueTextBG: {
		fontSize: "16px",
		color: "#1a1f36",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	valueTextBold: {
		fontSize: "14px",
		color: "#1a1f36",
		fontWeight: "bold",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	valueTextPad: {
		fontSize: "14px",
		color: "#1a1f36",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		paddingLeft: "10px",
	},
	valueTextLink: {
		fontSize: "14px",
		color: "#3d5af1",
		cursor: "pointer",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	errorText: {
		fontSize: "14px",
		color: "#ff0000",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	blockLabel: {
		paddingTop: "10px",
		paddingBottom: "5px",
	},
	blockText: {
		paddingLeft: "10px",
	},
	subHeading: {
		fontSize: "24px",
		fontWeight: "bold",
		color: "#1a1f36",
	},
	fieldRows: {
		paddingTop: "8px",
		paddingLeft: "10px",
	},
	fieldColumnOne: {
		width: "100px",
		display: "inline-block",
	},
	fieldColumnTwo: {
		display: "inline-block",
	},
	sdCustomCellOne: {
		border: "none",
		width: "10%",
		maxWidth: "200px",
		padding: "10px",
	},
	sdCustomCellTwo: {
		border: "none",
		width: "25%",
		maxWidth: "300px",
		padding: "10px",
	},
	sdCustomCellThree: {
		border: "none",
		width: "10%",
		maxWidth: "200px",
		padding: "10px",
	},
	sdCustomCellFour: {
		border: "none",
		padding: "10px",
	},
	headerPadding: {
		padding: "10px",
	},
	bottomSpacer: {
		height: "50px",
	},
	topMarginBlock: {
		marginTop: "10px",
	},
	loaderWrapper: {
		width: "100%",
		height: "calc(100vh - 64px)"
	},
	loaderMain: {
		position: "absolute",
		top: "50%",
		left: "50%"
	},
	media: {
		height: 200,
		width: 200,
		objectFit: "contain",
		resizeMode: "contain"
	},
	avatar: {
		height: theme.spacing(20),
		width: theme.spacing(20),
	},
	formContainer: {
		border: '20px',
		backgroundColor: 'white',
		borderRadius: '4px',
		padding: "20px",
		maxWidth: "600px"
	}
}));

const BusinessProfile = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [_, updateState] = useState(0);
	const large = useMediaQuery('(min-width: 960px)');
	const small = useMediaQuery('(min-width: 750px)');
	const [edit, setEdit] = useState(false);
	const [editSinglePage, setEditSinglePage] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null);
	const [dI, setDI] = useState(false);
	const [imageName, setImageName] = useState(false);
	const [docForm, setDocForm] = useState(false);
	const [businessDetails, setBusinessDetails] = useState([]);
	const [fileName, setFileName] = useState(null);
	const [fileData, setFileData] = useState(null);
	const businessDetailsState = useSelector((state) => { return state.buisnessDetails });
	const businessId = useSelector((state) => { return state.buisnessDetails.businessId });

	const handleImage = async (file) => {
		setEdit(false);
		if (file.target.files && file.target.files[0] && file.target.files[0].type && file.target.files[0].type.includes('image')) {
			setFileName(file.target.files[0].name);
			setFileData(file.target.files[0]);
			setImageName(URL.createObjectURL(file.target.files[0]));
			setDocForm(true);
		}

	};

	const handleImageAdd = async () => {
		let preSignedURL = null;

		dispatch({
			type: "UPDATE",
			payload: {
				loading: true,
			},
		});

		preSignedURL = await signedAdminImageUrlUpload({
			businessId,
			fileName
		});

		if (preSignedURL && !preSignedURL.error) {

			let s3url = preSignedURL.signedData.url;
			let logo = preSignedURL.imageURL;
			let fields = preSignedURL.signedData.fields;
			let formData = new FormData();

			for (let k in fields) {
				formData.append(k, fields[k]);
			};

			formData.append('file', fileData);

			try {
				await axios.post(s3url, formData);
				await updateProfile({ businessId, logo });

				dispatch({
					type: "UPDATE",
					payload: {
						loading: false,
					},
				});
				setDocForm(false);

			} catch (error) {
				dispatch({
					type: "UPDATE",
					payload: {
						loading: false,
					},
				});
			};

		} else {
			dispatch({
				type: "UPDATE",
				payload: {
					loading: false,
				},
			});
		};
		updateState((_) => !_);
	};

	async function handleDelete(event) {
		const success = await withLoading(updateProfile, {
			businessId: businessId,
			logo: null
		})
		if (success) {
			setDI(false)
			updateState((_) => !_);
		}
	};

	async function handleSubmit(event) {

		dispatch({
			type: 'UPDATE', payload: {
				loading: true
			}
		});

		let success = await updateProfile({
			businessId: businessId,
			name: event.name,
			phoneNumber: event.pnumber,
			email: event.email,
			about: event.about
		});
		dispatch({
			type: 'UPDATE', payload: {
				loading: false
			}
		});
		if (success) {

			updateState((_) => !_);
			setEdit(false);
		}
	};

	const setup = async () => {
		let data = null;
		dispatch({
			type: "UPDATE",
			payload: {
				loading: true,
			},
		});
		data = await profileList({ businessId: businessId });
		dispatch({
			type: "UPDATE",
			payload: {
				loading: false,
			},
		});
		if (data) {
			setBusinessDetails(data.businessDetails);
			if (data.businessDetails.name !== businessDetailsState.businessName) {
				let newDashList = await withLoading(dashboardList, {})
				if (newDashList) {
					dispatch({
						type: "UPDATE",
						payload: {
							dashboards: newDashList,
							buisnessDetails: { ...businessDetailsState, businessName: data.businessDetails.name }
						}
					})
				}
			}
			if (data.businessDetails.logo !== businessDetailsState.logo) {
				dispatch({
					type: "UPDATE",
					payload: {
						buisnessDetails: { ...businessDetailsState, logo: data.businessDetails.logo }
					}
				})
			}
		}
	}

	useEffect(() => {
		setup();
	}, [_]);

	if (businessDetails && businessDetails.operatingUnitInfo) {
		return (
			<React.Fragment>
				<GeneralDialog
					open={dI}
					close={setDI}
					title="Delete Business Logo"
					text="Are you sure you want to delete this logo?"
					action={handleDelete}
				/>
				<CModal open={edit} setOpen={setEdit}
					children={
						<Container component="main" className={classes.formContainer}>
							<Form
								action={handleSubmit}
								postAction={null}
								title="Edit Profile"
								cancelEnabled
								cancelAction={() => { setEdit(false); }}
								cancelText="Cancel"
								submitText="Save"
								inputFields={
									[
										{
											type: "TextField",
											validator: [notEmpty],
											initialvalue: businessDetails.name,
											label: "Name",
											name: "name",
										},
										{
											type: "PhoneNumber",
											name: "pnumber",
											label: "Phone Number",
											initialvalue: businessDetails.phoneNumber,
											validator: [(value) => { if (value === "+1") { return ("Bad number") } }, validPhoneNumber]
										},
										{
											type: "TextField",
											validator: [notEmpty, validEmail],
											initialvalue: businessDetails.email,
											label: "Email",
											name: "email",
										},
										{
											type: "TextArea",
											validator: notEmpty,
											name: "about",
											label: "About Business",
											resizeEnabled: true,
											initialvalue: businessDetails.about,
											moreProps: {
												minRows: 4,
												maxRows: 10
											}
										},
									]
								}
							>
							</Form>
						</Container>
					}
				/>
				<CModal open={editSinglePage} setOpen={setEditSinglePage}>
					<Container component="main" className={classes.formContainer}>
						<Form
							action={editSinglePageType}
							postAction={(res)=>{
								if(res?.error ===  false){
									setEditSinglePage(false)
									setup()
								}
							}}
							moreData={{
								businessId
							}}
							title="Edit Single Page Type"
							cancelEnabled
							cancelAction={() => { setEditSinglePage(false); }}
							cancelText="Cancel"
							submitText="Edit"
							inputFields={[
								{
									type:"RadioSet",
									choices: [
										["Location", "Location"],
										["Catalog", "Catalog"]
									],
									validator: value => !value? "A type must be provided" : null,
									name:"type",
									label:"Type",
									initialvalue: businessDetails?.singlePageType
								}
							]}
								/>
				</Container>
				</CModal>
				<CModal open={docForm} setOpen={setDocForm}
					children={
						<Container component="main" className={classes.formContainer}>
							<Grid container spacing={1} item xs={12} direction="column" alignItems="center">
								<CModalError />
								<Grid item xs={12}>
									<div className="container">
										<img style={{ minHeight: "250px", height: "100%", width: "100%", maxWidth: "400px" }} src={imageName ? imageName : null} alt="Business Image" />
										<span className={small ? "button" : "bSmall"} style={{ display: "inline-flex" }}>
											<input
												accept="image/*"
												onChange={(file) => { handleImage(file); }}
												style={{ display: 'none' }}
												id="contained-button-file"
												multiple
												type="file"
											/>
											<label htmlFor="contained-button-file">
												<IconButton variant="contained" component="span" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
													<PublishIcon />
												</IconButton>
											</label>
										</span>
									</div>
								</Grid>
								<Grid item xs={12} />
								<Grid container item xs={12} spacing={1} justifyContent="flex-end" direction="row">
									<Grid item >
										<Button className={classes.buttonBasic} onClick={() => { setDocForm(false) }}>Cancel</Button>
									</Grid>
									<Grid item style={{ justifyContent: "flex-end" }}>
										<Button className={classes.buttonBasic} style={{ backgroundColor: "#0079fc", color: "white" }} onClick={handleImageAdd}>Submit</Button>
									</Grid>
								</Grid>
							</Grid>
						</Container>
					} />
				<Menu
					anchorEl={anchorEl}
					getContentAnchorEl={null}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					transformOrigin={{ vertical: "top", horizontal: "center" }}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={() => {
						setAnchorEl(false);
					}}
				>
					<MenuItem
						onClick={() => {
							setEdit(true);
							setAnchorEl(false);
						}}
					>
						Edit Profile
					</MenuItem>
					<MenuItem
						onClick={() => {
							setEditSinglePage(true);
							setAnchorEl(false);
						}}
					>
						Edit Single Page Type
					</MenuItem>
				</Menu>
				<Grid container className={large ? classes.mainWrapper : classes.mobilePadding}>
					<Grid item xs={12}>
						<div className={classes.topBlock}>
							<span className={classes.topHeading}>BUSINESS PROFILE</span>
							<Button
								onClick={(event) => {
									setAnchorEl(event.target);
								}}
								className={classes.buttonBasic}
								style={{ float: "right" }}
							>
								<CEllipsisIcon />
							</Button>
						</div>
					</Grid>
					<Grid container item xs={12} direction="row">
						<Grid item xs={12}>
							<div className={classes.headerPadding} style={{ width: `calc(100%)`, paddingLeft: "0px" }}>
								<LightTooltip title={businessDetails.name} arrow>
									<Typography noWrap className={small ? classes.topHeadingOne : classes.mobileTopHeadingOne}>
										{businessDetails.name}
									</Typography>
								</LightTooltip>
							</div>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={2} style={large ? { paddingTop: "15px", paddingBottom: "15px", paddingLeft: "15px" } : { paddingTop: "25px" }} direction={large ? "row" : "column"}>
						<Grid item style={large ? { paddingLeft: "0px" } : { display: "flex", justifyContent: "center" }}>
							<div className="container">
								<img className={classes.media} src={businessDetails.logo} alt="smth" />
								<span className={small ? "button" : "bSmall"} style={{ display: "inline-flex" }}>
									<input
										accept="image/*"
										onChange={(file) => { handleImage(file); }}
										style={{ display: 'none' }}
										id="contained-button-file"
										type="file"
									/>
									<label htmlFor="contained-button-file">
										<IconButton variant="contained" component="span" style={businessDetails.logo && businessDetails.logo !== "https://caritra-app-images.s3.us-west-1.amazonaws.com/default-images/business-logo.png" ? { right: "41px", backgroundColor: "rgba(255, 255, 255, 0.6)", height: "20px", width: "20px" } : { backgroundColor: "rgba(255, 255, 255, 0.6)", height: "20px", width: "20px" }}><EditIcon style={{ color: "#1a1f36", opacity: 1 }} /></IconButton>
									</label>
									{businessDetails.logo && businessDetails.logo !== "https://caritra-app-images.s3.us-west-1.amazonaws.com/default-images/business-logo.png" ?
										<LightTooltip title="Delete Business Logo" arrow>
											<IconButton variant="contained" style={{ right: "40px", backgroundColor: "rgba(255, 255, 255, 0.6)", height: "44px", width: "44px" }} onClick={() => { setDI(true); }}>
												<CCloseIcon />
											</IconButton>
										</LightTooltip>
										: null}
								</span>
							</div>
						</Grid>
						<Grid item style={large ? null : { paddingLeft: "0px" }}>
							<div className={classes.contentRow}>
								<div className={classes.fieldRows}>
									<div className={classes.fieldColumnOne} style={{ maxWidth: "70px", paddingBottom: "5px" }}>
										<span className={classes.labelText} style={{ whiteSpace: "normal" }}>Phone Number</span>{" "}
									</div>
									<div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
										<span className={classes.valueText} >
											{`${businessDetails.phoneNumber.slice(0, 2)} (${businessDetails.phoneNumber.slice(2, 5)}) ${businessDetails.phoneNumber.slice(5, 8)}-${businessDetails.phoneNumber.slice(8, 12)}`}
										</span>
									</div>
								</div>
								<div className={classes.fieldRows}>
									<div className={classes.fieldColumnOne} style={{ maxWidth: "70px", paddingBottom: "5px" }}>
										<span className={classes.labelText} style={{ whiteSpace: "normal" }}>Email</span>{" "}
									</div>
									<div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
										<span className={classes.valueText}>
											{businessDetails.email}
										</span>
									</div>
								</div>
								<div className={classes.fieldRows}>
									<div className={classes.fieldColumnOne} style={{ maxWidth: "70px" }}>
										<span className={classes.labelText} style={{ whiteSpace: "normal" }}>Country</span>{" "}
									</div>
									<div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
										<span className={classes.valueText}>
											{businessDetails.operatingUnitInfo.countryLongName}
										</span>
									</div>
								</div>
								<div className={classes.fieldRows}>
									<div className={classes.fieldColumnOne} style={{ maxWidth: "70px" }}>
										<span className={classes.labelText} style={{ whiteSpace: "normal" }}>Single Page Type</span>{" "}
									</div>
									<div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
										<span className={classes.valueText}>
											{businessDetails.singlePageType}
										</span>
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.headingWrapper}>
							<span className={classes.labelText}>About Business</span>
						</div>
					</Grid>
					<Grid item xs={12} style={{ paddingTop: "10px" }}>
						<span className={classes.parText}>
							<CDisplayArea
								value={businessDetails.about ? businessDetails.about : null}
								initialMaxHeight={"calc(100vh - 360px)"}
							/>
						</span>{" "}
					</Grid>
					<Grid item xs={12}>
						<div className={classes.bottomSpacer}></div>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	} else {
		return (
			<Grid container className={classes.mainWrapper}>
				<Grid item xs={12}>
					<div className={classes.loaderWrapper}>
						<CircularProgress className={classes.loaderMain} />
					</div>
				</Grid>
			</Grid>
		);
	}
};

export default BusinessProfile;
