import { Button, Container, Grid, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { rescheduleStaffSession, staffSessionDetails, withLoading } from "../../../../../../utils";
import { AttendeeInfoModal } from "../../../../../AttendeeInfoModal/AttendeeInfoModal";
import CButtonPair from "../../../../../Form/CButtonPair";
import CDisplayArea from "../../../../../Form/CDisplayArea";
import CModal from "../../../../../Form/CModal";
import CTitle from "../../../../../Form/CTitle";
import LightTooltip from "../../../../../Form/LightTooltip";
import { CEllipsisIcon } from "../../../../../Icons/Icons";
import MapContainer from "../../../../../MapContainer/MapContainer";
import ResourceTable from "../../../../../ResourceTable/ResourceTable";
import ListTable from "../../../../../TableComponents/ListTable";
import { FeedbackFormWithStarTimeGate, intentForSession } from "../../../AdminDashboard/AdminOptions/Feedback/FeedbackForm";
import ScheduleCalendar from "../../../AdminDashboard/AdminOptions/Packages/ScheduleCalendar";
import WebConfForm from "../../../AdminDashboard/AdminOptions/Sessions/WebConfForm";
import StatusTag from "./../../../../../TagComponents/StatusTag";
import { BusinessFeedbackList } from "../../../../../Account/AccountOptions/Feedback/FeedbackList";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
    maxWidth: 200,
    objectFit: "cover"
  },
  avatar: {
    height: theme.spacing(20),
    width: theme.spacing(20),
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px"
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
    height: "100%"
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
    height: "100%"
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none"
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px"
  },
  fieldColumnOne: {
    width: "130px",
    display: "inline-block",

  },
  fieldColumnTwo: {
    display: "inline-block",
    maxWidth: "250px",

  },
  sdCustomCellOne: {
    border: "none",
    width: "5%",
    maxWidth: "200px",
    padding: "0px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingThree: {
    fontSize: "20px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "35%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "60%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
    paddingLeft: "0px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  media: {
    height: 200,
    width: 200,
    objectFit: "cover"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "700px"
  }
}));

const isUTCPassed = (utcTime) => {
  const curMoment = new Date()
  const refMomemt = new Date(utcTime)
  return curMoment > refMomemt
};

const StaffSession = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [RS, setRS] = useState(false)
  const [CS, setCS] = useState(false)
  const bigEnough = useMediaQuery("(min-width: 750px)")
  const matches = useMediaQuery("(min-width: 1240px)")
  const [sessionDetails, setSessionDetails] = useState(null)
  const [newClassId, setNewClassId] = useState(null);
  const [editWebConf, setEditWebConf] = useState(false);
  const [aM, setAM] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false)
  const [feedback, setFeedback] = useState(false)
  const role = useSelector((state) => {
    return state.buisnessDetails.role;
  });
  const schedulingAccess = useSelector((state) => { return state.buisnessDetails.schedulingAccess });
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const { sessionIdURL } = useParams();
  const addOnHeaders = [
    {
      name: "Name",
      columnKey: "name",
      columnWidth: "30%",
      type: "Typography",
      tooltip: true
    },
    {
      name: "Basis",
      columnKey: "sellBy",
      columnWidth: "100px",
    },
    {
      name: "Description",
      columnKey: "description",
      columnWidth: "70%",
      tooltip: true
    },
    {
      name: "",
      columnKey: "action",
      columnWidth: "100px",
      type: "Normal"
    },
  ];
  const hostsHeaders = [
    {
      name: "Name",
      columnKey: "name",
      columnWidth: "50%",
      type: "Normal"
    },

    {
      name: "Email",
      columnKey: "email",
      columnWidth: "50%",
      tooltip: true
    },
    {
      name: "Phone Number",
      columnKey: "phoneNumber",
      columnWidth: "150px",
    },
  ];
  const hostsHeadersM = [
    {
      name: "Name",
      columnKey: "name",
      columnWidth: "100%",
      type: "Normal"
    },
  ];

  const setup = async () => {
    let data = await withLoading(staffSessionDetails, {
      businessId: businessId,
      sessionId: sessionIdURL,
    }, true)

    if (data) {
      setSessionDetails(data.sessionDetails);
    }
  }

  useEffect(()=>{
    setup()
  }, []);

  if (sessionDetails) {
    let status = null
    if (sessionDetails.isCanceled) {
      status = <StatusTag wrapperName={'redWrapper'} value={'Canceled'} />
    }
    else if (isUTCPassed(sessionDetails.startDateUTC)) {
      status = (sessionDetails.attendanceRecord) ? <StatusTag wrapperName={(sessionDetails.attendanceRecord == 'Present' ? 'greenWrapper': (sessionDetails.attendanceRecord == 'Absen' ? 'redWrapper': 'grayWrapper'))} value={sessionDetails.attendanceRecord} /> :<StatusTag wrapperName={'grayWrapper'} value={'Completed'} />
    }
    else {
      status = (sessionDetails.attendanceRecord) ? <StatusTag wrapperName={(sessionDetails.attendanceRecord == 'Present' ? 'greenWrapper': (sessionDetails.attendanceRecord == 'Absen' ? 'redWrapper': 'grayWrapper'))} value={sessionDetails.attendanceRecord} /> :<StatusTag wrapperName={'greenWrapper'} value={'Scheduled'} />
    }
    return (
      <React.Fragment>
        <FeedbackFormWithStarTimeGate open = {feedback} setOpen = {setFeedback} fetchDetails = {setup} intent = {intentForSession(sessionIdURL)} eventEndTimeUTC = {sessionDetails.endDateUTC} staff/>
        <CModal open={RS} setOpen={setRS}>
          <AttendeeInfoModal open={aM} setOpen={setAM} attendeeQuestions={sessionDetails.attendeeQuestions} />
          <Container component="main" className={classes.formContainer} style={{ maxWidth: "1000px" }}>
            <WebConfForm open={editWebConf} role={role} refresh={setup} setOpen={setEditWebConf} sessionDetails={sessionDetails} />
            <ScheduleCalendar
              handleSubmit={
                async (payload) => {
                  let success = null
                  if (sessionDetails.offeringType === "Group") {
                    success = await withLoading(rescheduleStaffSession, {
                      businessId: businessId,
                      sessionId: sessionDetails.sessionId,
                      classId: newClassId
                    })
                  }
                  else if (sessionDetails.offeringType === "Private") {
                    success = await withLoading(rescheduleStaffSession, {
                      businessId: businessId,
                      sessionId: sessionDetails.sessionId,
                      ...payload
                    })
                  }
                  if (success) {
                    setup()
                    setRS(false)
                  }
                }
              }
              edit
              setClassId={setNewClassId}
              initialdate={moment(sessionDetails.startDateUTC).format("YYYY-MM-DD")}
              initialtime={moment(sessionDetails.startDateUTC).format("HH:mm")}
              serviceIds={sessionDetails.serviceId}
              siteIds={sessionDetails.siteId}
              staffIds={sessionDetails.staffId}
              role={role}
              serviceTypes={sessionDetails.offeringType}
              location={sessionDetails.siteName}
              timeZone={sessionDetails.timeZone}
              mode={sessionDetails.isVirtual}
              cancel={() => { setRS(false); }}
              offeringType={sessionDetails.offeringType}
              preProcess={true}
            />
          </Container>
        </CModal>
        <CModal open={CS} setOpen={setCS}>
          <Container component="main" className={classes.formContainer}>
            <Grid container spacing={2}>
              <CTitle title="Cancel Session" />
              <Grid item xs={12}>
                <Typography>
                  Please contact a business administrator to cancel the session.
                </Typography>
              </Grid>
              <CButtonPair submitText="Close" action={async () => setCS(false)} />
            </Grid>
          </Container>
        </CModal>
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={Boolean(anchorEl)}
          onClose={() => { setAnchorEl(false) }}
        >
          {schedulingAccess ? <MenuItem onClick={() => { setRS(true); setAnchorEl(false) }}>Reschedule</MenuItem> : null}
          <MenuItem onClick={() => { setCS(true); setAnchorEl(false) }}>Cancel</MenuItem>
          {sessionDetails.isCanceled?null: <MenuItem onClick={() => { setFeedback(true); setAnchorEl(false) }}>Give Feedback</MenuItem>}
          
        </Menu>
        <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
          <Grid item xs={12}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>SESSION</span>
              {sessionDetails.isCanceled ? null : <span className={classes.rightElem}>
                <Button onClick={(event) => { setAnchorEl(event.target) }} className={classes.buttonBasic}><CEllipsisIcon /></Button>
              </span>}
            </div>
          </Grid>
          <Grid item style={(bigEnough ? { maxWidth: "50%" } : { width: "100%" })}>
            <div className={classes.headerPadding}>
              <LightTooltip title={`#${sessionDetails.sessionNumber} (${sessionDetails.offeringType} - ${(sessionDetails.isVirtual ? "Online" : "In Person")})`} arrow>
                <Typography className={(bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne)} noWrap>
                  #{sessionDetails.sessionNumber} ({sessionDetails.offeringType} - {(sessionDetails.isVirtual ? "Online" : "In Person")})
                </Typography>
              </LightTooltip>
            </div>
          </Grid>
          {
            (bigEnough ?
              <Grid item>
                <div className={classes.headerPadding} style={{ paddingLeft: "20px" }}>
                  <span className={classes.topHeadingFour}>
                    {status}
                  </span>
                </div>
              </Grid>
              :
              <Grid item>
                <div style={{ paddingLeft: "10px" }}>
                  {status}
                </div>
              </Grid>
            )
          }
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <div className={matches ? classes.contentColumnFirst : classes.fieldRows}>
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Start Time</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { maxWidth: 160 } : { paddingLeft: "15px", maxWidth: "calc(100% - 145px)" }}>
                  <LightTooltip arrow title={`${sessionDetails.startDate}: ${sessionDetails.startTime} ${sessionDetails.timeZone}`}>
                    <Typography noWrap className={classes.valueText}>
                      {`${sessionDetails.startDate}: ${sessionDetails.startTime} ${sessionDetails.timeZone}`}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
              <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Service</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { maxWidth: 160 } : { paddingLeft: "15px", maxWidth: "calc(100% - 145px)" }}>
                  <LightTooltip title={sessionDetails.serviceName} arrow>
                    <Typography noWrap className={classes.valueText}>
                      {sessionDetails.serviceName}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
              <div className={matches ? classes.contentColumnMiddle : classes.fieldRows} >
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Site</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { maxWidth: 160 } : { paddingLeft: "15px", maxWidth: "calc(100% - 145px)" }}>
                  <LightTooltip title={sessionDetails.siteName} arrow>
                    <Typography noWrap className={classes.valueText} style={{ maxWidth: "100px" }}>
                      {sessionDetails.siteName}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
              {(sessionDetails.optionType === "Pack" || sessionDetails.optionType === "Season Pass" ?
                <div className={matches ? classes.contentColumnLast : classes.fieldRows}>
                  <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                    <Typography noWrap className={classes.labelText}>{sessionDetails.optionType} #</Typography>
                  </div>
                  <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>
                    <Link className={classes.valueTextLink} to={`/dashboard/${businessId}/Packages-and-Passes/${sessionDetails.packId}`}>
                      <Typography noWrap className={classes.valueTextLink}>
                        {sessionDetails.packNumber}
                      </Typography>
                    </Link>
                  </div>
                </div>
                : null)}
            </div>
          </Grid>
          {(sessionDetails.isVirtual
            ?
            <React.Fragment>
              <Grid item xs={12} className={classes.headingWrapper}>
                <div>
                  <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Web Conference Info</span>
                  {(sessionDetails.offeringType === "Group" || sessionDetails.isCanceled ? null :
                    <LightTooltip title="Edit Web Conference Information" arrow>
                      <Button
                        className={[classes.rightElem, classes.buttonBasic]}
                        onClick={() => { setEditWebConf(true); }}
                      >
                        <EditIcon />
                      </Button>
                    </LightTooltip>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentRow}>
                  <CDisplayArea
                    value={null}
                    initialMaxHeight={"calc(100vh - 360px)"}
                  />
                </div>
              </Grid>
            </React.Fragment>
            :
            <React.Fragment>
              <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                  <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Address</Typography>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={classes.contentRow}>
                  <LightTooltip arrow title={sessionDetails.address2 ? `${sessionDetails.address1}, ${sessionDetails.address2}` : `${sessionDetails.address1}`}>
                    <Typography className={classes.valueText} style={{ paddingBottom: "10px" }}>
                      <b>Address:</b> {sessionDetails.address2 ? `${sessionDetails.address1}, ${sessionDetails.address2}` : `${sessionDetails.address1}`}
                    </Typography>
                  </LightTooltip>
                  <div style={{ height: 400, width: "100%", justify: "center" }}>
                    <MapContainer siteDetails={{
                      geoPoint: {
                        lat: sessionDetails.geoPoint[1],
                        lon: sessionDetails.geoPoint[0]
                      }
                    }}
                      options={{
                        disableDefaultUI: true,
                        zoomControl: false,
                        gestureHandling: 'none'
                      }} />
                  </div>
                </div>
              </Grid>
            </React.Fragment>
          )}
          {(sessionDetails.offeringType === "Group" ?
            <React.Fragment>
              <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                  <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Hosts</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentRow}>
                  <ListTable
                    styling={{ width: "100%", maxHeight: 250 }}
                    identifier={"hosts"}
                    tableHeaders={(bigEnough ? hostsHeaders : hostsHeadersM)}
                    tableRows={sessionDetails.hostDetails.map((host,) => {
                      return ({
                        name: (
                          <LightTooltip arrow title={`${host.hostFirstName} ${host.hostLastName}`}>
                            <Typography noWrap className={classes.valueText}>
                              {`${host.hostFirstName} ${host.hostLastName}`}
                            </Typography>
                          </LightTooltip>),
                        phoneNumber: `${host.hostPhoneNumber.slice(0, 2)} (${host.hostPhoneNumber.slice(2, 5)}) ${host.hostPhoneNumber.slice(5, 8)}-${host.hostPhoneNumber.slice(8, 12)}`,
                        email: <LightTooltip arrow title="Email Host"><a style={{ color: "#0079fc" }} href={"mailto:" + host.hostEmail}>{host.hostEmail}</a></LightTooltip>
                      })
                    })}
                    rowClick={null}
                  />
                </div>
              </Grid>
            </React.Fragment>
            : null)}
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Attendee Info</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Name</Typography>
                </div>
                <div className={classes.fieldColumnTwo} style={{ maxWidth: "70%" }}>
                  <LightTooltip title={`${sessionDetails.attendeeFirstName} ${sessionDetails.attendeeLastName}`} arrow>
                    <Typography noWrap className={classes.valueText}>
                      {`${sessionDetails.attendeeFirstName} ${sessionDetails.attendeeLastName}`}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container direction="row" item xs={12} className={classes.headingWrapper}>
            <Grid item xs={6}>
              <div>
                <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Attendee Questions</span>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {sessionDetails.attendeeQuestions.length !== 0 ? (
                <TableContainer style={bigEnough ? { maxHeight: "265px", width: "100%" } : { maxHeight: "177px", width: "100%" }}>
                  <Table stickyHeader aria-label="simple table" style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className={classes.headerPadding} style={{ width: `calc(50%)` }}>
                          <span className={classes.labelText}>QUESTION</span>
                        </TableCell>
                        <TableCell align="left" className={classes.headerPadding} style={{ width: `calc(50%)` }}>
                          <Typography noWrap className={classes.labelText}>ANSWER</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sessionDetails.attendeeQuestions.map((ques) => {
                        return (
                          <TableRow >
                            <TableCell
                              align="left"
                              className={classes.sdCustomCellOne}
                            >
                              <Typography noWrap className={classes.valueText}>
                                {ques.question}
                              </Typography>

                            </TableCell>
                            <TableCell align="left" className={classes.sdCustomCellTwo}>
                              <Typography noWrap className={classes.valueText}>
                                {ques.answer}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    No questions for this attendee.
                  </span>
                </div>
              )}
            </div>
          </Grid>
          <ResourceTable
            classes={classes}
            businessId={businessId}
            resourceDetails={(sessionDetails?.resourceDetails ?? [])}
            bigEnough={bigEnough}
            eventId={sessionDetails.sessionId}
            eventType="Session"
          />
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Customer Info</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Name</Typography>
                </div>
                <div className={classes.fieldColumnTwo}>
                  <LightTooltip title={`${sessionDetails.customerFirstName} ${sessionDetails.customerLastName}`} arrow>
                    <Typography noWrap className={classes.valueText}>
                      {`${sessionDetails.customerFirstName} ${sessionDetails.customerLastName}`}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Email</Typography>
                </div>
                <div className={classes.fieldColumnTwo}>
                  <Typography noWrap className={classes.valueText}>
                    <LightTooltip title="Email Customer" arrow>
                      <a style={{ color: "#0079fc" }} href={"mailto:" + sessionDetails.customerEmail}>{sessionDetails.customerEmail}</a>
                    </LightTooltip>
                  </Typography>
                </div>
              </div>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Phone</Typography>
                </div>
                <div className={classes.fieldColumnTwo}>
                  {sessionDetails.customerPhoneNumber ? (
                    <Typography noWrap className={classes.valueText}>
                      {`${sessionDetails.customerPhoneNumber.slice(0, 2)} (${sessionDetails.customerPhoneNumber.slice(2, 5)}) ${sessionDetails.customerPhoneNumber.slice(5, 8)}-${sessionDetails.customerPhoneNumber.slice(8, 12)}`}
                    </Typography>
                  ) : (
                    <Typography noWrap className={classes.valueText}>Not Available</Typography>
                  )}
                </div>
              </div>
            </div>
          </Grid>
          {(sessionDetails.attendeeAddOn.length > 0 ?
            <React.Fragment>
              <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                  <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Add Ons</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentRow}>
                  <ListTable
                    styling={{ width: "100%", maxHeight: 250 }}
                    identifier={"addOns"}
                    tableHeaders={addOnHeaders}
                    tableRows={sessionDetails.attendeeAddOn.map((aO) => {
                      return ({
                        name: aO.displayName,
                        sellBy: aO.sellBy.toLowerCase(),
                        description: aO.description,
                        action: (aO.isCanceled ? <StatusTag wrapperName={'redWrapper'} value={'Canceled'} /> : null)
                      })
                    })}
                    rowClick={null}
                  />
                </div>
              </Grid>
            </React.Fragment> :
            null)}
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Timeline</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow} >
              <Timeline style={{ width: 0, padding: 0 }}>
                {(sessionDetails.isCanceled ?
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography className={classes.valueText}>
                        Canceled By: {`${sessionDetails.canceledByStaffFirstName} ${sessionDetails.canceledByStaffLastName}`}
                      </Typography>
                      <Typography className={classes.labelText}>
                        At: {moment(sessionDetails.canceledAt).format("MMM, Do, YYYY : h:mm A ")}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  :
                  null)}
                {sessionDetails.rescheduleHistory.map((hist) => {
                  return (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography className={classes.valueText}>
                          {`Rescheduled By: ${hist.rescheduledByStaffFirstName} ${hist.rescheduledByStaffLastName}`}
                        </Typography>
                        <Typography className={classes.labelText}>
                          {`Original Time: ${hist.oldTime}`}
                        </Typography>
                        <Typography className={classes.labelText}>
                          At: {moment(hist.rescheduledAt).format("MMM, Do, YYYY : h:mm A ")}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  )
                })}
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography className={classes.valueText}>
                      Created By: {sessionDetails.createdBy === "Customer" ? `${sessionDetails.customerFirstName} ${sessionDetails.customerLastName}` : `${sessionDetails.createdByStaffFirstName} ${sessionDetails.createdByStaffLastName}`}
                    </Typography>
                    <Typography className={classes.labelText}>
                      At: {moment(sessionDetails.createdAt).format("MMM, Do, YYYY : h:mm A ")}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>

              </Timeline>
            </div>
          </Grid>
          {sessionDetails.sessionFeedbacks.length > 0 ?
            <React.Fragment>
              <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                  <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Feedback</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentRow} >
                    <BusinessFeedbackList bigEnough = {bigEnough} feedback = {sessionDetails.sessionFeedbacks} businessId = {businessId} staff/>
                </div>
              </Grid>
            </React.Fragment>
          :null}
          <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (null);
  }
};

export default StaffSession;
