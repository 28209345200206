import { createTheme } from '@material-ui/core/styles'
// "#FC8300"
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    drawer: {
      main: "#a4cdfe"
    },
    primary: {
      main: "#3366FF",
      disabled: "#4C95E3"
      //#e8edfa
    },
    success: {
      main: "#27AE60"
    },
    secondary: {
      main: "#FFBF00",
    },
    accent1: {
      main: "#DBDDDF"
    },
    accent2: {
      main: "#F7FAFC",
      dark: "#e4ebf0"
    },
    primaryText: {
      main: "#1A1F36"
    },
    textColor2: {
      main: "#3C4257"
    },
    textColor3: {
      main: "#697386"
    },
    white: {
      main: "white"
    },
    formBody: {
      main: "#F7FAFC"
    },
    checkoutSectionHeader: {
      main: ""
    }
  },
  typography: {
    "fontFamily": `"Rubik", sans-serif`,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  overrides: {
    "MuiPickersBasePicker-container": {
      justifyContent: "center"
    }
  }
});

export default theme

/*
use:
font-family: 'Karla', sans-serif;

font-family: 'Rubik', sans-serif;
*/