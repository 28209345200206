import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Menu, MenuItem,
  TextareaAutosize,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from "@material-ui/icons/Print";
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { getClassDetails, removeHost, withLoading } from "../../../../../../utils";
import LightTooltip from "../../../../../Form/LightTooltip";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import { CAddIcon, CCloseIcon, CEllipsisIcon } from "../../../../../Icons/Icons";
import MapContainer from "../../../../../MapContainer/MapContainer";
import ResourceTable from "../../../../../ResourceTable/ResourceTable";
import ListTable from "../../../../../TableComponents/ListTable";
import { FeedbackFormWithStarTimeGate, intentForClass } from "../Feedback/FeedbackForm";
import StatusTag from "./../../../../../TagComponents/StatusTag";
import AddHostForm from './AddHostForm';
import AttendanceSheet from "./AttendanceSheet";
import CancelClassForm from "./CancelClassForm";
import Capacity from "./Capacity";
import CheckInForm from "./CheckInForm";
import EmailClassForm from "./EmailClassForm";
import RescheduleClassForm from "./RescheduleClassForm";
import WebConfForm from "./WebConfForm";
import { BusinessFeedbackList } from "../../../../../Account/AccountOptions/Feedback/FeedbackList";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px",
    width: "100%",
    maxHeight: "80vh",
    overflowY: "auto"
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px"
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
    height: "100%"
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
    height: "100%"
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none"
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px"
  },
  fieldColumnOne: {
    width: "100px",
    display: "inline-block",

  },
  fieldColumnTwo: {
    display: "inline-block",
    maxWidth: "250px",

  },
  sdCustomCellOne: {
    border: "none",
    width: "5%",
    maxWidth: "200px",
    padding: "0px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingThree: {
    fontSize: "20px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "35%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "60%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  media: {
    height: 200,
    width: 250,
    objectFit: "cover"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflowY: "auto"
  }
}));

const GroupClass = (props) => {
  const [anchorEl, setAnchorEl] = useState(false)
  const classes = useStyles();
  const [rClass, setRClass] = useState(false)
  const [editCap, setEditCap] = useState(false)
  const [editWebConf, setEditWebConf] = useState(false)
  const [addHost, setAddHost] = useState(false)
  const [selH, setSelH] = useState(null)
  const [delH, setDelH] = useState(false)
  const [cancelC, setCancelC] = useState(false)
  const [emailC, setEmailC] = useState(false)
  const [attendance, setAttendance] = useState(false)
  const [aBP, setABP] = useState(false)
  const [groupClassDetails, setGroupClassDetails] = useState(null)
  const [feedback, setFeedback] = useState(false)
  const componentRef = useRef(null)
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const { classIdURL } = useParams();
  const matches = useMediaQuery('(min-width: 1240px)');
  const bigEnough = useMediaQuery('(min-width: 750px)')
  const mountedRef = useRef(false)
  const attendeesHeaders = [
    {
      name: "Session #",
      columnKey: "sessionNumber",
      columnWidth: "100px",
    },
    {
      name: "Name",
      columnKey: "name",
      columnWidth: "50%",
      tooltip: true
    },
    {
      name: "Option",
      columnKey: "optionName",
      columnWidth: "50%",
      tooltip: true
    },
    {
      name: "Type",
      columnKey: "optionType",
      columnWidth: "200px",
    },
    {
      name: "Attendance",
      columnKey: "attendance",
      columnWidth: "150px",
      type: "Normal"
    }
  ];
  const attendeesHeadersM = [
    {
      name: "Session #",
      columnKey: "sessionNumber",
      columnWidth: "100px",
    },
    {
      name: "Name",
      columnKey: "name",
      columnWidth: "100%",
      tooltip: true
    },
    {
      name: "Attendance",
      columnKey: "attendance",
      columnWidth: "100px",
      type: "Normal"
    }
  ];
  const hostsHeaders = [
    {
      name: "",
      columnKey: "check",
      columnWidth: "50px",
      type: "Normal"
    },
    {
      name: "Name",
      columnKey: "name",
      columnWidth: "50%",
      type: "Normal"
    },
    {
      name: "Email",
      columnKey: "email",
      columnWidth: "50%",
      tooltip: true
    },
    {
      name: "Phone Number",
      columnKey: "phoneNumber",
      columnWidth: "150px",
    },
  ];
  const hostsHeadersM = [
    {
      name: "",
      columnKey: "check",
      columnWidth: "50px",
      type: "Normal"
    },
    {
      name: "Name",
      columnKey: "name",
      columnWidth: "100%",
      type: "Normal"
    },
  ];


  const genEmptyState = (list) => {
    const temp = []
    for (let i = 0; i < list.length; i++) {
      temp.push(false)
    }
    return (temp)
  }

  const genDelH = () => {
    const temp = []
    selH.forEach((choice, index) => {
      if (choice === true) {
        temp.push(groupClassDetails.hostDetails[index].hostId)
      }
    })
    return (temp)
  }

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${groupClassDetails?.classNumber} Attendee List`,
    fonts: [{ style: "font-face" }],
    removeAfterPrint: true,
    pageStyle: {
      padding: "10px"
    }
  });

  useEffect(async () => {
    mountedRef.current = true
    setUp()
    return ()=>{mountedRef.current = false}
  }, [])

  const setUp = async () => {
    let classData = await withLoading(getClassDetails, {
      businessId: businessId,
      classId: classIdURL
    })
    if (classData && mountedRef.current) {
      setSelH(genEmptyState(classData.hostDetails))
      setGroupClassDetails(classData)
    }
  }


  if (groupClassDetails && selH) {
    return (
      <React.Fragment>
        <Capacity
          refresh={() => setUp}
          groupClassDetails={groupClassDetails}
          businessId={businessId}
          open={editCap}
          setOpen={setEditCap}
          classes={classes}
        />
        <CheckInForm open={aBP} setOpen={setABP} classes={classes} businessId={businessId} classId={classIdURL} fetch={setUp} />
        <RescheduleClassForm open={rClass} setOpen={setRClass} refresh={() => { setUp() }} groupClassDetails={groupClassDetails} />
        <CancelClassForm open={cancelC} setOpen={setCancelC} refresh={() => { setUp() }} groupClassDetails={groupClassDetails} />
        <EmailClassForm open={emailC} setOpen={setEmailC} refresh={() => { setUp() }} groupClassDetails={groupClassDetails} />
        <AddHostForm open={addHost} setOpen={setAddHost} refresh={() => { setUp() }} hostDetails={groupClassDetails.hostDetails} classId={groupClassDetails.classId} />
        <WebConfForm open={editWebConf} setOpen={setEditWebConf} refresh={() => { setUp() }} groupClassDetails={groupClassDetails} />
        <AttendanceSheet open={attendance} setOpen={setAttendance} businessId={businessId} classId={classIdURL} classNumber={groupClassDetails.classNumber} />
        <GeneralDialog
          open={delH}
          close={() => { setDelH(false) }}
          title={"Remove Hosts"}
          text={"Are you sure you want to remove hosts?"}
          action={async () => {
            let data = await withLoading(removeHost,
              {
                businessId: businessId,
                classId: groupClassDetails.classId,
                hostIds: genDelH()
              })
            if (data) {
              setDelH(false)
              setUp()
            }
          }}
        />
        <FeedbackFormWithStarTimeGate open = {feedback} setOpen = {setFeedback}  fetchDetails = {setUp} intent = {intentForClass(classIdURL)} eventEndTimeUTC = {groupClassDetails.endTimeUTC}/>
        <Menu

          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={Boolean(anchorEl)}
          onClose={() => { setAnchorEl(false) }}
        >
          <MenuItem onClick={() => { setRClass(true); setAnchorEl(false) }}>Reschedule Class</MenuItem>
          <MenuItem onClick={() => { setCancelC(true); setAnchorEl(false) }}>Cancel Class</MenuItem>
          <MenuItem onClick={() => { setAttendance(true); setAnchorEl(false) }}>Take Attendance</MenuItem>
          <MenuItem onClick={() => { setEditCap(true); setAnchorEl(false) }}>Edit Capacity</MenuItem>
          <MenuItem onClick={() => { setEmailC(true); setAnchorEl(false) }}>Email Customer</MenuItem>
          {(groupClassDetails.isCanceled?null: <MenuItem onClick={() => { setFeedback(true); setAnchorEl(false) }}>Give Feedback</MenuItem>)}
         
        </Menu>
        <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
          <Grid item xs={12}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>GROUP CLASS</span>
              {(groupClassDetails.isCanceled ? null :
                <span className={classes.rightElem}>
                  <Button onClick={(event) => { setAnchorEl(event.target) }} className={classes.buttonBasic}><CEllipsisIcon /></Button>
                </span>
              )}
            </div>
          </Grid>
          <Grid item style={(bigEnough ? { maxWidth: "50%" } : { width: "100%" })}>
            <div className={classes.headerPadding} style={{ paddingLeft: "0px" }}>
              <LightTooltip title={`${groupClassDetails.serviceName} (${(groupClassDetails.isVirtual ? "Online" : "In-Person")})`} arrow>
                <Typography className={(bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne)} noWrap>
                  {`${groupClassDetails.serviceName} (${(groupClassDetails.isVirtual ? "Online" : "In-Person")})`}
                </Typography>
              </LightTooltip>
            </div>
          </Grid>
          {(groupClassDetails.isCanceled ?
            (bigEnough ?
              <Grid item>
                <div className={classes.headerPadding} style={{ paddingLeft: "20px" }}>
                  <span className={classes.topHeadingFour}>
                    <StatusTag wrapperName="redWrapper" value="Canceled" />
                  </span>
                </div>
              </Grid>
              :
              <Grid item>
                <div style={{ paddingLeft: "10px" }}>
                  <StatusTag wrapperName="redWrapper" value="Canceled" />
                </div>
              </Grid>
            )
            :
            null
          )}
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <div className={matches ? classes.contentColumnFirst : classes.fieldRows}>
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography className={classes.labelText}>Start Time</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>
                  <Typography className={classes.valueText}>
                    {`${groupClassDetails.startDate}: ${groupClassDetails.startTime} ${groupClassDetails.timeZone}`}
                  </Typography>
                </div>
              </div>
              <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography className={classes.labelText}>End Time</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>
                  <LightTooltip title={`${groupClassDetails.endDate}: ${groupClassDetails.endTime} ${groupClassDetails.timeZone}`} arrow>
                    <Typography className={classes.valueText}>
                      {`${groupClassDetails.endDate}: ${groupClassDetails.endTime} ${groupClassDetails.timeZone}`}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
              <div className={matches ? classes.contentColumnMiddle : classes.fieldRows} >
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography className={classes.labelText}>Location</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { maxWidth: 160 } : { paddingLeft: "15px" }}>

                  <LightTooltip arrow title={groupClassDetails.siteName}>
                    <Typography className={classes.valueTextLink} noWrap style={{ maxWidth: "100px" }}>
                      <Link className={classes.valueTextLink} to={`/dashboard/${businessId}/Sites/${groupClassDetails.siteId}`}>
                        {groupClassDetails.siteName}
                      </Link>
                    </Typography>
                  </LightTooltip>

                </div>
              </div>
              <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography className={classes.labelText}>Capacity</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>
                  <Typography className={classes.valueText} noWrap >
                    {(groupClassDetails.totalSeats === null ? "No Limit" : groupClassDetails.totalSeats)}
                  </Typography>
                </div>
              </div>
              <div className={matches ? classes.contentColumnLast : classes.fieldRows}>
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography className={classes.labelText}>Class #</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>
                  <Typography className={classes.valueText}>
                    {groupClassDetails.classNumber}
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Hosts</span>
              {(groupClassDetails.isCanceled ? null
                :
                (Array.isArray(selH) && !selH.includes(true) ?
                  <Button className={[classes.buttonBasic, classes.rightElem]}
                    onClick={() => { setAddHost(true) }}
                  >
                    <CAddIcon />
                  </Button>
                  :
                  <Button
                    className={[classes.buttonBasic, classes.rightElem]}
                    onClick={() => { setDelH(true) }}
                  >
                    <CCloseIcon />
                  </Button>
                )
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {(groupClassDetails.hostDetails.length > 0 ?
                <ListTable
                  styling={{ width: "100%", maxHeight: 250 }}
                  identifier={"hosts"}
                  tableHeaders={(bigEnough ? hostsHeaders : hostsHeadersM).slice((groupClassDetails.isCanceled ? 1 : 0))}
                  tableRows={groupClassDetails.hostDetails.map((host, index) => {
                    return ({
                      check:
                        (<Checkbox
                          checked={selH[index]}
                          onClick={() => {
                            const temp = [...selH]
                            temp[index] = !temp[index]
                            setSelH(temp)
                          }}
                          color="primary"
                        />),
                      name: (

                        <LightTooltip arrow title={`${host.hostFirstName} ${host.hostLastName}`}>
                          <Typography noWrap >
                            <span className={classes.valueTextLink}>
                              <Link className={classes.valueTextLink} to={`/dashboard/${businessId}/Staffs/${host.hostId}`}>
                                {`${host.hostFirstName} ${host.hostLastName}`}
                              </Link>
                            </span>
                          </Typography>
                        </LightTooltip>
                      ),
                      phoneNumber: `${host.hostPhoneNumber.slice(0, 2)} (${host.hostPhoneNumber.slice(2, 5)}) ${host.hostPhoneNumber.slice(5, 8)}-${host.hostPhoneNumber.slice(8, 12)}`,
                      email: <LightTooltip title="Email Host" arrow><a style={{ color: "#0079fc" }} href={"mailto:" + host.hostEmail}>{host.hostEmail}</a></LightTooltip>
                    })
                  })}
                  rowClick={null}
                />
                :
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    No hosts for this group class.
                  </span>
                </div>
              )}
            </div>
          </Grid>
          <Grid container xs={12} spacing={2} >
            <Grid item xs={12}>
              <div style={{ paddingBottom: "5px", boxShadow: "inset 0 -1px #e3e8ee" }}>
                <Typography display="inline" gutterBottom={false}>
                  <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Attendees</span>
                </Typography>
                <Button variant="conatined" color="primary" className={[classes.buttonBasic, classes.rightElem]} style={{ marginLeft: "10px" }} onClick={handlePrint}>
                  <PrintIcon />
                </Button>
                {(groupClassDetails.isCanceled ? null : <Button className={[classes.buttonBasic, classes.rightElem]} style={{ color: "white", backgroundColor: "#0079fc" }}
                  onClick={() => { setABP(true); }}
                >
                  Check-in
                </Button>
                )}
              </div>
            </Grid>
            <Grid xs={12} >
              <div className={classes.contentRow} ref={componentRef}>
                {(groupClassDetails.sessionDetails.filter(canc => canc.isCanceled === false).length === 0 ?
                  <div className={classes.topMarginBlock}>
                    <span className={classes.valueTextPad}>
                      No atendees for this group class.
                    </span>
                  </div>
                  :
                  <ListTable
                    styling={{ width: "100%", maxHeight: 250 }}
                    identifier={"attendees"}
                    tableHeaders={(bigEnough ? attendeesHeaders : attendeesHeadersM)}
                    tableRows={groupClassDetails.sessionDetails.filter(canc => canc.isCanceled == false).map((session) => {
                      return ({
                        sessionNumber: (
                          <Link className={classes.valueTextLink} to={`/dashboard/${businessId}/Sessions/${session.sessionId}`}>
                            <Typography noWrap className={classes.valueTextLink}>
                              {session.sessionNumber}
                            </Typography>
                          </Link>),
                        name: <Link to={`/dashboard/${businessId}/Attendees/${session.shareId}`} className={classes.valueTextLink}>
                          <Typography noWrap className={classes.valueTextLink} >
                            {`${session.attendeeFirstName} ${session.attendeeLastName}`}
                          </Typography>
                        </Link>,
                        optionName: session.optionName,
                        optionType: session.optionType,
                        attendance: (session.attendanceRecord ? (session.attendanceRecord == "Absent" ? <StatusTag wrapperName="redWrapper" value="Absent" />: (session.attendanceRecord == "Present" ? <StatusTag wrapperName="greenWrapper" value="Present" /> :<StatusTag wrapperName="grayWrapper" value="Excused" />) ) : null)
                      })
                    })}
                    rowClick={null}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Canceled Sessions</span>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className={classes.contentRow}>
              {(groupClassDetails.sessionDetails.filter(canc => canc.isCanceled == true).length === 0 ?
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    No cancelations for this group class.
                  </span>
                </div>
                :
                <ListTable
                  styling={{ width: "100%", maxHeight: 250 }}
                  identifier={"attendees"}
                  tableHeaders={(bigEnough ? attendeesHeaders : attendeesHeadersM)}
                  tableRows={groupClassDetails.sessionDetails.filter(canc => canc.isCanceled == true).map((session) => {
                    return ({
                      sessionNumber: (
                        <Link className={classes.valueTextLink} to={`/dashboard/${businessId}/Sessions/${session.sessionId}`}>
                          <Typography noWrap className={classes.valueTextLink}>
                            {session.sessionNumber}
                          </Typography>
                        </Link>),
                      name: <Link to={`/dashboard/${businessId}/Attendees/${session.shareId}`} className={classes.valueTextLink}>
                        <Typography noWrap className={classes.valueTextLink} >
                          {`${session.attendeeFirstName} ${session.attendeeLastName}`}
                        </Typography>
                      </Link>
                    })
                  })}
                  rowClick={null}
                />
              )}
            </div>
          </Grid>
          <ResourceTable
            classes={classes}
            businessId={businessId}
            resourceDetails={(groupClassDetails?.resourceDetails ?? [])}
            bigEnough={bigEnough}
            eventId={groupClassDetails.classId}
            eventType="Class"
            fetchDetails={() => setUp()}
            siteId={groupClassDetails.siteId}
            admin
          />
          {(!groupClassDetails.isVirtual ?
            <React.Fragment>
              <Grid item xs={12}>
                <Typography className={classes.headingWrapper}>
                  <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Address</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentRow}>
                  <Typography className={classes.valueText} style={{ paddingBottom: "10px" }}>
                    <b>Address:</b> {groupClassDetails.address2 ? `${groupClassDetails.address1}, ${groupClassDetails.address2}` : `${groupClassDetails.address1}`}
                  </Typography>
                  <div style={{ height: 400, width: "100%", justify: "center" }}>
                    <MapContainer siteDetails={{
                      geoPoint: {
                        lat: groupClassDetails.geoPoint[1],
                        lon: groupClassDetails.geoPoint[0]
                      }
                    }}
                      options={{
                        disableDefaultUI: true,
                        zoomControl: false,
                        gestureHandling: 'none'
                      }} />
                  </div>
                </div>
              </Grid>
            </React.Fragment>
            : null)}
          {(groupClassDetails.isVirtual ?
            <React.Fragment>
              <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                  <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Web Conference Info</span>
                  {(groupClassDetails.isCanceled ? null :
                    <Button
                      className={classes.rightElem}
                      onClick={() => { setEditWebConf(true) }}
                    >
                      <EditIcon />
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid xs={12}>
                <div className={classes.contentRow}>
                  <TextareaAutosize
                    variant="outlined"
                    minRows={8}
                    value={groupClassDetails.onlineConferenceInfo ? groupClassDetails.onlineConferenceInfo : "No details provide"}
                    readOnly
                    className={classes.valueText}
                    style={{ width: '100%', resize: 'none', padding: "10px", boxSizing: "border-box" }}
                  />
                </div>
              </Grid>
            </React.Fragment>
            : null)}
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Timeline</span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow} >
              <Timeline style={{ width: 0, padding: 0 }}>
                {(groupClassDetails.isCanceled ?
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography className={classes.valueText}>
                        Canceled By: {`${groupClassDetails.canceledByStaffFirstName} ${groupClassDetails.canceledByStaffLastName}`}
                      </Typography>
                      <Typography className={classes.labelText}>
                        At: {moment(groupClassDetails.canceledAt).format("MMM, Do, YYYY : h:mm A ")}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  :
                  null)}
                {groupClassDetails.rescheduleHistory.map((hist) => {
                  return (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography className={classes.valueText}>
                          {`Rescheduled By: ${hist.rescheduledByStaffFirstName} ${hist.rescheduledByStaffLastName}`}
                        </Typography>
                        <Typography className={classes.labelText}>
                          {`Original Time: ${hist.oldTime}`}
                        </Typography>
                        <Typography className={classes.labelText}>
                          At: {moment(hist.rescheduledAt).format("MMM, Do, YYYY : h:mm A ")}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  )
                })}
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography className={classes.valueText}>
                      Created By: {`${groupClassDetails.createdByStaffFirstName} ${groupClassDetails.createdByStaffLastName}`}
                    </Typography>
                    <Typography className={classes.labelText}>
                      At: {moment(groupClassDetails.createdAt).format("MMM, Do, YYYY : h:mm A ")}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
          </Grid>
          {groupClassDetails.classFeedbacks.length > 0 ?
            <React.Fragment>
              <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                  <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Feedback</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentRow} >
                    <BusinessFeedbackList bigEnough = {bigEnough} feedback = {groupClassDetails.classFeedbacks} businessId = {businessId}/>
                </div>
              </Grid>
            </React.Fragment>
          :null}
          <Grid xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default GroupClass;
