import React from 'react'
import { Container, makeStyles } from '@material-ui/core'
import Form from '../../../Form/Form'
import CModal from '../../../Form/CModal'
import {customerUpdateProfile } from '../../../../utils'
import notEmpty from '../../../../FormValidation/notEmpty'
import noWhiteSpace from '../../../../FormValidation/noWhiteSpace'
import maxTextField from '../../../../FormValidation/maxTextField'
import validPhoneNumber from '../../../../FormValidation/phonenumber'
const useStyles = makeStyles((theme) => ({
    formContainer: {
      border: '20px',
      backgroundColor: 'white',
      borderRadius: '4px',
      padding: "20px",
      width:"100%",
      maxWidth: "600px",
      maxHeight: "80vh",
      overflowY: "auto"
    }
  }))

const UpdateProf = (props) => {
    const classes = useStyles()
    const refresh = (response) => {
        if (response) {
            props.setOpen(false)
            props.refresh()
        }
    }
    if (props.customerDetails) {
        return (
            <CModal open={props.open} setOpen={props.setOpen}>
                <Container component="main" className={classes.formContainer}>
                <Form
                        title="Update Profile"
                        cancelEnabled
                        cancelAction={() => { props.setOpen(false) }}
                        submitText="Save"
                        action={customerUpdateProfile}
                        postAction={refresh}
                        inputFields={[
                            {
                                type: "TextField",
                                name: "firstName",
                                label: "First Name",
                                validator: [notEmpty, noWhiteSpace, maxTextField],
                                initialvalue: props.customerDetails.firstName

                            },
                            {
                                type: "TextField",
                                name: "lastName",
                                label: "Last Name",
                                validator: [notEmpty, noWhiteSpace, maxTextField],
                                initialvalue: props.customerDetails.lastName

                            },
                            {
                                type: "PhoneNumber",
                                name: "phoneNumber",
                                label: "Phone Number",
                                initialvalue: props.customerDetails.phoneNumber,
                                validator: validPhoneNumber

                            }

                        ]}
                    />
                </Container>
            </CModal>
        )
    }
    else{
        return null
    }

}

export default UpdateProf