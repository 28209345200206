import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Form from '../../../../../Form/Form'
import CModal from '../../../../../Form/CModal'
import { Container } from '@material-ui/core';
import { makeStyles} from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import validEmail from '../../../../../../FormValidation/validEmail';
import validPhoneNumber from '../../../../../../FormValidation/phonenumber';
import maxTextField from '../../../../../../FormValidation/maxTextField';
import maxTextArea from '../../../../../../FormValidation/maxTextArea';
import { updateStaff } from '../../../../../../utils';

const useStyles = makeStyles((theme)=>({
    formContainer:{
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))

const EditProfileForm= (props) => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()
    const history = useHistory();
    const location = useLocation();

    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main"  className = {classes.formContainer}>
                <Form
                    moreData = {{
                        businessId:businessId,
                        staffId: props.staffDetails.staffId
                    }}
                    title="Edit Staff Profile"
                    cancelEnabled
                    cancelAction = {()=>{props.setOpen(false)}}
                    submitText="Save"
                    action={updateStaff}
                    postAction = {()=>{
                        props.setOpen(false)
                        props.refresh()

                    }}
                    inputFields={[
                        {
                            type:"TextField",
                            name: "firstName",
                            label: "First Name",
                            validator: [notEmpty, noWhiteSpace, maxTextField],
                            initialvalue: props.staffDetails.firstName
                        },
                        {
                            type:"TextField",
                            name: "lastName",
                            label: "Last Name",
                            validator: [notEmpty, noWhiteSpace, maxTextField],
                            initialvalue: props.staffDetails.lastName
                        },
                        {
                            type:"TextField",
                            name: "email",
                            label: "Email",
                            validator: [notEmpty, validEmail, maxTextField],
                            initialvalue: props.staffDetails.email
                        },
                        {
                            type:"PhoneNumber",
                            name: "phoneNumber",
                            label: "Phone Number",
                            validator: validPhoneNumber,
                            initialvalue: props.staffDetails.phoneNumber
                        },
                        {
                            type:"TextArea",
                            name: "profile",
                            resizeEnabled:true,
                            moreProps:{
                                minRows:8
                            },
                            label: "Profile",
                            validator: [noWhiteSpace, maxTextArea],
                            initialvalue: props.staffDetails.profile
                        },
                    ]}
                />
            </Container>
        </CModal>
    );
};

export default EditProfileForm