import { FormHelperText, MenuItem, Typography } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import CheckIcon from '@material-ui/icons/Check';
import React, { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    comp:
    {
        width: "100%"
    },
    formLabel: {
        fontSize: 14,
        color: "#3C4257"
    },
    showChoice: {
        fontSize: 14,
        color: "#1A1F36",
        padding: "10px",
    },
    fieldText: {
        fontSize: 14,
        color: "#1A1F36",
        padding: "10px",
        paddingLeft: "31px"
    },
    helperText: {
        marginRight: 14,
        marginLeft: 14
    },
    selected: {
        fontSize: 14,
        color: theme.palette.primary.main,
        padding: "10px"
    },
    check: {
        fontSize: 14,
        paddingRight: 7
    },
    menuPaper: {
        maxHeight: 220,
    },
    noPadding: {
        padding: 0
    }
}));

const CMultiSelect = (props) => {
    const [width, setWidth] = useState(null)
    const comp = useRef(null);
    const [open, setOpen] = useState(false)
    const classes = useStyles();
    const [choices, setChoices] = useState((props.initialvalue ? props.initialvalue : []));
    const handleChange = (event) => {
        setChoices(event.target.value);
    };
    useEffect(() => {
        if (props.initialvalue && props.initialvalue !== choices) {
            props.updateGlob(choices)
        }

        else if (props.initialvalue) {
            props.updateGlob(props.initialvalue)
        }
        else {
            props.updateGlob([])
        }

    }, [])
    useEffect(() => {
        setWidth(comp.current.clientWidth)
    }, [choices])





    const valtoKey = {}
    if (Array.isArray(props.choices)) {
        for (let i = 0; i < props.choices.length; i++) {
            valtoKey[props.choices[i][1]] = props.choices[i][0]
        }
    }
    const intoInputProps = (props.moreProps && props.moreProps.inputProps ? props.moreProps.inputProps : {})
    intoInputProps.className = classes.showChoice
    const otherProps = { ...props.moreProps, inputProps: intoInputProps }

    if (props.enabled === false) {
        return null
    }
    else {
        return (
            <div className={classes.comp} ref={comp}>
                <Typography className={classes.formLabel} >
                    {props.label}
                </Typography>
                <Select
                    IconComponent={(props) => {
                        return (
                            <ArrowDropDownCircleIcon {...props} style={(open ? { color: "#0079FC" } : null)} />
                        )
                    }}
                    open={open}
                    onOpen={() => { setOpen(true) }}
                    onClose={() => { setOpen(false) }}
                    error={(typeof props.errortext == "string" && props.errortext !== "" ? true : false)}
                    className={classes.comp}
                    MenuProps={{
                        classes: { paper: classes.menuPaper },
                        getContentAnchorEl: null,
                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                        transformOrigin: { vertical: 'top', horizontal: 'center' }
                    }}
                    multiple
                    renderValue={(selected) => {
                        let temp = []
                        selected.forEach((value) => {
                            temp.push(valtoKey[value])
                        })
                        return (temp.join(", "))
                    }}
                    value={choices}
                    variant="outlined"
                    name={props.name}
                    onChange={(event) => {
                        props.updateGlob(event.target.value)
                        handleChange(event)
                        props.onchange(event)
                    }}
                    inputProps={{
                        ...intoInputProps
                    }}
                    {...otherProps}
                >
                    {
                        (Array.isArray(props.choices) ? props.choices : []).map((pair, index) => {
                            return (
                                <MenuItem
                                    key={`${props.name}-${index}`}
                                    value={pair[1]}
                                    style={{maxWidth: width ?? "100%",  width: "100%", display: "block !important", zIndex: 100 }}
                                    classes={{
                                        root: classes.noPadding
                                    }}

                                >
                                    <Typography
                                        className={(choices.includes(pair[1]) ? classes.selected : classes.fieldText)}
                                        style={{
                                            whiteSpace: "pre-wrap"
                                        }}
                                    >
                                        {choices.includes(pair[1]) ? <CheckIcon className={classes.check} /> : null}
                                        {pair[0]}
                                    </Typography>
                                </MenuItem>
                            );
                        })
                    }
                </Select>
                <FormHelperText
                    className={classes.helperText}
                    error={(typeof props.errortext == "string" && props.errortext !== "" ? true : false)}
                >
                    {props.errortext}
                </FormHelperText>
            </div>
        );
    }
}

export default CMultiSelect