import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, CircularProgress } from "@material-ui/core";
import ListTable from "./../../../../../TableComponents/ListTable";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListCard from "../../../../../MobileCards/ListCard";
import EmptyListImage from "../../../../../EmptyListImage/Empty";

import { listDisputes, retrieveGetParams } from "../../../../../../utils";
import Moment from "react-moment";
import CurrencyPreProcessor from "../../../../../CurrencyFormatter/CurrencyFormatter";
import PostFilter, { applyFilters } from "../../../../../Filter/PostFilter";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px"
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  headerPadding: {
    paddingBottom: "10px"
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "inset 0 -1px #e3e8ee"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px"
  }
}));

const DisputesList = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const error = useSelector(state => state.error);
  const errorMessage = useSelector(state => state.errorMessage);
  const small = useMediaQuery('(min-width: 750px)');
  const large = useMediaQuery('(min-width: 960px)');

  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const currencyInfo = useSelector((state) => {
    return state.buisnessDetails.operatingUnitInfo;
  });

  const disputesHeaders = [
    {
      name: "status",
      columnKey: "status",
      columnWidth: "100px",
      tooltip: true
    },
    {
      name: "amount",
      columnKey: "amount",
      columnWidth: "100px",
      type: "Normal"
    },
    {
      name: "created on",
      columnKey: "createdDate",
      columnWidth: "25%",
      tooltip: true
    },
    {
      name: "due date",
      columnKey: "dateDue",
      columnWidth: "25%",
    },
  ];
  const keyObjects = [{key:'status', label:'Status', keyLabel:'status'}]
  const [disputesList, setDisputesList] = useState([]);
  const [initialState, setInitialState] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const location = useLocation()
  const queryParams = retrieveGetParams(location)
  const processDisputesData = (disputeData) => {
    return disputeData.map((dispute) => {
      let {
        disputeId,
        disputeAmount,
        status,
        createdAt,
        dueDate,
      } = dispute;

      let id = disputeId;
      let amount = <CurrencyPreProcessor
        amount={dispute.disputeAmount}
        currencyBreak={currencyInfo.currencyBreak}
        roundingFactor={currencyInfo.roundingFactor}
        currencySymbol={currencyInfo.currencySymbol}
      />
      let createdDate = <Moment format="MMMM, DD YYYY">{createdAt}</Moment>;
      let dateDue = <Moment format="MMMM, DD YYYY">{dueDate}</Moment>;

      if(status !== "Need Response"){
        dateDue = ""
      };

      return {
        id,
        status,
        amount,
        createdDate,
        dateDue
      };
    });
  };
  const getDisputesDetails = async () => {
    let disputesPayload = await listDisputes({
      businessId: businessId,
      limit: currentLimit,
      page: currentPage
    });
    if (disputesPayload && disputesPayload.disputes) {
      setDisputesList(processDisputesData(disputesPayload.disputes.docs));
      setInitialState(true);
    }
  };

  const navigateToDispute = (disputeId) => {
    history.push(`/dashboard/${businessId}/Disputes/${disputeId}`);
  };

  const ViewChanger = () => {
    if (small) {
      return (
        <ListTable
          identifier={"admin_orders"}
          tableHeaders={disputesHeaders}
          tableRows={applyFilters(disputesList, queryParams, keyObjects)}
          rowClick={navigateToDispute}
        />
      );
    } else {
      return (
        applyFilters(disputesList, queryParams, keyObjects).map((mobileDispute) => {
          return (
            <ListCard
              action={() => { history.push(`/dashboard/${businessId}/Disputes/${mobileDispute.id}`); }}
              cardName={mobileDispute.amount}
              statusReplace={mobileDispute.status}
              other={`${mobileDispute.createdDate}-${mobileDispute.dateDue}`}
            />
          )
        })
      );
    };
  };

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    await getDisputesDetails();
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
  }, [currentPage, currentLimit]);

  if (initialState === null) {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={large ? classes.mainWrapper : !large && small ? classes.mobilePadding : null} >
        <Grid container item xs={12} direction="row" className={small ? null : classes.stickyHeader} style={!large && small ? { paddingTop: "15px" } : null}>
          <Grid item xs={12}>
            <div className={classes.headerPadding}>
              <span className={small ? classes.topHeading : classes.mobileTopHeadingOne}>Disputes</span>
              <PostFilter allEntries = {disputesList} keyObjects = {keyObjects}/>
            </div>
          </Grid>
        </Grid>
        {disputesList.length > 0 && currencyInfo ?
          <Grid item xs={12} className={small ? null : classes.mobilePadding}>
            <div>
              <ViewChanger />
            </div>
          </Grid> : <EmptyListImage name = "Disputes"/>}
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  }
};

export default DisputesList;