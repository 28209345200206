import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { validTime, validTimeRange } from '../../../../../../FormValidation/validTime';
import { rescheduleInternalEvent } from '../../../../../../utils';
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto"

    }
}))

const RescheduleInternalEventForm = (props) => {


    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()

    return (
        <React.Fragment>
            <CModal open={props.open} setOpen={props.setOpen}>
                <Container component="main" className={classes.formContainer}>
                    <Form
                        moreData={{
                            businessId: businessId,
                            eventId: props.internalEventDetails.eventId,
                            editTag: props.internalEventDetails.editTag,
                            seriesId: props.internalEventDetails.seriesId
                        }}
                        title="Reschedule Internal Event"
                        cancelEnabled
                        cancelAction={() => { props.setOpen(false) }}
                        submitText="Reschedule"
                        action={rescheduleInternalEvent}
                        postAction={(res) => {
                            if (res && !res.error) {
                                props.setOpen(false)
                                props.fetchInternalEventDetails()
                            }
                        }}
                        inputFields={[
                            {
                                type: "Date",
                                name: "eventDate",
                                label: "Date",
                                validator: value => (!value ? "Enter date" : null),
                                initialvalue: moment(props.internalEventDetails.startTimeUTC).tz(props.internalEventDetails.zoneCode).format("YYYY/MM/DD")
                            },
                            {
                                type: "TimeRange",
                                name: ["startTime", "endTime"],
                                label: ["Start Time", "End Time"],
                                validator: [
                                    times => times.map(validTime),
                                    validTimeRange
                                ],
                                initialvalue: [
                                    moment(props.internalEventDetails.startTimeUTC).tz(props.internalEventDetails.zoneCode).format("HH:mm"),
                                    moment(props.internalEventDetails.endTimeUTC).tz(props.internalEventDetails.zoneCode).format("HH:mm")
                                ]
                            }
                        ]}
                    />
                </Container>
            </CModal>
        </React.Fragment>
    )
}

export default RescheduleInternalEventForm