import { Button, makeStyles, Typography, Container, Grid, Select, MenuItem } from "@material-ui/core"
import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import CButtonPair from "../../../Form/CButtonPair"
import CModal from "../../../Form/CModal"
import CModalError from "../../../Form/CModalError"
import CTitle from "../../../Form/CTitle"
import { withLoading, customerCards, changePaymentMethod } from "../../../../utils"
import NewPayment from "../../../Checkout/NewPayment"
const useStyles = makeStyles(theme => ({
    buttontext: {
        textTransform: "none"
    },
    button: {
        float: 'right'
    },
    formContainer: {
        border: "20px",
        backgroundColor: "white",
        borderRadius: "4px",
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto",
    },
    comp:
    {
        width: "100%"
    },
    formLabel: {
        fontSize: 14,
        color: "#3C4257"
    },
    fieldText: {
        fontSize: 16,
        color: "#1A1F36",
        padding: "10px",
        paddingLeft: "31px"
    },
    showChoice: {
        fontSize: 14,
        color: "#1A1F36",
        padding: "10px",
    },
    helperText: {
        marginRight: 14,
        marginLeft: 14
    },
    selected: {
        fontSize: 14,
        color: theme.palette.primary.main,
        padding: "10px"
    },
    check: {
        fontSize: 14,
        paddingRight: 7
    },
    menuPaper: {
        maxHeight: 220,
    },
    noPadding: {
        padding: 0
    }
}))




const getNewCardInfo = async (stripe, cardElement, save) => {

    if (!stripe || !cardElement) {
        return undefined;
    }
    const { error, token } = await stripe.createToken(cardElement)
    if (error) {

        return {
            error: error
        }
    }
    else if (token) {
        return {
            token: token.id,
            brand: token.card.brand,
            last4: token.card.last4,
            save: save
        }
    }
}

const getLabel = (card, mapping) => {
    if(card && mapping.current!==null){
        return card.newCard ?
        <span><b>New Card</b></span>
        : mapping[card.cardId]
    }
    return ''
}

const ChangePayment = (props) => {
    const classes = useStyles()
    const [paymentMethods, setPaymentMethods] = useState([])
    const [newCustomerCard, setNewCustomerCard] = useState(null)
    const [choice, setChoice] = useState(null)
    const { orderDetails, refresh } = props
    const [open, setOpen] = useState(false)
    const error = useSelector(state => state.error)
    const dispatch = useDispatch()
    const mapping = useRef(null)
    const handleSubmit = async () => {
        if (choice) {
            const payload = {
                orderId: orderDetails.orderId
            }
            if (choice.newCard) {
                const { stripe, cardElement, save } = newCustomerCard
                const newCardData = await getNewCardInfo(stripe,cardElement,save)
                if (newCardData.error) {
                    dispatch({
                        type: "UPDATE",
                        payload: {
                            error: true,
                            errorMessage: newCardData.error.message
                        }
                    })
                    return
                }
                payload.newCard = true
                payload.token = newCardData.token
            }
            else {
                payload.newCard = false
                payload.cardId = choice.cardId
            }
            if (payload.cardId || (payload.newCard && payload.token)) {
                const response = await withLoading(changePaymentMethod, payload)
                if (response && !response.error) {
                    setOpen(false)
                    refresh()
                }
            }
            else {
                dispatch({
                    type: "UPDATE",
                    payload: {
                        error: true,
                        errorMessage: "There was an error updating card information. Please contact support."
                    }
                })
            }
        }
        else {
            dispatch({
                type: "UPDATE",
                payload: {
                    error: true,
                    errorMessage: "Please make a selection."
                }
            })
        }
    }

    const setUp = async () => {
        const response = await withLoading(customerCards, {}, true)
        const allCards = []
        const newMapping = {}
        if (response && response.cardDetails) {
            for (let card of response.cardDetails) {
                if (card.brand !== orderDetails.cardOnFileDetails.brand || card.last4 !== orderDetails.cardOnFileDetails.last4) {
                    allCards.push(card)
                    newMapping[card.cardId] = <span><b>{card.brand}</b>{` ending in ${card.last4}`}</span>
                }
            }
            allCards.push({ newCard: true })
            mapping.current = newMapping
            setPaymentMethods(allCards)
            setChoice(allCards[0])
        }
    }

    useEffect(() => {
        if (open) {
            setUp()
        }
        else {
            setPaymentMethods([])
            mapping.current = null
        }
    }, [open])

    return (
        <React.Fragment>
            <Button
                className={classes.button}
                onClick={() => { setOpen(true) }}
                variant="contained"
                color="primary"
            >
                <Typography className={classes.buttontext}>
                    Update
                </Typography>
            </Button>
            <CModal
                open={open}
                setOpen={setOpen}
            >
                <Container className={classes.formContainer}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CTitle title="Update Payment Method" />
                        </Grid>
                        <Grid item xs={12}>
                            <CModalError />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Payment Method
                            </Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                value={choice}
                                inputProps={{
                                    className: classes.showChoice
                                }}
                                MenuProps={{
                                    classes: { paper: classes.menuPaper },
                                    getContentAnchorEl: null,
                                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                                    transformOrigin: { vertical: 'top', horizontal: 'center' }
                                }}
                                onChange={(event) => {
                                    setChoice(event.target.value)
                                }}
                                renderValue={(value) => {
                                    if ((value.newCard || value.cardId) && mapping.current) {
                                        return getLabel(value, mapping.current)
                                    }
                                    else {
                                        return ''
                                    }
                                }}
                            >
                                {mapping.current !== null ?
                                    paymentMethods.map((card, index) =>
                                        <MenuItem
                                            value={card}
                                            key={`Payment Method ${index}`}
                                        >
                                            {getLabel(card, mapping.current)}
                                        </MenuItem>
                                    ) : null}
                            </Select>
                        </Grid>
                        {
                            choice && choice.newCard ?
                                <Grid item xs={12}>
                                    <NewPayment setNewCustomerCard={setNewCustomerCard} setErrors={() => { }} installment={true} />
                                </Grid>
                                : null
                        }
                        <Grid item xs={12}>
                            <CButtonPair
                                action={handleSubmit}
                                cancelEnabled
                                notSubmit
                                cancelAction={() => { setChoice(null); setOpen(false) }}
                                errors={[]}
                                disableSubmit={error}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </CModal>
        </React.Fragment>
    )
}

export default ChangePayment