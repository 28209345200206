import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import InputMask from 'react-input-mask'
import { fromArmyTime } from '../../FormValidation/validTime';
const useStyles = makeStyles((theme) => ({
    comp:
    {
        width: "100%"
    },
    formLabel: {
        fontSize: 14,
        color: "#3C4257"
    },
    fieldText: {
        fontSize: 16,
        color: "#1A1F36",
        padding: "10px"
    }
}));
/*
Required params: onchange, name
Optional: label, intialvalue
*/
const CTime = (props) => {
    const classes = useStyles();
    const intoInputProps = (props.moreProps && props.moreProps.InputProps ? props.moreProps.InputProps : {})
    intoInputProps.classes = { input: classes.fieldText };
    const otherProps = { ...props.moreProps, InputProps: intoInputProps }
    return (
        <div>
            <Typography className={classes.formLabel}>
                {props.label}
            </Typography>


            <InputMask
                name = {props.name}
                error={props.errortext}
                helperText={props.errortext}
                onChange={props.onchange}
                placeholder= "09:00:am"
                defaultValue={props.initialvalue?fromArmyTime(props.initialvalue):undefined}
                mask='19:69:AM'
                maskChar='-'
                formatChars={{
                    '1': '[0-1]',
                    '6': '[0-6]',
                    '9': '[0-9]',
                    'A': '[ap]',
                    '*': '[A-Za-z0-9]',
                    'M': '[m]'
                }}
            >
                {(inputProps) =>
                    <TextField
                        className={classes.comp}
                        variant="outlined"
                        {...otherProps}
                        {...inputProps}
                    />}
            </InputMask>

        </div>
    );
}

export default CTime;