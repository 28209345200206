import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router'
import { changeTemplateStatus, getTemplateDetails, removeTemplate, safePush, withLoading, deleteTemplateField } from '../../../../../../utils'
import { useSelector } from 'react-redux'
import { makeStyles, useMediaQuery, Grid, Button, Menu, MenuItem, Typography } from '@material-ui/core'
import { CEllipsisIcon } from '../../../../../Icons/Icons'
import TemplateForm from './TemplateForm'
import GeneralDialog from '../../../../../GeneralDialog/GeneralDialog'
import LightTooltip from '../../../../../Form/LightTooltip'
import StatusTag from '../../../../../TagComponents/StatusTag'
import { CAddIcon } from '../../../../../Icons/Icons'
import FieldForm from './FieldForm'
import ListTable from '../../../../../TableComponents/ListTable'
const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    topBlock: {
        paddingBottom: "15px",
    },
    recordRow: {
        paddingLeft: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRoght: "5px",
        width: "550px"
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    rightElem: {
        float: "right",
    },
    rightElemPad: {
        float: "right",
        marginRight: "10px",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeadingOne: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    topHeadingTwo: {
        fontSize: "28px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    topHeadingThree: {
        backgroundColor: "#eb5757",
        opacity: "50%",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    topHeadingFour: {
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    buttonBasic: {
        backgroundColor: "#ffffff",
        color: "#1a1f36",
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    contentColumnFirst: {
        paddingRight: "20px",
        boxShadow: "inset -1px 0 #e3e8ee",
        display: "inline-block",
    },
    contentColumnMiddle: {
        boxShadow: "inset -1px 0 #e3e8ee",
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "inline-block",
    },
    contentColumnLast: {
        paddingLeft: "20px",
        display: "inline-block",
    },
    labelText: {
        fontSize: "14px",
        color: "#697386",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText2: {
        fontSize: "14px",
        color: "#3c4257",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextNeg: {
        fontSize: "14px",
        color: "#009211",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBG: {
        fontSize: "16px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBold: {
        fontSize: "14px",
        color: "#1a1f36",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextPad: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingLeft: "10px",
    },
    valueTextLink: {
        fontSize: "14px",
        color: "#3d5af1",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    errorText: {
        fontSize: "14px",
        color: "#ff0000",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    blockLabel: {
        paddingTop: "10px",
        paddingBottom: "5px",
    },
    blockText: {
        paddingLeft: "10px",
    },
    subHeading: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    fieldRows: {
        paddingTop: "8px"
    },
    fieldColumnOne: {
        width: "90px",
        display: "inline-block",
    },
    fieldColumnTwo: {
        display: "inline-block",
    },
    sdCustomCellOne: {
        border: "none",
        width: "10%",
        maxWidth: "200px",
        padding: "10px",
    },
    sdCustomCellTwo: {
        border: "none",
        width: "25%",
        maxWidth: "300px",
        padding: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingThree: {
        fontSize: "20px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    sdCustomCellThree: {
        border: "none",
        width: "10%",
        maxWidth: "200px",
        padding: "10px",
    },
    sdCustomCellFour: {
        border: "none",
        padding: "10px",
    },
    headerPadding: {
        padding: "10px",
        paddingLeft: "0px"
    },
    bottomSpacer: {
        height: "50px",
    },
    topMarginBlock: {
        marginTop: "10px",
    },
    loaderWrapper: {
        width: "100%",
        height: "calc(100vh - 64px)"
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    },
    media: {
        height: 500,
        objectFit: "cover"
    }
}));

const textMapping = {
    "singleSelect": "Single Select",
    "text": "Text",
    "yes/no": "Yes/No"
}

const fieldHeaders = [
    {
        name: "type",
        columnKey: "type",
        columnWidth: "200px",
    },
    {
        name: "name",
        columnKey: "fieldName",
        columnWidth: "40%",
    },
    {
        name: "options",
        columnKey: "options",
        columnWidth: "60%",
        type: "Normal"
    },
    {
        name: "",
        columnKey: "action",
        columnWidth: "100px",
        type: "Normal"
    },
]

const fieldHeadersM = [
    {
        name: "name",
        columnKey: "fieldName",
        columnWidth: "100%",
    },
    {
        name: "",
        columnKey: "action",
        columnWidth: "100px",
        type: "Normal"
    },
]

const resourceHeader = [
    {
        name: "status",
        columnKey: "status",
        columnWidth: "100px",
        type: "Normal"
    },
    {
        name: "resource name",
        columnKey: "name",
        columnWidth: "100%",
    },
]


const TemplateDetails = (props) => {
    const classes = useStyles()
    const { basePath } = props
    const { templateIdURL } = useParams()
    const businessId = useSelector((state) => {
        return state.buisnessDetails.businessId;
    });
    const location = useLocation()
    const history = useHistory()
    const [templateDetails, setTemplateDetails] = useState(null)
    const [editTemplate, setEditTemplate] = useState(false)
    const [statusTemplate, setStatusTemplate] = useState(false)
    const [deleteTemplate, setDeleteTemplate] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [addField, setAddField] = useState(false)
    const [delField, setDelField] = useState(false)

    const bigEnough = useMediaQuery('(min-width: 750px)');
    const fetchTemplateDetails = async () => {
        const templateResponse = await withLoading(getTemplateDetails, {
            businessId,
            templateId: templateIdURL
        }, true)
        if (!templateResponse?.error && templateResponse?.templateDetails) {
            setTemplateDetails(templateResponse.templateDetails)
        }
    }

    useEffect(() => {
        fetchTemplateDetails()
    }, [])

    if (templateDetails) {
        return (
            <React.Fragment>
                <FieldForm open={addField} setOpen={setAddField} refetch={fetchTemplateDetails} templateDetails={templateDetails} />
                <TemplateForm
                    template={templateDetails}
                    refresh={fetchTemplateDetails}
                    open={editTemplate}
                    setOpen={setEditTemplate}
                />
                <GeneralDialog
                    open={statusTemplate}
                    close={setStatusTemplate}
                    title={`Make Resource Type ${templateDetails.isActive ? 'Inactive' : 'Active'}`}
                    text={`Are you sure you want to make this resource type ${templateDetails.isActive ? 'inactive' : 'active'}?`}
                    action={async () => {
                        const statusResponse = await withLoading(changeTemplateStatus, {
                            businessId: businessId,
                            templateId: templateDetails.templateId,
                            editTag: templateDetails.editTag,
                            isActive: !templateDetails.isActive
                        })
                        if (statusResponse && !statusResponse.error) {
                            setStatusTemplate(false)
                            await fetchTemplateDetails()
                        }
                    }}
                />
                <GeneralDialog
                    open={delField}
                    close={setDelField}
                    title={`Delete Attribute`}
                    text={`Are you sure you want to delete this attribute?`}
                    action={async () => {
                        const delFieldResponse = await withLoading(deleteTemplateField, {
                            businessId: businessId,
                            templateId: templateDetails.templateId,
                            editTag: templateDetails.editTag,
                            fieldId: delField.fieldId

                        })
                        if (delFieldResponse && !delFieldResponse.error) {
                            setDelField(false)
                            await fetchTemplateDetails()
                        }
                    }}
                />
                {templateDetails.isActive === false ?
                    <GeneralDialog
                        open={deleteTemplate}
                        close={setDeleteTemplate}
                        title="Delete Resource Type"
                        text="Are you sure you want to delete this resource type?"
                        action={async () => {
                            const deleteResponse = await withLoading(removeTemplate, {
                                businessId: businessId,
                                templateId: templateDetails.templateId,
                                editTag: templateDetails.editTag,
                            })
                            if (deleteResponse && !deleteResponse.error) {
                                safePush(basePath, history, location)
                            }
                        }}
                    />
                    : null}
                <Menu
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    keepMounted

                    open={Boolean(anchorEl)}
                    onClose={() => { setAnchorEl(null) }}
                >
                    <MenuItem onClick={() => { setAnchorEl(null); setStatusTemplate(true) }}>
                        <Typography >
                            {templateDetails.isActive ? "Deactivate" : "Activate"} Resource Type
                        </Typography>
                    </MenuItem>
                    {!templateDetails.isActive ?
                        <MenuItem onClick={() => { setAnchorEl(null); setDeleteTemplate(true) }}>
                            <Typography >
                                Delete Resource Type
                            </Typography>
                        </MenuItem> : null}
                </Menu>
                <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                    <Grid item xs={12}>
                        <div className={classes.topBlock}>
                            <span className={classes.topHeading}>Resource Type</span>
                            <Button onClick={(event) => { setAnchorEl(event.target); }} className={[classes.buttonBasic, classes.rightElem].join(" ")}>
                                <CEllipsisIcon />
                            </Button>
                        </div>
                    </Grid>
                    <Grid item style={(bigEnough ? { maxWidth: "50%" } : { width: "100%" })}>
                        <div className={classes.headerPadding}>
                            <LightTooltip title={templateDetails.name} arrow>
                                <Typography className={(bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne)} noWrap>
                                    {templateDetails.name}
                                </Typography>
                            </LightTooltip>
                        </div>
                    </Grid>
                    {(bigEnough ?
                        <Grid item>
                            <div className={classes.headerPadding} style={{ paddingLeft: "20px" }}>
                                <span className={classes.topHeadingFour}>
                                    <StatusTag wrapperName={templateDetails.isActive ? "greenWrapper" : "redWrapper"} value={templateDetails.isActive ? "Active" : "Inactive"} />
                                </span>
                            </div>
                        </Grid>
                        :
                        <Grid item>
                            <div >
                                <StatusTag wrapperName={templateDetails.isActive ? "greenWrapper" : "redWrapper"} value={templateDetails.isActive ? "Active" : "Inactive"} />
                            </div>
                        </Grid>
                    )}
                    <Grid item xs={12} style={{ paddingTop: '40px' }}>
                        <div className={classes.headingWrapper}>
                            <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Attributes</span>
                            <div className={classes.rightElem}>
                                <Button onClick={() => { setAddField(true) }} className={[classes.buttonBasic, classes.rightElem].join(" ")}>
                                    <CAddIcon />
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.contentRow}>
                            {
                                templateDetails.fields.length > 0 ?
                                    <ListTable
                                        styling={{ maxHeight: 300, width: "100%" }}
                                        identifier={"fields"}
                                        tableHeaders={bigEnough ? fieldHeaders : fieldHeadersM}
                                        tableRows={templateDetails.fields.map(field => ({
                                            id: field.fieldId,
                                            fieldName: field.fieldName,
                                            type: textMapping[field.type],
                                            action: (
                                                <Button onClick={() => {
                                                    setDelField(field)
                                                }}>
                                                    <Typography style={{ textTransform: 'none', color: 'red', fontSize: '14px' }}>
                                                        Delete
                                                    </Typography>
                                                </Button>
                                            ),
                                            options: (field?.options?.length > 0 ?
                                                <LightTooltip title={
                                                    <ul style={{ paddingLeft: 16 }}>
                                                        {field.options.map((option, index) => (
                                                            <li key={`tooltip-${field.fieldId}-${index}`}>{option}</li>
                                                        ))}
                                                    </ul>
                                                }>
                                                    <Typography noWrap>
                                                        {field.options.map(option => `"${option}"`).join(', ')}
                                                    </Typography>
                                                </LightTooltip>
                                                :
                                                <Typography noWrap>
                                                    N/A
                                                </Typography>
                                            )
                                        }))}
                                    />
                                    :
                                    <span>There are no fields for this template.</span>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.headingWrapper}>
                            <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Resources Using This Type</span>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.contentRow}>
                            {
                                templateDetails.resources.length > 0 ?
                                    <ListTable
                                        rowClick={id => safePush(`/dashboard/${businessId}/Resource/${id}`, history, location)}
                                        styling={{ maxHeight: 300, width: "100%" }}
                                        identifier={"resource"}
                                        tableHeaders={resourceHeader}
                                        tableRows={templateDetails.resources.map(resource => ({
                                            id: resource.resourceId,
                                            name: resource.name,
                                            status: (
                                                <StatusTag
                                                    wrapperName={resource.isActive ? "greenWrapper" : "redWrapper"}
                                                    value={resource.isActive ? "Active" : "Inactive"}
                                                />
                                            )
                                        }))}
                                    />
                                    :
                                    <span>
                                        There are no resources using this template.
                                    </span>
                            }
                        </div>
                    </Grid>
                </Grid>
            </React.Fragment >
        )
    }
    else {
        return null
    }
}

export default TemplateDetails