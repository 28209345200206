import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Form from "../Form/Form";
import { Container } from "@material-ui/core";
import CModal from "../Form/CModal";

const useStyles = makeStyles((theme) => ({
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "100%"
  },
  labelText: {
    color: "#3c4257",
    fontSize: "18px",
  },
  fieldText: {
    color: "#1a1f36",
    fontSize: "18px",
  },
  timeZoneText: {
    fontSize: "10px",
  },
  buttonAction: {
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      "rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;",
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflowY: "auto"
  }
}));

const FilterDialog = (props) => {
  const classes = useStyles();
  const [val, setVal] = useState(0);

  const [serviceIdsDialog, setServiceIdsDialog] = useState(
    props.currentFilterVal[0]
  );
  const [siteIdsDialog, setSiteIdsDialog] = useState(props.currentFilterVal[1]);
  const [staffIdsDialog, setStaffIdsDialog] = useState(props.currentFilterVal[2]);
  const [serviceTypesDialog, setServiceTypesDialog] = useState(props.currentFilterVal[3]);
  const [statusTypesDialog, setStatusTypesDialog] = useState(props.currentFilterVal[4]);
  const [modeTypesDialog, setModeTypesDialog] = useState(props.currentFilterVal[5]);

  const [serviceTypesDialogS, setServiceTypesDialogS] = useState(props.currentFilterVal[2]);
  const [statusTypesDialogS, setStatusTypesDialogS] = useState(props.currentFilterVal[3]);
  const [modeTypesDialogS, setModeTypesDialogS] = useState(props.currentFilterVal[4]);

  const [staffIdsDialogR, setStaffIdsDialogR] = useState(props.currentFilterVal[1]);

  const dialogFieldRef = [
    serviceIdsDialog,
    siteIdsDialog,
    staffIdsDialog,
    serviceTypesDialog,
    statusTypesDialog,
    modeTypesDialog
  ];

  const dialogFieldRefS = [
    serviceIdsDialog,
    siteIdsDialog,
    serviceTypesDialogS,
    statusTypesDialogS,
    modeTypesDialogS
  ];

  const dialogFieldRefR = [
    serviceIdsDialog,
    staffIdsDialogR,
    serviceTypesDialogS,
    statusTypesDialogS,
    modeTypesDialogS
  ];

  const dialogFieldRefSR = [
    serviceIdsDialog,
    siteIdsDialog,
    serviceTypesDialogS,
    statusTypesDialogS,
    modeTypesDialogS
  ];

  const fieldLabelsSR = [
    "Services",
    "Sites",
    "Service Type",
    "Status",
    "Service Mode",
  ];

  const fieldNamesSR = [
    "serviceIds",
    "siteIds",
    "serviceTypes",
    "status",
    "mode",
  ];

  const fieldLabelsR = [
    "Services",
    "Staffs",
    "Service Type",
    "Status",
    "Service Mode",
  ];

  const fieldNamesR = [
    "serviceIds",
    "staffIds",
    "serviceTypes",
    "status",
    "mode",
  ];

  const fieldLabels = [
    "Services",
    "Sites",
    "Staffs",
    "Service Type",
    "Status",
    "Service Mode",
  ];

  const fieldNames = [
    "serviceIds",
    "siteIds",
    "staffIds",
    "serviceTypes",
    "status",
    "mode",
  ];

  const fieldLabelsS = [
    "Services",
    "Sites",
    "Service Type",
    "Status",
    "Service Mode",
  ];

  const fieldNamesS = [
    "serviceIds",
    "siteIds",
    "serviceTypes",
    "status",
    "mode",
  ];

  const optionFields = () => {
    if (props.role === "Admin") {
      if(props.page == 'ResourceMap'){
        return [
          {
            type: "MultiSelect",
            label: fieldLabelsR[0],
            choices: props.fieldOptions[0].map((f) => {
              return [f.name, f.id];
            }),
            validator: (value) => { if (value !== null) { setVal(val + 1); } },
            name: fieldNamesR[0],
            initialvalue: dialogFieldRefR[0]
          },
          {
            type: "MultiSelect",
            label: fieldLabelsR[1],
            choices: props.fieldOptions[1].map((f) => {
              return [f.name, f.id];
            }),
            validator: (value) => { if (value !== null) { setVal(val + 1); } },
            name: fieldNamesR[1],
            initialvalue: dialogFieldRefR[1]
          },
          {
            type: "MultiSelect",
            label: fieldLabelsR[2],
            choices: props.fieldOptions[2].map((f) => {
              return [f.name, f.id];
            }),
            validator: (value) => { if (value !== null) { setVal(val + 1); } },
            name: fieldNamesR[2],
            initialvalue: dialogFieldRefR[2]
          },
          {
            type: "MultiSelect",
            label: fieldLabelsR[3],
            choices: props.fieldOptions[3].map((f) => {
              return [f.name, f.id];
            }),
            validator: (value) => { if (value !== null) { setVal(val + 1); } },
            name: fieldNamesR[3],
            initialvalue: dialogFieldRefR[3]
          },
          {
            type: "MultiSelect",
            label: fieldLabelsR[4],
            choices: props.fieldOptions[4].map((f) => {
              return [f.name, f.id];
            }),
            validator: (value) => { if (value !== null) { setVal(val + 1); } },
            name: fieldNamesR[4],
            initialvalue: dialogFieldRefR[4]
          }
        ];
      }  else if (props.page == 'StaffResourceMap'){
        return [
          {
            type: "MultiSelect",
            label: fieldLabelsSR[0],
            choices: props.fieldOptions[0].map((f) => {
              return [f.name, f.id];
            }),
            validator: (value) => { if (value !== null) { setVal(val + 1); } },
            name: fieldNamesSR[0],
            initialvalue: dialogFieldRefSR[0]
          },
          {
            type: "MultiSelect",
            label: fieldLabelsSR[1],
            choices: props.fieldOptions[1].map((f) => {
              return [f.name, f.id];
            }),
            validator: (value) => { if (value !== null) { setVal(val + 1); } },
            name: fieldNamesSR[1],
            initialvalue: dialogFieldRefSR[1]
          },
          {
            type: "MultiSelect",
            label: fieldLabelsSR[2],
            choices: props.fieldOptions[2].map((f) => {
              return [f.name, f.id];
            }),
            validator: (value) => { if (value !== null) { setVal(val + 1); } },
            name: fieldNamesSR[2],
            initialvalue: dialogFieldRefSR[2]
          },
          {
            type: "MultiSelect",
            label: fieldLabelsSR[3],
            choices: props.fieldOptions[3].map((f) => {
              return [f.name, f.id];
            }),
            validator: (value) => { if (value !== null) { setVal(val + 1); } },
            name: fieldNamesSR[3],
            initialvalue: dialogFieldRefSR[3]
          },
          {
            type: "MultiSelect",
            label: fieldLabelsSR[4],
            choices: props.fieldOptions[4].map((f) => {
              return [f.name, f.id];
            }),
            validator: (value) => { if (value !== null) { setVal(val + 1); } },
            name: fieldNamesSR[4],
            initialvalue: dialogFieldRefSR[4]
          }
        ];
      } else {
      return [
        {
          type: "MultiSelect",
          label: fieldLabels[0],
          choices: props.fieldOptions[0].map((f) => {
            return [f.name, f.id];
          }),
          validator: (value) => { if (value !== null) { setVal(val + 1); } },
          name: props.role === "Admin" ? fieldNames[0] : fieldNamesS[0],
          initialvalue: dialogFieldRef[0]
        },
        {
          type: "MultiSelect",
          label: fieldLabels[1],
          choices: props.fieldOptions[1].map((f) => {
            return [f.name, f.id];
          }),
          validator: (value) => { if (value !== null) { setVal(val + 1); } },
          name: fieldNames[1],
          initialvalue: dialogFieldRef[1]
        },
        {
          type: "MultiSelect",
          label: fieldLabels[2],
          choices: props.fieldOptions[2].map((f) => {
            return [f.name, f.id];
          }),
          validator: (value) => { if (value !== null) { setVal(val + 1); } },
          name: fieldNames[2],
          initialvalue: dialogFieldRef[2]
        },
        {
          type: "MultiSelect",
          label: fieldLabels[3],
          choices: props.fieldOptions[3].map((f) => {
            return [f.name, f.id];
          }),
          validator: (value) => { if (value !== null) { setVal(val + 1); } },
          name: fieldNames[3],
          initialvalue: dialogFieldRef[3]
        },
        {
          type: "MultiSelect",
          label: fieldLabels[4],
          choices: props.fieldOptions[4].map((f) => {
            return [f.name, f.id];
          }),
          validator: (value) => { if (value !== null) { setVal(val + 1); } },
          name: fieldNames[4],
          initialvalue: dialogFieldRef[4]
        },
        {
          type: "MultiSelect",
          label: fieldLabels[5],
          choices: props.fieldOptions[5].map((f) => {
            return [f.name, f.id];
          }),
          validator: (value) => { if (value !== null) { setVal(val + 1); } },
          name: fieldNames[5],
          initialvalue: dialogFieldRef[5]
        },
      ];
    }
    } else {
      return [
        {
          type: "MultiSelect",
          label: fieldLabelsS[0],
          choices: props.fieldOptions[0].map((f) => {
            return [f.name, f.id];
          }),
          validator: (value) => { if (value !== null) { setVal(val + 1); } },
          name: fieldNamesS[0],
          initialvalue: dialogFieldRefS[0]
        },
        {
          type: "MultiSelect",
          label: fieldLabelsS[1],
          choices: props.fieldOptions[1].map((f) => {
            return [f.name, f.id];
          }),
          validator: (value) => { if (value !== null) { setVal(val + 1); } },
          name: fieldNamesS[1],
          initialvalue: dialogFieldRefS[1]
        },
        {
          type: "MultiSelect",
          label: fieldLabelsS[2],
          choices: props.fieldOptions[2].map((f) => {
            return [f.name, f.id];
          }),
          validator: (value) => { if (value !== null) { setVal(val + 1); } },
          name: fieldNamesS[2],
          initialvalue: dialogFieldRefS[2]
        },
        {
          type: "MultiSelect",
          label: fieldLabelsS[3],
          choices: props.fieldOptions[3].map((f) => {
            return [f.name, f.id];
          }),
          validator: (value) => { if (value !== null) { setVal(val + 1); } },
          name: fieldNamesS[3],
          initialvalue: dialogFieldRefS[3]
        },
        {
          type: "MultiSelect",
          label: fieldLabelsS[4],
          choices: props.fieldOptions[4].map((f) => {
            return [f.name, f.id];
          }),
          validator: (value) => { if (value !== null) { setVal(val + 1); } },
          name: fieldNamesS[4],
          initialvalue: dialogFieldRefS[4]
        },
      ];
    }
  }

  return (
    <CModal open={props.open} setOpen={props.close}
      children={
        <Container component="main" className={classes.formContainer}>
          <Form
            moreData={{
              length: val
            }}
            action={(event) => { props.applyFilter(event) }}
            postAction={null}
            title="Filter"
            clearEnabled={props.filters ? true : null}
            clearAction={props.clearFilter}
            cancelEnabled
            cancelAction={props.close}
            cancelText="Close"
            submitText="Apply"
            inputFields={optionFields()}
          >
          </Form>
        </Container>
      }
    />
  );
};

export default FilterDialog;
