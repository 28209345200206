import React from 'react';
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import StaffSessionList from "./StaffSessionList";
import StaffSession from "./StaffSessions";

function StaffSessionsHandler() {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    return(
        <React.Fragment>
            <Switch>
            <Route path = {`/dashboard/${businessId}/Sessions`} exact render = {() => {return(<StaffSessionList/>)}} />
            <Route path = {`/dashboard/${businessId}/Sessions/:sessionIdURL`} render = {() => {return(<StaffSession/>);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default StaffSessionsHandler;