export const timeZoneMap = [

    {
        key: "local",
        value: "Local (System Time)",
    },
    {
        key: "America/Adak",
        value: "America/Adak (Hawaii Time)",
    },
    {
        key: "Pacific/Honolulu",
        value: "Pacific/Honolulu (Hawaii Time)",
    },
    {
        key: "America/Anchorage",
        value: "America/Anchorage (Alaska Time)",
    },
    {
        key: "America/Juneau",
        value: "America/Juneau (Alaska Time)",

    },
    {

        key: "America/Metlakatla",
        value: "America/Metlakatla (Alaska Time)",

    },
    {

        key: "America/Nome",
        value: "America/Nome (Alaska Time)",
    },
    {
        key: "America/Sitka",
        value: "America/Sitka (Alaska Time)",
    },
    {
        key: "America/Yakutat",
        value: "America/Yakutat (Alaska Time)",
    },
    {
        key: "America/Los_Angeles",
        value: "America/Los_Angeles (Pacific Time)",
    },
    {
        key: "America/Boise",
        value: "America/Boise (Mountain Time)",
    },
    {
        key: "America/Denver",
        value: "America/Denver (Mountain Time)",
    },
    {
        key: "America/Phoenix",
        value: "America/Phoenix (Mountain Time)",
    },
    {
        key: "America/Chicago",
        value: "America/Chicago (Central Time)",
    },
    {
        key: "America/Indiana/Knox",
        value: "America/Indiana/Knox (Central Time)",
    },
    {
        key: "America/Indiana/Tell_City",
        value: "America/Indiana/Tell_City (Central Time)",
    },
    {
        key: "America/Menominee",
        value: "America/Menominee (Central Time)",
    },
    {
        key: "America/North_Dakota/Beulah",
        value: "America/North_Dakota/Beulah (Central Time)",
    },
    {
        key: "America/North_Dakota/Center",
        value: "America/North_Dakota/Center (Central Time)",
    },
    {
        key: "America/North_Dakota/New_Salem",
        value: "America/North_Dakota/New_Salem (Central Time)",
    },
    {
        key: "America/Detroit",
        value: "America/Detroit (Eastern Time)",
    },
    {
        key: "America/Indiana/Indianapolis",
        value: "America/Indiana/Indianapolis (Eastern Time)",
    },
    {
        key: "America/Indiana/Marengo",
        value: "America/Indiana/Marengo (Eastern Time)",
    },
    {
        key: "America/Indiana/Petersburg",
        value: "America/Indiana/Petersburg (Eastern Time)",
    },
    {
        key: "America/Indiana/Vevay",
        value: "America/Indiana/Vevay (Eastern Time)",
    },
    {
        key: "America/Indiana/Vincennes",
        value: "America/Indiana/Vincennes (Eastern Time)",
    },
    {
        key: "America/Indiana/Winamac",
        value: "America/Indiana/Winamac (Eastern Time)",
    },
    {
        key: "America/Kentucky/Louisville",
        value: "America/Kentucky/Louisville (Eastern Time)",
    },
    {
        key: "America/Kentucky/Monticello",
        value: "America/Kentucky/Monticello (Eastern Time)",
    },
    {
        key: "America/New_York",
        value: "America/New_York (Eastern Time)",
    },

];