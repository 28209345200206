import notEmpty from "../../../../FormValidation/notEmpty"
import noWhiteSpace from "../../../../FormValidation/noWhiteSpace"
import maxTextField from '../../../../FormValidation/maxTextField'
import validEmail from '../../../../FormValidation/validEmail'
import validPhoneNumber from '../../../../FormValidation/phonenumber'
import { pastDate } from '../../../../FormValidation/validDate'


export const generateFilteredPayload = ({ attendeeId, firstName, lastName, email, phoneNumber, gender, dob, relation }, attendee) => {
    return (
        {
            attendeeId,
            firstName,
            lastName,
            email: email ? email : null,
            phoneNumber: phoneNumber.length > 2 ? phoneNumber : null,
            gender,
            dob: dob ? dob : null,
            relation,
            image: attendee && !attendee.isDefaultImage ? attendee.image : null
        }
    )
}

export const generateAttendeeFormFields = (attendee, enabled = true) => (
    [
        {
            type: "TextField",
            name: "firstName",
            label: "First Name*",
            initialvalue: attendee?.firstName,
            validator: [notEmpty, noWhiteSpace, maxTextField],
            enabled
        },
        {
            type: "TextField",
            name: "lastName",
            label: "Last Name*",
            initialvalue: attendee?.lastName,
            validator: [notEmpty, noWhiteSpace, maxTextField],
            enabled
        },
        {
            type: "TextField",
            name: "email",
            label: "Email",
            initialvalue: attendee?.email,
            validator: [noWhiteSpace, maxTextField, (value) => value ? validEmail(value) : null],
            enabled
        },
        {
            type: "PhoneNumber",
            name: "phoneNumber",
            label: "Phone Number",
            initialvalue: attendee?.phoneNumber,
            // If number starts with +1 or is null/undefined, no need to check
            validator: value => value?.length > 2 ? validPhoneNumber(value) : null,
            enabled
        },
        {
            type: "SingleSelect",
            name: "gender",
            label: "Gender",
            initialvalue: attendee?.gender,
            choices: [
                [<br />, null],
                ["Male", "Male"],
                ["Female", "Female"],
                ["Other", "Other"]
            ],
            enabled
        },
        {
            type: "Date",
            name: "dob",
            label: "Date of Birth",
            initialvalue: attendee?.dob,
            validator: [pastDate],
            enabled
        },
        {
            type: "SingleSelect",
            name: "relation",
            label: "Relation",
            initialvalue: attendee?.relation,
            choices: [
                [<br />, null],
                ["Self", "Self"],
                ["Children", "Children"],
                ["Grand Children", "Grand Children"],
                ["Children", "Children"],
                ["Grand Children", "Grand Children"],
                ["Parent", "Parent"],
                ["Grand Parent", "Grand Parent"],
                ["Friend", "Friend"],
                ["Other", "Other"],
            ],
            enabled
        },
    ]
)