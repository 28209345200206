import React, { useState, useEffect } from 'react'
import { Grid, FormHelperText, makeStyles, Typography, Button, useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    comp:
    {
        width: "100%"
    },
    formLabel: {
        fontSize: 14,
        color: "#3C4257"
    },
    fieldTextUnselected: {
        fontSize: 14,
        color: "#1A1F36",
        padding:5,
        textTransform: "none" 
    },
    fieldTextSelected: {
        fontSize: 14,
        color: "white",
        padding:5,
        textTransform: "none" 
    },
    helperText: {
        marginRight: 14,
        marginLeft: 14
    },
    chip: {
        width: "100px",
        padding: "0px"
    }
}));

const CChips = (props) => {
    const classes = useStyles()
    const [usrChoice, setUsrChoice] = useState(null)
    const bigEnough = useMediaQuery("(min-width: 750px)")
    useEffect(() => {
        setUsrChoice(null)
        props.updateGlob(null)
    }, [props.choices])

    return (

        <div className={classes.comp}>
            <Typography className={classes.formLabel} style={{ paddingBottom: "10px" }} align="left">
                {props.label}
            </Typography>
            <Grid container spacing={1} justifyContent = "flex-start" style ={{maxHeight:(bigEnough?"205px":"123px"), overflow:"auto"}}>
                {props.choices.map((choice, index) =>
                    <Grid item>
                        <Button
                            className={classes.chip}
                            key={`timeChoice-${index}`}
                            clickable
                            onClick={() => { setUsrChoice(choice[1]); props.updateGlob(choice[1]); props.onchange({ event: { target: null } }) }}
                            color="primary"
                            variant={(usrChoice === choice[1] ? "contained" : "outlined")}
                        >
                            <Typography className={(usrChoice === choice[1] ? classes.fieldTextSelected : classes.fieldTextUnselected)}>
                                {choice[0]}
                            </Typography>
                        </Button>
                    </Grid>
                )}
            </Grid>
            <FormHelperText
                className={classes.helperText}
                error={(typeof props.errortext == "string" && props.errortext !== "" ? true : false)}
            >
                {props.errortext}
            </FormHelperText>
        </div>

    )
}

export default CChips