import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Form from '../../../Form/Form'
import CModal from '../../../Form/CModal'
import { Container, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import notEmpty from '../../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../../FormValidation/noWhiteSpace';
import { customerCreateReview } from '../../../../utils';
import maxTextArea from '../../../../FormValidation/maxTextArea';
import Rating from '@material-ui/lab/Rating'
import CDisplayArea from '../../../Form/CDisplayArea';
import { Typography } from '@material-ui/core';
import CModalError from '../../../Form/CModalError';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100%",
        maxHeight: "80vh",
        overflowY: "auto"
    },
    formLabel: {
        fontSize: 14,
        color: theme.palette.textColor2.main
    },
}))



const ReviewForm = (props) => {
    const classes = useStyles()
    if (props.view) {
        if (props.sessionDetails) {
            return (
                <CModal open={props.open} setOpen={props.setOpen}>
                    <Container component="main" className={classes.formContainer}>
                        <Grid container spacing = {2}>
                            <CModalError/>
                            <Grid item xs={12} >
                                <Typography noWrap className={classes.formLabel}>
                                    Rating
                                </Typography>
                                <Rating
                                    readOnly
                                    value={props.sessionDetails.reviewDetails.rating}
                                />
                            </Grid>
                            {props.sessionDetails.reviewDetails.comments ?
                                <React.Fragment>
                                    <Typography noWrap className={classes.formLabel}>
                                        More Comments
                                    </Typography>
                                    <CDisplayArea
                                        value={props.sessionDetails.reviewDetails.comments}
                                        initialMaxHeight={"150px"}
                                    />
                                </React.Fragment>
                                : null}
                            <Grid item xs={12}>
                                <Button
                                    variant = "contained"
                                    color = "primary"
                                    style = {{float:"right"}}
                                    onClick = {()=>{props.setOpen(false)}}
                                >
                                    <Typography noWrap style = {{fontSize: 14, textTransform:"none"}}>
                                        Close
                                    </Typography>
                                </Button>

                            </Grid>
                        </Grid>
                    </Container>
                </CModal>)
        }
        else { return null }

    }
    else {
        const refresh = async (response) => {
            if (response) {
                props.setOpen(false)
                props.refresh()
            }
        }
        if (props.sessionDetails) {
            return (
                <CModal open={props.open} setOpen={props.setOpen}>
                    <Container component="main" className={classes.formContainer}>
                        <Form
                            moreData={{ sessionId: props.sessionDetails.sessionId }}
                            title="Review Session"
                            cancelEnabled
                            cancelAction={() => { props.setOpen(false) }}
                            submitText="Review"
                            action={customerCreateReview}
                            postAction={refresh}
                            inputFields={[
                                {
                                    type: "Stars",
                                    name: "rating",
                                    label: "Rating",
                                    validator: (value) => (!value ? "Must select a rating" : null)
                                },
                                {
                                    type: "TextArea",
                                    name: "comments",
                                    resizeEnabled: true,
                                    moreProps: {
                                        minRows: 8,
                                        maxRows: 12
                                    },
                                    label: "Additional Comments",
                                    validator: [noWhiteSpace, maxTextArea]
                                },

                            ]}
                        />
                    </Container>
                </CModal>
            );
        }
        else { return null }
    }
};

export default ReviewForm