import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from '../../../../../Form/Form'
import CModal from '../../../../../Form/CModal'
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import validDates from '../../../../../../FormValidation/validDates';
import { addStaffSchedule, editStaffSchedule, getGenericDetails, staffAddScheduleEvent, staffGenericDetails, staffUpdateScheduleEvent, withLoading } from '../../../../../../utils';
import { validTime } from '../../../../../../FormValidation/validTime';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))

const Assignment = (props) => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const [serviceList, setServiceList] = useState(null)
    const classes = useStyles()

    const refresh = (response) => {
        if (response) {
            props.setOpen(false)
            props.refresh()
        }
    }

    useEffect(() => {
        if (props.open) {
            setServiceList([])
            setUp()
        }

    }, [props.open])


    const filterChoices = (services) => {
        const temp = []
        services.forEach(service => {
            if (service.isActive && service.type === "Private") {
                temp.push([service.name, service.serviceId])
            }
        })
        return temp
    }

    const setUp = async () => {
        let data = null;
        if (props.role === "Admin") {
            data = await withLoading(getGenericDetails, {
                businessId: businessId
            })
        } else {
            data = await withLoading(staffGenericDetails, {
                businessId: businessId
            })
        }

        if (data) {
            setServiceList(filterChoices(data.genericDetails.serviceDetails))
        }
    }




    return (
        <CModal open={props.open} setOpen={props.setOpen}>

            <Container component="main" className={classes.formContainer}>
                <Form
                    moreData={{ businessId: businessId, calendarId: props.calendarDetails.calendarId, scheduleId: (props.edit ? props.open.scheduleId : null) }}
                    title={`${(props.edit ? "Edit" : "Add")} Assignment`}
                    cancelEnabled
                    cancelAction={() => { props.setOpen(false) }}
                    submitText={(props.edit ? "Save" : "Create")}
                    action={(props.role === "Admin" && props.edit ? editStaffSchedule : props.role === "Admin" && !props.edit ? addStaffSchedule : props.role === "Staff" && props.edit ? staffUpdateScheduleEvent : staffAddScheduleEvent)}
                    postAction={refresh}
                    inputFields={[
                        {
                            type: "TimeRange",
                            name: ["startTime", "endTime"],
                            label: ["Start Time", "End Time"],
                            initialvalue: (props.edit ? [props.open.startTime, props.open.endTime] : null),
                            validator: values => [validTime(values[0]), validTime(values[1])]
                        },
                        {
                            type: "SingleSelect",
                            name: "day",
                            label: "Day",
                            enabled: !Boolean(props.edit),
                            choices: [
                                ["Sunday", "sun"],
                                ["Monday", "mon"],
                                ["Tuesday", "tue"],
                                ["Wednesday", "wed"],
                                ["Thursday", "thr"],
                                ["Friday", "fri"],
                                ["Saturday", "sat"],
                            ],
                            validator: (value) => (!value ? "A day must be chosen" : null),
                            initialvalue: (props.edit ? props.open.day : null)
                        },
                        {
                            type: "MultiSelect",
                            name: "services",
                            label: "Services",
                            choices: serviceList,
                            validator: (value) => (value.length === 0 ? "At least one service must be selected" : null),
                            initialvalue: (props.edit ? (props.open ? props.open.serviceInfo.map(service => service.serviceId) : null) : null)
                        }
                    ]}
                />
            </Container>


        </CModal>
    );

};

export default Assignment