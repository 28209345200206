import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState, useEffect } from 'react'
import CModal from '../../../Form/CModal';
import { Container } from "@material-ui/core"
import { makeStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { customerAddCard, withLoading } from '../../../../utils';
import { useDispatch } from 'react-redux';
import CModalError from '../../../Form/CModalError';
const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: "20px",
        backgroundColor: "white",
        borderRadius: "4px",
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto",
    },
    formLabel: {
        fontSize: 20,
        paddingBottom:5,
        color: "#1a1f36",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
}));


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)

const AddPayment = (props) => {

    const classes = useStyles()

    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container className={classes.formContainer}>
                <Elements stripe={stripePromise}>
                    <Form setOpen = {props.setOpen} refresh = {props.refresh}/>
                </Elements>
            </Container>
        </CModal>)
}

const Form = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (e)=>{
        e.preventDefault()
        if (!stripe || !elements) {
            return;
        }
        const cardElement = elements.getElement(CardElement);
        const {error, token} = await stripe.createToken(cardElement)
        if (error) {
            dispatch(
                {
                    type: 'UPDATE',
                    payload:
                    {
                        error: true,
                        errorMessage: (error.message?error.message:"Internal Server Error")
                    }
                }
            );
        }
        else if(token){
            let success = await withLoading(customerAddCard, {
                token:token.id
            })
            if(success){
                props.setOpen(false)
                props.refresh()
            }
        }
    }

    return (
        <form onSubmit = {handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CModalError/>
                    <Grid item xs={12} style={{paddingBottom:"10px"}}>
                       <Typography variant="h6" className={classes.formLabel}>
                            Card Number
                        </Typography> 
                    </Grid>
                    <CardElement
                        options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#1A1F36',
                                    '::placeholder': {
                                        color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="flex-end" spacing = {2}>
                        <Grid item>
                        <Button variant  = "outlined"
                            onClick = {()=>{props.setOpen(false)}}
                        >
                            Cancel
                        </Button>
                        </Grid>
                        <Grid item>
                        <Button variant = "contained" color  = "primary" type="submit">
                            Submit
                        </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </form>
    )
}


export default AddPayment