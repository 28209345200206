import CModal from "../../../../../Form/CModal"
import { Container, makeStyles } from "@material-ui/core"
import Form from "../../../../../Form/Form"
import { editResourceDetails, withLoading } from "../../../../../../utils"
import { useSelector } from "react-redux"
import maxTextArea from '../../../../../../FormValidation/maxTextArea';
import maxTextField from '../../../../../../FormValidation/maxTextField';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import notEmpty from '../../../../../../FormValidation/notEmpty';

const useStyles = makeStyles(theme => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100%",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))

const generateMapping = (resource) => {
    const fieldMapping = new Map()
    if (resource?.fields?.length > 0) {
        resource.fields.forEach(field => {
            fieldMapping.set(field.fieldId, field.value)
        })
    }
    return fieldMapping
}

const handleSubmit = async (payload, resource, businessId) => {
    const editResponse = await withLoading(editResourceDetails, {
        businessId,
        resourceId: resource.resourceId,
        editTag: resource.editTag,
        name: payload.name,
        description: payload.description,
        fields: resource.templateFields.map(field => ({
            fieldId: field.fieldId,
            value: payload[field.fieldId]
        }))
    })
    return editResponse
}

const generateInputFields = (fields, resourceMapping) => {
    const inputFields = []
    fields.forEach(field => {
        switch (field.type) {
            case "singleSelect":
                inputFields.push({
                    type: "SingleSelect",
                    name: field.fieldId,
                    label: field.fieldName,
                    validator: [(value) => (!value ? "Select an option" : null)],
                    choices: field.options.map(option => ([option, option])),
                    initialvalue: resourceMapping.get(field.fieldId)
                })
                break;
            case "text":
                inputFields.push({
                    type: "TextArea",
                    name: field.fieldId,
                    label: field.fieldName,
                    resizeEnabled: false,
                    validator: [notEmpty, noWhiteSpace, maxTextArea],
                    moreProps: {
                        minRows: 8,
                        maxRows: 12
                    },
                    initialvalue: resourceMapping.get(field.fieldId)
                })
                break;
            case "yes/no":
                inputFields.push({
                    type: "RadioSet",
                    name: field.fieldId,
                    label: field.fieldName,
                    choices: [["Yes", "yes"], ["No", "no"]],
                    validator: value => value === null ? "Select yes or no" : null,
                    initialvalue: resourceMapping.get(field.fieldId)
                })
                break;
            default:
                break;
        };
    })
    return inputFields
}

const EditResourceForm = (props) => {
    const classes = useStyles()
    const { open, setOpen, resource, fetchResourceDetails } = props
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    return (
        <CModal open={open} setOpen={setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    title="Edit Resource"
                    cancelEnabled
                    cancelAction={() => { setOpen(false) }}
                    submitText={"Edit"}
                    action={async payload => await handleSubmit(payload, resource, businessId)}
                    postAction={(response) => {
                        if (!response.error) {
                            setOpen(false)
                            fetchResourceDetails()
                        }
                    }}
                    inputFields={[
                        {
                            type: "TextField",
                            name: "name",
                            label: "Resource Name",
                            validator: [notEmpty, noWhiteSpace, maxTextField],
                            initialvalue: resource.name
                        }
                        ,
                        ...generateInputFields(resource.templateFields, generateMapping(resource)),
                        {
                            type: "TextArea",
                            label: "Description (optional)",
                            name: "description",
                            resizeEnabled: false,
                            validator: [maxTextField],
                            moreProps: {
                              rowsMin: 4,
                              rowsMax: 10
                            },
                            initialvalue: resource.description
                        }
                    ]}
                />
            </Container>
        </CModal>
    )
}

export default EditResourceForm