import React, { useState, useEffect } from "react";
import CModal from "../Form/CModal";
import { Container, makeStyles } from "@material-ui/core";
import { withLoading, offeringReviews } from "../../utils";
import { Typography, Grid, Button, IconButton } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import CDisplayArea from "../Form/CDisplayArea";
import CTitle from "../Form/CTitle";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CModalError from "../Form/CModalError";
const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "20px",
    paddingBottom: "20px",
    maxWidth: "600px",
    width: "100vw",
    maxHeight: "80vh",
    minHeight: "60vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  pageNav: {
    marginTop: "auto",
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    display: "flex",
  },
}));

const ViewCRModal = (props) => {
  const offeringId = props.offeringId;
  const [reviews, setReviews] = useState(null);
  const classes = useStyles();

  const nextPage = async () => {
    let data = await withLoading(
      offeringReviews,
      {
        offeringId: offeringId,
        limit: 10,
        page: reviews.page + 1,
      },
      true
    );
    if (data && data.reviews) {
      setReviews(data.reviews);
    }
  };
  const prevPage = async () => {
    let data = await withLoading(
      offeringReviews,
      {
        offeringId: offeringId,
        limit: 10,
        page: reviews.page - 1,
      },
      true
    );
    if (data && data.reviews) {
      setReviews(data.reviews);
    }
  };

  const setUp = async () => {
    if (props.open) {
      let data = await withLoading(
        offeringReviews,
        {
          offeringId: offeringId,
          limit: 10,
          page: 1,
        },
        true
      );
      if (data && data.reviews) {
        setReviews(data.reviews);
      }
    }
  };
  useEffect(() => {
    if (props.open) {
      setUp();
    } else {
      setReviews(null);
    }
  }, [props.open]);
  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Grid container className={classes.formContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{paddingTop: 20, paddingLeft: 20, paddingRight: 20, backgroundColor:"#FFBF00"}}>
            <CTitle title="Customer Reviews" />
          </Grid>
          <CModalError />
          {reviews !== null
            ? reviews.docs.map((review, index) => (
                <Grid item xs={12} key={`review ${index}`} style={{paddingRight: 20, paddingLeft: 20}}>
                  <ReviewBloc review={review} />
                </Grid>
              ))
            : null}
        </Grid>
        <div className={classes.pageNav}>
          <div style={{ flex: 1 }} />
          <div style={{ width: 48 }}>
            {reviews && reviews.hasPrevPage === true ? (
              <IconButton onClick={prevPage}>
                <ChevronLeftIcon />
              </IconButton>
            ) : null}
          </div>
          <div style={{ width: 48 }}>
            {reviews && reviews.hasNextPage === true ? (
              <IconButton onClick={nextPage}>
                <ChevronRightIcon />
              </IconButton>
            ) : null}
          </div>
          <div style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                justifyContent: "flex-end",
                paddingRight: 20
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Grid>
    </CModal>
  );
};

const ReviewBloc = (props) => {
  const review = props.review;
  return (
    <Grid container>
      <Grid item>
        <Rating readOnly value={review.rating} />
      </Grid>
      <Grid item style={{ width: "calc(100% - 130px)", marginLeft: 10 }}>
        <Typography noWrap variant="body1">
          {`${review.reviewerFirstName} ${review.reviewerLastName}`}
        </Typography>
      </Grid>
      {review.staffFirstName && review.staffLastName ? (
        <Grid item xs={12}>
          <Typography variant="body2">
            Coach: {`${review.staffFirstName} ${review.staffLastName}`}
          </Typography>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <CDisplayArea initialMaxHeight={200} value={review.comments} />
      </Grid>
    </Grid>
  );
};

export default ViewCRModal;
