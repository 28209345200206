import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Form from '../../../../../Form/Form'
import CModal from '../../../../../Form/CModal'
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import positive from '../../../../../../FormValidation/positive';
import maxTextField from "../../../../../../FormValidation/maxTextField"
import maxTextArea from "../../../../../../FormValidation/maxTextArea"
import validDates from '../../../../../../FormValidation/validDates';
import { getGenericDetails, addPricingOption, withLoading } from '../../../../../../utils';
import { futureDate } from '../../../../../../FormValidation/validDate'
const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        width: "100vw",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"

    },

}))
const AddPricingForm = (props) => {
    const [serviceMap, setServiceMap] = useState(new Map())
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const [cancelEnabled, setCancledEnabled] = useState(null)
    const cancelEnabledRef = useRef(null)
    const [pricingType, setPricingType] = useState(null)
    const [serviceOptions, setServiceOptions] = useState([])
    const [siteOptions, setSiteOptions] = useState(null)
    const [schedulingType, setSchedulingType] = useState(null)
    const [sellEndDate, setSellEndDate] = useState(null)
    const [validityType, setValidityType] = useState(null)
    const [currService, setCurrService] = useState(null)
    const classes = useStyles()
    const history = useHistory();


    const redirect = (success) => {
        if (success) {
            history.push(`${props.basePath}/${success.optionId}`);
        }
    }


    const setUp = async () => {
        let data = await withLoading(getGenericDetails, { businessId: businessId })
        if (data && data.genericDetails) {
            let tempServList = []
            let tempSiteList = []
            let tempServiceMap = new Map()
            data.genericDetails.serviceDetails.forEach((service) => {
                tempServiceMap.set(service.serviceId, service)

                tempServList.push([service.name, service.serviceId])
            })
            data.genericDetails.siteDetails.forEach((site) => {
                tempSiteList.push([site.name, site.siteId])
            })
            setServiceMap(tempServiceMap)
            setServiceOptions(tempServList)
            setSiteOptions(tempSiteList)
        }
    }
    useEffect(() => {
        if (props.open) {
            setServiceMap(new Map())
            setServiceOptions([])
            setSiteOptions([])

            setUp()

            setCurrService(null)
            setSellEndDate(null)
            setCancledEnabled(false)
            setPricingType(null)
            setSchedulingType("Default")
        }

    }, [props.open])



    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    moreData={{ businessId: businessId }}
                    title="Add Pricing"
                    cancelEnabled
                    cancelAction={() => { props.setOpen(false) }}
                    submitText="Create"
                    action={addPricingOption}
                    postAction={redirect}
                    inputFields={[
                        {
                            type: "TextField",
                            name: "name",
                            label: "Name",
                            validator: [notEmpty, noWhiteSpace, maxTextField],
                        },
                        {
                            type: "SingleSelect",
                            name: "serviceId",
                            label: "Service",
                            choices: serviceOptions,
                            validator: [
                                (value) => {
                                    setCurrService(value);
                                    return null
                                }, (value) => {
                                    if (!value) {
                                        return ("Choose a service")
                                    }
                                }]

                        },
                        {
                            type: "SingleSelect",
                            name: "siteId",
                            label: "Site",
                            choices: siteOptions,
                            validator: (value) => { if (!value) { return ("Choose a site") } }
                        },

                        {
                            type: "Currency",
                            name: "price",
                            label: "Price",
                            validator: [(value) => (positive(value) ? "Must be positive" : null), (value) => (value > 1000000 ? "value must be between 0 and 10,000" : null)],
                            currencyInfo: props.currencyInfo,
                            size: {
                                xs: 12
                            }
                        },
                        {
                            type: "Percent",
                            name: "maxDiscount",
                            label: "Max discount applicable",
                            numberProps: {
                                decimalScale: 2
                            },
                            validator: [
                                value => value && value > 99.99 ? "Max discount must be less than 100%" : null,
                                value => value && value < 0.01 ? "Max discount must be more than 0%" : null
                            ],
                            size: {
                                xs: 6
                            }
                        },
                        {
                            type: "Percent",
                            name: "transactionFee",
                            label: "Transaction Fee",
                            numberProps: {
                                decimalScale: 2
                            },
                            size: {
                                xs: 6
                            },
                            validator: [(value) => (value < 0 ? "Must be non-zero" : null), (value) => (value > 100 ? "Cannot be greater than 100%" : null)]
                        },
                        {
                            type: "CheckBox",
                            name: "cancelAllowed",
                            label: "Allow Session Cancelation by Customer",
                            validator: (value) => { setCancledEnabled(value); cancelEnabledRef.current = value },
                            enabled: !(pricingType === "Season Pass" && schedulingType === "Fixed"),
                            padding: {
                                top: 0
                            }

                        },
                        {
                            type: "Number",
                            name: "cancelDeadline",
                            label: "Customer Session Cancellation Deadline (hours)",
                            numberProps: {
                                decimalScale: 0
                            },
                            enabled: cancelEnabled,
                            initialvalue: 0,
                            validator: value => value < 0 || isNaN(value) ? "Must be greater than or equal to zero" : null
                        },
                        {
                            type: "RadioSet",
                            name: "type",
                            label: "Type",
                            choices: [
                                ["Unit", "Unit"],
                                ["Pack", "Pack"],
                                ["Season Pass", "Season Pass"]
                            ],
                            validator: (value) => {
                                setPricingType(value)
                                if (!value) { return ("Type must be selected") }
                            },

                        },
                        {
                            type: "Number",
                            name: "sessionCount",
                            label: "Number of Sessions",
                            numberProps: {
                                decimalScale: 0
                            },
                            enabled: pricingType === "Pack" || pricingType === "Season Pass",
                            validator: (value) => { if (!value || isNaN(value) || value < 0 || value > 1000) { return ("value must be within 0 and 1,000") } }
                        },
                        {
                            type: "SingleSelect",
                            name: "scheduleType",
                            label: "Schedule Type",
                            choices: [
                                ["Default Scheduling", "Default"],
                                ["Fixed Scheduling", "Fixed"]
                            ],
                            enabled: pricingType === "Season Pass" && serviceMap.get(currService) && serviceMap.get(currService).type === "Group",
                            initialvalue: "Default",
                            validator: (value) => {
                                setSchedulingType(value)
                                if (value === "Fixed") {
                                    setCancledEnabled(false)
                                }
                                else if (cancelEnabledRef.current) {
                                    setCancledEnabled(true)
                                }
                            }
                        },
                        {
                            type: "Number",
                            name: "validity",
                            label: "Validity",
                            validator: positive,
                            numberProps: {
                                decimalScale: 0
                            },
                            size: {
                                xs: 6
                            },
                            enabled: pricingType === "Pack",
                        },
                        {
                            type: "SingleSelect",
                            name: "validityUnit",
                            label: "Units",
                            size: {
                                xs: 6
                            },
                            choices: [
                                ['Days', 'days'],
                                ['Months', 'months']
                            ],
                            enabled: pricingType === "Pack",
                            validator: [(value) => { setValidityType(value) }, (value) => (!value ? "Must choose a type" : null)]
                        },
                        {
                            type: "DateRange",
                            label: ["Start Date", "End Date"],
                            name: ["startDate", "endDate"],
                            enabled: pricingType === "Season Pass",
                            validator: [(dates) => {
                                if (dates[1]) {
                                    const date2 = dates[1]
                                    const [year, month, day] = date2.split('/');
                                    const end = new Date(year, (month - 1) % 12, day)
                                    setSellEndDate(end)
                                    return [null, null]
                                }

                            }, validDates],
                            padding: {
                                bottom: 0
                            }
                        },
                        {
                            type: "Date",
                            label: "Sell End Date",
                            name: "sellEndDate",
                            enabled: pricingType === "Season Pass" && schedulingType === "Default" && serviceMap.get(currService) && serviceMap.get(currService).type === "Group" && sellEndDate,
                            validator: [
                                futureDate,
                                (date) => {
                                    if (date) {
                                        const [year, month, day] = date.split('/');
                                        const choice = new Date(year, month - 1, day);
                                        return (!choice || sellEndDate && choice >= sellEndDate) ? "Sell end date must be before season pass ends" : null
                                    }
                                }
                            ]
                        },

                        {
                            type: "CheckBox",
                            label: "Prorate",
                            name: "proRate",
                            enabled: pricingType === "Season Pass" && schedulingType === "Fixed" && serviceMap.get(currService) && serviceMap.get(currService).type === "Group",
                            padding: {
                                top: 0
                            }
                        },
                        {
                            type: "CheckBox",
                            label: "Customer Self Check-in Enabled",
                            name: "customerCheckin",
                            enabled: pricingType === "Pack" || pricingType === "Season Pass" && !(schedulingType === "Fixed" && serviceMap.get(currService) && serviceMap.get(currService).type === "Group"),
                            padding: {
                                top: 0
                            }
                        },
                        {
                            type: "Number",
                            label: "Booking Window (days from date of booking)",
                            name: "advanceBookingWindow",
                            enabled: pricingType === "Unit",
                            initialvalue: 90,
                            validator: value => (value < 0 || value > 365 || isNaN(value) ? "Must be between 0 to 365 days" : null)
                        }



                    ]}
                />
            </Container>
        </CModal>
    );
};

export default AddPricingForm