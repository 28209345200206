import React from 'react';
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Discount from "./Discount";
import DiscountsList from "./DiscountList";

function CustomerQualiHandler() {
    const history = useHistory();
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    return(
        <React.Fragment>
            <Switch>
            <Route path = {`/dashboard/${businessId}/Discounts`} exact render = {() => {return(<DiscountsList />)}} />
            <Route path = {`/dashboard/${businessId}/Discounts/:discountIdURL`} render = {() => {return(<Discount/>);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default CustomerQualiHandler;