import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { signedStaffImageUrlUpload, staffProfileDetails } from "../../../../../../utils";
import { updateStaffProfile } from "../../../../../../utils";
import {
  Grid, Button, Container,
  CircularProgress, Avatar
} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { sizing } from '@material-ui/system';
import EditIcon from '@material-ui/icons/Edit';
import "./Profile.css";
import validEmail from "../../../../../../FormValidation/validEmail";
import validPhoneNumber from "../../../../../../FormValidation/phonenumber";
import Form from "../../../../../Form/Form";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import CModal from "../../../../../Form/CModal";
import PublishIcon from '@material-ui/icons/Publish';
import { Typography } from "@material-ui/core";
import LightTooltip from "../../../../../Form/LightTooltip";
import CDisplayArea from "../../../../../Form/CDisplayArea";
import { CCloseIcon } from "../../../../../Icons/Icons";
import { IconButton } from "@material-ui/core";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import axios from 'axios';
import CModalError from "../../../../../Form/CModalError";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px"
  },
  headingWrapper: {
    paddingBottom: "10px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
    paddingLeft: "10px",
  },
  fieldColumnOne: {
    width: "100px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  sdCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "25%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  media: {
    height: 200,
    width: 200,
    objectFit: "cover"
  },
  avatar: {
    height: theme.spacing(20),
    width: theme.spacing(20),
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px"
  }
}));

const StaffProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [_, updateState] = useState(0);
  const large = useMediaQuery('(min-width: 960px)');
  const small = useMediaQuery('(min-width: 750px)');
  const [edit, setEdit] = useState(false);
  const [dI, setDI] = useState(false);
  const [profileDetails, setProfileDetails] = useState([]);
  const [imageForm, setImageForm] = useState(false);
  const [imageName, setImageName] = useState(false);

  const [fileName, setFileName] = useState(null);
  const [fileData, setFileData] = useState(null);

  const handleImage = async (file) => {
    if (file.target.files && file.target.files[0] && file.target.files[0].type && file.target.files[0].type.includes('image')) {
      setFileName(file.target.files[0].name);
      setFileData(file.target.files[0]);
      setImageName(URL.createObjectURL(file.target.files[0]));
      setImageForm(true);
    }
  };

  const handleImageAdd = async () => {
    let preSignedURL = null;

    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });

    preSignedURL = await signedStaffImageUrlUpload({
      businessId,
      fileName
    });

    if (preSignedURL && !preSignedURL.error) {

      let s3url = preSignedURL.signedData.url;
      let image = preSignedURL.imageURL;
      let fields = preSignedURL.signedData.fields;
      let formData = new FormData();

      for (let k in fields) {
        formData.append(k, fields[k]);
      };

      formData.append('file', fileData);

      try {
        await axios.post(s3url, formData);
        await updateStaffProfile({ businessId, image });

        dispatch({
          type: "UPDATE",
          payload: {
            loading: false,
          },
        });
        setImageForm(false);
        updateState((_) => !_);

      } catch (error) {
        dispatch({
          type: "UPDATE",
          payload: {
            loading: false,
          },
        });
      };

    } else {
      dispatch({
        type: "UPDATE",
        payload: {
          loading: false,
        },
      });
    };
  };

  async function handleDelete(event) {

    dispatch({
      type: 'UPDATE', payload: {
        loading: true
      }
    });

    let success = await updateStaffProfile({
      businessId: businessId,
      image: null
    });
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
    if (success) {
      setDI(false);
      updateState((_) => !_);
    }
  };

  async function handleSubmit(event) {

    dispatch({
      type: 'UPDATE', payload: {
        loading: true
      }
    });

    let success = await updateStaffProfile({
      businessId: businessId,
      firstName: event.firstName,
      lastName: event.lastName,
      phoneNumber: event.pnumber,
      email: event.email,
      image: event.image ? event.image : null,
      profile: event.about ? event.about : null
    });
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
    if (success) {
      updateState((_) => !_);
      setEdit(false);
    }
  };

  useEffect(async () => {
    let data = null;
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    data = await staffProfileDetails({ businessId: businessId });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (data) {
      setProfileDetails(data.profileInfo);
    }
  }, [_]);
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const editAccess = useSelector((state) => {
    return state.buisnessDetails.profileAccess;
  });

  if (profileDetails && profileDetails.phoneNumber) {
    return (
      <Grid container className={large ? classes.mainWrapper : classes.mobilePadding}>
        <GeneralDialog
          open={dI}
          close={setDI}
          title="Delete Profile Picture"
          text="Are you sure you want to delete this image?"
          action={handleDelete}
        />
        <CModal open={imageForm} setOpen={setImageForm}
          children={
            <Container component="main" className={classes.formContainer}>
              <Grid container spacing={1} item xs={12} direction="column">
                <CModalError />
                <Grid container item xs={12} justifyContent="flex-end">
                  <div className="container">
                    <img style={{ minHeight: "250px", height: "100%", width: "100%" }} src={imageName ? imageName : null} alt="smth" />
                    <Grid item>
                      <div className={small ? "button" : "bSmall"}>
                        <input
                          accept="image/*"
                          onChange={(file) => { handleImage(file); }}
                          style={{ display: 'none' }}
                          id="contained-button-file"
                          type="file"
                        />
                        <label htmlFor="contained-button-file">
                          <IconButton variant="contained" component="span" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}><PublishIcon style={{ color: "#1a1f36" }} /></IconButton>
                        </label>
                      </div>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} />
                <Grid container item xs={12} spacing={1} justifyContent="flex-end" direction="row">
                  <Grid item >
                    <Button className={classes.buttonBasic} onClick={() => { setImageForm(false) }}>Cancel</Button>
                  </Grid>
                  <Grid item justifyContent="flex-end">
                    <Button className={classes.buttonBasic} style={{ backgroundColor: "#0079fc", color: "white" }} onClick={handleImageAdd}>Submit</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          }
        />
        <CModal open={edit} setOpen={setEdit}
          children={
            <Container component="main" className={classes.formContainer}>
              <Form
                action={handleSubmit}
                postAction={null}
                title="Edit Profile Information"
                cancelEnabled
                cancelAction={() => { setEdit(false); }}
                cancelText="Cancel"
                submitText="Save"
                inputFields={
                  [
                    {
                      type: "TextField",
                      validator: [notEmpty],
                      initialvalue: profileDetails.firstName,
                      label: "First Name",
                      name: "firstName",
                    },
                    {
                      type: "TextField",
                      validator: [notEmpty],
                      initialvalue: profileDetails.lastName,
                      label: "Last Name",
                      name: "lastName",
                    },
                    {
                      type: "PhoneNumber",
                      name: "pnumber",
                      label: "Phone Number",
                      initialvalue: profileDetails.phoneNumber,
                      validator: [(value) => { if (value === "+1") { return ("Bad number") } }, validPhoneNumber]
                    },
                    {
                      type: "TextField",
                      validator: [notEmpty, validEmail],
                      initialvalue: profileDetails.email,
                      label: "Email",
                      name: "email",
                    },
                    {
                      type: "TextArea",
                      name: "about",
                      label: "Bio",
                      resizeEnabled: true,
                      initialvalue: profileDetails.profile,
                      moreProps: {
                        rowsMin: 4,
                        rowsMax: 10
                      }
                    },
                  ]
                }
              >
              </Form>
            </Container>
          }
        />
        <Grid container item xs={12} direction="row">
          <Grid item xs={editAccess ? 6 : 12}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>MY PROFILE</span>
            </div>
          </Grid>
          {editAccess ? <Grid item xs={6}>
            <div className={classes.topBlock}>
              <LightTooltip title="Edit Profile" arrow>
                <span className={classes.rightElem}>
                  <Button
                    onClick={() => { setEdit(true); }}
                    style={{ backgroundColor: "#0079fc", color: "white" }}
                    className={classes.buttonBasic}
                  >
                    Edit
                  </Button>
                </span>
              </LightTooltip>
            </div>
          </Grid> : null}
        </Grid>
        <Grid container item xs={12} direction="row">
          <Grid item xs={12}>
            <div className={classes.headerPadding} style={{ width: `calc(100%)`, paddingLeft: "0px" }}>
              <LightTooltip title={`${profileDetails.firstName} ${profileDetails.lastName}`} arrow>
                <Typography noWrap className={small ? classes.topHeadingOne : classes.mobileTopHeadingOne}>
                  {`${profileDetails.firstName} ${profileDetails.lastName}`}
                </Typography>
              </LightTooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2} style={small ? { paddingTop: "15px", paddingBottom: "15px", paddingLeft: "15px" } : { paddingTop: "25px" }} direction={small ? "row" : "column"}>
          <Grid item style={small ? { paddingLeft: "0px" } : { display: "flex", justifyContent: "center" }}>
            <div className="container">
              <img className={classes.media} src={profileDetails.image} alt="smth" />
              <span className={small ? "button" : "bSmall"} style={{ display: "inline-flex" }}>
                <input
                  accept="image/*"
                  onChange={(file) => { handleImage(file); }}
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  type="file"
                />
                <label htmlFor="contained-button-file">
                  <IconButton variant="contained" component="span" style={profileDetails.image && profileDetails.image !== "https://caritra-app-images.s3.us-west-1.amazonaws.com/default-images/profile.png" ? { right: "41px", height: "20px", width: "20px", backgroundColor: "rgba(255, 255, 255, 0.6)" } : { backgroundColor: "rgba(255, 255, 255, 0.6)" }}><EditIcon style={{ color: "#1a1f36" }} /></IconButton>
                </label>
                {profileDetails.image && profileDetails.image !== "https://caritra-app-images.s3.us-west-1.amazonaws.com/default-images/profile.png" ?
                  <LightTooltip title="Delete Profile Picture" arrow>
                    <IconButton variant="contained" style={{ right: "40px", backgroundColor: "rgba(255, 255, 255, 0.6)", height: "44px", width: "44px" }} onClick={() => { setDI(true); }}>
                      <CCloseIcon />
                    </IconButton>
                  </LightTooltip>
                  : null}
              </span>
            </div>
          </Grid>
          <Grid item style={small ? null : { paddingLeft: "0px" }}>
            <div className={classes.contentRow}>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne} style={{ maxWidth: "50px", paddingBottom: "5px" }}>
                  <span className={classes.labelText} style={{ whiteSpace: "normal" }}>Phone Number</span>{" "}
                </div>
                <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
                  <span className={classes.valueText} >
                    {`${profileDetails.phoneNumber.slice(0, 2)} (${profileDetails.phoneNumber.slice(2, 5)}) ${profileDetails.phoneNumber.slice(5, 8)}-${profileDetails.phoneNumber.slice(8, 12)}`}
                  </span>
                </div>
              </div>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne} style={{ maxWidth: "50px", paddingBottom: "5px" }}>
                  <span className={classes.labelText} style={{ whiteSpace: "normal" }}>Email</span>{" "}
                </div>
                <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
                  <span className={classes.valueText}>
                    {profileDetails.email}
                  </span>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={classes.labelText}>Bio</span>
          </div>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          <span className={classes.valueText}>
            <CDisplayArea
              value={profileDetails.profile ? profileDetails.profile : null}
              initialMaxHeight={"calc(100vh - 360px)"}
            />
          </span>{" "}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default StaffProfile;
