import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import FeedbackList from "./FeedbackList";

const FeedbackHandler = () => {
  const basePath = `/account/Feedback`;
  return (
    <Switch>
      <Route
        path={basePath}
        exact
        render={() => {
          return <FeedbackList basePath={basePath} />;
        }}
      />
      <Route
        path="*"
        render={() => {
          return <Redirect to="/404" />;
        }}
      />
    </Switch>
  );
};

export default FeedbackHandler;
