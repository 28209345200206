import React from 'react'
import { makeStyles, useMediaQuery, Grid, Typography } from "@material-ui/core";
import { formatSrc } from "../Image/Image";
import { aboutUsData } from "./AboutUsUtils";
const useStyles = makeStyles((theme) => ({
    hero: {
        paddingTop: '100px',
        marginBottom: '10px',
        padding: '0px 10px 0px 10px',
        backgroundImage: `url(${formatSrc('https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/contact-sales/contact-sales.webp', {
            mobile: false,
            mobileOptions: undefined,
            desktopOptions: ['fit-in/1920x1080']
        })})`,
        backgroundPosition: 'center bottom',
        backgroundSize: "cover",
        height: "400px",

    },
    heroContent: {
        width: "100%",
        height: "100%"
    },
    contactContent: {
        width: "100%",
        height: "100%"
    },
    contact: {
        background: 'transparent',
        height: "50vh",
        minHeight: "400px"
    },
    whiteText: {
        color: theme.palette.white.main
    },
    buttonContainer: {
        paddingTop: "20px"
    },
    buttonText: {
        textTransform: "none",
        color: theme.palette.primary.main,
        textDecoration: "none"
    },
    horizontalPadding: {
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    verticalPadding: {
        paddingTop: "50px",
        paddingBottom: "100px"
    },
    FeatureImage: {
        objectFit: "cover",
        maxWidth: "450px",
        width: "100%",
    },
    FeatureImageContainer: {
        display: "flex",
        padding: 10,
        justifyContent: "center",
    },
    FeatureDesktop: {
        maxWidth: "550px",
        width: "50%"
    },
    FeatureMobile: {
        width: "100%"
    },
    success: {
        color: theme.palette.success.main
    },
    bold: {
        fontWeight: 'bold'
    },
    textColor3: {
        color: theme.palette.textColor3.main
    },
    benefit: {
        '&:hover': {
            boxShadow: `0 5px 10px 5px rgba(0,0,0,0.1)`,
        },
        padding: "50px 10px 50px 10px",
        transitionDuration: "500ms"
    },
    contactImage: {
        zIndex: -1,
        position: "fixed",
        width: "100%",
        height: "100vh",
        backgroundImage: `url('https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/contact-us-final.png')`,
        backgroundPosition: "center",
        backgroundSize: "max(1920px,100vw) auto",
    },
    noBackground: {
        background: 'transparent'
    },
    hidden: {
        display: "none"
    },
    block: {
        display: "block"
    },
    whiteBackground: {
        backgroundColor: "white"
    },
    greyBackground: {
        backgroundColor: theme.palette.accent2.main
    },
    testimonial: {
        backgroundColor: theme.palette.accent2.main,
        borderRadius: "10px",
        marginBottom: "10px",
        height: "300px"
    }
}));

const AboutUsSection = (props) => {
    const { title, description, descriptionTwo } = props
    return (
        <React.Fragment>
            <Typography variant="h5">
                {title}
            </Typography>
            <Typography>
                {description}
            </Typography>
            {descriptionTwo ?
                <React.Fragment>
                    <br />
                    <Typography>
                        {descriptionTwo}
                    </Typography>
                </React.Fragment>
                : null}
        </React.Fragment>
    )
}

export const AboutUs = () => {
    const mobile = useMediaQuery('(min-width: 750px)');
    const classes = useStyles()
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} className={classes.hero}>
                    <Typography className={classes.whiteText} variant="h5" align='center' style={{ maxWidth: '600px', margin: '0 auto' }}>
                        Our actions embody the meaning of the word, Caritra:
                        a sacred site; upholding high values and character
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={{ maxWidth: '1440px', padding: "0px 10px 50px 10px", margin: '0 auto' }} direction="row-reverse">
                <Grid item xs={12} className={classes.verticalPadding}>
                    <Typography variant='h4'>
                        {aboutUsData.title}
                    </Typography>
                    <Typography>
                        {aboutUsData.titleText}
                    </Typography>
                    <br />
                    <Typography>
                        {aboutUsData.titleTextMore}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img
                        style={{ width: "calc(100% - 50px)", padding: '25px' }}
                        src={formatSrc('https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/about-us/mission-value-people.png', {
                            mobile: false,
                            mobileOptions: undefined,
                            desktopOptions: ['fit-in/640x400']
                        })} />
                </Grid>

                <Grid item xs={12} md={6}>
                    {aboutUsData.segments.map((segment, index) => (
                        <Grid item xs={12} style={{ paddingBottom: '50px ' }}>
                            <AboutUsSection {...segment} key={`segment-${index}}`} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}