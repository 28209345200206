import React from 'react'
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    img: {
        height:300,
        width:300
    },
    errorText: {
        color: theme.palette.textColor3.main,
    }
}))

const ServerIssue = (props) => {
    const classes = useStyles()
    return (
        <Grid container alignItems="center" direction = "column" justifyContent="center" style={{ height: "calc(100vh - 64px)" }}>
            <img className = {classes.img} src = "https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/error-logo/error-any.png" alt = "Server Issue"/>
            <Typography className={classes.errorText}>
                There was an error while processing
            </Typography>
        </Grid>
    )
}

export default ServerIssue