import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Agreement from './Agreement';
import AgreementsList from './AgreementsList';
const AgreementsHandler = () => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const basePath = `/dashboard/${businessId}/Agreements`
    return (
        <Switch>
            <Route exact path = {basePath} render = {()=>(<AgreementsList basePath = {basePath}/>)}/>
            <Route exact path = {`${basePath}/:agreementIdURL`} render = {()=>(<Agreement basePath = {basePath}/>)}/>
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
        </Switch>
    );
}

export default AgreementsHandler;