import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { customerPackDetails, operatingUnits, withLoading } from "../../../../utils";
import {
  Grid, Button, Table, TableBody, TableCell,
  Typography, TableContainer, TableHead, TableRow,
  CircularProgress
} from "@material-ui/core";
import moment from "moment";
import StatusTag from "./../../../TagComponents/StatusTag";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LightTooltip from "../../../Form/LightTooltip";
import BookingCalendar from "../../../BookingCalendar/BookingCalendar";
import { CInfoIcon } from "../../../InfoIcon/InfoIcon";
import { AttendeeInfoModal } from "../../../AttendeeInfoModal/AttendeeInfoModal";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../../TableComponents/ListTable";
import CurrencyPreProcessor from "../../../CurrencyFormatter/CurrencyFormatter";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb575750",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none"
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px"
  },
  fieldColumnOne: {
    width: "80px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
    maxWidth: "calc(100% - 150px)"
  },
  sdCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "25%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
    paddingLeft: "0px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    backgroundColor: "#ffffff"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "700px"
  }
}));

const isUTCPassed = (utcTime) => {
  try {
    let curMoment = new moment();
    let refMomemt = new moment(utcTime);

    if (curMoment.isBefore(refMomemt)) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return true;
  }
};

const addOnHeaders = [
  {
    name: "Name",
    columnKey: "name",
    columnWidth: "30%",
    type: "Typography",
    tooltip: true
  },


  {
    name: "Basis",
    columnKey: "sellBy",
    columnWidth: "100px",
  },
  {
    name: "Description",
    columnKey: "description",
    columnWidth: "70%",
    tooltip: true
  },
  {
    name: "",
    columnKey: "action",
    columnWidth: "100px",
    type: "Normal"
  },
];

const Package = () => {
  const dispatch = useDispatch();
  const bigEnough = useMediaQuery("(min-width: 750px)")
  const classes = useStyles();
  const [_, updateState] = useState(0);
  const [open, setOpen] = useState(false);
  const small = useMediaQuery('(min-width: 750px)');
  const large = useMediaQuery('(min-width: 1250px)');
  const [aM, setAM] = useState(false);
  const [packDetails, setPackDetails] = useState(null)
  const { packIdURL } = useParams();
  const currencyInfo = useSelector((state) => {
    return state.operatingUnitInfo;
  });

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });

    let success = await operatingUnits();

    let data = await withLoading(customerPackDetails, {
      packId: packIdURL
    })
    if (data && data.packDetails) {
      setPackDetails(data.packDetails)
    }
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
        operatingUnitInfo: success ? success.operatingUnitDetails : null,
      },
    });
  }, [_]);
  if (packDetails) {
    return (

      <React.Fragment>
        <Grid container className={large ? classes.mainWrapper : classes.mobilePadding}>
          <BookingCalendar open={open} setOpen={setOpen} packDetails={packDetails} refresh={() => { updateState(_ => !_) }} />
          <AttendeeInfoModal open={aM} setOpen={setAM} attendeeQuestions={packDetails.attendeeQuestions} />
          <Grid item xs={12}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>PACKAGE / PASS</span>
            </div>
          </Grid>
          <Grid container direction="row" item xs={12} className={classes.headingWrapper}>
            <Grid item style={(bigEnough ? { maxWidth: "50%" } : { width: "100%" })}>
              <div className={classes.headerPadding}>
                <Typography className={(bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne)} noWrap>
                  #{packDetails.packNumber}
                </Typography>
              </div>
            </Grid>

            {packDetails.isCanceled ? (bigEnough ?
              <Grid item>
                <div className={classes.headerPadding} style={{ paddingLeft: "20px" }}>
                  <span className={classes.topHeadingFour}>
                    <StatusTag wrapperName="redWrapper" value="Canceled" />
                  </span>
                </div>
              </Grid>
              :
              <Grid item>
                <div style={{ paddingLeft: "10px" }}>
                  <StatusTag wrapperName="redWrapper" value="Canceled" />
                </div>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <div className={large ? classes.contentColumnFirst : classes.fieldRows}>
                <div className={large ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Attendee</Typography>
                </div>
                <div className={large ? classes.blockText : classes.fieldColumnTwo} style={large ? null : { paddingLeft: "15px" }}>
                  <LightTooltip title="View Attendee Details" arrow>
                    <Link to={`/account/Attendees/${packDetails.attendeeId}`} className={classes.valueTextLink}>
                      <Typography noWrap className={classes.valueTextLink} >
                        {`${packDetails.attendeeFirstName} ${packDetails.attendeeLastName}`}
                      </Typography>
                    </Link>
                  </LightTooltip>
                </div>
                {!large & packDetails.attendeeQuestions.length > 0 ? <div className={classes.fieldColumnOne} style={{ width: "25px" }}>
                  <CInfoIcon setOpen={setAM} />
                </div> : null}
              </div>
              <div className={large ? classes.contentColumnMiddle : classes.fieldRows}>
                <div className={large ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Service</Typography>
                </div>
                <div className={large ? classes.blockText : classes.fieldColumnTwo} style={large ? { width: "80%", paddingLeft: "15px" } : { width: "65%", paddingLeft: "15px" }}>
                  <LightTooltip title={packDetails.serviceName} arrow>
                    <Typography noWrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.valueText}>
                      {packDetails.serviceName}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
              {packDetails.offeringType == "Private" ? (
                <div className={large ? classes.contentColumnMiddle : classes.fieldRows}>
                  <div className={large ? classes.blockLabel : classes.fieldColumnOne}>
                    <Typography noWrap className={classes.labelText}>Professional</Typography>
                  </div>
                  <div className={large ? classes.blockText : classes.fieldColumnTwo} style={large ? { width: "80%", paddingLeft: "15px" } : { width: "65%", paddingLeft: "15px" }}>
                    <LightTooltip arrow title={`${packDetails.staffFirstName} ${packDetails.staffLastName}`}>
                      <Typography noWrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.valueText}>
                        {`${packDetails.staffFirstName} ${packDetails.staffLastName}`}
                      </Typography>
                    </LightTooltip>
                  </div>
                </div>
              ) : null}
              <div className={large ? classes.contentColumnMiddle : classes.fieldRows}>
                <div className={large ? classes.blockLabel : classes.fieldColumnOne}>
                  {packDetails.optionType == "Pack" ? (
                    <Typography noWrap className={classes.labelText}>Pack Name</Typography>
                  ) : (
                    <Typography noWrap className={classes.labelText}>Pass Name</Typography>
                  )}
                </div>
                <div className={large ? classes.blockText : classes.fieldColumnTwo} style={large ? { width: "80%", paddingLeft: "15px" } : { width: "65%", paddingLeft: "15px" }}>
                  <LightTooltip title={packDetails.optionName} arrow>
                    <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                      {packDetails.optionName}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
              <div className={large ? classes.contentColumnMiddle : classes.fieldRows}>
                <div className={large ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography style={{ whitespace: "normal" }} className={classes.labelText}>Total Sessions</Typography>
                </div>
                <div className={large ? classes.blockText : classes.fieldColumnTwo} style={large ? null : { paddingLeft: "15px" }}>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                    {packDetails.sessionCount}
                  </Typography>
                </div>
              </div>
              <div className={large ? classes.contentColumnMiddle : classes.fieldRows}>
                <div className={large ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Order #</Typography>
                </div>
                <div className={large ? classes.blockText : classes.fieldColumnTwo} style={large ? { width: "80%", paddingLeft: "15px" } : { width: "65%", paddingLeft: "15px" }}>
                  <Typography noWrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.valueTextLink}>
                    <Link to={`/account/Orders/${packDetails.orderId}`} className={classes.valueTextLink}>
                      {packDetails.orderNumber}
                    </Link>
                  </Typography>
                </div>
              </div>
              <div className={large ? classes.contentColumnLast : classes.fieldRows}>
                <div className={large ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Validity</Typography>
                </div>
                <div className={large ? classes.blockText : classes.fieldColumnTwo} style={large ? null : { paddingLeft: "15px" }}>
                  <span className={classes.valueText}>
                    <Typography className={classes.valueText} noWrap>
                      {`${packDetails.validityStart} - ${packDetails.validityEnd}`}
                    </Typography>
                  </span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} >
            <div className={classes.headingWrapper}>
              <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Sessions</span>
              {packDetails.scheduledSession < packDetails.sessionCount && !packDetails.isCanceled && !isUTCPassed(packDetails.validityEndRef) && packDetails.customerCheckin ? (
                <Button className={[classes.buttonBasic, classes.rightElem]} onClick={() => { setOpen(true) }}>
                  Schedule
                </Button>
              ) : null}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {packDetails.sessionDetails.length !== 0 ? (
                <TableContainer style={small ? { maxHeight: "265px", width: "100%" } : { maxHeight: "177px", width: "100%" }}>
                  <Table stickyHeader aria-label="simple table" style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className={classes.headerPadding} style={{ width: "70px" }}>
                          <span className={classes.labelText}>SESSION #</span>
                        </TableCell>
                        <TableCell align="left" className={classes.headerPadding} style={{ width: `calc(100%)` }}>
                          <Typography noWrap className={classes.labelText}>START TIME</Typography>
                        </TableCell>
                        {packDetails.offeringType === "Group" ? (
                          <TableCell align="left" className={classes.headerPadding} style={{ width: "60px" }}>
                            <span className={classes.labelText}>CLASS #</span>
                          </TableCell>
                        ) : null}
                        <TableCell align="left" className={classes.headerPadding} style={{ width: "100px" }}>
                          <span className={classes.labelText}>STATUS</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {packDetails.sessionDetails.map((session) => {
                        return (
                          <TableRow key={session.sessionId}>
                            <TableCell
                              align="left"
                              className={classes.sdCustomCellOne}
                            >
                              <Link to={`/account/Schedule/${session.sessionId}`} className={classes.valueTextLink}>
                                <Typography noWrap className={classes.valueTextLink}>
                                  {session.sessionNumber}
                                </Typography>
                              </Link>
                            </TableCell>
                            <TableCell align="left" className={classes.sdCustomCellTwo}>

                              <LightTooltip title={`${session.startDate} ${session.startTime} (${packDetails.timeZone})`} arrow>
                                <Typography noWrap className={classes.valueText}>
                                  {`${session.startDate} ${session.startTime} (${packDetails.timeZone})`}
                                </Typography>
                              </LightTooltip>

                            </TableCell>
                            {session.classNumber ? (
                              <TableCell align="left" className={classes.sdCustomCellThree}>
                                <LightTooltip title="Group Class Page" arrow>
                                  <Typography className={classes.valueTextLink}>
                                    {session.classNumber}
                                  </Typography>
                                </LightTooltip>
                              </TableCell>
                            ) : null}
                            {session.isCanceled ? (
                              <TableCell align="left" className={classes.sdCustomCellFour}>
                                <StatusTag wrapperName={'redWrapper'} value={'Canceled'} />
                              </TableCell>
                            ) : isUTCPassed(session.endDateUTC) ? (
                              <TableCell align="left" className={classes.sdCustomCellFour}>
                                {(session.attendanceRecord) ? <StatusTag wrapperName={(session.attendanceRecord == 'Present' ? 'greenWrapper': (session.attendanceRecord == 'Absen' ? 'redWrapper': 'grayWrapper'))} value={session.attendanceRecord} /> :<StatusTag wrapperName={'grayWrapper'} value={'Completed'} />}
                              </TableCell>
                            ) : (
                              <TableCell align="left" className={classes.sdCustomCellFour}>
                                {(session.attendanceRecord) ? <StatusTag wrapperName={(session.attendanceRecord == 'Present' ? 'greenWrapper': (session.attendanceRecord == 'Absen' ? 'redWrapper': 'grayWrapper'))} value={session.attendanceRecord} /> :<StatusTag wrapperName={'greenWrapper'} value={'Scheduled'} />}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    No sessions scheduled yet.
                  </span>
                </div>
              )}
            </div>
          </Grid>
          {(packDetails.attendeeAddOn.length > 0 ?
            <React.Fragment>
              <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                  <Typography noWrap className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Add Ons</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentRow}>
                <ListTable
                    styling={{ width: "100%", maxHeight: 250 }}
                    identifier={"addOns"}
                    tableHeaders={addOnHeaders}
                    tableRows={packDetails.attendeeAddOn.map((aO) => {
                      return ({
                        name: aO.displayName,
                        sellBy: aO.sellBy.toLowerCase(),
                        description: aO.description,
                        action: (aO.isCanceled ? <StatusTag wrapperName={'redWrapper'} value={'Canceled'} /> :null)
                      })
                    })}
                    rowClick={null}
                  />
                </div>
              </Grid>
            </React.Fragment> : 
            null)}
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Service</span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Name</Typography>
                </div>
                <div className={classes.fieldColumnTwo} style={{ width: "80%" }}>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                    <LightTooltip title={packDetails.serviceName} arrow>
                      <Typography className={classes.valueText}>
                        {packDetails.serviceName}
                      </Typography>
                    </LightTooltip>
                  </Typography>
                </div>
              </div>
              {packDetails.isVirtual ? (
                <div className={classes.fieldRows}>
                  <div className={classes.fieldColumnOne}>
                    <Typography noWrap className={classes.labelText}>Type</Typography>
                  </div>
                  <div className={classes.fieldColumnTwo} style={{ width: "80%" }}>
                    <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", }}>
                      {`${packDetails.offeringType} - Virtual`}
                    </Typography>
                  </div>
                </div>
              ) : (
                <div className={classes.fieldRows}>
                  <div className={classes.fieldColumnOne}>
                    <Typography noWrap className={classes.labelText}>Type</Typography>
                  </div>
                  <div className={classes.fieldColumnTwo} style={{ width: "80%" }}>
                    <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", }}>
                      {`${packDetails.offeringType} - In Person`}
                    </Typography>
                  </div>
                </div>
              )}
              {
                !packDetails.isVirtual ?
                  <div className={classes.fieldRows}>
                    <div className={classes.fieldColumnOne}>
                      <Typography noWrap className={classes.labelText}>Location</Typography>
                    </div>
                    <div className={classes.fieldColumnTwo} style={{ width: "80%" }}>
                      <LightTooltip title={`${packDetails.address1}${packDetails.address2 ? `, ${packDetails.address2}` : ""}`} arrow>
                        <Typography className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", }} noWrap>
                          {packDetails.address2 ? `${packDetails.address1}, ${packDetails.address2}` : `${packDetails.address1}`}
                        </Typography>
                      </LightTooltip>
                    </div>
                  </div>
                  : null
              }
            </div>
          </Grid>

          {packDetails.offeringType === "Private" ? (
            <Grid item xs={12}>
              <div className={classes.headingWrapper}>
                <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Professional</span>
              </div>
            </Grid>
          ) : null}
          {packDetails.offeringType === "Private" ? (
            <Grid item xs={12}>
              <div className={classes.contentRow}>
                <div className={classes.fieldRows}>
                  <div className={classes.fieldColumnOne}>
                    <Typography noWrap className={classes.labelText}>Name</Typography>
                  </div>
                  <div className={classes.fieldColumnTwo} style={{ width: "80%" }}>
                    <LightTooltip arrow ttile={`${packDetails.staffFirstName} ${packDetails.staffLastName}`}>
                      <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", }}>
                        {`${packDetails.staffFirstName} ${packDetails.staffLastName}`}
                      </Typography>
                    </LightTooltip>
                  </div>
                </div>
                <div className={classes.fieldRows}>
                  <div className={classes.fieldColumnOne}>
                    <span className={classes.labelText}>Email</span>{" "}
                  </div>
                  <div className={classes.fieldColumnTwo}>
                    <LightTooltip title="Email Staff Member" arrow>
                      <span className={classes.valueTextLink}>
                        <a style={{ color: "#0079fc" }} href={"mailto:" + packDetails.staffEmail}>{packDetails.staffEmail}</a>
                      </span>
                    </LightTooltip>
                  </div>
                </div>
                {packDetails.staffPhoneNumber ? (
                  <div className={classes.fieldRows}>
                    <div className={classes.fieldColumnOne}>
                      <span className={classes.labelText}>Phone</span>{" "}
                    </div>
                    <div className={classes.fieldColumnTwo}>
                      <span className={classes.valueText}>
                        {`${packDetails.staffPhoneNumber.slice(0, 2)} (${packDetails.staffPhoneNumber.slice(2, 5)}) ${packDetails.staffPhoneNumber.slice(5, 8)}-${packDetails.staffPhoneNumber.slice(8, 12)}`}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={classes.fieldRows}>
                    <div className={classes.fieldColumnOne}>
                      <span className={classes.labelText}>Phone</span>{" "}
                    </div>
                    <div className={classes.fieldColumnTwo}>
                      <span className={classes.valueText}>Not Available</span>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Terms and Conditions</span>
            </div>
          </Grid>
          {packDetails.cancelAllowed ? (
            <Grid item xs={12}>
              <div className={classes.contentRow}>
                <div>
                  <span className={classes.labelText}>
                    Session Cancellation Policy
                  </span>
                </div>
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad} style={{ whiteSpace: "normal" }}>
                    {packDetails.cancelDeadline === 0
                      ? `Session cancellation allowed by customer up to session start time.`
                      : `Session cancellation allowed by customer ${packDetails.cancelDeadline} hours before session start time.`}
                  </span>
                </div>
              </div>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <div className={classes.contentRow}>
                <div>
                  <span className={classes.labelText}>
                    Session Cancellation Policy
                  </span>
                </div>
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    Session cancellation not allowed by customer.
                  </span>
                </div>
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default Package;
