import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { ColorPicker } from 'material-ui-color';
import LightTooltip from "./LightTooltip";

const useStyles = makeStyles((theme) => ({
    comp:
    {
        width: "100%"
    },
    formLabel: {
        fontSize: 14,
        color: theme.palette.textColor2.main
    },
    fieldText: {
        fontSize: 14,
        color: theme.palette.primaryText.main,
        padding: "10px !important"
    },
    noPadding: {
        padding: "0px !important"
    }
}));

export const CColor = (props) => {
    const classes = useStyles();
    return (
        <Grid container item direction="column" alignItems="center">
            <Grid item xs={12}>
                <Typography className={classes.formLabel}>
                    {props.label}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <ColorPicker
                    hideTextfield
                    deferred
                    onChange={(event) => {
                        props.onchange(event.css.backgroundColor);
                    }}
                    value={props.color}
                />
            </Grid>
            <Grid item xs={6}>
                <Typography className={classes.formLabel} style={{ color: "#1a1f36" }}>
                    {props.color}
                </Typography>
            </Grid>
        </Grid>
    );
}