import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { encodeGetParams, listOrders, retrieveGetParams, withLoading } from "../../../../../../utils";
import Grid from "@material-ui/core/Grid";
import CurrencyPreProcessor from "../../../../../CurrencyFormatter/CurrencyFormatter";
import Moment from "react-moment";
import ListTable from "./../../../../../TableComponents/ListTable";
import ListTableNavigation from "./../../../../../TableComponents/ListTableNavigation";
import ListCard from "../../../../../MobileCards/ListCard";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import { CircularProgress } from "@material-ui/core";
import Filter from "../../../../../Filter/Filter";

const orderHeaders = [
  {
    name: "order #",
    columnKey: "orderNumber",
    columnWidth: "75px",
    type: "Normal"
  },
  {
    name: "booking date",
    columnKey: "bookingDateF",
    columnWidth: "10%",
    tooltip: true
  },
  {
    name: "customer",
    columnKey: "customerName",
    columnWidth: "15%",
    tooltip: true
  },
  {
    name: "service",
    columnKey: "serviceName",
    columnWidth: "30%",
    tooltip: true
  },
  {
    name: "pricing option",
    columnKey: "optionName",
    columnWidth: "20%",
    tooltip: true
  },
  {
    name: "amount (usd/cad)",
    columnKey: "orderAmountF",
    columnWidth: "140px",
  },
];


const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "inset 0 -1px #e3e8ee"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  }
}));
const currentLimit = 20


const OrderList = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width: 750px)');
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });



  const [orderList, setOrderList] = useState([]);
  const [initialState, setInitialState] = useState(null);
  const location = useLocation()
  const queryParams = retrieveGetParams(location)

  const currentPage = queryParams.pg ? Number(queryParams.pg) : 1
  const service = queryParams.service
  const site = queryParams.site
  const start = queryParams.start
  const end = queryParams.end
  const cId = queryParams.cId
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  const processOrderData = (orderData) => {
    return orderData.map((order) => {
      let {
        orderId,
        orderNumber,
        serviceName,
        customerFirstName,
        customerLastName,
        bookingDate,
        optionName,
        orderAmount,
        currencyInfo,
      } = order;

      let id = orderId;
      let customerName = `${customerFirstName} ${customerLastName}`;
      let orderAmountF = (
        <CurrencyPreProcessor
          amount={orderAmount}
          currencyBreak={currencyInfo.currencyBreak}
          roundingFactor={currencyInfo.roundingFactor}
          currencySymbol={currencyInfo.currencySymbol}
        />
      );
      let bookingDateF = (
        <Moment format="MMMM, DD YYYY">{bookingDate}</Moment>
      );

      return {
        id,
        orderNumber,
        bookingDateF,
        customerName,
        serviceName,
        optionName,
        orderAmountF,
      };
    });
  };

  const getOrdersDetails = async () => {
    let ordersPayload = await listOrders({
      businessId: businessId,
      limit: currentLimit,
      page: currentPage,
      serviceIdList: service ? [service] : undefined,
      siteList: site ? [site] : undefined,
      startDate: start,
      endDate: end,
      customerIdList: cId?[cId]:undefined,
    });
    if (ordersPayload && ordersPayload.orders) {
      setOrderList(processOrderData(ordersPayload.orders.docs));
      setTotalDocs(ordersPayload.orders.totalDocs);
      setHasNext(ordersPayload.orders.hasNextPage);
      setHasPrevious(ordersPayload.orders.hasPrevPage);
      setInitialState(true);
    }
  };

  const getNextPage = () => {
    if (hasNext) {
      history.push(location.pathname + '?' + encodeGetParams({ ...queryParams, pg: currentPage + 1 }))

    }
  };

  const getPrevPage = () => {
    if (hasPrevious) {
      history.push(location.pathname + '?' + encodeGetParams({ ...queryParams, pg: currentPage - 1 }))
    }
  };

  const navigateToOrder = (orderId) => {
    history.push(`/dashboard/${businessId}/Orders/${orderId}`);
  };

  const ViewChanger = () => {
    if (matches) {
      return (
        <ListTable
          identifier={"admin_orders"}
          tableHeaders={orderHeaders}
          tableRows={orderList}
          rowClick={navigateToOrder}
        />
      );
    } else {
      return (
        orderList.map((mobileOrder) => {
          return (
            <ListCard
              action={() => { history.push(`/dashboard/${businessId}/Orders/${mobileOrder.id}`); }}
              cardName={mobileOrder.customerName}
              statusReplace={mobileOrder.orderAmountF}
              other={`#${mobileOrder.orderNumber}`}
            />
          )
        })
      );
    };
  };

  const setup = async () => {
    await withLoading(getOrdersDetails, {})
  }

  useEffect(() => {
    setup()
  }, [currentPage, site, service, start, end, cId]);

  if (initialState === null) {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={matches ? classes.mainWrapper : classes.mobilePadding}>
        <Grid item xs={12} className={matches ? null : classes.stickyHeader}>
          <div className={classes.headerPadding}>
            <span className={matches ? classes.topHeading : classes.mobileTopHeadingOne}>Orders</span>
            <Filter businessId={businessId} filterFields={['service', 'site', 'dateRange', 'customer', ]} />
          </div>
        </Grid>
        {orderList.length > 0 ?
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <ViewChanger />
            </div>
          </Grid> : <EmptyListImage name="Orders" />}
        {orderList.length > 0 ?
          <Grid item xs={12}>
            <ListTableNavigation
              currentPage={currentPage}
              currentLimit={currentLimit}
              totalDocs={totalDocs}
              tableRows={orderList}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              getPrevPage={getPrevPage}
              getNextPage={getNextPage}
            />
          </Grid> : null}
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  }
};

export default OrderList;