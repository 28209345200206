import React, { useEffect, useState } from 'react'
import { TextField, Grid, makeStyles, Typography, useMediaQuery, Button} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { encodeGetParams, retrieveGetParams, reverseGeoCode, safePush, withLoading, categoryList } from '../../utils';
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
    boxShadow: {
        height:80,
        backgroundColor: "#D3D3D355",
        borderRadius:5,
        paddingLeft:10,
        paddingRight:3
    },
    boxShadowO: {
        border: `1px solid ${theme.palette.accent1.main}`
    },
    seperator: {
        height: 40,
        borderTop: "5px solid white",
        borderBottom: "5px solid white",
        width: 2,
        backgroundColor: theme.palette.accent1.main
    },
    createButton: {
        color: theme.palette.primary.main,
    },
    createButton_back: {
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "#e0e0e0"
        }
    },
    createButton_backHome: {
        fontFamily: "'Work sans', sans-serif",
        fontSize:"16px",
        backgroundColor: "#0098D4",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#FFFFFF",
            color: "#0098D4"
        }
    },
    submitButton: {
        height: 40,
        minWidth: "0px !important",
        width: 40,
        padding: 0
    },
    submitButtonDisabled: {
        backgroundColor: "#e0e0e0 !important",
        color: "#c2c2c2 !important"
    },
    comp:
    {
        height: 40
    },
    formLabel: {
        fontSize: 14,
        color: theme.palette.textColor2.main
    },
    fieldText: {
        fontSize: 15,
        fontFamily: "'Work sans', sans-serif",
        color: theme.palette.primaryText.main,
        width: "calc(100% - 80px) !important",
        flexGrow: "initial"
    },
    inputRoot: {
        padding: "0px !important",
        borderRadius: 5
    },
    inputRootO: {
        padding: "0px !important",
    },
    noPadding: {
        padding: 0
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    textFieldContainer: {
        width: "calc(50% - 71px)",
        borderRadius: "5px !important"
    },
    textFieldContainerMarket: {
        width: "calc(50% - 62px)",
        borderRadius: "5px !important"
    },
    textFieldContainerO: {
        width: "calc(53% + 5px)",
    },
    mobileTextFieldContainer: {
        width: "100%",
        borderRadius: "5px !important"
    },
    whiteBack: {
        backgroundColor: "white"
    },
    whiteBackL: {
        backgroundColor: "white",
        fontFamily: "Cairo, sans-serif",
        height:60,
        justifyContent: "center"
    },
    noBorderO: {
        border: "none !important",
        borderRadius: "0px !important"
    },
    noBorder: {
        border: "none !important",
        borderRadius: "5px !important",
        height:50
    },
    noBorderL: {
        border: "none !important",
        borderRadius: "5px !important",
        width:130,
        height:60
        
    }
}));

const SearchBar = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const [categories, setCategories] = useState(null)
    const [inputSearch, setInputSearch] = useState(null)
    const bigEnough = useMediaQuery('(min-width: 750px)')
    const [search, setSearch] = useState(null)
    const [currPlaceData, setCurrPlaceData] = useState(null)
    const placeData = useSelector(state => state.placeData)
    const mobile = props.mobile
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: {
                country: "us",
            },
        },
    });



    const load = async () => {
        let data = await withLoading(categoryList, {});
        if (data && Array.isArray(data.categoryDetails)) {
            setCategories(data.categoryDetails)
        }
    }

    const storeAddressInfo = (placeData) => {
        dispatch({
            type: "UPDATE",
            payload: {
                placeData: placeData
            },
        });
    }

    const setUp = async () => {

        if (categories) {
            const { catId, plId } = (!props.home ? retrieveGetParams(location) : {})
            let choice = null
            let newPlaceData = null
            for (let i = 0; i < categories.length; i++) {
                if (categories[i].categoryId === catId) {
                    choice = categories[i]
                    break
                }
            }
            if (plId) {
                if (!placeData || plId !== placeData.place_id) {
                    newPlaceData = await withLoading(reverseGeoCode, { placeId: plId })
                    if (newPlaceData) {
                        storeAddressInfo(newPlaceData)
                    }
                }
                else {
                    newPlaceData = placeData
                }
            }
            else if (props.useRecent && placeData) {
                newPlaceData = placeData
            }
            if (choice && (newPlaceData || plId) && !props.home) {
                setInputSearch((choice ? choice.name : ""))
                setSearch(choice)
                setCurrPlaceData(newPlaceData)
                setValue(newPlaceData ? newPlaceData.formatted_address : placeData.formatted_address)
            }

        }
        else {
            await load()
        }
    }


    useEffect(() => {
        setUp()
    }, [location.search, categories, placeData])

    useEffect(() => {
        if (props.defLocation && !currPlaceData && !value && props.home) {
            setCurrPlaceData(props.defLocation)
            setValue(props.defLocation.formatted_address)
        }

    }, [props.defLocation, props.home])

    useEffect(() => {
        if (props.catDefId && categories && !search && props.home) {
            categories.forEach(category => {
                if (category.categoryId === props.catDefId) {
                    setSearch(category);
                    setInputSearch(category.name);
                }
            })
        }

    }, [props.catDefId, props.home, categories])



    const handleSubmit = () => {
        if (search && currPlaceData) {
            storeAddressInfo(currPlaceData)
            safePush(`/Market-Place?` + encodeGetParams({
                ...retrieveGetParams(location), catId: search.categoryId, plId: currPlaceData.place_id, pn: undefined
            }), history, location)

        }

    }

    const combineParams = (moreProps) => {
        const intoInputProps = (moreProps && moreProps.InputProps ? moreProps.InputProps : {})
        intoInputProps.classes = { input: classes.fieldText, root: classes.comp, notchedOutline: bigEnough && !mobile || props.lightColorButton ? classes.noBorder : null };
        intoInputProps.startAdornment = (
            <InputAdornment position="start">
                <LocationOnOutlinedIcon style={{ paddingLeft: 10 }} />
            </InputAdornment>
        )
        const otherProps = { ...moreProps, InputProps: intoInputProps, }
        return (otherProps)
    }

    const classes = useStyles()
    return (
        <Grid
            container
            alignItems="center"
            justifyContent={bigEnough && !mobile ? "flex-start" : "center"}
            className={bigEnough && !mobile && location.pathname != "/" ? classes.boxShadowO : bigEnough && !mobile ? classes.boxShadow : null}
            spacing={bigEnough && !mobile ? 0 : 2}
        >
            <Grid 
                item 
                className={(bigEnough && !mobile && location.pathname == "/" ? classes.textFieldContainer : bigEnough && !mobile ? classes.textFieldContainerO : classes.mobileTextFieldContainer)} 
                style={bigEnough && !mobile && location.pathname == "/" ? {paddingRight:5} : null}
            >
                <Autocomplete
                    disableClearable
                    options={categories ? categories : []}
                    getOptionLabel={(option) => option.name}
                    inputValue={(inputSearch ? inputSearch : "")}
                    onInputChange={(event, newValue) => {
                        if (newValue) {
                            setInputSearch(newValue)
                        }
                    }}
                    value={search}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setSearch(newValue);
                        }
                    }}
                    classes={{
                        inputRoot: classes.inputRoot,


                    }}
                    renderInput={(params) => {
                        params.InputProps.classes = {
                            input: classes.fieldText,
                            root: classes.comp,
                            notchedOutline: (bigEnough && !mobile) || props.lightColorButton ? classes.noBorder : null
                        }
                        params.InputProps.startAdornment = (
                            <InputAdornment position="start">
                                <PersonIcon style={{ paddingLeft: 10 }} />
                            </InputAdornment>
                        )
                        return (
                            <TextField
                                className={bigEnough && !mobile && location.pathname == "/" ? `${classes.highZ} ${classes.whiteBackL}`: `${classes.highZ} ${classes.whiteBack}`}
                                style={location.pathname == "/" ? {borderRadius:5} : null}
                                fullWidth
                                placeholder={"Type of Service"}
                                variant="outlined"
                                {...params}
                            />
                        )
                    }}
                />
            </Grid>
            {
                bigEnough && !mobile && location.pathname != "/" ?
                    <Grid item className={classes.seperator}>
                    </Grid> : null
            }
            <Grid item className={(bigEnough && !mobile ? classes.textFieldContainer : classes.mobileTextFieldContainer)}>
                <Autocomplete
                    disableClearable
                    getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.description
                    }
                    options={ready && status === "OK" && data ? data.slice(0, 4) : []}
                    classes={{
                        inputRoot: classes.inputRoot,
                        input: classes.fieldText
                    }}
                    value={value}
                    onChange={async (event, address) => {
                        if ((address ? address.description : "") !== value) {
                            try {
                                const result = await getGeocode({ address: (address ? address.description : "") });
                                setCurrPlaceData(result[0])

                            } catch (error) {
                                setCurrPlaceData(null)
                            }
                        }
                    }}
                    onInputChange={(event, newInputValue) => {
                        setValue(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            className={bigEnough && !mobile && location.pathname == "/" ? `${classes.highZ} ${classes.whiteBackL}`: `${classes.highZ} ${classes.whiteBack}`}
                            {...combineParams(params)}
                            variant="outlined"
                            style={location.pathname != "/" ? null : {borderRadius:5}}
                            placeholder="Location"
                            fullWidth
                            autoComplete={(props.autoComplete === true ? "on" : "off")}
                        />
                    )}
                    renderOption={(option) => {
                        const matches = option.structured_formatting.main_text_matched_substrings;
                        const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match) => [match.offset, match.offset + match.length])
                        );

                        return (
                            <Grid container alignItems="center">
                                <Grid item>
                                    <LocationOnIcon className={classes.icon} />
                                </Grid>
                                <Grid item xs>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                        >
                                            {part.text}
                                        </span>
                                    ))}

                                    <Typography variant="body2">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    }}
                />
            </Grid>
            <Grid item style={location.pathname == "/" && bigEnough && !mobile ? {paddingLeft:5} : null}>
                {bigEnough && !mobile ?
                    <Button
                        onClick={handleSubmit}
                        disableElevation
                        className={`${classes.submitButton} ${classes.createButton}`}
                        classes={{
                            root: location.pathname != "/" ? `${classes.createButton_back} ${classes.noBorderO}` : `${classes.createButton_backHome} ${classes.noBorderL}`,
                            disabled: classes.submitButtonDisabled
                        }}
                        variant="contained"
                        style={location.pathname == "/" ? undefined : {marginRight:-10}}
                    >
                        <SearchIcon style={location.pathname == "/" ? {paddingRight:8, fontSize:30}: {fontSize:30}} /> {location.pathname == "/" ? "SEARCH" : null}
                    </Button>
                    :
                    <div style={{borderRadius:5}}>
                        <Button
                            disabled={search === null || currPlaceData === null}
                            size= {location.pathname == "/" ? "large" : undefined}
                            onClick={handleSubmit}
                            variant="contained"
                            classes={{
                                root: `${classes.createButton_backHome} ${classes.noBorder}`
                            }}
                            style={{textTransform: "none"}}
                        >
                            <Typography>
                                Search
                            </Typography>
                        </Button>
                    </div>
                }
            </Grid>
        </Grid>
    )



}




export default SearchBar
