import {
  Button,
  Checkbox,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  addQualificationToAttendee,
  adminAttendeeDetails,
  getGenericDetails,
  removeQualificationFromAttendee,
  safePush,
  withLoading
} from "../../../../../../utils";
import AttendeeDetailsTopBlock from "../../../../../AttendeeDetails/AttendeeDetailsTopBlock";
import CModal from '../../../../../Form/CModal';
import Form from '../../../../../Form/Form';
import LightTooltip from '../../../../../Form/LightTooltip';
import GeneralDialog from '../../../../../GeneralDialog/GeneralDialog';
import { CAddIcon, CCloseIcon } from '../../../../../Icons/Icons';
import ListTable from "../../../../../TableComponents/ListTable";
import StatusTag from "../../../../../TagComponents/StatusTag";

const useStyles = makeStyles((theme) => ({
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  rightElem: {
    float: "right",
  },
  buttontext: {
    textTransform: "none",
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  headerPadding: {
    padding: "10px",
  },
  sdCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "25%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "3px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#0079fc",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));


/*
Pack Number
Option Name
Validity Start date
Validity End Date
*/

const packHeaders = [
  {
    name: "Pack #",
    columnKey: "packNumber",
    columnWidth: "90px"
  },
  {
    name: "Option",
    columnKey: "optionName",
    columnWidth: "50%",
  },
  {
    name: "Valid From",
    columnKey: "validityStart",
    columnWidth: "150px"
  },
  {
    name: "Valid Till",
    columnKey: "validityEnd",
    columnWidth: "150px"
  },
  {
    name: "",
    columnKey: "status",
    columnWidth: "110px",
    type: "Normal"
  },
]

const packHeadersM = [
  {
    name: "Pack #",
    columnKey: "packNumber",
    columnWidth: "90px"
  },
  {
    name: "Option",
    columnKey: "optionName",
    columnWidth: "100%",
  },
]


const processQualiNames = (qualiNameData) => {
  return qualiNameData.map((qualis) => {
    let { name, qualificationId, isActive, type } = qualis;

    let id = qualificationId;
    return {
      id,
      isActive,
      name,
      type
    };
  });
};

const getAttendeeDetails = async (updateState, buisnessId, shareId, updateDoc, setQualiChoices) => {
  const detailsResponse = await withLoading(adminAttendeeDetails, {
    businessId: buisnessId,
    shareId,
  });
  if (detailsResponse && !detailsResponse.error) {
    updateState(detailsResponse.attendeeDetails);
    updateDoc(`${detailsResponse.attendeeDetails.firstName} ${detailsResponse.attendeeDetails.lastName}`);
  }
  let genericResponse = await getGenericDetails({
    businessId: buisnessId,
  });
  if (genericResponse && genericResponse.genericDetails) {
    setQualiChoices(
      processQualiNames(genericResponse.genericDetails.qualificationDetails)
    );
  }
};

const AttendeeDetails = () => {
  const buisnessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const [attendeeDetails, setAttendeeDetails] = useState(null);
  const [docTitle, setDocTitle] = useState("Attendee Details");
  const [qualiChoices, setQualiChoices] = useState([])
  const [qualList, setQualList] = useState([])
  const [qAOpen, setQAOpen] = useState(false);
  const [qROpen, setQROpen] = useState(false);
  const { shareIdURL } = useParams();
  const classes = useStyles();
  const bigEnough = useMediaQuery("(min-width:750px )");
  const componentRef = useRef();

  function handleQualChange(id) {
    const newQualList = [...qualList]
    newQualList.push(id)
    setQualList(newQualList)
  }

  function handleQualRemove(id) {
    const newQualList = [...qualList]
    const index = newQualList.indexOf(id);
    if (index > -1) {
      newQualList.splice(index, 1);
    }
    setQualList(newQualList);
  }

  const handleQAdd = async (event) => {

    let success = await withLoading(addQualificationToAttendee, {
      businessId: buisnessId,
      shareId: attendeeDetails.shareId,
      qualificationIds: event.qualIds,
    });
    if (success) {
      getAttendeeDetails(setAttendeeDetails, buisnessId, shareIdURL, setDocTitle, setQualiChoices);
      setQAOpen(false)
    }
  };

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: docTitle,
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });





  useEffect(() => {
    getAttendeeDetails(setAttendeeDetails, buisnessId, shareIdURL, setDocTitle, setQualiChoices);
  }, []);

  if (attendeeDetails) {
    return (
      <React.Fragment>
        <GeneralDialog
          open={qROpen}
          close={() => setQROpen(false)}
          title={"Remove Qualification"}
          text={
            "Are you sure you want to remove the selected qualification(s)?"
          }
          action={async () => {
            const response = await withLoading(removeQualificationFromAttendee, {
              businessId: buisnessId,
              qualificationIds: qualList,
              shareId: attendeeDetails.shareId,
            })
            if (response && !response.error) {
              getAttendeeDetails(setAttendeeDetails, buisnessId, shareIdURL, setDocTitle, setQualiChoices);
              setQROpen(false)
              setQualList([])
            }
          }}
        />
        <CModal open={qAOpen} setOpen={setQAOpen}>
          <Container component="main" className={classes.formContainer}>
            <Form
              action={handleQAdd}
              postAction={null}
              title="Add Qualification(s)"
              cancelEnabled
              cancelAction={() => {
                setQAOpen(false);
              }}
              cancelText="Cancel"
              submitText="Add"
              inputFields={[
                {
                  type: "MultiSelect",
                  label: "Qualifications",
                  choices: qualiChoices
                    .filter((choice) => choice.isActive === true && choice.type === 'Attendee')
                    .map((choices) => {
                      return [choices.name, choices.id];
                    }),
                  name: "qualIds",
                  validator: (value) => {
                    if (value.length === 0) {
                      return "Cannot be empty";
                    }
                  },
                  initialvalue: "",
                },
              ]}
            ></Form>
          </Container>
        </CModal>
        <Grid
          container
          className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
        >
          <Grid item xs={12}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>ATTENDEE</span>
            </div>
          </Grid>
          <div>
            <IconButton
              onClick={handlePrint}
              component="span"
              style={{ color: "#3366FF" }}
            >
              <PrintIcon />
            </IconButton>
            <AttendeeDetailsTopBlock
              ref={componentRef}
              attendeeDetails={attendeeDetails}
              admin
            />
          </div>
          <Grid
            container
            item
            xs={12}
            direction="row"
            className={classes.headingWrapper}
          >
            <Grid item xs={6}>
              <span className={classes.subHeading}>Qualifications</span>
            </Grid>
            <Grid item xs={6}>
              <LightTooltip
                title={
                  qualList.length === 0
                    ? "Add Qualification"
                    : "Remove Qualification"
                }
                arrow
              >
                {qualList.length > 0 ? (
                  <span className={classes.rightElem}>
                    <Button
                      className={classes.buttonBasic}
                      onClick={() => {
                        setQROpen(true);
                      }}
                    >
                      <CCloseIcon />
                    </Button>
                  </span>
                ) : (
                  <span className={classes.rightElem}>
                    <Button
                      className={classes.buttonBasic}
                      onClick={() => {
                        setQAOpen(true);
                      }}
                    >
                      <CAddIcon />
                    </Button>
                  </span>
                )}
              </LightTooltip>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {attendeeDetails.qualifications?.length > 0 ? (
                <TableContainer style={{ maxHeight: "231px", maxWidth: "100%" }}>
                  <Table
                    stickyHeader
                    aria-label="simple table"
                    style={{ tableLayout: "fixed" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.headerPadding}
                          style={{ width: "25px" }}
                        />
                        <TableCell
                          align="left"
                          className={classes.headerPadding}
                          style={{ width: "calc(100%)" }}
                        >
                          <Typography noWrap className={classes.labelText}>
                            Name
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attendeeDetails.qualifications.map((qualification) => {
                        return (
                          <TableRow key={qualification.qualificationId}>
                            <TableCell
                              style={{ width: "5%" }}
                              className={classes.sdCustomCellFour}
                            >
                              <Checkbox
                                style={{ color: "#0079fc" }}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    handleQualChange(
                                      qualification.qualificationId
                                    );
                                  } else {
                                    handleQualRemove(
                                      qualification.qualificationId
                                    );
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.sdCustomCellOne}
                            >
                              <Typography
                                noWrap
                                className={classes.valueTextLink}
                              >
                                <LightTooltip title="Qualification Page" arrow>
                                  <Link
                                    style={{
                                      color: "#0079fc",
                                      textDecoration: "none",
                                    }}
                                    to={`/dashboard/${buisnessId}/Qualifications/${qualification.qualificationId}`}
                                  >
                                    {qualification.name}
                                  </Link>
                                </LightTooltip>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    No Qualifications applied to this attendee.
                  </span>
                </div>
              )}
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            className={classes.headingWrapper}
          >
            <Grid item xs={12}>
              <span className={classes.subHeading}>Packs</span>
            </Grid>

          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <AttendeePacks attendeeDetails={attendeeDetails} businessId={buisnessId} />
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return null;
  }
};


export const AttendeePacks = (props) => {
  const bigEnough = useMediaQuery("(min-width:750px )");
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  return (
    props.attendeeDetails.packageRecords?.length > 0 ? (
      <ListTable
        styling={{ width: "100%", maxHeight: "300px" }}
        identifier={"packs"}
        tableHeaders={bigEnough ? packHeaders : packHeadersM}
        tableRows={props.attendeeDetails.packageRecords.map((pack) => ({
          ...pack,
          id: pack.optionId,
          validityStart: moment(pack.validityStart).format("MMM Do yyyy"),
          validityEnd: moment(pack.validityEnd).format("MMM Do yyyy"),
          status: pack.isCancelled ? <StatusTag wrapperName="redWrapper" value="Canceled" /> : null
        }))}
        rowClick={props.businessId ? (id) => { safePush(`/dashboard/${props.businessId}/Pricings/${id}`, history, location) } : undefined}
      />
    ) : (
      <div className={classes.topMarginBlock}>
        <span className={classes.valueTextPad}>
          No packages for this attendee.
        </span>
      </div>
    )
  )
}

export default AttendeeDetails;
