import React, { useState } from 'react'
import { useSelector} from 'react-redux';
import Form from '../../../../../Form/Form'
import CModal from '../../../../../Form/CModal'
import { Container, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import { editService} from '../../../../../../utils';
import maxTextField from '../../../../../../FormValidation/maxTextField';
import maxTextArea from '../../../../../../FormValidation/maxTextArea';
import { CColor } from '../../../../../Form/CColorPicker';
const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        width:"100%",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))


const EditServiceForm = (props) => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles();
    const [c, setC] = useState('#3366ff');
    const [change, setChange] = useState(false);


    const refresh = (success) => {
        if (success) {
            props.setOpen(false)
            props.refresh()
        }
    }


    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    moreData={{ businessId: businessId, serviceId: props.serviceDetails.serviceId, primaryColor: c }}
                    cancelEnabled
                    title="Edit Service"
                    cancelAction={() => { props.setOpen(false); setChange(false); }}
                    submitText="Save"
                    comp={<Grid container>
                        <Grid item>
                            <CColor
                                label="Display Color"
                                onchange={(color) => {setC(color); setChange(true);}}
                                color={!change ? props.serviceDetails.primaryColor: c}
                            />
                        </Grid>
                    </Grid>}
                    action={editService}
                    postAction={refresh}
                    inputFields={[
                        {
                            type: "TextField",
                            name: "name",
                            label: "Service Name",
                            validator: [notEmpty, noWhiteSpace, maxTextField],
                            initialvalue: props.serviceDetails.name

                        },
                        {
                            type: "TextArea",
                            name: "description",
                            label: "Description",
                            validator: [notEmpty, noWhiteSpace, maxTextArea],
                            moreProps: {
                                minRows: 8
                            },
                            initialvalue: props.serviceDetails.description
                        },
                        {
                            type:"Custom"
                        }

                    ]}
                />
            </Container>
        </CModal>
    );

};

export default EditServiceForm