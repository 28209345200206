import { Container, makeStyles } from "@material-ui/core";
import { attachOptionsToCatalog } from "../../../../../../utils";
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    width: "100vw",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflowY: "auto",
  },
}));

const CatalogPricingOptionForm = (props) => {
  const classes = useStyles();
  const alreadyAssigned = new Set(
    props.catalog.options.map((option) => option.optionId)
  );

  return (
    <CModal open={props.open === true} setOpen={props.setOpen}>
      <Container className={classes.formContainer}>
        <Form
          moreData={{
            businessId: props.businessId,
            catalogId: props.catalog.catalogId,
          }}
          cancelEnabled
          cancelAction={() => {
            props.setOpen(false);
          }}
          submitText="Assign"
          action={attachOptionsToCatalog}
          postAction={(res) => {
            if (res?.error === false) {
              props.setOpen(false);
              props.refetch();
            }
          }}
          title="Assign Pricing Options to Catalog"
          inputFields={[
            {
              type: "MultiSelect",
              label: "Pricing Options",
              choices: props.pricingOptions
                .filter(
                  (option) =>
                    !alreadyAssigned.has(option.optionId) && option.isActive
                )
                .map((option) => [option.name, option.optionId]),
              name: "optionIds",
              validator: (value) =>
                value?.length > 0
                  ? null
                  : "At least one pricing option must be selected",
            },
          ]}
        />
      </Container>
    </CModal>
  );
};

export default CatalogPricingOptionForm;
