import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { addResourceInternalEvent, addResourceInternalSeries, getGenericDetails, withLoading } from '../../../../../../utils';
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto"

    }
}))

const AddInternalEventResourceForm = (props) => {

    const alreadyUsedResourceIdSet = useMemo(() => {
        const alreadyUsedResourceIdSet = new Set()
        props.internalEventDetails.resourceDetails.forEach(resourceDetail => {
            alreadyUsedResourceIdSet.add(resourceDetail.resourceId)
        });
        return alreadyUsedResourceIdSet
    }, [props.internalEventDetails])

    const mountedRef = useRef(false)
    const [genericDetails, setGenericDetails] = useState(null)
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()

    const fetchGenericDetails = async () => {
        const genericResponse = await withLoading(getGenericDetails, {
            businessId
        })
        if (!genericResponse.error) {
            setGenericDetails(genericResponse.genericDetails)
        }
    }

    useEffect(() => {
        if (!mountedRef.current) {
            mountedRef.current = true
        }

        if (!props.open && genericDetails) {
            setGenericDetails(null)
        }
        else if (props.open && mountedRef.current) {
            fetchGenericDetails()
        }
    }, [props.open])

    return (
        <React.Fragment>
            <CModal open={props.open} setOpen={props.setOpen}>
                <Container component="main" className={classes.formContainer}>
                    <Form
                        moreData={{
                            businessId: businessId,
                            eventId: props.internalEventDetails.eventId,
                            editTag: props.internalEventDetails.editTag,
                            seriesId: props.internalEventDetails.seriesId
                        }}
                        title="Add Resource(s) to Internal Event(s)"
                        cancelEnabled
                        cancelAction={() => { props.setOpen(false) }}
                        submitText="Add"
                        action={async (payload) => {
                            const finalPayload = {
                                businessId: businessId,
                                eventId: props.internalEventDetails.eventId,
                                editTag: props.internalEventDetails.editTag,
                                seriesId: props.internalEventDetails.seriesId,
                                resourceIds: payload.resourceIds

                            }
                            if (payload.applyAll === true) {
                                return await addResourceInternalSeries(finalPayload)
                            }
                            else {
                                return await addResourceInternalEvent(finalPayload)
                            }
                        }}
                        postAction={(res) => {
                            if (res && !res.error) {
                                props.setOpen(false)
                                props.fetchInternalEventDetails()
                            }
                        }}
                        inputFields={[
                            {
                                type: "MultiSelect",
                                name: "resourceIds",
                                label: "Resources",
                                validator: value => (value === null || value.length === 0 ? "Select resource(s) to add" : null),
                                choices: genericDetails === null ? [] : genericDetails.resourceDetails
                                    .filter(resourceDetail => !alreadyUsedResourceIdSet.has(resourceDetail.resourceId) && resourceDetail.siteId === props.internalEventDetails.siteId)
                                    .map(resourceDetail => [resourceDetail.name, resourceDetail.resourceId])
                            },
                            {
                                type: "CheckBox",
                                name: "applyAll",
                                label: "Add resource(s) to all events in series",
                                enabled: props.internalEventDetails.seriesId !== null
                            },
                        ]}
                    />
                </Container>
            </CModal>
        </React.Fragment>
    )
}

export default AddInternalEventResourceForm