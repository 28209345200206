import { Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  comp: {
    width: "100%",
  },
  formLabel: {
    fontSize: 14,
    color: theme.palette.textColor2.main,
  },
  fieldText: {
    fontSize: 16,
    color: theme.palette.primaryText.main,
    padding: "10px",
  },
  addIcon: {
    color: "green",
  },
  delIcon: {
    color: "red",
  },
}));

const delFromArr = (arr, index) => {
  const newData = [];
  arr.forEach((item, i) => {
    if (i !== index) {
      newData.push(item);
    }
  });
  return newData;
};

const CMultiField = (props) => {
  const classes = useStyles();
  const [data, setData] = useState(["", ""]);

  const updateData = (newData, index) => {
    const temp = [...data];
    temp[index] = newData;
    return temp;
  };

  useEffect(() => {
    props.updateGlob?.();
    if (Array.isArray(props.initialvalue)) {
      props.updateGlob?.(props.initialvalue);
      setData(props.initialvalue);
    }
  }, []);

  return (
    <Grid container justifyContent="center">
      {data.map((field, index) => (
        <Grid item xs={12} key={`choice-${index}`}>
          <Grid container>
            <Grid item style={{ paddingTop: 14 }}>
              <IconButton
                className={classes.delIcon}
                disabled={index <= 1}
                onClick={() => {
                  const newData = delFromArr(data, index);
                  setData(newData);
                  props.updateGlob?.(newData);
                }}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Grid>
            <Grid item style={{ width: "calc(100% - 50px)" }}>
              <Typography className={classes.formLabel} align="left">
                {`Choice #${index + 1}`}
              </Typography>
              <TextField
                className={classes.comp}
                onChange={(event) => {
                  const newData = updateData(event.target.value, index);
                  setData(newData);
                  props.updateGlob?.(newData);
                  props.onchange(newData);
                }}
                error={
                  Array.isArray(props.errortext) && props.errortext[index]
                    ? true
                    : false
                }
                helperText={
                  Array.isArray(props.errortext) && props.errortext[index]
                    ? props.errortext[index]
                    : ""
                }
                value={field}
                InputProps={{
                  classes: {
                    input: classes.fieldText,
                  },
                }}
                variant="outlined"
              ></TextField>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item>
        <IconButton
          className={classes.addIcon}
          onClick={() => {
            const newData = [...data, ""];
            setData(newData);
            props.updateGlob?.(newData);
          }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CMultiField;
