import React, { useState, useEffect } from "react";
import CModal from "../../../../../Form/CModal";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Link,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  classAttendanceSheetStaff,
  markClassAttendanceStaff,
} from "../../../../../../utils";
import CTitle from "../../../../../Form/CTitle";
import LightTooltip from "../../../../../Form/LightTooltip";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    width: "900px",
    maxWidth: "100vw",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  cancel: {
    fontSize: 14,
    color: theme.palette.primaryText.main,
    textTransform: "none",
  },
  attendeeBlock: {
    backgroundColor: "#F7FAFC",
    borderRadius: "4px",
    textAlign: "center",
    padding: "4px",
  },
  attendeeBlockHeader: {
    backgroundColor: "#fafafa",
    borderRadius: "4px",
    textAlign: "center",
    padding: "4px",
    fontWeight: "500",
  },
  textContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none",
  },
}));

const StaffAttendanceSheet = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [attendanceSheet, setAttendanceSheet] = useState([]);
  const history = useHistory();

  const loadSheet = async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let sheet = await classAttendanceSheetStaff({
      businessId: props.businessId,
      classId: props.classId,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (sheet) {
      setAttendanceSheet(sheet.attendanceSheet);
    }
  };

  const attendanceAPICall = async (sessionId, attendanceCall) => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    await markClassAttendanceStaff({
      businessId: props.businessId,
      classId: props.classId,
      sessionId,
      attendanceCall,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
  };

  useEffect(() => {
    loadSheet();
  }, []);

  const markAttendance = (event, sessionId, dataIndex) => {
    let tempSheet = [...attendanceSheet];
    let tempElement = { ...tempSheet[dataIndex] };
    tempElement.attendanceRecord = event.target.value;
    tempSheet[dataIndex] = tempElement;
    setAttendanceSheet(tempSheet);
    try {
      attendanceAPICall(sessionId, event.target.value);
    } catch (error) {
      console.error(error);
    }
  };

  const navigateToSession = (sessionId) => {
    history.push(`/dashboard/${props.businessId}/Sessions/${sessionId}`);
  };

  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Container component="main" className={classes.formContainer}>
        {attendanceSheet.length > 0 ? (
          <Grid container spacing={2}>
            <CTitle
              title={`Attendance Sheet - #${props.classNumber}`}
              center={props.titleCenter}
            />
            <Grid item xs={12}>
              <Grid
                container
                spacing={1}
                className={classes.attendeeBlockHeader}
              >
                <Grid item xs={2}>
                  <div className={classes.textContainer}>
                    <Typography>Session#</Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.textContainer}>
                    <Typography>Attendee Name</Typography>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.textContainer}>
                    <Typography>Attendance</Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {attendanceSheet.map((attendee, index) => {
              return (
                <Grid item xs={12} key={`session: ${attendee.sessionId}`}>
                  <Grid container spacing={1} className={classes.attendeeBlock}>
                    <Grid item xs={2}>
                      <div className={classes.textContainer}>
                        <Link className={classes.valueTextLink}>
                          <Typography
                            noWrap
                            className={classes.valueTextLink}
                            onClick={() => {
                              navigateToSession(attendee.sessionId);
                            }}
                          >
                            {attendee.sessionNumber}
                          </Typography>
                        </Link>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.textContainer}>
                        <LightTooltip title={attendee.attendeeName} arrow>
                          <Typography noWrap>
                            {attendee.attendeeName}
                          </Typography>
                        </LightTooltip>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="attendance"
                          name="attendance-radio-buttons-group"
                          value={attendee.attendanceRecord}
                          onChange={(event) => {
                            markAttendance(event, attendee.sessionId, index);
                          }}
                        >
                          <FormControlLabel
                            value="Present"
                            control={<Radio color="primary" />}
                            label="Present"
                          />
                          <FormControlLabel
                            value="Absent"
                            control={<Radio color="primary" />}
                            label="Absent"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => {
                  props.setOpen(false);
                }}
                style={{ float: "right" }}
              >
                <Typography className={classes.cancel}>Cancel</Typography>
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <CTitle
              title={`Attendance Sheet - #${props.classNumber}`}
              center={props.titleCenter}
            />
            <Grid item xs={12}>
              <div className={classes.textContainer}>
                <Typography>
                  There are no attendees available for this class.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => {
                  props.setOpen(false);
                }}
                style={{ float: "right" }}
              >
                <Typography className={classes.cancel}>Cancel</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </CModal>
  );
};

export default StaffAttendanceSheet;
