import React from 'react';
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import CustomerQualification from "./CustomerQualification";
import CustomerQualificationsList from "./CustomerQualificationsList";

function CustomerQualiHandler() {
    const history = useHistory();
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    return(
        <React.Fragment>
            <Switch>
            <Route path = {`/dashboard/${businessId}/Qualifications`} exact render = {() => {return(<CustomerQualificationsList />)}} />
            <Route path = {`/dashboard/${businessId}/Qualifications/:qualificationIdURL`} render = {() => {return(<CustomerQualification />);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default CustomerQualiHandler;