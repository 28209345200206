import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import AttendeeDetails from './AttendeeDetails';
import AttendeeList from './AttendeeList';

const AttendeeHandler = () => {
    const basePath = `/account/Attendees`
    return (
        <Switch>
            <Route path={basePath} exact render={() => { return (<AttendeeList basePath={basePath} />) }} />
            <Route path={`${basePath}/:attendeeIdURL`} render={() => { return (<AttendeeDetails />); }} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
        </Switch>
    );
}

export default AttendeeHandler;