import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, makeStyles, InputAdornment, Button, Typography } from '@material-ui/core'
import Form from '../../../Form/Form'
import CModal from '../../../Form/CModal'
import { customerUpdatePassword, withLoading, genOTP, count, changeEmail, logout } from '../../../../utils'
import notEmpty from '../../../../FormValidation/notEmpty'
import validEmail from '../../../../FormValidation/validEmail'
import validOTP from '../../../../FormValidation/OTP'

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        width: "100%",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))

const ChangeEmail = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const resendDisabled = useSelector((state) => { return (state.resendDisabled) })
    const email = useRef();
    const refresh = (response) => {
        if (response) {
            props.setOpen(false)
            props.refresh()
        }
    }

    const countDown = async (seconds) => {
        for (let i = 0; i < seconds; i++) {
            setTimeout(() => {
                dispatch({
                    type: 'UPDATE', payload: {
                        resendDisabled: i
                    }
                });
            }, (seconds - i) * 1000)
        }

    }

    async function resendOTP() {

        let success = await withLoading(genOTP, {
            email: email.current.value
        })

        if (success) {
            countDown(120)
        }




    }

    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    title="Change Email"
                    cancelEnabled
                    cancelAction={() => { props.setOpen(false) }}
                    submitText="Update"
                    action={changeEmail}
                    postAction={logout}
                    inputFields={[
                        {
                            type: "TextField",
                            name: "newEmail",
                            validator: [(value) => { setButtonDisabled(Boolean(validEmail(value))) }, notEmpty, validEmail],
                            label: "New Email",
                            moreProps: {
                                inputRef: email,
                                inputProps: {
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    }
                                }
                            }

                        },
                        {
                            type: "Password",
                            initialvalue: "",
                            validator: notEmpty,
                            label: "Password",
                            name: "password",
                        },
                        {
                            type: "TextField",
                            name: "otp",
                            label: "Email Verification Code",
                            validator: [notEmpty, validOTP],
                            moreProps: {
                                InputProps: {
                                    endAdornment:
                                        <InputAdornment position="end" classes={{ inputAdornedEnd: classes.endAdornment }}>
                                            <Button onClick={resendOTP} className={classes.button} color="primary" disabled={Boolean(resendDisabled) || buttonDisabled}>
                                                <Typography className={classes.buttonText} >
                                                    {(resendDisabled ? `Wait ${resendDisabled}s` : "Get Code")}
                                                </Typography>
                                            </Button>
                                        </InputAdornment>
                                }
                            }

                        }

                    ]}
                />
            </Container>
        </CModal>
    )

}

export default ChangeEmail