import { Container, makeStyles} from "@material-ui/core"
import { installmentOption } from "../../../../../../utils"
import CModal from "../../../../../Form/CModal"
import Form from "../../../../../Form/Form"
const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto"

    }
}))



// Props has businessId and optionId
const InstallmentPayment = (props) => {
    const classes = useStyles()
    const { businessId, optionId, open, setOpen, refresh:refreshFunction} = props

    const refresh = (res) => {
        if(res){
            setOpen(false)
            refreshFunction()
        }
    }

    return (
        <CModal open = {open} setOpen = {setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    moreData={{ businessId: businessId, optionId: optionId, installmentEnabled:true}}
                    title="Configure Installment Payment"
                    cancelEnabled
                    cancelAction={() => { setOpen(false) }}
                    submitText="Confirm"
                    action={installmentOption}
                    postAction={refresh}
                    inputFields={[
                        {
                            type:"Number",
                            name:"installmentCount",
                            label:"Number of Installments (Max 4)",
                            validator:value=>value>=2 && value<=4?null:"Must enter a number between 2 and 4",
                            numberProps:{
                                decimalScale:0
                            }
                        },
                        {
                            type:"SingleSelect",
                            name:"installmentFrequency",
                            label:"Installment Frequency",
                            validator:value=>!value?"Must select a frequency":null,
                            choices:[
                                ["Every week", "1 Week"],
                                ["Every two weeks", "2 Week"],
                                ["Every month", "1 Month"]
                            ]
                        }
                    ]}
                />
            </Container>
        </CModal>
    )
}

export default InstallmentPayment