import { Feedback } from '@material-ui/icons';
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import MoneyIcon from "@material-ui/icons/Money";
import PaymentIcon from "@material-ui/icons/Payment";
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from "@material-ui/icons/Person";
import ViewListIcon from "@material-ui/icons/ViewList";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import React from 'react';
import AttendeeHandler from './AccountOptions/Attendees/AttendeeHandler';
import CreditHandler from './AccountOptions/Credits/CreditHandler';
import FeedbackHandler from './AccountOptions/Feedback/FeedbackHandler';
import OrdersHandler from "./AccountOptions/Orders/OrderHandler";
import PackagesHandler from "./AccountOptions/Packages/PackageHandler";
import PaymentMethodsHandler from "./AccountOptions/PaymentMethods/PaymentMethodsHandler";
import PreferredBusinessesHandler from './AccountOptions/PreferredBusinesses/PreferredBusinessesHandler';
import Profile from './AccountOptions/Profile/Profile';
import ScheduleHandler from './AccountOptions/Schedule/ScheduleHandler';
const routes = [
    { type: "route", route: 'Schedule', comp: <ScheduleHandler />, icon: <WatchLaterIcon /> },
    { type: "route", route: 'Orders', comp: <OrdersHandler />, icon: <LibraryBooksIcon /> },
    { type: "route", route: 'Packages and Passes', comp: <PackagesHandler />, icon: <ConfirmationNumberIcon /> },
    { type: "route", route: 'Attendees', comp: <AttendeeHandler />, icon: <PeopleIcon /> },
    { type: "route", route: 'Feedback', comp: <FeedbackHandler />, icon: <Feedback /> },
    { type: "route", route: 'Payment Methods', comp: <PaymentMethodsHandler />, icon: <PaymentIcon /> },
    { type: "route", route: 'Credits', comp: <CreditHandler />, icon: <MoneyIcon /> },
    { type: "route", route: 'My Businesses', comp: <PreferredBusinessesHandler />, icon: <ViewListIcon /> },
    { type: "route", route: 'My Profile', comp: <Profile />, icon: <PersonIcon /> },
    
];

export default routes