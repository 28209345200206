import React from 'react'
import Form from "../../../../../Form/Form"
import CModal from "../../../../../Form/CModal";
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import { useSelector} from 'react-redux';
import { serviceWaiver } from '../../../../../../utils';
import maxTextArea from '../../../../../../FormValidation/maxTextArea';

// import { editService} from '../../../../../../utils';
const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    width:"100%",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflowY: "auto"
  }
}))


const WaiverForm = (props) => {

  const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()

  const refresh = (success) => {
    if (success) {
        props.setOpen(false)
        props.refresh()
    }
}

  return(
    <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    moreData={{ businessId: businessId, serviceId: props.serviceDetails.serviceId, waiverSignRequired:  true}}
                    title="Waiver"
                    cancelEnabled
                    cancelAction={() => { props.setOpen(false) }}
                    action={serviceWaiver}
                    postAction={refresh}
                    inputFields={[
                        {
                            type: "TextArea",
                            moreProps:{
                                rowsMin:8
                            },
                            name: "waiverDocument",
                            resizeEnabled: true,
                            label: "Waiver Content",
                            validator: [notEmpty, noWhiteSpace, maxTextArea],
                            initialvalue: props.serviceDetails.waiverDocument

                        }

                    ]}
                />
            </Container>
        </CModal>
  )
}

export default WaiverForm