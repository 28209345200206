import React from 'react'
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    img: {
        height: 150,
        width: 150
    },
    errorText: {
        color: theme.palette.textColor3.main,
    }
}))

const EmptyListImage = (props) => {
    const classes = useStyles()
    return (
        <Grid container alignItems="center" direction="column" justifyContent="center" display="flex" style={{ height:`calc(100vh - 177px - ${props.offset?props.offset:'0px'})` }}>

            <img className={classes.img} src="https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/error-logo/no-data-found-dashboard.png" alt="Server Issue" />
            <Typography variant = "h5">
                {props.name}
            </Typography>
            <Typography className={classes.errorText}>
                There are no results
            </Typography>

        </Grid>
    )
}

export default EmptyListImage