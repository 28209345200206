import React from 'react'
import { searchRecords } from '../../utils'
import LightTooltip from '../Form/LightTooltip'
import { Link } from 'react-router-dom'
import {
    Grid, makeStyles,
    useMediaQuery, Card, CardActionArea,
    CardMedia, CardContent, CardActions,
    Button, Typography
} from '@material-ui/core'
import Rating from '@material-ui/lab/Rating';
import { formatSrc } from '../Image/Image'

const useStyles = makeStyles(theme => ({
    card: {
        "&:hover": {
            backgroundColor: theme.palette.accent2.main
        }
    },
    root: {
        padding: 10
    },
    media: {
        maxWidth: "100%",
        height: 250
    },
    imageContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 250,
        boxSizing: "box-border"
    },
    textColor3: {
        color: theme.palette.textColor3.main
    },
    link: {
        textDecoration: "none",
        color: theme.palette.primary.main
    },
    fullWidth: {
        width: "100%"
    }
}))


const OfferingCard = (props) => {

    const classes = useStyles()
    return (
        <Grid item xs={props.gridSize} style={{ maxWidth: props.bigEnough ? undefined : "100%" }}>

            <Link style={{ textDecoration: "none" }} className={classes.fullWidth} to={`/Market-Place/${props.record.offeringId}`}>
                <Card elevation={0}
                    className={classes.card}
                >
                    {/* <CardActionArea
                        disableRipple
                        disableTouchRipple
                    > */}
                    <CardMedia
                        className={classes.media}
                        component = "img"
                        image={formatSrc(props.record.image,{
                            desktopOptions:[
                                'fit-in/500x500'
                            ],
                            mobileOptions:[
                                'fit-in/400x400'
                            ],
                            mobile:!props.bigEnough
                        })}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src=props.record.image;
                          }}
                    />
                    <CardContent
                        classes={{
                            root: classes.root
                        }}
                    >

                        <Grid container>
                            <Grid item xs={12}>
                                <LightTooltip arrow title={props.record.serviceName}>
                                    <Typography variant="h5" noWrap color="primary">
                                        {props.record.serviceName}
                                    </Typography>
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <LightTooltip arrow title={props.record.businessName}>
                                    <Typography >
                                        {props.record.businessName}
                                    </Typography>
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    props.record.isVirtual
                                        ?
                                        <Typography noWrap className={classes.textColor3}>
                                            Virtual
                                        </Typography>

                                        :
                                        <LightTooltip arrow title={props.record.address1 + (props.record.address2 ? ", " + props.record.address2 : "")}>
                                            <Typography noWrap className={classes.textColor3}>
                                                {props.record.address1 + (props.record.address2 ? ", " + props.record.address2 : "")}
                                            </Typography>
                                        </LightTooltip>


                                }
                            </Grid>
                            <Grid item xs={12}>
                                <div>

                                    {!props.record.isVirtual ?
                                        <Typography style={{ float: "right", maxWidth: "100px" }} noWrap>
                                            {`${props.record.distance} mi`}
                                        </Typography >
                                        : null}

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Rating name="read-only" value={props.record.rating | 0} readOnly precision={0.1} />
                                        <Typography style={{ paddingLeft: (props.record.rating !== null ? 10 : 10) }}>
                                            {props.record.totalReviews === 0 ? `0 Reviews` : props.record.totalReviews === 1 ? `${props.record.totalReviews} Review` : `${props.record.totalReviews} Reviews`}
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>


                        </Grid>

                    </CardContent>
                    {/* </CardActionArea> */}

                </Card>
            </Link>

        </Grid>
    )
}

export default OfferingCard