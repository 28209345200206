import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { listDiscounts } from "../../../../../../utils";
import { listMemberships } from "../../../../../../utils";
import { addDiscount } from "../../../../../../utils";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Grid, Container, Typography, CircularProgress } from "@material-ui/core";
import ListTable from "./../../../../../TableComponents/ListTable";
import StatusTag from "../../../../../TagComponents/StatusTag";
import Form from "../../../../../Form/Form";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import noWhiteSpace from "../../../../../../FormValidation/noWhiteSpace";
import { futureDate } from "../../../../../../FormValidation/validDate";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListCard from "../../../../../MobileCards/ListCard";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import CModal from "../../../../../Form/CModal";
import LightTooltip from "../../../../../Form/LightTooltip";
import positive from "../../../../../../FormValidation/positive";
import PostFilter from '../../../../../Filter/PostFilter'

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px"
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  headerPadding: {
    paddingBottom: "10px"
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "inset 0 -1px #e3e8ee"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px"
  }
}));

const keyObjects = [
  {
    key: 'isActive',
    label: 'Status',
    keyLabel: 'statusName'
  },
  {
    key: 'type',
    label: 'Type',
    keyLabel: 'type'
  }
]

const DiscountsList = () => {
  const [add, setAdd] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const error = useSelector(state => state.error);
  const errorMessage = useSelector(state => state.errorMessage);
  const small = useMediaQuery('(min-width: 750px)');
  const large = useMediaQuery('(min-width: 960px)');

  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  async function handleSubmit(event) {
    dispatch({
      type: 'UPDATE', payload: {
        loading: true
      }
    });
    let success = await addDiscount({
      businessId: businessId,
      name: event.discountName,
      description: event.description ? event.description : null,
      percentage: event.percent,
      type: event.type,
      endDate: event.date ? event.date : null,
      membershipId: event.membership ? event.membership : null,
    })
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
    if (success) {
      history.push(`${location.pathname}/${success.discountId}`);
    }
  };

  const discountsHeaders = [
    {
      name: "status",
      columnKey: "status",
      columnWidth: "100px",
      type: "Normal"
    },
    {
      name: "name",
      columnKey: "wrappedName",
      columnWidth: "35%",
      tooltip: true
    },
    {
      name: "type",
      columnKey: "wrappedType",
      columnWidth: "25%",
      tooltip: true
    },
    {
      name: "percentage",
      columnKey: "percent",
      columnWidth: "91px",
    },
  ];

  const [discountsList, setDiscountsList] = useState([]);
  const [type, setType] = useState(null);
  const [initialState, setInitialState] = useState(null);
  const [membershipChoices, setMembershipChoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [done, setDone] = useState(false);

  const processMembershipNames = (membershipNameData) => {
    return membershipNameData.map((memberships) => {
      let {
        name,
        membershipId,
        isActive
      } = memberships;

      let id = membershipId;

      return {
        id,
        isActive,
        name
      };
    });
  };

  const processDiscountsData = (discountData) => {
    return discountData.map((discount) => {
      let {
        discountId,
        name,
        isActive,
        type,
        percentage
      } = discount;

      let status = <StatusTag wrapperName={isActive ? "greenWrapper" : "redWrapper"} value={isActive ? "Active" : "Inactive"} />;
      let percent = `${percentage}%`;
      let id = discountId;
      let wrappedType = <Typography noWrap style={{ fontSize: "14px", color: "#1a1f36", width: "100%", fontFamily: "Rubik, sans-serif" }}>{type}</Typography>;
      let wrappedName = <Typography noWrap style={{ fontSize: "14px", color: "#1a1f36", width: "100%", fontFamily: "Rubik, sans-serif" }}>{name}</Typography>;

      return {
        id,
        status,
        isActive,
        statusName: isActive ? 'Active' : 'Not Active',
        type,
        name,
        wrappedName,
        wrappedType,
        percent
      };
    });
  };

  const getMembershipNames = async () => {
    let names = await listMemberships({
      businessId: businessId
    });
    if (names && names.membershipsDetails) {
      setMembershipChoices(processMembershipNames(names.membershipsDetails));
      setInitialState(true);
    }
  }
  const getDiscountsDetails = async () => {
    let discountsPayload = await listDiscounts({
      businessId: businessId
    });
    if (discountsPayload && discountsPayload.discountDetails) {
      setDiscountsList(processDiscountsData(discountsPayload.discountDetails));
    }
  };

  const navigateToDiscount = (discountId) => {
    history.push(`/dashboard/${businessId}/Discounts/${discountId}`);
  };

  const ViewChanger = () => {
    if (small) {
      return (
        <ListTable
          identifier={"admin_orders"}
          tableHeaders={discountsHeaders}
          tableRows={discountsList}
          rowClick={navigateToDiscount}
        />
      );
    } else {
      return (
        discountsList.map((mobileDiscount) => {
          return (
            <ListCard
              action={() => { history.push(`/dashboard/${businessId}/Discounts/${mobileDiscount.id}`); }}
              cardName={mobileDiscount.name}
              statusTagValue={mobileDiscount.isActive}
              activeValue="Active"
              inactiveValue="Inactive"
              other={mobileDiscount.percent}
            />
          )
        })
      );
    };
  };

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    await getDiscountsDetails();
    await getMembershipNames();
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
  }, [currentPage, currentLimit]);

  if (initialState === null) {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={large ? classes.mainWrapper : !large && small ? classes.mobilePadding : null} >
        <CModal open={add} setOpen={setAdd}
          children={
            <Container component="main" className={classes.formContainer}>
              <Form
                action={handleSubmit}
                postAction={null}
                cancelEnabled
                title="New Discount"
                cancelAction={() => { setAdd(false); setType(null); }}
                cancelText="Cancel"
                submitText="Create"
                inputFields={
                  [
                    {
                      type: "TextField",
                      validator: [notEmpty, noWhiteSpace],
                      initialvalue: "",
                      label: "Name",
                      name: "discountName",
                    },
                    {
                      type: "SingleSelect",
                      label: "Type",
                      choices: [
                        ["Multi Purchase", "Multi Purchase"],
                        ["Flat", "Flat"],
                        ["Membership", "Membership"],
                        ["Early Bird", "Early Bird"]
                      ],
                      name: "type",
                      validator: value => (value === null ? "Cannot be empty" : value === "Early Bird" ? setType("EB") : value === "Membership" ? setType("M") : setType(false)),
                      initialvalue: ""
                    },
                    {
                      type: "Percent",
                      label: "Percent",
                      validator: [notEmpty, positive],
                      initialvalue: "",
                      name: "percent",
                      numberProps: {
                        decimalScale: 1
                      }
                    },
                    {
                      type: "Date",
                      initialvalue: "",
                      validator: [notEmpty, futureDate],
                      label: "End Date",
                      name: "date",
                      enabled: type === "EB"
                    },
                    {
                      type: "SingleSelect",
                      label: "Membership",
                      choices: membershipChoices.filter(choice => choice.isActive === true).map((choices) => {
                        return choices.isActive && [
                          choices.name, choices.id
                        ];
                      }),
                      validator: (value) => (value === null ? "Must chose a membership" : null),
                      name: "membership",
                      initialvalue: membershipChoices.length > 0 ? "" : 1,
                      enabled: type === "M"
                    },
                    {
                      type: "TextArea",
                      initialvalue: "",
                      label: "Description (optional)",
                      name: "description",
                      resizeEnabled: false,
                      moreProps: {
                        rowsMin: 4,
                        rowsMax: 10
                      },
                    }
                  ]
                }
              >
              </Form>
            </Container>
          }
        />
        <Grid container item xs={12} direction="row" className={small ? null : classes.stickyHeader} style={!large && small ? { paddingTop: "15px" } : null}>
          <Grid item xs={6}>
            <div className={classes.headerPadding}>
              <span className={small ? classes.topHeading : classes.mobileTopHeadingOne}>Discounts</span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.headerPadding}>
              <LightTooltip title="Create Discount" arrow>
                <span className={classes.rightElem}>
                  <Button style={{ marginLeft: '10px', textTransform: 'none' }} variant="contained" color='primary' onClick={() => { setAdd(true); }}>Add</Button>
                </span>

              </LightTooltip>
              <PostFilter allEntries={discountsList} keyObjects={keyObjects} />
            </div>
          </Grid>
        </Grid>
        {discountsList.length > 0 ?
          <Grid item xs={12} className={small ? null : classes.mobilePadding}>
            <div>
              <ViewChanger />
            </div>
          </Grid> : <EmptyListImage name="Discounts" />}
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  }
};

export default DiscountsList;