import { Button, Card, CardActionArea, CardContent, CardMedia, Grid, makeStyles, SvgIcon, Typography, useMediaQuery } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import React, { useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { geoCodeLatLng, recommendedBusiness, safeQuery, withLoading, getTrendingPrograms } from '../../utils'
import LightTooltip from '../Form/LightTooltip'
import { Benefit, Feature } from '../Header/RegisterBuisness/RBPrettyPage'
import { PersonSearchIcon } from '../Header/RegisterBuisness/RBPrettyPageUtils';
import SearchBar from '../SearchBar/SearchBar'
import { formatSrc } from '../Image/Image';
const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: 'bold'
    },
    white: {
        color: theme.palette.white.main
    },
    media: {
        maxWidth: "100%",
        height: 300,
    },
    section: {
        paddingTop: 100,
        paddingBottom: 100
    },
    title: {
        paddingTop: 50,
        paddingBottom: 50,
        paddingLeft: 10,
        paddingRight: 10
    },
    imageContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 250,
        boxSizing: "box-border"
    },
    root: {
        padding: 10
    },
    fullWidth: {
        width: "100%"
    },
    background: {
        backgroundColor: theme.palette.accent2.main
    },

    textColor3: {
        color: theme.palette.textColor3.main
    },
    success: {
        color: theme.palette.success.main
    },
    "for-business": {
        padding: 8,
        height: 600
    },
    FBImage: {
        objectFit: "cover",
        maxHeight: "300px",
        width: "100%"
    },
    FBImageContainer: {
        display: "flex",
        padding: 10,
        justifyContent: "center",
    },
    link: {
        textDecoration: "none",
        color: theme.palette.primary.main
    },
    white: {
        color: "white"
    },
    valuePropIcon: {
        color: theme.palette.primary.main,
        height: 45,
        width: 45
    },
    createButton_backHome: {
        fontFamily: "'Work sans', sans-serif",
        fontSize: "24px",
        backgroundColor: "#0098D4",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#FFFFFF",
            color: "#0098D4"
        }
    },
    MPImage: {
        objectFit: "contain",
        maxHeight: "300px",
        maxWidth: "100%"
    },
    MPImageContainer: {
        display: "flex",
        padding: 10,
        justifyContent: "center",
    }
}))




const valuePropData = [
    {
        icon: <PersonSearchIcon style={{ height: "45px", width: "45px" }} color="primary" />,
        title: "Top-notch Professionals",
        content: "All of our professionals have industry-leading reputations and proven track records. Discover the best program for you."
    },
    {
        icon: <VerifiedUserIcon style={{ height: "45px", width: "45px" }} color="primary" />,
        title: "Hassle-free",
        content: "Professional dealings with vetted businesses, financial security, and information transparency"
    },
    {
        icon: <ThumbUpAltIcon style={{ height: "45px", width: "45px" }} color="primary" />,
        title: "Effortless",
        content: "Easy to book, schedule, and pay - all in your online portal. Prompt and up-to-date communications."
    },

]

function groupIntoThrees(children) {
    const output = []
    let currentGroup = []

    children.forEach((child, index) => {
        currentGroup.push(child)

        if (index % 3 === 2) {
            output.push(currentGroup)
            currentGroup = []
        } else if (children.length % 3 != 0 & index == children.length - 1) {
            output.push(currentGroup)
            currentGroup = []
        }
    })

    return output
}

const RecommendedBusiness = (props) => {
    const { img, title, subtitle, url } = props
    const classes = useStyles()
    const mobile = useMediaQuery("(max-width:750px)")

    return (
        <Grid item style={!mobile ? { width: "350px", height: "350" } : { width: "70%", height: "100%" }}>
            <Link style={{ textDecoration: "none" }} className={classes.fullWidth} to={url}>
                <Card elevation={3} style={{ boxShadow: "5px 10px 12px lightgrey" }}>
                    <CardActionArea
                        disableRipple
                        disableTouchRipple
                    >
                        <CardMedia
                            className={classes.media}
                            image={formatSrc(img, {
                                desktopOptions: [
                                    'fit-in/500x500'
                                ],
                                mobileOptions: undefined,
                                mobile: false
                            })}
                            style={{
                                height: "250px"
                            }}
                        />
                        <CardContent
                            classes={{
                                root: classes.root
                            }}
                        >

                            <Grid container>
                                <Grid item xs={12}>
                                    <LightTooltip arrow title={title}>
                                        <Typography variant="h5" noWrap style={{ paddingTop: 5, paddingBottom: 5 }}>
                                            {title}
                                        </Typography>
                                    </LightTooltip>
                                    {subtitle ?
                                        <LightTooltip arrow title={subtitle}>
                                            <Typography variant="h6" noWrap style={{ paddingTop: 5, paddingBottom: 5 }}>
                                                {subtitle}
                                            </Typography>
                                        </LightTooltip>
                                        : null}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>

                </Card>
            </Link>
        </Grid>
    )
}

const feature = {
    img: 'https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/home-page/user-benefits.png',
    title: "A trusted and convenient booking experience",
    list: [
        "Review program details, coach profiles, and commnets to choose your favorite coach and / or program",
        "Manage bookings, payment, scheduling, and check-ins conveniently from mobile / tablets on the go",
        "All actions, communications, financial transactions, and schedules are always transparent",
        "All financial information and transactions are securely maintained in PCI compliant system"
    ],
    imgFirst: true
}

const SFDef = {
    "formatted_address": "San Francisco, CA, USA",
    "geometry": {
        "location": {
            "lat": 37.7749295,
            "lng": -122.4194155
        },
        "viewport": {
            "northeast": {
                "lat": 37.812,
                "lng": -122.3482
            },
            "southwest": {
                "lat": 37.70339999999999,
                "lng": -122.527
            }
        }

    },
    "name": "San Francisco",
    "place_id": "ChIJIQBpAG2ahYAR_6128GcTUEo"
}

const catDefId = "60123e46ba614973be5c5d29"

const Home = (props) => {
    const classes = useStyles()
    const shrink = useMediaQuery("(max-width: 1200px)")
    const mobile = useMediaQuery("(max-width:750px)")
    const [item, setItem] = useState(0);
    const [defLocation, setDefLocation] = useState(null)
    const [recomendations, setRecomendations] = useState(null)
    const [trending, setTrending] = useState(null)
    const placeData = useSelector(state => state.placeData)
    const setUpRecomended = async () => {
        let data = await withLoading(recommendedBusiness, {
            limit: 20,
            page: 1
        })
        if (data) {
            setRecomendations(data)
        }
    }
    const setUpTrending = async () => {
        const data = await withLoading(getTrendingPrograms, {
            "limit": 10,
            "page": 1
        })
        if (data) {
            setTrending(data)
        }
    }
    useEffect(() => {
        setUpRecomended()
        setUpTrending()
        getLocation()
    }, [])

    function getLocation() {
        if (placeData) {
            setDefLocation(placeData)
        }
        else if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(async (loc) => {
                let data = await withLoading(geoCodeLatLng, { lat: loc.coords.latitude, lng: loc.coords.longitude })
                if (data) {
                    setDefLocation(data)
                }
                else {
                    setDefLocation(SFDef)
                }
            }, () => { setDefLocation(SFDef) });
        }
        else {
            setDefLocation(SFDef)
        }
    }

    return (
        <Grid container>
            <Grid item style={{ marginTop: -64, backgroundColor: "black", zIndex: 0, width: "100%", position: "relative", overflowX: "hidden" }}>
                <div
                    style={{
                        width: "100%",
                        height: !mobile ? "calc(100vh - 193px)" : "calc(100vh - 233px)",
                        minHeight: "600px",
                        position: "absolute",
                        zIndex: -1
                    }}
                >
                    <div

                        style={{
                            backgroundImage: `url('https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/home-page/home-hero-large.jpeg')`,
                            height: "calc(100vh - 150px)",
                            opacity: 0.5,
                            position: "relative",
                            width: "100vw",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            minHeight: "600px",
                        }}
                    />
                </div>

                <Grid container justifyContent="center" alignItems="center" style={{
                    padding: 8, height: "calc(100vh - 150px)", zIndex: 0, minHeight: "600px",
                }}>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} >
                            <Typography variant={!mobile ? "h3" : "h5"} style={{ fontFamily: "'Work Sans', sans-serif" }} className={classes.white} align="center">
                                <b>Take your game to the next level</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                            <Typography className={classes.white} align="center" variant={!mobile ? 'h5' : 'body1'}>
                                Discover your new favorite sports & fitness, and educational coaching programs
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ width: "100vw", maxWidth: 950 }}>
                            <SearchBar defLocation={defLocation} home catDefId={catDefId} />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}
                style={{
                    height: !mobile ? "214px" : "234px",
                    zIndex: 0,
                    backgroundColor: "black",
                    position: "relative",
                    overflowX: "hidden",
                }}
            >
                <div
                    style={{
                        width: "100vw",
                        height: !mobile ? "214px" : "234px",
                        position: "absolute",
                        zIndex: -1,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "#1a213d",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        opacity: 0.5
                    }}
                />
                <Grid container justifyContent="center" alignItems='center' style={!mobile ? { maxWidth: "1200px", margin: "auto", height: "214px", zIndex: 2, padding: "10px 20px 10px 20px" } : { height: "234", zIndex: 2, padding: "10px 10px 10px 10px" }}>
                    <Grid item style={{ width: mobile ? "100%" : "calc(100% - 245px)" }} >
                        <Typography style={!mobile ? { fontWeight: "bold", fontFamily: "'Work Sans', sans-serif", fontSize: 30 } : { fontWeight: "bold", fontFamily: "'Work Sans', sans-serif", fontSize: 20 }} className={classes.white} align={mobile ? 'center' : 'left'}>
                            For Business Owners
                        </Typography>
                        <Typography variant={!mobile ? "body1" : "body2"} style={{ fontWeight: "100", fontFamily: "'Work Sans', sans-serif" }} className={classes.white} align={mobile ? 'center' : 'left'}>
                            Run your business smarter with the most advanced solution built for the coaching business. Spend time where it matters the most.
                        </Typography>
                    </Grid>
                    <Grid item style={{ paddingLeft: !mobile ? '40px' : '0px' }}>
                        <Link to="/for-business" className={classes.link}>
                            <Button
                                variant="contained"
                                classes={{
                                    root: classes.createButton_backHome
                                }}
                                style={!mobile ? { width: "200px", height: "60px", borderRadius: 25, textTransform: "none" } : { width: "125px", height: "50px", borderRadius: 25, textTransform: "none" }}
                            >
                                <Typography style={!mobile ? { fontSize: "20px" } : { fontSize: "16px" }}>
                                    Explore
                                </Typography>
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
            {
                safeQuery(recomendations, ["totalBusiness"]) > 0 ?
                    <Grid className={mobile ? null : classes.section} item xs={12} style={mobile ? { paddingTop: 50, paddingBottom: 50 } : { paddingTop: 75, paddingBottom: 75 }}>
                        <Typography variant={"h4"} align="center" style={{ paddingBottom: 50, fontFamily: "'Work Sans', sans-serif", fontWeight: "450", color: "#000000" }} >
                            Recommended Tennis Coaching near you
                        </Typography>
                        <div style={{ padding: 16, maxWidth: "1440px", margin: "0 auto" }}>
                            <Carousel
                                fullHeightHover={false}
                                interval={9000}
                                navButtonsAlwaysVisible={false}
                                swipe={true}
                                showDots={true}
                                navButtonsProps={{
                                    style: {
                                        padding: 10,
                                        backgroundColor: "#0098D4",
                                        marginTop: "30px",
                                    }
                                }}
                                indicatorIconButtonProps={{
                                    style: {
                                        color: "#dee2e6",
                                        marginTop: "50px",
                                        "&:hover": {
                                            color: "#dee2e6",
                                        }
                                    }
                                }}
                                activeIndicatorIconButtonProps={{
                                    style: {
                                        color: "#0098D4",
                                    },
                                }}
                                style={{ padding: 8 }}
                            >
                                {!mobile ? groupIntoThrees(recomendations.business, mobile).map((business, index) => (
                                    <Grid container direction="row" spacing={3} justifyContent="center" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                        <RecommendedBusiness
                                            title={business[0].businessName}
                                            img={business[0].image}
                                            key={`rec business ${index}-1`}
                                            url={`/business/${business[0].businessId}`}
                                        />
                                        {business.length >= 2 ?
                                            <RecommendedBusiness
                                                title={business[1].businessName}
                                                img={business[1].image}
                                                key={`rec business ${index}-2`}
                                                url={`/business/${business[1].businessId}`}
                                            />
                                            : null}
                                        {business.length >= 3 ?
                                            <RecommendedBusiness
                                                title={business[2].businessName}
                                                img={business[2].image}
                                                key={`rec business ${index}-3`}
                                                url={`/business/${business[2].businessId}`}
                                            />
                                            : null}
                                    </Grid>
                                )) :
                                    recomendations.business.map((business, index) => {
                                        return <Grid container item direction="row" spacing={3} style={{ height: "300px" }} justify="center">
                                            <RecommendedBusiness
                                                title={business.businessName}
                                                img={business.image}
                                                url={`/business/${business.businessId}`}
                                                key={`rec business ${index}`} />
                                        </Grid>
                                    })
                                }
                            </Carousel>
                        </div>
                    </Grid> : null
            }
            {
                safeQuery(trending, ["totalOptions"]) > 0 ?
                    <Grid className={`${mobile ? null : classes.section} ${classes.background}`} item xs={12} style={mobile ? { paddingTop: 50, paddingBottom: 50 } : { paddingTop: 75, paddingBottom: 75 }}>
                        <Typography variant={"h4"} align="center" style={{ paddingBottom: 50, fontFamily: "'Work Sans', sans-serif", fontWeight: "450", color: "#000000" }} >
                            Trending Tennis Programs near you
                        </Typography>
                        <div style={{ padding: 16, maxWidth: "1440px", margin: "0 auto" }}>
                            <Carousel
                                fullHeightHover={false}
                                interval={9000}
                                navButtonsAlwaysVisible={false}
                                swipe={true}
                                showDots={true}
                                navButtonsProps={{
                                    style: {
                                        padding: 10,
                                        backgroundColor: "#0098D4",
                                        marginTop: "30px",
                                    }
                                }}
                                indicatorIconButtonProps={{
                                    style: {
                                        color: "#dee2e6",
                                        marginTop: "50px",
                                        "&:hover": {
                                            color: "#dee2e6",
                                        }
                                    }
                                }}
                                activeIndicatorIconButtonProps={{
                                    style: {
                                        color: "#0098D4",
                                    },
                                }}
                                style={{ padding: 8 }}
                            >
                                {!mobile ? groupIntoThrees(trending.options, mobile).map((offering, index) => (
                                    <Grid container item direction="row" spacing={3} style={{ height: "350px", paddingLeft: "10px", paddingRight: "10px" }} justify="center">
                                        <RecommendedBusiness
                                            title={offering[0].optionName}
                                            img={offering[0].image}
                                            key={`trending program ${index}-1`}
                                            url={`/Market-Place/${offering[0].offeringId}`}
                                        />
                                        {offering.length >= 2 ?
                                            <RecommendedBusiness
                                                title={offering[1].optionName}
                                                img={offering[1].image}
                                                key={`trending program ${index}-2`}
                                                url={`/Market-Place/${offering[1].offeringId}`}
                                            />
                                            : null}
                                        {offering.length >= 3 ?
                                            <RecommendedBusiness
                                                title={offering[2].optionName}
                                                img={offering[2].image}
                                                key={`trending program ${index}-3`}
                                                url={`/Market-Place/${offering[2].offeringId}`}
                                            />
                                            : null}
                                    </Grid>
                                )) :
                                    trending.options.map((offering, index) => {
                                        return <Grid container item direction="row" spacing={3} style={{ height: "300px" }} justify="center">
                                            <RecommendedBusiness
                                                title={offering.optionName}
                                                img={offering.image}
                                                key={`trending program ${index}-3`}
                                                url={`/Market-Place/${offering.offeringId}`}
                                            />
                                        </Grid>
                                    })
                                }
                            </Carousel>
                        </div>
                    </Grid> : null
            }

            <Grid item xs={12} className={classes.section}>
                <Grid container justifyContent="center" style={{ maxWidth: "1440px", width: "100%", margin: "0 auto" }}>
                    <Grid item xs={12} style={{ paddingBottom: '40px' }}>
                        <Typography color="primary" variant="h4" align="center" className={classes.bold}>
                            Caritra is the go-to site for all your coaching needs
                        </Typography>
                    </Grid>
                    {valuePropData.map((data, index) => (
                        <Benefit key={`beneift-${index}`} title={data.title} text={data.content} icon={data.icon} breakpoints={{
                            xs: 12,
                            md: 4,
                            lg: 4,
                        }} />
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: '100px' }} className={`${classes.background}`}>

                <div style={{ maxWidth: 1200, margin: "0 auto", padding: "10px" }}>
                    <Grid container spacing={2} justifyContent="center">
                        <Feature background='none' img={feature.img} title={feature.title} content={feature.content} list={feature.list} imgFirst={feature.imgFirst} bigEnough={!mobile} />
                    </Grid>
                </div>
            </Grid>
        </Grid >
    )
}




export default Home