import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import { cancelAddon, withLoading } from "../../../../../../utils";


const CancelAddon = (props) => {
    const {open, setOpen, businessId, refresh} = props
    return (
        <GeneralDialog
            open = {open}
            close = {setOpen}
            title = "Cancel Add On"
            text = "Are you sure you want to cancel this add on?"
            action = {async () => {
                const response = await withLoading(cancelAddon,{
                    refId:open,
                    businessId:businessId
                })
                if(response && !response.error){
                    setOpen(false)
                    refresh()
                }
            }}
        />
    )
}

export default CancelAddon