import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { customerUpcomingSessions, encodeGetParams, withLoading } from "../../../../../utils";
import { Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ListTable from "../../../../TableComponents/ListTable";
import ListTableNavigation from "../../../../TableComponents/ListTableNavigation";
import StatusTag from "../../../../TagComponents/StatusTag";
import ListCard from "../../../../MobileCards/ListCard";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography } from "@material-ui/core";
import EmptyListImage from "../../../../EmptyListImage/Empty";
// import ReviewForm from "./ReviewForm";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "inset 0 -1px #e3e8ee"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  valueText: {
    fontSize: 14,
  }
}));
const upcomingHeaders = [
  {
    name: "session #",
    columnKey: "sessionNumber",
    columnWidth: "100px",
  },
  {
    name: "Attendee",
    columnKey: "attendeeName",
    columnWidth: "25%",
    tooltip: true
  },
  {
    name: "service",
    columnKey: "serviceName",
    columnWidth: "25%",
    tooltip: true
  },
  {
    name: "business name",
    columnKey: "businessName",
    columnWidth: "25%",
    tooltip: true
  },
  {
    name: "start time",
    columnKey: "startDateTime",
    columnWidth: "170px",
    tooltip: true
  },

];

const UpcomingList = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const matches = useMediaQuery('(min-width: 750px)');
  const [upcomingList, setUpcomingList] = useState([]);
  const [initialState, setInitialState] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  const processUpcomingData = (upcomingData) => {
    return upcomingData.map((upcoming) => {
      let {
        sessionId,
        sessionNumber,
        serviceName,
        siteName,
        businessName,
        startTime,
        startDate,
        isReviewed,
        attendeeFirstName,
        attendeeLastName


      } = upcoming;
      let attendeeName = `${attendeeFirstName} ${attendeeLastName}`
      let startDateTime = `${startDate} ${startTime}`
      let id = sessionId;



      return {
        id,
        sessionNumber,
        serviceName,
        siteName,
        businessName,
        startDateTime,
        attendeeName
      };
    });
  };

  const getUpcomingDetails = async () => {
    let upcomingPayload = await customerUpcomingSessions({
      limit: currentLimit,
      page: currentPage,
    });
    if (upcomingPayload && upcomingPayload.upcomingSessions) {
      setUpcomingList(processUpcomingData(upcomingPayload.upcomingSessions.docs));
      setTotalPages(upcomingPayload.upcomingSessions.totalPages);
      setTotalDocs(upcomingPayload.upcomingSessions.totalDocs);
      setHasNext(upcomingPayload.upcomingSessions.hasNextPage);
      setHasPrevious(upcomingPayload.upcomingSessions.hasPrevPage);
      setInitialState(true);
    }
  };

  const getNextPage = () => {
    if (hasNext) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPrevPage = () => {
    if (hasPrevious) {
      setCurrentPage(currentPage - 1);
    }
  };

  const navigateToUpcoming = (sessionId) => {
    history.push(`${props.basePath}/${sessionId}`);
  };

  const ViewChanger = () => {
    if (matches) {
      return (
        <ListTable
          identifier={"upcoming_sessions"}
          tableHeaders={upcomingHeaders}
          tableRows={upcomingList}
          rowClick={navigateToUpcoming}
        />
      );
    } else {
      return (
        upcomingList.map((upcoming) => {
          return (
            <ListCard
              action={() => { history.push(`${props.basePath}/${upcoming.id}`); }}
              cardName={upcoming.sessionNumber}
              statusReplace={
                <React.Fragment>
                  <Typography noWrap className={classes.valueText}>
                    {upcoming.startDateTime}
                  </Typography>
                  <Typography noWrap className={classes.valueText}>
                    {upcoming.serviceName}
                  </Typography>
                </React.Fragment>
              }
              other={upcoming.review}
            />
          )
        })
      );
    };
  };

  useEffect(async () => {
    await withLoading(getUpcomingDetails, {}, true)
  }, [currentPage, currentLimit]);

  if (initialState === null) {
    return (null);
  } else {
    return (
      <React.Fragment>
        {upcomingList.length > 0 ?
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <ViewChanger />
            </div>
          </Grid> : <EmptyListImage name = "Upcoming Sessions" offset = "48px"/>}
        {upcomingList.length > 0 ?
          <Grid item xs={12}>
            <ListTableNavigation
              currentPage={currentPage}
              currentLimit={currentLimit}
              totalDocs={totalDocs}
              tableRows={upcomingList}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              getPrevPage={getPrevPage}
              getNextPage={getNextPage}
            />
          </Grid> : null}
      </React.Fragment>
    );
  }
};

export default UpcomingList;
