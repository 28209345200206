import React, { useEffect, useRef, useState } from 'react'
import { Container, Grid, makeStyles, Typography } from "@material-ui/core"
import CModal from "../Form/CModal"
import { withLoading, makePayment, getOptionDetails } from '../../utils'
import CTitle from '../Form/CTitle'
import CModalError from '../Form/CModalError'
import CButtonPair from '../Form/CButtonPair'
import NewPayment from '../Checkout/NewPayment'
import { useDispatch } from 'react-redux'
import CurrencyPreProcessor from '../CurrencyFormatter/CurrencyFormatter'
/*
props = {
open,
setOpen,
orderDetails
businessId
}
*/


const useStyles = makeStyles(theme => ({
    formContainer: {
        border: "20px",
        backgroundColor: "white",
        borderRadius: "4px",
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto",
    },
    cardHolder: {
        maxWidth: 350,
        width: "100vh",
        padding: 8
    },
    comp:
    {
        width: "100%"
    },
    formLabel: {
        fontSize: 14,
        color: "#3C4257"
    },
    fieldText: {
        fontSize: 14,
        color: "#1A1F36",
        padding: "10px",
        paddingLeft: "31px"
    },
    showChoice: {
        fontSize: 14,
        color: "#1A1F36",
        padding: "10px",
    },
    helperText: {
        marginRight: 14,
        marginLeft: 14
    },
    selected: {
        fontSize: 14,
        color: theme.palette.primary.main,
        padding: "10px"
    },
    check: {
        fontSize: 14,
        paddingRight: 7
    },
    menuPaper: {
        maxHeight: 220,
    },
    noPadding: {
        padding: 0
    }
}))

const getNewCardInfo = async (stripe, cardElement, save) => {

    if (!stripe || !cardElement) {
        return undefined;
    }
    const { error, token } = await stripe.createToken(cardElement)
    if (error) {

        return {
            error: error
        }
    }
    else if (token) {
        return {
            token: token.id,
            brand: token.card.brand,
            last4: token.card.last4,
            save: save
        }
    }
}

const MakePayment = (props) => {
    const { open, setOpen, orderDetails, refresh } = props
    const dispatch = useDispatch()
    const [newCustomerCard, setNewCustomerCard] = useState(null)
    const classes = useStyles()



    const payNow = async () => {
        const { stripe, cardElement, save } = newCustomerCard
        const newCardData = await getNewCardInfo(stripe, cardElement, save)
        if (newCardData && !newCardData.error) {
            const response = await withLoading(makePayment, {
                orderId: orderDetails.orderId,
                planId: open,
                token: newCardData.token
            })
            if (response && !response.error) {
                setOpen(false)
                refresh()
            }
        }
        else {
            dispatch({
                type: "UPDATE",
                payload: {
                    error: true,
                    errorMessage: newCardData.error.message
                }
            })
        }

    }

    const getPaymentPlanAmount = (planId) => {
        for (let plan of orderDetails.paymentPlan) {
            if (plan.planId === planId) {
                return CurrencyPreProcessor({
                    amount: plan.amount,
                    ...orderDetails.currencyInfo
                })
            }
        }
    }

    return (
        <React.Fragment>
            <CModal
                open={open}
                setOpen={setOpen}
            >
                <Container className={classes.formContainer}>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <CTitle title="Pay Now" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <CModalError />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                            {
                                open ?
                                    <Typography>
                                        {`The amount that will be processed is ${getPaymentPlanAmount(open)}.`}
                                    </Typography>
                                    : null
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <NewPayment setNewCustomerCard={setNewCustomerCard} setErrors={() => { }} installment={true} />
                    </Grid>
                    <Grid item xs={12}>
                        <CButtonPair
                            errors={[]}
                            notSubmit
                            submitText={"Pay"}
                            action={payNow}
                            cancelEnabled cancelText={"Cancel"}
                            cancelAction={(bool) => { setOpen(false) }}
                        />
                    </Grid>
                </Container>
            </CModal>
        </React.Fragment>
    )
}


export default MakePayment