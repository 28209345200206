import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Redirect } from 'react-router'
import Checkout from './Checkout'

const CheckoutHandler = (props) => {
    const customerSelection = useSelector(state => state.customerSelection)
    const dispatch = useDispatch()
    const clearSelectId = () => {
        dispatch({
            type: "UPDATE",
            payload: {
                customerSelection: undefined
            }
        })
    }

    useEffect(() => {
        return () => {
            if (customerSelection) {
                clearSelectId()
            }
        }
    }, [])

    if (customerSelection) {
        return <Checkout customerSelection = {customerSelection} />
    }
    else {
        return <Redirect to  = "/401"/>
    }
}

export default CheckoutHandler