import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { forgetOTP, withLoading, resetPassword } from "../../../utils";
import { useHistory, Link } from "react-router-dom";
import validEmail from "../../../FormValidation/validEmail";
import validPassword from "../../../FormValidation/password";
import validOTP from "../../../FormValidation/OTP";
import notEmpty from "../../../FormValidation/notEmpty";
import Form from "../../Form/Form";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Grid,
  Button,
  Typography,
  InputAdornment,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontSize: 14,
    textTransform: "none",
  },
  button: {
    padding: 0,
  },
  container: {
    maxWidth: "500px",
    minHeight: "calc(100vh - 64px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
function ForgotPassword(props) {
  const classes = useStyles();
  const email = useRef(null);
  const history = useHistory();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const resendDisabled = useSelector((state) => {
    return state.resendDisabled;
  });
  const dispatch = useDispatch();

  const countDown = async (seconds) => {
    for (let i = 0; i < seconds; i++) {
      setTimeout(() => {
        dispatch({
          type: "UPDATE",
          payload: {
            resendDisabled: i,
          },
        });
      }, (seconds - i) * 1000);
    }
  };

  async function resendOTP(event) {
    let success = await withLoading(forgetOTP, {
      email: email.current.value,
    });
    if (success) {
      countDown(120);
    }
  }

  const redirect = (response) => {
    if (response) {
      if (props.cOForm) {
        props.setL();
      } else {
        history.push(`/login`);
      }
    }
  };

  return (
    <div
      style={{
        minWidth: "100vw",
        minHeight: "calc(100vh - 64px)",
        backgroundColor: "#F7FAFC",
      }}
    >
      <Container component="main" className={classes.container}>
        <div
          style={{
            border: "1px solid #e9eced",
            padding: "20px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Form
            titleCenter
            title="Forgot Password"
            submitText="Reset"
            action={resetPassword}
            formStyles={{
              padding: 14,
            }}
            postAction={redirect}
            inputFields={[
              {
                type: "TextField",
                name: "email",
                validator: [
                  (value) => {
                    setButtonDisabled(Boolean(validEmail(value)));
                  },
                  notEmpty,
                  validEmail,
                ],
                label: "Email",
                moreProps: {
                  inputRef: email,
                },
              },
              {
                type: "TextField",
                name: "otp",
                label: "Email Verification Code",
                validator: [notEmpty, validOTP],
                moreProps: {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        classes={{ inputAdornedEnd: classes.endAdornment }}
                      >
                        <Button
                          onClick={resendOTP}
                          className={classes.button}
                          disabled={Boolean(resendDisabled) || buttonDisabled}
                        >
                          <Typography className={classes.buttonText} color = "primary">
                            {resendDisabled
                              ? `Wait ${resendDisabled}s`
                              : "Get Code"}
                          </Typography>
                        </Button>
                      </InputAdornment>
                    ),
                  },
                },
              },
              {
                type: "Password",
                name: "password",
                label: "New Password",
                validator: [notEmpty, validPassword],
              },
            ]}
          />
          {props.cOForm ? (
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  onClick={props.setSU}
                  style={{
                    cursor: "pointer",
                    textDecorationLine: "underline",
                    color: "#551A8B",
                  }}
                  align="left"
                >
                  Don't have an account?
                  <br />
                  Sign up here
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  onClick={props.setL}
                  style={{
                    cursor: "pointer",
                    textDecorationLine: "underline",
                    color: "#551A8B",
                  }}
                  align="right"
                >
                  Login
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={6}>
                <Link to="/sign-up">
                  <Typography align="left">
                    Don't have an account?
                    <br />
                    Sign up here
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link to="/login">
                  <Typography align="right">Login</Typography>
                </Link>
              </Grid>
            </Grid>
          )}
        </div>
      </Container>
    </div>
  );
}

export default ForgotPassword;
