import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { addFS, classListFS, delFS, withLoading } from '../../../../../../utils';
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px",
    width: "100vw",
    maxHeight: "80vh",
    overflowY: "auto"

  }
}))

const arrEq = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }
  else {
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false
      }
    }
    return true
  }
}


const applyChange = async (payload, optionDetails) => {
  const payloadSet = new Set(payload.classIds)
  const previous = optionDetails.fixedSchedule.map(schedule => schedule.classId)
  const previousSet = new Set(previous)
  const deletedClassId = []
  const addClassId = []

  previous.forEach(classId => {
    if (!payloadSet.has(classId)) {
      deletedClassId.push(classId)
    }
  })


  payload.classIds.forEach(classId => {
    if (!previousSet.has(classId)) {
      addClassId.push(classId)
    }
  })
  let [addResponse, delResponse] = [false, false]

  if (deletedClassId.length > 0) {
    delResponse = await withLoading(delFS, { ...payload, classIds: deletedClassId })
  }
  else {
    delResponse = true
  }

  if (addClassId.length > 0 && delResponse) {
    addResponse = await withLoading(addFS, { ...payload, classIds: addClassId })
  }
  else {
    addResponse = true
  }


  return addResponse && delResponse
}

const combineClasses = (generatedChoices, selectedChoices) => {
  const unsorted = [...generatedChoices, ...selectedChoices]
  const formatString = 'MMM, Do YYYY hh:mm a'
  unsorted.sort((first, second) => moment(first[0], formatString) < moment(second[0], formatString) ? -1 : 1)
  return unsorted
}

const AssignFixedSchedule = (props) => {

  const [classList, setClassList] = useState([])

  const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });

  const generateChoices = (allOptions) => {
    const temp = []
    allOptions.forEach(day => {
      day.classes.forEach(classData => {
        temp.push([`${day.dayofWeek} ${day.dateStr} ${classData.time}`, classData.classId])
      })
    })
    return (temp)
  }

  const setUp = async () => {
    const classListData = await withLoading(classListFS, {
      businessId: businessId,
      optionId: props.optionDetails.optionId
    })
    if (classListData) {
      setClassList(classListData.classDetails)
    }
  }

  useEffect(() => {
    if (props.open) {
      setClassList([])
      setUp()
    }
  }, [props.open])

  const classes = useStyles()
  const refresh = (success) => {
    if (success) {
      props.setOpen(false)
      props.refresh()
    }
  }
  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Container component="main" className={classes.formContainer}>
        <Form
          moreData={{ businessId: businessId, optionId: props.optionDetails.optionId }}
          title="Setup Classes"
          cancelEnabled
          cancelAction={() => { props.setOpen(false) }}
          submitText="Update"
          action={async (payload) => { return await applyChange(payload, props.optionDetails) }}
          postAction={refresh}
          inputFields={[
            {
              type: "MultiSelect",
              name: "classIds",
              label: "Select Classes",
              choices: combineClasses(generateChoices(classList), props.optionDetails.fixedSchedule.map((day) => [`${moment.utc(day.startTimeUTC)?.format('dddd')?.substring(0, 3)} ${day.startDate} ${day.startTime}`, day.classId])),
              initialvalue: props.optionDetails.fixedSchedule.map(obj => obj.classId),
              validator: [
                chosen => arrEq(chosen, props.optionDetails.fixedSchedule.map(obj => obj.classId)) ? "Update requires a change in fixed schedule" : null,
                chosen => chosen.length > props.optionDetails.optionSettings.sessionCount ? `You can assign at most ${props.optionDetails.optionSettings.sessionCount} classes` : null
              ]
            }

          ]}
        />
      </Container>
    </CModal>
  )
}

export default AssignFixedSchedule