import EventIcon from '@material-ui/icons/Event';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PersonIcon from '@material-ui/icons/Person';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import React from 'react';
import LogPresenceHandler from '../../../LogPresence/LogPresenceHandler';
import StaffCalendar from "./StaffOptions/Calendar/StaffCalendar";
import StaffClassHandler from "./StaffOptions/Classes/StaffClassHandler";
import InternalEventsHandler from './StaffOptions/InternalEvents/InternalEventsHandler';
import StaffPacksHandler from "./StaffOptions/Packs/StaffPacksHandler";
import StaffProfile from "./StaffOptions/Profile/StaffProfile";
import StaffScheduleHandler from "./StaffOptions/Scheduling/StaffScheduleHandler";
import StaffSessionsHandler from "./StaffOptions/Sessions/StaffSessionHandler";

const staffRoutes = [
    {
        type: "route",
        route: 'Calender',
        comp: <StaffCalendar />,
        icon: <EventIcon />
    },
    {
        type: "bucket",
        route: "Bookings",
        comp: [
            {
                type: "route",
                route: 'Packages and Passes',
                comp: <StaffPacksHandler />,
                icon: null
            },
            {
                type: "route",
                route: 'Sessions',
                comp: <StaffSessionsHandler />,
                icon: null
            },
            {
                type: "route",
                req: (businessDetails) => businessDetails.checkInAccess,
                route: 'Log Presence',
                comp: <LogPresenceHandler />,
                icon: null
            },
        ],
        icon: <LibraryBooksIcon />
    },
    {
        type: "bucket",
        route: "Scheduling",
        comp: [
            {
                type: "route",
                route: 'Group Classes',
                comp: <StaffClassHandler />,
                icon: null
            },

            {
                type: "route",
                route: 'My Schedule',
                comp: <StaffScheduleHandler />,
                icon: null
            },
            {
                type: "route",
                route: "Internal Events",
                comp: <InternalEventsHandler />,
                icon: null
            }
        ],
        icon: <WatchLaterIcon />
    },

    { type: "route", route: 'My Profile', comp: <StaffProfile />, icon: <PersonIcon /> }



];

export default staffRoutes;