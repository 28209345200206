import React, { useState, useEffect } from 'react'
import Form from "../../../../../Form/Form"
import CModal from "../../../../../Form/CModal";
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { rescheduleClass } from '../../../../../../utils';
import moment from "moment"
import { validTime } from '../../../../../../FormValidation/validTime';
import maxTextArea from '../../../../../../FormValidation/maxTextArea';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        width: "600px",
        maxWidth: "100vw",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))


const RescheduleClassForm = (props) => {

    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });


    const classes = useStyles()
    const refresh = (success) => {
        if (success) {
            props.setOpen(false)
            props.refresh()
        }
    }

    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    moreData={{ businessId: businessId, classId: props.groupClassDetails.classId}}
                    title="Reschedule Class"
                    cancelEnabled
                    cancelAction={() => { props.setOpen(false) }}
                    submitText="Save"
                    action={rescheduleClass}
                    postAction={refresh}
                    inputFields={[
                        {
                            type: "Date",
                            name: "dateRef",
                            label: "Date",
                            initialvalue: moment.utc(props.groupClassDetails.startTimeUTC).format('YYYY/MM/DD')

                        },
                        {
                            type: "Time",
                            name: "startTime",
                            label: "Start Time",
                            initialvalue: moment.utc(props.groupClassDetails.startTimeUTC).format('HH:mm'),
                            valiator:validTime
                        },
                        {
                            type:"TextArea",
                            name:"emailMessage",
                            label: "Reschedule email message (optional)",
                            moreProps:{
                                rowsMin:5,
                                maxRows:15
                            },
                            resizeEnabled: true,
                            validator: [noWhiteSpace, maxTextArea],
                            initialvalue: ''      
                        }

                    ]}
                />
            </Container>
        </CModal>
    )
}

export default RescheduleClassForm