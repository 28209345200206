import React from 'react';
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Widget from "./Widget";
import WidgetList from "./WidgetList";

function WidgetsHandler() {
    const history = useHistory();
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    return(
        <React.Fragment>
            <Switch>
            <Route path = {`/dashboard/${businessId}/Widgets`} exact render = {() => {return(<WidgetList />)}} />
            <Route path = {`/dashboard/${businessId}/Widgets/:widgetIdURL`} render = {() => {return(<Widget />);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default WidgetsHandler;