import React, { useRef, useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  arrow: {
    "&:before": {
      border: "1px solid black"
    },
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid black",
    color: "#4A4A4A",
    fontSize: 14
  },

}))

const LightTooltip = (props) => {
  const classes = useStyles()
  const [disabled, setDisabled] = useState(null)
  const children = useRef({})

  useEffect(() => {
    const updateDisabled = () => {
      setDisabled(children?.current?.offsetWidth === children?.current?.scrollWidth)
    }
    window.addEventListener("resize", updateDisabled);
    updateDisabled()
    return () => window.removeEventListener("resize", updateDisabled)

  }, []);

  useEffect(() => {
    setDisabled(children?.current?.offsetWidth === children?.current?.scrollWidth)
  }, [children])

  return (
    <Tooltip
      disableHoverListener={disabled}
      {...props}
      classes={
        {
          arrow: classes.arrow,
          tooltip: classes.tooltip
        }
      }
    >
      {React.cloneElement(props.children, { ref: children })}
    </Tooltip>
  )
}

export default LightTooltip