import GeneralDialog from "../GeneralDialog/GeneralDialog";
import { cancelInstallmentPayment, withLoading } from "../../utils";

const CancelPayment = (props) => {
    const { open, setOpen, businessId, orderId, refresh } = props;


    return (
        <GeneralDialog
            open={open}
            close={() => { setOpen(null) }}
            action={async () => {
                const res = await withLoading(cancelInstallmentPayment, {
                    businessId: businessId,
                    orderId:orderId,
                    planId:open
                })
                if(res){
                    setOpen(null)
                    refresh()
                }
            }}
            title="Cancel payment"
            text="Are you sure you want to cancel this installment payment"
        />
    )
}

export default CancelPayment