import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { customerCompletedSessions, encodeGetParams, withLoading } from "../../../../../utils";
import { Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ListTable from "../../../../TableComponents/ListTable";
import ListTableNavigation from "../../../../TableComponents/ListTableNavigation";
import ListCard from "../../../../MobileCards/ListCard";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography } from "@material-ui/core";
import ReviewForm from "../ReviewForm";
import EmptyListImage from "../../../../EmptyListImage/Empty";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "inset 0 -1px #e3e8ee"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  valueText:{
    fontSize:14,
  }
}));
const completedHeaders = [
  {
    name: "session #",
    columnKey: "sessionNumber",
    columnWidth: "100px",
  },
  {
    name: "Attendee",
    columnKey: "attendeeName",
    columnWidth: "25%",
    tooltip: true
  },
  {
    name: "service",
    columnKey: "serviceName",
    columnWidth: "25%",
    tooltip: true
  },
  {
    name: "business name",
    columnKey: "businessName",
    columnWidth: "25%",
    tooltip: true
  },
  {
    name: "start time",
    columnKey: "startDateTime",
    columnWidth: "150px",
    tooltip: true
  },
  {
    name: "",
    columnKey: "review",
    columnWidth: "100px",
    type: "Normal"
  },

];

const CompletedList = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const matches = useMediaQuery('(min-width: 750px)');
  const [_,updateState] = useState(null)
  const [completedList, setCompletedList] = useState([]);
  const [initialState, setInitialState] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sessionDetails, setSessionDetails]  = useState(null)
  const [currentLimit, setCurrentLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  const processCompletedData = (completedData) => {
    return completedData.map((completed) => {
      let {
        sessionId,
        sessionNumber,
        serviceName,
        siteName,
        businessName,
        startTime,
        startDate,
        isReviewed,
        attendeeFirstName,
        attendeeLastName


      } = completed;
      let attendeeName = `${attendeeFirstName} ${attendeeLastName}`
      let startDateTime = `${startDate} ${startTime}`
      let id = sessionId;
      let review = (isReviewed ? null :
        <Button
          style = {{padding:0}}
          color = "primary"
          onClick = {(event)=>{
            event.stopPropagation();
            setSessionDetails(completed);
          
          }}
        >
          <Typography className = {classes.valueText} noWrap style = {{textTransform: "none"}}>
            Review
          </Typography>
        </Button>)



      return {
        id,
        sessionNumber,
        serviceName,
        siteName,
        businessName,
        startDateTime,
        review,
        attendeeName
      };
    });
  };

  const getCompletedDetails = async () => {
    let completedPayload = await customerCompletedSessions({
      limit: currentLimit,
      page: currentPage,
    });
    if (completedPayload && completedPayload.completedSessions) {
      setCompletedList(processCompletedData(completedPayload.completedSessions.docs));
      setTotalPages(completedPayload.completedSessions.totalPages);
      setTotalDocs(completedPayload.completedSessions.totalDocs);
      setHasNext(completedPayload.completedSessions.hasNextPage);
      setHasPrevious(completedPayload.completedSessions.hasPrevPage);
      setInitialState(true);
    }
  };

  const getNextPage = () => {
    if (hasNext) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPrevPage = () => {
    if (hasPrevious) {
      setCurrentPage(currentPage - 1);
    }
  };

  const navigateToCompleted = (sessionId) => {
    history.push(`${props.basePath}/${sessionId}`);
  };

  const ViewChanger = () => {
    if (matches) {
      return (
        <ListTable
          identifier={"completed_sessions"}
          tableHeaders={completedHeaders}
          tableRows={completedList}
          rowClick={navigateToCompleted}
        />
      );
    } else {
      return (
        completedList.map((completed) => {
          return (
            <ListCard
              action={() => { history.push(`${props.basePath}/${completed.id}`); }}
              cardName={completed.sessionNumber}
              statusReplace={
                <React.Fragment>
                <Typography noWrap className = {classes.valueText}>
                {completed.startDateTime}
                </Typography>
                <Typography noWrap className = {classes.valueText}>
                {completed.serviceName}
                </Typography>
              </React.Fragment>
              } 
              other={completed.review} 
            />
          )
        })
      );
    };
  };

  useEffect(async () => {
    await withLoading(getCompletedDetails, {}, true)
  }, [currentPage, currentLimit, _]);

  if (initialState === null) {
    return (null);
  } else {
    return (
      <React.Fragment>
        <ReviewForm open={sessionDetails} refresh={() => {updateState((_) => !_);}} setOpen={setSessionDetails} sessionDetails={sessionDetails} />
        {completedList.length > 0 ?
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <ViewChanger />
            </div>
          </Grid> : <EmptyListImage name = "Completed Sessions" offset = "48px"/>}
        {completedList.length > 0 ?
          <Grid item xs={12}>
            <ListTableNavigation
              currentPage={currentPage}
              currentLimit={currentLimit}
              totalDocs={totalDocs}
              tableRows={completedList}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              getPrevPage={getPrevPage}
              getNextPage={getNextPage}
            />
          </Grid> : null}

      </React.Fragment>
    );
  }
};

export default CompletedList;
