import "@fontsource/roboto/400.css";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import MessageBanner from "../../components/MessageBanner/MessageBanner";
import Dashboards from "./Dashboards/Dashboards";
import DropdownMenu from "./Dropdown/Dropdown";
import Loader from "./Loader/Loader";
const useStyles = makeStyles((theme) => ({
    title: {
        flex: 1
    },
    mobileImage: {
        position: "absolute",
        maxWidth: '240px',
        left: 0,
        right: 0,
        margin: "0 auto",
        top: 18
    },
    desktopImage: {
        height: '30px'
    },
    toolbar: {
        height: "64px"
    },

}));


const useStylesDashboard = makeStyles((theme) => ({
    appBar: {
        backgroundColor: theme.palette.white.main,
        zIndex: theme.zIndex.drawer + 1,
        height: "64px",
    },
    headerWordsMobile: {
        color: "black",
        fontSize: 16,
    },
    headerWords: {
        color: "black",
        fontSize: 18,
    },
}))

const useStylesTransparent = makeStyles((theme) => ({
    appBar: {
        backgroundColor: 'transparent',
        zIndex: theme.zIndex.drawer + 1,
        height: "64px",
    },
    headerWordsMobile: {
        color: "white",
        fontSize: 16,
    },
    headerWords: {
        color: "white",
        fontSize: 18,
    },
    whiteImage: {
        filter: 'brightness(0) invert(1)'
    }
}))

const useStylesNotTransparent = makeStyles((theme) => ({
    appBar: {
        backgroundColor: '#303750',
        zIndex: theme.zIndex.drawer + 1,
        height: "64px",
    },
    headerWordsMobile: {
        color: "white",
    },
    headerWords: {
        color: "white",
        fontSize: 18,
    },
    whiteImage: {
        filter: 'brightness(0) invert(1)'
    }
}))

const transHeaderRoutes = [
    '/',
    '/for-business',
    '/for-business/',
    '/for-business/pricing',
    '/for-business/pricing/',
    '/for-business/contact-sales',
    '/for-business/contact-sales/',
    '/aboutUs',
    '/aboutUs/'
]

const calcHeaderStyles = (location, scrolled, styles) => {
    if (transHeaderRoutes.includes(location.pathname)) {
        if (scrolled) {
            return styles.notTransparentClasses
        }
        else {
            return styles.transparentClasses
        }
    }
    else {
        return styles.dashboardClasses
    }
}

const Header = (props) => {
    const appBar = useRef({ current: { clientHeight: 0 } });
    const history = useHistory();
    const classes = useStyles();
    const dashboardClasses = useStylesDashboard()
    const transparentClasses = useStylesTransparent()
    const notTransparentClasses = useStylesNotTransparent();
    const location = useLocation();
    const [scrolled, setScrolled] = useState(false)
    const headerClasses = calcHeaderStyles(location, scrolled, { dashboardClasses, transparentClasses, notTransparentClasses })

    const loggedIn = useSelector((state) => state.token !== null);
    const bigEnough = useMediaQuery("(min-width:750px)");
    const dashboards = useSelector((state) => state.dashboards);


    const changeNavbarColor = () => {
        if (window.scrollY > 5) {
            setScrolled(true)
        }
        else if (window.scrollY <= 5) {
            setScrolled(false);
        }
    };

    const hideForBusiness = () => {
        const locArr = location.pathname.split("/");
        if (locArr.length >= 1 && locArr[1] === "dashboard") {
            return true;
        }
        if (locArr.length >= 1 && locArr[1] === "for-business") {
            return true;
        }
        return false;
    };

    useEffect(() => {
        window.addEventListener("scroll", changeNavbarColor)
        return () => { window.removeEventListener("scroll", changeNavbarColor) }
    }, [])

    return (
        <div>
            <AppBar position="fixed" elevation={0} ref={appBar} className={headerClasses.appBar} >
                <Loader />

                <Toolbar
                    variant="regular"
                    className={classes.toolbar}
                >
                    <div className={classes.title}>
                        {location.pathname.split('/').length >= 2 && location.pathname.split('/')[1] === 'for-business' ?
                            <Link to="/for-business">
                                <img
                                    src="https://caritra-app-images.s3.us-west-1.amazonaws.com/app-logo/caritra-business-blue.png"
                                    alt='Caritra Business'
                                    style={{ cursor: "pointer" }}
                                    className={`${(!bigEnough ? classes.mobileImage : classes.desktopImage)} ${headerClasses.whiteImage}`}
                                />
                            </Link>
                            :
                            <Link to="/">
                                <img
                                    src="https://caritra-app-images.s3.us-west-1.amazonaws.com/app-logo/caritra-logo.png"
                                    alt='Caritra'
                                    style={{ cursor: "pointer" }}
                                    className={`${(!bigEnough ? classes.mobileImage : classes.desktopImage)} ${headerClasses.whiteImage}`}
                                />
                            </Link>
                        }
                    </div>

                    {location.pathname.split('/').length >= 2 && location.pathname.split('/')[1] === 'for-business' && bigEnough ?
                        <React.Fragment>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Button style={{ textTransform: 'none' }}>
                                    <Typography className={bigEnough ? headerClasses.headerWords : headerClasses.headerWordsMobile}>
                                        Market Place
                                    </Typography>
                                </Button>
                            </Link>
                            <Link to="/for-business/pricing" style={{ textDecoration: 'none' }}>
                                <Button style={{ textTransform: 'none' }}>
                                    <Typography className={bigEnough ? headerClasses.headerWords : headerClasses.headerWordsMobile}>
                                        Pricing
                                    </Typography>
                                </Button>
                            </Link>
                        </React.Fragment> : null}
                    {bigEnough && !hideForBusiness() ?
                        <Link to="/for-business" style={{ textDecoration: 'none' }}>
                            <Button style={{ textTransform: 'none' }} >
                                <Typography className={headerClasses.headerWords}>
                                    For Business
                                </Typography>
                            </Button>
                        </Link>
                        : null}
                    {(!loggedIn && bigEnough ?
                        (<React.Fragment>
                            <Link to="/login" style={{ textDecoration: 'none' }}>
                                <Button style={{ textTransform: 'none' }} >
                                    <Typography className={bigEnough ? headerClasses.headerWords : headerClasses.headerWordsMobile}>
                                        Login
                                    </Typography>
                                </Button>
                            </Link>
                            <Link to="/sign-up" style={{ textDecoration: 'none' }}>
                                <Button style={{ textTransform: 'none' }}>
                                    <Typography className={headerClasses.headerWords}>
                                        Sign Up
                                    </Typography>
                                </Button>
                            </Link>
                        </React.Fragment>) : (
                            <React.Fragment>
                                {dashboards && dashboards.length !== 0 && bigEnough ?
                                    (dashboards.length === 1
                                        ?
                                        <Button style={{ textTransform: 'none' }} onClick={() => { history.push(`/dashboard/${dashboards[0].businessId}`) }}>
                                            <Typography noWrap style={{ maxWidth: 150 }} className={headerClasses.headerWords}>
                                                {dashboards[0].businessName}
                                            </Typography>
                                        </Button>
                                        :
                                        <Dashboards headerClasses={headerClasses} />
                                    )
                                    : null}
                                <DropdownMenu bigEnough={bigEnough} headerClasses={headerClasses} />
                            </React.Fragment>
                        )
                    )}
                </Toolbar>
                <MessageBanner />
            </AppBar>
            {headerClasses === dashboardClasses ?
                <div style={{ height: "64px" }}></div>
                : null}
        </div>
    );
};

export default Header;
