import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import CatalogDetails from "./CatalogDetail";
import CatalogList from "./CatalogList";

const CatalogHandler = () => {
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  return (
    <React.Fragment>
      <Switch>
        <Route
          path={`/dashboard/${businessId}/Catalog`}
          exact
          render={() => {
            return <CatalogList />;
          }}
        />
        <Route
          path={`/dashboard/${businessId}/Catalog/:catalogIdURL`}
          exact
          render={() => {
            return <CatalogDetails />;
          }}
        />
        <Route
          path="*"
          render={() => {
            return <Redirect to="/404" />;
          }}
        />
      </Switch>
    </React.Fragment>
  );
};

export default CatalogHandler;
