import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getStaffList, withLoading, staffScheduleList } from "../../../../../../utils";
import Grid from "@material-ui/core/Grid";
import { useHistory, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ListTable from "./../../../../../TableComponents/ListTable";

import { useMediaQuery } from "@material-ui/core";
import ListCard from "../../../../../MobileCards/ListCard";
import CalenderForm from "../../../AdminDashboard/AdminOptions/Work Availabilities/CalendarForm";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import { CircularProgress, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    topBlock: {
        paddingBottom: "15px",
    },
    recordRow: {
        paddingLeft: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRoght: "5px",
        width: "550px"
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    rightElem: {
        float: "right",
    },
    rightElemPad: {
        float: "right",
        marginRight: "10px",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeadingOne: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    topHeadingTwo: {
        fontSize: "28px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    topHeadingThree: {
        backgroundColor: "#eb5757",
        opacity: "50%",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    topHeadingFour: {
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    buttonBasic: {
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    contentColumnFirst: {
        paddingRight: "20px",
        boxShadow: "inset -1px 0 #e3e8ee",
        display: "inline-block",
        height: "100%"
    },
    contentColumnMiddle: {
        boxShadow: "inset -1px 0 #e3e8ee",
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "inline-block",
        maxWidth: "160px",
        height: "100%"
    },
    contentColumnLast: {
        paddingLeft: "20px",
        display: "inline-block",
    },
    labelText: {
        fontSize: "14px",
        color: "#697386",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText2: {
        fontSize: "14px",
        color: "#3c4257",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextNeg: {
        fontSize: "14px",
        color: "#009211",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBG: {
        fontSize: "16px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBold: {
        fontSize: "14px",
        color: "#1a1f36",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextPad: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingLeft: "10px",
    },
    valueTextLink: {
        fontSize: "14px",
        color: "#3d5af1",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textDecoration: "none"
    },
    errorText: {
        fontSize: "14px",
        color: "#ff0000",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    blockLabel: {
        paddingTop: "10px",
        paddingBottom: "5px",
    },
    blockText: {
        paddingLeft: "10px",
    },
    subHeading: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    fieldRows: {
        paddingTop: "8px",
        paddingLeft: "10px",
    },
    fieldColumnOne: {
        width: "100px",
        display: "inline-block",

    },
    fieldColumnTwo: {
        display: "inline-block",
        maxWidth: "250px",

    },
    sdCustomCellOne: {
        border: "none",
        width: "5%",
        maxWidth: "200px",
        padding: "0px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingThree: {
        fontSize: "20px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    sdCustomCellTwo: {
        border: "none",
        width: "35%",
        maxWidth: "300px",
        padding: "10px",
    },
    sdCustomCellThree: {
        border: "none",
        width: "60%",
        maxWidth: "200px",
        padding: "10px",
    },
    sdCustomCellFour: {
        border: "none",
        padding: "10px",
    },
    headerPadding: {
        padding: "10px",
    },
    bottomSpacer: {
        height: "50px",
    },
    topMarginBlock: {
        marginTop: "10px",
    },
    loaderWrapper: {
        width: "100%",
        height: "calc(100vh - 64px)"
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    },
    media: {
        height: 200,
        width: 250,
        objectFit: "cover"
    }
}));

const StaffScheduleList = (props) => {
    const [add, setAdd] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const businessId = props.businessId;
    const role = useSelector((state) => {
        return state.buisnessDetails.role;
    });
    const schedulingAccess = useSelector((state) => {
        return state.buisnessDetails.schedulingAccess;
    });
    const sitesHeaders = [
        {
            name: "Name",
            columnKey: "name",
            columnWidth: "40%",
        },
        {
            name: "Site",
            columnKey: "siteName",
            columnWidth: "40%",
        },
        {
            name: "Time Zone",
            columnKey: "timeZone",
            columnWidth: "200px",
        },
    ];

    const [staffData, setStaffData] = useState(null);
    const [calenderList, setCalenderList] = useState(null);
    const[siteIds, setSiteIds] = useState([]);
    const [initialState, setInitialState] = useState(false);
    const bigEnough = useMediaQuery("(min-width:750px)")

    const processCalendarData = (calendarsData) => {
        return calendarsData.map((calendar) => {
            let {
                calendarId,
                name,
                siteInfo,

            } = calendar;
            let id = calendarId;
            let siteName = siteInfo.name
            let siteId = siteInfo.siteId
            let timeZone = siteInfo.timeZone
            return {
                id,
                name,
                timeZone,
                siteName,
                siteId

            };
        });
    };

    const setUp = async () => {
        await withLoading(async()=>{
            let calendarPayload = await staffScheduleList({
                businessId: businessId
            })
            if (calendarPayload.calenderDetails) {
                setCalenderList(processCalendarData(calendarPayload.calenderDetails));
            }
            setInitialState(true);
        },{},true)
    };

    const navigateToSite = (calendarId) => {
        history.push(`/dashboard/${businessId}/My-Schedule/${calendarId}`);
    };

    useEffect(() => {
        setUp()
    }, []);

    const ViewChanger = () => {
        if (bigEnough) {
            return (
                <React.Fragment>
                    <Grid item xs={12}>
                        <div className={classes.contentRow}>
                            <ListTable
                                identifier={"admin_orders"}
                                tableHeaders={sitesHeaders}
                                tableRows={calenderList}
                                rowClick={navigateToSite}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.bottomSpacer}></div>
                    </Grid>
                </React.Fragment>
            )
        }
        else {
            return (
                <Grid item xs={12} style={{ paddingTop: "10px" }}>
                    {calenderList.map((calendar) => {
                        return (
                            <ListCard 
                            action={() => { history.push(`/dashboard/${businessId}/My-Schedule/${calendar.id}`); }} 
                            cardName={calendar.name} 
                            statusReplace={calendar.siteName} 
                            other={calendar.timeZone} />
                        )
                    })}
                </Grid>
            )
        }
    }
    if (initialState) {
        return (
            <React.Fragment>
                <CalenderForm open = {add} setOpen = {setAdd} edit={false} role={role} staffData = {staffData} calenderList = {calenderList}/>
                <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                    <Grid item xs={12} className={classes.topBlock}>
                        <span className={classes.topHeadingOne}>My Schedule</span>
                        
                        {schedulingAccess ? <span className={classes.rightElem} >
                            <Button
                                className={classes.buttonBasic}
                                color="primary"
                                variant="contained"
                                onClick = {()=>{setAdd(true)}}
                            >
                                Add
                            </Button>
                        </span> : null}
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.contentRow}>
                            {(calenderList ? <ViewChanger /> : <EmptyListImage name = "Schedule"/>)}
                        </div>
                    </Grid>

                </Grid>
            </React.Fragment>
        );
    }
    else {
        return null
    }


};

export default StaffScheduleList;