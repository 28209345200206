import {
  Button,
  ButtonGroup,
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  deleteFeePricing,
  deleteOption,
  deleteTaxPricing,
  detachCatalogsFromOption,
  getGenericDetails,
  getPricingOptionsDetails,
  installmentOption,
  optionStatus,
  pricingOfflineBooking,
  removeAddon,
  removeDiscountFromOption,
  removePricingAgreement,
  removeStaffFromOption,
  safePush,
  withLoading,
} from "../../../../../../utils";
import CurrencyPreProcessor from "../../../../../CurrencyFormatter/CurrencyFormatter";
import LightTooltip from "../../../../../Form/LightTooltip";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import {
  CAddIcon,
  CCloseIcon,
  CEllipsisIcon,
} from "../../../../../Icons/Icons";
import ListTable from "../../../../../TableComponents/ListTable";
import StatusTag from "./../../../../../TagComponents/StatusTag";
import AddAddOnForm from "./AddAddonForm";
import AddAgreementForm from "./AddAgreementForm";
import AddDiscountsForm from "./AddDiscountsForm";
import AddPricingStaffForm from "./AddPricingStaffForm";
import AddToCatalogForm from "./AddToCatalogForm";
import AssignFixedSchedule from "./AssignFixedSchedule";
import Capacity from "./Capacity";
import EditPricingForm from "./EditPricingForm";
import EmailCustomerForm from "./EmailCustomerForm";
import FeeForm from "./FeeForm";
import InstallmentPayment from "./InstallmentPayment";
import ReqSignForm from "./ReqSignForm";
import TaxForm from "./TaxForm";
import UpdatePriceGroupForm from "./UpdatePriceGroupForm";
const useStyles = makeStyles((theme) => ({
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
    width: "100vw",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
    maxWidth: "160px",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    overflow: "hidden",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
  },
  fieldColumnOne: {
    width: "120px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
    maxWidth: "160px",
  },
  sdCustomCellOne: {
    border: "none",
    width: "5%",
    maxWidth: "200px",
    padding: "0px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingThree: {
    fontSize: "20px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "35%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "60%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
    paddingLeft: "0px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  media: {
    height: 200,
    width: 250,
    objectFit: "cover",
  },
}));

const catalogHeaders = [
  {
    name: "",
    columnKey: "action",
    columnWidth: "100px",
    type: "Normal",
  },
  {
    name: "status",
    columnKey: "status",
    columnWidth: "100px",
    type: "Normal",
  },
  {
    name: "name",
    columnKey: "name",
    columnWidth: "30%",
    tooltip: true,
  },
  {
    name: "description",
    columnKey: "description",
    columnWidth: "70%",
    tooltip: true,
  },
];

const catalogHeadersM = [
  {
    name: "",
    columnKey: "action",
    columnWidth: "100px",
    type: "Normal",
  },
  {
    name: "name",
    columnKey: "name",
    columnWidth: "100%",
    tooltip: true,
  },
];

const discountHeaders = [
  {
    name: "",
    columnKey: "check",
    columnWidth: "50px",
    type: "Normal",
  },
  {
    name: "name",
    columnKey: "name",
    columnWidth: "50%",
    type: "Normal",
  },
  {
    name: "percent",
    columnKey: "percent",
    columnWidth: "50%",
    tooltip: true,
  },
];

const staffHeaders = [
  {
    name: "",
    columnKey: "check",
    columnWidth: "50px",
    type: "Normal",
  },
  {
    name: "name",
    columnKey: "name",
    columnWidth: "50%",
    type: "Normal",
  },
  {
    name: "member id",
    columnKey: "memberId",
    columnWidth: "50%",
    tooltip: true,
  },
];
const feeHeaders = [
  {
    name: "name",
    columnKey: "name",
    columnWidth: "20%",
    tooltip: true,
  },
  {
    name: "amount",
    columnKey: "amount",
    columnWidth: "20%",
  },
  {
    name: "",
    columnKey: "actions",
    columnWidth: "100px",
    type: "Normal",
  },
];

const taxHeaders = [
  {
    name: "name",
    columnKey: "name",
    columnWidth: "20%",
    tooltip: true,
  },
  {
    name: "percentage",
    columnKey: "percentage",
    columnWidth: "20%",
  },
  {
    name: "",
    columnKey: "actions",
    columnWidth: "100px",
    type: "Normal",
  },
];

const agreementHeaders = [
  {
    name: "",
    columnKey: "check",
    columnWidth: "50px",
    type: "Normal",
  },
  {
    name: "type",
    columnKey: "type",
    columnWidth: "100px",
  },
  {
    name: "name",
    columnKey: "name",
    columnWidth: "100%",
    type: "Normal",
  },
];

const scheduleHeader = [
  {
    name: "Class Number",
    columnKey: "classNumber",
    columnWidth: "100px",
    type: "Normal",
  },
  {
    name: "Start Time",
    columnKey: "startTime",
    columnWidth: "200px",
    type: "Typography",
    tooltip: true,
  },
  {
    name: "End Time",
    columnKey: "endTime",
    columnWidth: "200px",
    type: "Typography",
    tooltip: true,
  },
  {
    name: "",
    columnKey: "",
    columnWidth: "100%",
    type: "Normal",
  },
];

const scheduleHeaderM = [
  {
    name: "Class Number",
    columnKey: "classNumber",
    columnWidth: "50%",
    type: "Normal",
  },
  {
    name: "Start Time",
    columnKey: "startTime",
    columnWidth: "50%",
    type: "Typography",
    tooltip: true,
  },
];

const addOnHeaders = [
  {
    name: "",
    columnKey: "check",
    columnWidth: "50px",
    type: "Normal",
  },
  {
    name: "display name",
    columnKey: "displayName",
    columnWidth: "30%",
    type: "Normal",
  },
  {
    name: "price",
    columnKey: "price",
    columnWidth: "150px",
    type: "Typography",
    tooltip: true,
  },
  {
    name: "",
    columnKey: "",
    columnWidth: "70%",
    type: "Normal",
  },
];

const addOnHeadersM = [
  {
    name: "",
    columnKey: "check",
    columnWidth: "50px",
    type: "Normal",
  },
  {
    name: "display name",
    columnKey: "displayName",
    columnWidth: "100%",
    type: "Normal",
  },
];

const PricingOption = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [_, updateState] = useState(0);
  const [optionDetails, setOptionDetails] = useState(null);
  const [pricingGroups, setPricingGroups] = useState([]);
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const [offlineEnabled, setOfflineEnabled] = useState(false);
  const [editCap, setEditCap] = useState(false);
  const [editOffline, setEditOffline] = useState(false);
  const [aADOOpen, setAADOOpen] = useState(false);
  const [iPOpen, setIPOpen] = useState(false);
  const [eOpen, setEOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [signOpen, setSignOpen] = useState(false);
  const [aFOpen, setAFOpen] = useState(false);
  const [eFOpen, setEFOpen] = useState(false);
  const [dFOpen, setDFOpen] = useState(false);
  const [aDOpen, setADOpen] = useState(false);
  const [aAOpen, setAAOpen] = useState(false);
  const [aTOpen, setATOpen] = useState(false);
  const [dTOpen, setDTOpen] = useState(false);
  const [eTOpen, setETOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [DiscOpen, setDiscOpen] = useState(false);
  const [StaffOpen, setStaffOpen] = useState(false);
  const [AChecked, setAChecked] = useState(null);
  const [DChecked, setDChecked] = useState(null);
  const [SChecked, setSChecked] = useState(null);
  const [AOChecked, setAOChecked] = useState(null);
  const [DelD, setDelD] = useState(false);
  const [DelS, setDelS] = useState(false);
  const [DelA, setDelA] = useState(false);
  const [DelAO, setDelAO] = useState(false);
  const [AFS, setAFS] = useState(false);
  const [DelIP, setDelIP] = useState(false);
  const [checkedC, setCheckedC] = useState([]);
  const [assignC, setAssignC] = useState(false);
  const [unassignC, setUnassignC] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const { optionIdURL } = useParams();
  const [updatePriceGroup, setUpdatePriceGroup] = useState(false);
  const matches = useMediaQuery("(min-width: 1600px)");
  const bigEnough = useMediaQuery("(min-width: 750px)");

  const refresh = () => {
    updateState((_) => !_);
  };

  const genEmptyState = (list) => {
    const temp = [];
    for (let i = 0; i < list.length; i++) {
      temp.push(false);
    }
    return temp;
  };

  const genDelD = () => {
    let temp = [];
    DChecked.forEach((delD, index) => {
      if (delD) {
        temp.push(optionDetails.discounts[index].discountId);
      }
    });
    return temp;
  };
  const genDelS = () => {
    let temp = [];
    SChecked.forEach((delS, index) => {
      if (delS) {
        temp.push(optionDetails.staffs[index].staffId);
      }
    });
    return temp;
  };
  const genDelA = () => {
    let temp = [];
    AChecked.forEach((delA, index) => {
      if (delA) {
        temp.push(optionDetails.agreements[index].agreementId);
      }
    });
    return temp;
  };

  const genDelAO = () => {
    let temp = [];
    AOChecked.forEach((delAO, index) => {
      if (delAO) {
        temp.push(optionDetails.addOns[index].addOnId);
      }
    });
    return temp;
  };

  useEffect(async () => {
    setUp();
  }, [_]);

  const setUp = async () => {
    let data = await withLoading(
      getPricingOptionsDetails,
      {
        businessId: businessId,
        optionId: optionIdURL,
      },
      true
    );

    const buisnessDetails = await withLoading(getGenericDetails, {
      businessId: businessId,
    });
    if (buisnessDetails && !buisnessDetails.error) {
      setOfflineEnabled(
        buisnessDetails?.genericDetails?.businessDetails?.offlineBooking ??
          false
      );
      setPricingGroups(
        buisnessDetails?.genericDetails?.priceGroupDetails ?? []
      );
      setCatalogs(buisnessDetails?.genericDetails?.catalogDetails ?? []);
    }

    if (data) {
      setDChecked(genEmptyState(data.optionDetails.discounts));
      setAChecked(genEmptyState(data.optionDetails.agreements));
      setAOChecked(genEmptyState(data.optionDetails.addOns));
      if (data.optionDetails.offeringType === "Private") {
        setSChecked(genEmptyState(data.optionDetails.staffs));
      }
      setOptionDetails(data.optionDetails);
    }
  };

  if (optionDetails && props.currencyInfo) {
    return (
      <Grid
        container
        className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
      >
        <React.Fragment>
          <GeneralDialog
            open={unassignC}
            close={setUnassignC}
            title="Remove Pricing Options From Catalog"
            text="Are you sure you want to remove this pricing option from the selected catalog(s)"
            action={async () => {
              const response = await withLoading(detachCatalogsFromOption, {
                businessId: businessId,
                catalogIds: checkedC,
                optionId: optionDetails.optionId,
              });
              if (response?.error === false) {
                setCheckedC([]);
                setUnassignC(false);
                setUp();
              }
            }}
          />

          <AddToCatalogForm
            businessId={businessId}
            open={assignC}
            setOpen={setAssignC}
            optionDetails={optionDetails}
            refetch={setUp}
            classes={classes}
            catalogs={catalogs}
          />

          <UpdatePriceGroupForm
            open={updatePriceGroup}
            setOpen={setUpdatePriceGroup}
            businessId={businessId}
            optionDetails={optionDetails}
            refetch={setUp}
            pricingGroups={pricingGroups}
            classes={classes}
          />

          {optionDetails.offeringType === "Private" ? (
            <AddPricingStaffForm
              open={StaffOpen}
              setOpen={setStaffOpen}
              optionDetails={optionDetails}
              refresh={refresh}
            />
          ) : null}

          {optionDetails.type === "Season Pass" &&
          optionDetails.optionSettings.scheduleType === "Fixed" ? (
            <Capacity
              businessId={businessId}
              optionDetails={optionDetails}
              refresh={refresh}
              open={editCap}
              setOpen={setEditCap}
              classes={classes}
            />
          ) : null}
          {optionDetails.type === "Season Pass" &&
          optionDetails.optionSettings.scheduleType === "Fixed" ? (
            <AssignFixedSchedule
              open={AFS}
              setOpen={setAFS}
              optionDetails={optionDetails}
              refresh={refresh}
            />
          ) : null}

          <InstallmentPayment
            open={iPOpen}
            setOpen={setIPOpen}
            businessId={businessId}
            optionId={optionDetails.optionId}
            refresh={refresh}
          />

          <AddAgreementForm
            open={aAOpen}
            setOpen={setAAOpen}
            optionDetails={optionDetails}
            refresh={refresh}
          />
          <AddAddOnForm
            open={aADOOpen}
            setOpen={setAADOOpen}
            optionDetails={optionDetails}
            refresh={refresh}
          />
          <TaxForm
            open={aTOpen}
            setOpen={setATOpen}
            optionDetails={optionDetails}
            refresh={refresh}
          />
          <TaxForm
            edit
            open={eTOpen}
            setOpen={setETOpen}
            optionDetails={optionDetails}
            refresh={refresh}
          />
          <FeeForm
            open={aFOpen}
            setOpen={setAFOpen}
            optionDetails={optionDetails}
            refresh={refresh}
            currencyInfo={props.currencyInfo}
          />
          <FeeForm
            edit={true}
            open={eFOpen}
            setOpen={setEFOpen}
            optionDetails={optionDetails}
            refresh={refresh}
            currencyInfo={props.currencyInfo}
          />
          <AddDiscountsForm
            open={DiscOpen}
            setOpen={setDiscOpen}
            optionDetails={optionDetails}
            refresh={refresh}
          />
          <EditPricingForm
            open={eOpen}
            setOpen={setEOpen}
            refresh={refresh}
            optionDetails={optionDetails}
            currencyInfo={props.currencyInfo}
          />

          <EmailCustomerForm
            open={emailOpen}
            setOpen={setEmailOpen}
            refresh={refresh}
            optionDetails={optionDetails}
          />

          <ReqSignForm
            open={signOpen}
            setOpen={setSignOpen}
            refresh={refresh}
            optionDetails={optionDetails}
          />

          <GeneralDialog
            open={DelIP}
            close={() => {
              setDelIP(false);
            }}
            title="Disable Installment Payment"
            text="Are you sure you want to disable installment payment?"
            action={async () => {
              const response = await withLoading(installmentOption, {
                businessId: businessId,
                optionId: optionDetails.optionId,
                installmentEnabled: false,
              });
              if (response) {
                setDelIP(false);
                refresh();
              }
            }}
          />

          <GeneralDialog
            open={aDOpen}
            close={() => {
              setADOpen(false);
            }}
            title={
              optionDetails.isActive
                ? "Deactivate Pricing Option"
                : "Activate Pricing Option"
            }
            text={
              optionDetails.isActive
                ? "Are you sure you want to deactivate this pricing option?"
                : "Are you sure you want to activate this pricing option?"
            }
            action={async () => {
              let data = await withLoading(optionStatus, {
                businessId: businessId,
                optionId: optionDetails.optionId,
                isActive: !optionDetails.isActive,
              });
              if (data) {
                setADOpen(false);
                refresh();
              }
            }}
          />

          <GeneralDialog
            open={deleteOpen}
            close={() => {
              setDeleteOpen(false);
            }}
            title="Delete Pricing option"
            text="Are you sure you want to delete this pricing option permanently?"
            action={async () => {
              let success = await withLoading(deleteOption, {
                businessId: businessId,
                optionId: optionDetails.optionId,
              });
              if (success && success.error === false) {
                history.replace(`/dashboard/${businessId}/Pricings`);
              }
            }}
          />
          <GeneralDialog
            open={dFOpen}
            close={() => {
              setDFOpen(false);
            }}
            title="Remove Fee"
            text="Are you sure you want to remove this fee(s)?"
            action={async () => {
              let data = await withLoading(deleteFeePricing, {
                businessId: businessId,
                optionId: optionDetails.optionId,
                additionalFeeId: dFOpen,
              });
              if (data) {
                setDFOpen(false);
                refresh();
              }
            }}
          />
          <GeneralDialog
            open={dTOpen}
            close={() => {
              setDTOpen(false);
            }}
            title="Remove Tax"
            text="Are you sure you want to remove this tax(s)?"
            action={async () => {
              let data = await withLoading(deleteTaxPricing, {
                businessId: businessId,
                optionId: optionDetails.optionId,
                taxLineId: dTOpen,
              });
              if (data) {
                setDTOpen(false);
                refresh();
              }
            }}
          />
          <GeneralDialog
            open={DelA}
            close={() => {
              setDelA(false);
            }}
            title="Remove Agreements"
            text="Are you sure you want to remove this agreement(s)?"
            action={async () => {
              let data = await withLoading(removePricingAgreement, {
                businessId: businessId,
                optionId: optionDetails.optionId,
                agreementIds: genDelA(),
              });
              if (data) {
                setDelA(false);
                refresh();
              }
            }}
          />

          <GeneralDialog
            open={DelAO}
            close={() => {
              setDelAO(false);
            }}
            title="Remove Add Ons"
            text="Are you sure you want to remove this add on(s)?"
            action={async () => {
              let data = await withLoading(removeAddon, {
                businessId: businessId,
                optionId: optionDetails.optionId,
                addOnIds: genDelAO(),
              });
              if (data) {
                setDelAO(false);
                refresh();
              }
            }}
          />

          <GeneralDialog
            open={DelD}
            close={() => {
              setDelD(false);
            }}
            title={"Delete Discounts"}
            text={"Are you sure you want to delete this discount(s)"}
            action={async () => {
              let data = await withLoading(removeDiscountFromOption, {
                businessId: businessId,
                optionId: optionDetails.optionId,
                discountIds: genDelD(),
              });
              if (data) {
                setDelD(false);
                refresh();
              }
            }}
          />
          <GeneralDialog
            open={editOffline}
            close={setEditOffline}
            title={
              optionDetails.bookingType === "Offline"
                ? "Make Payment Online"
                : "Make Payment Offline"
            }
            text={
              optionDetails.bookingType === "Offline"
                ? "By making the payment online, payment processing will be handled by Caritra (customers will be charged in Caritra's checkout)"
                : "By making the payment offline, payment processing will be handled by the buisness (customers will not be charged in Caritra's checkout)"
            }
            action={async () => {
              let data = await withLoading(pricingOfflineBooking, {
                businessId: businessId,
                optionId: optionDetails.optionId,
                bookingType:
                  optionDetails.bookingType === "Offline"
                    ? "Online"
                    : "Offline",
              });
              if (data) {
                setEditOffline(false);
                refresh();
              }
            }}
          />
          <GeneralDialog
            open={DelS}
            close={() => {
              setDelS(false);
            }}
            title={"Delete Staffs"}
            text={"Are you sure you want to delete this staff(s)"}
            action={async () => {
              let data = await withLoading(removeStaffFromOption, {
                businessId: businessId,
                optionId: optionDetails.optionId,
                staffIds: genDelS(),
              });
              if (data) {
                setDelS(false);
                refresh();
              }
            }}
          />
        </React.Fragment>
        <Grid item xs={12}>
          <div className={classes.topBlock}>
            <span className={classes.topHeading}>PRICE</span>
            <span className={classes.rightElem}>
              <Button
                onClick={(event) => {
                  setAnchorEl(event.target);
                }}
                className={classes.buttonBasic}
              >
                <CEllipsisIcon />
              </Button>
              <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(false);
                }}
              >
                <MenuItem
                  onClick={() => {
                    setEOpen(true);
                    setAnchorEl(false);
                  }}
                >
                  Edit Option
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setEmailOpen(true);
                    setAnchorEl(false);
                  }}
                >
                  Email Customers
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSignOpen(true);
                    setAnchorEl(false);
                  }}
                >
                  Request Sign
                </MenuItem>
                {optionDetails.type === "Season Pass" &&
                optionDetails.optionSettings.scheduleType === "Fixed" &&
                optionDetails.fixedSchedule.length !==
                  optionDetails.optionSettings.sessionCount ? null : (
                  <MenuItem
                    onClick={() => {
                      setADOpen(true);
                      setAnchorEl(false);
                    }}
                  >
                    {optionDetails.isActive ? "Deactivate" : "Activate"}
                  </MenuItem>
                )}
                {!optionDetails.isActive ? (
                  <MenuItem
                    onClick={() => {
                      setDeleteOpen(true);
                      setAnchorEl(false);
                    }}
                  >
                    <Typography>Delete Option</Typography>
                  </MenuItem>
                ) : null}
                {optionDetails.type === "Season Pass" &&
                optionDetails.optionSettings.scheduleType === "Fixed" &&
                optionDetails.fixedSchedule.length ===
                  optionDetails.optionSettings.sessionCount ? (
                  <MenuItem
                    onClick={() => {
                      setEditCap(true);
                      setAnchorEl(false);
                    }}
                  >
                    Edit Capacity
                  </MenuItem>
                ) : null}
                <MenuItem
                  onClick={() => {
                    setUpdatePriceGroup(true);
                    setAnchorEl(false);
                  }}
                >
                  Update Price Group
                </MenuItem>
              </Menu>
            </span>
          </div>
        </Grid>
        <Grid item style={bigEnough ? { maxWidth: "50%" } : { width: "100%" }}>
          <div className={classes.headerPadding}>
            <LightTooltip title={optionDetails.name} arrow>
              <Typography
                className={
                  bigEnough
                    ? classes.topHeadingOne
                    : classes.mobileTopHeadingOne
                }
                noWrap
              >
                {optionDetails.name}
              </Typography>
            </LightTooltip>
          </div>
        </Grid>
        {bigEnough ? (
          <Grid item>
            <div
              className={classes.headerPadding}
              style={{ paddingLeft: "20px" }}
            >
              <span className={classes.topHeadingFour}>
                <StatusTag
                  wrapperName={
                    optionDetails.isActive ? "greenWrapper" : "redWrapper"
                  }
                  value={optionDetails.isActive ? "Active" : "Inactive"}
                />
              </span>
            </div>
          </Grid>
        ) : (
          <Grid item>
            <div style={{ paddingLeft: "10px" }}>
              <StatusTag
                wrapperName={
                  optionDetails.isActive ? "greenWrapper" : "redWrapper"
                }
                value={optionDetails.isActive ? "Active" : "Inactive"}
              />
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <div
              className={
                matches ? classes.contentColumnFirst : classes.fieldRows
              }
            >
              <div
                className={
                  matches ? classes.blockLabel : classes.fieldColumnOne
                }
              >
                <Typography className={classes.labelText}>Type</Typography>
              </div>
              <div
                className={matches ? classes.blockText : classes.fieldColumnTwo}
                style={matches ? null : { paddingLeft: "15px" }}
              >
                <Typography className={classes.valueText} noWrap>
                  {optionDetails.type}
                </Typography>
              </div>
            </div>
            <div
              className={
                matches ? classes.contentColumnMiddle : classes.fieldRows
              }
            >
              <div
                className={
                  matches ? classes.blockLabel : classes.fieldColumnOne
                }
              >
                <Typography className={classes.labelText}>Service</Typography>
              </div>
              <div
                className={matches ? classes.blockText : classes.fieldColumnTwo}
                style={matches ? null : { paddingLeft: "15px" }}
              >
                <Link
                  to={`/dashboard/${businessId}/Services/${optionDetails.serviceId}`}
                  className={classes.valueTextLink}
                >
                  <LightTooltip
                    arrow
                    title={`${optionDetails.serviceName} (${optionDetails.offeringType})`}
                  >
                    <Typography className={classes.valueTextLink} noWrap>
                      {`${optionDetails.serviceName} (${optionDetails.offeringType})`}
                    </Typography>
                  </LightTooltip>
                </Link>
              </div>
            </div>
            <div
              className={
                matches ? classes.contentColumnMiddle : classes.fieldRows
              }
            >
              <div
                className={
                  matches ? classes.blockLabel : classes.fieldColumnOne
                }
              >
                <Typography className={classes.labelText}>Site</Typography>
              </div>
              <div
                className={matches ? classes.blockText : classes.fieldColumnTwo}
                style={matches ? null : { paddingLeft: "15px" }}
              >
                <Link
                  to={`/dashboard/${businessId}/Sites/${optionDetails.siteId}`}
                  className={classes.valueTextLink}
                >
                  <LightTooltip arrow title={optionDetails.siteName}>
                    <Typography className={classes.valueTextLink} noWrap>
                      {optionDetails.siteName}
                    </Typography>
                  </LightTooltip>
                </Link>
              </div>
            </div>
            <div
              className={
                matches ? classes.contentColumnMiddle : classes.fieldRows
              }
            >
              <div
                className={
                  matches ? classes.blockLabel : classes.fieldColumnOne
                }
              >
                <Typography className={classes.labelText}>Sessions</Typography>
              </div>
              <div
                className={matches ? classes.blockText : classes.fieldColumnTwo}
                style={matches ? null : { paddingLeft: "15px" }}
              >
                <Typography className={classes.valueText} noWrap>
                  {optionDetails.type === "Pack" ||
                  optionDetails.type === "Season Pass"
                    ? optionDetails.optionSettings.sessionCount
                    : "1"}
                </Typography>
              </div>
            </div>
            <div
              className={
                matches ? classes.contentColumnMiddle : classes.fieldRows
              }
            >
              <div
                className={
                  matches ? classes.blockLabel : classes.fieldColumnOne
                }
              >
                <Typography className={classes.labelText}>Price</Typography>
              </div>
              <div
                className={matches ? classes.blockText : classes.fieldColumnTwo}
                style={matches ? null : { paddingLeft: "15px" }}
              >
                <Typography className={classes.valueText} noWrap>
                  {CurrencyPreProcessor({
                    amount: optionDetails.price,
                    currencyBreak: props.currencyInfo.currencyBreak,
                    roundingFactor: props.currencyInfo.roundingFactor,
                    currencySymbol: props.currencyInfo.currencySymbol,
                  }) + ` ${props.currencyInfo.currencyShortName}`}
                </Typography>
              </div>
            </div>
            <div
              className={
                matches ? classes.contentColumnMiddle : classes.fieldRows
              }
            >
              <div
                className={
                  matches ? classes.blockLabel : classes.fieldColumnOne
                }
              >
                <Typography className={classes.labelText}>
                  Transaction Fee
                </Typography>
              </div>
              <div
                className={matches ? classes.blockText : classes.fieldColumnTwo}
                style={matches ? null : { paddingLeft: "15px" }}
              >
                <Typography className={classes.valueText}>
                  {optionDetails.transactionFee !== null
                    ? `${optionDetails.transactionFee}%`
                    : "None"}
                </Typography>
              </div>
            </div>
            {optionDetails.type === "Season Pass" ? (
              <div
                className={
                  matches ? classes.contentColumnMiddle : classes.fieldRows
                }
              >
                <div
                  className={
                    matches ? classes.blockLabel : classes.fieldColumnOne
                  }
                >
                  <Typography className={classes.labelText}>
                    Scheduling Type
                  </Typography>
                </div>
                <div
                  className={
                    matches ? classes.blockText : classes.fieldColumnTwo
                  }
                  style={matches ? null : { paddingLeft: "15px" }}
                >
                  <Typography className={classes.valueText}>
                    {optionDetails.optionSettings.scheduleType}
                  </Typography>
                </div>
              </div>
            ) : null}
            {optionDetails.type === "Season Pass" ? (
              <div
                className={
                  matches ? classes.contentColumnMiddle : classes.fieldRows
                }
              >
                <div
                  className={
                    matches ? classes.blockLabel : classes.fieldColumnOne
                  }
                >
                  <Typography className={classes.labelText}>
                    {optionDetails.optionSettings.scheduleType === "Default"
                      ? "Sell End Date"
                      : "Prorate Enabled"}
                  </Typography>
                </div>
                <div
                  className={
                    matches ? classes.blockText : classes.fieldColumnTwo
                  }
                  style={matches ? null : { paddingLeft: "15px" }}
                >
                  <Typography className={classes.valueText}>
                    {optionDetails.optionSettings.scheduleType === "Default"
                      ? moment(
                          optionDetails.optionSettings.sellEndDate,
                          "YYYY/MM/DD"
                        ).format("MMMM Do YYYY")
                      : optionDetails.optionSettings.proRate
                      ? "Yes"
                      : "No"}
                  </Typography>
                </div>
              </div>
            ) : null}
            <div
              className={
                matches ? classes.contentColumnMiddle : classes.fieldRows
              }
            >
              <div
                className={
                  matches ? classes.blockLabel : classes.fieldColumnOne
                }
              >
                <Typography className={classes.labelText}>
                  Pricing Group
                </Typography>
              </div>
              <div
                className={matches ? classes.blockText : classes.fieldColumnTwo}
                style={matches ? null : { paddingLeft: "15px" }}
              >
                <Typography className={classes.valueText}>
                  {optionDetails.priceGroup === null ? (
                    "None"
                  ) : (
                    <Link
                      to={`/dashboard/${businessId}/Price-Groups/${optionDetails.priceGroup.priceGroupId}`}
                      className={classes.valueTextLink}
                    >
                      {optionDetails.priceGroup.name}
                    </Link>
                  )}
                </Typography>
              </div>
            </div>
            <div
              className={
                matches ? classes.contentColumnLast : classes.fieldRows
              }
            >
              <div
                className={
                  matches ? classes.blockLabel : classes.fieldColumnOne
                }
              >
                <Typography className={classes.labelText}>
                  Max Discount
                </Typography>
              </div>
              <div
                className={matches ? classes.blockText : classes.fieldColumnTwo}
                style={matches ? null : { paddingLeft: "15px" }}
              >
                <Typography className={classes.valueText}>
                  {optionDetails.maxDiscount !== null
                    ? `${optionDetails.maxDiscount}%`
                    : "None"}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span
              className={
                bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
              }
            >
              Terms and Conditions
            </span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            {optionDetails.type !== "Unit" ? (
              <div className={classes.fieldRows}>
                <div
                  className={classes.fieldColumnTwo}
                  style={{ maxWidth: "140px" }}
                >
                  <span className={classes.labelText}>Validity</span>{" "}
                </div>
                <div
                  className={classes.fieldColumnTwo}
                  style={{ paddingLeft: "110px" }}
                >
                  <span className={classes.valueText}>
                    {optionDetails.type === "Pack"
                      ? `${optionDetails.optionSettings.validity} ${
                          optionDetails.optionSettings.validity < 2
                            ? optionDetails.optionSettings.validityUnit.slice(
                                0,
                                -1
                              )
                            : optionDetails.optionSettings.validityUnit
                        } from purchase`
                      : `${optionDetails.optionSettings.startDateStr} - ${optionDetails.optionSettings.endDateStr}`}
                  </span>
                </div>
              </div>
            ) : null}
            <div className={classes.fieldRows}>
              <div
                className={classes.fieldColumnTwo}
                style={{ maxWidth: "140px" }}
              >
                <span
                  className={classes.labelText}
                  style={{ whiteSpace: "normal" }}
                >
                  Session Cancelation by Customer
                </span>{" "}
              </div>
              <div
                className={classes.fieldColumnTwo}
                style={{ paddingLeft: "15px" }}
              >
                <span className={classes.valueText}>
                  {optionDetails.optionSettings.cancelAllowed
                    ? "Allowed"
                    : "Not allowed"}
                </span>
              </div>
            </div>
            {optionDetails.optionSettings.cancelAllowed ? (
              <div className={classes.fieldRows}>
                <div
                  className={classes.fieldColumnTwo}
                  style={{ maxWidth: "140px" }}
                >
                  <span
                    className={classes.labelText}
                    style={{ whiteSpace: "normal" }}
                  >
                    Session Cancelation Deadline
                  </span>
                </div>
                <div
                  className={classes.fieldColumnTwo}
                  style={{ paddingLeft: "15px" }}
                >
                  <span className={classes.valueText}>
                    {optionDetails.optionSettings.cancelAllowed === false
                      ? "Not Allowed"
                      : optionDetails.optionSettings.cancelDeadline === 0
                      ? "Any time before session start time"
                      : `${optionDetails.optionSettings.cancelDeadline} ${
                          optionDetails.optionSettings.cancelDeadline === 1
                            ? "hour"
                            : "hours"
                        } before session start time`}
                  </span>
                </div>
              </div>
            ) : null}
            {optionDetails.type != "Unit" ? (
              <div className={classes.fieldRows}>
                <div
                  className={classes.fieldColumnTwo}
                  style={{ maxWidth: "140px" }}
                >
                  <span
                    className={classes.labelText}
                    style={{ whiteSpace: "normal" }}
                  >
                    Self Check-in by Customer
                  </span>{" "}
                </div>
                <div
                  className={classes.fieldColumnTwo}
                  style={{ paddingLeft: "15px" }}
                >
                  <span className={classes.valueText}>
                    {optionDetails.optionSettings.customerCheckin
                      ? "Allowed"
                      : "Not allowed"}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </Grid>
        {offlineEnabled ? (
          <React.Fragment>
            <Grid item xs={12}>
              <div className={classes.headingWrapper}>
                <span
                  className={
                    bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                  }
                >
                  Payment Method
                </span>
                <span
                  title={
                    optionDetails.bookingType === "Offline"
                      ? "Make Payment Online"
                      : "Make Payment Offline"
                  }
                  className={classes.rightElem}
                >
                  <Button
                    className={classes.buttonBasic}
                    onClick={() => {
                      setEditOffline(true);
                    }}
                  >
                    <Typography>
                      Make{" "}
                      {optionDetails.bookingType === "Offline"
                        ? "Online"
                        : "Offline"}
                    </Typography>
                  </Button>
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.contentRow}>
                <Typography
                  className={classes.valueTextPad}
                  style={{ maxWidth: "100%" }}
                >
                  Payment method is set to <b>{optionDetails.bookingType}</b>
                </Typography>
              </div>
            </Grid>
          </React.Fragment>
        ) : null}

        {optionDetails.type === "Season Pass" &&
        optionDetails.optionSettings.scheduleType === "Fixed" ? (
          <React.Fragment>
            <Grid item xs={12}>
              <div className={classes.headingWrapper}>
                <span
                  className={
                    bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                  }
                >
                  Schedule
                </span>
                <span title="Update Schedule" className={classes.rightElem}>
                  <Button
                    className={classes.buttonBasic}
                    onClick={() => {
                      setAFS(true);
                    }}
                  >
                    <Typography>Update Schedule</Typography>
                  </Button>
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.contentRow}>
                {optionDetails.fixedSchedule.length > 0 ? (
                  <ListTable
                    styling={{ maxHeight: 250, width: "100%" }}
                    identifier={"addons"}
                    tableHeaders={bigEnough ? scheduleHeader : scheduleHeaderM}
                    tableRows={optionDetails.fixedSchedule.map(
                      (classData, index) => ({
                        classNumber: (
                          <Link
                            className={classes.valueTextLink}
                            to={`/dashboard/${businessId}/Group-Classes/${classData.classId}`}
                          >
                            <Typography
                              noWrap
                              className={classes.valueTextLink}
                            >
                              {classData.classNumber}
                            </Typography>
                          </Link>
                        ),
                        startTime: `${classData.startDate} ${classData.startTime}`,
                        endTime: `${classData.endDate} ${classData.endTime}`,
                      })
                    )}
                  />
                ) : null}
                {optionDetails.fixedSchedule.length !==
                optionDetails.optionSettings.sessionCount ? (
                  <Typography
                    style={{ maxWidth: "100%", paddingTop: "20px" }}
                    align="center"
                  >
                    {`*Update schedule to have ${optionDetails.optionSettings.sessionCount} classes in order to be able to activate pricing option`}
                  </Typography>
                ) : null}
              </div>
            </Grid>
          </React.Fragment>
        ) : null}

        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span
              className={
                bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
              }
            >
              Add Ons
            </span>
            {Array.isArray(AOChecked) && !AOChecked.includes(true) ? (
              <span title="Add Add On" className={classes.rightElem}>
                <Button
                  className={classes.buttonBasic}
                  onClick={() => {
                    setAADOOpen(true);
                  }}
                >
                  <CAddIcon />
                </Button>
              </span>
            ) : (
              <span title="Remove Add On(s)" className={classes.rightElem}>
                <Button
                  className={classes.buttonBasic}
                  onClick={() => {
                    setDelAO(true);
                  }}
                >
                  <CCloseIcon />
                </Button>
              </span>
            )}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.contentRow}>
            {optionDetails.addOns.length !== 0 ? (
              <ListTable
                styling={{ maxHeight: 250, width: "100%" }}
                identifier={"addons"}
                tableHeaders={bigEnough ? addOnHeaders : addOnHeadersM}
                tableRows={optionDetails.addOns.map((addOn, index) => ({
                  check: (
                    <Checkbox
                      color="primary"
                      checked={AOChecked[index]}
                      onChange={() => {
                        let temp = [...AOChecked];
                        temp[index] = !temp[index];
                        setAOChecked(temp);
                      }}
                    />
                  ),
                  displayName: (
                    <Link
                      className={classes.valueTextLink}
                      to={`/dashboard/${businessId}/Add-Ons/${addOn.addOnId}`}
                    >
                      <LightTooltip arrow title={addOn.displayName}>
                        <Typography noWrap className={classes.valueTextLink}>
                          {addOn.displayName}
                        </Typography>
                      </LightTooltip>
                    </Link>
                  ),
                  id: addOn.addOnId,
                  type: addOn.type,
                  price: `${CurrencyPreProcessor({
                    amount: addOn.price,
                    currencyBreak: props.currencyInfo.currencyBreak,
                    roundingFactor: props.currencyInfo.roundingFactor,
                    currencySymbol: props.currencyInfo.currencySymbol,
                  })} ${addOn.sellBy.toLowerCase()}`,
                  basis: addOn.sellBy.toLowerCase(),
                }))}
              />
            ) : (
              <div className={classes.topMarginBlock}>
                <Typography
                  className={classes.valueTextPad}
                  style={{ maxWidth: "100%" }}
                >
                  No add ons have been added for this pricing option.
                </Typography>
              </div>
            )}
          </div>
        </Grid>

        {optionDetails.type !== "Unit" ? (
          <React.Fragment>
            <Grid item xs={12}>
              <div className={classes.headingWrapper}>
                <span
                  className={
                    bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                  }
                >
                  Installment Payment
                </span>
                {!optionDetails.installmentEnabled ? (
                  <span
                    title="Setup Installment Payment"
                    className={classes.rightElem}
                  >
                    <Button
                      className={classes.buttonBasic}
                      onClick={() => {
                        setIPOpen(true);
                      }}
                    >
                      <CAddIcon />
                    </Button>
                  </span>
                ) : (
                  <span title="Reset Payment" className={classes.rightElem}>
                    <Button
                      className={classes.buttonBasic}
                      onClick={() => {
                        setDelIP(true);
                      }}
                    >
                      <CCloseIcon />
                    </Button>
                  </span>
                )}
              </div>
            </Grid>
          </React.Fragment>
        ) : null}

        {optionDetails.type !== "Unit" ? (
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {optionDetails.installmentEnabled ? (
                <React.Fragment>
                  <div className={classes.fieldRows}>
                    <div
                      className={classes.fieldColumnTwo}
                      style={{ width: "140px" }}
                    >
                      <span
                        className={classes.labelText}
                        style={{ whiteSpace: "normal" }}
                      >
                        # of Installments
                      </span>
                    </div>
                    <div
                      className={classes.fieldColumnTwo}
                      style={{ paddingLeft: "15px" }}
                    >
                      <span className={classes.valueText}>
                        {optionDetails.installmentCount}
                      </span>
                    </div>
                  </div>
                  <div className={classes.fieldRows}>
                    <div
                      className={classes.fieldColumnTwo}
                      style={{ width: "140px" }}
                    >
                      <span
                        className={classes.labelText}
                        style={{ whiteSpace: "normal" }}
                      >
                        Payment Frequency
                      </span>
                    </div>
                    <div
                      className={classes.fieldColumnTwo}
                      style={{ paddingLeft: "15px" }}
                    >
                      <span className={classes.valueText}>
                        {optionDetails.installmentFrequency}
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div className={classes.topMarginBlock}>
                  <Typography
                    className={classes.valueTextPad}
                    style={{ maxWidth: "100%" }}
                  >
                    Installment payment has not been configured for this pricing
                    option
                  </Typography>
                </div>
              )}
            </div>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span
              className={
                bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
              }
            >
              Agreements
            </span>
            {Array.isArray(AChecked) && !AChecked.includes(true) ? (
              <span title="Add Agreement" className={classes.rightElem}>
                <Button
                  className={classes.buttonBasic}
                  onClick={() => {
                    setAAOpen(true);
                  }}
                >
                  <CAddIcon />
                </Button>
              </span>
            ) : (
              <span title="Remove Agreement(s)" className={classes.rightElem}>
                <Button
                  className={classes.buttonBasic}
                  onClick={() => {
                    setDelA(true);
                  }}
                >
                  <CCloseIcon />
                </Button>
              </span>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            {optionDetails.agreements.length != 0 ? (
              <ListTable
                styling={{ maxHeight: 250, width: "100%" }}
                identifier={"agreements"}
                tableHeaders={agreementHeaders}
                tableRows={optionDetails.agreements.map((agreement, index) => ({
                  check: (
                    <Checkbox
                      color="primary"
                      checked={AChecked[index]}
                      onChange={() => {
                        let temp = [...AChecked];
                        temp[index] = !temp[index];
                        setAChecked(temp);
                      }}
                    />
                  ),
                  name: (
                    <Link
                      className={classes.valueTextLink}
                      to={`/dashboard/${businessId}/Agreements/${agreement.agreementId}`}
                    >
                      <LightTooltip arrow title={agreement.name}>
                        <Typography noWrap className={classes.valueTextLink}>
                          {agreement.name}
                        </Typography>
                      </LightTooltip>
                    </Link>
                  ),
                  id: agreement.agreementId,
                  type: agreement.type,
                }))}
              />
            ) : (
              <div className={classes.topMarginBlock}>
                <Typography
                  className={classes.valueTextPad}
                  style={{ maxWidth: "100%" }}
                >
                  No agreements have been added for this pricing option.
                </Typography>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span
              className={
                bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
              }
            >
              Fees
            </span>
            <span className={classes.rightElem}>
              <Button
                className={classes.buttonBasic}
                onClick={() => {
                  setAFOpen(true);
                }}
              >
                <CAddIcon />
              </Button>
            </span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            {optionDetails.additionalFees.length !== 0 ? (
              <ListTable
                styling={{ maxHeight: 250, width: "100%" }}
                identifier={"fees"}
                tableHeaders={feeHeaders}
                tableRows={optionDetails.additionalFees.map((fee) => ({
                  name: fee.name,
                  amount:
                    fee.feeType === "Percentage"
                      ? `${fee.feeValue}%`
                      : CurrencyPreProcessor({
                          amount: fee.feeValue,
                          currencyBreak: props.currencyInfo.currencyBreak,
                          roundingFactor: props.currencyInfo.roundingFactor,
                          currencySymbol: props.currencyInfo.currencySymbol,
                        }) + ` ${props.currencyInfo.currencyShortName}`,
                  actions: (
                    <ButtonGroup variant="text" size="small">
                      <Button
                        style={{
                          color: "#0079fc",
                          paddingTop: "2px",
                          paddingBottom: "5px",
                          textTransform: "none",
                        }}
                        onClick={() => {
                          setEFOpen(fee);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        style={{
                          color: "red",
                          paddingTop: "2px",
                          paddingBottom: "5px",
                          textTransform: "none",
                        }}
                        onClick={() => {
                          setDFOpen(fee.additionalFeeId);
                        }}
                      >
                        Remove
                      </Button>
                    </ButtonGroup>
                  ),
                }))}
              />
            ) : (
              <div className={classes.topMarginBlock}>
                <Typography
                  className={classes.valueTextPad}
                  style={{ maxWidth: "100%" }}
                >
                  No fees have been added for this pricing option.
                </Typography>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span
              className={
                bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
              }
            >
              Catalog
            </span>
            <span className={classes.rightElem}>
              <Button
                variant="outlined"
                onClick={() => {
                  if (checkedC.length > 0) {
                    setUnassignC(true);
                  } else {
                    setAssignC(true);
                  }
                }}
              >
                {checkedC.length === 0 ? <CAddIcon /> : <CCloseIcon />}
              </Button>
            </span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            {optionDetails.catalogDetails?.length !== 0 ? (
              <ListTable
                styling={{ maxHeight: 250, width: "100%" }}
                identifier={"catalog"}
                tableHeaders={bigEnough ? catalogHeaders : catalogHeadersM}
                tableRows={optionDetails.catalogDetails.map((catalog) => ({
                  ...catalog,
                  action: (
                    <Checkbox
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (checkedC.includes(catalog.catalogId)) {
                          setCheckedC(
                            checkedC.filter(
                              (otherCatalogId) =>
                                otherCatalogId !== catalog.catalogId
                            )
                          );
                        } else {
                          setCheckedC([...checkedC, catalog.catalogId]);
                        }
                      }}
                    />
                  ),
                  status: (
                    <StatusTag
                      wrapperName={
                        catalog.isActive ? "greenWrapper" : "redWrapper"
                      }
                      value={catalog.isActive ? "Active" : "Inactive"}
                    />
                  ),
                  id: catalog.catalogId,
                }))}
                // TODO: api doesn't send catalog id
                rowClick={(catalogId) =>
                  safePush(
                    `/dashboard/${businessId}/Catalog/${catalogId}`,
                    history,
                    location
                  )
                }
              />
            ) : (
              <div className={classes.topMarginBlock}>
                <Typography
                  className={classes.valueTextPad}
                  style={{ maxWidth: "100%" }}
                >
                  This pricing option has not been added to any catalog
                </Typography>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span
              className={
                bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
              }
            >
              Taxes
            </span>
            <span className={classes.rightElem}>
              <Button
                className={classes.buttonBasic}
                onClick={() => {
                  setATOpen(true);
                }}
              >
                <CAddIcon />
              </Button>
            </span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            {optionDetails.taxLines.length !== 0 ? (
              <ListTable
                styling={{ maxHeight: 250, width: "100%" }}
                identifier={"taxes"}
                tableHeaders={taxHeaders}
                tableRows={optionDetails.taxLines.map((tax) => ({
                  name: tax.name,
                  percentage: `${tax.percentage}%`,
                  actions: (
                    <ButtonGroup variant="text" size="small">
                      <Button
                        style={{
                          color: "#0079fc",
                          paddingTop: "2px",
                          paddingBottom: "5px",
                          textTransform: "none",
                        }}
                        onClick={() => {
                          setETOpen(tax);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        style={{
                          color: "red",
                          paddingTop: "2px",
                          paddingBottom: "5px",
                          textTransform: "none",
                        }}
                        onClick={() => {
                          setDTOpen(tax.taxLineId);
                        }}
                      >
                        Remove
                      </Button>
                    </ButtonGroup>
                  ),
                }))}
              />
            ) : (
              <div className={classes.topMarginBlock}>
                <Typography
                  className={classes.valueTextPad}
                  style={{ maxWidth: "100%" }}
                >
                  No taxes have been added for this pricing option.
                </Typography>
              </div>
            )}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span
              className={
                bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
              }
            >
              Discounts
            </span>
            {Array.isArray(DChecked) && !DChecked.includes(true) ? (
              <span title="Add Discount" className={classes.rightElem}>
                <Button
                  className={classes.buttonBasic}
                  onClick={() => {
                    setDiscOpen(true);
                  }}
                >
                  <CAddIcon />
                </Button>
              </span>
            ) : (
              <span title="Remove Discounts" className={classes.rightElem}>
                <Button
                  className={classes.buttonBasic}
                  onClick={() => {
                    setDelD(true);
                  }}
                >
                  <CCloseIcon />
                </Button>
              </span>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            {optionDetails.discounts.length !== 0 ? (
              <ListTable
                styling={{ maxHeight: 250, width: "100%" }}
                identifier={"discounts"}
                tableHeaders={discountHeaders}
                tableRows={optionDetails.discounts.map((discount, index) => ({
                  check: (
                    <Checkbox
                      color="primary"
                      checked={DChecked[index]}
                      onChange={() => {
                        let temp = [...DChecked];
                        temp[index] = !temp[index];
                        setDChecked(temp);
                      }}
                    />
                  ),
                  name: (
                    <Link
                      className={classes.valueTextLink}
                      to={`/dashboard/${businessId}/Discounts/${discount.discountId}`}
                    >
                      <LightTooltip arrow title={discount.name}>
                        <Typography noWrap className={classes.valueTextLink}>
                          {discount.name}
                        </Typography>
                      </LightTooltip>
                    </Link>
                  ),
                  id: discount.discountId,
                  percent: `${discount.percentage}%`,
                }))}
              />
            ) : (
              <div className={classes.topMarginBlock}>
                <Typography
                  className={classes.valueTextPad}
                  style={{ maxWidth: "100%" }}
                >
                  No discounts have been added for this pricing option.
                </Typography>
              </div>
            )}
          </div>
        </Grid>
        {optionDetails.offeringType === "Private" ? (
          <React.Fragment>
            <Grid item xs={12}>
              <div className={classes.headingWrapper}>
                <span
                  className={
                    bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                  }
                >
                  Staff
                </span>
                {Array.isArray(SChecked) && !SChecked.includes(true) ? (
                  <span title="Add Staff" className={classes.rightElem}>
                    <Button
                      className={classes.buttonBasic}
                      onClick={() => {
                        setStaffOpen(true);
                      }}
                    >
                      <CAddIcon />
                    </Button>
                  </span>
                ) : (
                  <span title="Remove Staff" className={classes.rightElem}>
                    <Button
                      className={classes.buttonBasic}
                      onClick={() => {
                        setDelS(true);
                      }}
                    >
                      <CCloseIcon />
                    </Button>
                  </span>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.contentRow}>
                {optionDetails.staffs && optionDetails.staffs.length !== 0 ? (
                  <ListTable
                    styling={{ maxHeight: 250, width: "100%" }}
                    identifier={"staffs"}
                    tableHeaders={staffHeaders}
                    tableRows={optionDetails.staffs.map((staff, index) => ({
                      check: (
                        <Checkbox
                          color="primary"
                          checked={SChecked[index]}
                          onChange={() => {
                            let temp = [...SChecked];
                            temp[index] = !temp[index];
                            setSChecked(temp);
                          }}
                        />
                      ),
                      name: (
                        <Link
                          className={classes.valueTextLink}
                          to={`/dashboard/${businessId}/Staffs/${staff.staffId}`}
                        >
                          <LightTooltip
                            arrow
                            title={`${staff.firstName} ${staff.lastName}`}
                          >
                            <Typography
                              noWrap
                              className={classes.valueTextLink}
                            >
                              {`${staff.firstName} ${staff.lastName}`}
                            </Typography>
                          </LightTooltip>
                        </Link>
                      ),
                      id: staff.staffId,
                      memberId: `${staff.memberId}`,
                    }))}
                  />
                ) : (
                  <div className={classes.topMarginBlock}>
                    <Typography
                      className={classes.valueTextPad}
                      style={{ maxWidth: "100%" }}
                    >
                      No staff have been added to this pricing option.
                    </Typography>
                  </div>
                )}
              </div>
            </Grid>
          </React.Fragment>
        ) : null}
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

export default PricingOption;
