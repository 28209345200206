import { Container, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { updateStaff } from '../../../../../../utils';
import CModal from '../../../../../Form/CModal';
import Form from '../../../../../Form/Form';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))

const EditPermsForm = (props) => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()
    const [admin, setAdmin] = useState(null)

    useEffect(() => {
        setAdmin(props.staffDetails.role === "Admin")
    }, [props.open])

    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    moreData={{
                        businessId: businessId,
                        staffId: props.staffDetails.staffId
                    }}
                    title="Edit Staff Permissions"
                    cancelEnabled
                    cancelAction={() => { props.setOpen(false) }}
                    submitText="Edit"
                    action={updateStaff}
                    postAction={() => {
                        props.setOpen(false)
                        props.refresh()

                    }}
                    inputFields={[
                        {
                            type: "RadioSet",
                            label: "Role",
                            name: "role",
                            choices: [
                                ["Admin", "Admin"],
                                ["Staff", "Staff"]
                            ],
                            initialvalue: props.staffDetails.role,
                            validator: (value) => { setAdmin(value === "Admin") }
                        },
                        {
                            type: "CheckBox",
                            label: "Profile Access",
                            name: "profileAccess",
                            initialvalue: props.staffDetails.profileAccess,
                            size: {
                                xs: 3
                            },
                            enabled: !admin,
                        },
                        {
                            type: "CheckBox",
                            label: "Scheduling Access",
                            name: "schedulingAccess",
                            size: {
                                xs: 3
                            },
                            initialvalue: props.staffDetails.schedulingAccess,
                            enabled: !admin,
                        },
                        {
                            type: "CheckBox",
                            label: "Dashboard Access",
                            name: "dashboardAccess",
                            size: {
                                xs: 3
                            },
                            initialvalue: props.staffDetails.dashboardAccess,
                            enabled: !admin,
                        },
                        {
                            type: "CheckBox",
                            label: "Log Presence Access",
                            name: "checkInAccess",
                            size: {
                                xs: 3
                            },
                            initialvalue: props.staffDetails.checkInAccess,
                            enabled: !admin,
                        },
                        {
                            type: "CheckBox",
                            label: "Finance Access",
                            name: "financeAccess",
                            initialvalue: props.staffDetails.financeAccess,
                            enabled: admin,
                            size: {
                                xs: 3
                            },
                        },
                        {
                            type: "CheckBox",
                            label: "Report Access",
                            name: "reportAccess",
                            initialvalue: props.staffDetails.reportAccess,
                            enabled: admin,
                            size: {
                                xs: 3
                            },
                        },

                    ]}
                />
            </Container>
        </CModal>
    );
};

export default EditPermsForm