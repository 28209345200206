import React, { useState, useEffect } from 'react'
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from "@material-ui/core/FormHelperText";
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    comp:
    {
        width: "100%"
    },
    formLabel: {
        fontSize: 14,
        color: "#3C4257"
    },
    fieldText: {
        fontSize: 14,
        color: "#1A1F36",
        padding: "10px"
    },
    helperText: {
        marginRight: 14,
        marginLeft: 14
    }
}));

const CRadioSet = (props) => {
    useEffect(() => {
        if (props.initialvalue) {
            props.updateGlob(props.initialvalue)
        }
        else {
            props.updateGlob(null)
        }
    }, [])
    const [selected, setSelected] = useState(props.initialvalue)

    const classes = useStyles();
    if(props.enabled === false){
        return null
    }else {
        if (Array.isArray(props.choices)) {
            return (
                <div className={classes.comp}>
                    <Typography className = {classes.formLabel}>
                        {props.label}
                    </Typography>
                    {props.choices.map((choice, index) => {
                        return (
                            <FormControlLabel
                                pos = "start"
                                key = {`${props.name}-${index}`}
                                control={<Radio
                                    color = "primary"
                                    checked={selected === choice[1]}
                                    onChange={(event) => {
                                        setSelected(choice[1])
                                        props.updateGlob(choice[1])
                                        props.onchange(choice[1])
                                    }}
                                    name={props.name}
                                    value={choice[1]}
                                />}
                                label={<Typography className={classes.fieldText}>{choice[0]}</Typography>} />
                        )
                    })}
                    <FormHelperText
                        className={classes.helperText}
                        error={(typeof props.errortext == "string" && props.errortext !== "" ? true : false)}
                    >
                        {props.errortext}
                    </FormHelperText>
    
                </div>)
        }
        else {
            return (null);
        }
    }

}

export default CRadioSet
