import "@fontsource/roboto/400.css";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import '../HeaderButton.css';
const useStyles = makeStyles((theme) => ({
    header: {
        fontFamily: "'Work Sans', sans-serif",
        fontSize: 18,
        color: "black",
        "&:hover": {
            fontSize: 19,
            fontWeight: "bold"
        }
    },
    headerWordsT: {
        fontFamily: "'Work Sans', sans-serif",
        fontSize: 18,
        color: "white",
        "&:hover": {
            fontSize: 19,
            fontWeight: "bold"
        }
    },
    headerArrow: {
        fontFamily: "'Work Sans', sans-serif",
        fontSize: 18,
        color: "black",
        "&:hover": {
            fontSize: 19,
            fontWeight: "bold"
        }
    },
    headerArrowT: {
        fontFamily: "'Work Sans', sans-serif",
        fontSize: 16,
        color: "white",
        "&:hover": {
            fontSize: 19,
            fontWeight: "bold"
        }
    }
}))
const Dashboards = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();
    const { headerClasses } = props
    const bigEnough = useMediaQuery('(min-width:750px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const dashboards = useSelector(state => state.dashboards)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (bigEnough) {
        return (
            <React.Fragment>
                <Button onClick={handleClick} className={headerClasses.headerWords} style={{ textTransform: 'none' }}>
                    Dashboards
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {dashboards ? dashboards.map((dashboard, index) => (
                        <MenuItem key={`dashboards-${index}`} style={{ textTransform: 'none' }} onClick={(event) => { handleClose(event); history.push(`/dashboard/${dashboard.businessId}`) }}>
                            <Typography noWrap style={{ maxWidth: 150, fontFamily: "'Work Sans', sans-serif" }}>
                                {dashboard.businessName}
                            </Typography>
                        </MenuItem>
                    )) : null}
                </Menu>
            </React.Fragment>
        );
    }
    else {
        return null
    }
};

export default Dashboards;