const validDates = (dates) => {
    const date1 = dates[0]
    const date2 = dates[1]

    let error1 = null
    let error2 = null

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    let start, end;





    if (!date1) {
        error1 = "Date is required"
    }
    if (!date2) {
        error2 = "Date is required"
    }

    if (date1) {
        start = convertToDate(date1)
        if (start < today) {
            error1 = "Start cannot be before today"
        }
    }
    if (date1 && date2) {
        end = convertToDate(date2)
        if (end < start) {
            error2 = "End cannot be before start"
        }
    }

    return ([error1, error2])
}

export const convertToDate = (date) => {
    let [year, month, day] = date.split('/');
    return new Date(year, (month - 1) % 12, day)
}

export default validDates