import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  schedulePackStaff,
  staffClassDetails,
  staffPackSearch,
  withLoading,
} from "../../../../../../utils";
import CDisplayArea from "../../../../../Form/CDisplayArea";
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";
import LightTooltip from "../../../../../Form/LightTooltip";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import {
  CEllipsisIcon
} from "../../../../../Icons/Icons";
import MapContainer from "../../../../../MapContainer/MapContainer";
import ResourceTable from "../../../../../ResourceTable/ResourceTable";
import ListTable from "../../../../../TableComponents/ListTable";
import { FeedbackFormWithStarTimeGate, intentForClass } from "../../../AdminDashboard/AdminOptions/Feedback/FeedbackForm";
import StatusTag from "./../../../../../TagComponents/StatusTag";
import StaffAttendanceSheet from "./StaffAttendanceSheet";
import { BusinessFeedbackList } from "../../../../../Account/AccountOptions/Feedback/FeedbackList";

const useStyles = makeStyles((theme) => ({
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
    height: "100%",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
    height: "100%",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
  },
  fieldColumnOne: {
    width: "100px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
    maxWidth: "250px",
  },
  sdCustomCellOne: {
    border: "none",
    width: "5%",
    maxWidth: "200px",
    padding: "0px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingThree: {
    fontSize: "20px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "35%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "60%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
    paddingLeft: "0px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  media: {
    height: 200,
    width: 250,
    objectFit: "cover",
  },
}));

const StaffClass = (props) => {
  const classes = useStyles();
  const [selH, setSelH] = useState(null);
  const [aBP, setABP] = useState(false);
  const [admitP, setAdmitP] = useState(false);
  const [packDetails, setPackDetails] = useState(false);
  const [groupClassDetails, setGroupClassDetails] = useState(null);
  const [feedback, setFeedback] = useState(false)
  const componentRef = useRef(null)
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const [anchorEl, setAnchorEl] = useState(false);
  const [attendance, setAttendance] = useState(false);

  const { classIdURL } = useParams();
  const matches = useMediaQuery("(min-width: 1240px)");
  const bigEnough = useMediaQuery("(min-width: 750px)");

  const attendeesHeaders = [
    {
      name: "Session #",
      columnKey: "sessionNumber",
      columnWidth: "100px",
    },
    {
      name: "Name",
      columnKey: "name",
      columnWidth: "50%",
      type: "Normal",
    },

    {
      name: "Option",
      columnKey: "optionName",
      columnWidth: "50%",
      tooltip: true
    },
    {
      name: "Type",
      columnKey: "optionType",
      columnWidth: "200px",
    },
    {
      name: "",
      columnKey: "status",
      columnWidth: "100px",
      type: "Normal",
    },
  ];
  const attendeesHeadersM = [
    {
      name: "Session #",
      columnKey: "sessionNumber",
      columnWidth: "100px",
    },
    {
      name: "Name",
      columnKey: "name",
      columnWidth: "100%",
      type: "Normal",
    },
    {
      name: "",
      columnKey: "status",
      columnWidth: "100px",
      type: "Normal",
    },
  ];
  const hostsHeaders = [
    {
      name: "Name",
      columnKey: "name",
      columnWidth: "50%",
      type: "Normal",
    },
    {
      name: "Email",
      columnKey: "email",
      columnWidth: "50%",
      tooltip: true,
    },
    {
      name: "Phone Number",
      columnKey: "phoneNumber",
      columnWidth: "150px",
    },
  ];
  const hostsHeadersM = [
    {
      name: "Name",
      columnKey: "name",
      columnWidth: "100%",
      type: "Normal",
    },
  ];

  const packSearch = async (event) => {
    let pack = await staffPackSearch({
      businessId: businessId,
      classId: classIdURL,
      packNumber: event.packNumber,
    });
    if (pack) {
      setPackDetails(pack.packDetails);
    }
  };

  const genEmptyState = (list) => {
    const temp = [];
    for (let i = 0; i < list.length; i++) {
      temp.push(false);
    }
    return temp;
  };

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${groupClassDetails?.classNumber} Attendee List`,
    fonts: [{ style: "font-face" }],
    removeAfterPrint: true,
    pageStyle: {
      padding: "10px"
    }
  });

  useEffect(async () => {
    setUp();
  }, []);

  const setUp = async () => {
    let classData = await withLoading(staffClassDetails, {
      businessId: businessId,
      classId: classIdURL,
    });
    if (classData) {
      setSelH(genEmptyState(classData.classDetails.hostDetails));
      setGroupClassDetails(classData.classDetails);
    }
  };

  if (groupClassDetails && selH) {
    return (
      <React.Fragment>
        <FeedbackFormWithStarTimeGate open = {feedback} setOpen = {setFeedback}  fetchDetails = {setUp} intent = {intentForClass(classIdURL)} eventEndTimeUTC = {groupClassDetails.endTimeUTC} staff/>
        <CModal
          open={aBP}
          setOpen={setABP}
          children={
            <Container component="main" className={classes.formContainer}>
              <Form
                action={packSearch}
                postAction={() => {
                  setAdmitP(true);
                  setABP(false);
                }}
                cancelEnabled
                cancelAction={() => {
                  setABP(false);
                }}
                cancelText="Cancel"
                submitText={"Search"}
                inputFields={[
                  {
                    type: "TextField",
                    label: "Customer Pack Number",
                    name: "packNumber",
                  },
                ]}
              ></Form>
            </Container>
          }
        />
        <StaffAttendanceSheet open={attendance} setOpen={setAttendance} businessId={businessId} classId={classIdURL} classNumber={groupClassDetails.classNumber} />
        <GeneralDialog
          open={admitP}
          close={() => {
            setAdmitP(false);
          }}
          title={"Admit Customer to Group Class"}
          text={
            <div className={classes.contentRow}>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <span
                    className={classes.labelText}
                    style={{ whiteSpace: "normal" }}
                  >
                    Pack Number
                  </span>
                </div>
                <div className={classes.fieldColumnTwo}>
                  <span className={classes.valueText}>
                    {packDetails.packNumber}
                  </span>
                </div>
              </div>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <span
                    className={classes.labelText}
                    style={{ whiteSpace: "normal" }}
                  >
                    Attendee Name
                  </span>
                </div>
                <div className={classes.fieldColumnTwo}>
                  <span
                    className={classes.valueText}
                  >{`${packDetails.attendeeFirstName} ${packDetails.attendeeLastName}`}</span>
                </div>
              </div>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <span className={classes.labelText}>Pack Validity </span>
                </div>
                <div className={classes.fieldColumnTwo}>
                  <span
                    className={classes.valueText}
                  >{`${packDetails.validityStart} - ${packDetails.validityEnd}`}</span>
                </div>
              </div>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <span
                    className={classes.labelText}
                    style={{ whiteSpace: "normal" }}
                  >
                    Service Name{" "}
                  </span>
                </div>
                <div className={classes.fieldColumnTwo}>
                  <span className={classes.valueText}>
                    {packDetails.serviceName}
                  </span>
                </div>
              </div>
            </div>
          }
          action={async () => {
            let data = await withLoading(schedulePackStaff, {
              businessId: businessId,
              classId: classIdURL,
              packId: packDetails.packId,
            });
            if (data) {
              setAdmitP(false)
              setUp()
            }
          }}
        />
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(false);
          }}
        >
          <MenuItem
            onClick={() => {
              setAttendance(true);
              setAnchorEl(false);
            }}
          >
            Take Attendance
          </MenuItem>
          {groupClassDetails.isCanceled?null:
          <MenuItem onClick={() => { setFeedback(true); setAnchorEl(false) }}>Give Feedback</MenuItem>}
          
        </Menu>
        <Grid
          container
          className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
        >
          <Grid item xs={12}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>GROUP CLASS</span>
              {groupClassDetails.isCanceled ? null : (
                <span className={classes.rightElem}>
                  <Button
                    onClick={(event) => {
                      setAnchorEl(event.target);
                    }}
                    className={classes.buttonBasic}
                  >
                    <CEllipsisIcon />
                  </Button>
                </span>
              )}
            </div>
          </Grid>
          <Grid
            item
            style={bigEnough ? { maxWidth: "50%" } : { width: "100%" }}
          >
            <div className={classes.headerPadding}>
              <LightTooltip
                title={`${groupClassDetails.serviceName} (${groupClassDetails.isVirtual ? "Online" : "In-Person"
                  })`}
                arrow
              >
                <Typography
                  className={
                    bigEnough
                      ? classes.topHeadingOne
                      : classes.mobileTopHeadingOne
                  }
                  noWrap
                >
                  {`${groupClassDetails.serviceName} (${groupClassDetails.isVirtual ? "Online" : "In-Person"
                    })`}
                </Typography>
              </LightTooltip>
            </div>
          </Grid>
          {groupClassDetails.isCanceled ? (
            bigEnough ? (
              <Grid item>
                <div
                  className={classes.headerPadding}
                  style={{ paddingLeft: "20px" }}
                >
                  <span className={classes.topHeadingFour}>
                    <StatusTag wrapperName="redWrapper" value="Canceled" />
                  </span>
                </div>
              </Grid>
            ) : (
              <Grid item>
                <div style={{ paddingLeft: "10px" }}>
                  <StatusTag wrapperName="redWrapper" value="Canceled" />
                </div>
              </Grid>
            )
          ) : null}
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <div
                className={
                  matches ? classes.contentColumnFirst : classes.fieldRows
                }
              >
                <div
                  className={
                    matches ? classes.blockLabel : classes.fieldColumnOne
                  }
                >
                  <Typography className={classes.labelText}>
                    Start Time
                  </Typography>
                </div>
                <div
                  className={
                    matches ? classes.blockText : classes.fieldColumnTwo
                  }
                  style={matches ? null : { paddingLeft: "15px" }}
                >
                  <Typography className={classes.valueText}>
                    {`${groupClassDetails.startDate}: ${groupClassDetails.startTime} ${groupClassDetails.timeZone}`}
                  </Typography>
                </div>
              </div>
              <div
                className={
                  matches ? classes.contentColumnMiddle : classes.fieldRows
                }
              >
                <div
                  className={
                    matches ? classes.blockLabel : classes.fieldColumnOne
                  }
                >
                  <Typography className={classes.labelText}>
                    End Time
                  </Typography>
                </div>
                <div
                  className={
                    matches ? classes.blockText : classes.fieldColumnTwo
                  }
                  style={matches ? null : { paddingLeft: "15px" }}
                >
                  <LightTooltip
                    title={`${groupClassDetails.endDate}: ${groupClassDetails.endTime} ${groupClassDetails.timeZone}`}
                    arrow
                  >
                    <Typography className={classes.valueText}>
                      {`${groupClassDetails.endDate}: ${groupClassDetails.endTime} ${groupClassDetails.timeZone}`}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
              <div
                className={
                  matches ? classes.contentColumnMiddle : classes.fieldRows
                }
              >
                <div
                  className={
                    matches ? classes.blockLabel : classes.fieldColumnOne
                  }
                >
                  <Typography className={classes.labelText}>
                    Location
                  </Typography>
                </div>
                <div
                  className={
                    matches ? classes.blockText : classes.fieldColumnTwo
                  }
                  style={matches ? { maxWidth: 160 } : { paddingLeft: "15px" }}
                >
                  <LightTooltip title={groupClassDetails.siteName} arrow>
                    <Typography
                      className={classes.valueText}
                      noWrap
                      style={{ maxWidth: "100px" }}
                    >
                      {groupClassDetails.siteName}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
              <div
                className={
                  matches ? classes.contentColumnMiddle : classes.fieldRows
                }
              >
                <div
                  className={
                    matches ? classes.blockLabel : classes.fieldColumnOne
                  }
                >
                  <Typography className={classes.labelText}>
                    Capacity
                  </Typography>
                </div>
                <div
                  className={
                    matches ? classes.blockText : classes.fieldColumnTwo
                  }
                  style={matches ? null : { paddingLeft: "15px" }}
                >
                  <Typography className={classes.valueText} noWrap>
                    {groupClassDetails.totalSeats === null
                      ? "No Limit"
                      : groupClassDetails.totalSeats}
                  </Typography>
                </div>
              </div>
              <div
                className={
                  matches ? classes.contentColumnMiddle : classes.fieldRows
                }
              >
                <div
                  className={
                    matches ? classes.blockLabel : classes.fieldColumnOne
                  }
                >
                  <Typography className={classes.labelText}>Class #</Typography>
                </div>
                <div
                  className={
                    matches ? classes.blockText : classes.fieldColumnTwo
                  }
                  style={matches ? null : { paddingLeft: "15px" }}
                >
                  <Typography className={classes.valueText}>
                    {groupClassDetails.classNumber}
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <span
                className={
                  bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                }
              >
                Hosts
              </span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {groupClassDetails.hostDetails.length > 0 ? (
                <ListTable
                  styling={{ width: "100%", maxHeight: 250 }}
                  identifier={"hosts"}
                  tableHeaders={(bigEnough
                    ? hostsHeaders
                    : hostsHeadersM
                  ).slice(groupClassDetails.isCanceled ? 1 : 0)}
                  tableRows={groupClassDetails.hostDetails.map(
                    (host, index) => {
                      return {
                        name: (
                          <LightTooltip
                            arrow
                            title={`${host.hostFirstName} ${host.hostLastName}`}
                          >
                            <Typography noWrap className={classes.valueText}>
                              {`${host.hostFirstName} ${host.hostLastName}`}
                            </Typography>
                          </LightTooltip>
                        ),
                        phoneNumber: `${host.hostPhoneNumber.slice(
                          0,
                          2
                        )} (${host.hostPhoneNumber.slice(
                          2,
                          5
                        )}) ${host.hostPhoneNumber.slice(
                          5,
                          8
                        )}-${host.hostPhoneNumber.slice(8, 12)}`,
                        email: (
                          <LightTooltip arrow title="Email Host">
                            <a
                              style={{ color: "#0079fc" }}
                              href={"mailto:" + host.hostEmail}
                            >
                              {host.hostEmail}
                            </a>
                          </LightTooltip>
                        ),
                      };
                    }
                  )}
                  rowClick={null}
                />
              ) : (
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    No hosts for this group class.
                  </span>
                </div>
              )}
            </div>
          </Grid>
          <Grid container xs={12} spacing={2} >
            <Grid item xs={12}>
              <div style={{ paddingBottom: "5px", boxShadow: "inset 0 -1px #e3e8ee" }}>
                <Typography display="inline" gutterBottom={false}>
                  <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Attendees</span>
                </Typography>
                <Button variant="conatined" color="primary" className={[classes.buttonBasic, classes.rightElem]} style={{ marginLeft: "10px" }} onClick={handlePrint}>
                  <PrintIcon />
                </Button>
                {groupClassDetails.isCanceled ? null : (
                  <LightTooltip title="Admit Attendee" arrow>
                    <span>
                      <Button
                        className={[classes.rightElem, classes.buttonBasic]}
                        style={{ color: "white", backgroundColor: "#0079fc" }}
                        onClick={() => {
                          setABP(true);
                        }}
                      >
                        Check-in
                      </Button>
                    </span>
                  </LightTooltip>
                )}
              </div>
            </Grid>
            <Grid xs={12}>
              <div className={classes.contentRow} ref={componentRef}>
                {groupClassDetails.sessionDetails.filter(canc => canc.isCanceled == false).length === 0 ? (
                  <div className={classes.topMarginBlock}>
                    <span className={classes.valueTextPad}>
                      No atendees for this group class.
                    </span>
                  </div>
                ) : (
                  <ListTable
                    styling={{ width: "100%", maxHeight: 250 }}
                    identifier={"attendees"}
                    tableHeaders={
                      bigEnough ? attendeesHeaders : attendeesHeadersM
                    }
                    tableRows={groupClassDetails.sessionDetails.filter(canc => canc.isCanceled == false).map((session) => {
                      return {
                        sessionNumber: (
                          <LightTooltip arrow title="Session Page">
                            <Link
                              className={classes.valueTextLink}
                              to={`/dashboard/${businessId}/Sessions/${session.sessionId}`}
                            >
                              <Typography
                                noWrap
                                className={classes.valueTextLink}
                              >
                                {session.sessionNumber}
                              </Typography>
                            </Link>
                          </LightTooltip>
                        ),
                        optionName: session.optionName,
                        optionType: session.optionType,
                        name: `${session.attendeeFirstName} ${session.attendeeLastName}`,
                        createdAt: moment(session.createdAt).format(
                          "MMM, Do, YYYY : h:mm A "
                        ),
                        status: session.isCanceled ? (
                          <StatusTag wrapperName="redWrapper" value="Canceled" />
                        ) : null,
                      };
                    })}
                    rowClick={null}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <span
                className={
                  bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                }
              >
                Canceled Sessions
              </span>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className={classes.contentRow}>
              {groupClassDetails.sessionDetails.filter(canc => canc.isCanceled == true).length === 0 ? (
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    No cancelations for this group class.
                  </span>
                </div>
              ) : (
                <ListTable
                  styling={{ width: "100%", maxHeight: 250 }}
                  identifier={"attendees"}
                  tableHeaders={
                    bigEnough ? attendeesHeaders : attendeesHeadersM
                  }
                  tableRows={groupClassDetails.sessionDetails.filter(canc => canc.isCanceled == true).map((session) => {
                    return {
                      sessionNumber: (
                        <LightTooltip arrow title="Session Page">
                          <Link
                            className={classes.valueTextLink}
                            to={`/dashboard/${businessId}/Sessions/${session.sessionId}`}
                          >
                            <Typography
                              noWrap
                              className={classes.valueTextLink}
                            >
                              {session.sessionNumber}
                            </Typography>
                          </Link>
                        </LightTooltip>
                      ),
                      name: `${session.attendeeFirstName} ${session.attendeeLastName}`,
                      createdAt: moment(session.createdAt).format(
                        "MMM, Do, YYYY : h:mm A "
                      ),
                      status: session.isCanceled ? (
                        <StatusTag wrapperName="redWrapper" value="Canceled" />
                      ) : null,
                    };
                  })}
                  rowClick={null}
                />
              )}
            </div>
          </Grid>
          <ResourceTable
            classes={classes}
            businessId={businessId}
            resourceDetails={(groupClassDetails?.resourceDetails ?? [])}
            bigEnough={bigEnough}
            eventId={groupClassDetails.classId}
            eventType="Class"
          />
          {!groupClassDetails.isVirtual ? (
            <React.Fragment>
              <Grid item xs={12}>
                <LightTooltip>
                  <Typography className={classes.headingWrapper}>
                    <span
                      className={
                        bigEnough
                          ? classes.subHeading
                          : classes.mobileTopHeadingTwo
                      }
                    >
                      Address
                    </span>
                  </Typography>
                </LightTooltip>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentRow}>
                  <Typography
                    className={classes.valueText}
                    style={{ paddingBottom: "10px" }}
                  >
                    <b>Address:</b>{" "}
                    {groupClassDetails.address2
                      ? `${groupClassDetails.address1}, ${groupClassDetails.address2}`
                      : `${groupClassDetails.address1}`}
                  </Typography>
                  <div
                    style={{ height: 400, width: "100%", justify: "center" }}
                  >
                    <MapContainer
                      siteDetails={{
                        geoPoint: {
                          lat: groupClassDetails.geoPoint[1],
                          lon: groupClassDetails.geoPoint[0],
                        },
                      }}
                      options={{
                        disableDefaultUI: true,
                        zoomControl: false,
                        gestureHandling: "none",
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </React.Fragment>
          ) : null}
          {groupClassDetails.isVirtual ? (
            <React.Fragment>
              <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                  <span
                    className={
                      bigEnough
                        ? classes.subHeading
                        : classes.mobileTopHeadingTwo
                    }
                  >
                    Web Conference Info
                  </span>
                </div>
              </Grid>
              <Grid xs={12}>
                <div className={classes.contentRow}>
                  <CDisplayArea
                    value={"value not provided"}
                    initialMaxHeight={"calc(100vh - 360px)"}
                  />
                </div>
              </Grid>
            </React.Fragment>
          ) : null}
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <span
                className={
                  bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                }
              >
                Timeline
              </span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <Timeline style={{ width: 0, padding: 0 }}>
                {groupClassDetails.isCanceled ? (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography className={classes.valueText}>
                        Canceled By:{" "}
                        {`${groupClassDetails.canceledByStaffFirstName} ${groupClassDetails.canceledByStaffLastName}`}
                      </Typography>
                      <Typography className={classes.labelText}>
                        At:{" "}
                        {moment(groupClassDetails.canceledAt).format(
                          "MMM, Do, YYYY : h:mm A "
                        )}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ) : null}
                {groupClassDetails.rescheduleHistory.map((hist) => {
                  return (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography className={classes.valueText}>
                          {`Rescheduled By: ${hist.rescheduledByStaffFirstName} ${hist.rescheduledByStaffLastName}`}
                        </Typography>
                        <Typography className={classes.labelText}>
                          {`Original Time: ${hist.oldTime}`}
                        </Typography>
                        <Typography className={classes.labelText}>
                          At:{" "}
                          {moment(hist.rescheduledAt).format(
                            "MMM, Do, YYYY : h:mm A "
                          )}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography className={classes.labelText}>
                      Created At:{" "}
                      {moment(groupClassDetails.createdAt).format(
                        "MMM, Do, YYYY : h:mm A "
                      )}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
          </Grid>
          {groupClassDetails.classFeedbacks.length > 0 ?
            <React.Fragment>
              <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                  <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Feedback</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentRow} >
                    <BusinessFeedbackList bigEnough = {bigEnough} feedback = {groupClassDetails.classFeedbacks} businessId = {businessId} staff/>
                </div>
              </Grid>
            </React.Fragment>
          :null}
          <Grid xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default StaffClass;
