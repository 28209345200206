import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Form from '../../../../../Form/Form'
import CModal from '../../../../../Form/CModal'
import { Container } from '@material-ui/core';
import { makeStyles} from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import validEmail from '../../../../../../FormValidation/validEmail';
import { addStaff } from '../../../../../../utils';

const useStyles = makeStyles((theme)=>({
    formContainer:{
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))

const NewStaffForm= (props) => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()
    const history = useHistory();
    const location = useLocation();

    

    const redirect = (success) => {
        if (success) {
            props.setOpen(false);
            history.push(`${props.basePath}/${success.staffId}`);
        }
    }
    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main"  className = {classes.formContainer}>
                <Form
                    moreData = {{businessId:businessId}}
                    title="Add Staff"
                    cancelEnabled
                    cancelAction = {()=>{props.setOpen(false)}}
                    submitText="Add Staff"
                    action={addStaff}
                    postAction = {redirect}
                    inputFields={[
                        {
                            type:"TextField",
                            name: "email",
                            label: "Email",
                            validator: [notEmpty, validEmail]
                        },
                        {
                            type:"TextField",
                            name: "accountNumber",
                            label: "Account Number",
                            validator: notEmpty
                        }
                    ]}
                />
            </Container>
        </CModal>
    );
};

export default NewStaffForm