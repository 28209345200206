import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import FormTemplateList from "./FormTemplateList";

const FormTemplateHandler = () => {
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const basePath = `/dashboard/${businessId}/Feedback-Forms`;
  return (
    <Switch>
      <Route
        exact
        path={basePath}
        render={() => (
          <FormTemplateList basePath={basePath} businessId={businessId} />
        )}
      />
      <Route
        path="*"
        render={() => {
          return <Redirect to="/404" />;
        }}
      />
    </Switch>
  );
};

export default FormTemplateHandler;
