import { Container, makeStyles, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { validTimeRange, validTime } from '../../../../../../FormValidation/validTime';
import { updateCalendarPrefs } from '../../../../../../utils';
import CModal from '../../../../../Form/CModal';
import Form from '../../../../../Form/Form';
import { timeZoneMap } from './timeZone';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))

export const formatTime = (time) => {
    const timeSplit = time.split(":")
    return `${timeSplit[0]}:${timeSplit[1]}`
}

const UpdatePreferencesForm = (props) => {
    const { fetchPreferences, currentPrefs } = props
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [slotRange, setSlotRange] = useState("All Day")

    useEffect(() => {
        if (open) {
            setSlotRange(currentPrefs.slotRange)
        }
        else {
            setSlotRange("All Day")
        }
    }, [open])

    return (
        <React.Fragment>
            <CModal open={open} setOpen={setOpen}>
                <Container component="main" className={classes.formContainer}>
                    <Form
                        moreData={{
                            businessId
                        }}
                        title="Update Calendar Preferences"
                        cancelEnabled
                        cancelAction={() => { setOpen(false) }}
                        submitText="Update"
                        action={async (payload) => await updateCalendarPrefs({
                            businessId: payload.businessId,
                            timeZone: payload.timeZone,
                            slotRange: payload.slotRange,
                            slotMinTime: payload.slotRange === "Fixed" ? payload.slotMinTime : undefined,
                            slotMaxTime: payload.slotRange === "Fixed" ? payload.slotMaxTime : undefined,
                            slotDuration: payload.slotDuration
                        })}
                        postAction={(response) => {
                            if (response && !response.error) {
                                setOpen(false)
                                fetchPreferences()
                            }
                        }}
                        inputFields={[
                            {
                                type: "SingleSelect",
                                name: "timeZone",
                                label: "Time Zone",
                                validator: value => !value ? "Must select a time zone" : null,
                                choices: timeZoneMap.map(timeZone => [timeZone.value, timeZone.key]),
                                initialvalue: currentPrefs.timeZone,
                            },
                            {
                                type: "SingleSelect",
                                name: "slotDuration",
                                label: "Slot Duration",
                                validator: value => !value ? "Must select a duration" : null,
                                choices: [
                                    ["15 Minutes", 15],
                                    ["30 Minutes", 30],
                                    ["60 Minutes", 60],
                                ],
                                initialvalue: Number(currentPrefs.slotDuration.split(":")[1]),
                            },
                            {
                                type: "RadioSet",
                                name: "slotRange",
                                label: "Slot Range",
                                validator: [
                                    value => setSlotRange(value),
                                    value => !value ? "Must select a range" : null
                                ],
                                choices: [["All Day", "All Day"], ["Fixed", "Fixed"]],
                                initialvalue: currentPrefs.slotRange,
                            },
                            {
                                type: "TimeRange",
                                name: ["slotMinTime", "slotMaxTime"],
                                label: ["Start", "End"],
                                validator: [
                                    values => [validTime(values[0]), validTime(values[1])],
                                    validTimeRange
                                ],
                                enabled: slotRange === "Fixed",
                                initialvalue: currentPrefs.slotRange === "Fixed" ? [
                                    formatTime(currentPrefs.slotMinTime),
                                    formatTime(currentPrefs.slotMaxTime)
                                ] : undefined
                            },
                        ]}
                    />
                </Container>
            </CModal>
            <Button variant="outlined" onClick={() => setOpen(true)}>
                <Typography style={{ textTransform: 'none' }}>
                    Update
                </Typography>
            </Button>
        </React.Fragment>
    );
};

export default UpdatePreferencesForm