import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { addOnStatus, listAddOns, updateAddOn, deleteAddOn, withLoading } from "../../../../../../utils";
import {
  Grid, Button, CircularProgress,
  Menu, MenuItem, Container, useMediaQuery, Typography
} from "@material-ui/core";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import { sizing } from '@material-ui/system';
import { CEllipsisIcon } from "../../../../../Icons/Icons";
import moment from "moment";
import StatusTag from "./../../../../../TagComponents/StatusTag";
import Form from "../../../../../Form/Form";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import noWhiteSpace from "../../../../../../FormValidation/noWhiteSpace";
import CModal from "../../../../../Form/CModal";
import LightTooltip from "../../../../../Form/LightTooltip";
import CDisplayArea from "../../../../../Form/CDisplayArea";
import CurrencyPreProcessor from "../../../../../CurrencyFormatter/CurrencyFormatter";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px"
  },
  headingWrapper: {
    paddingBottom: "10px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  formOne: {
    fontSize: "20px",
    color: "#1a1f36",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
    paddingLeft: "10px",
  },
  fieldColumnOne: {
    width: "100px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
    paddingLeft: "5px"
  },
  sdCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "25%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px"
  }
}));

const AddOn = () => {
  const classes = useStyles();
  const history = useHistory();
  const [_, updateState] = useState(0);
  const dispatch = useDispatch();
  const small = useMediaQuery('(min-width: 750px)');
  const large = useMediaQuery('(min-width: 960px)');
  const addOnDetails = useSelector((state) => {
    return state.addOnsDetails;
  });
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const currencyInfo = useSelector((state) => {
    return state.buisnessDetails.operatingUnitInfo;
  });
  const [eOpen, setEOpen] = useState(false);
  const [dW, setDW] = useState(false);
  const [aDOpen, setADOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const handleClose = () => {
    setEOpen(false);
    setADOpen(false);
    setDW(false);
  };
  const handleMenuClose = () => {
    setAnchorEl(false);
  };

  async function handleSubmit(event) {
    dispatch({
      type: 'UPDATE', payload: {
        loading: true
      }
    });

    let success = await updateAddOn({
      businessId: businessId,
      addOnId: addOnDetails.addOnId,
      name: event.name,
      displayName: event.displayName,
      sellBy: event.sellBy,
      type: addOnDetails.type,
      description: event.description ? event.description : null,
      price: event.price,
      duration: addOnDetails.type == "Service" ? event.duration : null
    })
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
    if (success) {
      updateState((_) => !_);
      setEOpen(false);
    }
  };
  async function handleClick(event) {
    let success = await withLoading(addOnStatus, {
      businessId: businessId,
      addOnId: addOnDetails.addOnId,
      isActive: addOnDetails.isActive ? false : true,
    })
    if (success && !success.error) {
      setADOpen(false);
      updateState((_) => !_);

    }
  }

  const { addOnIdURL } = useParams();

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });

    let data = await listAddOns({
      businessId: businessId,
      addOnId: addOnIdURL,
    });

    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
        addOnsDetails: data ? data.addOnDetails[0] : null,
      },
    });
  }, [_]);

  if (addOnDetails && addOnDetails.addOnId == addOnIdURL) {
    return (
      <Grid container className={large ? classes.mainWrapper : classes.mobilePadding}>
        <React.Fragment>
          <CModal open={eOpen} setOpen={setEOpen}
            children={
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={handleSubmit}
                  postAction={null}
                  cancelEnabled
                  title="Edit Add On"
                  cancelAction={() => { setEOpen(false); }}
                  cancelText="Cancel"
                  submitText="Save"
                  inputFields={
                    [
                      {
                        type: "TextField",
                        validator: [notEmpty, noWhiteSpace],
                        initialvalue: addOnDetails.displayName,
                        label: "Name",
                        name: "name",
                      },
                      {
                        type: "TextField",
                        validator: [notEmpty, noWhiteSpace],
                        initialvalue: addOnDetails.name,
                        label: "Display Name",
                        name: "displayName",
                      },
                      {
                        type: "SingleSelect",
                        validator: [notEmpty, value => value == null ? "Must chose at least one selling basis" : null],
                        initialvalue: addOnDetails.sellBy,
                        label: "Selling Basis",
                        name: "sellBy",
                        choices: [
                          ["Per Session", "Per Session"],
                          ["Per Attendee", "Per Attendee"]
                        ]
                      },
                      {
                        type: "Currency",
                        validator: [notEmpty],
                        initialvalue: addOnDetails.price,
                        currencyInfo: currencyInfo,
                        label: "Price",
                        name: "price",
                      },
                      {
                        type: "Number",
                        validator: [notEmpty, value => value >= 5 ? null : "Duration must atleast be 5 minutes"],
                        initialvalue: addOnDetails.duration,
                        label: "Duration (min)",
                        name: "duration",
                        enabled: addOnDetails.type == "Service"
                      },
                      {
                        type: "TextArea",
                        name: "description",
                        initialvalue: addOnDetails.description,
                        label: "Description (optional)",
                        size: {
                          xs: 12
                        },
                        resizeEnabled: false,
                        moreProps: {
                          rowsMin: 4,
                          rowsMax: 10
                        }
                      }
                    ]
                  }
                >
                </Form>
              </Container>
            }
          />
          <GeneralDialog
            open={aDOpen}
            close={handleClose}
            title={addOnDetails.isActive ? "Deactivate Add On" : "Activate Add On"}
            text={addOnDetails.isActive ? "Are you sure you want to deactivate this add on?" : "Are you sure you want to activate this add on?"}
            action={handleClick}
          />
          <GeneralDialog
            open={deleteOpen}
            close={() => { setDeleteOpen(false) }}
            title="Delete Add On"
            text="Are you sure you want to delete this add on permanently?"
            action={async () => {
              let success = await withLoading(deleteAddOn, {
                businessId: businessId,
                addOnId: addOnDetails.addOnId
              })
              if (success && success.error == false) {
                history.replace(`/dashboard/${businessId}/Add-Ons`);
              }

            }}
          />
        </React.Fragment>
        <Grid container item xs={12} direction="row">
          <Grid item xs={6}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>ADD ON</span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.topBlock}>
              <span className={classes.rightElem}>
                <Button onClick={(event) => { setAnchorEl(event.currentTarget); }} className={classes.buttonBasic}><CEllipsisIcon /></Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  keepMounted
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => { setEOpen(true); handleMenuClose(); }}>Edit</MenuItem>
                  <MenuItem onClick={() => { setADOpen(true); handleMenuClose(); }}>{addOnDetails.isActive ? "Deactivate" : "Activate"}</MenuItem>
                  {!addOnDetails.isActive ?
                    <MenuItem onClick={() => { setDeleteOpen(true); setAnchorEl(false) }}>
                      <Typography >
                        Delete AddOn
                      </Typography>
                    </MenuItem> : null}
                </Menu>
              </span>
            </div>
          </Grid>
        </Grid>
        <Grid container item direction="row" xs={12} spacing={small ? 2 : 0} style={{ width: "100%" }}>
          <Grid item style={(small ? { maxWidth: "50%" } : { width: "100%" })}>
            <div className={classes.headerPadding} style={{ marginLeft: -10 }}>
              <LightTooltip title={addOnDetails.displayName} arrow>
                <Typography className={small ? classes.topHeadingOne : classes.mobileTopHeadingTwo} noWrap style={{ width: `calc(100%)` }}>
                  {addOnDetails.name}
                </Typography>
              </LightTooltip>
            </div>
          </Grid>
          <Grid item style={small ? { paddingLeft: "20px" } : { paddingLeft: "10px" }}>
            <div className={small ? classes.headerPadding : null}>
              <span className={small ? classes.topHeadingFour : null}>
                <StatusTag wrapperName={addOnDetails.isActive ? "greenWrapper" : "redWrapper"} value={addOnDetails.isActive ? "Active" : "Inactive"} />
              </span>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow} style={{ marginLeft: -10 }}>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <span className={classes.labelText}>Display Name</span>{" "}
              </div>
              <div className={classes.fieldColumnTwo}>
                <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", marginBottom: -5 }}>
                  {addOnDetails.displayName}
                </Typography>
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <span className={classes.labelText}>Type</span>{" "}
              </div>
              <div className={classes.fieldColumnTwo}>
                <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", marginBottom: -5 }}>
                  {addOnDetails.type}
                </Typography>
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne} style={{ width: "100px" }}>
                <span className={classes.labelText} style={{ whiteSpace: "normal" }}>Unit Of Measure</span>{" "}
              </div>
              <div className={classes.fieldColumnTwo}>
                <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", marginBottom: -5 }}>
                  {addOnDetails.uom}
                </Typography>
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <span className={classes.labelText}>Price</span>{" "}
              </div>
              <div className={classes.fieldColumnTwo}>
                <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", marginBottom: -5 }}>
                  <CurrencyPreProcessor amount={addOnDetails.price}
                    currencyBreak={currencyInfo.currencyBreak}
                    roundingFactor={currencyInfo.roundingFactor}
                    currencySymbol={currencyInfo.currencySymbol}
                  />
                </Typography>
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <span className={classes.labelText}>Sell Basis</span>{" "}
              </div>
              <div className={classes.fieldColumnTwo}>
                <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", marginBottom: -5 }}>
                  {addOnDetails.sellBy}
                </Typography>
              </div>
            </div>
            {addOnDetails.type == "Service" ?
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <span className={classes.labelText}>Duration</span>{" "}
                </div>
                <div className={classes.fieldColumnTwo}>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", marginBottom: -5 }}>
                    {`${addOnDetails.duration} mins`}
                  </Typography>
                </div>
              </div> : null}
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <span className={classes.labelText}>Options Using</span>{" "}
              </div>
              <div className={classes.fieldColumnTwo}>
                <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", marginBottom: -5 }}>
                  {addOnDetails.optionsUsing}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid container className={classes.headingWrapper} item xs={12}>
          <span className={classes.labelText}>Description</span>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow} style={{ paddingLeft: "10px" }}>
            <span className={classes.valueText}>
              <CDisplayArea
                value={addOnDetails.description ? addOnDetails.description : null}
                initialMaxHeight={"calc(100vh - 360px)"}
              />
            </span>{" "}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default AddOn;