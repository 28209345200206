import axios from "axios";
import { handleRefreshError, serverError, unauthorizedError, tokenExpired, refreshToken, forceClear } from "./utils";

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
    withCredentials: true,
    baseURL
});

api.interceptors.request.use(async (config) => {
    if (![undefined, null].includes(config.headers['x-auth']) && config.url !== '/api/users/refresh' && tokenExpired(config.headers['x-auth'])) {
        const newToken = await refreshToken()
        if (!newToken) {
            throw new axios.Cancel('Token was expired and could not get new token.');
        }
        else {
            config.headers['x-auth'] = newToken
            return config
        }
    }
    else {
        return config
    }
}, error => Promise.reject(error));


api.interceptors.response.use(
    response => {
        if (response.data && response.data.error) {
            let error = new Error();
            error.response = response;
            throw error;
        }
        else {
            return (response)
        }

    },
    error => {
        if (error.response) {
            const responseStatus = error.response.status
            if (error.response.config.url === '/api/users/logout') {
                return
            }
            else if (error.response.config.url === '/api/users/refresh') {
                handleRefreshError()
            }
            else if ([500, 422, 400].includes(responseStatus)) {
                serverError()
            }
            else if (responseStatus === 401) {
                unauthorizedError()
            }
            else {
                throw error;
            }
        }
        else {
            throw error;
        }

    }
);


export default api;