import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { retrieveGetParams, services, withLoading } from "../../../../../../utils";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Typography, useMediaQuery, Button } from "@material-ui/core";
import ListTable from "./../../../../../TableComponents/ListTable";
import AddServiceForm from "./AddServiceForm";
import StatusTag from "../../../../../TagComponents/StatusTag";
import ListCard from "../../../../../MobileCards/ListCard";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import PostFilter, { applyFilters } from "../../../../../Filter/PostFilter";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  buttonBasic: {
    fontSize: "14px",
    marginLeft: '10px',
    textTransform: "none",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
}));

const servicesHeaders = [
  {
    name: "status",
    columnKey: "status",
    columnWidth: "100px",
    type: "Normal"
  },
  {
    name: "name",
    columnKey: "name",
    columnWidth: "30%",
    tooltip: true
  },
  {
    name: "category",
    columnKey: "categoryName",
    columnWidth: "30%",
    tooltip: true
  },
  {
    name: "type",
    columnKey: "type",
    columnWidth: "100px",
  },
  {
    name: "duration",
    columnKey: "durationTime",
    columnWidth: "100px",
  },
  {
    name: "mode",
    columnKey: "remote",
    columnWidth: "100px",
  }
];
const keyObjects = [
  {
    key: 'isActive',
    label: 'Status',
    keyLabel: 'statusLabel'
  },
  {
    key: 'categoryName',
    label: 'Category',
    keyLabel: 'categoryName'
  },
  {
    key: 'type',
    label: 'Session Type',
    keyLabel: 'type'
  },
  {
    key: 'isVirtual',
    label: 'Session Mode',
    keyLabel: 'modeLabel'
  },
]

const processServiceDataFilter = (serviceData) => {
  return serviceData.map(service => ({
    ...service,
    statusLabel: service.isActive ? 'Active' : 'Not Active',
    modeLabel: service.isVirtual ? 'Virtual' : 'In Person'
  }))
};


const processServicesData = (serviceData) => {
  return serviceData.map((service) => {
    let {
      serviceId,
      name,
      isActive,
      isVirtual,
      duration,
      type,
      categoryName
    } = service;

    let remote = null;
    if (isVirtual) {
      remote = "Virtual";
    } else {
      remote = "In Person";
    }
    let status = <StatusTag wrapperName={isActive ? "greenWrapper" : "redWrapper"} value={isActive ? "Active" : "Inactive"} />;
    let durationTime = `${duration} minutes`;
    let id = serviceId;

    return {
      id,
      status,
      name,
      type,
      remote,
      categoryName,
      durationTime,
      isActive,
      isVirtual
    };
  });
};
const ServicesList = (props) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const [filterList, setFilterList] = useState(null);
  const [servicesList, setServicesList] = useState(null);
  const bigEnough = useMediaQuery("(min-width:750px)")
  const location = useLocation()
  const queryParams = retrieveGetParams(location)
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const getServicesDetails = async () => {
    let servicesPayload = await services({
      businessId: businessId,
    });

    if (servicesPayload && servicesPayload.services) {
      setFilterList(processServiceDataFilter(servicesPayload.services))
      setServicesList(processServicesData(servicesPayload.services));
    };

  };

  const navigateToService = (serviceId) => {
    history.push(`${props.basePath}/${serviceId}`);
  };

  useEffect(async () => {
    await withLoading(getServicesDetails, {}, true)
  }, []);



  const ViewChanger = () => {
    if (bigEnough) {
      return (<React.Fragment>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <ListTable
              identifier={"services"}
              tableHeaders={servicesHeaders}
              tableRows={applyFilters(servicesList, queryParams, keyObjects)}
              rowClick={navigateToService}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </React.Fragment>)
    }
    else {
      return (<Grid item xs={12} style={{ paddingTop: "10px" }}>
        {applyFilters(servicesList, queryParams, keyObjects).map((service) => {
          return (
            <ListCard
              key={service.serviceId}
              action={() => { history.push(`${props.basePath}/${service.id}`); }}
              cardName={service.name}
              statusReplace={
                <Typography
                  noWrap
                  style={!service.isActive ? { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "red" } : { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "green" }}>
                  {service.isActive ? "Active" : "Inactive"}
                </Typography>
              }
              other={service.categoryName} />
          )
        })}
      </Grid>)
    }

  }


  if (servicesList === null) {
    return (null)
  }
  else {
    return (
      <React.Fragment>
        <AddServiceForm open={open} setOpen={setOpen} basePath={props.basePath} />
        <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
          <Grid item xs={12}>
            <div className={classes.headerPadding}>
              <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Services</span>

              <span title="Add Service" className={classes.rightElem}>
                <Button
                  className={classes.buttonBasic}
                  onClick={() => { setOpen(true); }}
                  color="primary"
                  variant="contained"
                >
                  Add
                </Button>
              </span>
              <PostFilter allEntries={filterList} keyObjects={keyObjects} />

            </div>
          </Grid>
          {(Array.isArray(servicesList) && servicesList.length > 0 ?
            <ViewChanger />
            :
            <EmptyListImage name="Services" />)}
        </Grid>
      </React.Fragment>
    );
  }

};

export default ServicesList;