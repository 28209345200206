import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import PricingGroupDetails from "./PricingGroupDetails";
import PricingGroupsList from "./PricingGroupList";

const PricingGroupsHandler = () => {
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  // const basePath = `/dashboard/${businessId}/PricingGroups`;
  return (
    <React.Fragment>
      <Switch>
        <Route
          path={`/dashboard/${businessId}/Price-Groups`}
          exact
          render={() => {
            return <PricingGroupsList />;
          }}
        />
        <Route
          path={`/dashboard/${businessId}/Price-Groups/:pricingGroupIdURL`}
          exact
          render={() => {
            return <PricingGroupDetails />;
          }}
        />
        <Route
          path="*"
          render={() => {
            return <Redirect to="/404" />;
          }}
        />
      </Switch>
    </React.Fragment>
  );
};

export default PricingGroupsHandler;
