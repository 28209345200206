import { editFSCap, withLoading } from "../../../../../../utils";
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";
import { Container } from "@material-ui/core";
const Capacity = (props) => {
    const { businessId, optionDetails, refresh, open, setOpen, classes } = props

    const handleSubmit = async (payload) => {
        const response = await withLoading(editFSCap, payload);
        if (response && !response.error) {
            setOpen(false)
            refresh()
        }
    }


    return (

            <CModal
                open={open}
                setOpen={setOpen}
            >
                <Container className={classes.formContainer}>
                    <Form
                        title="Change Capacity For All Classes"
                        moreData={{ businessId: businessId, optionId: optionDetails.optionId }}
                        action={handleSubmit}
                        cancelEnabled
                        cancelAction={() => { setOpen(false) }}
                        inputFields={[
                            {
                                type: "Number",
                                label: "New Capacity",
                                name: "totalSeats",
                                validator: [
                                    value => !value || value === 0 ? 'Please enter a valid capacity' : null,
                                ]
                            }
                        ]}
                    />
                </Container>
            </CModal>

    )
}

export default Capacity