import React, { useState, useEffect } from 'react'
import CModal from '../../../../../Form/CModal'
import Form from '../../../../../Form/Form'
import CTitle from '../../../../../Form/CTitle'
import { Container, Grid, Typography } from '@material-ui/core'
import { withLoading, adminPackSearch, scheduleClass } from '../../../../../../utils'
import TwoColumn from '../../../../../TwoColumn/TwoColumn'
import CButtonPair from '../../../../../Form/CButtonPair'
import CModalError from '../../../../../Form/CModalError'
const CheckInForm = (props) => {
    const { open, setOpen, classes, businessId, classId, fetch } = props;
    const [customer, setCustomer] = useState(null)

    const retrievePackDetails = async (payload) => {
        let data = await withLoading(adminPackSearch, payload)
        if (data && data.packDetails) {
            setCustomer(data.packDetails)
        }
    }

    const handleSubmit = async () => {
        let data = await withLoading(scheduleClass,
            {
                businessId: businessId,
                packId: customer.packId,
                classId: classId
            })
        if (data) {
            fetch()
            setOpen(false)
        }
    }


    useEffect(() => {
        if (open === true) {
            setCustomer(null)
        }
    }, [open])
    return (
        <CModal open={open} setOpen={setOpen}>
            <Container className={classes.formContainer}>
                {
                    !customer ?
                        <Form
                            title="Customer Check-In"
                            moreData={{ businessId, classId }}
                            action={retrievePackDetails}
                            cancelEnabled
                            cancelAction={() => { setOpen(false) }}
                            inputFields={[
                                {
                                    type: "TextField",
                                    label: "Customer Pack Number",
                                    name: "packNumber",
                                }
                            ]}
                        />
                        :
                        <Grid container spacing={2}>
                            <CTitle title="Admit Customer to Group Class" />
                            <CModalError/>
                            <Grid item xs={12}>
                                <TwoColumn
                                    first={[
                                        <Typography key="Pack Number left" gutterBottom>
                                            Pack Number
                                        </Typography>,
                                        <Typography key="Attendee Name left" gutterBottom>
                                            Attendee Name
                                        </Typography>,
                                        <Typography key="Pack Validity left" gutterBottom>
                                            Pack Validity
                                        </Typography>,
                                        <Typography key="Service Name left" gutterBottom>
                                            Service Name
                                        </Typography>,
                                    ]}
                                    extProps={{
                                        justifyContent: "space-evenly"
                                    }}
                                    second={[
                                        <Typography key="Pack Number right" gutterBottom>
                                            {customer.packNumber}
                                        </Typography>,
                                        <Typography key="Attendee Name right" gutterBottom>
                                            {`${customer.attendeeFirstName} ${customer.attendeeLastName}`}
                                        </Typography>,
                                        <Typography key="Pack Validity right" gutterBottom noWrap>
                                            {`${customer.validityStart} - ${customer.validityEnd}`}
                                        </Typography>,
                                        <Typography key="Service Name right" gutterBottom>
                                            {customer.serviceName}
                                        </Typography>,
                                    ]}
                                />

                            </Grid>
                            <CButtonPair
                                action={handleSubmit}
                                cancelEnabled
                                cancelAction={() => { setOpen(false) }}
                                errors={[]}
                            />
                        </Grid>

                }
            </Container>
        </CModal>
    )
}

export default CheckInForm