import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { adminReviewDetails, withLoading } from "../../../../../../utils";
import {
  Grid, Button,
  Checkbox, GridList, GridListTile,
  Typography, Avatar
} from "@material-ui/core";

import moment from 'moment'
import { useMediaQuery } from "@material-ui/core";
import ListTable from "../../../../../TableComponents/ListTable";
import LightTooltip from "../../../../../Form/LightTooltip";

import CDisplayArea from "../../../../../Form/CDisplayArea"
import Rating from "@material-ui/lab/Rating";
import { ReactReduxContext } from "react-redux";
const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
    maxWidth: 200,
    objectFit: "cover"
  },
  avatar: {
    height: theme.spacing(20),
    width: theme.spacing(20),
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px"
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none"
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
    maxWidth: "150px"
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px"
  },
  fieldColumnOne: {
    width: "80px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
    maxWidth:"calc(100% - 90px)"
  },
  sdCustomCellOne: {
    border: "none",
    width: "5%",
    maxWidth: "200px",
    padding: "0px",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingThree: {
    fontSize: "20px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "10%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "85%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },

}));

const Review = () => {
  const classes = useStyles();
  const bigEnough = useMediaQuery("(min-width: 750px)")
  const matches = useMediaQuery('(min-width: 1000px)')
  const [reviewDetails, setReviewDetails] = useState(null)
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const { reviewIdURL } = useParams();

  useEffect(async () => {

    let data = await withLoading(adminReviewDetails, {
      businessId: businessId,
      reviewId: reviewIdURL
    }, true)

    if (data && data.reviewDetails) {
      setReviewDetails(data.reviewDetails)
    }
  }, []);

  if (reviewDetails) {
    return (
      <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
        <Grid item xs={12}>
          <div className={classes.topBlock}>
            <span className={classes.topHeading}>REVIEWS</span>
          </div>
        </Grid>
        <Grid item style={(bigEnough ? { maxWidth: "50%" } : { width: "100%" })}>
          <div className={classes.headerPadding} style={{paddingLeft:"0px"}}>
            <LightTooltip title={`${reviewDetails.reviewerFirstName} ${reviewDetails.reviewerLastName}`} arrow>
              <Typography className={(bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne)} noWrap>
                {`${reviewDetails.reviewerFirstName} ${reviewDetails.reviewerLastName}`}
              </Typography>
            </LightTooltip>
          </div>
        </Grid>
        {(bigEnough ?
          <Grid item>
            <div className={classes.headerPadding} style={{ paddingLeft: "20px" }}>
              <span className={classes.topHeadingFour}>
                <Rating value={reviewDetails.rating} readOnly />
              </span>
            </div>
          </Grid>
          :
          <Grid item>
            <div style={{ paddingLeft: "10px" }}>
              <Rating value={reviewDetails.rating} readOnly />
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <div
              className={
                matches ? classes.contentColumnFirst : classes.fieldRows
              }
            >
              <div
                className={
                  matches ? classes.blockLabel : classes.fieldColumnOne
                }
              >
                <Typography className={classes.labelText}>Type</Typography>
              </div>
              <div
                className={matches ? classes.blockText : classes.fieldColumnTwo}
                style={matches ? null : { paddingLeft: "10px" }}
              >
                <Typography className={classes.valueText} noWrap>
                  {reviewDetails.reviewType}
                </Typography>
              </div>
            </div>
            <div
              className={
                matches ? classes.contentColumnMiddle : classes.fieldRows
              }
            >
              <div
                className={
                  matches ? classes.blockLabel : classes.fieldColumnOne
                }
              >
                <Typography className={classes.labelText}>Service</Typography>
              </div>
              <div
                className={matches ? classes.blockText : classes.fieldColumnTwo}
                style={matches ? null : { paddingLeft: "10px" }}
              >
                <Link
                  to={`/dashboard/${businessId}/Services/${reviewDetails.serviceId}`}
                  className={classes.valueTextLink}
                >
                    <Typography className={classes.valueTextLink} noWrap>
                      {reviewDetails.serviceName}
                    </Typography>

                </Link>
              </div>
            </div>
            <div
              className={
                matches ? classes.contentColumnMiddle : classes.fieldRows
              }
            >
              <div
                className={
                  matches ? classes.blockLabel : classes.fieldColumnOne
                }
              >
                <Typography className={classes.labelText}>Session</Typography>
              </div>
              <div
                className={matches ? classes.blockText : classes.fieldColumnTwo}
                style={matches ? null : { paddingLeft: "10px" }}
              >
                <Link
                  to={`/dashboard/${businessId}/Sessions/${reviewDetails.sessionId}`}
                  className={classes.valueTextLink}
                >
                    <Typography className={classes.valueTextLink} noWrap>
                      {reviewDetails.sessionNumber}
                    </Typography>

                </Link>
              </div>
            </div>
            <div
              className={
                matches ? classes.contentColumnLast : classes.fieldRows
              }
            >
              <div
                className={
                  matches ? classes.blockLabel : classes.fieldColumnOne
                }
              >
                <Typography className={classes.labelText}>
                  Date
                </Typography>
              </div>
              <div
                className={matches ? classes.blockText : classes.fieldColumnTwo}
                style={matches ? null : { paddingLeft: "10px" }}
              >
                <Typography className={classes.valueText}>
                  {moment(reviewDetails.reviewDate).format("MMM, Do, YYYY")}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper} style={{ paddingTop: 20 }}>
            <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Comments</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <CDisplayArea
              value={reviewDetails.comments}
              initialMaxHeight={"calc(400px)"}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Host(s)</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            {(
              reviewDetails.reviewType === "Group" ?
                <ListTable
                  styling={{ width: "100%", maxHeight: 250 }}
                  identifier={"hosts"}
                  tableHeaders={[{ name: "Name", columnKey: "name", columnWidth: "100%", type: "Normal" }]}
                  tableRows={reviewDetails.hostDetails.map((host) => {
                    return ({
                      name: (
                        <Link className={classes.valueTextLink} to={`/dashboard/${businessId}/Staffs/${host.hostStaffId}`}>
                          <Typography noWrap className={classes.valueTextLink}>
                            {`${host.hostFirstName} ${host.hostLastName}`}
                          </Typography>
                        </Link>
                      ),
                    })
                  })}
                  rowClick={null}
                />
                :
                reviewDetails.reviewType === "Private" ?
                  <React.Fragment>
                    <div className={classes.fieldRows}>
                      <div className={classes.fieldColumnOne} style={{ paddingBottom: "5px" }}>
                        <Typography noWrap className={classes.labelText}>Name</Typography>
                      </div>
                      <div className={classes.fieldColumnTwo} style={{ paddingLeft: "10px" }}>
                        <Link to={`/dashboard/${businessId}/Staffs/${reviewDetails.staffId}`} className={classes.valueTextLink}>
                          <Typography noWrap className={classes.valueTextLink}>
                            {`${reviewDetails.staffFirstName} ${reviewDetails.staffLastName}`}
                          </Typography>
                        </Link>
                      </div>
                    </div>
                  </React.Fragment>
                  : null
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return (null);
  }
};

export default Review;
