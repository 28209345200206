import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const CModal = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.open) {
      dispatch({
        type: "UPDATE",
        payload: {
          insideModal: true
        }
      })
    }
    else if (!props.open) {
      dispatch({
        type: "UPDATE",
        payload: {
          insideModal: false
        }
      })
    }

    return (() => {
      dispatch({
        type: "UPDATE",
        payload: {
          insideModal: false
        }
      })
    })
  }, [props.open, dispatch])

  const classes = useStyles()

  return (
    <Modal
      // disableAutoFocus={true}
      keepMounted={props.keepMounted}
      className={classes.modal}
      open={props.open}
      style={{
        height: "100%",
        width: "100%",
        resizeMode: "cover",
        objectFit: "cover"
      }}
      onClose={() => { props.setOpen(false) }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div style={{ outline: 'none' }}>
          {props.children}
        </div>
      </Fade>
    </Modal>
  )
}

export default CModal