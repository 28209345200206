import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { customerPackages } from "../../../../utils";
import {Grid, Typography, CircularProgress, useMediaQuery} from "@material-ui/core";
import ListTable from "./../../../TableComponents/ListTable";
import ListTableNavigation from "./../../../TableComponents/ListTableNavigation";
import { useHistory } from "react-router-dom";
import EmptyListImage from "../../../EmptyListImage/Empty";
import ListCard from "../../../MobileCards/ListCard";
import StatusTag from "../../../TagComponents/StatusTag";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingTop:"10px",
    backgroundColor: "#ffffff"
  },
  loaderMain : {
    position : "absolute",
    top : "50%",
    left: "50%"
  }
}));

const PackageList = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width: 750px)');

  const packHeaders = [
    {
      name: "pack/pass #",
      columnKey: "packNumber",
      columnWidth: "100px",
      type:"Normal"
    },
    {
      name: "expires",
      columnKey: "validityEnd",
      columnWidth: "15%",
      tooltip:true
    },
    {
      name: "attendee",
      columnKey: "attendeeName",
      columnWidth: "20%",
      tooltip:true
    },
    {
      name: "service",
      columnKey: "serviceName",
      columnWidth: "20%",
      tooltip:true
    },
    {
      name: "pricing option",
      columnKey: "optionName",
      columnWidth: "25%",
      tooltip:true
    },
    {
      name: "sessions",
      columnKey: "sessionCount",
      columnWidth: "75px",
    },
    {
      name: " ",
      columnKey: "canceled",
      columnWidth: "100px",
    },
  ];

  const [packList, setPackList] = useState([]);
  const [initialState, setInitialState] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  const processPackData = (packData) => {
    return packData.map((pack) => {
      let {
        packId,
        packNumber,
        validityEnd,
        attendeeFirstName,
        attendeeLastName,
        serviceName,
        optionName,
        isCanceled,
        sessionCount,
      } = pack;

      let id = packId;
      let attendeeName = `${attendeeFirstName} ${attendeeLastName}`;
      let canceled = null;
      if(isCanceled) {
        canceled = <StatusTag wrapperName="redWrapper" value="Canceled" />;
      }
      
      return {
        id,
        packNumber,
        validityEnd,
        isCanceled,
        canceled,
        attendeeName,
        serviceName,
        optionName,
        sessionCount,
      };
    });
  };

  const getPacksDetails = async () => {
    let packsPayload = await customerPackages({
      limit: currentLimit,
      page: currentPage,
    });
    if (packsPayload && packsPayload.packs) {
      setPackList(processPackData(packsPayload.packs.docs));
      setTotalPages(packsPayload.packs.totalPages);
      setTotalDocs(packsPayload.packs.totalDocs);
      setHasNext(packsPayload.packs.hasNextPage);
      setHasPrevious(packsPayload.packs.hasPrevPage);
      setInitialState(true);
    }
  };

  const getNextPage = () => {
    if (hasNext) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPrevPage = () => {
    if (hasPrevious) {
      setCurrentPage(currentPage - 1);
    }
  };

  const navigateToPack = (packId) => {
    history.push(`/account/Packages-and-Passes/${packId}`);
  };

  const ViewChanger = () => {
    if(matches) {
      return(
        <ListTable
          identifier={"admin_orders"}
          tableHeaders={packHeaders}
          tableRows={packList}
          rowClick={navigateToPack}
        />
      );
    } else {
      return(
        packList.map((mobilePack) => {
        return(
          <ListCard 
            action = {() => {history.push(`/account/Packages-and-Passes/${mobilePack.id}`);}} 
            cardName={mobilePack.attendeeName} 
            statusTagValue = {mobilePack.isCanceled ? !mobilePack.isCanceled : null}
            inactiveValue = "Canceled"
            statusReplace={mobilePack.optionName} 
            other={`#${mobilePack.packNumber}`} 
          />
        )})
      );
    };
  };

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    await getPacksDetails();
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
  }, [currentPage, currentLimit]);

  if(initialState === null) {
    return(
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain}/>
          </div>
        </Grid>
      </Grid>
    );
  } else {
  return (
    <Grid container className={matches ? classes.mainWrapper : classes.mobilePadding}>
      <Grid item xs={12} className={matches ? null : classes.stickyHeader}>
        <div className={classes.headerPadding}>
          <span className={matches ? classes.topHeading : classes.mobileTopHeadingOne}>Packs and Season Passes</span>
        </div>
      </Grid>
      {packList.length > 0 ? 
        <Grid item xs={12}>
          <div className={classes.contentRow}>
          <ViewChanger />
          </div>
        </Grid> : <EmptyListImage name = "Packages"/>}
      {packList.length > 0 ? 
        <Grid item xs={12}>
        <ListTableNavigation
            currentPage={currentPage}
            currentLimit={currentLimit}
            totalDocs={totalDocs}
            tableRows={packList}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            getPrevPage={getPrevPage}
            getNextPage={getNextPage}
          />
        </Grid> : null}
      <Grid item xs={12}>
        <div className={classes.bottomSpacer}></div>
      </Grid>
    </Grid>
  );
  }
};

export default PackageList;
