import React, { useEffect } from 'react'
import NumberFormat from 'react-number-format';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    comp:
    {
        width: "100%"
    },
    formLabel: {
        fontSize: 14,
        color: "#3C4257"
    },
    fieldText: {
        fontSize: 16,
        color: "#1A1F36",
        padding: "10px"
    },
    helperText: {
        marginRight: 14,
        marginLeft: 14
    }
}));
function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            thousandSeparator={false}
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isNumericString
        />
    );
}

const CPhoneNumber = (props) => {
    const classes = useStyles()
    const intoInputProps = (props.moreProps && props.moreProps.InputProps ? props.moreProps.InputProps : {})
    intoInputProps.classes = { input: classes.fieldText };
    intoInputProps.inputComponent = NumberFormatCustom
    const otherProps = { ...props.moreProps, InputProps: intoInputProps }
    return (
        <div className={classes.comp}>
            <Typography className={classes.formLabel}>
                {props.label}
            </Typography>
            <TextField
                autoComplete={(props.autoComplete === "on" ? "on" : "off")}
                error={(typeof props.errortext == "string" && props.errortext !== "" ? true : false)}
                className={classes.comp}
                onChange={props.onchange}
                defaultValue={props.initialvalue?props.initialvalue.substr(2):undefined}
                helperText={(props.errortext !== "" ? props.errortext : null)}
                variant="outlined"
                name={props.name}
                {...otherProps}
            />

        </div>
    )
}

export default CPhoneNumber