import React from "react";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

export const CAddIcon = () => {
    return(
        <AddIcon style={{color: "green"}} />
    );
};

export const CCloseIcon = () => {
    return(
        <CloseIcon style={{color:"red"}} />
    );
};

export const CEllipsisIcon = () => {
    return(
        <MoreHorizIcon style={{color: "#0079fc"}} />
    );
};