import { Container, MenuItem, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import positive from "../../../../../../FormValidation/positive";
import validDates from "../../../../../../FormValidation/validDates";
import { validTime } from "../../../../../../FormValidation/validTime";
import {
  createGroupSessions,
  generateGroupSessions,
  getGenericDetails,
} from "../../../../../../utils";
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    width: "100vw",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflowY: "auto",
  },
}));

const AddGroupClassForm = (props) => {
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const [isVirtual, setIsVirtual] = useState(null);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [originalPayload, setOriginalPayload] = useState(null);
  const [siteOptions, setSiteOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [resourceOptions, setResourceOptions] = useState([]);
  const [allResources, setAllResources] = useState([]);
  const [next, setNext] = useState(null);
  const [open, setOpen] = useState(false)
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (open === true) {
      setUp();
    }
    else {
      setNext(null);
      setOriginalPayload(null);
      setIsVirtual(null);
      setServiceOptions([]);
      setSiteOptions([]);
      setStaffOptions([]);
      setResourceOptions([]);
      setAllResources([]);
    }
  }, [open]);

  const setUp = async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let data = await getGenericDetails({
      businessId: businessId,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (data && data.genericDetails) {
      let tempServList = [];
      let tempSiteList = [];
      let tempStaffList = [];
      let tempResourceList = [];
      data.genericDetails.serviceDetails.forEach((service) => {
        if (service.type === "Group" && service.isActive) {
          tempServList.push([
            service.name,
            service.serviceId,
            service.isVirtual,
          ]);
        }
      });
      data.genericDetails.siteDetails.forEach((site) => {
        if (site.isActive) {
          tempSiteList.push([site.name, site.siteId]);
        }
      });
      data.genericDetails.staffDetails.forEach((staff) => {
        if (staff.isActive) {
          tempStaffList.push([
            `${staff.firstName} ${staff.lastName}`,
            staff.staffId,
          ]);
        }
      });
      setServiceOptions(tempServList);
      setSiteOptions(tempSiteList);
      setStaffOptions(tempStaffList);
      setResourceOptions(tempResourceList);
      setAllResources(data.genericDetails.resourceDetails);
    }
  };

  if (siteOptions === null || serviceOptions === null) {
    return null;
  } else {
    return (
      <React.Fragment>
        <CModal open={open} setOpen={setOpen}>
          <Container component="main" className={classes.formContainer}>
            {next === null || originalPayload === null ? (
              <Form
                key="first"
                moreData={{ businessId: businessId }}
                title="Schedule Group Classes"
                cancelEnabled
                cancelAction={() => {
                  setOpen(false);
                }}
                submitText="Next"
                action={async (payload) => {
                  setOriginalPayload(payload);
                  setIsVirtual(
                    serviceOptions.some((service) => {
                      return service[1] === payload.serviceId && service[2];
                    })
                  );
                  setResourceOptions(
                    allResources
                      .filter((resource) => {
                        return (
                          resource.siteId === payload.siteId &&
                          resource.isActive === true
                        );
                      })
                      .map((resource) => {
                        return [`${resource.name}${resource.description ? ' - ' + resource.description : ""}`, resource.resourceId];
                      })
                  );
                  return await generateGroupSessions(payload);
                }}
                postAction={(data) => {
                  if (data) {
                    setNext(data);
                  }
                }}
                inputFields={[
                  {
                    type: "SingleSelect",
                    name: "serviceId",
                    label: "Service",
                    validator: (value) =>
                      !value ? "A service must be selected" : null,
                    choices: serviceOptions,
                    size: {
                      xs: 12,
                      sm: 6,
                    },
                  },
                  {
                    type: "SingleSelect",
                    name: "siteId",
                    label: "Site",
                    validator: (value) =>
                      !value ? "A site must be selected" : null,
                    choices: siteOptions,
                    size: {
                      xs: 12,
                      sm: 6,
                    },
                  },

                  {
                    type: "DateRange",
                    name: ["startDate", "endDate"],
                    label: ["Start Date", "End Date"],
                    validator: validDates,
                  },
                  {
                    type: "Time",
                    name: "startTime",
                    label: "Start Time",
                    validator: validTime,
                    size: {
                      xs: 12,
                      sm: 6,
                    },
                  },
                  {
                    type: "DaysPicker",
                    name: "dayObj",
                    label: "Days",
                    validator: (value) => {
                      const allDays = [
                        "sun",
                        "mon",
                        "tue",
                        "wed",
                        "thr",
                        "fri",
                        "sat",
                      ];
                      return allDays.every((day) => value[day] === false)
                        ? "A day must be selected"
                        : null;
                    },
                    size: {
                      xs: 12,
                      sm: 6,
                    },
                  },
                ]}
              />
            ) : (
              <Form
                key="second"
                moreData={{
                  businessId: businessId,
                  serviceId: originalPayload.serviceId,
                  siteId: originalPayload.siteId,
                  startTime: originalPayload.startTime,
                }}
                title="Schedule Group Classes"
                cancelEnabled
                cancelAction={() => {
                  setOpen(false);
                }}
                submitText="Create Classes"
                action={createGroupSessions}
                postAction={(data) => {
                  if (data) {
                    setOpen(false);
                    props.refresh();
                  }
                }}
                inputFields={[
                  {
                    type: "MultiSelect",
                    name: "dateRefList",
                    label: "Group Classes",
                    initialvalue: next.map((record) => {
                      return record.dateRef;
                    }),
                    choices: next.map((record) => {
                      return [record.dateStr, record.dateRef];
                    }),
                    validator: (value) =>
                      value === [] ? "Must chose at least one session" : null,
                  },
                  {
                    type: "MultiSelect",
                    name: "hostIds",
                    label: "Hosts",
                    choices: staffOptions,
                    validator: (value) =>
                      value === [] ? "Must chose at least one staff" : null,
                  },
                  {
                    type: "MultiSelect",
                    name: "resourceIds",
                    label: "Resources",
                    choices: resourceOptions,
                    validator: (value) =>
                      value === [] ? "Must chose at least one resource" : null,
                  },
                  {
                    type: "Number",
                    name: "totalSeats",
                    label: "Capacity",
                    validator: (value) => {
                      if (value !== "") {
                        return positive(value);
                      }
                    },
                    numberProps: {
                      decimalScale: 0,
                    },
                  },
                  isVirtual
                    ? {
                      type: "TextArea",
                      name: "onlineConferenceInfo",
                      label: "Web Conference Information",
                      moreProps: {
                        rowsMin: 4,
                      },
                    }
                    : {},
                ]}
              />
            )}
          </Container>
        </CModal>
        <MenuItem onClick={() => setOpen(true)}>
          <Typography>
            Add
          </Typography>
        </MenuItem>
      </React.Fragment>
    );
  }
};

export default AddGroupClassForm;
