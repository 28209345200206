import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import CModal from "../Form/CModal";
import Form from "../Form/Form";
import { refundTransaction } from "../../utils";
import CurrencyPreProcessor from "../CurrencyFormatter/CurrencyFormatter";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
    width: "100vw",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  buttonText: {
    textTransform: "none",
    fontSize: "14px",
  },
  button: {
    float: "right",
  },
}));

const RefundTransaction = (props) => {
  const classes = useStyles();
  const { transaction, orderDetails, businessId, refresh } = props;
  const [open, setOpen] = useState(false);
  const [refundType, setRefundType] = useState(null);
  const [showRefundTo, setShowRefundTo] = useState(true);
  const [applicableToServiceType, setApplicableToServiceType] = useState("All");

  const reload = (response) => {
    if (response && !response.error) {
      setOpen(false);
      refresh();
    }
  };

  useEffect(() => {
    if (transaction.transactionSubType == "creditCharge") {
      setRefundType("Credit");
      setShowRefundTo(false);
    }
  }, []);

  return (
    <React.Fragment>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Typography className={classes.buttonText}>{refundType === "Credit" ? "Refund Credit" : "Make Refund"}</Typography>
      </Button>
      <CModal open={open} setOpen={setOpen}>
        <Container className={classes.formContainer}>
          <Form
            moreData={{
              businessId: businessId,
              orderId: orderDetails.orderId,
              transactionSequence: transaction.transactionSequence,
              refundTo: "Credit"
            }}
            title={refundType === "Credit" ? "Refund Credit" : "Refund Transaction"}
            cancelEnabled
            cancelAction={() => {
              setOpen(false);
            }}
            submitText="Refund"
            action={refundTransaction}
            postAction={reload}
            inputFields={[
              {
                type: "Currency",
                name: "refundAmount",
                label: (refundType === "Credit" ? "Refund Credit Amount" : "Refund Amount"),
                currencyInfo: orderDetails.currencyInfo,
                validator: [
                  (value) =>
                    value <= 0
                      ? "Refund amount must be greater than zero"
                      : null,
                  (value) =>
                    value + transaction.refundAmount > transaction.amount
                      ? `Maximum refund amount is ${CurrencyPreProcessor({
                          amount: transaction.amount - transaction.refundAmount,
                          ...orderDetails.currencyInfo,
                        })}`
                      : null,
                ],
              },
              {
                type: "RadioSet",
                name: "refundTo",
                label: "Refund To",
                choices: [
                  ["Original Card", "Card"],
                  ["As Credit", "Credit"],
                ],
                validator: (value) => {
                  setRefundType(value);
                  if (!value) {
                    return "Refund to must be selected";
                  }
                },
                enabled: showRefundTo === true,
              },
              {
                type: "SingleSelect",
                name: "applicableToServiceType",
                label: "Credit restrictions",
                choices: [
                  ["All Services", "All"],
                  ["Only Private Services", "Private"],
                  ["Only Group Services", "Group"],
                ],
                enabled: refundType === "Credit",
                initialvalue: "All",
                validator: (value) => {
                  setApplicableToServiceType(value);
                },
              },
            ]}
          />
        </Container>
      </CModal>
    </React.Fragment>
  );
};

export default RefundTransaction;
