import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputMask from 'react-input-mask'
import { fromArmyTime } from '../../FormValidation/validTime';

const useStyles = makeStyles((theme) => ({
    comp: {
        width: "100%",
        float: "left"
    },
    formLabel: {
        fontSize: 14,
        color: "#3C4257"
    },
    fieldText: {
        fontSize: 14,
        color: "#1A1F36",
        padding: "10px"
    }
}));

const getFromArray = (error, index) => {
    if (Array.isArray(error)) {
        return (error[index])
    }
}


const CTimeRange = (props) => {
    useEffect(() => {
        props.updateGlob()
        if (Array.isArray(props.initialvalue)) {
            props.updateGlob(fromArmyTime(props.initialvalue[0]), 0)
            props.updateGlob(fromArmyTime(props.initialvalue[1]), 1)
        }
    }, [])
    const classes = useStyles();
    const intoInputProps = (props.moreProps && props.moreProps.InputProps ? props.moreProps.InputProps : {})
    intoInputProps.classes = { input: classes.fieldText };
    const otherProps = { ...props.moreProps, InputProps: intoInputProps }
    return (
        <Grid container spacing={3}>
            <Grid item xs={6} >
                <Typography className={classes.formLabel}>
                    {props.label[0]}
                </Typography>

                <InputMask
                    error={(typeof getFromArray(props.errortext, 0) == "string" && getFromArray(props.errortext, 0) !== "" ? true : false)}
                    helperText={(typeof getFromArray(props.errortext, 0) == "string" && getFromArray(props.errortext, 0) !== "" ? getFromArray(props.errortext, 0) : null)}
                    defaultValue={(typeof getFromArray(props.initialvalue, 0) == "string" && getFromArray(props.initialvalue, 0) !== "" ? fromArmyTime(getFromArray(props.initialvalue, 0)) : null)}
                    className={classes.comp}
                    variant="outlined"
                    {...otherProps}
                    onChange={(event) => {
                        props.updateGlob(event.target.value, 0)
                        props.onchange(event)
                    }}
                    mask='19:69:AM'
                    maskChar='-'
                    placeholder = "09:00:am"
                    formatChars={{
                        '1': '[0-1]',
                        '6': '[0-6]',
                        '9': '[0-9]',
                        'A': '[ap]',
                        '*': '[A-Za-z0-9]',
                        'M': '[m]'
                    }}
                >
                    {(inputProps) =>
                        <TextField
                            className={classes.comp}
                            variant="outlined"
                            {...otherProps}
                            {...inputProps}
                        />}
                </InputMask>
            </Grid>
            <Grid item xs={6}>
                <Typography className={classes.formLabel}>
                    {props.label[1]}
                </Typography>


                <InputMask
                    error={(typeof getFromArray(props.errortext, 1) == "string" && getFromArray(props.errortext, 1) !== "" ? true : false)}
                    helperText={(typeof getFromArray(props.errortext, 1) == "string" && getFromArray(props.errortext, 1) !== "" ? getFromArray(props.errortext, 1) : null)}
                    defaultValue={(typeof getFromArray(props.initialvalue, 1) == "string" && getFromArray(props.initialvalue, 1) !== "" ? fromArmyTime(getFromArray(props.initialvalue, 1)) : null)}
                    className={classes.comp}
                    variant="outlined"
                    {...otherProps}
                    onChange={(event) => {
                        props.updateGlob(event.target.value, 1)
                        props.onchange(event)
                    }}
                    mask='19:69:AM'
                    maskChar='-'
                    placeholder = "09:00:pm"
                    formatChars={{
                        '1': '[0-1]',
                        '6': '[0-6]',
                        '9': '[0-9]',
                        'A': '[ap]',
                        '*': '[A-Za-z0-9]',
                        'M': '[m]'
                    }}
                >
                    {(inputProps) =>
                        <TextField
                            className={classes.comp}
                            variant="outlined"
                            {...otherProps}
                            {...inputProps}
                        />}
                </InputMask>
            </Grid>
        </Grid>
    )
}

export default CTimeRange