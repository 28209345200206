import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button,Tabs,Tab } from "@material-ui/core";
import CompletedList from "./List/CompletedList";
import UpcomingList from "./List/UpcomingList"
import CanceledList from "./List/CanceledList"
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    mobilePadding: {
        paddingTop: "10px",
        width: "100%",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    topHeading: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    bottomSpacer: {
        height: "50px",
    },
    stickyHeader: {
        position: "sticky",
        top: 64,
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: "#ffffff",
        boxShadow: "inset 0 -1px #e3e8ee"
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    },
    valueText: {
        fontSize: 14,
    }
}));


const ScheduleList = (props) => {

    const classes = useStyles()
    const [choice, setChoice] = useState(0)
    const matches = useMediaQuery("(min-width: 750px)")
    let comp = null
    if (choice === 0) {
        comp = <UpcomingList basePath={props.basePath} />
    }
    else if (choice === 1) {
        comp = <CompletedList basePath={props.basePath} />
    }
    else if (choice === 2) {
        comp = <CanceledList basePath = {props.basePath}/>
    }
    return (

        <Grid container className={matches ? classes.mainWrapper : classes.mobilePadding}>
            <Grid item xs={12} className={matches ? null : classes.stickyHeader}>
                <div className={classes.headerPadding}>
                    <span className={matches ? classes.topHeading : classes.mobileTopHeadingOne}>Schedule</span>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Tabs
                    value={choice}
                    onChange={(event, value) => { setChoice(value) }}
                    indicatorColor="primary"
                >
                    <Tab label="Upcoming" />
                    <Tab label="Completed" />
                    <Tab label="Canceled" />
                </Tabs>
            </Grid>
            {comp}
            <Grid item xs={12}>
                <div className={classes.bottomSpacer}></div>
            </Grid>
        </Grid>

    );


};

export default ScheduleList;
