import React, { useState, useEffect } from 'react';
import Form from "../../../../../Form/Form";
import CModal from "../../../../../Form/CModal";
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getGenericDetails, addPricingAgreement, withLoading } from '../../../../../../utils';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px",
    width: "100vw",
    maxHeight: "80vh",
    overflowY: "auto"

  }
}))


const AddAgreementForm = (props) => {

  const [allAgreements, setAllAgreements] = useState([])
  const dispatch = useDispatch()
  const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });

  const generateChoices = (allOptions) => {
    const temp = []
    const currentChosen = new Set()
    props.optionDetails.agreements.forEach((agreement) => {
      currentChosen.add(agreement.agreementId)
    })
    allOptions.forEach((agreement) => {
      if (!currentChosen.has(agreement.agreeementId) && agreement.isActive) {
        temp.push([agreement.name, agreement.agreementId])
      }
    })
    return (temp)
  }

  const setUp = async () => {
    let genericData = await withLoading(getGenericDetails,{
        businessId: businessId
      })
    if (genericData && genericData.genericDetails && Array.isArray(genericData.genericDetails.agreementDetails)) {
      setAllAgreements(genericData.genericDetails.agreementDetails)
    }
  }

  useEffect(() => {
    if (props.open) {
      setAllAgreements([])
      setUp()
    }
  }, [props.open])

  const classes = useStyles()
  const refresh = (success) => {
    if (success) {
      props.setOpen(false)
      props.refresh()
    }
  }
  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Container component="main" className={classes.formContainer}>
        <Form
          moreData={{ businessId: businessId, optionId: props.optionDetails.optionId }}
          title="Add Agreements"
          cancelEnabled
          cancelAction={() => { props.setOpen(false) }}
          submitText="Add"
          action={addPricingAgreement}
          postAction={refresh}
          inputFields={[
            {
              type: "MultiSelect",
              name: "agreementIds",
              label: "Agreements",
              choices: generateChoices(allAgreements)
            }

          ]}
        />
      </Container>
    </CModal>
  )
}

export default AddAgreementForm