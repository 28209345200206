import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { listOrderDetails, safeQuery, withLoading } from "../../../../../../utils";
import { getDocument } from "../../../../../../utils";
import { issueRefund } from "../../../../../../utils";
import CButtonPair from "../../../../../Form/CButtonPair";
import {
  Grid, Button, Table, TableBody,
  TableCell, TableContainer, TableHead,
  TableRow, CircularProgress, Container,
  Typography, useMediaQuery, IconButton
} from "@material-ui/core";
import CurrencyPreProcessor from "../../../../../CurrencyFormatter/CurrencyFormatter";
import Moment from "react-moment";
import Form from "../../../../../Form/Form";
import StatusTag from "../../../../../TagComponents/StatusTag";
import CModal from "../../../../../Form/CModal";
import LightTooltip from "../../../../../Form/LightTooltip";
import ListTable from "../../../../../TableComponents/ListTable"
import CDisplayArea from "../../../../../Form/CDisplayArea";
import CTitle from "../../../../../Form/CTitle";
import InstallmentSchedule from "../../../../../InstallmentSchedule/InstallmentSchedule";
import TransactionCard from "../../../../../TransactionCard/TransactionCard";
import ManualPayment from "../../../../../TransactionCard/ManualPayment";
import { CAddIcon } from "../../../../../Icons/Icons";
import RecordPayment from "../../../../../TransactionCard/RecordPayment";
import ChangeOrderOption from "./ChangeOrderOption";
import OrderChangeHistory from "../../../../../OrderChangeHistory/OrderChangeHistory";

const useStyles = makeStyles((theme) => ({
  formContainerLarge: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    width: "80vw",
    maxHeight: "80vh",
    overflowY: "auto"
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "30px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingThree: {
    fontSize: "18px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#dbdddf",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "red",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPos: {
    fontSize: "14px",
    color: "green",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none"
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px"
  },
  fieldColumnOne: {
    width: "90px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  atCustomCellOne: {
    border: "none",
    width: "20%",
    maxWidth: "300px",
    padding: "10px",
  },
  atCustomCellTwo: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  atCustomCellThree: {
    border: "none",
    padding: "10px",
  },
  ttCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  ttCustomCellTwo: {
    border: "none",
    width: "20%",
    maxWidth: "300px",
    padding: "10px",
  },
  ttCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  ttCustomCellFour: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  ttCustomCellFive: {
    border: "none",
    padding: "10px",
  },
  ptCustomCellOne: {
    border: "none",
    width: "30%",
    maxWidth: "400px",
    padding: "10px",
  },
  ptCustomCellTwo: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  ptCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  ptCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  ccBlock: {
    display: "inline-block"
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingTop: "10px",
    backgroundColor: "#ffffff"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px"
  }
}));

const aaHeaders = [
  {
    name: "name",
    columnKey: "name",
    columnWidth: "100%",
    tooltip: true
  },
  {
    name: "action",
    columnKey: "action",
    columnWidth: "100px",
    tooltip: true
  },

];

const unitHeaders = [
  {
    name: "status",
    columnKey: "status",
    columnWidth: "100px",
    type: "Normal"
  },
  {
    name: "session #",
    columnKey: "sessionNumber",
    columnWidth: "100px",
    type: "Normal"
  },

  {
    name: "name",
    columnKey: "name",
    columnWidth: "100%",
    tooltip: true
  },
]

const packHeaders = [
  {
    name: "status",
    columnKey: "status",
    columnWidth: "100px",
    type: "Normal"
  },
  {
    name: "pack/pass #",
    columnKey: "packNumber",
    columnWidth: "100px",
    type: "Normal"
  },

  {
    name: "name",
    columnKey: "name",
    columnWidth: "100%",
    tooltip: true
  },
]

const Order = () => {
  const [_, updateState] = useState(0);
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const large = useMediaQuery('(min-width: 960px)');
  const matches = useMediaQuery('(min-width: 1140px)');
  const [orderDetails, setOrderDetails] = useState(null)
  const { orderIdURL } = useParams();
  const [refund, setRefund] = useState(false);
  const [view, setView] = useState(false);
  const small = useMediaQuery('(min-width: 750px)');
  const [addMP, setAddMP] = useState(false)

  async function getDocuments(documentCode) {
    dispatch({
      type: 'UPDATE', payload: {
        loading: true
      }
    });
    let success = await getDocument({
      businessId,
      documentCode
    })
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
    if (success) {
      window.open(success.docUrl, `/document/${success.docUrl}`, "rel=noreferrer");
    }
  };

  async function handleSubmit(event) {

    dispatch({
      type: 'UPDATE', payload: {
        loading: true
      }
    });
    let success = await issueRefund({
      businessId: businessId,
      orderId: orderDetails.orderId,
      refundAmount: event.amount
    })
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
    if (success) {
      setRefund(false);
      updateState((_) => !_);
    }
  };

  const setUp = async () => {
    const data = await withLoading(listOrderDetails, {
      businessId: businessId,
      orderId: orderIdURL
    })
    if (data && !data.error) {
      setOrderDetails(data.orderDetails)
    }
  }

  useEffect(() => {
    setUp()
  }, [_]);

  const getAgrCount = (agrList, type) => {

    let recCount = 0;
    
    for(let i = 0 ; i < agrList.length; i++){
      if(agrList[i].status == type){
        recCount += 1;
      }
    };

    return recCount;

  };

  if (orderDetails && orderDetails.orderId === orderIdURL) {
    return (
      <Grid container className={large ? classes.mainWrapper : classes.mobilePadding}>
        <RecordPayment
          buisnessId={businessId}
          orderId={orderIdURL}
          open={addMP}
          setOpen={setAddMP}
          currencyInfo={orderDetails.currencyInfo}
          reloadPage={updateState}
        />
        <CModal open={refund} setOpen={setRefund}
          children={
            <Container component="main" className={classes.formContainer}>

              <Form
                action={handleSubmit}
                postAction={null}
                title="Issue Refund"
                cancelEnabled
                cancelAction={() => { setRefund(false); }}
                cancelText="Cancel"
                submitText="Submit"
                inputFields={
                  [
                    {
                      type: "Currency",
                      currencyCode: "USD",
                      label: "Refund Amount",
                      name: "amount",
                      validator: value => (value + orderDetails.refundAmount > orderDetails.orderAmount ? `Cannot issue refund more than ${CurrencyPreProcessor({
                        amount: orderDetails.orderAmount - orderDetails.refundAmount,
                        currencyBreak: orderDetails.currencyInfo.currencyBreak,
                        roundingFactor: orderDetails.currencyInfo.roundingFactor,
                        currencySymbol: orderDetails.currencyInfo.currencySymbol
                      })
                        }` : null),
                      initialvalue: "",
                      currencyInfo: orderDetails.currencyInfo
                    },
                  ]
                }
              >
              </Form>
            </Container>
          }
        />
        <CModal open={view} setOpen={setView}>
          <Container className={classes.formContainerLarge}>
            <Grid container spacing={2}>
              <CTitle title="Agreement" />
              <Grid item xs={12}>
                <CDisplayArea
                  value={view ? view.document : null}
                  te={false}
                  initialMaxHeight="calc(80vh - 300px)"
                />
              </Grid>
              <CButtonPair
                submitText="Close"
                action={() => { setView(false) }}
                errors={[]}
              />
            </Grid>
          </Container>
        </CModal>
        <Grid item xs={12}>
          <div className={classes.topBlock}>
            <span className={classes.topHeading}>ORDER</span>
            {orderDetails.allowOptionChange ?
              <span className={classes.rightElem}>
                <ChangeOrderOption orderId={orderDetails.orderId} fetchOrderDetails={setUp} />
              </span>
              : null}
          </div>
        </Grid>
        <Grid container direction="row" item xs={12} spacing={2} className={classes.headingWrapper}>
          <Grid item xs={orderDetails.refundAmount > 0 ? 4 : 5}>
            <div>
              <span className={large ? classes.topHeadingOne : classes.mobileTopHeadingTwo} style={small ? { fontSize: "24px" } : null}>
                <CurrencyPreProcessor
                  amount={orderDetails.orderAmount}
                  currencyBreak={orderDetails.currencyInfo.currencyBreak}
                  roundingFactor={orderDetails.currencyInfo.roundingFactor}
                  currencySymbol={orderDetails.currencyInfo.currencySymbol}
                />
              </span>
              <span className={large ? classes.topHeadingTwo : classes.mobileTopHeadingThree} style={small ? { fontSize: "24px" } : null}>
                {` ${orderDetails.currencyInfo.currencyShortName}`}
              </span>
            </div>
          </Grid>
          <Grid item xs={orderDetails.refundAmount > 0 ? 3 : 2}>
            <div>
              {orderDetails.refundAmount && orderDetails.refundAmount > 0 ?
                <span className={classes.topHeadingThree}>
                  <CurrencyPreProcessor
                    amount={orderDetails.refundAmount}
                    currencyBreak={orderDetails.currencyInfo.currencyBreak}
                    roundingFactor={orderDetails.currencyInfo.roundingFactor}
                    currencySymbol={orderDetails.currencyInfo.currencySymbol}
                  />{" "}
                  refunded
                </span> : null}
            </div>
          </Grid>
          <Grid container direction={large ? "row-reverse" : "column-reverse"} item xs={5}>
            <Grid item>
              <div>
                <span className={classes.rightElemPad}>
                  <span className={classes.valueText}>
                    {orderDetails.orderNumber}
                  </span>
                </span>
              </div>
            </Grid>
            <Grid item>
              <div>
                <span className={classes.rightElemPad}>
                  <span className={classes.labelText}>{small ? "ORDER NUMBER" : "ORDER #"}</span>
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <div className={matches ? classes.contentColumnFirst : classes.fieldRows}>
              <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                <span className={classes.labelText} style={{ whiteSpace: "normal" }}>Booking Date</span>
              </div>
              <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>
                <span className={classes.valueText}>
                  <Moment format="MMMM, DD YYYY">
                    {orderDetails.bookingDate}
                  </Moment>
                </span>
              </div>
            </div>
            <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
              <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                <span className={classes.labelText}>Customer</span>
              </div>
              <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { width: "110px" } : !matches && large ? { width: "80%", paddingLeft: "15px" } : { width: "65%", paddingLeft: "15px" }}>
                <LightTooltip arrow title={`${orderDetails.customerFirstName} ${orderDetails.customerLastName}`}>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", marginBottom: -6 }}>
                    {`${orderDetails.customerFirstName} ${orderDetails.customerLastName}`}
                  </Typography>
                </LightTooltip>
              </div>
            </div>
            <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
              <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                <span className={classes.labelText}>Service</span>
              </div>
              <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { width: "100px" } : !matches && large ? { width: "80%", paddingLeft: "15px" } : { width: "65%", paddingLeft: "15px" }}>
                <LightTooltip arrow title={orderDetails.serviceName}>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", marginBottom: -6 }}>
                    {orderDetails.serviceName}
                  </Typography>
                </LightTooltip>
              </div>
            </div>
            {orderDetails.offeringType === "Private" ? (
              <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <span className={classes.labelText}>Professional</span>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { width: "100px" } : !matches && large ? { width: "80%", paddingLeft: "15px" } : { width: "65%", paddingLeft: "15px" }}>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", marginBottom: -6 }}>
                    {`${orderDetails.staffFirstName} ${orderDetails.staffLastName}`}
                  </Typography>
                </div>
              </div>
            ) : null}
            <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
              <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                <span className={classes.labelText} style={{ whiteSpace: "normal" }}>Pricing Option</span>
              </div>
              <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { width: "100px" } : !matches && large ? { width: "80%", paddingLeft: "15px" } : { width: "65%", paddingLeft: "15px" }}>
                <LightTooltip arrow title={orderDetails.optionName}>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif", marginBottom: -6 }}>
                    {orderDetails.optionName}
                  </Typography>
                </LightTooltip>
              </div>
            </div>
            <div className={matches ? classes.contentColumnLast : classes.fieldRows}>
              <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                <span className={classes.labelText}>Quantity</span>
              </div>
              <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>
                <span className={classes.valueText}>
                  {orderDetails.purchaseQty}
                </span>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Service</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <Typography className={classes.labelText}>Name</Typography>
              </div>
              <div className={classes.fieldColumnTwo} style={matches ? { width: "85%" } : !matches && large ? { width: "80%" } : { width: "70%" }}>
                <LightTooltip arrow title={orderDetails.serviceName}>
                  <Typography className={classes.valueText} noWrap>
                    <Link style={{ color: "#0079fc", textDecoration: "none" }} to={`/dashboard/${businessId}/Services/${orderDetails.serviceId}`}>{orderDetails.serviceName}</Link>
                  </Typography>
                </LightTooltip>
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <Typography className={classes.labelText}>Type</Typography>
              </div>
              <div className={classes.fieldColumnTwo} style={matches ? { width: "85%" } : !matches && large ? { width: "80%" } : { width: "70%" }}>
                <LightTooltip arrow title={`${orderDetails.offeringType} - ${orderDetails.isVirtual ? "Virtual" : "In Person"}`}>
                  <Typography className={classes.valueText}>
                    {`${orderDetails.offeringType} - ${orderDetails.isVirtual ? "Virtual" : "In Person"}`}
                  </Typography>
                </LightTooltip>
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <span className={classes.labelText}>Location</span>{" "}
              </div>
              <div className={classes.fieldColumnTwo} style={matches ? { width: "85%" } : !matches && large ? { width: "80%" } : { width: "70%" }}>
                <Typography className={classes.valueText} noWrap style={{ fontFamily: "Rubik, sans-serif" }}>
                  <Link style={{ color: "#0079fc", textDecoration: "none" }} to={`/dashboard/${businessId}/Sites/${orderDetails.siteId}`}>{orderDetails.siteName}</Link>
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Attendees</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <ListTable
              identifier={"lineDetails"}
              tableHeaders={(orderDetails.optionType === "Unit" ? unitHeaders : packHeaders)}
              tableRows={orderDetails.lineDetails.map(line => ({
                name: <Link to={`/dashboard/${businessId}/Attendees/${line.shareId}`} className={classes.valueTextLink}>
                  <Typography noWrap className={classes.valueTextLink} >
                    {`${line.attendeeFirstName} ${line.attendeeLastName}`}
                  </Typography>
                </Link>,
                sessionNumber: (orderDetails.optionType === "Unit" ?
                  <Link to={`/dashboard/${businessId}/Sessions/${line.sessionId}`} className={classes.valueTextLink}>
                    <Typography noWrap className={classes.valueTextLink} >
                      {line.sessionNumber}
                    </Typography>
                  </Link>
                  : null),
                packNumber: (orderDetails.optionType !== "Unit" ?
                  <Link to={`/dashboard/${businessId}/Packages-and-Passes/${line.lineId}`} className={classes.valueTextLink}>
                    <Typography noWrap className={classes.valueTextLink} >
                      {line.packNumber}
                    </Typography>
                  </Link>
                  : null),
                status: <StatusTag wrapperName={line.isCanceled ? "redWrapper" : "greenWrapper"} value={line.isCanceled ? "Canceled" : "Active"} />
              }))}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Customer</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <Typography noWrap className={classes.labelText}>Name</Typography>
              </div>
              <div className={classes.fieldColumnTwo} style={matches ? { width: "85%" } : !matches && large ? { width: "80%" } : { width: "70%" }}>
                <LightTooltip title="Customer Profile Page" arrow>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                    <Link style={{ color: "#0079fc", textDecoration: "none" }} to={`/dashboard/${businessId}/Customers/${orderDetails.customerId}`}>{`${orderDetails.customerFirstName} ${orderDetails.customerLastName}`}</Link>
                  </Typography>
                </LightTooltip>
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <Typography className={classes.labelText}>Email</Typography>
              </div>
              <div className={classes.fieldColumnTwo} style={matches ? { width: "85%" } : !matches && large ? { width: "80%" } : { width: "70%" }}>
                <LightTooltip title="Email Customer" arrow>
                  <Typography className={classes.valueTextLink} noWrap>
                    <a style={{ color: "#0079fc" }} href={"mailto:" + orderDetails.customerEmail}>{orderDetails.customerEmail}</a>
                  </Typography>
                </LightTooltip>
              </div>
            </div>
            {orderDetails.customerPhoneNumber ? (
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <Typography className={classes.labelText}>Phone</Typography>
                </div>
                <div className={classes.fieldColumnTwo} style={matches ? { width: "85%" } : !matches && large ? { width: "80%" } : { width: "70%" }}>

                  <LightTooltip title={`${orderDetails.customerPhoneNumber.slice(0, 2)} (${orderDetails.customerPhoneNumber.slice(2, 5)}) ${orderDetails.customerPhoneNumber.slice(5, 8)}-${orderDetails.customerPhoneNumber.slice(8, 12)}`} arrow>
                    <Typography className={classes.valueText} noWrap>
                      {`${orderDetails.customerPhoneNumber.slice(0, 2)} (${orderDetails.customerPhoneNumber.slice(2, 5)}) ${orderDetails.customerPhoneNumber.slice(5, 8)}-${orderDetails.customerPhoneNumber.slice(8, 12)}`}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
            ) : (
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <span className={classes.labelText}>Phone</span>{" "}
                </div>
                <div className={classes.fieldColumnTwo}>
                  <span className={classes.valueText}>Not Available</span>
                </div>
              </div>
            )}
          </div>
        </Grid>
        {orderDetails.offeringType == "Private" ? (
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Professional</span>
            </div>
          </Grid>
        ) : null}
        {orderDetails.offeringType == "Private" ? (
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Name</Typography>
                </div>
                <div className={classes.fieldColumnTwo} style={{ width: "80%" }}>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                    <LightTooltip title="Staff Profile Page" arrow>
                      <Link style={{ color: "#0079fc", textDecoration: "none" }} to={`/dashboard/${businessId}/Staffs/${orderDetails.staffId}`}>{`${orderDetails.staffFirstName} ${orderDetails.staffLastName}`}</Link>
                    </LightTooltip>
                  </Typography>
                </div>
              </div>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <span className={classes.labelText}>Email</span>{" "}
                </div>
                <div className={classes.fieldColumnTwo}>
                  <LightTooltip title="Email Staff Member" arrow>
                    <span className={classes.valueTextLink}>
                      <a style={{ color: "#0079fc" }} href={"mailto:" + orderDetails.staffEmail}>{orderDetails.staffEmail}</a>
                    </span>
                  </LightTooltip>
                </div>
              </div>
              {orderDetails.staffPhoneNumber ? (
                <div className={classes.fieldRows}>
                  <div className={classes.fieldColumnOne}>
                    <span className={classes.labelText}>Phone</span>{" "}
                  </div>
                  <div className={classes.fieldColumnTwo}>
                    <span className={classes.valueText}>
                      {`${orderDetails.staffPhoneNumber.slice(0, 2)} (${orderDetails.staffPhoneNumber.slice(2, 5)}) ${orderDetails.staffPhoneNumber.slice(5, 8)}-${orderDetails.staffPhoneNumber.slice(8, 12)}`}
                    </span>
                  </div>
                </div>
              ) : (
                <div className={classes.fieldRows}>
                  <div className={classes.fieldColumnOne}>
                    <span className={classes.labelText}>Phone</span>{" "}
                  </div>
                  <div className={classes.fieldColumnTwo}>
                    <span className={classes.valueText}>Not Available</span>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Invoice</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <TableContainer style={{ width: "100%" }}>
              <Table stickyHeader aria-label="simple table" style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className={classes.headerPadding} style={{ width: `calc(100%)` }}>
                      <Typography noWrap className={classes.labelText}>DESCRIPTION</Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.headerPadding} style={{ width: "100px" }}>
                      <span className={classes.labelText}>PRICE</span>
                    </TableCell>
                    <TableCell align="left" className={classes.headerPadding} style={{ width: "50px" }}>
                      <span className={classes.labelText}>QTY</span>
                    </TableCell>
                    <TableCell align="left" className={classes.headerPadding} style={{ width: "75px" }}>
                      <span className={classes.labelText}>AMOUNT</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left" className={classes.ptCustomCellOne}>
                      <Typography noWrap className={classes.valueText}>
                        {`${orderDetails.checkoutCard.offeringLine.serviceName} - ${orderDetails.checkoutCard.offeringLine.optionName}`}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.ptCustomCellTwo}>
                      <span className={classes.valueText}>
                        <CurrencyPreProcessor
                          amount={
                            orderDetails.checkoutCard.offeringLine.unitPrice
                          }
                          currencyBreak={
                            orderDetails.currencyInfo.currencyBreak
                          }
                          roundingFactor={
                            orderDetails.currencyInfo.roundingFactor
                          }
                          currencySymbol={
                            orderDetails.currencyInfo.currencySymbol
                          }
                        />
                      </span>
                    </TableCell>
                    <TableCell align="left" className={classes.ptCustomCellThree}>
                      <span className={classes.valueText}>
                        {orderDetails.checkoutCard.offeringLine.purchaseQty}
                      </span>
                    </TableCell>
                    <TableCell align="left" className={classes.ptCustomCellFour}>
                      <span className={classes.valueText}>
                        &nbsp;&nbsp;
                        <CurrencyPreProcessor
                          amount={
                            orderDetails.checkoutCard.offeringLine.subTotal
                          }
                          currencyBreak={
                            orderDetails.currencyInfo.currencyBreak
                          }
                          roundingFactor={
                            orderDetails.currencyInfo.roundingFactor
                          }
                          currencySymbol={
                            orderDetails.currencyInfo.currencySymbol
                          }
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                  {orderDetails.checkoutCard.discountLines && orderDetails.checkoutCard.discountLines.map(
                    (discount, index) => {
                      return (
                        <TableRow key={`${orderDetails.orderId}${index}`}>
                          <TableCell
                            align="left"
                            className={classes.ptCustomCellOne}
                          >
                            <LightTooltip title={`${discount.discountName} ${discount.percentage}%`} arrow>
                              <Typography noWrap>
                                <span className={classes.valueText}>
                                  {discount.discountName}
                                </span>
                                <span className={classes.valueTextBold}>
                                  {` (${discount.percentage}%)`}
                                </span>
                              </Typography>
                            </LightTooltip>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.ptCustomCellTwo}
                          ></TableCell>
                          <TableCell
                            align="left"
                            className={classes.ptCustomCellThree}
                          ></TableCell>
                          <TableCell
                            align="left"
                            className={classes.ptCustomCellFour}
                          >
                            <span className={classes.valueTextNeg}>
                              -{" "}
                              <CurrencyPreProcessor
                                amount={discount.calculatedDiscount}
                                currencyBreak={
                                  orderDetails.currencyInfo.currencyBreak
                                }
                                roundingFactor={
                                  orderDetails.currencyInfo.roundingFactor
                                }
                                currencySymbol={
                                  orderDetails.currencyInfo.currencySymbol
                                }
                              />
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                  {orderDetails.checkoutCard.feeLines.length > 0 && orderDetails.checkoutCard.feeLines.map((fee) => {
                    return (
                      <TableRow>
                        <TableCell
                          align="left"
                          className={classes.ptCustomCellOne}
                        >
                          <Typography noWrap>
                            <span className={classes.valueText}>{fee.feeName}</span>
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.ptCustomCellTwo}
                        ></TableCell>
                        <TableCell
                          align="left"
                          className={classes.ptCustomCellThree}
                        ></TableCell>
                        <TableCell
                          align="left"
                          className={classes.ptCustomCellFour}
                        >
                          <span className={classes.valueText}>
                            &nbsp;&nbsp;
                            <CurrencyPreProcessor
                              amount={
                                fee.calculatedFee
                              }
                              currencyBreak={
                                orderDetails.currencyInfo.currencyBreak
                              }
                              roundingFactor={
                                orderDetails.currencyInfo.roundingFactor
                              }
                              currencySymbol={
                                orderDetails.currencyInfo.currencySymbol
                              }
                            />
                          </span>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {safeQuery(orderDetails, ["checkoutCard", "addOnLines", "length"]) > 0 && orderDetails.checkoutCard.addOnLines.map((addOn) => {
                    return (
                      <TableRow>
                        <TableCell
                          align="left"
                          className={classes.ptCustomCellOne}
                        >
                          <LightTooltip title={`${addOn.addOnName}`} arrow>
                            <Typography noWrap>
                              <span className={classes.valueText}>{addOn.addOnName}</span>
                            </Typography>
                          </LightTooltip>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.ptCustomCellTwo}
                        >
                          <span className={classes.valueText}>
                            &nbsp;&nbsp;
                            <CurrencyPreProcessor
                              amount={
                                addOn.unitPrice
                              }
                              currencyBreak={
                                orderDetails.currencyInfo.currencyBreak
                              }
                              roundingFactor={
                                orderDetails.currencyInfo.roundingFactor
                              }
                              currencySymbol={
                                orderDetails.currencyInfo.currencySymbol
                              }
                            />
                          </span>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.ptCustomCellThree}
                        >
                          {addOn.purchaseQty}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.ptCustomCellFour}
                        >
                          <span className={classes.valueText}>
                            &nbsp;&nbsp;
                            <CurrencyPreProcessor
                              amount={
                                addOn.unitPrice * addOn.purchaseQty
                              }
                              currencyBreak={
                                orderDetails.currencyInfo.currencyBreak
                              }
                              roundingFactor={
                                orderDetails.currencyInfo.roundingFactor
                              }
                              currencySymbol={
                                orderDetails.currencyInfo.currencySymbol
                              }
                            />
                          </span>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {orderDetails.checkoutCard.taxLines.length > 0 && orderDetails.checkoutCard.taxLines.map((tx) => {
                    return (
                      <TableRow>
                        <TableCell
                          align="left"
                          className={classes.ptCustomCellOne}
                        >
                          <Typography noWrap>
                            <span className={classes.valueText}>{tx.TaxName}</span>
                            <span className={classes.valueTextBold}>
                              {` ${tx.percentage}%`}
                            </span>
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.ptCustomCellTwo}
                        ></TableCell>
                        <TableCell
                          align="left"
                          className={classes.ptCustomCellThree}
                        ></TableCell>
                        <TableCell
                          align="left"
                          className={classes.ptCustomCellFour}
                        >
                          <span className={classes.valueText}>
                            &nbsp;&nbsp;
                            <CurrencyPreProcessor
                              amount={
                                tx.calculatedTax
                              }
                              currencyBreak={
                                orderDetails.currencyInfo.currencyBreak
                              }
                              roundingFactor={
                                orderDetails.currencyInfo.roundingFactor
                              }
                              currencySymbol={
                                orderDetails.currencyInfo.currencySymbol
                              }
                            />
                          </span>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {orderDetails.checkoutCard.transactionLine.feeApplied ?
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.ptCustomCellOne}
                      >
                        <Typography noWrap>
                          <span className={classes.valueText}>Transaction Fee(s)</span>
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.ptCustomCellTwo}
                      ></TableCell>
                      <TableCell
                        align="left"
                        className={classes.ptCustomCellThree}
                      ></TableCell>
                      <TableCell
                        align="left"
                        className={classes.ptCustomCellFour}
                      >
                        <span className={classes.valueText}>
                          &nbsp;&nbsp;
                          <CurrencyPreProcessor
                            amount={
                              orderDetails.checkoutCard.transactionLine.calculatedFee
                            }
                            currencyBreak={
                              orderDetails.currencyInfo.currencyBreak
                            }
                            roundingFactor={
                              orderDetails.currencyInfo.roundingFactor
                            }
                            currencySymbol={
                              orderDetails.currencyInfo.currencySymbol
                            }
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                    : null}
                  {orderDetails.checkoutCard?.creditLine?.creditLineApplicable ?
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.ptCustomCellOne}
                      >
                        <Typography noWrap>
                          <span className={classes.valueText}>Credit</span>
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.ptCustomCellTwo}
                      ></TableCell>
                      <TableCell
                        align="left"
                        className={classes.ptCustomCellThree}
                      ></TableCell>
                      <TableCell
                        align="left"
                        className={classes.ptCustomCellFour}
                      >
                        <span className={classes.valueTextNeg}>
                          -{" "}
                          <CurrencyPreProcessor
                            amount={
                              orderDetails.checkoutCard.creditLine.creditApplied
                            }
                            currencyBreak={
                              orderDetails.currencyInfo.currencyBreak
                            }
                            roundingFactor={
                              orderDetails.currencyInfo.roundingFactor
                            }
                            currencySymbol={
                              orderDetails.currencyInfo.currencySymbol
                            }
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                    : null}
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.ptCustomCellOne}
                    ></TableCell>
                    <TableCell
                      align="left"
                      className={classes.ptCustomCellTwo}
                    ></TableCell>
                    <TableCell
                      align="right"
                      className={classes.ptCustomCellThree}
                    >
                      <span className={classes.valueText}>TOTAL</span>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.ptCustomCellFour}
                    >
                      <span className={classes.valueTextBold}>
                        &nbsp;&nbsp;
                        <CurrencyPreProcessor
                          amount={orderDetails.checkoutCard.totalLine.total}
                          currencyBreak={
                            orderDetails.currencyInfo.currencyBreak
                          }
                          roundingFactor={
                            orderDetails.currencyInfo.roundingFactor
                          }
                          currencySymbol={
                            orderDetails.currencyInfo.currencySymbol
                          }
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
        {orderDetails.bookingType !== 'Offline' ?
          <React.Fragment>
            <Grid container direction="row" item xs={12} className={classes.headingWrapper}>
              <Grid item xs={12}>
                <div>
                  <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Payments</span>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.contentRow}>
                {orderDetails.transactionDetails.map((transaction, index) => {
                  return (
                    <TransactionCard
                      businessId={businessId}
                      orderDetails={orderDetails}
                      transaction={transaction}
                      key={`transaction-${index}`}
                      index={index}
                      refresh={() => { updateState(_ => !_) }}
                    />)
                })}
              </div>
            </Grid>
          </React.Fragment>
          :
          <React.Fragment>
            <Grid container direction="row" item xs={12} className={classes.headingWrapper}>
              <Grid item xs={12}>
                <Grid container alignItems="center" display="flex">
                  <span style={{ flexGrow: 1 }} className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Manual Payments</span>
                  <IconButton variant="contained" onClick={() => { setAddMP(true) }} style={{ float: "right" }}>
                    <CAddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.contentRow}>
                {orderDetails.manualPayments.map((payment, index) => {
                  return (
                    <ManualPayment
                      updateState={updateState}
                      currencyInfo={orderDetails.currencyInfo}
                      buisnessId={businessId}
                      orderId={orderIdURL}
                      key={`manual-payment-${index}`}
                      payment={payment}
                      admin={true}
                    />
                  )
                })}
              </div>
            </Grid>
          </React.Fragment>
        }
        {orderDetails.paymentOption === "Installment" && orderDetails.bookingType !== 'Offline' ?
          <React.Fragment>
            <Grid item xs={12}>
              <div className={classes.headingWrapper}>
                <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Installment Schedule</span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.contentRow}>

                <InstallmentSchedule orderDetails={orderDetails} refresh={() => { updateState((_) => !_) }} businessId={businessId} />
              </div>
            </Grid>
          </React.Fragment>
          : null}

        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Accepted Agreements</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            {
              (getAgrCount(orderDetails.agreements , 'Signed')) !== 0 ?
                <ListTable
                  identifier={"accepted-agreements"}
                  tableHeaders={aaHeaders}
                  tableRows={orderDetails.agreements
                    .filter(agreement => (agreement.status == 'Signed'))
                    .map(agreement => ({
                    id: agreement.agreementId,
                    name: agreement.name,
                    action: (
                      <Button
                        className={classes.buttonBasic}
                        style={{ color: "white", backgroundColor: "#0079fc" }}
                        onClick={() => { setView(agreement) }}
                      >
                        View
                      </Button>)

                  }))}
                  rowClick={null}
                /> :
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    No agreements were signed.
                  </span>
                </div>
            }
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Pending Agreements To Accept</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            {
              (getAgrCount(orderDetails.agreements , 'Unsigned')) !== 0 ?
                <ListTable
                  identifier={"accepted-agreements"}
                  tableHeaders={aaHeaders}
                  tableRows={orderDetails.agreements
                    .filter(agreement => (agreement.status == 'Unsigned'))
                    .map(agreement => ({
                    id: agreement.agreementId,
                    name: agreement.name,
                    action: (
                      <Button
                        className={classes.buttonBasic}
                        style={{ color: "white", backgroundColor: "#0079fc" }}
                        onClick={() => { setView(agreement) }}
                      >
                        View
                      </Button>)

                  }))}
                  rowClick={null}
                /> :
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    No pending agreements to accept.
                  </span>
                </div>
            }
          </div>
        </Grid>
        {
          orderDetails?.orderChangeHistory?.length > 0?
            <React.Fragment>
              <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Order Change History</span>
          </div>
        </Grid>
        <Grid item xs = {12}>
          <div className = {classes.contentRow}>
            <OrderChangeHistory orderDetails = {orderDetails} admin/>
          </div>
        </Grid>
            </React.Fragment>
          :null
        }
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default Order;
