import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { listQuestions, withLoading, retrieveGetParams } from "../../../../../../utils";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Typography, useMediaQuery, Button } from "@material-ui/core";
import ListTable from "./../../../../../TableComponents/ListTable";
import StatusTag from "../../../../../TagComponents/StatusTag";
import ListCard from "../../../../../MobileCards/ListCard";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import ManageQuestionForm from "./ManageQuestion";
import PostFilter, { applyFilters } from '../../../../../Filter/PostFilter'

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    rightElem: {
        float: "right",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    },
    buttonBasic: {
        fontSize: "14px",
        marginLeft: '10px',
        textTransform: "none",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeading: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    bottomSpacer: {
        height: "50px",
    },
}));

const keyObjects = [
    {
        key: 'isActive',
        label: 'Status',
        keyLabel: 'statusName'
    },
    {
        key: 'type',
        label: 'Type',
        keyLabel: 'type'
    }
]
const questionsHeaders = [
    {
        name: "status",
        columnKey: "status",
        columnWidth: "100px",
        type: "Normal"
    },
    {
        name: "type",
        columnKey: "type",
        columnWidth: "100px",
    },
    {
        name: "question",
        columnKey: "question",
        columnWidth: "100%",
        tooltip: true
    },


];


const QuestionsList = (props) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const [questionsList, setQuestionsList] = useState(null);
    const bigEnough = useMediaQuery("(min-width:750px)");
    const location = useLocation()
    const queryParams = retrieveGetParams(location)
    const businessId = useSelector((state) => {
        return state.buisnessDetails.businessId;
    });



    const processQuestionsData = (questionsData) => {
        return questionsData.map((questionData) => {
            let {
                questionId,
                question,
                isActive,
                type,
            } = questionData;

            let status = <StatusTag wrapperName={isActive ? "greenWrapper" : "redWrapper"} value={isActive ? "Active" : "Inactive"} />;
            let id = questionId;
            type = type.charAt(0).toUpperCase() + type.slice(1)
            return {
                id,
                status,
                type,
                isActive,
                question,
                statusName: isActive ? "Active" : "Not Active"
            };
        });
    };

    const getQuestionsDetails = async () => {
        let questionsPayload = await listQuestions({
            businessId: businessId,
        });

        if (questionsPayload) {
            setQuestionsList(processQuestionsData(questionsPayload.questionDetails));
        };

    };

    const navigateToQuestion = (questionId) => {
        history.push(`${props.basePath}/${questionId}`);
    };

    useEffect(async () => {
        await withLoading(getQuestionsDetails, {}, true)
    }, []);



    const ViewChanger = () => {
        if (bigEnough) {
            return (<React.Fragment>
                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        <ListTable
                            identifier={"questions"}
                            tableHeaders={questionsHeaders}
                            tableRows={applyFilters(questionsList, queryParams, keyObjects)}
                            rowClick={navigateToQuestion}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bottomSpacer}></div>
                </Grid>
            </React.Fragment>)
        }
        else {
            return (<Grid item xs={12} style={{ paddingTop: "10px" }}>
                {applyFilters(questionsList, queryParams, keyObjects).map((question) => {
                    return (
                        <ListCard
                            key={question.id}
                            action={() => { navigateToQuestion(question.id) }}
                            cardName={question.question}
                            statusReplace={
                                <Typography
                                    noWrap
                                    style={!question.isActive ? { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "red" } : { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "green" }}>
                                    {question.isActive ? "Active" : "Inactive"}
                                </Typography>
                            }
                            other={question.type} />
                    )
                })}
            </Grid>)
        }

    }


    if (questionsList === null) {
        return (null)
    }
    else {
        return (
            <React.Fragment>
                <ManageQuestionForm open={open} setOpen={setOpen} basePath={props.basePath} />
                <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                    <Grid item xs={12}>
                        <div className={classes.headerPadding}>
                            <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Questions</span>
                            <span title="Add Question" className={classes.rightElem}>
                                <Button
                                    className={classes.buttonBasic}
                                    onClick={() => { setOpen(true); }}
                                    color="primary"
                                    variant="contained"
                                >
                                    Add
                                </Button>
                            </span>
                            <PostFilter allEntries={questionsList} keyObjects={keyObjects} />
                        </div>
                    </Grid>
                    {(Array.isArray(questionsList) && questionsList.length > 0 ?
                        <ViewChanger />
                        :
                        <EmptyListImage name="Questions" />)}
                </Grid>
            </React.Fragment>
        );
    }

};

export default QuestionsList;