import { Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import notEmpty from '../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../FormValidation/noWhiteSpace';
import validPassword from '../../../FormValidation/password';
import validPhoneNumber from '../../../FormValidation/phonenumber';
import validEmail from '../../../FormValidation/validEmail';
import { genOTP, signUp, withLoading } from '../../../utils';
import CButtonPair from '../../Form/CButtonPair';
import CModalError from '../../Form/CModalError';
import CTitle from '../../Form/CTitle';
import Form from '../../Form/Form';

const useStyles = makeStyles((theme) => ({
    buttonText: {
        fontSize: 14,
        textTransform: "none"
    },
    container: {
        maxWidth: "550px",
        paddingTop: '40px',
        paddingBottom: '40px'
    },
    formLabel: {
        fontSize: 14,
        color: theme.palette.textColor2.main
    },
    fieldText: {
        fontSize: 16,
        color: theme.palette.primaryText.main,
        padding: "10px"
    }
}));

const createAccount = async (otp, formData, postLoginAction) => {
    const signUpResponse = await withLoading(signUp, {
        otp,
        ...formData,
    })
    if (signUpResponse && !signUpResponse.error) {
        postLoginAction(signUpResponse)
    }
}

const MPSignUp = (props) => {
    const classes = useStyles()
    const [formData, setFormData] = useState(null)
    const [otpStage, setOTPStage] = useState(false)
    const [timeoutRef, setTimeoutRef] = useState(null)
    const [timeDisabled, setTimeDisabled] = useState(0)
    const otpRef = useRef(null)


    useEffect(() => {
        if (timeDisabled > 0) {
            if (timeoutRef) {
                clearTimeout(timeoutRef)
                setTimeoutRef(null)
            }
            const ref = setTimeout(() => {
                setTimeDisabled(timeDisabled => timeDisabled - 1)
            }, 1000)
            setTimeoutRef(ref)
        }
        return () => {
            if (timeoutRef) {
                clearTimeout(timeoutRef)
                setTimeoutRef(null)
            }
        }
    }, [timeDisabled])

    async function resendOTP(email) {
        let success = await withLoading(genOTP, {
            email
        })

        if (success) {
            setTimeDisabled(60)
        }
    }



    return (
        <React.Fragment>
            {otpStage && formData
                ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CTitle center title={"Email Verification"} />
                    </Grid>
                    <Grid item xs={12}>
                        <CModalError />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align='center' gutterBottom>
                            We sent an email with the verification code to <b>{formData?.email}</b>. Check your spam/promotion folder if you didn't receive the email in your inbox. Enter the code below to create an account.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.formLabel}>
                            Email verification code
                        </Typography>
                        <TextField
                            variant="outlined"
                            fullWidth
                            inputRef={otpRef}
                            InputProps={{
                                classes: { input: classes.fieldText }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Button onClick={() => { resendOTP(formData.email) }} disabled={Boolean(timeDisabled)} variant="contained" color="primary">
                            <Typography className={classes.buttonText}>
                                Resend OTP
                            </Typography>
                        </Button>
                        {timeDisabled ?
                            <Typography component='span' style={{ marginLeft: '10px' }}>
                                Please wait {timeDisabled} sec
                            </Typography>
                            : null}
                    </Grid>
                    <Grid item xs={12}>
                        <CButtonPair
                            submitText="Create Account"
                            cancelEnabled
                            cancelText="Go Back"
                            action={() => { createAccount(otpRef.current.value, formData, props.postLoginAction) }}
                            cancelAction={() => { setOTPStage(false) }}
                        />
                    </Grid>
                </Grid>
                :
                <React.Fragment>
                    <Form
                        titleCenter
                        title="Sign Up"
                        formStyles={{
                            padding: 14,
                        }}
                        submitText="Next"
                        action={(payload) => {
                            if (payload.email != formData?.email) {
                                resendOTP(payload.email)
                            }
                            setFormData(payload)
                            setOTPStage(true)
                        }}
                        inputFields={[
                            {
                                type: "TextField",
                                name: "firstName",
                                validator: [notEmpty, noWhiteSpace],
                                initialvalue: formData?.firstName,
                                label: "First Name"
                            },
                            {
                                type: "TextField",
                                name: "lastName",
                                initialvalue: formData?.lastName,
                                validator: [notEmpty, noWhiteSpace],
                                label: "Last Name"
                            },
                            {
                                type: "PhoneNumber",
                                name: "phoneNumber",
                                validator: validPhoneNumber,
                                initialvalue: formData?.phoneNumber,
                                label: "Phone Number"
                            },
                            {
                                type: "TextField",
                                name: "email",
                                initialvalue: formData?.email,
                                trim: true,
                                validator: [notEmpty, validEmail],
                                label: "Email",
                            },
                            {
                                type: "Password",
                                name: "password",
                                label: "Password",
                                initialvalue: formData?.password,
                                validator: [notEmpty, validPassword]
                            },
                            {
                                type: "CheckBox",
                                name: "tos",
                                initialvalue: formData?.tos,
                                label: <span>By clicking the box, I agree to <a target="_blank" href={`${window.location.origin}/document/cctou`} rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                    Caritra Terms of Use
                                </a> and <a target="_blank" href={`${window.location.origin}/document/cpp`} rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                        Privacy Policy
                                    </a>
                                </span>,
                                validator: (value) => { if (value === false) { return ("Please agree to Terms and Conditions") } }
                            }

                        ]}
                    />
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography align="left" onClick={() => { props.setForm("login") }} style={{ cursor: "pointer" }} color="primary">
                                Already have an account? Log in here
                            </Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default MPSignUp