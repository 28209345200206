import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { staffPackDetails, withLoading } from "../../../../../../utils";
import { schedulePackStaff } from "../../../../../../utils";
import { cancelPackStaff } from "../../../../../../utils";
import {
  Grid, Button, Table, TableBody, TableCell,
  Typography, TableContainer, TableHead, TableRow,
  CircularProgress, Container
} from "@material-ui/core";
import moment from "moment";
import StatusTag from "./../../../../../TagComponents/StatusTag";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CModal from "../../../../../Form/CModal";
import LightTooltip from "../../../../../Form/LightTooltip";
import ScheduleCalendar from "../../../AdminDashboard/AdminOptions/Packages/ScheduleCalendar";
import { CEllipsisIcon } from "../../../../../Icons/Icons";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import { CInfoIcon } from "../../../../../InfoIcon/InfoIcon";
import { AttendeeInfoModal } from "../../../../../AttendeeInfoModal/AttendeeInfoModal";
import ListTable from "../../../../../TableComponents/ListTable";
import CTitle from "../../../../../Form/CTitle";
import CButtonPair from "../../../../../Form/CButtonPair";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb575750",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px"
  },
  fieldColumnOne: {
    width: "90px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  sdCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "25%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
    paddingLeft: "0px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    backgroundColor: "#ffffff"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "700px"
  }
}));
const addOnHeaders = [
  {
    name: "Name",
    columnKey: "name",
    columnWidth: "30%",
    type: "Typography",
    tooltip: true
  },

  {
    name: "Basis",
    columnKey: "sellBy",
    columnWidth: "100px",
  },
  {
    name: "Description",
    columnKey: "description",
    columnWidth: "70%",
    tooltip: true
  },
  {
    name: "",
    columnKey: "action",
    columnWidth: "100px",
    type: "Normal"
  },
];

const isUTCPassed = (utcTime) => {
  try {
    let curMoment = new moment();
    let refMomemt = new moment(utcTime);

    if (curMoment.isBefore(refMomemt)) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return true;
  }
};

const StaffPack = () => {
  const classes = useStyles();
  const [_, updateState] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const [schedule, setSchedule] = useState(false);
  const matches = useMediaQuery('(min-width: 1140px)');
  const small = useMediaQuery('(min-width: 750px)');
  const large = useMediaQuery('(min-width: 960px)');
  const [anchorEl, setAnchorEl] = useState(false);
  const [aM, setAM] = useState(false);
  const [cOpen, setCOpen] = useState(false);
  const packDetails = useSelector((state) => {
    return state.packDetails;
  });
  const [classId, setClassId] = useState(null);
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const schedulingAccess = useSelector((state) => { return state.buisnessDetails.schedulingAccess });
  const role = useSelector((state) => {
    return state.buisnessDetails.role;
  });

  const { packIdURL } = useParams();

  async function handleSubmit(event) {
    let success = null;
    dispatch({
      type: 'UPDATE', payload: {
        loading: true
      }
    });
    if (packDetails.offeringType === "Group") {
      success = await schedulePackStaff({
        businessId: businessId,
        packId: packDetails.packId,
        classId: classId
      })
    } else {
      success = await schedulePackStaff({
        businessId: businessId,
        packId: packDetails.packId,
        startDate: event.startDate,
        startTime: event.startTime
      })
    }
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
    if (success) {
      history.push(`/dashboard/${businessId}/Sessions/${success.sessionId}`);
    };
  };

  async function handleCancel(event) {
    const success = await withLoading(cancelPackStaff, {
      businessId: businessId,
      packId: packDetails.packId
    })
    if (success) {
      setCOpen(false)
      updateState((_) => !_);
    }
  };

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });

    let data = await staffPackDetails({
      businessId: businessId,
      packId: packIdURL,
    });

    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
        packDetails: data ? data.packDetails : null,
      },
    });
  }, [_]);

  if (packDetails && packDetails.packId === packIdURL) {
    return (
      <Grid container className={large ? classes.mainWrapper : classes.mobilePadding}>
        <AttendeeInfoModal open={aM} setOpen={setAM} attendeeQuestions={packDetails.attendeeQuestions} />
        <CModal open={schedule} setOpen={setSchedule}
          children={
            <Container component="main" className={classes.formContainer} style={{ maxWidth: "1000px" }}>
              <ScheduleCalendar
                packId={packDetails.packId}
                validityStart={packDetails.validityStart.replace(/,|st|th|rd|nd/g, "")}
                validityEnd={packDetails.validityEnd.replace(/,|rd|nd|th|st/g, "")}
                serviceIds={packDetails.serviceId}
                siteIds={packDetails.siteId}
                serviceTypes={packDetails.offeringType}
                location={packDetails.siteName}
                role={role}
                timeZone={packDetails.timeZone}
                mode={packDetails.isVirtual}
                setClassId={setClassId}
                handleSubmit={handleSubmit}
                cancel={() => { setSchedule(false); }}
                offeringType={packDetails.offeringType}
                preProcess={true}
              />
            </Container>
          }
        />
        {packDetails.isCanceled ?
          <GeneralDialog
            open={cOpen}
            close={setCOpen}
            title={packDetails.isCanceled ? "Reactivate Pack" : "Cancel Pack"}
            text={packDetails.isCanceled ? "Are you sure you want to reactivate this pack?" : "Are you sure you want to cancel this pack?"}
            action={handleCancel}
          />
          :
          <CModal open={cOpen} setOpen={setCOpen}>
            <Container component="main" className={classes.formContainer}>
              <Grid container spacing={2}>
                <CTitle title="Cancel Session" />
                <Grid item xs={12}>
                  <Typography>
                    Please contact a business administrator to cancel the pack or pass.
                  </Typography>
                </Grid>
                <CButtonPair submitText="Close" action={async () => setCOpen(false)} />
              </Grid>
            </Container>
          </CModal>}
        <Grid container item xs={12} className={classes.topBlock}>
          <Grid item xs={packDetails.isCanceled ? 12 : 6}>
            <div>
              <span className={classes.topHeading}>PACKAGE / PASS</span>
            </div>
          </Grid>
          {!packDetails.isCanceled ? <Grid item xs={6}>
            <div className={classes.rightElem}>
              <Button className={classes.buttonBasic} onClick={(event) => { setAnchorEl(event.currentTarget); }}><CEllipsisIcon /></Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={Boolean(anchorEl)}
                onClose={() => { setAnchorEl(false); }}
              >
                <MenuItem onClick={() => { setCOpen(true); setAnchorEl(false); }}>Cancel</MenuItem>
              </Menu>
            </div>
          </Grid> : null}
        </Grid>
        <Grid container direction="row" item xs={12} className={classes.headingWrapper}>
          <Grid item xs={4}>
            <div>
              <span className={large ? classes.topHeadingOne : classes.mobileTopHeadingTwo}>
                #&nbsp;{packDetails.packNumber}
              </span>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div>
              {packDetails.isCanceled ? (
                <span className={classes.topHeadingThree} style={matches ? { marginBottom: -5 } : { marginTop: 0 }}>Canceled</span>
              ) : null}
            </div>
          </Grid>
          <Grid container style={{ marginTop: -20, marginLeft: -15 }} direction={large ? "row-reverse" : "column-reverse"} item>
            <Grid item>
              <div className={classes.rightElemPad}>
                <span className={classes.valueText}>
                  {`${packDetails.validityStart} - ${packDetails.validityEnd}`}
                </span>
              </div>
            </Grid>
            <Grid item>
              <div className={classes.rightElemPad}>
                <span className={classes.labelText}>VALIDITY</span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <div className={matches ? classes.contentColumnFirst : classes.fieldRows}>
              <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                <Typography nowrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.labelText}>Attendee</Typography>
              </div>
              <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { width: "110px" } : !matches && large ? { width: "80%", paddingLeft: "15px" } : { width: "65%", paddingLeft: "15px" }}>
                <LightTooltip title={`${packDetails.attendeeFirstName} ${packDetails.attendeeLastName}`} arrow>
                    <Typography noWrap className={classes.valueText} style={{ marginBottom: -6, fontFamily: "Rubik, sans-serif" }} >
                      {`${packDetails.attendeeFirstName} ${packDetails.attendeeLastName}`}
                    </Typography>
                </LightTooltip>
              </div>
            </div>
            <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
              <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                <Typography nowrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.labelText}>Service</Typography>
              </div>
              <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { width: "100px" } : !matches && large ? { width: "80%", paddingLeft: "15px" } : { width: "65%", paddingLeft: "15px" }}>
                <LightTooltip title={packDetails.serviceName} arrow>
                  <Typography noWrap style={{ marginBottom: -6, fontFamily: "Rubik, sans-serif" }} className={classes.valueText}>
                    {packDetails.serviceName}
                  </Typography>
                </LightTooltip>
              </div>
            </div>
            <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
              <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                {packDetails.optionType == "Pack" ? (
                  <Typography nowrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.labelText}>Pack Name</Typography>
                ) : (
                  <Typography nowrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.labelText}>Pass Name</Typography>
                )}
              </div>
              <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { width: "100px" } : !matches && large ? { width: "80%", paddingLeft: "15px" } : { width: "65%", paddingLeft: "15px" }}>
                <LightTooltip title={packDetails.optionName} arrow>
                  <Typography noWrap className={classes.valueText} style={{ marginBottom: -6, fontFamily: "Rubik, sans-serif" }}>
                    {packDetails.optionName}
                  </Typography>
                </LightTooltip>
              </div>
            </div>
            <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
              <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                <Typography nowrap style={{ fontFamily: "Rubik, sans-serif", whiteSpace: "normal" }} className={classes.labelText}>Total Sessions</Typography>
              </div>
              <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>
                <Typography nowrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.valueText}>
                  {packDetails.sessionCount}
                </Typography>
              </div>
            </div>
            <div className={matches ? classes.contentColumnLast : classes.fieldRows}>
              <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                <Typography style={{ fontFamily: "Rubik, sans-serif", whiteSpace: "normal" }} className={classes.labelText}>Cancellation by Customer</Typography>
              </div>
              <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>
                <Typography nowrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.valueText}>
                  {packDetails.cancelAllowed && packDetails.cancelDeadline > 0 ? `Allowed ${packDetails.cancelDeadline} hours before` : packDetails.cancelDeadline = 0 ? "Allowed up to state of session" : "Disabled"}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid container direction="row" item xs={12} className={classes.headingWrapper}>
          <Grid item xs={6}>
            <div>
              <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Attendee Questions</span>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
              {packDetails.attendeeQuestions.length !== 0 ? (
                <TableContainer style={small ? { maxHeight: "265px", width: "100%" } : { maxHeight: "177px", width: "100%" }}>
                  <Table stickyHeader aria-label="simple table" style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className={classes.headerPadding} style={{ width: `calc(50%)` }}>
                          <span className={classes.labelText}>QUESTION</span>
                        </TableCell>
                        <TableCell align="left" className={classes.headerPadding} style={{ width: `calc(50%)` }}>
                          <Typography noWrap className={classes.labelText}>ANSWER</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {packDetails.attendeeQuestions.map((ques) => {
                        return (
                          <TableRow >
                            <TableCell
                              align="left"
                              className={classes.sdCustomCellOne}
                            >
                              <Typography noWrap className={classes.valueText}>
                                  {ques.question}
                                </Typography>

                            </TableCell>
                            <TableCell align="left" className={classes.sdCustomCellTwo}>
                                <Typography noWrap className={classes.valueText}>
                                  {ques.answer}
                                </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    No questions for this attendee.
                  </span>
                </div>
              )}
          </div>
        </Grid>
        <Grid container direction="row" item xs={12} className={classes.headingWrapper}>
          <Grid item xs={6}>
            <div>
              <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Sessions</span>
            </div>
          </Grid>
          {packDetails.scheduledSession < packDetails.sessionCount && !packDetails.isCanceled && schedulingAccess ? (<Grid item xs={6}>
            <div>
              <Button className={[classes.buttonBasic, classes.rightElem]} onClick={() => { setSchedule(true) }}>
                Schedule
              </Button>
            </div>
          </Grid>) : null}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            {packDetails.sessionDetails.length != 0 ? (
              <TableContainer style={small ? { maxHeight: "265px", width: "100%" } : { maxHeight: "177px", width: "100%" }}>
                <Table stickyHeader aria-label="simple table" style={{ tableLayout: "fixed" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.headerPadding} style={{ width: "70px" }}>
                        <span className={classes.labelText}>SESSION #</span>
                      </TableCell>
                      <TableCell align="left" className={classes.headerPadding} style={{ width: `calc(100%)` }}>
                        <Typography noWrap className={classes.labelText}>START TIME</Typography>
                      </TableCell>
                      {packDetails.offeringType == "Group" ? (
                        <TableCell align="left" className={classes.headerPadding} style={{ width: "45px" }}>
                          <span className={classes.labelText}>CLASS #</span>
                        </TableCell>
                      ) : null}
                      <TableCell align="left" className={classes.headerPadding} style={{ width: "100px" }}>
                        <span className={classes.labelText}>STATUS</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {packDetails.sessionDetails.map((session) => {
                      return (
                        <TableRow key={session.sessionId}>
                          <TableCell
                            align="left"
                            className={classes.sdCustomCellOne}
                          >
                            <LightTooltip title="Session Page" arrow>
                              <span className={classes.valueText}>
                                <Link style={{ color: "#0079fc", textDecoration: "none" }} to={`/dashboard/${businessId}/Sessions/${session.sessionId}`}>{session.sessionNumber}</Link>
                              </span>
                            </LightTooltip>
                          </TableCell>
                          <TableCell align="left" className={classes.sdCustomCellTwo}>
                            <Typography noWrap className={classes.valueText}>
                              <LightTooltip title={`${session.startDate} ${session.startTime} (${packDetails.timeZone})`} arrow>
                                <span>
                                  {`${session.startDate} ${session.startTime} (${packDetails.timeZone})`}
                                </span>
                              </LightTooltip>
                            </Typography>
                          </TableCell>
                          {session.classNumber ? (
                            <TableCell align="left" className={classes.sdCustomCellThree}>
                              <LightTooltip title="Group Class Page" arrow>
                                <span className={classes.valueTextLink}>
                                  <Link style={{ color: "#0079fc", textDecoration: "none" }} to={`/dashboard/${businessId}/Group-Classes/${session.classId}`}>{session.classNumber}</Link>
                                </span>
                              </LightTooltip>
                            </TableCell>
                          ) : null}
                          {session.isCanceled ? (
                            <TableCell align="left" className={classes.sdCustomCellFour}>
                              <StatusTag wrapperName={'redWrapper'} value={'Canceled'} />
                            </TableCell>
                          ) : isUTCPassed(session.endDateUTC) ? (
                            <TableCell align="left" className={classes.sdCustomCellFour}>
                              {(session.attendanceRecord) ? <StatusTag wrapperName={(session.attendanceRecord == 'Present' ? 'greenWrapper': (session.attendanceRecord == 'Absen' ? 'redWrapper': 'grayWrapper'))} value={session.attendanceRecord} /> :<StatusTag wrapperName={'grayWrapper'} value={'Completed'} />}
                            </TableCell>
                          ) : (
                            <TableCell align="left" className={classes.sdCustomCellFour}>
                              {(session.attendanceRecord) ? <StatusTag wrapperName={(session.attendanceRecord == 'Present' ? 'greenWrapper': (session.attendanceRecord == 'Absen' ? 'redWrapper': 'grayWrapper'))} value={session.attendanceRecord} /> :<StatusTag wrapperName={'greenWrapper'} value={'Scheduled'} />}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className={classes.topMarginBlock}>
                <span className={classes.valueTextPad}>
                  No sessions scheduled yet.
                </span>
              </div>
            )}
          </div>
        </Grid>
        {(packDetails.attendeeAddOn.length > 0 ?
          <React.Fragment>
            <Grid item xs={12}>
              <div className={classes.headingWrapper}>
                <Typography noWrap className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Add Ons</Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.contentRow}>
                <ListTable
                  styling={{ width: "100%", maxHeight: 250 }}
                  identifier={"addOns"}
                  tableHeaders={addOnHeaders}
                  tableRows={packDetails.attendeeAddOn.map((aO) => {
                    return ({
                      name: aO.displayName,
                      sellBy: aO.sellBy.toLowerCase(),
                      description: aO.description,
                      action: (aO.isCanceled ? <StatusTag wrapperName={'redWrapper'} value={'Canceled'} /> : null)
                    })
                  })}
                  rowClick={null}
                />
              </div>
            </Grid>
          </React.Fragment> :
          null)}
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Service</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <Typography nowrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.labelText}>Name</Typography>
              </div>
              <div className={classes.fieldColumnTwo} style={{ width: "80%" }}>
                <LightTooltip title={packDetails.serviceName} arrow>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                    {packDetails.serviceName}
                  </Typography>
                </LightTooltip>
              </div>
            </div>
            {packDetails.isVirtual ? (
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <Typography nowrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.labelText}>Type</Typography>
                </div>
                <div className={classes.fieldColumnTwo} style={{ width: "80%" }}>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                    {`${packDetails.offeringType} - Virtual`}
                  </Typography>
                </div>
              </div>
            ) : (
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <Typography nowrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.labelText}>Type</Typography>
                </div>
                <div className={classes.fieldColumnTwo} style={{ width: "80%" }}>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                    {`${packDetails.offeringType} - In Person`}
                  </Typography>
                </div>
              </div>
            )}
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <Typography nowrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.labelText}>Location</Typography>
              </div>
              <div className={classes.fieldColumnTwo} style={{ width: "80%" }}>
                <LightTooltip title={packDetails.siteName} arrow>
                  <Typography className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }} noWrap>
                    {packDetails.siteName}
                  </Typography>
                </LightTooltip>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Attendee</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <Typography nowrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.labelText}>Name</Typography>
              </div>
              <div className={classes.fieldColumnTwo} style={{ maxWidth: "70%" }}>
                <LightTooltip title={`${packDetails.attendeeFirstName} ${packDetails.attendeeLastName}`} arrow>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                    {`${packDetails.attendeeFirstName} ${packDetails.attendeeLastName}`}
                  </Typography>
                </LightTooltip>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Customer</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <Typography nowrap style={{ fontFamily: "Rubik, sans-serif" }} className={classes.labelText}>Name</Typography>
              </div>
              <div className={classes.fieldColumnTwo} style={{ width: "80%" }}>
                <LightTooltip title={`${packDetails.customerFirstName} ${packDetails.customerLastName}`} arrow>
                  <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                    {`${packDetails.customerFirstName} ${packDetails.customerLastName}`}
                  </Typography>
                </LightTooltip>
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <span className={classes.labelText}>Email</span>{" "}
              </div>
              <div className={classes.fieldColumnTwo}>
                <LightTooltip title="Email Customer" arrow>
                  <span className={classes.valueTextLink}>
                    <a style={{ color: "#0079fc" }} href={"mailto:" + packDetails.customerEmail}>{packDetails.customerEmail}</a>
                  </span>
                </LightTooltip>
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <span className={classes.labelText}>Phone</span>{" "}
              </div>
              <div className={classes.fieldColumnTwo}>
                {packDetails.customerPhoneNumber ? (
                  <span className={classes.valueText}>
                    {`${packDetails.customerPhoneNumber.slice(0, 2)} (${packDetails.customerPhoneNumber.slice(2, 5)}) ${packDetails.customerPhoneNumber.slice(5, 8)}-${packDetails.customerPhoneNumber.slice(8, 12)}`}
                  </span>
                ) : (
                  <span className={classes.valueText}>Not Available</span>
                )}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default StaffPack;
