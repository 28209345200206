import { Container, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import {
  assignPriceGroupToOptions,
  getGenericDetails,
  withLoading,
} from "../../../../../../utils";
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    width: "100vw",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflowY: "auto",
  },
}));

const AssociatedPricingOptionForm = (props) => {
  const mountedRef = useRef(false);
  const classes = useStyles();
  const [pricingOptions, setPricingOptions] = useState([]);

  const fetchPricingOptions = async () => {
    const res = await withLoading(getGenericDetails, {
      businessId: props.businessId,
    });
    if (
      res.error === false &&
      res?.genericDetails?.pricingOptionDetails instanceof Array &&
      mountedRef.current
    ) {
      setPricingOptions(res.genericDetails.pricingOptionDetails);
    }
  };

  const alreadyAssigned = new Set(
    props.priceGroup.pricingOptions.map((option) => option.optionId)
  );

  useEffect(() => {
    mountedRef.current = true;
    fetchPricingOptions();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <CModal open={props.open === true} setOpen={props.setOpen}>
      <Container className={classes.formContainer}>
        <Form
          moreData={{
            businessId: props.businessId,
            priceGroupId: props.priceGroup.priceGroupId,
          }}
          cancelEnabled
          cancelAction={() => {
            props.setOpen(false);
          }}
          submitText="Assign"
          action={assignPriceGroupToOptions}
          postAction={(res) => {
            if (res.error === false) {
              props.setOpen(false);
              props.refetch();
            }
          }}
          title="Assign Pricing Options to Price Group"
          inputFields={[
            {
              type: "MultiSelect",
              label: "Pricing Options",
              choices: pricingOptions
                .filter(
                  (option) =>
                    !alreadyAssigned.has(option.optionId) && option.isActive
                )
                .map((option) => [option.name, option.optionId]),
              name: "optionIds",
              validator: (value) =>
                value?.length > 0
                  ? null
                  : "At least one pricing option must be selected",
            },
          ]}
        />
      </Container>
    </CModal>
  );
};

export default AssociatedPricingOptionForm;
