import {
  Button,
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  deletePriceGroup,
  priceGroupDetails,
  removePriceGroupFromOptions,
  safePush,
  withLoading,
} from "../../../../../../utils";
import LightTooltip from "../../../../../Form/LightTooltip";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import {
  CAddIcon,
  CCloseIcon,
  CEllipsisIcon,
} from "../../../../../Icons/Icons";
import ListTable from "../../../../../TableComponents/ListTable";
import AssociatedPricingOptionForm from "./AssociatedPricingOptionForm";
import PricingGroupForm from "./PricingGroupForm";

const useStyles = makeStyles((theme) => ({
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px",
  },
  headingWrapper: {
    paddingBottom: "10px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
    width: "100vw",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
    maxWidth: "160px",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    overflow: "hidden",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
  },
  fieldColumnOne: {
    width: "120px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
    maxWidth: "160px",
  },
  sdCustomCellOne: {
    border: "none",
    width: "5%",
    maxWidth: "200px",
    padding: "0px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingThree: {
    fontSize: "20px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "35%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "60%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
    paddingLeft: "0px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  media: {
    height: 200,
    width: 250,
    objectFit: "cover",
  },
}));

const pricingOptionHeaders = [
  {
    name: "",
    columnKey: "action",
    columnWidth: "100px",
    type: "Normal",
  },
  {
    name: "option",
    columnKey: "name",
    columnWidth: "40%",
    tooltip: true,
  },
  {
    name: "service",
    columnKey: "service",
    columnWidth: "30%",
    tooltip: true,
  },
  {
    name: "site",
    columnKey: "site",
    columnWidth: "30%",
    tooltip: true,
  },
];

const PricingGroupDetails = (props) => {
  const mountedRef = useRef(false);
  const bigEnough = useMediaQuery("(min-width: 750px)");
  const [checked, setChecked] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { pricingGroupIdURL } = useParams();
  const [pricingGroup, setPricingGroup] = useState(null);
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);
  const [assign, setAssign] = useState(false);
  const [unassign, setUnassign] = useState(false);
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const fetchPricingGroupDetails = async () => {
    const res = await withLoading(priceGroupDetails, {
      businessId,
      priceGroupId: pricingGroupIdURL,
    });
    if (res?.error === false && res.priceGroupDetails && mountedRef.current) {
      setPricingGroup(res.priceGroupDetails);
    }
  };

  useEffect(() => {
    mountedRef.current = true;
    fetchPricingGroupDetails();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (pricingGroup) {
    return (
      <React.Fragment>
        <PricingGroupForm
          open={edit}
          setOpen={setEdit}
          businessId={businessId}
          priceGroupId={pricingGroupIdURL}
          refetch={fetchPricingGroupDetails}
          pricingGroup={pricingGroup}
        />
        <GeneralDialog
          open={del}
          close={setDel}
          title="Delete Price Group"
          text="Are you sure you want to delete this price group?"
          action={async () => {
            const response = await withLoading(deletePriceGroup, {
              businessId: businessId,
              priceGroupId: pricingGroupIdURL,
            });
            if (response?.error === false) {
              setDel(false);
              safePush(
                `/dashboard/${businessId}/Price-Groups`,
                history,
                location
              );
            }
          }}
        />
        <GeneralDialog
          open={unassign}
          close={setUnassign}
          title="Remove Pricing Options From Price Group"
          text="Are you sure you want to remove the selected pricing options from this price group"
          action={async () => {
            const response = await withLoading(removePriceGroupFromOptions, {
              businessId: businessId,
              priceGroupId: pricingGroupIdURL,
              optionIds: checked,
            });
            if (response?.error === false) {
              setChecked([]);
              setUnassign(false);
              fetchPricingGroupDetails();
            }
          }}
        />
        <AssociatedPricingOptionForm
          open={assign}
          setOpen={setAssign}
          priceGroup={pricingGroup}
          businessId={businessId}
          refetch={fetchPricingGroupDetails}
        />

        <Grid
          container
          className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
        >
          <Grid item xs={12}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>PRICE GROUP</span>
              <span className={classes.rightElem}>
                <Button
                  onClick={(event) => {
                    setAnchorEl(event.target);
                  }}
                  className={classes.buttonBasic}
                >
                  <CEllipsisIcon />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => {
                    setAnchorEl(false);
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setEdit(true);
                      setAnchorEl(false);
                    }}
                  >
                    Edit Price Group
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setDel(true);
                      setAnchorEl(false);
                    }}
                  >
                    Delete Price Group
                  </MenuItem>
                </Menu>
              </span>
            </div>
          </Grid>
          <Grid
            item
            style={bigEnough ? { maxWidth: "50%" } : { width: "100%" }}
          >
            <div className={classes.headerPadding}>
              <LightTooltip title={pricingGroup.name} arrow>
                <Typography
                  className={
                    bigEnough
                      ? classes.topHeadingOne
                      : classes.mobileTopHeadingOne
                  }
                  noWrap
                >
                  {pricingGroup.name}
                </Typography>
              </LightTooltip>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.headingWrapper}>
            <Typography
              className={
                bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
              }
              style={{ marginTop: "10px" }}
            >
              Description
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <Typography className={classes.valueText}>
                {pricingGroup.description}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.headingWrapper}>
            <Grid container>
              <Grid item style={{ flexGrow: 1 }}>
                <Typography
                  className={
                    bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                  }
                >
                  Pricing Options
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (checked.length > 0) {
                      setUnassign(true);
                    } else {
                      setAssign(true);
                    }
                  }}
                >
                  {checked.length === 0 ? <CAddIcon /> : <CCloseIcon />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {pricingGroup.pricingOptions.length > 0 ? (
                <ListTable
                  styling={{ maxHeight: 350, width: "100%" }}
                  identifier={"addons"}
                  tableHeaders={pricingOptionHeaders}
                  tableRows={pricingGroup.pricingOptions.map(
                    (option, index) => ({
                      action: (
                        <Checkbox
                          color="primary"
                          checked={checked.includes(option.optionId)}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!checked.includes(option.optionId)) {
                              setChecked([...checked, option.optionId]);
                            } else {
                              setChecked(
                                checked.filter(
                                  (optionId) => optionId !== option.optionId
                                )
                              );
                            }
                          }}
                        />
                      ),
                      id: option.optionId,
                      name: option.optionName,
                      service: option.serviceName,
                      site: option.siteName,
                    })
                  )}
                  rowClick={(id) =>
                    safePush(
                      `/dashboard/${businessId}/Pricings/${id}`,
                      history,
                      location
                    )
                  }
                />
              ) : (
                <Typography>
                  There are no pricing options associated with this pricing
                  group
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default PricingGroupDetails;
