import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { listPayouts } from '../../../../../../utils';
import Payout from "./Payout";
import PayoutList from "./PayoutList";

function PayoutsHandler() {
  const [_, updateState] = useState(0);
  const dispatch = useDispatch();
  const [currencyInfo, setCurrencyInfo] = useState([]);
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

    useEffect(async () => {
        dispatch({
          type: "UPDATE",
          payload: {
            loading: true,
          },
        });
        let data = await listPayouts({businessId: businessId})
        dispatch({
          type: "UPDATE",
          payload: {
            loading: false,
          },
        });
        if(data) {
            setCurrencyInfo(data.currencyInfo);
        }
      }, [_]);
    if(currencyInfo && currencyInfo.currencyBreak) {
       return(
        <React.Fragment>
            <Switch>
            <Route path = {`/dashboard/${businessId}/Payout-Statements`} exact render = {() => {return(<PayoutList currencyInfo = {currencyInfo} />)}} />
            <Route path = {`/dashboard/${businessId}/Payout-Statements/:recordIdURL`} render = {() => {return(<Payout />);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
        ); 
    } else {return (null)}
    
};

export default PayoutsHandler;