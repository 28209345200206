import React, { useState } from "react";
import {retrieveGetParams, encodeGetParams } from "../../utils";
import { Button, Container, makeStyles, ButtonGroup} from '@material-ui/core'
import CModal from '../Form/CModal'
import Form from '../Form/Form'
import { useHistory, useLocation } from "react-router";
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: '100vw'
    },
    button: {
        float: "right",
    },
    buttonText: {
        fontSize: '14px',
        textTransform: 'none'
    },
    icon:{
        height:'20px'
    }

}));

//Assumption: allData is an array of objects
// Keys is an array of keys to track
// Possibel keys ['name', '']

const createAvailableSet = (allData, keyObjects) => {
    const options = {}
    keyObjects.forEach(keyObject => {
        options[keyObject.key] = {}
    })
    allData.forEach(entry => {
        keyObjects.forEach(keyObject => {
            options[keyObject.key][entry[keyObject.key]] = entry[keyObject.keyLabel]
        })
    })
    return options
}

const passesFilter = (entry, queryParams, keyObjects)  => {
    let success = true
    keyObjects.forEach(keyObject=>{
        if(keyObject.key in queryParams && queryParams[keyObject.key] !== `${entry[keyObject.key]}`){
            success = false;
        }
    })
    return success
}

export const applyFilters = (allEntries, queryParams, keyObjects) => {
    const filteredEntries = []
    allEntries.forEach(entry=>{
        if(passesFilter(entry, queryParams,keyObjects)){
            filteredEntries.push(entry)
        }
    })
    return filteredEntries
}

const generateFields = (keyObjects, availableSet, queryParams) => {
    const fields = []
    keyObjects.forEach(keyObject => {
        fields.push({
            name: keyObject.key,
            label: keyObject.label,
            type: "SingleSelect",
            initialvalue: queryParams[keyObject.key] ? queryParams[keyObject.key] : undefined,
            choices: Object.keys(availableSet[keyObject.key]).map(key => [availableSet[keyObject.key][key], key])
        })
    })
    return fields
}
//keyObjects = [{key:string, label:string, keyLabel:string, dType: ...}...]
const PostFilter = (props) => {
    const { allEntries, keyObjects } = props;
    const keys = keyObjects.map(keyObject => keyObject.key)
    const [open, setOpen] = useState(false)
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const queryParams = retrieveGetParams(location)
    if (Array.isArray(allEntries) && allEntries.length > 0 && Array.isArray(keyObjects) && keyObjects.length > 0) {
        const availableSet = createAvailableSet(allEntries, keyObjects)
        const active = keys.some(key => queryParams[key])
        return (
            <React.Fragment>
                <ButtonGroup className={classes.button} >
                    <Button
                    variant="contained" color={active ? 'primary' : undefined}
                        onClick={() => { setOpen(true) }}
                    >
                        <span className = {classes.buttonText}>Filter</span>
                    </Button>
                    {active ?
                        <Button
                        color = "primary"
                        variant = "outlined"
                        style = {{width:0, padding:0}}
                            onClick={async () => {
                                history.push(location.pathname)
                            }}
                        >
                            <CloseIcon className = {classes.icon}/>
                        </Button>
                        : null}
                </ButtonGroup>

                <CModal
                    open={open}
                    setOpen={setOpen}
                >
                    <Container className={classes.formContainer}>
                        <Form

                            title="Update Filter"
                            cancelEnabled
                            clearEnabled
                            clearAction={async () => {
                                setOpen(false)
                                history.push(location.pathname)
                            }}
                            cancelAction={async () => { setOpen(false) }}
                            submitText="Filter"
                            action={async (newParams) => {
                                setOpen(false)
                                history.push(location.pathname + '?' + encodeGetParams({
                                    ...newParams,
                                }))
                            }}
                            inputFields={generateFields(keyObjects, availableSet, queryParams)}
                        />
                    </Container>
                </CModal>
            </React.Fragment>
        )
    }
    else {
        return null;
    }

}

export default PostFilter
