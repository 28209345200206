import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  addCustMembership,
  addCustPricing,
  addCustQualification,
  addCustomerDocument,
  addCustomerEC,
  blockCustomer,
  deleteCustPricing,
  deleteCustomerDocument,
  docUrlGet,
  editCustPricing,
  editCustomerDocument,
  editCustomerEC,
  editCustomerNotes,
  getGenericDetails,
  listcustomerDetails,
  operatingUnits,
  removeCustMembership,
  removeCustQual,
  removeCustomerEC,
  safePush,
  signedUrlUpload,
  withLoading,
} from "../../../../../../utils";
import ListTable from "../../../../../TableComponents/ListTable";

import {
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import validPhoneNumber from "../../../../../../FormValidation/phonenumber";
import CurrencyPreProcessor from "../../../../../CurrencyFormatter/CurrencyFormatter";
import CustomerDetailsTopBlock from "../../../../../CustomerDetails/CustomerDetailsTopBlock";
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";
import LightTooltip from "../../../../../Form/LightTooltip";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import {
  CAddIcon,
  CCloseIcon,
  CEllipsisIcon,
} from "../../../../../Icons/Icons";
import ListCard from "../../../../../MobileCards/ListCard";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  primary: {
    color: theme.palette.primary.main,
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#0079fc",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
  },
  fieldColumnOne: {
    width: "100px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  sdCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "25%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "3px",
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
  },
}));

const orderHistoryHeaders = [
  {
    name: "Order #",
    columnKey: "orderNumber",
    columnWidth: "150px",
  },
  {
    name: "Service",
    columnKey: "serviceName",
    columnWidth: "30%",
  },
  {
    name: "Option",
    columnKey: "optionName",
    columnWidth: "30%",
  },
  {
    name: "Purchase Qty",
    columnKey: "purchaseQty",
    columnWidth: "150px",
  },
  {
    name: "Amount",
    columnKey: "orderAmount",
    columnWidth: "150px",
    tooltip: true,
  },
];

const orderHistoryHeadersM = [
  {
    name: "Order #",
    columnKey: "orderNumber",
    columnWidth: "80px",
  },
  {
    name: "Option",
    columnKey: "optionName",
    columnWidth: "100%",
  },
  {
    name: "Amount",
    columnKey: "orderAmount",
    columnWidth: "80px",
    tooltip: true,
  },
];

const hostsHeaders = [
  {
    name: "Name",
    columnKey: "name",
    columnWidth: "50%",
    type: "Normal",
  },

  {
    name: "Email",
    columnKey: "email",
    columnWidth: "50%",
    tooltip: true,
  },
  {
    name: "Phone Number",
    columnKey: "phoneNumber",
    columnWidth: "150px",
  },
];

const hostsHeadersM = [
  {
    name: "Name",
    columnKey: "name",
    columnWidth: "50%",
    type: "Normal",
  },
  {
    name: "Email",
    columnKey: "email",
    columnWidth: "50%",
    tooltip: true,
  },
];

const NewCustomer = () => {
  const [_, updateState] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const large = useMediaQuery("(min-width: 960px)");
  const small = useMediaQuery("(min-width: 750px)");
  const componentRef = useRef();
  const [docTitle, setDocTitle] = useState("Customer Details");
  const history = useHistory();
  const location = useLocation();

  const customerDetails = useSelector((state) => {
    return state.customerDetails;
  });
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const operatingInfo = useSelector((state) => {
    return state.buisnessDetails.operatingUnitInfo;
  });

  /**
 * Order Number
Service Name
Option Name
Purchase quantity
Amount
 */

  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorEll, setAnchorEll] = useState(false);
  const [bOpen, setBOpen] = useState(false);
  const [cAOpen, setCAOpen] = useState(false);
  const [cEOpen, setCEOpen] = useState(false);
  const [cROpen, setCROpen] = useState(false);
  const [dAOpen, setDAOpen] = useState(false);
  const [dROpen, setDROpen] = useState(false);
  const [eAOpen, setEAOpen] = useState(false);
  const [eEOpen, setEEOpen] = useState(false);
  const [eROpen, setEROpen] = useState(false);
  const [mAOpen, setMAOpen] = useState(false);
  const [mROpen, setMROpen] = useState(false);
  const [notes, setNotes] = useState(false);
  const [qAOpen, setQAOpen] = useState(false);
  const [qROpen, setQROpen] = useState(false);

  const [currencyInfo, setCurrencyInfo] = useState(null);
  const [docEditForm, setDocEditForm] = useState(false);
  const [docForm, setDocForm] = useState(false);
  const [fileName, setFileName] = useState(false);
  const [fileData, setFileData] = useState(false);
  const [index, setIndex] = useState(0);
  const [memChoices, setMemChoices] = useState([]);
  const [memList, setMemList] = useState([]);
  const [memLength, setMemLength] = useState(0);
  const [imageName, setImageName] = useState(false);
  const [priceChoices, setPriceChoices] = useState([]);
  const [qualiChoices, setQualiChoices] = useState([]);
  const [qualList, setQualList] = useState([]);

  function handleQualChange(id) {
    const newQualList = [...qualList];
    newQualList.push(id);
    setQualList(newQualList);
  }

  function handleQualRemove(id) {
    const newQualList = [...qualList];
    const index = newQualList.indexOf(id);
    if (index > -1) {
      newQualList.splice(index, 1);
    }
    setQualList(newQualList);
  }

  function handleMemChange(event) {
    memList.splice(1, 0, event.toString());
    setMemLength(memList.length);
  }

  async function handleMemRemove(id) {
    let index = memList.indexOf(id);
    memList.splice(index, 1);
    setMemLength(memList.length);
  }

  const handleClose = () => {
    setAnchorEl(false);
    setAnchorEll(false);
    setCAOpen(false);
    setCEOpen(false);
    setCROpen(false);
    setDAOpen(false);
    setDROpen(false);
    setEAOpen(false);
    setEEOpen(false);
    setEROpen(false);
    setMAOpen(false);
    setMROpen(false);
    setQAOpen(false);
    setQROpen(false);
    setNotes(false);
  };

  //Printing Functions

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: docTitle,
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  const operatingDetails = async () => {
    let currency = await operatingUnits();
    if (currency) {
      setCurrencyInfo(currency);
    }
  };

  const processQualiNames = (qualiNameData) => {
    return qualiNameData.map((qualis) => {
      let { name, qualificationId, isActive, type } = qualis;

      let id = qualificationId;
      return {
        id,
        isActive,
        name,
        type,
      };
    });
  };

  const processPricingNames = (customNameData) => {
    return customNameData.map((custom) => {
      let { name, optionId, isActive, serviceName, siteName, price } = custom;

      let id = optionId;

      return {
        id,
        isActive,
        name,
        serviceName,
        siteName,
        price,
      };
    });
  };

  const processMemNames = (memNameData) => {
    return memNameData.map((mems) => {
      let { name, membershipId, isActive } = mems;

      let id = membershipId;

      return {
        id,
        isActive,
        name,
      };
    });
  };

  const getNames = async () => {
    let names = await getGenericDetails({
      businessId: businessId,
    });
    if (names && names.genericDetails) {
      setQualiChoices(
        processQualiNames(names.genericDetails.qualificationDetails)
      );
      setPriceChoices(
        processPricingNames(names.genericDetails.pricingOptionDetails)
      );
      setMemChoices(processMemNames(names.genericDetails.membershipDetails));
    }
  };

  const handleQRemove = async (event) => {
    let success = await withLoading(removeCustQual, {
      businessId: businessId,
      qualificationIds: qualList,
      customerId: customerDetails.customerId,
    });
    if (success && !success.error) {
      updateState((_) => !_);
      handleClose();
      setQualList([]);
    }
  };

  const handleMRemove = async (event) => {
    let success = await withLoading(removeCustMembership, {
      businessId: businessId,
      customerId: customerDetails.customerId,
      membershipIds: memList,
    });
    if (success && !success.error) {
      handleClose();
      updateState((_) => !_);
      setMemLength(0);
    }
  };

  const handleECRemove = async (event) => {
    let success = await withLoading(removeCustomerEC, {
      businessId: businessId,
      customerId: customerDetails.customerId,
      emergencyContactId: eROpen,
    });
    if (success) {
      handleClose();
      updateState((_) => !_);
      setIndex(0);
    }
  };

  const handleCPRemove = async (event) => {
    let success = await withLoading(deleteCustPricing, {
      businessId: businessId,
      customerId: customerDetails.customerId,
      customPriceId: customerDetails.customPricing[index].customPriceId,
    });
    if (success && !success.error) {
      handleClose();
      updateState((_) => !_);
      setIndex(0);
    }
  };

  const handleECAdd = async (event) => {
    let success;
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    success = await addCustomerEC({
      businessId: businessId,
      customerId: customerDetails.customerId,
      name: event.name,
      phoneNumber: event.phoneNumber,
      relation: event.relation ? event.relation : null,
      email: event.email ? event.email : null,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (success) {
      updateState((_) => !_);
      handleClose();
    }
  };

  const handleECEdit = async (event) => {
    let success;
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    success = await editCustomerEC({
      businessId: businessId,
      customerId: customerDetails.customerId,
      emergencyContactId:
        customerDetails.emergencyContacts[index].emergencyContactId,
      name: event.name,
      phoneNumber: event.phoneNumber,
      relation: event.relation ? event.relation : null,
      email: event.email ? event.email : null,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (success) {
      updateState((_) => !_);
      handleClose();
    }
  };

  const handleNoteSubmit = async (event) => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let success = await editCustomerNotes({
      businessId: businessId,
      customerId: customerIdURL,
      notes: event.notes ? event.notes : null,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (success) {
      updateState((_) => !_);
      handleClose();
    }
  };

  const handleQAdd = async (event) => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let success = await addCustQualification({
      businessId: businessId,
      customerId: customerIdURL,
      qualificationIds: event.qualIds,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (success) {
      updateState((_) => !_);
      handleClose();
    }
  };

  const handleCPAdd = async (event) => {
    let success = await withLoading(addCustPricing, event);
    if (success) {
      updateState((_) => !_);
      handleClose();
    }
  };

  const handleFile = async (file) => {
    if (
      file.target.files &&
      file.target.files[0] &&
      file.target.files[0].type
    ) {
      setFileName(file.target.files[0].name);
      setFileData(file.target.files[0]);
      setImageName(URL.createObjectURL(file.target.files[0]));
      setDocForm(true);
    }
  };

  const handleFileAdd = async (event) => {
    let preSignedURL = null;
    let success = null;

    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });

    preSignedURL = await signedUrlUpload({
      businessId,
      fileName,
    });

    if (preSignedURL && !preSignedURL.error) {
      let s3url = preSignedURL.signedData.url;
      let fields = preSignedURL.signedData.fields;
      let formData = new FormData();

      for (let k in fields) {
        formData.append(k, fields[k]);
      }

      formData.append("file", fileData);

      try {
        await axios.post(s3url, formData);
        await addCustomerDocument({
          businessId: businessId,
          customerId: customerIdURL,
          name: event.name,
          description: event.description ? event.description : null,
          docKey: preSignedURL.docKey,
        });

        dispatch({
          type: "UPDATE",
          payload: {
            loading: false,
          },
        });
        setDocForm(false);
        updateState((_) => !_);
      } catch (error) {
        dispatch({
          type: "UPDATE",
          payload: {
            loading: false,
          },
        });
      }
    } else {
      dispatch({
        type: "UPDATE",
        payload: {
          loading: false,
        },
      });
    }
  };

  const handleDocEdit = async (event) => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let success = await editCustomerDocument({
      businessId: businessId,
      customerId: customerIdURL,
      documentId: customerDetails.documents[index].documentId,
      name: event.name ? event.name : null,
      description: event.description ? event.description : null,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (success) {
      setDocEditForm(false);
      updateState((_) => !_);
    }
  };

  const handleDocDelete = async (event) => {
    let success = await withLoading(deleteCustomerDocument, {
      businessId: businessId,
      customerId: customerIdURL,
      documentId: customerDetails.documents[index].documentId,
    });
    if (success) {
      handleClose();
      setIndex(0);
      updateState((_) => !_);
    }
  };

  async function getDocument(docKey) {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let success = await docUrlGet({
      businessId,
      docKey,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (success) {
      window.open(
        success.docUrl,
        `/document/${success.docUrl}`,
        "rel=noreferrer"
      );
    }
  }

  const handleCPEdit = async (payload) => {
    let success = withLoading(editCustPricing, payload);
    if (success) {
      updateState((_) => !_);
    }
  };

  const handleMAdd = async (event) => {
    let success;
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    success = await addCustMembership({
      businessId: businessId,
      customerId: customerIdURL,
      membershipIds: event.memIds,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (success) {
      updateState((_) => !_);
      handleClose();
    }
  };

  const handleBlock = async (event) => {
    let success = await withLoading(blockCustomer, {
      businessId: businessId,
      customerId: customerDetails.customerId,
      isBlocked: !customerDetails.isBlocked,
    });
    if (success) {
      setBOpen(false);
      updateState((_) => !_);
    }
  };

  const { customerIdURL } = useParams();

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });

    let data = await listcustomerDetails({
      businessId: businessId,
      customerId: customerIdURL,
    });
    await getNames();
    await operatingDetails();

    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
        customerDetails: data ? data.customerDetails : null,
      },
    });
  }, [_]);

  if (
    customerDetails &&
    customerDetails.customerId === customerIdURL &&
    currencyInfo
  ) {
    return (
      <React.Fragment>
        <Grid
          container
          className={large ? classes.mainWrapper : classes.mobilePadding}
        >
          <React.Fragment>
            <GeneralDialog
              open={qROpen}
              close={handleClose}
              title={"Remove Qualification"}
              text={
                "Are you sure you want to remove the selected qualification(s)?"
              }
              action={handleQRemove}
            />
            <CModal open={notes} setOpen={setNotes}>
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={handleNoteSubmit}
                  postAction={null}
                  title={
                    !customerDetails.notes ? "New Note(s)" : "Edit Note(s)"
                  }
                  cancelEnabled
                  cancelAction={() => {
                    setNotes(false);
                  }}
                  cancelText="Cancel"
                  submitText={!customerDetails.notes ? "Create" : "Save"}
                  inputFields={[
                    {
                      type: "TextArea",
                      initialvalue: customerDetails.notes
                        ? customerDetails.notes
                        : null,
                      label: "Note(s)",
                      name: "notes",
                      resizeEnabled: false,
                      moreProps: {
                        rowsMin: 4,
                        rowsMax: 10,
                      },
                    },
                  ]}
                ></Form>
              </Container>
            </CModal>
            <CModal open={docForm} setOpen={setDocForm}>
              <Container component="main" className={classes.formContainer}>
                <Grid
                  item
                  xs={12}
                  className={classes.headingWrapper}
                  style={{ paddingBottom: "5px" }}
                >
                  <span
                    className={classes.topHeadingOne}
                    style={{
                      fontSize: "20px",
                      fontWeight: "normal",
                      fontFamily: "Rubik, sans-serif",
                    }}
                  >
                    New Document
                  </span>
                </Grid>
                <Grid container item xs={12} style={{ paddingTop: "15px" }}>
                  <Grid item xs={9}>
                    <TextField
                      variant="outlined"
                      multiline={true}
                      defaultValue={fileName}
                      minRow={1}
                      maxRows={1}
                      fullWidth={true}
                      inputProps={{
                        readOnly: true,
                        style: {
                          marginBottom: -10,
                          marginTop: -10,
                          fontSize: 14,
                          color: "#1a1f36",
                          fontFamily: "Rubik, sans-serif",
                          whiteSpace: "normal",
                        },
                      }}
                      readOnly={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <LightTooltip title="Reselect document" arrow>
                      <span className={classes.rightElem}>
                        <input
                          accept="application/pdf, .docx"
                          onChange={(file) => {
                            handleFile(file);
                          }}
                          style={{ display: "none" }}
                          id="contained-button-file"
                          multiple
                          type="file"
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            variant="contained"
                            component="span"
                            className={classes.buttonBasic}
                            onClick={() => {
                              setDocForm(false);
                            }}
                          >
                            <PublishIcon />
                          </Button>
                        </label>
                      </span>
                    </LightTooltip>
                  </Grid>
                </Grid>
                <Form
                  action={(event) => {
                    handleFileAdd(event);
                  }}
                  postAction={null}
                  cancelEnabled
                  cancelAction={() => {
                    setDocForm(false);
                  }}
                  cancelText="Cancel"
                  submitText="Submit"
                  inputFields={[
                    {
                      type: "TextField",
                      initialvalue: "",
                      validator: notEmpty,
                      label: "Name",
                      name: "name",
                    },
                    {
                      type: "TextField",
                      initialvalue: "",
                      label: "Description (optional)",
                      name: "description",
                    },
                  ]}
                ></Form>
              </Container>
            </CModal>
            <CModal open={docEditForm} setOpen={setDocEditForm}>
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={handleDocEdit}
                  postAction={null}
                  title="Edit document"
                  cancelEnabled
                  cancelAction={() => {
                    setDocEditForm(false);
                  }}
                  cancelText="Cancel"
                  submitText="Save"
                  inputFields={[
                    {
                      type: "TextField",
                      initialvalue:
                        customerDetails.documents.length > 0 &&
                        customerDetails.documents[index].name
                          ? customerDetails.documents[index].name
                          : null,
                      validator: notEmpty,
                      label: "Name",
                      name: "name",
                    },
                    {
                      type: "TextField",
                      initialvalue:
                        customerDetails.documents.length > 0 &&
                        customerDetails.documents[index].description
                          ? customerDetails.documents[index].description
                          : null,
                      label: "Description (optional)",
                      name: "description",
                    },
                  ]}
                ></Form>
              </Container>
            </CModal>
            <CModal open={qAOpen} setOpen={setQAOpen}>
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={handleQAdd}
                  postAction={null}
                  title="Add Qualification(s)"
                  cancelEnabled
                  cancelAction={() => {
                    setQAOpen(false);
                  }}
                  cancelText="Cancel"
                  submitText="Add"
                  inputFields={[
                    {
                      type: "MultiSelect",
                      label: "Qualifications",
                      choices: qualiChoices
                        .filter(
                          (choice) =>
                            choice.isActive === true &&
                            choice.type === "Customer"
                        )
                        .map((choices) => {
                          return [choices.name, choices.id];
                        }),
                      name: "qualIds",
                      validator: (value) => {
                        if (value.length === 0) {
                          return "Cannot be empty";
                        }
                      },
                      initialvalue: "",
                    },
                  ]}
                ></Form>
              </Container>
            </CModal>
            <GeneralDialog
              open={mROpen}
              close={handleClose}
              title={"Remove Membership(s)"}
              text={
                "Are you sure you want to remove the selected membership(s)?"
              }
              action={handleMRemove}
            />
            <CModal open={mAOpen} setOpen={setMAOpen}>
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={handleMAdd}
                  postAction={null}
                  title="Add Membership(s)"
                  cancelEnabled
                  cancelAction={() => {
                    setMAOpen(false);
                  }}
                  cancelText="Cancel"
                  submitText="Add"
                  inputFields={[
                    {
                      type: "MultiSelect",
                      label: "Memberships",
                      choices: memChoices
                        .filter((choice) => choice.isActive === true)
                        .map((choice) => {
                          return choice.isActive && [choice.name, choice.id];
                        }),
                      name: "memIds",
                      validator: (value) => {
                        if (value.length === 0) {
                          return "Cannot be empty";
                        }
                      },
                      initialvalue: "",
                    },
                  ]}
                ></Form>
              </Container>
            </CModal>
            <GeneralDialog
              open={bOpen}
              close={() => {
                setBOpen(false);
              }}
              title={
                customerDetails.isblocked
                  ? "Unblock Customer"
                  : "Block Customer"
              }
              text={
                customerDetails.isBlocked
                  ? "Are you sure you want to unblock this customer?"
                  : "Are you sure you want to block this customer?"
              }
              action={handleBlock}
            />
            <GeneralDialog
              open={cROpen}
              close={handleClose}
              title={"Remove Custom Pricing"}
              text={
                "Are you sure you want to remove this custom pricing from this customer?"
              }
              action={handleCPRemove}
            />
            <GeneralDialog
              open={eROpen}
              close={handleClose}
              title={"Remove Emergency Contact"}
              text={`Are you sure you want to remove this emergency contact? `}
              action={handleECRemove}
            />
            <GeneralDialog
              open={dROpen}
              close={handleClose}
              title={"Remove Document"}
              text={`Are you sure you want to remove this document? `}
              action={handleDocDelete}
            />
            <CModal open={cAOpen} setOpen={setCAOpen}>
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={handleCPAdd}
                  postAction={null}
                  moreData={{
                    businessId: businessId,
                    customerId: customerIdURL,
                  }}
                  title="New Custom Pricing"
                  cancelEnabled
                  cancelAction={() => {
                    setCAOpen(false);
                  }}
                  cancelText="Cancel"
                  submitText="Create"
                  inputFields={[
                    {
                      type: "SingleSelect",
                      label: "Option",
                      choices: priceChoices
                        .filter((choice) => choice.isActive === true)
                        .map((choice) => {
                          return [
                            `${choice.name} | ${choice.serviceName} | ${choice.siteName}`,
                            choice.id,
                          ];
                        }),
                      name: "optionId",
                      validator: (value) => (!value ? "Cannot be empty" : null),
                      initialvalue: "",
                    },
                    {
                      type: "Currency",
                      currencyCode: "USD",
                      label: "Currency",
                      name: "price",
                      initialvalue: "",
                      currencyInfo: operatingInfo,
                    },
                    {
                      type: "CheckBox",
                      name: "applyDiscounts",
                      label: "Apply Additional Discounts ?",
                      size: {
                        xs: 6,
                      },
                    },
                    {
                      type: "CheckBox",
                      name: "applyAdditionalFee",
                      label: "Apply Additional Fees ?",
                      size: {
                        xs: 6,
                      },
                    },
                    {
                      type: "CheckBox",
                      name: "applyTax",
                      label: "Apply Tax ?",
                      size: {
                        xs: 6,
                      },
                    },
                    {
                      type: "CheckBox",
                      name: "applyTransactionFee",
                      label: "Apply Transaction Fee ?",
                      size: {
                        xs: 6,
                      },
                    },
                  ]}
                ></Form>
              </Container>
            </CModal>
            <CModal open={cEOpen} setOpen={setCEOpen}>
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={handleCPEdit}
                  postAction={handleClose}
                  title="Edit Custom Pricing"
                  cancelEnabled
                  moreData={{
                    businessId: businessId,
                    customerId: customerIdURL,
                    customPriceId: customerDetails.customPricing[index]
                      ? customerDetails.customPricing[index].customPriceId
                      : null,
                  }}
                  cancelAction={() => {
                    setCEOpen(false);
                  }}
                  cancelText="Cancel"
                  submitText="Save"
                  inputFields={[
                    {
                      type: "Currency",
                      currencyCode: "USD",
                      label: "Currency",
                      name: "price",
                      initialvalue:
                        cEOpen && customerDetails.customPricing.length > 0
                          ? customerDetails.customPricing[index].price
                          : null,
                      currencyInfo: operatingInfo,
                    },
                    {
                      type: "CheckBox",
                      name: "applyDiscounts",
                      label: "Apply Additional Discounts ?",
                      initialvalue:
                        cEOpen && customerDetails.customPricing.length > 0
                          ? customerDetails.customPricing[index].applyDiscounts
                          : null,
                      size: {
                        xs: 6,
                      },
                    },
                    {
                      type: "CheckBox",
                      name: "applyAdditionalFee",
                      label: "Apply Additional Fees ?",
                      initialvalue:
                        cEOpen && customerDetails.customPricing.length > 0
                          ? customerDetails.customPricing[index]
                              .applyAdditionalFee
                          : null,
                      size: {
                        xs: 6,
                      },
                    },
                    {
                      type: "CheckBox",
                      name: "applyTax",
                      label: "Apply Tax ?",
                      initialvalue:
                        cEOpen && customerDetails.customPricing.length > 0
                          ? customerDetails.customPricing[index].applyTax
                          : null,
                      size: {
                        xs: 6,
                      },
                    },
                    {
                      type: "CheckBox",
                      name: "applyTransactionFee",
                      label: "Apply Transaction Fee ?",
                      initialvalue:
                        cEOpen && customerDetails.customPricing.length > 0
                          ? customerDetails.customPricing[index]
                              .applyTransactionFee
                          : null,
                      size: {
                        xs: 6,
                      },
                    },
                  ]}
                ></Form>
              </Container>
            </CModal>
            <CModal open={eAOpen} setOpen={setEAOpen}>
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={handleECAdd}
                  postAction={null}
                  title="New Emergency Contact"
                  cancelEnabled
                  cancelAction={() => {
                    setEAOpen(false);
                  }}
                  cancelText="Cancel"
                  submitText="Create"
                  inputFields={[
                    {
                      type: "TextField",
                      initialvalue: "",
                      validator: notEmpty,
                      label: "Contact Name",
                      name: "name",
                    },
                    {
                      type: "PhoneNumber",
                      name: "phoneNumber",
                      label: "Phone Number",
                      initialvalue: "+1",
                      validator: [
                        (value) => {
                          if (value === "+1") {
                            return "Bad number";
                          }
                        },
                        validPhoneNumber,
                      ],
                    },
                    {
                      type: "TextField",
                      initialvalue: "",
                      label: "Contact Email (optional)",
                      name: "email",
                    },
                    {
                      type: "TextField",
                      initialvalue: "",
                      label: "Relation (optional)",
                      name: "relation",
                    },
                  ]}
                ></Form>
              </Container>
            </CModal>
            <CModal open={eEOpen} setOpen={setEEOpen}>
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={handleECEdit}
                  postAction={null}
                  title="Edit Emergency Contact"
                  cancelEnabled
                  cancelAction={() => {
                    setEEOpen(false);
                  }}
                  cancelText="Cancel"
                  submitText="Save"
                  inputFields={[
                    {
                      type: "TextField",
                      validator: notEmpty,
                      label: "Contact Name",
                      name: "name",
                      initialvalue:
                        eEOpen && customerDetails.emergencyContacts.length > 0
                          ? customerDetails.emergencyContacts[index].name
                          : null,
                    },
                    {
                      type: "PhoneNumber",
                      name: "phoneNumber",
                      label: "Phone Number",
                      initialvalue:
                        eEOpen && customerDetails.emergencyContacts.length > 0
                          ? customerDetails.emergencyContacts[index].phoneNumber
                          : null,
                      validator: [
                        (value) => {
                          if (value === "+1") {
                            return "Bad number";
                          }
                        },
                        validPhoneNumber,
                      ],
                    },
                    {
                      type: "TextField",
                      initialvalue:
                        eEOpen && customerDetails.emergencyContacts.length > 0
                          ? customerDetails.emergencyContacts[index].email
                          : null,
                      label: "Contact Email",
                      name: "email",
                    },
                    {
                      type: "TextField",
                      initialvalue:
                        eEOpen && customerDetails.emergencyContacts.length > 0
                          ? customerDetails.emergencyContacts[index].relation
                          : null,
                      label: "Relation",
                      name: "relation",
                    },
                  ]}
                ></Form>
              </Container>
            </CModal>
          </React.Fragment>
          <Grid container item xs={12} direction="row">
            <Grid item xs={6}>
              <div className={classes.topBlock}>
                <span className={classes.topHeading}>CUSTOMER</span>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={classes.topBlock}>
                <span className={classes.rightElem}>
                  <Button
                    className={classes.buttonBasic}
                    onClick={
                      !customerDetails.isBlocked
                        ? (event) => {
                            setAnchorEl(event.currentTarget);
                          }
                        : () => {
                            setBOpen(true);
                          }
                    }
                  >
                    {customerDetails.isBlocked ? (
                      "unblock customer"
                    ) : (
                      <CEllipsisIcon />
                    )}
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    keepMounted
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setBOpen(true);
                        handleClose();
                      }}
                    >
                      Block
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handlePrint();
                        handleClose();
                      }}
                    >
                      Print
                    </MenuItem>
                  </Menu>
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <CustomerDetailsTopBlock
          ref={componentRef}
          customerDetails={customerDetails}
          classes={classes}
          currencyInfo={currencyInfo}
          small={small}
          large={large}
          setNotes={setNotes}
          businessId={businessId}
          customerIdURL={customerIdURL}
        />
        <Grid
          container
          className={large ? classes.mainWrapper : classes.mobilePadding}
        >
          <Grid item xs={12} direction="row" className={classes.headingWrapper}>
            <span className={classes.subHeading}>Attendees</span>
          </Grid>
          <Grid item sx={12} style={{ marginBottom: "20px" }}>
            {customerDetails.attendeeDetails.length != 0 &&
            customerDetails.attendeeDetails.length !== "undefined" ? (
              <React.Fragment>
                <Grid item xs={12}>
                  <div className={classes.contentRow}>
                    <ListTable
                      styling={{ width: "100%" }}
                      identifier={"attendees"}
                      tableHeaders={large ? hostsHeaders : hostsHeadersM}
                      tableRows={customerDetails.attendeeDetails.map((host) => {
                        return {
                          name: (
                            <LightTooltip arrow title="View Attendee Details">
                              <Typography
                                noWrap
                                className={classes.valueTextLink}
                              >
                                <Link
                                  className={classes.valueTextLink}
                                  to={`/dashboard/${businessId}/Attendees/${host.shareId}`}
                                >
                                  {`${host.firstName} ${host.lastName}`}
                                </Link>
                              </Typography>
                            </LightTooltip>
                          ),
                          phoneNumber:
                            host.phoneNumber != null
                              ? `${host.phoneNumber.slice(
                                  0,
                                  2
                                )} (${host.phoneNumber.slice(
                                  2,
                                  5
                                )}) ${host.phoneNumber.slice(
                                  5,
                                  8
                                )}-${host.phoneNumber.slice(8, 12)}`
                              : null,
                          email:
                            host.email != null ? (
                              <LightTooltip title="Email Attendee" arrow>
                                <a
                                  style={{
                                    color: "#0079fc",
                                    textDecorationLine: "underline",
                                  }}
                                  href={"mailto:" + host.email}
                                >
                                  {host.email}
                                </a>
                              </LightTooltip>
                            ) : null,
                        };
                      })}
                      rowClick={null}
                    />
                  </div>
                </Grid>
              </React.Fragment>
            ) : (
              <div className={classes.topMarginBlock}>
                <span className={classes.valueTextPad}>
                  No Attendees for this customer.
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} direction="row" className={classes.headingWrapper}>
            <span className={classes.subHeading}>Order History</span>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {customerDetails.orderHistory.length > 0 ? (
                <ListTable
                  styling={{ width: "100%", maxHeight: "300px" }}
                  identifier={"order history"}
                  tableHeaders={
                    large ? orderHistoryHeaders : orderHistoryHeadersM
                  }
                  tableRows={customerDetails.orderHistory.map((order) => ({
                    id: order.orderId,
                    orderNumber: (
                      <span
                        className={classes.primary}
                        style={{
                          cursor: "pointer",
                          maxWidth: "100%",
                        }}
                        onClick={() => {
                          safePush(
                            `/dashboard/${businessId}/Orders/${order.orderId}`,
                            history,
                            location
                          );
                        }}
                      >
                        {order.orderNumber}
                      </span>
                    ),
                    serviceName: (
                      <span
                        className={classes.primary}
                        style={{
                          cursor: "pointer",
                          maxWidth: "100%",
                        }}
                        onClick={() => {
                          safePush(
                            `/dashboard/${businessId}/Services/${order.serviceId}`,
                            history,
                            location
                          );
                        }}
                      >
                        {order.serviceName}
                      </span>
                    ),
                    optionName: (
                      <span
                        className={classes.primary}
                        style={{
                          cursor: "pointer",
                          maxWidth: "100%",
                        }}
                        onClick={() => {
                          safePush(
                            `/dashboard/${businessId}/Pricings/${order.optionId}`,
                            history,
                            location
                          );
                        }}
                      >
                        {order.optionName}
                      </span>
                    ),
                    purchaseQty: order.purchaseQty,
                    orderAmount: CurrencyPreProcessor({
                      amount: order.orderAmount,
                      currencyBreak: currencyInfo.currencyBreak,
                      roundingFactor: currencyInfo.roundingFactor,
                      currencySymbol: currencyInfo.currencySymbol,
                    }),
                  }))}
                  rowClick={null}
                />
              ) : (
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    This customer has no order history.
                  </span>
                </div>
              )}
            </div>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="row"
            className={classes.headingWrapper}
          >
            <Grid item xs={6}>
              <span className={classes.subHeading}>Qualifications</span>
            </Grid>
            <Grid item xs={6}>
              <LightTooltip
                title={
                  qualList.length === 0
                    ? "Add Qualification"
                    : "Remove Qualification"
                }
                arrow
              >
                {qualList.length > 0 ? (
                  <span className={classes.rightElem}>
                    <Button
                      className={classes.buttonBasic}
                      onClick={() => {
                        setQROpen(true);
                      }}
                    >
                      <CCloseIcon />
                    </Button>
                  </span>
                ) : (
                  <span className={classes.rightElem}>
                    <Button
                      className={classes.buttonBasic}
                      onClick={() => {
                        setQAOpen(true);
                      }}
                    >
                      <CAddIcon />
                    </Button>
                  </span>
                )}
              </LightTooltip>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {customerDetails.qualifications.length !== 0 &&
              customerDetails.qualifications.length !== "undefined" ? (
                <TableContainer
                  style={{ maxHeight: "231px", maxWidth: "100%" }}
                >
                  <Table
                    stickyHeader
                    aria-label="simple table"
                    style={{ tableLayout: "fixed" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.headerPadding}
                          style={{ width: "25px" }}
                        />
                        <TableCell
                          align="left"
                          className={classes.headerPadding}
                          style={{ width: "calc(100%)" }}
                        >
                          <Typography noWrap className={classes.labelText}>
                            Name
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerDetails.qualifications.map((qualification) => {
                        return (
                          <TableRow key={qualification.qualificationId}>
                            <TableCell
                              style={{ width: "5%" }}
                              className={classes.sdCustomCellFour}
                            >
                              <Checkbox
                                style={{ color: "#0079fc" }}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    handleQualChange(
                                      qualification.qualificationId
                                    );
                                  } else {
                                    handleQualRemove(
                                      qualification.qualificationId
                                    );
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.sdCustomCellOne}
                            >
                              <Typography
                                noWrap
                                className={classes.valueTextLink}
                              >
                                <LightTooltip title="Qualification Page" arrow>
                                  <Link
                                    style={{
                                      color: "#0079fc",
                                      textDecoration: "none",
                                    }}
                                    to={`/dashboard/${businessId}/Qualifications/${qualification.qualificationId}`}
                                  >
                                    {qualification.name}
                                  </Link>
                                </LightTooltip>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    No Qualifications applied to this customer.
                  </span>
                </div>
              )}
            </div>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="row"
            className={classes.headingWrapper}
          >
            <Grid item xs={6}>
              <span className={classes.subHeading}>Memberships</span>
            </Grid>
            <Grid item xs={6}>
              <LightTooltip
                title={
                  memLength < 1 && !memLength
                    ? "Add Membership"
                    : "Remove Membership"
                }
                arrow
              >
                {memLength && memLength > 0 ? (
                  <span className={classes.rightElem}>
                    <Button
                      onClick={() => {
                        setMROpen(true);
                      }}
                      className={classes.buttonBasic}
                    >
                      <CCloseIcon />
                    </Button>
                  </span>
                ) : (
                  <span className={classes.rightElem}>
                    <Button
                      onClick={() => {
                        setMAOpen(true);
                      }}
                      className={classes.buttonBasic}
                    >
                      <CAddIcon />
                    </Button>
                  </span>
                )}
              </LightTooltip>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {customerDetails.memberships.length != 0 &&
              customerDetails.memberships.length !== "undefined" ? (
                <TableContainer style={{ maxHeight: "231px", width: "100%" }}>
                  <Table
                    stickyHeader
                    aria-label="simple table"
                    style={{ tableLayout: "fixed" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.headerPadding}
                          style={{ width: "25px" }}
                        />
                        <TableCell
                          align="left"
                          className={classes.headerPadding}
                          style={{ width: `calc(100%)` }}
                        >
                          <Typography noWrap className={classes.labelText}>
                            Name
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerDetails.memberships.map((membership) => {
                        return (
                          <TableRow key={membership.membershipId}>
                            <TableCell className={classes.sdCustomCellFour}>
                              <Checkbox
                                style={{ color: "#0079fc" }}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    handleMemChange(membership.membershipId);
                                  } else {
                                    handleMemRemove(membership.membershipId);
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.sdCustomCellOne}
                            >
                              <Typography noWrap className={classes.valueText}>
                                <LightTooltip title="Membership Page" arrow>
                                  <Link
                                    style={{
                                      color: "#0079fc",
                                      textDecoration: "none",
                                    }}
                                    to={`/dashboard/${businessId}/Memberships/${membership.membershipId}`}
                                  >
                                    {membership.name}
                                  </Link>
                                </LightTooltip>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    This customer has no memberships.
                  </span>
                </div>
              )}
            </div>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="row"
            className={classes.headingWrapper}
          >
            <Grid item xs={6}>
              <span className={classes.subHeading}>Emergency Contacts</span>
            </Grid>
            <Grid item xs={6}>
              <LightTooltip title="Add Emergency Contact" arrow>
                <span className={classes.rightElem}>
                  <Button
                    onClick={() => {
                      setEAOpen(true);
                    }}
                    className={classes.buttonBasic}
                  >
                    <CAddIcon />
                  </Button>
                </span>
              </LightTooltip>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {!small &&
              customerDetails.emergencyContacts.length != 0 &&
              customerDetails.emergencyContacts.length !== "undefined" ? (
                <Grid
                  style={{
                    maxHeight: "129px",
                    overflow: "auto",
                    width: "97%",
                    padding: "5px",
                  }}
                >
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEll}
                    getContentAnchorEl={null}
                    keepMounted
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(anchorEll)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setEEOpen(true);
                        setAnchorEll(false);
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setEROpen(true);
                        setAnchorEll(false);
                      }}
                    >
                      Remove
                    </MenuItem>
                  </Menu>
                  {customerDetails.emergencyContacts.map((contact, index) => {
                    return (
                      <ListCard
                        action={null}
                        cardName={contact.name}
                        cardButton={
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              setAnchorEll(event.currentTarget);
                              setIndex(index);
                            }}
                          >
                            <CEllipsisIcon />
                          </IconButton>
                        }
                        statusReplace={`${contact.phoneNumber.slice(
                          0,
                          2
                        )} (${contact.phoneNumber.slice(
                          2,
                          5
                        )}) ${contact.phoneNumber.slice(
                          5,
                          8
                        )}-${contact.phoneNumber.slice(8, 12)}`}
                        other={
                          <LightTooltip title="Email Contact" arrow>
                            <a
                              style={{ color: "#0079fc" }}
                              href={"mailto:" + contact.email}
                            >
                              {contact.email}
                            </a>
                          </LightTooltip>
                        }
                      />
                    );
                  })}
                </Grid>
              ) : small &&
                customerDetails.emergencyContacts.length != 0 &&
                customerDetails.emergencyContacts.length !== "undefined" ? (
                <TableContainer style={{ maxHeight: "192px", width: "100%" }}>
                  <Table
                    stickyHeader
                    aria-label="simple table"
                    style={{ tableLayout: "fixed" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          className={classes.headerPadding}
                          style={{ width: `calc(40%)` }}
                        >
                          <Typography noWrap className={classes.labelText}>
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.headerPadding}
                          style={{ width: `calc(20%)` }}
                        >
                          <Typography noWrap className={classes.labelText}>
                            Relation
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.headerPadding}
                          style={{ width: "150px" }}
                        >
                          <span className={classes.labelText}>
                            Phone Number
                          </span>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.headerPadding}
                          style={{ width: `calc(40%)` }}
                        >
                          <Typography noWrap className={classes.labelText}>
                            Email
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.headerPadding}
                          style={{ width: "150px" }}
                        >
                          <span className={classes.labelText}>Actions</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerDetails.emergencyContacts.map(
                        (contact, index) => {
                          return (
                            <TableRow key={contact.contactId}>
                              <TableCell
                                align="left"
                                className={classes.sdCustomCellOne}
                              >
                                <Typography
                                  noWrap
                                  className={classes.valueText}
                                >
                                  {contact.name}
                                </Typography>
                              </TableCell>
                              {small ? (
                                <TableCell
                                  style={{ width: "10%" }}
                                  align="left"
                                  className={classes.sdCustomCellTwo}
                                >
                                  <Typography
                                    noWrap
                                    className={classes.valueText}
                                  >
                                    {contact.relation}
                                  </Typography>
                                </TableCell>
                              ) : null}
                              <TableCell
                                align="left"
                                className={classes.sdCustomCellThree}
                              >
                                <span className={classes.valueText}>
                                  {`${contact.phoneNumber.slice(
                                    0,
                                    2
                                  )} (${contact.phoneNumber.slice(
                                    2,
                                    5
                                  )}) ${contact.phoneNumber.slice(
                                    5,
                                    8
                                  )}-${contact.phoneNumber.slice(8, 12)}`}
                                </span>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.sdCustomCellFour}
                              >
                                <LightTooltip
                                  title="Email Emergency Contact"
                                  arrow
                                >
                                  <Typography
                                    noWrap
                                    className={classes.valueText}
                                  >
                                    <a
                                      style={{ color: "#0079fc" }}
                                      href={"mailto:" + contact.email}
                                    >
                                      {contact.email}
                                    </a>
                                  </Typography>
                                </LightTooltip>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.sdCustomCellFour}
                              >
                                <span className={classes.valueText}>
                                  <ButtonGroup
                                    variant="text"
                                    aria-label="text primary button group"
                                    size="small"
                                  >
                                    <Button
                                      style={{
                                        color: "#0079fc",
                                        padingBottom: "5px",
                                        paddingTop: "2px",
                                        textTransform: "none",
                                      }}
                                      onClick={() => {
                                        setIndex(index);
                                        setEEOpen(true);
                                      }}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      style={{
                                        color: "red",
                                        paddingBottom: "5px",
                                        paddingTop: "2px",
                                        textTransform: "none",
                                      }}
                                      onClick={() => {
                                        setEROpen(contact.emergencyContactId);
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  </ButtonGroup>
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    This customer has no emergency contacts.
                  </span>
                </div>
              )}
            </div>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="row"
            className={classes.headingWrapper}
          >
            <Grid item xs={6}>
              <span className={classes.subHeading}>
                Customer Specific Pricing
              </span>
            </Grid>
            <Grid item xs={6}>
              <LightTooltip title="Add Custom Pricing" arrow>
                <span className={classes.rightElem}>
                  <Button
                    onClick={() => {
                      setCAOpen(true);
                    }}
                    className={classes.buttonBasic}
                  >
                    <CAddIcon />
                  </Button>
                </span>
              </LightTooltip>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {customerDetails.customPricing.length !== 0 &&
              customerDetails.customPricing.length !== "undefined" ? (
                <TableContainer style={{ maxHeight: "216px", width: "100%" }}>
                  <Table
                    stickyHeader
                    aria-label="simple table"
                    style={{ tableLayout: "fixed" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          className={classes.headerPadding}
                          style={{ width: `calc(${small ? "40%" : "100%"})` }}
                        >
                          <Typography noWrap className={classes.labelText}>
                            Option
                          </Typography>
                        </TableCell>
                        {!small ? null : (
                          <React.Fragment>
                            <TableCell
                              align="left"
                              className={classes.headerPadding}
                              style={{ width: `calc(30%)` }}
                            >
                              <Typography noWrap className={classes.labelText}>
                                Service
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.headerPadding}
                              style={{ width: `calc(30%)` }}
                            >
                              <Typography noWrap className={classes.labelText}>
                                Site
                              </Typography>
                            </TableCell>
                          </React.Fragment>
                        )}
                        <TableCell
                          align="left"
                          className={classes.headerPadding}
                          style={{ width: "50px" }}
                        >
                          <span className={classes.labelText}>Price</span>
                        </TableCell>
                        {/* <TableCell align="left" className={classes.headerPadding} style={{ width: "60px" }}>
                        <span className={classes.labelText} style={{ whiteSpace: "Normal" }}>Additional Discounts</span>
                      </TableCell> */}
                        <TableCell
                          align="center"
                          className={classes.headerPadding}
                          style={{ width: "100px" }}
                        >
                          <span className={classes.labelText}>Actions</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerDetails.customPricing.map((price, index) => {
                        return (
                          <TableRow key={price.customPriceId}>
                            <TableCell
                              align="left"
                              className={classes.sdCustomCellOne}
                            >
                              <LightTooltip
                                title={`${price.optionName} | ${price.serviceName} | ${price.siteName}`}
                                arrow
                              >
                                <Typography
                                  noWrap
                                  className={classes.valueText}
                                >
                                  <Link
                                    style={{
                                      color: "#0079fc",
                                      textDecoration: "none",
                                    }}
                                    to={`/dashboard/${businessId}/Pricings/${price.optionId}`}
                                  >
                                    {`${price.optionName}`}
                                  </Link>
                                </Typography>
                              </LightTooltip>
                            </TableCell>
                            {small ? (
                              <React.Fragment>
                                <TableCell
                                  align="left"
                                  className={classes.sdCustomCellOne}
                                >
                                  <Typography
                                    noWrap
                                    className={classes.valueText}
                                  >
                                    <LightTooltip title="Service Page" arrow>
                                      <Link
                                        style={{
                                          color: "#0079fc",
                                          textDecoration: "none",
                                        }}
                                        to={`/dashboard/${businessId}/Services/${price.serviceId}`}
                                      >
                                        {price.serviceName}
                                      </Link>
                                    </LightTooltip>
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={classes.sdCustomCellOne}
                                >
                                  <Typography
                                    noWrap
                                    className={classes.valueText}
                                  >
                                    <LightTooltip title="Site Page" arrow>
                                      <Link
                                        style={{
                                          color: "#0079fc",
                                          textDecoration: "none",
                                        }}
                                        to={`/dashboard/${businessId}/Sites/${price.siteId}`}
                                      >
                                        {price.siteName}
                                      </Link>
                                    </LightTooltip>
                                  </Typography>
                                </TableCell>
                              </React.Fragment>
                            ) : null}
                            <TableCell
                              align="left"
                              className={classes.sdCustomCellOne}
                            >
                              <span className={classes.valueText}>
                                <CurrencyPreProcessor
                                  amount={price.price}
                                  currencyBreak={currencyInfo.currencyBreak}
                                  roundingFactor={currencyInfo.roundingFactor}
                                  currencySymbol={currencyInfo.currencySymbol}
                                />
                              </span>
                            </TableCell>
                            {/* <TableCell align="left" className={classes.sdCustomCellOne}>
                            <span className={classes.valueText}>
                              {price.applyDiscounts ? "Yes" : "No"}
                            </span>
                          </TableCell> */}
                            <TableCell
                              align="center"
                              className={classes.sdCustomCellTwo}
                            >
                              <span className={classes.valueText}>
                                <ButtonGroup
                                  variant="text"
                                  aria-label="text primary button group"
                                  size="small"
                                >
                                  <Button
                                    style={{
                                      color: "#0079fc",
                                      paddingTop: "2px",
                                      paddingBottom: "5px",
                                      textTransform: "none",
                                    }}
                                    onClick={() => {
                                      setCEOpen(true);
                                      setIndex(index);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    style={{
                                      color: "red",
                                      paddingTop: "2px",
                                      paddingBottom: "5px",
                                      textTransform: "none",
                                    }}
                                    onClick={() => {
                                      setCROpen(true);
                                      setIndex(index);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </ButtonGroup>
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    This customer has no custom pricings.
                  </span>
                </div>
              )}
            </div>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="row"
            className={classes.headingWrapper}
          >
            <Grid item xs={6}>
              <span className={classes.subHeading}>Documents</span>
            </Grid>
            <Grid item xs={6}>
              <LightTooltip title="Add Document" arrow>
                <span className={classes.rightElem}>
                  <input
                    accept="application/pdf, .docx"
                    onChange={(file) => {
                      handleFile(file);
                    }}
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      variant="raised"
                      component="span"
                      className={classes.buttonBasic}
                    >
                      <CAddIcon />
                    </Button>
                  </label>
                </span>
              </LightTooltip>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {customerDetails.documents.length != 0 &&
              customerDetails.documents.length !== "undefined" ? (
                <TableContainer>
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          className={classes.headerPadding}
                          style={{ width: `calc(40%)` }}
                        >
                          <Typography noWrap className={classes.labelText}>
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.headerPadding}
                          style={{ width: `calc(60%)` }}
                        >
                          <Typography noWrap className={classes.labelText}>
                            Description
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.headerPadding}
                          style={{ width: "150px" }}
                        >
                          <Typography noWrap className={classes.labelText}>
                            {" "}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerDetails.documents.map((document, index) => {
                        return (
                          <TableRow key={document.documentId}>
                            <TableCell
                              align="left"
                              className={classes.sdCustomCellOne}
                            >
                              <span className={classes.valueTextLink}>
                                {document.name}
                              </span>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.sdCustomCellTwo}
                            >
                              <span className={classes.valueTextLink}>
                                {document.description}
                              </span>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.sdCustomCellFour}
                            >
                              <span className={classes.valueText}>
                                <ButtonGroup
                                  variant="text"
                                  aria-label="text primary button group"
                                >
                                  <Button
                                    style={{
                                      color: "#0079fc",
                                      paddingTop: "2px",
                                      paddingBottom: "5px",
                                      textTransform: "none",
                                    }}
                                    onClick={() => {
                                      getDocument(document.docKey);
                                    }}
                                  >
                                    View
                                  </Button>
                                  <Button
                                    style={{
                                      color: "#0079fc",
                                      paddingTop: "2px",
                                      paddingBottom: "5px",
                                      textTransform: "none",
                                    }}
                                    onClick={() => {
                                      setDocEditForm(true);
                                      setIndex(index);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    style={{
                                      color: "red",
                                      paddingTop: "2px",
                                      paddingBottom: "5px",
                                      textTransform: "none",
                                    }}
                                    onClick={() => {
                                      setDROpen(true);
                                      setIndex(index);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </ButtonGroup>
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.topMarginBlock}>
                  <span className={classes.valueTextPad}>
                    This customer has no documents.
                  </span>
                </div>
              )}
            </div>
          </Grid>
          {small ? (
            <Grid item xs={12}>
              <div className={classes.bottomSpacer}></div>
            </Grid>
          ) : null}
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default NewCustomer;
