import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    comp:{
        width:"100%",
        float: "left"
    },
    formLabel: {
        fontSize: 14,
        color: "#3C4257"
    },
    fieldText: {
        fontSize: 16,
        color: "#1A1F36",
        padding: "10px"
    }
}));

const getFromArray = (error, index) => {
    if(Array.isArray(error)){
        return(error[index])
    }
}


const CDateRange = (props) => {
    useEffect(()=>{
        props.updateGlob()
        if(Array.isArray(props.initialvalue)){
            props.updateGlob(props.initialvalue[0], 0)
            props.updateGlob(props.initialvalue[1], 1)
            
        }
    }, [])
    const classes = useStyles();
    const intoInputProps = (props.moreProps && props.moreProps.InputProps ? props.moreProps.InputProps : {})
    intoInputProps.classes = { input: classes.fieldText };
    const otherProps = { ...props.moreProps, InputProps: intoInputProps }
    return (
        <Grid container spacing = {3}>
            <Grid item xs = {6} >
                <Typography className={classes.formLabel}>
                    {props.label[0]}
                </Typography>
                <TextField
                    type="date"
                    error={(typeof getFromArray(props.errortext, 0) == "string" && getFromArray(props.errortext, 0) !== "" ? true : false)}
                    helperText = {(typeof getFromArray(props.errortext, 0) == "string" && getFromArray(props.errortext, 0) !== "" ? getFromArray(props.errortext, 0) : null)}
                    defaultValue = {(typeof getFromArray(props.initialvalue, 0) == "string" && getFromArray(props.initialvalue, 0) !== "" ? getFromArray(props.initialvalue, 0).replaceAll('/', '-') : null)}
                    className={classes.comp}
                    variant = "outlined"
                    {...otherProps}
                    onChange = {(event)=>{
                        props.updateGlob(event.target.value.replaceAll('-', '/'), 0)
                        props.onchange(event)
                    }}
                    
                ></TextField>
            </Grid>
            <Grid item xs = {6}>
                <Typography className={classes.formLabel}>
                    {props.label[1]}
                </Typography>
                <TextField
                    type="date"
                    error={(typeof getFromArray(props.errortext, 1) == "string" && getFromArray(props.errortext, 1) !== "" ? true : false)}
                    helperText = {(typeof getFromArray(props.errortext, 1) == "string" && getFromArray(props.errortext, 1) !== "" ? getFromArray(props.errortext, 1) : null)}
                    defaultValue = {(typeof getFromArray(props.initialvalue, 1) == "string" && getFromArray(props.initialvalue, 1) !== "" ? getFromArray(props.initialvalue, 1).replaceAll('/', '-') : null)}
                    className={classes.comp}
                    variant = "outlined"
                    {...otherProps}
                    onChange = {(event)=>{
                        props.updateGlob(event.target.value.replaceAll('-', '/'), 1)
                        props.onchange(event)
                    }}
                ></TextField>
            </Grid>
        </Grid>
    )
}

export default CDateRange