import { Container, MenuItem, Typography, makeStyles } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import validDates from "../../../../../../FormValidation/validDates"
import { validTime, validTimeRange } from "../../../../../../FormValidation/validTime"
import { getGenericDetails, massEditGroupClass, withLoading } from "../../../../../../utils"
import CModal from "../../../../../Form/CModal"
import Form from "../../../../../Form/Form"


const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        width: "100%",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    },
    buttonBasic: {
        fontSize: "14px",
        marginLeft: '10px',
        textTransform: "none",
    },
}))

const MassEditForm = (props) => {

    const { fetchGroupClasses } = props
    const mounted = useRef(false)
    const [open, setOpen] = useState(false)
    const classes = useStyles()
    const businessId = useSelector(state => state.buisnessDetails.businessId)
    const [sites, setSites] = useState([])
    const [services, setServices] = useState([])
    const [staffs, setStaffs] = useState([])
    const [resources, setResources] = useState([])
    const [firstPayload, setFirsPlayload] = useState(null)

    const setUp = async () => {
        const response = await withLoading(getGenericDetails, {
            businessId
        })
        if (!response?.error
            && response?.genericDetails?.siteDetails
            && response?.genericDetails?.serviceDetails
            && response?.genericDetails?.staffDetails
            && response?.genericDetails?.resourceDetails
            && mounted.current) {

            setSites(response.genericDetails.siteDetails)
            setServices(response.genericDetails.serviceDetails)
            setStaffs(response.genericDetails.staffDetails)
            setResources(response.genericDetails.resourceDetails)
        }
    }

    useEffect(() => {
        mounted.current = true
        if (open === true) {
            setUp()
        }
        else {
            setSites([])
            setServices([])
            setSites([])
            setResources([])
            setFirsPlayload(null)
        }
        return () => mounted.current = false
    }, [open])

    return (
        <React.Fragment>
            <CModal open={open} setOpen={setOpen}>
                <Container className={classes.formContainer}>
                    {firstPayload === null ?
                        <Form
                            key="first"
                            moreData={{

                            }}
                            title={`Mass Edit Classes`}
                            cancelEnabled
                            cancelAction={() => { setOpen(false) }}
                            submitText={"Next"}
                            action={payload => setFirsPlayload(payload)}
                            inputFields={[
                                {
                                    type: "SingleSelect",
                                    name: "siteId",
                                    label: "Site",
                                    choices: sites.map(site => [site.name, site.siteId]),
                                    validator: value => (value ? null : "Please select a site")
                                },
                                {
                                    type: "SingleSelect",
                                    name: "serviceId",
                                    label: "Service",
                                    choices: services.map(service => [service.name, service.serviceId]),
                                    validator: value => (value ? null : "Please select a service")
                                },
                                {
                                    type: "DateRange",
                                    name: ["startDate", "endDate"],
                                    label: ["Start Date", "End Date"],
                                    validator: validDates
                                },
                                {
                                    type: "TimeRange",
                                    name: ["startTime", "endTime"],
                                    label: ["Start Time", "End Time"],
                                    validator: [
                                        values => [validTime(values[0]), validTime(values[1])],
                                        validTimeRange
                                    ]
                                },
                                {
                                    type: "DaysPicker",
                                    name: "dayObj",
                                    label: "Days",
                                    validator: (value) => {
                                        const allDays = [
                                            "sun",
                                            "mon",
                                            "tue",
                                            "wed",
                                            "thr",
                                            "fri",
                                            "sat",
                                        ];
                                        return allDays.every((day) => value[day] === false)
                                            ? "A day must be selected"
                                            : null;
                                    },
                                },
                                {
                                    type: "RadioSet",
                                    name: "operation",
                                    label: "Operation",
                                    validator: (value) => (value ? null : "Please select an operation"),
                                    choices: [
                                        ['Add', 'Add'],
                                        ['Remove', 'Remove']
                                    ],
                                    initialvalue: "Add"
                                }
                            ]}
                        />
                        :
                        <Form
                            key="second"
                            title={`${firstPayload.operation} Resources and/or Hosts`}
                            cancelEnabled
                            cancelAction={() => { setOpen(false) }}
                            submitText={"Submit"}
                            clearEnabled
                            clearAction={() => setFirsPlayload(null)}
                            clearText="Back"
                            action={async payload => await massEditGroupClass({ ...firstPayload, ...payload, businessId })}
                            postAction={(response) => {
                                if (response && !response.error) {
                                    setOpen(false)
                                    fetchGroupClasses()
                                }
                            }}
                            inputFields={[
                                {
                                    type: "MultiSelect",
                                    name: "hostIds",
                                    label: "Hosts",
                                    choices: staffs
                                        .filter(staff => firstPayload.operation === "Remove" || staff.isActive)
                                        .map(staff => [`${staff.firstName} ${staff.lastName}`, staff.staffId])
                                },
                                {
                                    type: "MultiSelect",
                                    name: "resourceIds",
                                    label: "Resources",
                                    choices: resources
                                        .filter(resource => (firstPayload.operation === "Remove" || resource.isActive) && resource.siteId === firstPayload.siteId)
                                        .map(resource => [`${resource.name}${resource.description ? ' - ' + resource.description : ""}`, resource.resourceId])
                                }
                            ]}
                        />
                    }

                </Container>
            </CModal>
            <MenuItem onClick={() => setOpen(true)}>
                <Typography>
                    Mass Edit
                </Typography>
            </MenuItem>
        </React.Fragment>
    )
}

export default MassEditForm