import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Collapse,
  Container,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  bookOrder,
  checkoutCard,
  createList,
  customerAttendeeList,
  customerGroupSlots,
  customerPrivateSlots,
  formatDuration,
  retrieveGetParams,
  safeQuery,
  withLoading,
} from "../../utils";
import CurrencyPreProcessor from "../CurrencyFormatter/CurrencyFormatter";
import CButtonPair from "../Form/CButtonPair";
import CModal from "../Form/CModal";
import CModalError from "../Form/CModalError";
import CTitle from "../Form/CTitle";
import LightTooltip from "../Form/LightTooltip";
import ListTable from "../TableComponents/ListTable";
import TwoColumn from "../TwoColumn/TwoColumn";
import AttendeeButton, { processAttendeeList } from "./AttendeeButton";
import PaymentChoice from "./PaymentChoice";
import SelectSession from "./SelectSession";
import ShowAgreement from "./ShowAgreement";

const processCurrency = (amount, currencyBreak, roundingFactor) => {
  amount = parseInt(amount);
  currencyBreak = parseInt(currencyBreak);
  roundingFactor = parseInt(roundingFactor);
  let output = parseFloat(
    Math.abs(parseFloat(amount / currencyBreak)).toFixed(roundingFactor)
  );
  return output;
};

const options = {
  autoConfig: true,
  debug: false,
};

const advancedMatching = {};

const useStyles = makeStyles((theme) => ({
  iconButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.accent1.main,
      color: "black",
    },
  },
  link: {
    cursor: "pointer",
  },
  success: {
    color: theme.palette.success.main,
  },
  spacingTop: {
    paddingTop: "20px !important",
  },
  headingWrapper: {
    paddingBottom: "10px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  textColor2: {
    color: `${theme.palette.textColor2.main} !important`,
  },
  textColor3: {
    color: `${theme.palette.textColor3.main} !important`,
  },
  primaryText: {
    color: `${theme.palette.primaryText.main} !important`,
  },
  primary: {
    color: `${theme.palette.primary.main} !important`,
  },
  bottomSpacer: {
    height: "50px",
  },
  offset2spacing: {
    padding: 8,
    maxWidth: 1440,
    margin: "0 auto",
  },
  checkoutCard: {
    width: "100%",
    minWidth: "500px",
    backgroundColor: theme.palette.accent2.main,
  },
  accordionSummary: {
    backgroundColor: theme.palette.accent2.main,
  },
  minusIcon: {
    height: 14,
    width: 14,
    padding: 2,
    marginRight: 10,
  },
  plusIcon: {
    height: 14,
    width: 14,
    padding: 2,
    marginLeft: 10,
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
    width: "100vw",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  basicDetails: {
    width: "calc(100% - 150px)",
    paddingLeft: "30px !important",
  },
  buttonText: {
    textTransform: "none",
    cursor: "pointer",
    fontSize: 14,
  },
  fullWidth: {
    width: "100%",
  },
  accordionContent: {
    padding: 10,
  },
  accordion: {
    border: `1px solid ${theme.palette.accent1.main}`,
    boxSizing: "border-box",
  },
  accordionError: {
    border: "1px solid red",
    boxSizing: "border-box",
  },
  error: {
    color: "red",
  },
  quantityField: {
    padding: 2,
    paddingBottom: 3,
  },
  iconButtonPadding: {
    padding: 0,
  },
}));

const checkAttendeeDetails = (attendeeList, questions) => {
  if (Array.isArray(attendeeList) && Array.isArray(questions)) {
    for (let i = 0; i < attendeeList.length; i++) {
      if ([null, undefined].includes(attendeeList[i])) {
        return false;
      }
      if (!attendeeList[i].attendeeId) {
        return false;
      }
      for (let j = 0; j < questions.length; j++) {
        if (
          [null, undefined].includes(
            safeQuery(attendeeList, [
              i,
              "attendeeAnswers",
              questions[j].questionId,
            ])
          )
        ) {
          return false;
        }
      }
    }
  }
  return true;
};
const anyErrors = (errors) => {
  return [
    "internalError",
    "sessionError",
    "attendeeError",
    "agreementError",
    "paymentError",
  ].some((key) => errors[key].length > 0);
};

const checkForUrlParams = async (payload) => {
  const getParams = retrieveGetParams(window.location);
  if (payload.staffId && payload.optionId && getParams.utcDate) {
    let data = await withLoading(customerPrivateSlots, {
      ...payload,
    });
    if (data && data.slotDetails) {
      for (let i = 0; i < data.slotDetails.length; i++) {
        for (let j = 0; j < data.slotDetails[i].slots.length; j++) {
          if (data.slotDetails[i].slots[j].utcDate === getParams.utcDate) {
            return {
              calendarEditTag: data.calendarEditTag,
              choice: {
                data: getParams.utcDate,
                time: data.slotDetails[i].slots[j].time,
              },
              dateChoice: data.slotDetails[i].dateStr,
            };
          }
        }
      }
    }
  } else if (payload.optionId && getParams.classId) {
    let data = await withLoading(customerGroupSlots, {
      ...payload,
    });
    if (data && data.classDetails) {
      for (let i = 0; i < data.classDetails.length; i++) {
        for (let j = 0; j < data.classDetails[i].classes.length; j++) {
          if (data.classDetails[i].classes[j].classId === getParams.classId) {
            return {
              calendarEditTag: data.calendarEditTag,
              choice: {
                classEditTag: data.classDetails[i].classes[j].classEditTag,
                data: getParams.classId,
                time: data.classDetails[i].classes[j].time,
              },
              dateChoice: data.classDetails[i].dateStr,
            };
          }
        }
      }
    }
  }
};

const parseAddons = (addonList) => {
  const addonObj = {};
  const retValue = [];
  addonList.forEach((addOnSet) => {
    const addons = Array.from(addOnSet);
    addons.forEach((addon) => {
      if (addonObj[addon] === undefined) {
        addonObj[addon] = 1;
      } else {
        addonObj[addon] += 1;
      }
    });
  });
  for (const [key, value] of Object.entries(addonObj)) {
    retValue.push({
      addOnId: key,
      quantity: value,
    });
  }
  return retValue;
};

const calcInstallmentAmountDue = (checkoutData, optionDetails) => {
  const total = checkoutData.checkoutCard.totalLine.total;
  const splits = optionDetails.installmentCount;
  return CurrencyPreProcessor({
    amount: total / splits,
    ...checkoutData.currencyInfo,
  });
};

const customerAttendeeListToMap = (customerAttendees) => {
  const attendeeMap = new Map();
  customerAttendees.forEach((attendeeDetail) => {
    attendeeMap.set(attendeeDetail.attendeeId, attendeeDetail);
  });
  return attendeeMap;
};

const fetchAttendeeDetailList = async (updateState) => {
  const response = await customerAttendeeList({});
  if (response && !response.error) {
    updateState({
      list: response.attendees,
      map: customerAttendeeListToMap(response.attendees),
    });
    return true;
  }
  return false;
};

const Checkout = (props) => {
  const [rendered, setRendered] = useState(false);
  const loading = useSelector((state) => state.loading);
  const [displayCheckout, setDisplayCheckout] = useState(false);
  const history = useHistory();
  const tableTooBig = useMediaQuery("(max-width: 1000px)", { noSsr: true });
  const [mobilePricing, setMobilePricing] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [attendeeList, setAttendeeList] = useState(null);
  const [customerAttendees, setCustomerAttendees] = useState({
    list: [],
    map: new Map(),
  });
  const [addonList, setAddonList] = useState(null);
  const [agreementList, setAgreementList] = useState(null);
  const [bookingChoice, setBookingChoice] = useState(null);
  const [errors, setErrors] = useState({});
  const [checkoutData, setCheckoutData] = useState(null);
  const [finalCheck, setFinalCheck] = useState(false);
  const classes = useStyles();
  const details = safeQuery(props, ["customerSelection", "details"]);
  const optionDetails = safeQuery(props, [
    "customerSelection",
    "optionDetails",
  ]);
  const staffDetails = safeQuery(props, ["customerSelection", "staffDetails"]);
  const [customerCard, setCustomerCard] = useState(null);
  const [newCustomerCard, setNewCustomerCard] = useState(null);
  const [extData, setExtData] = useState(null);
  const [installment, setInstallment] = useState(false);
  const [showQualifications, setShowQualifications] = useState(true)

  const acceptAgreement = (agreementId, index) => {
    if (Array.isArray(agreementList)) {
      const temp = [...agreementList];
      temp[index] = agreementId;
      setAgreementList(temp);
    }
  };
  const updateCardInfo = async () => {
    let data = await withLoading(checkoutCard, {
      optionId: optionDetails.optionId,
      purchaseQty: quantity,
      selectedAddOn: Array.isArray(addonList) ? parseAddons(addonList) : [],
    });
    if (data) {
      setCheckoutData(data);
    }
  };

  useEffect(() => {
    setMobilePricing(false);
  }, [tableTooBig]);

  const handleSubmit = async () => {
    let data = await withLoading(bookOrder, finalCheck);
    if (data) {
      let fbPixelId = details?.businessDetails?.fbPixelId;
      if (fbPixelId) {
        ReactPixel.init(fbPixelId, advancedMatching, options);
        ReactPixel.trackSingle(fbPixelId, "Purchase", {
          currency: checkoutData.currencyInfo.currencyShortName,
          value: Math.abs(
            parseFloat(
              finalCheck.total / checkoutData.currencyInfo.currencyBreak
            )
          ).toFixed(checkoutData.currencyInfo.roundingFactor),
        }); // TODO change to be dynamic
      }

      let googleTrackingId = details?.businessDetails?.googleTrackingId;
      if (googleTrackingId) {
        ReactGA.initialize(googleTrackingId, {
          gaOptions: {
            debug_mode: process.env.REACT_APP_GA_DEBUG_MODE,
          },
          gtagOptions: {
            debug_mode: process.env.REACT_APP_GA_DEBUG_MODE,
          },
        });

        let currencyBreak = checkoutData.currencyInfo.currencyBreak;
        let roundingFactor = checkoutData.currencyInfo.roundingFactor;
        let orderTotal = checkoutData.checkoutCard.totalLine.total;
        let transaction_id = data.orderId;
        let currency = checkoutData.currencyInfo.currencyShortName;
        let value = processCurrency(orderTotal, currencyBreak, roundingFactor);
        let discountTotal = 0;
        let discountsArray = checkoutData.checkoutCard.discountLines;
        for (let i = 0; i < discountsArray.length; i++) {
          discountTotal = discountTotal + discountsArray[i].calculatedDiscount;
        };

        let discountPerItem = 0
        if (discountTotal > 0) {
          discountPerItem = Math.round(discountTotal / checkoutData.checkoutCard.offeringLine.purchaseQty)
        };

        let unitPricePerItem = checkoutData.checkoutCard.offeringLine.unitPrice - discountPerItem;

        let items = new Array();

        let index = 0;

        items.push({
          item_name: checkoutData.checkoutCard.offeringLine.optionName,
          quantity: checkoutData.checkoutCard.offeringLine.purchaseQty,
          price: processCurrency(
            unitPricePerItem,
            currencyBreak,
            roundingFactor
          ),
          discount: processCurrency(
            discountPerItem,
            currencyBreak,
            roundingFactor
          ),
          item_category: optionDetails.offeringType,
          item_category2: details.serviceDetails.name,
          item_category3: "Pricing Option",
          index
        });

        let feeArray = checkoutData.checkoutCard.feeLines;
        let addOnArray = checkoutData.checkoutCard.addOnLines;
        let taxArray = checkoutData.checkoutCard.taxLines;

        for (let i = 0; i < feeArray.length; i++) {
          index += 1
          items.push({
            item_name: feeArray[i].feeName,
            quantity: 1,
            price: processCurrency(
              feeArray[i].calculatedFee,
              currencyBreak,
              roundingFactor
            ),
            item_category: optionDetails.offeringType,
            item_category2: details.serviceDetails.name,
            item_category3: "Fee",
            index
          });
        }

        for (let i = 0; i < addOnArray.length; i++) {
          index += 1
          items.push({
            item_name: addOnArray[i].addOnName,
            quantity: addOnArray[i].purchaseQty,
            price: processCurrency(
              addOnArray[i].unitPrice,
              currencyBreak,
              roundingFactor
            ),
            item_category: optionDetails.offeringType,
            item_category2: details.serviceDetails.name,
            item_category3: "Add On",
            index
          });
        }

        for (let i = 0; i < taxArray.length; i++) {
          index += 1
          items.push({
            item_name: taxArray[i].TaxName,
            quantity: 1,
            price: processCurrency(
              taxArray[i].calculatedTax,
              currencyBreak,
              roundingFactor
            ),
            item_category: optionDetails.offeringType,
            item_category2: details.serviceDetails.name,
            item_category3: "Tax",
            index
          });
        }

        if (
          checkoutData.checkoutCard?.transactionLine &&
          checkoutData.checkoutCard?.transactionLine.calculatedFee > 0
        ) {
          index += 1
          items.push({
            item_name: "Transaction Fee",
            quantity: 1,
            price: processCurrency(
              checkoutData.checkoutCard?.transactionLine.calculatedFee,
              currencyBreak,
              roundingFactor
            ),
            item_category: optionDetails.offeringType,
            item_category2: details.serviceDetails.name,
            item_category3: "Fee",
            index
          });
        }

        ReactGA.event("purchase", {
          currency,
          value,
          transaction_id,
          items,
        });
      }

      setFinalCheck(false);
      history.replace(`/account/Orders/${data.orderId}`);
    }
  };

  useEffect(() => {
    let mounted = true;
    const setup = async () => {
      if (rendered === true) {
        let data;
        if (
          safeQuery(details, ["serviceDetails", "type"]) === "Private" &&
          staffDetails
        ) {
          data = await checkForUrlParams({
            staffId: staffDetails.staffId,
            optionId: optionDetails.optionId,
          });
        } else if (safeQuery(details, ["serviceDetails", "type"]) === "Group") {
          data = await checkForUrlParams({
            optionId: optionDetails.optionId,
          });
        }
        if (data && mounted) {
          setBookingChoice(data);
        }
        fetchAttendeeDetailList(setCustomerAttendees);
      }
    };
    setup();
    return () => {
      mounted = false;
    };
  }, [rendered]);

  useEffect(() => {
    let fbPixelId = details?.businessDetails?.fbPixelId;
    if (fbPixelId) {
      ReactPixel.init(fbPixelId, advancedMatching, options);
      ReactPixel.trackSingle(fbPixelId, "AddToCart", {
        content_name: optionDetails.name,
      });
    }

    let googleTrackingId = details?.businessDetails?.googleTrackingId;
    if (googleTrackingId) {
      ReactGA.initialize(googleTrackingId, {
        gaOptions: {
          debug_mode: process.env.REACT_APP_GA_DEBUG_MODE,
        },
        gtagOptions: {
          debug_mode: process.env.REACT_APP_GA_DEBUG_MODE,
        },
      });

      ReactGA.event("add_to_cart", {
        items: [
          {
            item_name: optionDetails?.name
              ? optionDetails?.name
              : "Unknown Option",
            quantity: 1,
            item_category: optionDetails.offeringType,
            item_category2: details.serviceDetails.name,
            price: processCurrency(
              optionDetails.price,
              details.businessDetails.currencyInfo.currencyBreak,
              details.businessDetails.currencyInfo.roundingFactor
            ),
          },
        ],
      });
    }

    if (optionDetails) {
      setExtData({
        quantiyFixed:
          safeQuery(optionDetails, ["type"]) === "Unit" &&
          safeQuery(details, ["serviceDetails", "type"]) === "Private",
        showSessions: safeQuery(optionDetails, ["type"]) === "Unit",
        showTAC: safeQuery(optionDetails, ["agreements", "length"]) > 0,
      });
    }
  }, []);

  useEffect(() => {
    if (optionDetails) {
      setShowQualifications(true)
      setAgreementList(
        createList(safeQuery(optionDetails, ["agreements", "length"]))
      );
      setAttendeeList(createList(quantity));
      setAddonList(createList(quantity, () => new Set()));
      setBookingChoice(null);
      setFinalCheck(null);
      updateCardInfo();
      setErrors({
        sessionError: [],
        paymentError: [],
        agreementError: [],
        attendeeError: [],
        internalError: [],
      });
      setDisplayCheckout(true);
      setRendered(true);
    }
  }, [quantity]);

  useEffect(() => {
    if (optionDetails && rendered && Array.isArray(addonList)) {
      updateCardInfo();
    }
  }, [addonList]);

  if (details && optionDetails && displayCheckout && extData) {
    const sessionError = (sessionData) => {
      const errorsObject = [];
      if (optionDetails.type === "Unit" && !sessionData.calendarEditTag) {
        if (
          safeQuery(details, ["serviceDetails", "type"]) === "Private" &&
          !sessionData.utcDate
        ) {
          errorsObject.push("Must select a time slot");
        } else if (
          safeQuery(details, ["serviceDetails", "type"]) === "Group" &&
          !sessionData.classId
        ) {
          errorsObject.push("Must select a class");
        }
      }
      return errorsObject;
    };

    const paymentError = (paymentData) => {
      const errorsObject = [];
      if (paymentData.newCard && !paymentData.token) {
        errorsObject.push("Must enter new card information");
      }
      if (!paymentData.newCard && !paymentData.cardId) {
        errorsObject.push("Must provide credit card");
      }
      return errorsObject;
    };

    const agreementError = (agreementData) => {
      const errorsObject = [];
      if (
        agreementData.acceptedAgreementIds.some(
          (agreementId, index) =>
            agreementId !==
            safeQuery(optionDetails, ["agreements", index, "agreementId"])
        )
      ) {
        errorsObject.push("Must agree to all terms and conditions");
      }
      return errorsObject;
    };

    const attendeeError = (attendeeData) => {
      const errorsObject = [];
      if (
        !checkAttendeeDetails(
          attendeeData.attendeeList,
          safeQuery(details, ["serviceDetails", "questions"])
        )
      ) {
        errorsObject.push("Attendee information not completed");
      }
      return errorsObject;
    };

    const otherError = (data) => {
      const errorsObject = [];
      if (
        !data.optionId ||
        !data.serviceEditTag ||
        !data.optionEditTag ||
        data.total === undefined ||
        !data.purchaseQty
      ) {
        // return broken
        errorsObject.push("Internal Error");
      }
      return errorsObject;
    };
    const checkInfo = (data, bookingType, total) => {
      return {
        sessionError: sessionError(data),
        paymentError: (bookingType === "Offline" || total === 0) ? [] : paymentError(data),
        agreementError: agreementError(data),
        attendeeError: attendeeError(data),
        internalError: otherError(data),
      };
    };

    const getNewCardInfo = async (stripe, cardElement, save) => {
      if (!stripe || !cardElement) {
        return undefined;
      }
      const { error, token } = await stripe.createToken(cardElement);
      if (error) {
        return undefined;
      } else if (token) {
        return {
          token: token.id,
          brand: token.card.brand,
          last4: token.card.last4,
          save: save,
        };
      }
    };

    const getAttendeeDetails = async () => {
      const processedAttendeeList = processAttendeeList(
        attendeeList,
        addonList,
        safeQuery(details, ["serviceDetails", "questions"])
      );
      return {
        attendeeList: processedAttendeeList,
      };
    };

    const getAgreementDetails = async () => {
      return {
        acceptedAgreementIds: agreementList,
      };
    };

    const getPaymentDetails = async (bookingType, total) => {
      if (bookingType === "Offline" || total === 0) {
        return {
          total: checkoutData
            ? checkoutData.checkoutCard.totalLine.total
            : undefined,
          newCard: false,
          paymentMode: "Full",
        };
      } else {
        let newCardToken = undefined;
        let saveCardValue = undefined;
        let brand = undefined;
        let last4 = undefined;
        let paymentMode = installment ? "Installment" : "Full";
        if (
          customerCard !== null &&
          customerCard.newCard &&
          newCustomerCard !== null
        ) {
          const { stripe, cardElement, save } = newCustomerCard;
          let newCardData = await getNewCardInfo(stripe, cardElement, save);
          if (newCardData) {
            newCardToken = newCardData.token;
            saveCardValue = save.current.checked || installment;
            brand = newCardData.brand;
            last4 = newCardData.last4;
          }
        } else if (customerCard !== null && customerCard.cardId) {
          brand = customerCard.brand;
          last4 = customerCard.last4;
        }
        return {
          saveCard: saveCardValue,
          token: newCardToken,
          total: checkoutData
            ? checkoutData.checkoutCard.totalLine.total
            : undefined,
          newCard: customerCard !== null && customerCard.newCard ? true : false,
          cardId:
            customerCard !== null && !customerCard.newCard
              ? customerCard.cardId
              : undefined,
          brand,
          last4,
          paymentMode,
        };
      }
    };

    const getGeneralDetails = async () => {
      return {
        optionId: optionDetails.optionId,
        serviceEditTag: details.serviceDetails.editTag,
        optionEditTag: optionDetails.editTag,
        purchaseQty: quantity,
        staffId: safeQuery(staffDetails, ["staffId"]),
      };
    };

    const getSessionDetails = async () => {
      return {
        calendarEditTag:
          bookingChoice !== null ? bookingChoice.calendarEditTag : undefined,
        utcDate:
          details.serviceDetails.type === "Private"
            ? safeQuery(bookingChoice, ["data", "choice", "data"])
            : undefined,
        classId:
          details.serviceDetails.type === "Group"
            ? safeQuery(bookingChoice, ["data", "choice", "data"])
            : undefined,
        classEditTag:
          details.serviceDetails.type === "Group"
            ? safeQuery(bookingChoice, ["data", "choice", "classEditTag"])
            : undefined,
      };
    };

    const gatherInfo = async (bookingType, total) => {
      return {
        ...(await getAgreementDetails()),
        ...(await getAttendeeDetails()),
        ...(await getPaymentDetails(bookingType, total)),
        ...(await getGeneralDetails()),
        ...(await getSessionDetails()),
        bookingType,
      };
    };

    return (
      <div style={{ paddingRight: 16 }}>
        {details?.serviceDetails?.qualifications?.length > 0 || details?.serviceDetails?.attendeeQualifications?.length > 0 ?
          <CModal open={showQualifications} setOpen={setShowQualifications}>
            <Container
              className={classes.formContainer}
            >
              <Grid container>
                <CTitle title=" Customer and Attendee Qualifications" />
                <Grid xs={12} style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                  <Typography>
                    Please make sure that you and your attendees meet the following qualifications.
                  </Typography>
                </Grid>

                {details?.serviceDetails?.qualifications?.length > 0 ?
                  <Grid xs={12}>
                    <Typography variant="h6">
                      Customer:
                    </Typography>
                    <ul>
                      {details?.serviceDetails?.qualifications?.map((customerQulification, index) => (
                        <li>
                          <Typography key={`customer-quali-${index}`}>
                            <b>{customerQulification.name}</b>{customerQulification.description ? ` - ${customerQulification.description}` : ""}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                  : null}

                {details?.serviceDetails?.attendeeQualifications?.length > 0 ?
                  <Grid xs={12}>
                    <Typography variant="h6">
                      Attendee:
                    </Typography>
                    <ul>
                      {details?.serviceDetails?.attendeeQualifications?.map((attendeeQualification, index) => (
                        <li>
                          <Typography key={`customer-quali-${index}`}>
                            <b>{attendeeQualification.name}</b>{attendeeQualification.description ? ` - ${attendeeQualification.description}` : ""}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                  : null}

                <Grid item xs={12}>
                  <CButtonPair
                    action={() => setShowQualifications(false)}
                    notSubmit
                    errors={[]}
                    submitText="Close"
                  />
                </Grid>
              </Grid>
            </Container>
          </CModal>
          : null}
        <CModal open={finalCheck} setOpen={setFinalCheck}>
          <Container
            style={{ maxWidth: "950px" }}
            className={classes.formContainer}
          >
            <Grid container spacing={2}>
              <CTitle title="Purchase Summary" />
              <CModalError />
              <Grid item xs={12}>
                <BasicDetails
                  {...{
                    details,
                    classes,
                    tableTooBig,
                    staffDetails,
                    optionDetails,
                    extData,
                    quantity,
                    checkoutData,
                    setQuantity,
                    setMobilePricing,
                    extraFirst: [
                      <Typography
                        noWrap
                        key="attendeeNames left"
                        className={`${classes.fullWidth} ${classes.textColor3}`}
                      >
                        Attendees
                      </Typography>,
                      <Typography
                        noWrap
                        key="paymentInfo left"
                        className={`${classes.fullWidth} ${classes.textColor3}`}
                      >
                        Payment Info
                      </Typography>,
                    ],
                    extraSecond: [
                      <Typography
                        noWrap
                        key="attendeeNames right"
                        className={`${classes.fullWidth} ${classes.primaryText}`}
                      >
                        {finalCheck &&
                          Array.isArray(finalCheck.attendeeList) ? (
                          finalCheck.attendeeList
                            .map((attendee) => {
                              if (attendee) {
                                return `${customerAttendees.map.get(attendee.attendeeId)
                                  ?.firstName
                                  } ${customerAttendees.map.get(attendee.attendeeId)
                                    ?.lastName
                                  }`;
                              } else {
                                return null;
                              }
                            })
                            .filter((name) => name !== null)
                            .join(", ")
                        ) : (
                          <br />
                        )}
                      </Typography>,
                      <Typography
                        noWrap
                        key="paymentInfo right"
                        className={`${classes.fullWidth} ${classes.primaryText}`}
                      >
                        {finalCheck && finalCheck.brand && finalCheck.last4 ? (
                          <span>
                            <b>{finalCheck.brand}</b>
                            {` ending in ${finalCheck.last4}`}
                          </span>
                        ) : (
                          <br />
                        )}
                      </Typography>,
                    ],
                  }}
                  noEdit
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  overflowX: "auto",
                }}
              >
                <CheckoutCard
                  checkoutData={checkoutData}
                  optionDetails={optionDetails}
                />
              </Grid>
              {installment ? (
                <Grid item xs={12}>
                  <Typography className={classes.primaryText}>
                    Installment amount due now is{" "}
                    {calcInstallmentAmountDue(checkoutData, optionDetails)}.
                  </Typography>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => {
                        setFinalCheck(null);
                      }}
                      variant="contained"
                    >
                      <Typography className={classes.buttonText}>
                        Cancel
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={loading}
                      onClick={handleSubmit}
                      color="primary"
                      variant="contained"
                    >
                      <Typography className={classes.buttonText}>
                        Purchase
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </CModal>
        <Grid
          container
          spacing={2}
          style={{ maxWidth: "1440px", margin: "0 auto" }}
        >
          <Grid
            item
            xs={12}
            className={`${classes.spacingTop} ${classes.headingWrapper}`}
            style={{ marginLeft: 8, marginRight: 8, paddingLeft: 0 }}
          >
            <Typography variant="h4" className={classes.primaryText}>
              Checkout
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <LightTooltip arrow title={details.serviceDetails.name}>
              <Typography
                variant="h5"
                style={{ paddingTop: 10, paddingBottom: 5 }}
                noWrap
              >
                {details.serviceDetails.name}
              </Typography>
            </LightTooltip>
            <BasicDetails
              {...{
                details,
                classes,
                tableTooBig,
                staffDetails,
                optionDetails,
                extData,
                quantity,
                checkoutData,
                setQuantity,
                setMobilePricing,
                setShowQualifications
              }}
              extraFirst={[
                details?.serviceDetails?.qualifications?.length > 0 || details?.serviceDetails?.attendeeQualifications?.length > 0 ?
                  <Typography
                    key={`pre-reqs left `}
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowQualifications(true);
                    }}
                  >
                    View pre-requisites
                  </Typography>
                  : null
              ]}
            />
          </Grid>
          {!tableTooBig ? (
            <Grid item xs={6} style={{ paddingTop: 20 }}>
              <CheckoutCard
                checkoutData={checkoutData}
                optionDetails={optionDetails}
              />
            </Grid>
          ) : (
            <CModal open={mobilePricing} setOpen={setMobilePricing}>
              <Container className={classes.formContainer}>
                <Grid container spacing={2}>
                  <CTitle title={"Pricing"} />
                  <CModalError />
                  <Grid
                    item
                    xs={12}
                    style={{
                      overflowX: "scroll",
                    }}
                  >
                    <CheckoutCard
                      checkoutData={checkoutData}
                      optionDetails={optionDetails}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={() => {
                        setMobilePricing(false);
                      }}
                      color="primary"
                      variant="contained"
                      style={{ float: "right" }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </CModal>
          )}
          {extData.showSessions ? (
            <Grid item xs={12}>
              <SelectSession
                classes={classes}
                optionDetails={optionDetails}
                quantity={quantity}
                details={details}
                staffDetails={staffDetails}
                setBookingChoice={setBookingChoice}
                bookingChoice={bookingChoice}
                errors={errors.sessionError}
                dependancies={bookingChoice}
                onUpdate={async () => {
                  if (sessionError(await getSessionDetails()).length === 0) {
                    setErrors({ ...errors, sessionError: [] });
                  }
                }}
              />
            </Grid>
          ) : null}
          {optionDetails.type === "Season Pass" &&
            optionDetails.optionSettings.scheduleType === "Fixed" ? (
            <Grid item xs={12}>
              <CheckoutSection
                title={"Class Schedule"}
                errors={[]}
                onUpdate={async () => { }}
              >
                <ListTable
                  identifier={"FS"}
                  tableHeaders={FSHeaders}
                  noDivider
                  tableRows={optionDetails.fixedSchedule.map((fs) => ({
                    classNumber: fs.classNumber,
                    dateStr: `${fs.dateStr} ${fs.time}`,
                  }))}
                />
              </CheckoutSection>
            </Grid>
          ) : null}
          {attendeeList ? (
            <Grid item xs={12}>
              <CheckoutSection
                title={"Attendee Details"}
                errors={errors.attendeeError}
                dependancies={attendeeList}
                onUpdate={async () => {
                  if (attendeeError(await getAttendeeDetails()).length === 0) {
                    setErrors({ ...errors, attendeeError: [] });
                  }
                }}
              >
                <div style={{ width: "100%" }}>
                  {attendeeList.map((attendee, index) => {
                    return (
                      <Grid
                        container
                        key={`attendee_${index}`}
                        alignContent="center"
                        style={{
                          border: "1px solid #DBDDDF",
                          marginBottom: "10px",
                          marginTop: "10px",
                          padding: "10px",
                        }}
                      >
                        <div style={{ width: "220px" }}>
                          <Typography
                            noWrap
                            style={{ padding: "10px", fontSize: "20px" }}
                          >
                            {attendee
                              ? `${customerAttendees.map.get(attendee.attendeeId)
                                ?.firstName
                              } ${customerAttendees.map.get(attendee.attendeeId)
                                ?.lastName
                              }`
                              : `Attendee ${index + 1}`}
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: "calc(100% - 220px)",
                            paddingTop: "10px",
                          }}
                        >
                          <AttendeeButton
                            index={index}
                            attendeeList={attendeeList}
                            setAttendeeList={setAttendeeList}
                            questionList={safeQuery(details, [
                              "serviceDetails",
                              "questions",
                            ])}
                            attendeeListDetails={customerAttendees.list}
                            fetchAttendeeDetailList={async () => {
                              const success = await fetchAttendeeDetailList(
                                setCustomerAttendees
                              );
                              return success;
                            }}
                            classes={classes}
                          />
                        </div>
                        {optionDetails.addOns.length > 0 ? (
                          <React.Fragment>
                            <div
                              style={{
                                width: "100%",
                                paddingLeft: "10px",
                                paddingTop: "10px",
                              }}
                            >
                              <Typography noWrap>
                                Additional Purchase Options:
                              </Typography>
                            </div>
                            <div style={{ width: "100%" }}>
                              {optionDetails.addOns.map((addOn, subIndex) => {
                                return (
                                  <Grid
                                    container
                                    key={`attendee_${index}_addOn_${subIndex}`}
                                    style={{ width: "100%" }}
                                    alignContent="center"
                                  >
                                    <div style={{ width: "50px" }}>
                                      <Checkbox
                                        color="primary"
                                        checked={
                                          Array.isArray(addonList) &&
                                            addonList[index].has(addOn.addOnId)
                                            ? true
                                            : false
                                        }
                                        onClick={(event) => {
                                          if (Array.isArray(addonList)) {
                                            const newAddonList = [...addonList];
                                            const newAddonSet = new Set(
                                              addonList[index]
                                            );
                                            if (event.target.checked === true) {
                                              newAddonSet.add(addOn.addOnId);
                                            } else {
                                              newAddonSet.delete(addOn.addOnId);
                                            }
                                            newAddonList[index] = newAddonSet;
                                            setAddonList(newAddonList);
                                          }
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        width: "calc(100% - 50px)",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <Typography noWrap>
                                        {checkoutData
                                          ? `${addOn.displayName
                                          } (${CurrencyPreProcessor({
                                            amount: addOn.price,
                                            currencyBreak:
                                              checkoutData.currencyInfo
                                                .currencyBreak,
                                            roundingFactor:
                                              checkoutData.currencyInfo
                                                .roundingFactor,
                                            currencySymbol:
                                              checkoutData.currencyInfo
                                                .currencySymbol,
                                          })} ${addOn.sellBy.toLowerCase()})`
                                          : null}
                                      </Typography>
                                    </div>
                                  </Grid>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        ) : null}
                      </Grid>
                    );
                  })}
                </div>
              </CheckoutSection>
            </Grid>
          ) : null}
          {extData.showTAC && agreementList !== null ? (
            <Grid item xs={12}>
              <CheckoutSection
                title={"Terms And Conditions"}
                errors={errors.agreementError}
                dependancies={agreementList}
                onUpdate={async () => {
                  if (
                    agreementError(await getAgreementDetails()).length === 0
                  ) {
                    setErrors({ ...errors, agreementError: [] });
                  }
                }}
              >
                <ListTable
                  identifier={"TAC"}
                  tableHeaders={TACHeaders}
                  noHead
                  noDivider
                  tableRows={optionDetails.agreements.map(
                    (agreement, index) => ({
                      name: agreement.name,
                      type: agreement.type,
                      action: (
                        <ShowAgreement
                          classes={classes}
                          agreementDetails={agreement}
                          action={() => {
                            acceptAgreement(agreement.agreementId, index);
                          }}
                          accepted={
                            agreementList[index] === agreement.agreementId
                          }
                        />
                      ),
                    })
                  )}
                />
              </CheckoutSection>
            </Grid>
          ) : null}
          {(optionDetails.bookingType !== "Offline" && checkoutData?.checkoutCard?.totalLine?.total !== 0) ? (
            <Grid item xs={12}>
              <CheckoutSection
                title={"Payment Details"}
                errors={errors.paymentError}
              >
                <PaymentChoice
                  {...{
                    customerCard,
                    setCustomerCard,
                    newCustomerCard,
                    setNewCustomerCard,
                    optionDetails,
                    installment,
                    setInstallment,
                  }}
                  setErrors={setErrors}
                />
              </CheckoutSection>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Button
              style={{ float: "right" }}
              variant="contained"
              color="primary"
              onClick={async () => {
                const data = await gatherInfo(optionDetails.bookingType, checkoutData?.checkoutCard?.totalLine?.total);

                const newErrors = checkInfo(data, optionDetails.bookingType, checkoutData?.checkoutCard?.totalLine?.total);
                setErrors(newErrors);
                if (!anyErrors(newErrors)) {
                  setFinalCheck(data);
                }
              }}
            >
              <Typography style={{ textTransform: "none" }} variant="h5">
                Review
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} style={{ height: 50 }} />
        </Grid>
      </div>
    );
  } else {
    return null;
  }
};

const BasicDetails = (props) => {
  const {
    details,
    classes,
    tableTooBig,
    staffDetails,
    optionDetails,
    extData,
    quantity,
    checkoutData,
    setQuantity,
    setMobilePricing,
    extraFirst,
    extraSecond,
    noEdit,
    setShowQualifications
  } = props;
  return (
    <TwoColumn
      extProps={{
        spacing: 0,
      }}
      name="basicDetails"
      secondItemProps={{
        className: classes.basicDetails,
      }}
      firstProps={{
        spacing: 1,
      }}
      secondProps={{
        spacing: 1,
      }}
      first={[
        <Typography
          noWrap
          key={`businessName left`}
          className={classes.textColor3}
        >
          Business
        </Typography>,
        details.serviceDetails.type === "Private" ? (
          <Typography
            noWrap
            key={`staffName left`}
            className={classes.textColor3}
          >
            Instructor
          </Typography>
        ) : null,
        <Typography noWrap key={`location left`} className={classes.textColor3}>
          Location
        </Typography>,
        <Typography key="cancel left" noWrap className={classes.textColor3}>
          <span>{"Session"}</span>
          <br />
          <span>{"Cancelation Rule"}</span>
        </Typography>,
        <Typography
          noWrap
          key={`pricingOption left`}
          className={classes.textColor3}
        >
          Purchase Option
        </Typography>,
        <Typography
          noWrap
          key={`serviceType left`}
          className={classes.textColor3}
        >
          Service Type
        </Typography>,
        <Typography noWrap key={`duration left`} className={classes.textColor3}>
          Duration
        </Typography>,
        <Typography noWrap key="quantity left" className={classes.textColor3}>
          Quantity
        </Typography>,
        tableTooBig && !noEdit ? (
          <Typography
            noWrap
            key="price left"
            style={{ paddingTop: 5 }}
            className={classes.textColor3}
          >
            Total Price
          </Typography>
        ) : null,
        ...(Array.isArray(extraFirst) ? extraFirst : []),
      ]}
      second={[
        <LightTooltip
          arrow
          title={details.businessDetails.name}
          key={`businessName right`}
        >
          <Typography noWrap className={classes.fullWidth}>
            {details.businessDetails.name}
          </Typography>
        </LightTooltip>,
        details.serviceDetails.type === "Private" ? (
          <LightTooltip
            arrow
            key={`staffName right`}
            title={`${staffDetails.firstName} ${staffDetails.lastName}`}
          >
            <Typography noWrap className={classes.fullWidth}>
              {`${staffDetails.firstName} ${staffDetails.lastName}`}
            </Typography>
          </LightTooltip>
        ) : null,
        <LightTooltip
          title={
            !details.serviceDetails.isVirtual
              ? details.siteDetails.address1 +
              (details.siteDetails.address2
                ? ", " + details.siteDetails.address2
                : "")
              : "Virtual"
          }
          arrow
          key={`location right`}
        >
          <Typography noWrap className={classes.fullWidth} gutterBottom>
            {!details.serviceDetails.isVirtual
              ? details.siteDetails.address1 +
              (details.siteDetails.address2
                ? ", " + details.siteDetails.address2
                : "")
              : "Virtual"}
          </Typography>
        </LightTooltip>,
        <React.Fragment key="cancel right">
          <br />
          <Typography noWrap className={classes.primaryText}>
            {optionDetails.optionSettings.cancelAllowed === false
              ? "Not Allowed"
              : optionDetails.optionSettings.cancelDeadline === 0
                ? "Any time before session start time"
                : `${optionDetails.optionSettings.cancelDeadline} ${optionDetails.optionSettings.cancelDeadline === 1
                  ? "hour"
                  : "hours"
                } before session start time`}
          </Typography>
        </React.Fragment>,
        <LightTooltip
          arrow
          title={optionDetails.name}
          key={`pricingOption right`}
        >
          <Typography noWrap className={classes.fullWidth}>
            {`${optionDetails.name} (${optionDetails.type})`}
          </Typography>
        </LightTooltip>,
        <LightTooltip
          title={details.serviceDetails.type}
          key={`serviceType right`}
        >
          <Typography noWrap className={classes.fullWidth}>
            {details.serviceDetails.type}
          </Typography>
        </LightTooltip>,
        <LightTooltip
          title={details.serviceDetails.duration}
          key={`duration right`}
        >
          <Typography noWrap className={classes.fullWidth}>
            {formatDuration(details.serviceDetails.duration)}
          </Typography>
        </LightTooltip>,
        extData.quantiyFixed || noEdit ? (
          <Typography
            noWrap
            key="quantity right"
            className={`${classes.fullWidth}`}
          >
            {quantity}
          </Typography>
        ) : (
          <ChangeQuantity
            key="quantity right"
            quantity={quantity}
            setQuantity={setQuantity}
          />
        ),
        tableTooBig && !noEdit ? (
          <Typography
            noWrap
            key="price right"
            color="primary"
            style={{ paddingTop: 5 }}
          >
            {safeQuery(checkoutData, ["checkoutCard", "totalLine", "total"]) ? (
              <span
                onClick={() => {
                  setMobilePricing(true);
                }}
                className={classes.link}
              >
                {transformCurrency(
                  checkoutData.checkoutCard.totalLine.total,
                  checkoutData.currencyInfo
                )}
              </span>
            ) : (
              <br />
            )}
          </Typography>
        ) : null,
        ...(Array.isArray(extraSecond) ? extraSecond : []),
      ]}
    />
  );
};

const CheckoutSection = (props) => {
  const classes = useStyles();
  const { title, children, errors, onUpdate } = props;
  const [firstRender, setFirstRender] = useState(false);
  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
    } else {
      if (onUpdate && firstRender && props.dependancies) {
        onUpdate();
      }
    }
  }, [props.dependancies]);

  return (
    <Accordion
      className={
        errors && errors.length > 0 ? classes.accordionError : classes.accordion
      }
      elevation={0}
      square
      defaultExpanded
      TransitionComponent={Collapse}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={
          <ExpandMoreIcon
            color={errors && errors.length > 0 ? "error" : undefined}
          />
        }
      >
        <Typography
          variant="h5"
          color={errors && errors.length > 0 ? "error" : undefined}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.accordionContent,
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            {errors && errors.length > 0 ? (
              <ul style={{ marginTop: 0 }}>
                {errors.map((error, index) => (
                  <Grid item xs={12} key={`${title}-error-${index}`}>
                    <li className={classes.error}>
                      <Typography>{error}</Typography>
                    </li>
                  </Grid>
                ))}
              </ul>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
const TACHeaders = [
  {
    name: "Name",
    columnKey: "name",
    columnWidth: "200px",
    tooltip: true,
  },
  {
    name: "Action",
    columnKey: "action",
    columnWidth: "100%",
    type: "Normal",
  },
];

const FSHeaders = [
  {
    name: "Class #",
    columnKey: "classNumber",
    columnWidth: "100px",
    tooltip: true,
  },
  {
    name: "Start Time",
    columnKey: "dateStr",
    columnWidth: "100%",
    tooltip: true,
  },
];

const checkoutCardHeaders = [
  {
    name: "Purchase Option",
    columnKey: "po",
    columnWidth: "40%",
    tooltip: true,
  },
  {
    name: "Price",
    columnKey: "pi",
    columnWidth: "100px",
    tooltip: true,
  },

  {
    name: "Qty",
    columnKey: "qty",
    columnWidth: "50px",
    tooltip: true,
  },
  {
    name: "Amount",
    columnKey: "am",
    columnWidth: "100px",
  },
];

const ChangeQuantity = (props) => {
  const classes = useStyles();
  const quantity = props.quantity;
  const setQuantity = props.setQuantity;
  return (
    <Grid container>
      <IconButton
        style={{ marginRight: 10 }}
        className={classes.iconButton}
        onClick={() => {
          setQuantity(quantity - 1);
        }}
        classes={{
          root: classes.iconButtonPadding,
        }}
        disabled={quantity === 1}
      >
        <RemoveIcon />
      </IconButton>
      <TextField
        key="quantity right"
        style={{ width: 14 }}
        value={quantity}
        InputProps={{
          classes: {
            input: classes.quantityField,
          },
          inputComponent: (miniProps) => (
            <NumberFormat
              {...miniProps}
              decimalScale={0}
              isAllowed={(value) => {
                return (
                  value.floatValue === undefined ||
                  (value.floatValue >= 1 && value.floatValue <= 5)
                );
              }}
              onChange={(event) => {
                const value = event.target.value;
                if (value) {
                  setQuantity(Number(value));
                }
              }}
            />
          ),
        }}
      />
      <IconButton
        style={{ marginLeft: 10 }}
        className={classes.iconButton}
        variant="contained"
        classes={{
          root: classes.iconButtonPadding,
        }}
        disabled={quantity === 5}
        onClick={() => {
          setQuantity(quantity + 1);
        }}
      >
        <AddIcon />
      </IconButton>
    </Grid>
  );
};
const transformCurrency = (price, currencyInfo) =>
  CurrencyPreProcessor({
    amount: price,
    currencyBreak: currencyInfo.currencyBreak,
    roundingFactor: currencyInfo.roundingFactor,
    currencySymbol: currencyInfo.currencySymbol,
  });
const CheckoutCard = (props) => {
  const optionDetails = props.optionDetails;
  const checkoutData = props.checkoutData;
  const classes = useStyles();
  if (checkoutData) {
    const checkoutCard = checkoutData.checkoutCard;
    const currencyInfo = checkoutData.currencyInfo;
    const data = [];
    const offeringLine = checkoutCard.offeringLine;
    data.push({
      po: offeringLine.optionName,
      pi: transformCurrency(offeringLine.unitPrice, currencyInfo),
      qty: offeringLine.purchaseQty,
      am: transformCurrency(offeringLine.subTotal, currencyInfo),
    });

    if (safeQuery(checkoutCard, ["discountLines", "length"]) > 0) {
      data.push({
        po: checkoutCard.discountLines.map((discount, index) => (
          <span key={`discount name ${index}`}>
            {`${discount.discountName} (${discount.percentage})%`}
            <br />
          </span>
        )),
        am: checkoutCard.discountLines.map((discount, index) => (
          <span key={`discount amount ${index}`}>
            {`-${transformCurrency(discount.calculatedDiscount, currencyInfo)}`}
            <br />
          </span>
        )),
      });
    }

    if (safeQuery(checkoutCard, ["feeLines", "length"]) > 0) {
      data.push({
        po: checkoutCard.feeLines.map((fee, index) => (
          <span key={`fee name ${index}`}>
            {`${fee.feeName}`}
            <br />
          </span>
        )),
        am: checkoutCard.feeLines.map((fee, index) => (
          <span key={`fee amount ${index}`}>
            {`${transformCurrency(fee.calculatedFee, currencyInfo)}`}
            <br />
          </span>
        )),
      });
    }

    if (safeQuery(checkoutCard, ["addOnLines", "length"]) > 0) {
      data.push({
        po: checkoutCard.addOnLines.map((addOn, index) => (
          <span key={`add on name ${index}`}>
            {`${addOn.addOnName}`}
            <br />
          </span>
        )),
        pi: checkoutCard.addOnLines.map((addOn, index) => (
          <span key={`add on unit price ${index}`}>
            {`${transformCurrency(addOn.unitPrice, currencyInfo)}`}
            <br />
          </span>
        )),
        qty: checkoutCard.addOnLines.map((addOn, index) => (
          <span key={`add on qty ${index}`}>
            {`${addOn.purchaseQty}`}
            <br />
          </span>
        )),
        am: checkoutCard.addOnLines.map((addOn, index) => (
          <span key={`add on subTotal ${index}`}>
            {`${transformCurrency(
              addOn.unitPrice * addOn.purchaseQty,
              currencyInfo
            )}`}
            <br />
          </span>
        )),
      });
    }

    if (safeQuery(checkoutCard, ["taxLines", "length"]) > 0) {
      data.push({
        po: checkoutCard.taxLines.map((tax, index) => (
          <span key={`tax name ${index}`}>
            {`${tax.TaxName}`}
            <br />
          </span>
        )),
        am: checkoutCard.taxLines.map((tax, index) => (
          <span key={`tax amount ${index}`}>
            {`${transformCurrency(tax.calculatedTax, currencyInfo)}`}
            <br />
          </span>
        )),
      });
    }

    if (safeQuery(checkoutCard, ["creditLine", "creditLineApplicable"])) {
      data.push({
        po: (
          <span>
            {`Credit`}
            <br />
          </span>
        ),
        am: (
          <span>
            {`-${transformCurrency(
              checkoutCard.creditLine.creditApplied,
              currencyInfo
            )}`}
            <br />
          </span>
        ),
      });
    }

    if (safeQuery(checkoutCard, ["transactionLine", "feeApplied"])) {
      data.push({
        po: (
          <span>
            {`Transaction Fee`}
            <br />
          </span>
        ),
        pi:(
          <span>
            {checkoutCard.transactionLine.percentage}% of {transformCurrency(
              checkoutCard.transactionLine.appliedOnAmount,
              currencyInfo
            )}
          </span>
        ),
        am: (
          <span>
            {`${transformCurrency(
              checkoutCard.transactionLine.calculatedFee,
              currencyInfo
            )}`}
            <br />
          </span>
        ),
      });
    }



    data.push({
      po: (
        <React.Fragment>
          <b>Amount Due</b>
          {optionDetails.proRatedOption ? (
            <span style={{ fontSize: "12px" }}>
              <br />* Prorated
            </span>
          ) : null}
        </React.Fragment>
      ),
      am: (
        <b>{transformCurrency(checkoutCard.totalLine.total, currencyInfo)}</b>
      ),
    });

    return (
      <ListTable
        moreProps={{
          className: classes.checkoutCard,
        }}
        identifier={"checkoutCard"}
        tableHeaders={checkoutCardHeaders}
        tableRows={data}
      />
    );
  } else {
    return null;
  }
};

export default Checkout;
