import { ThemeProvider } from '@material-ui/core/styles';
import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import './App.css';
import { AboutUs } from './components/AboutUs/AboutUs';
import AccountHandler from './components/Account/AccountHandler';
import BusinessPage from './components/BusinessPage/BusinessPage';
import CheckoutHandler from './components/Checkout/CheckoutHandler';
import CustomerOnboardingForm from './components/CustomerOnboarding/CustomerOnboardingForm';
import Dashboard from './components/Dashboard/Dashboard';
import Documents from './components/Documents/Documents';
import PageNotFound from './components/ErrorPages/PageNotFound';
import ServerIssue from './components/ErrorPages/ServerIssue';
import Unauthorized from './components/ErrorPages/Unauthorized';
import Footer from './components/Footer/Footer';
import ForgotPassword from './components/Header/ForgotPassword/ForgotPassword';
import Header from './components/Header/Header';
import Login from './components/Header/Login/Login';
import RBHandler from './components/Header/RegisterBuisness/RBHandler';
import SignUp from './components/Header/SignUp/SignUp';
import Home from './components/Home/Home';
import MarketPlaceHandler from './components/MarketPlace/MarketPlaceHandler';
import theme from './theme';
import { encodeGetParams, firstTimeLoading, withLoading } from './utils';

const forBusinessRegex = new RegExp(/^\/(for.*business)(.*)$/i)
const forBusinessRegexPath = new RegExp(/^.*\/(for.*business)(.*)$/i)

const App = () => {
  const loaded = useSelector(state => state.loaded)
  const dispatch = useDispatch()
  const history = useHistory()
  const loggedIn = useSelector((state) => { return (state.token != null) });
  const serverError = useSelector((state) => { return (state.serverError) });
  const unauthorizedError = useSelector((state) => { return (state.unauthorizedError) });
  const logout = useSelector((state) => { return (state.logout) });
  const location = useLocation()



  const requireLogin = (component) => {
    return ((loggedIn ?
      <React.Fragment>
        {component}
      </React.Fragment>
      :
      <Redirect to={'/login?' + encodeGetParams({ urlTo: window.location.pathname + window.location.search })} />
    ));
  };




  const setUp = async () => {
    await withLoading(firstTimeLoading, {})
    dispatch({
      type: "UPDATE",
      payload: {
        loaded: true,
        loginPage: location.pathname === "/login",
      }
    })
  }

  useEffect(() => {
    if (!loaded) {
      setUp()
    }
  }, [loaded])


  useEffect(() => {
    if (serverError) {
      history.replace("/500")
      dispatch({
        type: "UPDATE",
        payload: {
          serverError: false
        }
      })
    }
  }, [serverError])

  useEffect(() => {
    if (logout) {
      history.replace("/")
      dispatch({
        type: "CLEAR",
      })
    }
  }, [logout])


  useEffect(() => {
    if (unauthorizedError) {
      history.replace("/401")
      dispatch({
        type: "UPDATE",
        payload: {
          unauthorizedError: false
        }
      })
    }
  }, [unauthorizedError])

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  if (loaded) {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <Header />
          <Route path={["/", ""]} exact>
            <Home />
          </Route>
          <Route
            exact
            path="/login"
            render={() => {
              return <Login />;
            }}
          />
          <Route
            exact
            path="/sign-up"
            render={() => {
              return <SignUp />;
            }}
          />
          <Route
            path="/for-business"
            render={() => {
              return <RBHandler />;
            }}
          />
          <Route
            path={forBusinessRegex}
            render={() => {
              const matches = window.location.href.match(forBusinessRegexPath)
              if (matches[1] === 'for-business') {
                return null
              }
              else {
                return <Redirect to={`/for-business${matches[2] ?? ''}`} />
              }
            }}
          />

          <Route
            exact
            path="/forgot-password"
            render={() => {
              return <ForgotPassword />;
            }}
          />
          <Route path="/Market-Place">
            <MarketPlaceHandler />
          </Route>
          <Route path='/account' >
            {requireLogin(<AccountHandler />)}
          </Route>
          <Route path='/dashboard' >
            {requireLogin(<Dashboard />)}
          </Route>
          <Route path="/checkout">
            {requireLogin(<CheckoutHandler />)}
          </Route>
          <Route path="/customeronboard/:businessIdURL" exact>
            <CustomerOnboardingForm loggedIn={loggedIn} />
          </Route>
          <Route path="/business/:businessId" exact>
            <BusinessPage />
          </Route>
          <Route exact path="/aboutUs">
            <AboutUs />
          </Route>
          <Route exact path="/500">
            <ServerIssue />
          </Route>
          <Route exact path="/401">
            <Unauthorized />
          </Route>
          <Route exact path="/404">
            <PageNotFound />
          </Route>
          <Route exact path="/document/:documentName">
            <Documents />
          </Route>
        </div>
        <Switch>
          <Route path={['/dashboard', '/account']}>
            {null}
          </Route>
          <Route path="*">
            <Footer />
          </Route>
        </Switch>
      </ThemeProvider>
    );
  }
  else {
    return (null);
  }
}

export default App;
