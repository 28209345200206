import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { processTime } from "../../FormValidation/validTime";
import { Search } from "./CAddressSearch";
import CAutoComplete from "./CAutoComplete";
import CButtonPair from "./CButtonPair";
import CCheckBox from "./CCheckBox";
import CChips from "./CChips";
import CCurrency from "./CCurrency";
import CDate from "./CDate";
import CDateRange from "./CDateRange";
import CModalError from "./CModalError";
import CMultiField from "./CMultiField";
import CMultiSelect from "./CMultiSelect";
import CNumber from "./CNumber";
import CPassword from "./CPassword";
import CPercent from "./CPercent";
import CPhoneNumber from "./CPhoneNumber";
import CRadioSet from "./CRadioSet";
import CSingleSelect from "./CSingleSelect";
import CSpecialDate from "./CSpecialDate";
import CStars from "./CStars";
import CSwitch from "./CSwitch";
import CTextArea from "./CTextArea";
import CTextField from "./CTextField";
import CTime from "./CTime";
import CTimeRange from "./CTimeRange";
import CTitle from "./CTitle";

const Form = (props) => {
  const inputFields = [];
  const validFields = [
    "TextField",
    "CheckBox",
    "Password",
    "Number",
    "TextArea",
    "SingleSelect",
    "MultiSelect",
    "Date",
    "Time",
    "Percent",
    "Currency",
    "PhoneNumber",
    "RadioSet",
    "TimeRange",
    "DateRange",
    "Switch",
    "Custom",
    "AddressSearch",
    "DaysPicker",
    "MultiField",
    "Stars",
    "SpecialDate",
    "Chips",
    "AutoComplete",
  ];
  if (Array.isArray(props.inputFields)) {
    props.inputFields.forEach((input) => {
      if (validFields.includes(input.type)) {
        inputFields.push(input);
      }
    });
  }

  const genInitialState = () => {
    if (Array.isArray(inputFields)) {
      const temp = [];
      for (let i = 0; i < inputFields.length; i++) {
        temp.push({
          errortext: null,
        });
      }
      return temp;
    } else {
      return [];
    }
  };

  const validateValue = (validators, value) => {
    if (typeof validators === "function") {
      return validators(value);
    } else if (Array.isArray(validators)) {
      for (let i = 0; i < validators.length; i++) {
        let errMsg = validators[i](value);
        if (Array.isArray(errMsg)) {
          if (errMsg.some((err) => Boolean(err))) {
            return errMsg;
          }
        } else if (errMsg) {
          return errMsg;
        }
      }
      return Array.isArray(value) ? [null, null] : null;
    }
  };

  const updateIndex = (index, value) => {
    if (errors[index].errortext !== value) {
      const copy = [...errors];
      copy[index] = { ...copy[index], errortext: value };
      setErrors(copy);
    }
  };

  const createPayload = (event) => {
    let field;
    let data = { ...props.moreData };
    for (let i = 0; i < inputFields.length; i++) {
      field = inputFields[i];
      if (field.enabled !== false) {
        if (typeof field.name === "string") {
          data[field.name] = access[i](event.target[field.name]);
        } else if (Array.isArray(field.name)) {
          for (let j = 0; j < field.name.length; j++) {
            data[field.name[j]] = access[i](event.target[field.name[j]])[j];
          }
        }
      }
    }

    return data;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let temp = new Array(inputFields.length);
    let field = null;
    for (let i = 0; i < inputFields.length; i++) {
      field = inputFields[i];
      if (access[i] !== null) {
        temp[i] = {
          ...errors[i],
          errortext: validateValue(
            field.validator,
            access[i](event.target[field.name])
          ),
        };
      }
    }
    let enabledArray = inputFields.map((inputField) => inputField.enabled);
    let disabled = temp.some((error, index) => {
      if (enabledArray[index] === false) {
        return false;
      } else {
        if (typeof error.errortext == "string") {
          return error.errortext !== "";
        } else if (Array.isArray(error.errortext)) {
          return error.errortext.some(
            (err) => typeof err == "string" && err !== ""
          );
        } else {
          return false;
        }
      }
    });
    setErrors(temp);
    if (disabled === false) {
      dispatch({
        type: "UPDATE",
        payload: {
          loading: true,
        },
      });
      let success = null;
      if (typeof props.action === "function") {
        success = await props.action(createPayload(event));
      }
      dispatch({
        type: "UPDATE",
        payload: {
          loading: false,
        },
      });
      if (typeof props.postAction === "function") {
        props.postAction(success);
      }
    }
  };
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(genInitialState());
  const access = [];
  if (Array.isArray(inputFields)) {
    const formContents = inputFields.map((inputField, index) => {
      let comp = null;
      switch (inputField.type) {
        case "TextField":
          access.push((element) => element.value);
          comp = (
            <CTextField
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              initialvalue={inputField.initialvalue}
              label={inputField.label}
              errortext={errors[index].errortext}
              name={inputField.name}
              moreProps={inputField.moreProps}
              trim={inputField.trim}
            />
          );
          break;
        case "CheckBox":
          access.push((element) => element.checked);
          comp = (
            <CCheckBox
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              initialvalue={inputField.initialvalue}
              label={inputField.label}
              errortext={errors[index].errortext}
              name={inputField.name}
              moreProps={inputField.moreProps}
            />
          );
          break;
        case "Password":
          access.push((element) => element.value);
          comp = (
            <CPassword
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              initialvalue={inputField.initialvalue}
              label={inputField.label}
              errortext={errors[index].errortext}
              name={inputField.name}
              moreProps={inputField.moreProps}
            />
          );
          break;
        case "Number":
          comp = (
            <CNumber
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              initialvalue={inputField.initialvalue}
              label={inputField.label}
              errortext={errors[index].errortext}
              name={inputField.name}
              moreProps={inputField.moreProps}
              numberProps={inputField.numberProps}
            />
          );
          access.push((element) =>
            element.value === ""
              ? null
              : Number(element.value.replaceAll(",", ""))
          );
          break;
        case "SingleSelect":
          comp = (
            <CSingleSelect
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              name={inputField.name}
              label={inputField.label}
              choices={inputField.choices}
              enabled={inputField.enabled}
              errortext={errors[index].errortext}
              initialvalue={inputField.initialvalue}
              moreProps={inputField.moreProps}
              updateGlob={(value) => {
                errors[index].value = value;
              }}
            />
          );
          access.push((element) => errors[index].value);
          break;
        case "DaysPicker":
          comp = (
            <CMultiSelect
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              name={inputField.name}
              label={inputField.label}
              choices={[
                ["Sunday", "sun"],
                ["Monday", "mon"],
                ["Tuesday", "tue"],
                ["Wednesday", "wed"],
                ["Thursday", "thr"],
                ["Friday", "fri"],
                ["Saturday", "sat"],
              ]}
              errortext={errors[index].errortext}
              initialvalue={inputField.initialvalue}
              moreProps={inputField.moreProps}
              updateGlob={(value) => {
                const allDays = [
                  "sun",
                  "mon",
                  "tue",
                  "wed",
                  "thr",
                  "fri",
                  "sat",
                ];
                const tempObj = {};
                const valueSet = new Set();
                value.forEach((day) => {
                  valueSet.add(day);
                });
                allDays.forEach((day) => {
                  tempObj[day] = valueSet.has(day);
                });
                errors[index].value = tempObj;
              }}
            />
          );
          access.push((element) => errors[index].value);
          break;
        case "Date":
          comp = (
            <CDate
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              initialvalue={inputField.initialvalue}
              label={inputField.label}
              errortext={errors[index].errortext}
              name={inputField.name}
              moreProps={inputField.moreProps}
            />
          );
          access.push((element) => element.value.replaceAll("-", "/"));
          break;
        case "TextArea":
          access.push((element) => element.value);
          comp = (
            <CTextArea
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              initialvalue={inputField.initialvalue}
              resizeEnabled={inputField.resizeEnabled}
              errortext={errors[index].errortext}
              name={inputField.name}
              label={inputField.label}
              moreProps={inputField.moreProps}
            />
          );
          break;
        case "MultiSelect":
          comp = (
            <CMultiSelect
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              name={inputField.name}
              label={inputField.label}
              choices={inputField.choices}
              errortext={errors[index].errortext}
              initialvalue={inputField.initialvalue}
              moreProps={inputField.moreProps}
              updateGlob={(value) => {
                errors[index].value = value;
              }}
            />
          );
          access.push((element) => errors[index].value);
          break;
        case "Time":
          comp = (
            <CTime
              name={inputField.name}
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              initialvalue={inputField.initialvalue}
              label={inputField.label}
              errortext={errors[index].errortext}
              moreProps={inputField.moreProps}
            />
          );
          access.push((element) => {
            return processTime(element.value);
          });
          break;
        case "Percent":
          comp = (
            <CPercent
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              initialvalue={inputField.initialvalue}
              label={inputField.label}
              errortext={errors[index].errortext}
              name={inputField.name}
              moreProps={inputField.moreProps}
              numberProps={inputField.numberProps}
            />
          );
          access.push((element) =>
            element.value === ""
              ? null
              : Number(element.value.replaceAll(",", ""))
          );
          break;
        case "Currency":
          comp = (
            <CCurrency
              currencyCode={inputField.currencyCode}
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              initialvalue={inputField.initialvalue}
              label={inputField.label}
              errortext={errors[index].errortext}
              name={inputField.name}
              moreProps={inputField.moreProps}
              numberProps={inputField.numberProps}
              currencyInfo={inputField.currencyInfo}
            />
          );
          access.push((element) =>
            element.value === ""
              ? null
              : Math.round(
                  Number(element.value.replaceAll(",", "")) *
                    inputField.currencyInfo.currencyBreak
                )
          );
          break;
        case "PhoneNumber":
          comp = (
            <CPhoneNumber
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              initialvalue={inputField.initialvalue}
              label={inputField.label}
              errortext={errors[index].errortext}
              name={inputField.name}
              moreProps={inputField.moreProps}
            />
          );
          access.push((element) => "+1" + element.value);
          break;
        case "RadioSet":
          comp = (
            <CRadioSet
              label={inputField.label}
              initialvalue={inputField.initialvalue}
              name={inputField.name}
              errortext={errors[index].errortext}
              choices={inputField.choices}
              updateGlob={(value) => {
                errors[index].value = value;
              }}
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
            />
          );
          access.push((element) => errors[index].value);
          break;
        case "TimeRange":
          comp = (
            <CTimeRange
              label={inputField.label}
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              initialvalue={inputField.initialvalue}
              errortext={errors[index].errortext}
              updateGlob={(value, i) => {
                if (!Array.isArray(errors[index].value)) {
                  errors[index].value = [null, null];
                } else {
                  errors[index].value[i] = value;
                }
              }}
            />
          );
          access.push((element) =>
            Array.isArray(errors[index].value)
              ? errors[index].value.map((time) =>
                  time === null ? null : processTime(time)
                )
              : errors[index].value
          );
          break;
        case "DateRange":
          comp = (
            <CDateRange
              label={inputField.label}
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              initialvalue={inputField.initialvalue}
              errortext={errors[index].errortext}
              updateGlob={(value, i) => {
                if (!Array.isArray(errors[index].value)) {
                  errors[index].value = [null, null];
                } else {
                  errors[index].value[i] = value;
                }
              }}
            />
          );
          access.push((element) => errors[index].value);
          break;
        case "Switch":
          comp = (
            <CSwitch
              label={inputField.label}
              initialvalue={inputField.initialvalue}
              name={inputField.name}
              errortext={errors[index].errortext}
              choices={inputField.choices}
              updateGlob={(value) => {
                errors[index].value = value;
              }}
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
            />
          );
          access.push((element) => element.checked);
          break;
        case "Custom":
          comp = props.comp;
          access.push(null);
          break;
        case "AddressSearch":
          comp = (
            <Search
              label={inputField.label}
              panTo={(value) => {
                if (!value) {
                  errors[index].value = [null, { lat: null, lon: null }];
                } else {
                  errors[index].value[0] = value.address1;
                  errors[index].value[1] = {
                    lat: value.lat,
                    lon: value.lng,
                  };
                }
              }}
              errortext={errors[index].errortext}
              onchange={(value) => {
                updateIndex(index, validateValue(inputField.validator, value));
              }}
            />
          );
          access.push((element) => {
            return errors[index].value;
          });
          break;
        case "MultiField":
          comp = (
            <CMultiField
              errortext={errors[index].errortext}
              initialvalue={inputField.initialvalue}
              onchange={(value) => {
                updateIndex(index, validateValue(inputField.validator, value));
              }}
              updateGlob={(value) => {
                if (!Array.isArray(errors[index].value)) {
                  errors[index].value = ["", ""];
                } else {
                  errors[index].value = value;
                }
              }}
            />
          );
          access.push((element) => {
            return errors[index].value;
          });
          break;
        case "Stars":
          comp = (
            <CStars
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              name={inputField.name}
              label={inputField.label}
              errortext={errors[index].errortext}
              initialvalue={inputField.initialvalue}
              updateGlob={(value) => {
                errors[index].value = value;
              }}
            />
          );
          access.push((element) => errors[index].value);
          break;
        case "SpecialDate":
          comp = (
            <CSpecialDate
              label={inputField.label}
              moreProps={inputField.moreProps}
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              initialvalue={inputField.initialvalue}
              updateGlob={(value) => {
                errors[index].value = value;
              }}
              endvalue={inputField.endvalue}
            />
          );
          access.push((element) => errors[index].value);
          break;
        case "Chips":
          comp = (
            <CChips
              label={inputField.label}
              choices={inputField.choices}
              moreProps={inputField.moreProps}
              errortext={errors[index].errortext}
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              updateGlob={(value) => {
                errors[index].value = value;
              }}
            />
          );
          access.push((element) => errors[index].value);
          break;
        case "AutoComplete":
          comp = (
            <CAutoComplete
              initialvalue={inputField.initialvalue}
              label={inputField.label}
              choices={inputField.choices}
              errortext={errors[index].errortext}
              onchange={(event) => {
                updateIndex(
                  index,
                  validateValue(
                    inputField.validator,
                    access[index](event.target)
                  )
                );
              }}
              updateGlob={(value) => {
                errors[index].value = value;
              }}
            />
          );
          access.push((element) => errors[index].value);
          break;
        default:
      }

      return (
        <Grid
          item
          key={`form-${index}`}
          xs={inputField.size ? inputField.size.xs : 12}
          sm={inputField.size ? inputField.size.sm : null}
          md={inputField.size ? inputField.size.md : null}
          lg={inputField.size ? inputField.size.lg : null}
          xl={inputField.size ? inputField.size.xl : null}
          style={{
            ...(inputField.padding
              ? {
                  paddingTop: inputField.padding.top,
                  paddingBottom: inputField.padding.bottom,
                  paddingRight: inputField.padding.right,
                  paddingLeft: inputField.padding.left,
                }
              : null),
            display: inputField.enabled === false ? "none" : "block",
          }}
        >
          {comp}
        </Grid>
      );
    });

    if (props.onlyFormContents) {
      return formContents;
    } else {
      return (
        <div
          style={{
            ...props.formStyles,
          }}
        >
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2} style={{ backgroundColor: "white" }}>
              {props.noTitleAndError ? null : (
                <React.Fragment>
                  <CTitle title={props.title} center={props.titleCenter} />
                  <CModalError />
                </React.Fragment>
              )}
              {props.subtitle ? (
                <Grid item xs={12}>
                  <Typography>{props.subtitle}</Typography>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={props.defaultSpacing === false ? 0 : 2}
                >
                  {formContents}
                </Grid>
              </Grid>
              <CButtonPair
                buttonPlacement={props.buttonPlacement}
                enabledArray={inputFields.map(
                  (inputField) => inputField.enabled
                )}
                errors={errors}
                submitText={props.submitText}
                cancelText={props.cancelText}
                clearEnabled={props.clearEnabled}
                clearAction={
                  props.clearAction !== null ? props.clearAction : () => {}
                }
                clearText={props.clearText}
                cancelAction={
                  props.cancelAction !== null ? props.cancelAction : () => {}
                }
                cancelEnabled={props.cancelEnabled === true ? true : false}
              />
            </Grid>
          </form>
        </div>
      );
    }
  } else {
    return null;
  }
};

export default Form;
