import React from 'react';
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Dispute from "./Dispute";
import DisputesList from "./DisputesList";

function DisputesHandler() {
    const history = useHistory();
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    return(
        <React.Fragment>
            <Switch>
            <Route path = {`/dashboard/${businessId}/Disputes`} exact render = {() => {return(<DisputesList />)}} />
            <Route path = {`/dashboard/${businessId}/Disputes/:disputeIdURL`} render = {() => {return(<Dispute/>);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default DisputesHandler;