import React, { useEffect, useState } from "react";
import AddPayment from "./AddPayment";
import { makeStyles } from "@material-ui/core/styles";
import { customerCards, withLoading, customerCardPrimary, customerDeleteCard } from "../../../../utils";
import { useHistory } from "react-router-dom";
import { Grid, Typography, useMediaQuery, Button, Checkbox } from "@material-ui/core";
import ListTable from "./../../../TableComponents/ListTable";
import GeneralDialog from "../../../GeneralDialog/GeneralDialog"
import ListCard from "../../../MobileCards/ListCard";
import EmptyListImage from "../../../EmptyListImage/Empty";
import CardImage from "../../../CardImage/CardImage";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  buttonBasic: {
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
}));

const PaymentMethodsList = (props) => {
  const [_, updateState] = useState(null)
  const [open, setOpen] = useState(false);
  const [del, setDel] = useState(false)
  const history = useHistory();
  const classes = useStyles();
  const [methodsList, setMethodsList] = useState(null);
  const bigEnough = useMediaQuery("(min-width:750px)")


  const methodsHeaders = [
    {
      name: "Primary",
      columnKey: "check",
      columnWidth: "50px",
      type: "Normal"
    },
    {
      name: "Brand",
      columnKey: "brand",
      columnWidth: "25%",
    },
    {
      name: "Card Number",
      columnKey: "last4",
      columnWidth: "25%",
    },
    {
      name: "",
      columnKey: "del",
      columnWidth: "25%",
      type: "Normal"
    },

  ];

  const changePrimary = async (cardId) => {
    let success = await withLoading(customerCardPrimary, {
      cardId: cardId,
    })
    if (success && !success.error) {
      refresh()
    }
  }

  const refresh = () => {
    updateState(_ => !_)
  }

  const delCard = async (cardId) => {
    let success = await withLoading(customerDeleteCard, {
      cardId: cardId,
    })
    if (success && !success.error) {
      setDel(false)
      refresh()
    }
  }



  const processMethodsData = (methodsData) => {
    return methodsData.map((method, index) => {
      let {
        cardId,
        last4,
        brand,
        primary
      } = method;


      let id = cardId;
      let check = (
        <Checkbox
          checked={primary}
          onClick={() => { changePrimary(cardId) }}
        />
      )
      let del = (
        <Button
          variant={"outlined"}
          className={classes.del}
          onClick={() => { setDel(cardId) }}
        >
          Remove
        </Button>
      )
      last4 = `************${last4}`

      brand = <CardImage brand={brand} />
      return {
        id,
        last4,
        brand,
        primary,
        check,
        del

      };
    });
  };

  const getMethodsDetails = async () => {
    let methodsPayload = await customerCards({});

    if (methodsPayload && methodsPayload.cardDetails) {
      setMethodsList(processMethodsData(methodsPayload.cardDetails));
    };

  };

  useEffect(async () => {
    await withLoading(getMethodsDetails, {}, true)
  }, [_]);



  const ViewChanger = () => {
    if (bigEnough) {
      return (<React.Fragment>

        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <ListTable
              identifier={"methods"}
              tableHeaders={methodsHeaders}
              tableRows={methodsList}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </React.Fragment>)
    }
    else {
      return (<Grid item xs={12} style={{ paddingTop: "10px" }}>
        {methodsList.map((method) => {
          return (
            <ListCard
              key={method.id}
              cardName={method.last4}
              statusReplace={method.brand}
              other={
                <Typography>
                  Primary:
                  <Checkbox
                    checked={method.primary}
                    onClick={() => { changePrimary(method.id) }}
                  />
                </Typography>
              } />
          )
        })}
      </Grid>)
    }

  }


  if (methodsList === null) {
    return (null)
  }
  else {
    return (
      <React.Fragment>
        <AddPayment open={open} setOpen={setOpen} refresh={refresh} />
        <GeneralDialog
          open={del}
          close={() => { setDel(false) }}
          title={"Delete Card"}
          text={"Are you sure you want to delete this card?"}
          action={() => {
            withLoading(delCard, del)
          }}
        />
        <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
          <Grid item xs={12}>
            <div className={classes.headerPadding}>
              <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Payment Methods</span>
              <span title="Add Service" className={classes.rightElem}>
                <Button
                  className={classes.buttonBasic}
                  onClick={() => { setOpen(true); }}
                  color="primary"
                  variant="contained"
                >
                  Add
                </Button>
              </span>
            </div>
          </Grid>
          {(Array.isArray(methodsList) && methodsList.length > 0 ?
            <ViewChanger />
            :
            <EmptyListImage name="Payments" />)}
        </Grid>
      </React.Fragment>
    );
  }

};

export default PaymentMethodsList;