import { Button, Grid, Menu, MenuItem, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router";
import { getInternalEventsDetailAdmin, getInternalEventsDetailStaff, withLoading } from "../../../../../../utils";
import LightTooltip from "../../../../../Form/LightTooltip";
import { CAddIcon, CCloseIcon, CEllipsisIcon } from "../../../../../Icons/Icons";
import MapContainer from "../../../../../MapContainer/MapContainer";
import ListTable from "../../../../../TableComponents/ListTable";
import StatusTag from "../../../../../TagComponents/StatusTag";
import AddInternalEventResourceForm from "./AddInternalEventResourceForm";
import AddInternalEventStaffForm from "./AddInternalEventStaffForm";
import CancelInternalEventForm from "./CancelnternalEventForm";
import { invertColor } from "./ColorUtils";
import EditInternalEventForm from "./EditInternalEventForm";
import RemoveInternalEventResourceForm from "./RemoveInternalEventResourceForm";
import RemoveInternalEventStaffForm from "./RemoveInternalEventStaffForm";
import RescheduleInternalEventForm from "./RescheduleInternalEventForm";

const useStyles = makeStyles((theme) => ({
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    topBlock: {
        paddingBottom: "15px",
    },
    recordRow: {
        paddingLeft: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRoght: "5px",
        width: "550px",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    rightElem: {
        float: "right",
    },
    rightElemPad: {
        float: "right",
        marginRight: "10px",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeadingOne: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    topHeadingTwo: {
        fontSize: "28px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    topHeadingThree: {
        backgroundColor: "#eb5757",
        opacity: "50%",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    topHeadingFour: {
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    buttonBasic: {
        backgroundColor: "#ffffff",
        color: "#1a1f36",
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto"

    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    contentColumnFirst: {
        paddingRight: "20px",
        boxShadow: "inset -1px 0 #e3e8ee",
        display: "inline-block",
    },
    contentColumnMiddle: {
        boxShadow: "inset -1px 0 #e3e8ee",
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "inline-block",
        maxWidth: "160px",
    },
    contentColumnLast: {
        paddingLeft: "20px",
        display: "inline-block",
    },
    labelText: {
        fontSize: "14px",
        color: "#697386",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        overflow: "hidden",

    },
    valueText2: {
        fontSize: "14px",
        color: "#3c4257",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextNeg: {
        fontSize: "14px",
        color: "#009211",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBG: {
        fontSize: "16px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBold: {
        fontSize: "14px",
        color: "#1a1f36",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextPad: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingLeft: "10px",
    },
    valueTextLink: {
        fontSize: "14px",
        color: "#3d5af1",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textDecoration: "none",
    },
    errorText: {
        fontSize: "14px",
        color: "#ff0000",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    blockLabel: {
        paddingTop: "10px",
        paddingBottom: "5px",
    },
    blockText: {
        paddingLeft: "10px",
    },
    subHeading: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    fieldRows: {
        paddingTop: "8px"
    },
    fieldColumnOne: {
        width: "200px",
        display: "inline-block",
    },
    fieldColumnTwo: {
        display: "inline-block",
        maxWidth: "160px",
    },
    sdCustomCellOne: {
        border: "none",
        width: "5%",
        maxWidth: "200px",
        padding: "0px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingThree: {
        fontSize: "20px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    sdCustomCellTwo: {
        border: "none",
        width: "35%",
        maxWidth: "300px",
        padding: "10px",
    },
    sdCustomCellThree: {
        border: "none",
        width: "60%",
        maxWidth: "200px",
        padding: "10px",
    },
    sdCustomCellFour: {
        border: "none",
        padding: "10px",
    },
    headerPadding: {
        padding: "10px",
        paddingLeft: "0px"
    },
    bottomSpacer: {
        height: "50px",
    },
    topMarginBlock: {
        marginTop: "10px",
    },
    loaderWrapper: {
        width: "100%",
        height: "calc(100vh - 64px)",
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%",
    },
    media: {
        height: 200,
        width: 250,
        objectFit: "cover",
    },
}));

const staffHeader = [
    {
        name: "Member Id",
        columnKey: "memberId",
        columnWidth: "100px",

    },
    {
        name: "Name",
        columnKey: "name",
        columnWidth: "100%",
        tooltip: true
    },
]

const staffHeaderNoId = [
    {
        name: "Name",
        columnKey: "name",
        columnWidth: "100%",
        tooltip: true
    },
]

const resourceHeader = [
    {
        name: "Name",
        columnKey: "name",
        columnWidth: "100%",
        tooltip: true
    },
]

const InternalEventsDetails = (props) => {
    const mountedRef = useRef(false)
    const history = useHistory()
    const { eventIdURL } = useParams()
    const classes = useStyles()
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const bigEnough = useMediaQuery("(min-width: 750px)");
    const matches = bigEnough;

    // State
    const [internalEventDetails, setInternalEventDetails] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [editOpen, setEditOpen] = useState(false)
    const [cancelOpen, setCancelOpen] = useState(false)
    const [addStaffOpen, setAddStaffOpen] = useState(false)
    const [removeStaffOpen, setRemoveStaffOpen] = useState(false)
    const [addResourceOpen, setAddResourceOpen] = useState(false)
    const [removeResourceOpen, setRemoveResourceOpen] = useState(false)
    const [rescheduleOpen, setRescheduleOpen] = useState(false)

    const fetchInternalEventDetails = async () => {
        const detailResponse = await withLoading(props.admin === true ? getInternalEventsDetailAdmin : getInternalEventsDetailStaff, {
            businessId,
            eventId: eventIdURL
        })
        if (detailResponse?.eventDetails && !detailResponse.error && mountedRef.current) {
            setInternalEventDetails(detailResponse.eventDetails)
        }
    }

    useEffect(() => {
        mountedRef.current = true
        fetchInternalEventDetails()
        return () => mountedRef.current = false
    }, [])

    if (internalEventDetails === null) {
        return null
    }
    else {
        return (
            <Grid
                container
                className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
            >
                {props.admin && internalEventDetails.isCanceled === false ?
                    <React.Fragment>
                        <EditInternalEventForm
                            open={editOpen}
                            setOpen={setEditOpen}
                            internalEventDetails={internalEventDetails}
                            fetchInternalEventDetails={fetchInternalEventDetails}
                        />
                        <CancelInternalEventForm
                            open={cancelOpen}
                            setOpen={setCancelOpen}
                            internalEventDetails={internalEventDetails}
                            fetchInternalEventDetails={fetchInternalEventDetails}
                        />
                        <AddInternalEventStaffForm
                            open={addStaffOpen}
                            setOpen={setAddStaffOpen}
                            internalEventDetails={internalEventDetails}
                            fetchInternalEventDetails={fetchInternalEventDetails}
                        />
                        <RemoveInternalEventStaffForm
                            open={removeStaffOpen}
                            setOpen={setRemoveStaffOpen}
                            internalEventDetails={internalEventDetails}
                            fetchInternalEventDetails={fetchInternalEventDetails}
                        />
                        <AddInternalEventResourceForm
                            open={addResourceOpen}
                            setOpen={setAddResourceOpen}
                            internalEventDetails={internalEventDetails}
                            fetchInternalEventDetails={fetchInternalEventDetails}
                        />
                        <RemoveInternalEventResourceForm
                            open={removeResourceOpen}
                            setOpen={setRemoveResourceOpen}
                            internalEventDetails={internalEventDetails}
                            fetchInternalEventDetails={fetchInternalEventDetails}
                        />
                        <RescheduleInternalEventForm
                            open={rescheduleOpen}
                            setOpen={setRescheduleOpen}
                            internalEventDetails={internalEventDetails}
                            fetchInternalEventDetails={fetchInternalEventDetails}
                        />
                    </React.Fragment>
                    : null}
                <Grid item xs={12}>
                    <div className={classes.topBlock}>
                        <span className={classes.topHeading}>INTERNAL EVENTS</span>
                        <span className={classes.rightElem}>

                            {props.admin && internalEventDetails.isCanceled === false ?
                                <React.Fragment>
                                    <Button
                                        onClick={(event) => {
                                            setAnchorEl(event.target);
                                        }}
                                        className={classes.buttonBasic}
                                    >
                                        <CEllipsisIcon />
                                    </Button>
                                    <Menu
                                        anchorEl={anchorEl}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={() => {
                                            setAnchorEl(false);
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                setAnchorEl(false);
                                                setEditOpen(true)
                                            }}
                                        >
                                            Edit Event
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                setAnchorEl(false);
                                                setRescheduleOpen(true)
                                            }}
                                        >
                                            Reschedule Event
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                setAnchorEl(false);
                                                setCancelOpen(true)
                                            }}
                                        >
                                            Cancel Event
                                        </MenuItem>
                                    </Menu>
                                </React.Fragment>
                                : null}
                        </span>
                    </div>
                </Grid>
                <Grid item style={bigEnough ? { maxWidth: "50%" } : { width: "100%" }}>
                    <div className={classes.headerPadding}>
                        <LightTooltip title={internalEventDetails.name} arrow>
                            <Typography
                                className={
                                    bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne
                                }
                                noWrap
                            >
                                {internalEventDetails.name}
                            </Typography>
                        </LightTooltip>
                    </div>
                </Grid>
                {bigEnough ? (
                    <Grid item>
                        <div
                            className={classes.headerPadding}
                            style={{ paddingLeft: "20px" }}
                        >
                            <span className={classes.topHeadingFour}>
                                <StatusTag
                                    wrapperName={
                                        !internalEventDetails.isCanceled ? "greenWrapper" : "redWrapper"
                                    }
                                    value={!internalEventDetails.isCanceled ? "Active" : "Canceled"}
                                />
                            </span>
                        </div>
                    </Grid>
                ) : (
                    <Grid item>
                        <div style={{ paddingLeft: "10px" }}>
                            <StatusTag
                                wrapperName={
                                    !internalEventDetails.isCanceled ? "greenWrapper" : "redWrapper"
                                }
                                value={!internalEventDetails.isCanceled ? "Active" : "Canceled"}
                            />
                        </div>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        <div
                            className={
                                matches ? classes.contentColumnFirst : classes.fieldRows
                            }
                        >
                            <div
                                className={
                                    matches ? classes.blockLabel : classes.fieldColumnOne
                                }
                            >
                                <Typography className={classes.labelText}>Number</Typography>
                            </div>
                            <div
                                className={matches ? classes.blockText : classes.fieldColumnTwo}
                                style={matches ? null : { paddingLeft: "15px" }}
                            >
                                <Typography className={classes.valueText} noWrap>
                                    {internalEventDetails.eventNumber}
                                </Typography>
                            </div>
                        </div>
                        <div
                            className={
                                matches ? classes.contentColumnMiddle : classes.fieldRows
                            }
                        >
                            <div
                                className={
                                    matches ? classes.blockLabel : classes.fieldColumnOne
                                }
                            >
                                <Typography className={classes.labelText}>Hard Block</Typography>
                            </div>
                            <div
                                className={matches ? classes.blockText : classes.fieldColumnTwo}
                                style={matches ? null : { paddingLeft: "15px" }}
                            >
                                <Typography className={classes.valueText} noWrap>
                                    {internalEventDetails.hardBlock ? "Yes" : "No"}
                                </Typography>

                            </div>
                        </div>
                        <div
                            className={
                                matches ? classes.contentColumnMiddle : classes.fieldRows
                            }
                        >
                            <div
                                className={
                                    matches ? classes.blockLabel : classes.fieldColumnOne
                                }
                            >
                                <Typography className={classes.labelText}>Start Date</Typography>
                            </div>
                            <div
                                className={matches ? classes.blockText : classes.fieldColumnTwo}
                                style={matches ? null : { paddingLeft: "15px" }}
                            >
                                <LightTooltip title={moment.utc(internalEventDetails.startTimeUTC).tz(internalEventDetails.zoneCode).format("M/D/YYYY")}>
                                    <Typography className={classes.valueText} noWrap>
                                        {moment.utc(internalEventDetails.startTimeUTC).tz(internalEventDetails.zoneCode).format("M/D/YYYY")}
                                    </Typography>
                                </LightTooltip>

                            </div>
                        </div>
                        <div
                            className={
                                matches ? classes.contentColumnMiddle : classes.fieldRows
                            }
                        >
                            <div
                                className={
                                    matches ? classes.blockLabel : classes.fieldColumnOne
                                }
                            >
                                <Typography className={classes.labelText}>Start Time</Typography>
                            </div>
                            <div
                                className={matches ? classes.blockText : classes.fieldColumnTwo}
                                style={matches ? null : { paddingLeft: "15px" }}
                            >
                                <LightTooltip title={moment.utc(internalEventDetails.startTimeUTC).tz(internalEventDetails.zoneCode).format("h:mm a z")}>
                                    <Typography className={classes.valueText} noWrap>
                                        {moment.utc(internalEventDetails.startTimeUTC).tz(internalEventDetails.zoneCode).format("h:mm a z")}
                                    </Typography>
                                </LightTooltip>

                            </div>
                        </div>
                        <div
                            className={
                                matches ? classes.contentColumnMiddle : classes.fieldRows
                            }
                        >
                            <div
                                className={
                                    matches ? classes.blockLabel : classes.fieldColumnOne
                                }
                            >
                                <Typography className={classes.labelText}>End Date</Typography>
                            </div>
                            <div
                                className={matches ? classes.blockText : classes.fieldColumnTwo}
                                style={matches ? null : { paddingLeft: "15px" }}
                            >
                                <LightTooltip title={moment.utc(internalEventDetails.endTimeUTC).tz(internalEventDetails.zoneCode).format("M/D/YYYY")}>
                                    <Typography className={classes.valueText} noWrap>
                                        {moment.utc(internalEventDetails.endTimeUTC).tz(internalEventDetails.zoneCode).format("M/D/YYYY")}
                                    </Typography>
                                </LightTooltip>
                            </div>
                        </div>
                        <div
                            className={
                                matches ? classes.contentColumnMiddle : classes.fieldRows
                            }
                        >
                            <div
                                className={
                                    matches ? classes.blockLabel : classes.fieldColumnOne
                                }
                            >
                                <Typography className={classes.labelText}>End Time</Typography>
                            </div>
                            <div
                                className={matches ? classes.blockText : classes.fieldColumnTwo}
                                style={matches ? null : { paddingLeft: "15px" }}
                            >
                                <LightTooltip title={moment.utc(internalEventDetails.endTimeUTC).tz(internalEventDetails.zoneCode).format("h:mm a z")}>
                                    <Typography className={classes.valueText} noWrap>
                                        {moment.utc(internalEventDetails.endTimeUTC).tz(internalEventDetails.zoneCode).format("h:mm a z")}
                                    </Typography>
                                </LightTooltip>
                            </div>
                        </div>
                        <div
                            className={
                                matches ? classes.contentColumnLast : classes.fieldRows
                            }
                        >
                            <div
                                className={
                                    matches ? classes.blockLabel : classes.fieldColumnOne
                                }
                            >
                                <Typography className={classes.labelText}>Color</Typography>
                            </div>
                            <div
                                className={matches ? classes.blockText : classes.fieldColumnTwo}
                                style={matches ? null : { paddingLeft: "15px" }}
                            >
                                <Typography className={classes.valueText} style={{ backgroundColor: internalEventDetails.primaryColor, color: invertColor(internalEventDetails.primaryColor, true) }}>
                                    {internalEventDetails.primaryColor}
                                </Typography>

                            </div>
                        </div>

                    </div>
                </Grid>
                {
                    internalEventDetails.description?.length > 0 ?
                        <React.Fragment>
                            <Grid item xs={12}>
                                <div className={classes.headingWrapper}>
                                    <span
                                        className={
                                            bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                                        }
                                    >
                                        Description
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.contentRow}>
                                    <Typography>
                                        {internalEventDetails.description}
                                    </Typography>
                                </div>
                            </Grid>
                        </React.Fragment>
                        : null
                }
                <Grid item xs={12}>
                    <div className={classes.headingWrapper}>
                        <span
                            className={
                                bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                            }
                        >
                            Staff
                        </span>
                        {props.admin && internalEventDetails.isCanceled === false ?
                            <React.Fragment>
                                <Button style={{ float: "right" }} variant="outlined" onClick={() => setRemoveStaffOpen(true)}>
                                    <CCloseIcon />
                                </Button>
                                <Button style={{ float: "right", marginRight: "10px" }} variant="outlined" onClick={() => setAddStaffOpen(true)}>
                                    <CAddIcon />
                                </Button>
                            </React.Fragment>
                            : null}

                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        {internalEventDetails.staffDetails.length !== 0 ? (
                            <ListTable
                                styling={{ maxHeight: 250, width: "100%" }}
                                identifier={"staff"}
                                tableHeaders={props.admin === true ? staffHeader : staffHeaderNoId}
                                tableRows={internalEventDetails.staffDetails.map(staffDetail => ({
                                    id: staffDetail.staffId,
                                    name: `${staffDetail.staffFirstName} ${staffDetail.staffLastName}`,
                                    memberId: staffDetail.staffMemberId
                                }))}
                                rowClick={props.admin === true ? (staffId) => history.push(`/dashboard/${businessId}/Staff/${staffId}`) : undefined}
                            />
                        ) : (
                            <div className={classes.topMarginBlock}>
                                <Typography className={classes.valueTextPad} style={{ maxWidth: "100%" }}>
                                    No staff have been added to this internal event.
                                </Typography>
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.headingWrapper}>
                        <span
                            className={
                                bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                            }
                        >
                            Resources
                        </span>
                        {props.admin && internalEventDetails.isCanceled === false ?
                            <React.Fragment>
                                <Button style={{ float: "right" }} variant="outlined" onClick={() => setRemoveResourceOpen(true)}>
                                    <CCloseIcon />
                                </Button>
                                <Button style={{ float: "right", marginRight: "10px" }} variant="outlined" onClick={() => setAddResourceOpen(true)}>
                                    <CAddIcon />
                                </Button>
                            </React.Fragment>
                            : null}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        {internalEventDetails.resourceDetails.length !== 0 ? (
                            <ListTable
                                styling={{ maxHeight: 250, width: "100%" }}
                                identifier={"resources"}
                                tableHeaders={resourceHeader}
                                tableRows={internalEventDetails.resourceDetails.map(resourceDetail => ({
                                    id: resourceDetail.resourceId,
                                    name: resourceDetail.name
                                }))}
                                rowClick={props.admin === true ? (resourceId) => history.push(`/dashboard/${businessId}/Resource/${resourceId}`) : undefined}
                            />
                        ) : (
                            <div className={classes.topMarginBlock}>
                                <Typography className={classes.valueTextPad} style={{ maxWidth: "100%" }}>
                                    No resources have been assigned to this internal event.
                                </Typography>
                            </div>
                        )}
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className={classes.headingWrapper}>
                        <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Site</Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        <LightTooltip arrow title={internalEventDetails.address2 ? `${internalEventDetails.address1}, ${internalEventDetails.address2}` : `${internalEventDetails.address1}`}>
                            <Typography className={classes.valueText} style={{ paddingBottom: "10px" }} justify="center">
                                <b>Address:</b> {internalEventDetails.address2 ? `${internalEventDetails.address1}, ${internalEventDetails.address2}` : `${internalEventDetails.address1}`}
                            </Typography>
                        </LightTooltip>
                        <div style={{ height: 400, width: "100%", justify: "center" }}>
                            <MapContainer siteDetails={{
                                address1: internalEventDetails.address1,
                                geoPoint: {
                                    lat: internalEventDetails.geoPoint[1],
                                    lon: internalEventDetails.geoPoint[0]
                                }
                            }}
                                options={{
                                    disableDefaultUI: true,
                                    zoomControl: false,
                                    gestureHandling: 'none'


                                }} />
                        </div>

                    </div>
                </Grid>
                {internalEventDetails.seriesId !== null ?
                    <React.Fragment>
                        <Grid item xs={12}>
                            <div className={classes.headingWrapper}>
                                <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Series Information</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.contentRow}>
                                <div
                                    className={
                                        classes.fieldRows
                                    }
                                >
                                    <div
                                        className={
                                            classes.fieldColumnOne
                                        }
                                    >
                                        <Typography className={classes.labelText}>Starting Recurring Date</Typography>
                                    </div>
                                    <div
                                        className={classes.fieldColumnTwo}
                                        style={{ paddingLeft: "15px" }}
                                    >
                                        <Typography className={classes.valueText} noWrap>
                                            {internalEventDetails.recuringStartDate}
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    className={
                                        classes.fieldRows
                                    }
                                >
                                    <div
                                        className={
                                            classes.fieldColumnOne
                                        }
                                    >
                                        <Typography className={classes.labelText}>Ending Recurring Date</Typography>
                                    </div>
                                    <div
                                        className={classes.fieldColumnTwo}
                                        style={{ paddingLeft: "15px" }}
                                    >
                                        <Typography className={classes.valueText} noWrap>
                                            {internalEventDetails.recuringEndDate}
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    className={
                                        classes.fieldRows
                                    }
                                >
                                    <div
                                        className={
                                            classes.fieldColumnOne
                                        }
                                    >
                                        <Typography className={classes.labelText}># of Recurrences</Typography>
                                    </div>
                                    <div
                                        className={classes.fieldColumnTwo}
                                        style={{ paddingLeft: "15px" }}
                                    >
                                        <Typography className={classes.valueText} noWrap>
                                            {internalEventDetails.totalRecuringEvents}
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    className={
                                        classes.fieldRows
                                    }
                                >
                                    <div
                                        className={
                                            classes.fieldColumnOne
                                        }
                                    >
                                        <Typography className={classes.labelText}># of Recurrences Canceled</Typography>
                                    </div>
                                    <div
                                        className={classes.fieldColumnTwo}
                                        style={{ paddingLeft: "15px" }}
                                    >
                                        <Typography className={classes.valueText} noWrap>
                                            {internalEventDetails.totalRecurringCanceledEvents}
                                        </Typography>

                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </React.Fragment>
                    : null}
                <Grid item xs={12}>
                    <div className={classes.headingWrapper}>
                        <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Timeline</Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.contentRow} >
                        <Timeline style={{ width: 0, padding: 0 }}>
                            {(internalEventDetails.isCanceled ?

                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Typography className={classes.valueText}>
                                            Canceled By: {`${internalEventDetails.canceledByStaffFirstName} ${internalEventDetails.canceledByStaffLastName}`}
                                        </Typography>
                                        <Typography className={classes.labelText}>
                                            At: {moment(internalEventDetails.canceledAt).format("MMM, Do, YYYY : h:mm A ")}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                                :
                                null)}

                            {internalEventDetails.rescheduleHistory.map((hist) => {
                                return (
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Typography className={classes.valueText}>
                                                {`Rescheduled By: ${hist.rescheduledByStaffFirstName} ${hist.rescheduledByStaffLastName}`}
                                            </Typography>
                                            <Typography className={classes.labelText}>
                                                {`Original Time: ${hist.oldTime}`}
                                            </Typography>
                                            <Typography className={classes.labelText}>
                                                At: {moment(hist.rescheduledAt).format("MMM, Do, YYYY : h:mm A ")}
                                            </Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                )
                            })}

                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Typography className={classes.valueText}>
                                        Created By: {`${internalEventDetails.createdByStaffFirstName} ${internalEventDetails.createdByStaffLastName}`}
                                    </Typography>
                                    <Typography className={classes.labelText}>
                                        At: {moment(internalEventDetails.createdAt).format("MMM, Do, YYYY : h:mm A ")}
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>

                        </Timeline>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bottomSpacer}></div>
                </Grid>
            </Grid >
        )
    }
}

export default InternalEventsDetails