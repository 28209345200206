
import React from 'react'
import { Grid, Typography, } from '@material-ui/core'
import ListTable from '../../../TableComponents/ListTable';
import StatusTag from '../../../TagComponents/StatusTag';
const addOnHeaders = [
    {
      name: "Name",
      columnKey: "name",
      columnWidth: "30%",
      type: "Typography",
      tooltip: true
    },
  
  
    {
      name: "Basis",
      columnKey: "sellBy",
      columnWidth: "100px",
      type: "Normal"
    },
    {
      name: "Description",
      columnKey: "description",
      columnWidth: "70%",
      tooltip: true
    },
    {
      name: "",
      columnKey: "action",
      columnWidth: "100px",
      type: "Normal"
    },
  ];

const AddOnTable = (props) => {
    const { sessionDetails, classes, bigEnough } = props

    return (sessionDetails.attendeeAddOn.length > 0 ?
        <React.Fragment>
            <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                    <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Add Ons</Typography>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.contentRow}>
                    <ListTable
                        styling={{ width: "100%", maxHeight: 250 }}
                        identifier={"addOns"}
                        tableHeaders={addOnHeaders}
                        tableRows={sessionDetails.attendeeAddOn.map((aO) => {
                            return ({
                                name: aO.displayName,
                                sellBy: aO.sellBy.toLowerCase(),
                                description: aO.description,
                                action: (aO.isCanceled ? <StatusTag wrapperName={'redWrapper'} value={'Canceled'} /> : null)
                            })
                        })}
                        rowClick={null}
                    />
                </div>
            </Grid>
        </React.Fragment> :
        null)
}

export default AddOnTable