import { Container } from "@material-ui/core";
import {
  attachOptionToPriceGroup,
  detachOptionFromPriceGroup,
} from "../../../../../../utils";
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const UpdatePriceGroupForm = (props) => {
  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Container className={props.classes.formContainer}>
        <Form
          moreData={{
            businessId: props.businessId,
            optionId: props.optionDetails.optionId,
          }}
          title="Update Assigned Price Group"
          cancelEnabled
          cancelAction={() => {
            props.setOpen(false);
          }}
          action={async (payload) => {
            if (payload.priceGroupId === "None") {
              return await detachOptionFromPriceGroup({
                ...payload,
                priceGroupId: undefined,
              });
            } else {
              return await attachOptionToPriceGroup(payload);
            }
          }}
          postAction={(res) => {
            if (res.error === false) {
              props.setOpen(false);
              props.refetch();
            }
          }}
          inputFields={[
            {
              type: "SingleSelect",
              name: "priceGroupId",
              label: "Price Group",
              choices: [
                [<i>None</i>, "None"],
                ...props.pricingGroups.map((priceGroup) => [
                  priceGroup.name,
                  priceGroup.priceGroupId,
                ]),
              ],
              validator: (value) =>
                typeof value === "string" || value === "None"
                  ? null
                  : "Please select a price group or none.",
              initialvalue:
                props.optionDetails.priceGroup?.priceGroupId ?? "None",
            },
          ]}
        />
      </Container>
    </CModal>
  );
};

export default UpdatePriceGroupForm;
