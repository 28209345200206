import { Button, Checkbox, CircularProgress, Container, Grid, IconButton, Menu, MenuItem, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import PublishIcon from "@material-ui/icons/Publish";
import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getStaffList, signedAdminImageUrlUpload, staffActive, updateStaff } from "../../../../../../utils";
import CDisplayArea from "../../../../../Form/CDisplayArea";
import CModal from "../../../../../Form/CModal";
import CModalError from "../../../../../Form/CModalError";
import LightTooltip from "../../../../../Form/LightTooltip";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import { CCloseIcon, CEllipsisIcon } from "../../../../../Icons/Icons";
import StatusTag from "./../../../../../TagComponents/StatusTag";
import EditPermsForm from "./EditPermsForm";
import EditProfileForm from "./EditProfileForm";

const useStyles = makeStyles((theme) => ({
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    topBlock: {
        paddingBottom: "15px",
    },
    recordRow: {
        paddingLeft: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRoght: "5px",
        width: "550px"
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    rightElem: {
        float: "right",
    },
    rightElemPad: {
        float: "right",
        marginRight: "10px",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeadingOne: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    topHeadingTwo: {
        fontSize: "28px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    topHeadingThree: {
        backgroundColor: "#eb5757",
        opacity: "50%",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    topHeadingFour: {
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    buttonBasic: {
        backgroundColor: "#ffffff",
        color: "#1a1f36",
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    contentColumnFirst: {
        paddingRight: "20px",
        boxShadow: "inset -1px 0 #e3e8ee",
        display: "inline-block",
    },
    contentColumnMiddle: {
        boxShadow: "inset -1px 0 #e3e8ee",
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "inline-block",
    },
    contentColumnLast: {
        paddingLeft: "20px",
        display: "inline-block",
    },
    labelText: {
        fontSize: "14px",
        color: "#697386",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText2: {
        fontSize: "14px",
        color: "#3c4257",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextNeg: {
        fontSize: "14px",
        color: "#009211",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBG: {
        fontSize: "16px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBold: {
        fontSize: "14px",
        color: "#1a1f36",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextPad: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingLeft: "10px",
    },
    valueTextLink: {
        fontSize: "14px",
        color: "#3d5af1",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    errorText: {
        fontSize: "14px",
        color: "#ff0000",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    blockLabel: {
        paddingTop: "10px",
        paddingBottom: "5px",
    },
    blockText: {
        paddingLeft: "10px",
    },
    subHeading: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    fieldRows: {
        paddingTop: "8px"
    },
    fieldColumnOne: {
        width: "108px",
        display: "inline-block",
    },
    fieldColumnTwo: {
        display: "inline-block",
    },
    sdCustomCellOne: {
        border: "none",
        width: "5%",
        maxWidth: "200px",
        padding: "0px",
    },
    sdCustomCellTwo: {
        border: "none",
        width: "10%",
        maxWidth: "300px",
        padding: "10px",
    },
    sdCustomCellThree: {
        border: "none",
        width: "85%",
        maxWidth: "200px",
        padding: "10px",
    },
    sdCustomCellFour: {
        border: "none",
        padding: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingThree: {
        fontSize: "20px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    headerPadding: {
        padding: "10px",
        paddingLeft: "0px"
    },
    bottomSpacer: {
        height: "50px",
    },
    topMarginBlock: {
        marginTop: "10px",
    },
    loaderWrapper: {
        width: "100%",
        height: "calc(100vh - 64px)"
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    },
    media: {
        height: 200,
        width: 200,
        objectFit: "cover"
    },
    avatar: {
        height: theme.spacing(20),
        width: theme.spacing(20),
    },
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px"
    }
}));

const SingleStaff = () => {
    const classes = useStyles();
    const [_, updateState] = useState(0);
    const [anchorEl, setAnchorEl] = useState(false);
    const [staffDetails, setStaffDetails] = useState(null);
    const [eProfOpen, setEProfOpen] = useState(false);
    const [ePermOpen, setEPermOpen] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [imageName, setImageName] = useState(false);
    const [dI, setDI] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [ADOpen, setADOpen] = useState(false);
    const [docForm, setDocForm] = useState(false);
    const bigEnough = useMediaQuery("(min-width:750px )");
    const dispatch = useDispatch();
    const businessId = useSelector((state) => {
        return state.buisnessDetails.businessId;
    });
    const { staffIdURL } = useParams();

    const handleImage = async (file) => {
        if (file.target.files && file.target.files[0] && file.target.files[0].type && file.target.files[0].type.includes('image')) {
            setFileName(file.target.files[0].name);
            setFileData(file.target.files[0]);
            setImageName(URL.createObjectURL(file.target.files[0]));
            setDocForm(true);
        };
    };

    const handleImageAdd = async () => {
        let preSignedURL = null;

        dispatch({
            type: "UPDATE",
            payload: {
                loading: true,
            },
        });

        preSignedURL = await signedAdminImageUrlUpload({
            businessId,
            fileName
        });

        if (preSignedURL && !preSignedURL.error) {

            let s3url = preSignedURL.signedData.url;
            let fields = preSignedURL.signedData.fields;
            let formData = new FormData();

            for (let k in fields) {
                formData.append(k, fields[k]);
            };

            formData.append('file', fileData);

            try {
                await axios.post(s3url, formData);
                await updateStaff({
                    businessId: businessId,
                    staffId: staffDetails.staffId,
                    image: preSignedURL.imageURL
                });

                dispatch({
                    type: "UPDATE",
                    payload: {
                        loading: false,
                    },
                });
                setDocForm(false);

            } catch (error) {
                dispatch({
                    type: "UPDATE",
                    payload: {
                        loading: false,
                    },
                });
            };

        } else {
            dispatch({
                type: "UPDATE",
                payload: {
                    loading: false,
                },
            });
        };
        updateState((_) => !_);
    };

    async function handleDelete(event) {

        dispatch({
            type: 'UPDATE', payload: {
                loading: true
            }
        });

        let success = await updateStaff({
            businessId: businessId,
            staffId: staffDetails.staffId,
            image: null
        });
        dispatch({
            type: 'UPDATE', payload: {
                loading: false
            }
        });
        if (success) {
            setDI(false)
            updateState((_) => !_);
        }
    };

    const withLoading = async (fn, arg) => {
        let temp = null;
        dispatch({
            type: "UPDATE",
            payload: {
                loading: true,
            },
        });
        temp = await fn(arg)
        dispatch({
            type: "UPDATE",
            payload: {
                loading: false,
            },
        });
        return (temp)
    }

    useEffect(async () => {

        let data = await withLoading(getStaffList, {
            businessId: businessId,
            staffId: staffIdURL,
        })

        if (data && Array.isArray(data)) {
            setStaffDetails(data[0])
        }
    }, [_]);

    if (staffDetails !== null) {
        return (
            <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                <React.Fragment>
                    <GeneralDialog
                        open={dI}
                        close={setDI}
                        title="Delete Staff Profile Image"
                        text="Are you sure you want to delete this staff's profile image?"
                        action={handleDelete}
                    />
                    <EditProfileForm open={eProfOpen} setOpen={setEProfOpen} staffDetails={staffDetails} refresh={() => { updateState(_ => !_) }} />
                    <EditPermsForm open={ePermOpen} setOpen={setEPermOpen} staffDetails={staffDetails} refresh={() => { updateState(_ => !_) }} />
                    <CModal open={docForm} setOpen={setDocForm}
                        children={
                            <Container component="main" className={classes.formContainer}>
                                <Grid container spacing={1} item xs={12} direction="column" alignItems="center">
                                    <CModalError />
                                    <Grid item xs={12}>
                                        <div className="container">
                                            <img style={{ minHeight: "250px", height: "100%", width: "100%" }} src={imageName ? imageName : null} alt="smth" />
                                            {staffDetails.profileAccess ? <span className={bigEnough ? "button" : "bSmall"} style={{ display: "inline-flex" }}>
                                                <input
                                                    accept="image/*"
                                                    onChange={(file) => { handleImage(file); }}
                                                    style={{ display: 'none' }}
                                                    id="contained-button-file"
                                                    multiple
                                                    type="file"
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <IconButton variant="contained" component="span" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
                                                        <PublishIcon />
                                                    </IconButton>
                                                </label>
                                            </span> : null}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} />
                                    <Grid container item xs={12} spacing={1} justifyContent="flex-end" direction="row">
                                        <Grid item >
                                            <Button className={classes.buttonBasic} onClick={() => { setDocForm(false) }}>Cancel</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button className={[classes.buttonBasic, classes.rightElem]} style={{ backgroundColor: "#0079fc", color: "white" }} onClick={handleImageAdd}>Submit</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                        }
                    />
                    <GeneralDialog
                        open={ADOpen}
                        close={() => { setADOpen(false) }}
                        title={staffDetails.isActive ? "Deactivate Staff" : "Activate Staff"}
                        text={staffDetails.isActive ? "Are you sure you want to deactivate this staff?" : "Are you sure you want to activate this staff?"}
                        action={async () => {
                            let data = await withLoading(staffActive,
                                {
                                    businessId: businessId,
                                    staffId: staffDetails.staffId,
                                    isActive: !staffDetails.isActive
                                })
                            if (data) {
                                setADOpen(false)
                                updateState(_ => !_)
                            }
                        }}
                    />
                    <Menu
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={Boolean(anchorEl)}
                        onClose={() => { setAnchorEl(false) }}
                    >
                        <MenuItem onClick={() => { setEProfOpen(true); setAnchorEl(false) }}>Edit Staff</MenuItem>
                        {(staffDetails.self || staffDetails.isOwner ? null :
                            <MenuItem onClick={() => { setADOpen(true); setAnchorEl(false) }}>{staffDetails.isActive ? "Deactivate Staff" : "Activate Staff"}</MenuItem>)}
                    </Menu>
                </React.Fragment>
                <Grid item xs={12}>
                    <div className={classes.topBlock}>
                        <span className={classes.topHeading}>STAFF</span>
                        <span className={classes.rightElem}><Button aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => { setAnchorEl(event.currentTarget); }} className={classes.buttonBasic}><CEllipsisIcon /></Button></span>
                    </div>
                </Grid>
                <Grid item style={(bigEnough ? { maxWidth: "50%" } : { width: "100%" })}>
                    <div className={classes.headerPadding}>
                        <LightTooltip title={`${staffDetails.firstName} ${staffDetails.lastName}`} arrow>
                            <Typography className={(bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne)} noWrap>
                                {`${staffDetails.firstName} ${staffDetails.lastName}`}
                            </Typography>
                        </LightTooltip>
                    </div>
                </Grid>
                {(bigEnough ?
                    <Grid item>
                        <div className={classes.headerPadding} style={{ paddingLeft: "20px" }}>
                            <span className={classes.topHeadingFour}>
                                <StatusTag wrapperName={staffDetails.isActive ? "greenWrapper" : "redWrapper"} value={staffDetails.isActive ? "Active" : "Inactive"} />
                            </span>
                        </div>
                    </Grid>
                    :
                    <Grid item>
                        <div style={{ paddingLeft: "10px" }}>
                            <StatusTag wrapperName={staffDetails.isActive ? "greenWrapper" : "redWrapper"} value={staffDetails.isActive ? "Active" : "Inactive"} />
                        </div>
                    </Grid>
                )}
                <Grid item xs={12} style={{ padding: 16 }}>
                    <Grid container style={{ paddingTop: "15px" }} spacing={4}>
                        <Grid item xs={(bigEnough ? null : 12)} >
                            <Grid container justify={(bigEnough ? "left" : "center")}>
                                <div className="container">
                                    <img className={classes.media} src={(staffDetails.image ? staffDetails.image : "https://www.nicepng.com/png/detail/52-521023_download-free-icon-female-vectors-blank-facebook-profile.png")} alt="smth" />
                                    <span className={bigEnough ? "button" : "bSmall"} style={{ display: "inline-flex" }}>
                                        <input
                                            accept="image/*"
                                            onChange={(file) => { handleImage(file); }}
                                            style={{ display: 'none' }}
                                            id="contained-button-file"
                                            multiple
                                            type="file"
                                        />
                                        <label htmlFor="contained-button-file">
                                            <IconButton variant="contained" component="span" style={staffDetails.image && staffDetails.image !== "https://caritra-app-images.s3.us-west-1.amazonaws.com/default-images/profile.png" ? { right: "41px", backgroundColor: "rgba(255, 255, 255, 0.6)", height: "20px", width: "20px" } : { backgroundColor: "rgba(255, 255, 255, 0.6)", height: "20px", width: "20px" }}><EditIcon style={{ color: "#1a1f36", opacity: 1 }} /></IconButton>
                                        </label>
                                        {staffDetails.image && staffDetails.image !== "https://caritra-app-images.s3.us-west-1.amazonaws.com/default-images/profile.png" ?
                                            <LightTooltip title="Delete Business Logo" arrow>
                                                <IconButton variant="contained" style={{ right: "40px", backgroundColor: "rgba(255, 255, 255, 0.6)", height: "44px", width: "44px" }} onClick={() => { setDI(true); }}>
                                                    <CCloseIcon />
                                                </IconButton>
                                            </LightTooltip>
                                            : null}
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <div className={classes.contentRow}>
                                <div className={classes.fieldRows}>
                                    <div className={classes.fieldColumnOne} style={{ paddingBottom: "5px" }}>
                                        <Typography noWrap className={classes.labelText}>Member Id</Typography>
                                    </div>
                                    <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
                                        <Typography noWrap className={classes.valueText}>
                                            {staffDetails.memberId}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.fieldRows}>
                                    <div className={classes.fieldColumnOne} style={{ paddingBottom: "2px" }}>
                                        <Typography noWrap className={classes.labelText}>Buisness Owner</Typography>
                                    </div>
                                    <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
                                        <Typography noWrap className={classes.valueText}>
                                            {(staffDetails.isOwner ? "Yes" : "No")}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.fieldRows}>
                                    <div className={classes.fieldColumnOne} style={{ paddingBottom: "2px" }}>
                                        <Typography noWrap className={classes.labelText}>Role</Typography>
                                    </div>
                                    <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
                                        <Typography noWrap className={classes.valueText}>
                                            {staffDetails.role}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.fieldRows}>
                                    <div className={classes.fieldColumnOne} style={{ paddingBottom: "2px" }}>
                                        <Typography noWrap className={classes.labelText}>Email</Typography>
                                    </div>
                                    <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
                                        <LightTooltip title="Email Staff" arrow>
                                            <Typography noWrap className={classes.valueTextLink}>
                                                <a style={{ color: "#0079fc" }} href={"mailto:" + staffDetails.email}>{staffDetails.email}</a>
                                            </Typography>
                                        </LightTooltip>
                                    </div>
                                </div>
                                <div className={classes.fieldRows}>
                                    <div className={classes.fieldColumnOne} style={{ paddingBottom: "2px" }}>
                                        <Typography noWrap className={classes.labelText}>Phone Number</Typography>
                                    </div>
                                    <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
                                        <Typography noWrap className={classes.valueText}>
                                            {`${staffDetails.phoneNumber.slice(0, 2)} (${staffDetails.phoneNumber.slice(2, 5)}) ${staffDetails.phoneNumber.slice(5, 8)}-${staffDetails.phoneNumber.slice(8, 12)}`}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <div className={classes.headingWrapper} style={{ paddingTop: "10px" }}>
                        <span className={classes.labelText}>About</span>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        <span className={classes.valueText}>
                            <CDisplayArea
                                initialMaxHeight={200}
                                value={staffDetails.profile ? staffDetails.profile : null}
                            />
                        </span>{" "}
                    </div>
                </Grid>
                {
                    !staffDetails.self && !staffDetails.isOwner ?
                        <React.Fragment>
                            <Grid item xs={12}>
                                <div className={classes.headingWrapper}>
                                    <span className={classes.subHeading}>
                                        Permissions
                                        <Button
                                            className={[classes.buttonBasic, classes.rightElem]}
                                            variant="outlined"
                                            style={staffDetails.self || staffDetails.isOwner || staffDetails.isActive === false ? { opacity: 0.5, backgroundColor: "grey" } : { backgroundColor: "#0079fc" }}
                                            disabled={staffDetails.self || staffDetails.isOwner || staffDetails.isActive === false}
                                            onClick={() => { setEPermOpen(true) }}
                                        >
                                            <EditIcon style={{ color: "white" }} />
                                        </Button>
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.contentRow}>
                                    <Grid container >
                                        {
                                            staffDetails.role === "Staff" ?
                                                <React.Fragment>
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography
                                                            className={classes.valueText}
                                                        >
                                                            <Checkbox
                                                                color="primary"
                                                                checked={staffDetails.profileAccess}
                                                            />
                                                            Profile Access
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} >
                                                        <Typography
                                                            className={classes.valueText}
                                                        >
                                                            <Checkbox
                                                                color="primary"
                                                                checked={staffDetails.schedulingAccess}
                                                            />
                                                            Scheduling Access
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography
                                                            className={classes.valueText}
                                                        >
                                                            <Checkbox
                                                                color="primary"
                                                                checked={staffDetails.dashboardAccess}
                                                            />
                                                            Dashboard Access
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography
                                                            className={classes.valueText}
                                                        >
                                                            <Checkbox
                                                                color="primary"
                                                                checked={staffDetails.checkInAccess}
                                                            />
                                                            Log Presence Access
                                                        </Typography>
                                                    </Grid>
                                                </React.Fragment>
                                                : null
                                        }
                                        {
                                            staffDetails.role === "Admin" ?
                                                <Grid item xs={12} sm={4}>
                                                    <Typography
                                                        className={classes.valueText}
                                                    >
                                                        <Checkbox
                                                            color="primary"
                                                            checked={staffDetails.financeAccess}
                                                        />
                                                        Finance Access
                                                    </Typography>
                                                </Grid>
                                                : null
                                        }
                                        {
                                            staffDetails.role === "Admin" ?
                                                <Grid item xs={12} sm={4}>
                                                    <Typography
                                                        className={classes.valueText}
                                                    >
                                                        <Checkbox
                                                            color="primary"
                                                            checked={staffDetails.reportAccess}
                                                        />
                                                        Report Access
                                                    </Typography>
                                                </Grid>
                                                : null
                                        }
                                    </Grid>
                                </div>
                            </Grid>
                        </React.Fragment>
                        : null
                }
                <Grid item xs={12}>
                    <div className={classes.bottomSpacer}></div>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container className={classes.mainWrapper}>
                <Grid item xs={12}>
                    <div className={classes.loaderWrapper}>
                        <CircularProgress className={classes.loaderMain} />
                    </div>
                </Grid>
            </Grid>
        );
    }
};

export default SingleStaff;
