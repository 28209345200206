import React, { useEffect } from 'react'

import { Container, makeStyles, Typography } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CTitle from '../../../../../Form/CTitle';
import CModalError from '../../../../../Form/CModalError';
import CModal from '../../../../../Form/CModal';

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "#1a1f36",
    paddingBottom: 5,
    boxShadow: "inset 0 -1px #e3e8ee",
    fontFamily: "Rubik, sans-serif",
    fontSize: "20px"
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "500px",
    width: "100%",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  action: {
    fontSize: 14,
    textTransform: "none",
},
fieldRows: {
    paddingTop: "8px"
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  buttonRight: {
    float: "right"
  }
}))

const ResourceMapDialog = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.open) {
      dispatch({
        type: "UPDATE",
        payload: {
          insideModal: true
        }
      })
    }

    return (() => {
      dispatch({
        type: "UPDATE",
        payload: {
          insideModal: false
        }
      })
    })
  }, [props.open, dispatch])




  return (
    <CModal
      open={props.open}
      //  setOpen always closes modal
      setOpen={(bool) => { props.close(false) }}
    >
      <Container className={classes.formContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CTitle title={props.title} />
          </Grid>
          <Grid xs={12}>
            <CModalError />
          </Grid>
          <Grid item xs={12}>
              {props?.text?.description ?  <div className={classes.fieldRows}>
                <div className={classes.fieldColumnTwo} style={{ maxWidth: "50px" }}>
                  <span className={classes.labelText} style={{ whiteSpace: "normal" }}>Description</span>{" "}
                </div>
                <div className={classes.fieldColumnTwo} style={{ paddingLeft: "40px" }}>
                <span className={classes.valueText}>
                    {props.text.description ? props.text.description : null}
                </span>
                </div>
              </div> : null}
            {props.text ? props.text.fields.map((field) => {
                return <div className={classes.fieldRows}>
                <div className={classes.fieldColumnTwo} style={{ maxWidth: "50px" }}>
                  <span className={classes.labelText} style={{ whiteSpace: "normal" }}>{field.name ? field.name : null}</span>{" "}
                </div>
                <div className={classes.fieldColumnTwo} style={{ paddingLeft: "40px" }}>
                <span className={classes.valueText}>
                    {field.value ? field.value : null}
                </span>
                </div>
              </div>
            }) : null}

          </Grid>
          <Grid xs={12}>
          <Button
                onClick={() => {props.close(false);}}
                color="primary"
                variant="contained"
                className={classes.buttonRight}
            >
                <Typography className={classes.action}>
                    Close
                </Typography>
            </Button>
          </Grid>

        </Grid>
      </Container>
    </CModal>
  );
}

export default ResourceMapDialog