import React from 'react';
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Membership from "./Membership";
import MembershipsList from "./MembershipsList";

function MembershipHandler() {
    const history = useHistory();
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    return(
        <React.Fragment>
            <Switch>
            <Route path = {`/dashboard/${businessId}/Memberships`} exact render = {() => {return(<MembershipsList />)}} />
            <Route path = {`/dashboard/${businessId}/Memberships/:membershipIdURL`} render = {() => {return(<Membership />);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default MembershipHandler;