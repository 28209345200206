import React from 'react';
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Package from "./Package";
import PackageList from "./PackageList";

function PackagesHandler() {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    return(
        <React.Fragment>
            <Switch>
            <Route path = {`/dashboard/${businessId}/Packages-and-Passes`} exact render = {() => {return(<PackageList />)}} />
            <Route path = {`/dashboard/${businessId}/Packages-and-Passes/:packIdURL`} render = {() => {return(<Package />);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default PackagesHandler;