import React from 'react'
import CNumber from './CNumber'
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles((theme)=>({
    icon:{
        padding:0,
        color:theme.palette.textColor3.main
    }
}))

const CPercent = (props) => {
    const classes = useStyles()
    return (
        <CNumber
            {...props}
            moreProps={{...props.moreProps, InputProps:
                {
                    endAdornment:
                        <InputAdornment position="end" >
                            <Typography className = {classes.icon}>
                                %
                            </Typography>
                        </InputAdornment>
                }
            }}
        />
    )
}

export default CPercent;