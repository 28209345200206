import { Button, Card, Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import StarIcon from '@material-ui/icons/Star';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { Link } from "react-router-dom";
import { formatSrc } from "../../Image/Image";

const simplePricingData = [
    {
        icon: (props) => (<CheckBoxIcon {...props} />),
        text: 'Everything you need to manage your business'
    },
    {
        icon: (props) => (<VpnKeyIcon {...props} />),
        text: 'Unlimited access to full range of features',
    },
    {
        icon: (props) => (<StarIcon {...props} />),
        text: 'No setup fees, monthly fees, or hidden fees',
        bold: true
    },

]

const useStyles = makeStyles(theme => ({
    hero: {
        backgroundImage: `url(${formatSrc('https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-price/pricing.webp', {
            mobile: false,
            mobileOptions: undefined,
            desktopOptions: ['fit-in/1920x1080']
        })})`,
        backgroundSize: "cover",
        minHeight: "max(100vh, 600px)",
        padding: '0px 10px 0px 10px'
    },
    whiteText: {
        color: 'white'
    },
    cardHolder: {
        minHeight: '600px'
    },
    pricingCard: {
        width: 'min(460px, calc(100% - 40px))',
        padding: '20px',
        margin: '0 auto',
        height: 'calc(100% - 40px)',
        display: 'flex',
        flexDirection: 'column'
    },
    bold: {
        fontWeight: 'bold'
    },
    buttonText: {
        textTransform: 'none',
        fontSize: '1.5rem',
        textDecoration: 'none'
    },
    button: {
        width: 'min(100%,300px)',
        height: '60px',
        textDecoration: 'none'
    },
    paddingTop: {
        paddingTop: '20px'
    },
    paddingBottom: {
        paddingBottom: '50px'
    },
    centerH: {
        margin: '0 auto'
    },
    spacing: {
        padding: '8px'
    },
    spacer: {
        height: '100px'
    }
}))


const Pricing = (props) => {
    const classes = useStyles()
    const mobile = useMediaQuery("(max-width:750px)")
    return (
        <div className={classes.spacing}>
            <Grid container className={classes.hero} spacing={2}>
                <Grid item xs={12} className={classes.spacer} />
                <Grid item xs={12}>
                    <Typography variant={mobile ? "h4" : "h3"} align="center" className={classes.whiteText} gutterBottom>
                        Pricing built for businesses of all sizes
                    </Typography>
                    <Typography variant={mobile ? "h4" : "h3"} align='center' className={classes.paddingBottom}>
                        Always know what you’ll pay.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={classes.cardHolder}>
                    <Card className={classes.pricingCard}>
                        <Typography align='center' variant="h5" color='primary' className={`${classes.bold} ${classes.paddingBottom}`}>
                            Simple Pricing
                        </Typography>
                        <Typography align='center' variant="h6" className={classes.paddingBottom}>
                            Only pay for what you use
                            without any commitment
                        </Typography>
                        <div className={`${classes.paddingBottom} ${classes.centerH}`} style={{ width: 'calc(100% - 40px)' }}>
                            {simplePricingData.map(({ icon, text, bold }, index) => (
                                <Grid container>
                                    <Grid item style={{ paddingRight: '10px' }}>
                                        {icon({
                                            color: 'primary'
                                        })}
                                    </Grid>
                                    <Grid item style={{ width: 'calc(100% - 34px)' }}>
                                        <Typography className={bold ? classes.bold : undefined}>
                                            {text}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            ))}
                        </div>
                        <Typography align='center' variant="h6" className={classes.paddingBottom}>
                            1 % of transaction amount
                        </Typography>
                        <Grid container justifyContent="center" alignItems="flex-end" style={{ flexGrow: 1 }} className={classes.paddingBottom}>
                            <Link to='/for-business/create' className={classes.button}>
                                <Button color='primary' variant='contained' className={classes.button}>
                                    <Typography className={classes.buttonText}>
                                        Get Started
                                    </Typography>
                                </Button>
                            </Link>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} className={classes.cardHolder}>
                    <Card className={classes.pricingCard}>
                        <Typography align='center' variant="h5" color='primary' className={`${classes.bold} ${classes.paddingBottom}`}>
                            Customized Pricing
                        </Typography>
                        <Typography align='center' variant="h6" className={classes.paddingBottom}>
                            For businesses with high volume transactions and unique needs
                        </Typography>
                        <div className={`${classes.paddingBottom} ${classes.centerH}`} style={{ width: 'calc(100% - 40px)' }}>
                            <Typography align='center' gutterBottom>
                                We listen to your business scenarios, and personalize the best solutions for you.
                            </Typography>
                            <Typography align="center" className={classes.bold}>
                                We are flexible
                            </Typography>
                        </div>
                        <Typography align='center' variant="h6" className={classes.paddingBottom}>
                            Pricing tailored for your business
                        </Typography>
                        <Grid container justifyContent="center" alignItems="flex-end" style={{ flexGrow: 1 }} className={classes.paddingBottom}>
                            <Link to='/for-business/contact-sales' className={classes.button}>
                                <Button color='primary' variant='contained' className={classes.button}>
                                    <Typography className={classes.buttonText}>
                                        Contact Sales
                                    </Typography>
                                </Button>
                            </Link>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} className={classes.spacer} />
            </Grid>
        </div >
    )
}

export default Pricing