import React, { useEffect } from 'react'
import { makeStyles, Collapse, IconButton, Grid } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import Alert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    errorContainer: {
        padding: theme.spacing(1),
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const CModalError = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const error = useSelector(state => state.error);
    const errorMessage = useSelector(state => state.errorMessage)
    const insideModal = useSelector(state => state.insideModal)

    const clearError = () => {
        dispatch({
            type: "UPDATE",
            payload: {
                error: false,
                errorMessage: null,
            }
        })
    }

    useEffect(() => {
        if (!insideModal) {
            clearError()
        }
        return clearError
    }, [insideModal])

    return (
        <Grid item xs={12} style={error && insideModal ? {} : { padding: 0 }}>
            <div className={classes.root}>
                <Collapse in={error && insideModal} style={{ width: "100%" }}>
                    <Alert
                        variant="filled" severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    clearError();
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {errorMessage}
                    </Alert>
                </Collapse>
            </div>
        </Grid>
    )
}

export default CModalError