import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { removeStaffInternalEvent, removeStaffInternalSeries } from '../../../../../../utils';
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto"

    }
}))

const RemoveInternalEventStaffForm = (props) => {


    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()

    return (
        <React.Fragment>
            <CModal open={props.open} setOpen={props.setOpen}>
                <Container component="main" className={classes.formContainer}>
                    <Form
                        moreData={{
                            businessId: businessId,
                            eventId: props.internalEventDetails.eventId,
                            editTag: props.internalEventDetails.editTag,
                            seriesId: props.internalEventDetails.seriesId
                        }}
                        title="Remove Staff from Internal Event(s)"
                        cancelEnabled
                        cancelAction={() => { props.setOpen(false) }}
                        submitText="Remove"
                        action={async (payload) => {
                            const finalPayload = {
                                businessId: businessId,
                                eventId: props.internalEventDetails.eventId,
                                editTag: props.internalEventDetails.editTag,
                                seriesId: props.internalEventDetails.seriesId,
                                staffIds: payload.staffIds

                            }
                            if (payload.applyAll === true) {
                                return await removeStaffInternalSeries(finalPayload)
                            }
                            else {
                                return await removeStaffInternalEvent(finalPayload)
                            }
                        }}
                        postAction={(res) => {
                            if (res && !res.error) {
                                props.setOpen(false)
                                props.fetchInternalEventDetails()
                            }
                        }}
                        inputFields={[
                            {
                                type: "MultiSelect",
                                name: "staffIds",
                                label: "Staff",
                                validator: value => (value === null || value.length === 0 ? "Select staff to remove" : null),
                                choices: props.internalEventDetails.staffDetails.map(staffDetail => [`${staffDetail.staffFirstName} ${staffDetail.staffLastName}`, staffDetail.staffId])
                            },
                            {
                                type: "CheckBox",
                                name: "applyAll",
                                label: "Remove staff from all events in series",
                                enabled: props.internalEventDetails.seriesId !== null
                            },
                        ]}
                    />
                </Container>
            </CModal>
        </React.Fragment>
    )
}

export default RemoveInternalEventStaffForm