import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PayoutBankForm from "./PayoutBankForm";
import {makeStyles} from "@material-ui/core/styles";
import {verificationDets} from "../../../../../../utils";
import {payoutBankInfo} from "../../../../../../utils";
import CModal from '../../../../../Form/CModal';
import { Grid, Button, CircularProgress, useMediaQuery } from '@material-ui/core';
import EditIcon from "@material-ui/icons/Edit";
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
      width: "100%",
      paddingLeft: "50px",
      paddingRight: "50px",
      paddingTop: "15px",
    },
    mobilePadding: {
      width: "100%",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "10px",
    },
    headerPadding: {
      padding: "10px",
    },
    headingWrapper: {
      paddingBottom: "15px",
      boxShadow: "inset 0 -1px #e3e8ee",
    },
    formContainer:{
      border: '20px',
      backgroundColor: 'white',
      borderRadius: '10px',
      padding:"20px",
      maxWidth: "600px"
    },
    topHeadingOne: {
      fontFamily:"Rubik, sans-serif",
      fontSize: "28px",
      fontWeight: "bold",
      color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
      fontFamily:"Rubik, sans-serif",
      fontSize: "20px",
      fontWeight: "bold",
      color: "#1a1f36",
    },
    subHeading: {
      fontFamily:"Rubik, sans-serif",
      fontSize: "24px",
      fontWeight: "bold",
      color: "#1a1f36",
    },
    contentRow: {
      paddingTop: "15px",
      paddingBottom: "25px",
    },
    fieldRows: {
      paddingTop: "8px"
    },
    fieldColumnOne: {
      width: "140px",
      display: "inline-block",
    },
    fieldColumnTwo: {
      display: "inline-block",
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      alignItems: "center",
    },
    root: {
      flexGrow: 1,
      justifyContent: 'center'
    },
    container: {
      margin: "0 auto"
    },
    space: {
      marginTop: theme.spacing(3)
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(1),
      },
    loaderMain : {
      position : "absolute",
      top : "50%",
      left: "50%"
    },
    buttonBasic: {
      backgroundColor: "#ffffff",
      color: "#1a1f36",
      fontSize: "14px",
      boxShadow:
        " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingTop: "3px",
      paddingBottom: "3px",
      textTransform: "none",
    },
    labelText: {
      fontFamily:"Rubik, sans-serif",
      fontSize: "14px",
      color: "#697386",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    valueText: {
      fontFamily:"Rubik, sans-serif",
      fontSize: "14px",
      color: "#1a1f36",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    valueTextPos: {
      textTransform:"uppercase",
      fontFamily:"Rubik, sans-serif",
      fontSize: "14px",
      color: "green",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    valueTextNeg: {
      textTransform:"uppercase",
      fontFamily:"Rubik, sans-serif",
      fontSize: "14px",
      color: "red",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    valueTextLink: {
      fontSize: "14px",
      color: "#0079fc",
      cursor: "pointer",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textDecoration:"underline"
    },
    rightElem: {
      float: "right",
    },
    ttCustomCellOne: {
      border: "none",
      width: "10%",
      maxWidth: "200px",
      padding: "10px",
    },
    ttCustomCellTwo: {
      border: "none",
      width: "20%",
      maxWidth: "300px",
      padding: "10px",
    },
    ttCustomCellThree: {
      border: "none",
      width: "10%",
      maxWidth: "200px",
      padding: "10px",
    },
    ttCustomCellFour: {
      border: "none",
      width: "10%",
      maxWidth: "200px",
      padding: "10px",
    },
    ttCustomCellFive: {
      border: "none",
      padding: "10px",
    },
    formContainer:{
      border: '20px',
      backgroundColor: 'white',
      boxShadow:
        " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
      borderRadius: '4px',
      padding:"20px",
      maxWidth: "600px"
    }
  }));
  
  const PayoutInformation = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [_, updateState] = useState(0);
    const large = useMediaQuery('(min-width: 960px)');
    const small = useMediaQuery('(min-width: 750px)');
      
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const payoutDetails= useSelector(((state) => { return (state.payoutBank) }));
    const [verificationDetails, setVerificationDetails] = useState(false);
    const [initialState, setInitialState] = useState(false);
    const [bankForm, setBankForm] = useState(false);
    const [load, setLoad] = useState(false);
  
    useEffect(async () => {
        dispatch({
            type: 'UPDATE', payload: {
                loading: true
            }
        });
        let success = await verificationDets({businessId: businessId});
        let payout = await payoutBankInfo({businessId: businessId});
        
        dispatch({
            type: 'UPDATE', payload: {
              loading: false,
              payoutBank: payout ? payout.payoutBank : null
            }
        });
        
        if(success) {
          setVerificationDetails(success.businessCheck);
          setInitialState(true);
          setLoad(false);
        };
    }, [load]);
  
      if (initialState) {
          return (
                    <Grid xs={12}>{verificationDetails.preAdd ? <Grid container xs={12} justify="center" spacing={2} className={large ? classes.mainWrapper : classes.mobilePadding}>
                        <CModal open={bankForm} setOpen={setBankForm} 
                            children={
                                <PayoutBankForm
                                open = {bankForm}
                                currency={"usd"}
                                country={"US"}
                                setOpen = {setBankForm}
                                cancelEnabled={true}
                                preAdd = {verificationDetails.preAdd}
                                success={()=>{setLoad(true); setBankForm(false);}}
                                />
                            }
                        />
                        <Grid container item className={classes.headerPadding} xs={12}>
                            <Grid item xs={6}>
                                <span className={small ? classes.topHeadingOne : classes.mobileTopHeadingTwo}>
                                    Bank Details
                                </span>
                            </Grid>
                            <Grid item xs={6} style={{paddingLeft:"100px"}}>
                                <Button variant="contained" style={{backgroundColor:"#0079fc", color:"white"}} className={[classes.buttonBasic, classes.rightElem]} onClick={()=>{setBankForm(true);}}><EditIcon /></Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.contentRow}>
                            <div className={classes.fieldRows}>
                                <div className={classes.fieldColumnOne}>
                                <span className={classes.labelText} style={{whiteSpace:"normal"}}>Country</span>{" "}
                                </div>
                                <div className={classes.fieldColumnTwo}>
                                    <span className={classes.valueText}>
                                        {payoutDetails.country} 
                                    </span>       
                                </div>
                            </div>
                            <div className={classes.fieldRows}>
                                <div className={classes.fieldColumnOne}>
                                <span className={classes.labelText} style={{whiteSpace:"normal"}}>Currency</span>{" "}
                                </div>
                                <div className={classes.fieldColumnTwo} style={{paddingRight:"15px"}}>
                                    <span className={classes.valueText} style={{textTransform:"uppercase"}}>
                                        {payoutDetails.currency}  
                                    </span>                
                                </div>
                            </div>
                            <div className={classes.fieldRows}>
                                <div className={classes.fieldColumnOne}>
                                <span className={classes.labelText} style={{whiteSpace:"normal"}}>Bank Name</span>{" "}
                                </div>
                                <div className={classes.fieldColumnTwo} style={{paddingRight:"15px"}}>
                                    <span className={classes.valueText}>
                                        {payoutDetails.bankName}
                                    </span>
                                </div>
                            </div>
                            <div className={classes.fieldRows}>
                                <div className={classes.fieldColumnOne}>
                                <span className={classes.labelText} style={{whiteSpace:"normal"}}>Account Number</span>{" "}
                                </div>
                                <div className={classes.fieldColumnTwo} style={{paddingRight:"15px"}}>
                                    <span className={classes.valueText}>
                                        {`********${payoutDetails.accountNumber}`}
                                    </span>
                                </div>
                            </div>
                            <div className={classes.fieldRows}>
                                <div className={classes.fieldColumnOne}>
                                <span className={classes.labelText} style={{whiteSpace:"normal"}}>Routing Number</span>{" "}
                                </div>
                                <div className={classes.fieldColumnTwo} style={{paddingRight:"15px"}}>
                                    <span className={classes.valueText}>
                                        {payoutDetails.routingNumber}
                                    </span>
                                </div>
                            </div>
                            </div>
                        </Grid>
                    </Grid> : 
                    <Grid container item xs={12} className={large ? classes.mainWrapper : classes.mobilePadding}>
                        <Grid container item className={classes.headerPadding} xs={12}>
                            <span className={small ? classes.topHeadingOne : classes.mobileTopHeadingTwo}>
                                Bank Details
                            </span>
                        </Grid>
                          <Container component="main" className={classes.formContainer}>
                            <PayoutBankForm
                              open = {verificationDetails.payoutDetails}
                              setOpen = {null}
                              currency={"usd"}
                              country={"US"}
                              cancelEnabled={false}
                              preAdd = {verificationDetails.preAdd}
                              success={()=>{setLoad(true);}}
                            />
                          </Container>
                    </Grid>}
                  </Grid>
            );
        } else {
            return(
                <Grid container className={classes.mainWrapper}>
                <Grid item xs={12}>
                    <div className={classes.loaderWrapper}>
                    <CircularProgress className={classes.loaderMain}/>
                    </div>
                </Grid>
                </Grid>
            );
        }
        };
    
        export default PayoutInformation;
  
  
  