import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Review from './Review';
import ReviewsList from './ReviewsList';
const ReviewsHandler = () => {
    const businessId = useSelector(state =>  state.buisnessDetails.businessId);
    const basePath = `/dashboard/${businessId}/Reviews`
    return(
        <Switch>
            <Route exact path = {basePath} render = {()=>(<ReviewsList basePath = {basePath}/>)}/>
            <Route exact path = {`${basePath}/:reviewIdURL`} render = {()=>(<Review/>)}/>
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
        </Switch>
    )
}

export default ReviewsHandler