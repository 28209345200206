import React, { useState, useEffect } from 'react';
import { useSelector} from 'react-redux';
import Form from '../../../../../Form/Form'
import CModal from '../../../../../Form/CModal'
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import { addFeePricing, editFeePricing } from '../../../../../../utils';
import maxTextField from '../../../../../../FormValidation/maxTextField';
import positive from '../../../../../../FormValidation/positive';


const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100%",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))

const FeeForm = (props) => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()
    const [type, setType] = useState("Percentage")
    useEffect(() => {
        if(props.open){
            setType((props.edit?props.open.feeType:null))
        }
    },[props.open])


    const refresh = (success) => {
        if (success) {
            props.setOpen(false)
            props.refresh()
        }
    }

    const add = async (payload) => {
        let response = null
        if(payload.feeType === "Percentage"){
            response = await addFeePricing({...payload, feeValuePer:null, feeValueFlat:null, feeValue: payload.feeValuePer})
        }
        else if(payload.feeType === "Fixed"){
            response = await addFeePricing({...payload, feeValuePer:null, feeValueFlat:null, feeValue: payload.feeValueFlat})
        }
        return response
    }

    const edit = async (payload) =>{
        let response = null
        if(payload.feeType === "Percentage"){
            response = await editFeePricing({...payload, feeValuePer:null, feeValueFlat:null, feeValue: payload.feeValuePer})
        }
        else if(payload.feeType === "Fixed"){
            response = await editFeePricing({...payload, feeValuePer:null, feeValueFlat:null, feeValue: payload.feeValueFlat})
        }
        return response
    }

    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    moreData={{ businessId: businessId, optionId: props.optionDetails.optionId, additionalFeeId: (props.edit?props.open.additionalFeeId:null) }}
                    title={`${(props.edit?"Edit":"Add")} Fee`}
                    cancelEnabled
                    cancelAction={() => { props.setOpen(false) }}
                    submitText={(props.edit?"Edit":"Add")}
                    action={(props.edit?edit:add)}
                    postAction={refresh}
                    inputFields={[
                        {
                            type: "TextField",
                            name: "name",
                            label: "Fee Name",
                            validator: [notEmpty, noWhiteSpace, maxTextField],
                            initialvalue: (props.edit?props.open.name:null)
                        },
                        {
                            type: "RadioSet",
                            name: "feeType",
                            label: "Fee Type",
                            choices:[
                                ["Flat", "Fixed"],
                                ["Percent", "Percentage"]
                            ],
                            initialvalue: (props.edit?props.open.feeType:null),
                            validator: [(value)=>{setType(value)},(value)=>(!value?"Choose a fee type":null)]
                        },
                        {
                            type: "Percent",
                            label: "Percent Fee Amount",
                            name: "feeValuePer",
                            enabled: (type === "Percentage"),
                            initialvalue: (props.edit && props.open.feeType === "Percentage"?props.open.feeValue:null),
                            validator: [positive, value=>value>100?"Maximum of 100%":null]
                        },
                        {
                            type: "Currency",
                            label: "Flat Fee Amount",
                            name: "feeValueFlat",
                            currencyInfo: props.currencyInfo,
                            enabled: (type === "Fixed"),
                            initialvalue: (props.edit && props.open.feeType === "Fixed"?props.open.feeValue:null),
                            validator: positive
                        }

                    ]}
                />
            </Container>
        </CModal>
    );
};

export default FeeForm