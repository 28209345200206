import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useEffect, useRef, useState } from "react";
import {
  deleteFormTemplate,
  getFormTemplateList,
  withLoading,
} from "../../../../../../utils";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import { CCloseIcon } from "../../../../../Icons/Icons";
import ListCard from "../../../../../MobileCards/ListCard";
import ListTable from "../../../../../TableComponents/ListTable";
import ManageFormTemplateForm from "./ManageFormTemplate";
import PreviewFormTemplate from "./PreviewFormTemplate";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));
const formTemplateHeaders = [
  {
    name: "form name",
    columnKey: "name",
    columnWidth: "100%",
    tooltip: true,
  },
  {
    name: "",
    columnKey: "preview",
    columnWidth: "50px",
    type: "Normal",
  },
  {
    name: "",
    columnKey: "edit",
    columnWidth: "50px",
    type: "Normal",
  },
  {
    name: "",
    columnKey: "delete",
    columnWidth: "50px",
    type: "Normal",
  },
];

const FormTemplateList = (props) => {
  const mountedRef = useRef(false);
  const classes = useStyles();
  const matches = useMediaQuery("(min-width: 750px)");
  const [del, setDel] = useState(false);
  const [formTemplateList, setFormTemplateList] = useState(null);
  const [manage, setManage] = useState(false);
  const [preview, setPreview] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const fetchFormTemplateList = async () => {
    const formTemplatePayload = await withLoading(getFormTemplateList, {
      businessId: props.businessId,
    });

    if (
      mountedRef.current &&
      formTemplatePayload &&
      formTemplatePayload.formTemplateList
    ) {
      setFormTemplateList(formTemplatePayload.formTemplateList);
    }
  };

  const ViewChanger = () => {
    if (matches) {
      return (
        <ListTable
          identifier={"admin_form_templates"}
          tableHeaders={formTemplateHeaders}
          tableRows={formTemplateList.map((formTemplate) => ({
            id: formTemplate.formTemplateId,
            name: formTemplate.templateName,
            preview: (
              <span title="Preview">
                <IconButton
                  onClick={() => {
                    setSelectedTemplate(formTemplate);
                    setPreview(true);
                  }}
                >
                  <VisibilityIcon color="primary" />
                </IconButton>
              </span>
            ),
            edit: (
              <span title="Edit">
                <IconButton
                  onClick={() => {
                    setSelectedTemplate(formTemplate);
                    setManage(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </span>
            ),
            delete: (
              <span title="Delete">
                <IconButton
                  onClick={() => {
                    setSelectedTemplate(formTemplate);
                    setDel(true);
                  }}
                >
                  <CCloseIcon />
                </IconButton>
              </span>
            ),
          }))}
          rowClick={null}
        />
      );
    } else {
      return formTemplateList.map((formTemplate) => {
        return (
          <ListCard
            action={null}
            cardName={formTemplate.templateName}
            statusReplace={
              <span
                style={{ color: "#1a1f36", cursor: "pointer" }}
                onClick={() => {
                  setSelectedTemplate(formTemplate);
                  setPreview(true);
                }}
              >
                Preview
              </span>
            }
            cardButton={
              <Typography
                color="primary"
                style={{
                  cursor: "pointer",
                  fontSize: "14px",
                  fontweight: "light",
                  float: "right",
                }}
                onClick={() => {
                  setSelectedTemplate(formTemplate);
                  setManage(true);
                }}
              >
                Edit
              </Typography>
            }
            other={
              <span
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  setSelectedTemplate(formTemplate);
                  setDel(true);
                }}
              >
                Delete
              </span>
            }
          />
        );
      });
    }
  };

  useEffect(() => {
    mountedRef.current = true;
    fetchFormTemplateList();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (!Array.isArray(formTemplateList)) {
    return null;
  } else {
    return (
      <React.Fragment>
        <PreviewFormTemplate
          open={preview}
          setOpen={setPreview}
          formTemplate={selectedTemplate}
        />
        <ManageFormTemplateForm
          open={manage}
          setOpen={setManage}
          editTemplate={selectedTemplate}
          fetchFormTemplateList={fetchFormTemplateList}
          businessId={props.businessId}
        />
        <GeneralDialog
          open={del}
          close={() => {
            setDel(false);
          }}
          title={"Delete Form Template"}
          text={`Are you sure you want to delete the "${selectedTemplate?.templateName}" form template?`}
          action={async () => {
            const response = await withLoading(deleteFormTemplate, {
              businessId: props.businessId,
              formTemplateId: selectedTemplate.formTemplateId,
            });
            if (response && !response.error && mountedRef.current) {
              setDel(false);
              setSelectedTemplate(null);
            }
            fetchFormTemplateList();
          }}
        />
        <Grid
          container
          className={matches ? classes.mainWrapper : classes.mobilePadding}
        >
          <Grid item xs={12} className={matches ? null : classes.stickyHeader}>
            <div className={classes.headerPadding}>
              <span
                className={
                  matches ? classes.topHeading : classes.mobileTopHeadingOne
                }
              >
                Feedback Forms
              </span>
              <span title="Add Form Template" style={{ float: "right" }}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: "10px", textTransform: "none" }}
                  onClick={() => {
                    setSelectedTemplate(null);
                    setManage(true);
                  }}
                >
                  Add
                </Button>
              </span>
            </div>
          </Grid>
          {formTemplateList.length > 0 ? (
            <Grid item xs={12}>
              <div className={classes.contentRow}>
                <ViewChanger />
              </div>
            </Grid>
          ) : (
            <EmptyListImage name="Forms" />
          )}
          <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
};

export default FormTemplateList;
