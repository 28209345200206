import React, { useState, useEffect } from 'react';
import CModal from '../Form/CModal';
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import MPLogin from '../MarketPlace/MPAccountForms/MPLogin';
import MPSignUp from '../MarketPlace/MPAccountForms/MPSignUp';
import MPForgot from '../MarketPlace/MPAccountForms/MPForgot';
import { sendToCheckout } from '../../utils';
import { useHistory, useLocation } from 'react-router';
const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
    width: "100%",
    maxHeight: "80vh",
    overflowY: "auto",
  }
}))

const COForm = (props) => {
  const classes = useStyles()
  const [form, setForm] = useState("login")

  const postLoginAction = (response) => {
    if (response) {
      props.setOpen(false)
    }
  }

  useEffect(() => {
    if (props.open) {
      setForm('login')
    }
  }, [props.open])

  const calcForm = (currFormChoice) => {
    if (currFormChoice === "login") {
      return (<MPLogin setForm={setForm} postLoginAction={postLoginAction} />)
    }
    else if (currFormChoice === "signUp") {
      return (<MPSignUp setForm={setForm} postLoginAction={postLoginAction} />)
    }
    else {
      return (<MPForgot setForm={setForm} />)
    }
  }


  return (
    <CModal
      open={props.open}
      setOpen={props.setOpen}
    >
      <Container className={classes.formContainer}>
        {calcForm(form)}
      </Container>
    </CModal>
  );
}

export default COForm;