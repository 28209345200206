import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useRef, useEffect } from 'react'

import {  Checkbox } from "@material-ui/core"
import { makeStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { customerAddCard, safeQuery, withLoading } from '../../utils';
import { useDispatch } from 'react-redux';
const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: "20px",
        backgroundColor: "white",
        borderRadius: "4px",
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto",
    },
    formLabel: {
        fontSize: 14,
        color: theme.palette.textColor2.main
    },
    paddingFor2: {
        padding: 8
    },
    newCardHolder: {
        border: "1px solid #dadada",
        boxSizing: "border-box",
        borderRadius: 4,

    }
}));


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)

const NewPayment = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <Form setNewCustomerCard={props.setNewCustomerCard} setErrors={props.setErrors} installment = {props.installment}/>
        </Elements>
    )
}

const Form = (props) => {
    const classes = useStyles()
    const stripe = useStripe();
    const elements = useElements();
    const saveCard = useRef(null)
    const {setErrors,installment} = props


    useEffect(() => {
        if (elements && stripe && saveCard.current) {
            props.setNewCustomerCard({
                cardElement: elements.getElement(CardElement),
                stripe: stripe,
                save: saveCard
            })
        }
    }, [elements, stripe, saveCard])
    return (
        <Grid container spacing={2} className={classes.paddingFor2} style={{ paddingTop: 0 }}>
            <Grid item xs={12} >
                <Typography className={classes.formLabel}>
                    New Card Number
                </Typography>
            </Grid>

            <Grid item xs={12} style={{ padding: 16, paddingTop: 0 }}>
                <Grid className={classes.newCardHolder} container spacing={2}>
                    <Grid item xs={12}>
                        <CardElement
                            onChange={(event) => {
                                if (event && !event.error && event.complete) {
                                    setErrors(errors => ({ ...errors, paymentError: [] }))
                                }
                            }}
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#1A1F36',
                                        '::placeholder': {
                                            color: '#aab7c4',
                                        },
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} style = {{display:installment?"none":"block"}}>
                        <Checkbox
                            style={{
                                paddingLeft: 0
                            }}
                            inputRef={saveCard}
                            color="primary"
                        />
                        <Typography display="inline" className={classes.formLabel}>
                            Save this card for future use
                        </Typography>
                    </Grid>
                </Grid>

            </Grid>



        </Grid>
    )
}


export default NewPayment
