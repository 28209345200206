import {
  Avatar, Button,
  Grid, makeStyles, Typography, useMediaQuery
} from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { safePush } from "../../../utils";
import RBAccount from "./RBAccount";
import { benefits, features, testimonials } from "./RBPrettyPageUtils";
import Check from "@material-ui/icons/Check";
import { formatSrc } from "../../Image/Image";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  videoBackground: {
    background: 'url("https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/onemin-video-background-final.png")',
    backgroundColor: theme.palette.primary.main,
    backgroundSize: "max(2160px, 100vw) auto",
    backgroundPosition: "center",
    height: "90vh",
    minHeight: "600px"
  },
  hero: {
    backgroundImage: `url(${formatSrc('https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/business-page-hero-final.webp', {
      mobile: false,
      mobileOptions: undefined,
      desktopOptions: ['fit-in/1920x1080']
    })})`,
    backgroundSize: "100% max(90vh,600px)",
    height: "90vh",
    minHeight: "600px"
  },
  heroContent: {
    width: "100%",
    height: "100%"
  },
  contactContent: {
    width: "100%",
    height: "100%"
  },
  contact: {
    background: 'transparent',
    height: "50vh",
    minHeight: "400px"
  },
  whiteText: {
    color: theme.palette.white.main
  },
  buttonContainer: {
    paddingTop: "20px"
  },
  buttonText: {
    textTransform: "none",
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  button: {
    padding: "10px 20px 10px 20px",
    borderRadius: '25px',
    backgroundColor: 'white'
  },
  horizontalPadding: {
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  verticalPadding: {
    paddingTop: "100px",
    paddingBottom: "100px"
  },
  FeatureImage: {
    objectFit: "cover",
    maxWidth: "600px",
    width: "100%",
  },
  FeatureImageContainer: {
    display: "flex",
    padding: 10,
    justifyContent: "center",
  },
  FeatureDesktop: {
    maxWidth: "550px",
    width: "50%"
  },
  FeatureMobile: {
    width: "100%"
  },
  success: {
    color: theme.palette.success.main
  },
  bold: {
    fontWeight: 'bold'
  },
  textColor3: {
    color: theme.palette.textColor3.main
  },
  benefit: {
    '&:hover': {
      boxShadow: `0 5px 10px 5px rgba(0,0,0,0.1)`,
    },
    padding: "50px 10px 50px 10px",
    transitionDuration: "500ms"
  },
  contactImage: {
    zIndex: -1,
    position: "fixed",
    width: "100%",
    height: "100vh",
    backgroundImage: `url('https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/contact-us-final.png')`,
    backgroundPosition: "center",
    backgroundSize: "max(1920px,100vw) auto",
  },
  noBackground: {
    background: 'transparent'
  },
  hidden: {
    display: "none"
  },
  block: {
    display: "block"
  },
  whiteBackground: {
    backgroundColor: "white"
  },
  greyBackground: {
    backgroundColor: theme.palette.accent2.main
  },
  testimonial: {
    backgroundColor: theme.palette.accent2.main,
    borderRadius: "10px",
    marginBottom: "10px",
    height: "300px"
  }
}));



const RBPrettyPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const bigEnough = useMediaQuery("(min-width: 750px)");
  const loggedIn = useSelector((state) => state.token !== null);
  const [loginOpen, setLoginOpen] = useState(false);
  const classes = useStyles();
  const [scrolled, setScrolled] = useState(false)
  const handeGetStartedClick = () => {
    if (!loggedIn) {
      setLoginOpen(true);
    } else {
      safePush(`/for-business/create`, history, location);
    }
  };


  const changeBackground = () => {
    if (window.scrollY > 5) {
      setScrolled(true)
    }
    else if (window.scrollY <= 5) {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground)
    return () => { window.removeEventListener("scroll", changeBackground) }
  }, [])

  return (
    <React.Fragment>
      <RBAccount open={loginOpen} setOpen={setLoginOpen} />
      <div className={`${classes.contactImage} ${scrolled ? classes.block : classes.hidden}`} />
      <Grid container>
        <Grid item xs={12} className={`${classes.hero} ${classes.horizontalPadding} ${classes.whiteBackground}`}>
          <Grid container justifyContent="center" alignContent="center" className={classes.heroContent}>
            <Grid item xs={12}>
              <Typography variant="h3" align="center" className={`${classes.whiteText} ${classes.bold}`} gutterBottom>Solutions, custom-fit for the coaching business</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" className={classes.whiteText} gutterBottom>Grow your customer base and run your business efficiently with Caritra</Typography>
            </Grid>
            <Grid item className={classes.buttonContainer}>
              <Link to='/for-business/create' className={classes.buttonText}>
                <Button variant="contained" className={classes.button}>
                  <Typography variant="h5" className={classes.buttonText}>Get Started</Typography>
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={`${classes.verticalPadding} ${classes.horizontalPadding} ${classes.whiteBackground}`}>
          <Grid container justifyContent="center" className={classes.heroContent}>
            <Grid item xs={12} style={{ paddingBottom: "30px" }}>
              <Typography color="primary" variant="h4" align="center" className={classes.bold}>
                Everything you need to grow your business
              </Typography>
            </Grid>
            {benefits.map((benefit, index) => {
              return (<Benefit key={`benefit-${index}`} icon={benefit.icon} title={benefit.title} text={benefit.text} />)
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} className={`${classes.videoBackground} ${classes.verticalPadding} ${classes.horizontalPadding}`}>
          <Typography variant="h4" className={classes.whiteText} align="center" gutterBottom>
            One Minute Introduction
          </Typography>
          <Grid container justifyContent="center" alignItems="center" style={{ height: "calc(100% - 42px)" }}>

            <Grid item style={{ width: "min(800px, 100%)", paddingBottom: '40px' }}>
              <iframe style={{ width: "100%", height: "min(400px, 50vw)" }} src="https://www.youtube-nocookie.com/embed/kYnfhv1Cr9E?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen></iframe>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item style={{ paddingTop: '100px' }} xs={12} className={`${classes.horizontalPadding} ${classes.whiteBackground}`}>
            <Typography variant="h4" align="center" color="primary" className={classes.bold} >
              Features
            </Typography>
          </Grid>
          {
            features.map((feature, index) => {
              return (
                <Feature {...feature} bigEnough={bigEnough} key={`feature-${index}`} />
              )
            })
          }
        </Grid>
      </Grid>
      <Grid item xs={12} className={`${classes.horizontalPadding} ${classes.whiteBackground} ${classes.verticalPadding}`} >
        <Grid container>
          <Grid item sm={6} xs={12} style={{ padding: '20px' }}>
            <img
              src="https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/feedback-final.png"
              style={{ width: "min(100%, 750px)", float: "right", margin: bigEnough ? "0px" : "auto" }} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography
              variant="h4"
              align="center"
              color="primary"
              className={classes.bold}
              style={{ paddingTop: 50, paddingBottom: 50 }}
            >
              People are talking
            </Typography>
            <div
              style={{ maxWidth: 800, margin: "0 auto", paddingBottom: 20 }}
            >
              <Carousel
                animation="slide"
                interval={9000}
                swipe={true}
                navButtonsAlwaysInvisible
                indicators={bigEnough}
                style={{ padding: 8 }}
              >
                {testimonials.map((item, i) => (
                  <Testimonial
                    key={`Testimonial ${i}`}
                    {...item}
                    classes={classes}
                  />
                ))}
              </Carousel>
            </div>
          </Grid>
        </Grid>


      </Grid>
      <Grid item xs={12} className={classes.contact}>
        <Grid container justifyContent="center" alignContent="center" className={classes.contactContent}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center" className={classes.whiteText} gutterBottom>Contact us to learn more</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" className={classes.whiteText} gutterBottom>Explore how Caritra’s custom-fit solutions can help your business</Typography>
          </Grid>
          <Grid item className={classes.buttonContainer}>
            <Link className={classes.buttonText} to='/for-business/contact-sales'>
              <Button variant="contained" className={classes.button}>
                <Typography variant="h5" className={classes.buttonText}>Request Demo</Typography>
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment >
  );
};

export const Benefit = (props) => {
  const { icon, title, text, breakpoints } = props
  const classes = useStyles()
  return (
    <Grid xs={breakpoints?.xs ?? 12} md={breakpoints?.md ?? 6} lg={breakpoints?.lg ?? 3} item className={classes.benefit}>
      <Grid container justifyContent="center">
        <Grid item style={{ paddingBottom: "20px" }}>
          {icon}
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: "20px" }}>
          <Typography align="center" variant="h5" className={classes.bold}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" className={`${classes.textColor3}`}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Testimonial = (props) => {
  const { name, description, img, classes } = props;
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
      style={{ paddingBottom: 50, padding: 10 }}
    >
      <Grid item xs={12} className={classes.testimonial}>
        <Grid container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
          <Typography variant="h6" align="center">
            {description}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Avatar src={img} />
      </Grid>
      <Grid item>
        <Typography>{name}</Typography>
      </Grid>
    </Grid>
  );
};

const getBackgroundClasses = (background, classes) => {
  switch (background) {
    case ('grey'):
      return classes.greyBackground
    case ('none'):
      return classes.noBackground
    default:
      return classes.whiteBackground
  }
}

export const Feature = (props) => {
  const { img, title, content, list, imgFirst, bigEnough, background } = props
  const classes = useStyles()
  return (
    <Grid item xs={12} className={`${classes.horizontalPadding} ${getBackgroundClasses(background, classes)}`}>
      <Grid container justifyContent="center" alignItems="center">
        {imgFirst || !bigEnough ?
          <React.Fragment>
            <FeatureImage img={img} bigEnough={bigEnough} />
            <FeatureText title={title} content={content} list={list} bigEnough={bigEnough} />
          </React.Fragment>
          :
          <React.Fragment>
            <FeatureText title={title} content={content} list={list} bigEnough={bigEnough} />
            <FeatureImage img={img} bigEnough={bigEnough} />
          </React.Fragment>
        }
      </Grid>
    </Grid>
  );
};


const FeatureImage = (props) => {
  const { img, bigEnough } = props
  const classes = useStyles()

  return (
    <Grid item className={bigEnough ? classes.FeatureDesktop : classes.FeatureMobile}>
      <div className={classes.FeatureImageContainer}>
        <img
          className={classes.FeatureImage}
          src={img}
          alt=""
        />
      </div>
    </Grid>
  )
}

const FeatureText = (props) => {
  const { title, content, list, bigEnough } = props
  const classes = useStyles()
  return (
    <Grid item className={`${bigEnough ? classes.FeatureDesktop : classes.FeatureMobile} ${classes.verticalPadding}`}>

      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      {content ?
        <Typography
          className={`${classes.textColor3}`}
          style={{ paddingBottom: "20px" }}
        >
          {content}
        </Typography>
        : null}
      {list.map((text, index) => {
        return (
          <Grid container key={`${title}-${index}`}>
            <Check
              className={classes.success}
              style={{ marginRight: 20 }}

            />
            <Typography
              style={{ width: "calc(100% - 44px)" }}
              className={`${classes.textColor3}`}
              gutterBottom
            >
              {text}
            </Typography>
          </Grid>
        );
      })}
    </Grid>

  )
}

export default RBPrettyPage;
