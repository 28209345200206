import {
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import React, { useEffect, useRef, useState } from "react";
import maxTextField from "../../../../../../FormValidation/maxTextField";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import {
  createFormTemplate,
  editFormTemplate,
  withLoading,
} from "../../../../../../utils";
import CButtonPair from "../../../../../Form/CButtonPair";
import CCheckBox from "../../../../../Form/CCheckBox";
import CModal from "../../../../../Form/CModal";
import CModalError from "../../../../../Form/CModalError";
import CMultiField from "../../../../../Form/CMultiField";
import CSingleSelect from "../../../../../Form/CSingleSelect";
import CTextField from "../../../../../Form/CTextField";
import CTitle from "../../../../../Form/CTitle";
import { CCloseIcon } from "../../../../../Icons/Icons";
import { questionTypes } from "../Questions/ManageQuestion";
import {
  extractFormTemplateParams,
  PreviewTemplateContents,
  useStyles,
} from "./PreviewFormTemplate";

const ManageFormTemplateForm = (props) => {
  const { open, setOpen, editTemplate, fetchFormTemplateList, businessId } =
    props;
  const classes = useStyles();

  const [preview, setPreview] = useState(false);
  const [name, setName] = useState("");
  const [heading, setHeading] = useState("");
  const [subheading, setSubheading] = useState("");
  const [fields, setFields] = useState([]);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (open) {
      const fields = editTemplate
        ? extractFormTemplateParams(editTemplate, true).fields
        : [];
      setPreview(false);
      setName(editTemplate?.templateName ?? "");
      setHeading(editTemplate?.templateHeading ?? "");
      setSubheading(editTemplate?.templateSubHeading ?? "");
      setFields(fields);
      setCounter(fields.length);
    }
  }, [open, editTemplate]);

  return (
    <CModal open={open} setOpen={setOpen}>
      <Container component="main" className={classes.formContainer}>
        {preview ? (
          <PreviewTemplateModal
            key="Preview"
            setOpen={setOpen}
            setPreview={setPreview}
            name={name}
            heading={heading}
            subheading={subheading}
            fields={fields}
            formTemplateId={editTemplate?.formTemplateId}
            fetchFormTemplateList={fetchFormTemplateList}
            businessId={businessId}
          />
        ) : (
          <SpecifyFormTemplate
            key="Create"
            setOpen={setOpen}
            name={name}
            setName={setName}
            heading={heading}
            setHeading={setHeading}
            subheading={subheading}
            setSubheading={setSubheading}
            fields={fields}
            setFields={setFields}
            setPreview={setPreview}
            counter={counter}
            setCounter={setCounter}
          />
        )}
      </Container>
    </CModal>
  );
};

const PreviewTemplateModal = (props) => {
  const {
    setOpen,
    setPreview,
    name,
    heading,
    subheading,
    fields,
    formTemplateId,
    fetchFormTemplateList,
    businessId,
  } = props;
  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Grid container spacing={2}>
      <PreviewTemplateContents
        name={name}
        heading={heading}
        subheading={subheading}
        fields={fields}
      />
      <Grid item xs={12}>
        <CButtonPair
          submitText={formTemplateId ? "Edit" : "Create"}
          action={async () => {
            const response = await executeManage(
              businessId,
              name,
              heading,
              subheading,
              fields,
              formTemplateId
            );
            if (response && !response.error && mountedRef.current) {
              setOpen(false);
            }
            fetchFormTemplateList();
          }}
          cancelEnabled
          cancelText="Close"
          cancelAction={() => {
            setOpen(false);
          }}
          clearEnabled
          clearText="Back"
          clearAction={() => {
            setPreview(false);
          }}
          errors={[]}
        />
      </Grid>
    </Grid>
  );
};

const SpecifyFormTemplate = (props) => {
  const {
    setOpen,
    name,
    setName,
    heading,
    setHeading,
    subheading,
    setSubheading,
    fields,
    setFields,
    setPreview,
    counter,
    setCounter,
  } = props;

  const [nameError, setNameError] = useState(null);
  const [headingError, setHeadingError] = useState(null);
  const [subheadingError, setSubheadingError] = useState(null);

  return (
    <Grid container spacing={2} justifyContent="center">
      <CTitle title="Create Form Template" />
      <CModalError />
      <Grid item xs={12}>
        <CTextField
          onchange={(event) => {
            setName(event.target.value);
            setNameError(checkName(event.target.value));
          }}
          initialvalue={name}
          label={"Form Name"}
          errortext={nameError}
          name={"name"}
        />
      </Grid>
      {/* TODO: These fields will not be shown for demo purposes*/}
      {/* <Grid item xs={12}>
        <CTextField
          onchange={(event) => {
            setHeading(event.target.value);
            setHeadingError(checkHeading(event.target.value));
          }}
          initialvalue={heading}
          label={"Form Header"}
          errortext={headingError}
          name={"header"}
        />
      </Grid>
      <Grid item xs={12}>
        <CTextField
          onchange={(event) => {
            setSubheading(event.target.value);
            setSubheadingError(checkSubheading(event.target.value));
          }}
          initialvalue={subheading}
          label={"Form Subheading"}
          errortext={subheadingError}
          name={"subheading"}
        />
      </Grid> */}
      {getOrderedFieldComponents(fields, setFields)}
      <Grid item>
        <Button
          color="primary"
          onClick={() => {
            createField(fields, setFields, counter, setCounter);
          }}
          variant="contained"
        >
          <Typography style={{ textTransform: "none" }}>
            Add New Field
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <CButtonPair
          submitText="Preview"
          cancelEnabled
          cancelText="Close"
          action={() => {
            const errorOccured = validateAll(
              name,
              setNameError,
              heading,
              setHeadingError,
              subheading,
              setSubheadingError,
              fields,
              setFields
            );
            if (!errorOccured) {
              setPreview(true);
            }
          }}
          cancelAction={() => {
            setOpen(false);
          }}
          errors={[]}
          disableSubmit={preventSubmit(
            nameError,
            headingError,
            subheadingError,
            fields
          )}
        />
      </Grid>
    </Grid>
  );
};

const getOrderedFieldComponents = (fields, setFields) => {
  const fieldComponents = [];
  for (let i = 0; i < fields.length; i++) {
    fieldComponents.push(
      <Grid
        item
        xs={12}
        key={`field-${fields[i].id}`}
        style={{ padding: "16px" }}
      >
        <Grid
          container
          spacing={2}
          style={{ border: "1px solid gray", borderRadius: "8px" }}
          justifyContent="center"
        >
          <Grid item xs={12}>
            <CSingleSelect
              onchange={(event) => {
                updateType(fields, setFields, event.target.value, i);
              }}
              name={`field-${fields[i].id}-type`}
              label={`Field #${i + 1} Type`}
              choices={questionTypes}
              enabled={true}
              initialvalue={fields[i].type}
            />
          </Grid>
          <Grid item xs={12}>
            <CTextField
              onchange={(event) => {
                updateLabel(
                  fields,
                  setFields,
                  event.target.value,
                  i,
                  checkLabel
                );
              }}
              initialvalue={fields[i].label}
              label={`Field #${i + 1} Label`}
              errortext={fields[i].labelError}
              name={`field-${fields[i].id}-label`}
            />
          </Grid>
          {["singleSelect", "multiSelect"].includes(fields[i].type) ? (
            <Grid item xs={12}>
              <CMultiField
                errortext={fields[i].optionsError}
                initialvalue={fields[i].options ?? ["", ""]}
                onchange={(value) => {
                  updateOptions(fields, setFields, value, i, checkOptions);
                }}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <CCheckBox
              onchange={(event) => {
                updateRequired(fields, setFields, event.target.checked, i);
              }}
              initialvalue={fields[i].required}
              label={`Field #${i + 1} is required`}
              errortext={null}
              name={`field-${fields[i].id}-required`}
            />
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                deleteField(fields, setFields, i);
              }}
            >
              <CCloseIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                moveFieldDown(fields, setFields, i);
              }}
            >
              <ArrowDownwardIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                moveFieldUp(fields, setFields, i);
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return fieldComponents;
};

const updateType = (fields, setFields, newType, indexIntoFields) => {
  fields[indexIntoFields] = {
    ...fields[indexIntoFields],
    type: newType,
    options: ["singleSelect", "multiSelect"].includes(newType)
      ? fields[indexIntoFields].options ?? ["", ""]
      : undefined,
    optionsError: ["singleSelect", "multiSelect"].includes(newType)
      ? fields[indexIntoFields].options?.map((_) => null) ?? [null, null]
      : undefined,
  };
  setFields([...fields]);
};

const updateLabel = (
  fields,
  setFields,
  newLabel,
  indexIntoFields,
  validator
) => {
  fields[indexIntoFields] = { ...fields[indexIntoFields] };
  fields[indexIntoFields].label = newLabel;
  fields[indexIntoFields].labelError = validator(newLabel, fields);

  setFields([...fields]);
};

const updateOptions = (
  fields,
  setFields,
  newOptions,
  indexIntoFields,
  validator
) => {
  if (["singleSelect", "multiSelect"].includes(fields[indexIntoFields].type)) {
    fields[indexIntoFields].options = [...newOptions];
    fields[indexIntoFields].optionsError = validator(newOptions);
    setFields([...fields]);
  }
};

const updateRequired = (fields, setFields, newRequired, indexIntoFields) => {
  fields[indexIntoFields].required = newRequired;
  setFields([...fields]);
};

const createField = (fields, setFields, counter, setCounter) => {
  setFields([
    ...fields,
    {
      id: `${counter + 1}`,
      type: "text",
      label: `Sample Label ${counter + 1}`,
      labelError: null,
      required: true,
    },
  ]);
  setCounter(counter + 1);
};

const moveFieldUp = (fields, setFields, indexIntoFields) => {
  if (indexIntoFields > 0) {
    const above = fields[indexIntoFields - 1];
    fields[indexIntoFields - 1] = fields[indexIntoFields];
    fields[indexIntoFields] = above;
    setFields([...fields]);
  }
};

const moveFieldDown = (fields, setFields, indexIntoFields) => {
  if (indexIntoFields < fields.length - 1) {
    const above = fields[indexIntoFields + 1];
    fields[indexIntoFields + 1] = fields[indexIntoFields];
    fields[indexIntoFields] = above;
    setFields([...fields]);
  }
};

const deleteField = (fields, setFields, indexIntoFields) => {
  fields.splice(indexIntoFields, 1);
  setFields([...fields]);
};

const checkName = (name) => {
  return notEmpty(name) || maxTextField(name);
};

const checkHeading = (heading) => {
  return maxTextField(heading);
};

const checkSubheading = (subheading) => {
  return maxTextField(subheading);
};

const checkLabel = (label, fields) => {
  let labelSeenCount = 0;
  for (let i = 0; i < fields.length; i++) {
    if (label === fields[i].label) {
      labelSeenCount += 1;
    }
  }
  if (labelSeenCount === 0) {
    throw Error("There was an issue validating the label.");
  }

  if (labelSeenCount > 1) {
    return "Labels must be unique.";
  }

  return notEmpty(label) || maxTextField(label);
};

const checkOptions = (options) => {
  const errors = [];
  const optionSet = new Set();
  for (let i = 0; i < options.length; i++) {
    const error = notEmpty(options[i]) || maxTextField(options[i]);
    if (error) {
      errors.push(error);
    } else if (optionSet.has(options[i])) {
      errors.push("Choices must be unique for a question.");
    } else {
      errors.push(null);
    }
    optionSet.add(options[i]);
  }
  return errors;
};

const validateAll = (
  name,
  setNameError,
  heading,
  setHeadingError,
  subheading,
  setSubheadingError,
  fields,
  setFields
) => {
  const nameError = checkName(name);
  setNameError(nameError);
  const headingError = checkHeading(heading);
  setHeadingError(headingError);
  const subheadingError = checkSubheading(subheading);
  setSubheadingError(subheadingError);

  for (let i = 0; i < fields.length; i++) {
    fields[i].labelError = checkLabel(fields[i].label, fields);
    if (["singleSelect", "multiSelect"].includes(fields[i].type)) {
      fields[i].optionsError = checkOptions(fields[i].options);
    }
  }
  setFields([...fields]);

  return preventSubmit(nameError, headingError, subheadingError, fields);
};

const preventSubmit = (nameError, headingError, subheadingError, fields) => {
  return Boolean(
    nameError ||
      headingError ||
      subheadingError ||
      fields.some((field) => {
        return Boolean(
          field.labelError ||
            (Array.isArray(field?.optionsError) &&
              field.optionsError.some((optionError) => optionError !== null))
        );
      })
  );
};

const createFormTemplateDTO = (name, heading, subheading, fields) => {
  return {
    templateName: name,
    templateHeading: heading,
    templateSubHeading: subheading,
    templateFields: fields.map((field) => ({
      fieldName: field.label,
      fieldType: field.type,
      isRequired: field.required,
      options: field?.options ?? [],
    })),
  };
};

const executeManage = async (
  businessId,
  name,
  heading,
  subheading,
  fields,
  formTemplateId = undefined
) => {
  if (formTemplateId) {
    return await withLoading(editFormTemplate, {
      formTemplateId,
      businessId,
      ...createFormTemplateDTO(name, heading, subheading, fields),
    });
  } else {
    return await withLoading(createFormTemplate, {
      businessId,
      ...createFormTemplateDTO(name, heading, subheading, fields),
    });
  }
};

export default ManageFormTemplateForm;
