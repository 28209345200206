import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import PreferredBusiness from './PreferredBusiness';
import PreferredBusinessList from './PreferredBusinessesList';

function PreferredBusinessesHandler() {
    const basePath = "/account/My-Businesses"
    return(
        <React.Fragment>
            <Switch>
            <Route path = {basePath} exact render = {() => {return(<PreferredBusinessList />)}} />
            <Route path = {`${basePath}/:businessIdURL`} render = {() => {return(<PreferredBusiness />);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default PreferredBusinessesHandler;