import { Button, CircularProgress, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Mail from "@material-ui/icons/Mail";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import noWhiteSpace from "../../../../../../FormValidation/noWhiteSpace";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import validEmail from "../../../../../../FormValidation/validEmail";
import {
  addCustomer,
  customerSearch,
  downloadCustomerList,
  listCustomers,
  safePush,
  withLoading,
} from "../../../../../../utils";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";
import LightTooltip from "../../../../../Form/LightTooltip";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import ListCard from "../../../../../MobileCards/ListCard";
import Search from "../../../../../Search/Search";
import StatusTag from "../../../../../TagComponents/StatusTag";
import ListTable from "./../../../../../TableComponents/ListTable";
import ListTableNavigation from "./../../../../../TableComponents/ListTableNavigation";
import EmailAllCustomersForm from "./EmailAllCustomersForm";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  headerPadding: {
    paddingBottom: "10px",
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  buttonDownload: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "3px",
    paddingRight: "3px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  stickyHeader: {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#ffffff",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "20px",
    maxWidth: "600px",
  },
}));

const CustomersList = () => {
  const [_, updateState] = useState(0);
  const [add, setAdd] = useState(false);
  const [download, setDownload] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const small = useMediaQuery("(min-width: 750px)");
  const large = useMediaQuery("(min-width: 960px)");
  const error = useSelector((state) => state.error);
  const errorMessage = useSelector((state) => state.errorMessage);
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const [downloadedData, setDownloadedData] = useState([]);
  const [downloadedHeaders, setDownloadedHeaders] = useState([]);

  const refresh = () => {
    updateState((_) => !_);
  };

  const customerHeaders = [
    {
      name: "name",
      columnKey: "customerName",
      columnWidth: "30%",
      tooltip: true,
    },
    {
      name: "phone number",
      columnKey: "phoneTrans",
      columnWidth: "30%",
      tooltip: true,
    },
    {
      name: "email",
      columnKey: "email",
      columnWidth: "30%",
      tooltip: true,
    },
    {
      name: " ",
      columnKey: "blocked",
      columnWidth: "100px",
      type: "Normal",
    },
  ];

  const [customerList, setCustomerList] = useState([]);
  const [initialState, setInitialState] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const csvLinkEl = useRef();

  const processCustomerData = (customerData) => {
    return customerData.map((customer) => {
      let { customerId, firstName, lastName, phoneNumber, email, isBlocked } =
        customer;

      let phoneTrans = `${phoneNumber.slice(0, 2)} (${phoneNumber.slice(
        2,
        5
      )}) ${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;
      let id = customerId;
      let customerName = `${firstName} ${lastName}`;
      let blocked = null;
      if (!isBlocked) {
        blocked = null;
      } else {
        blocked = (
          <span title="This customer is blocked">
            <StatusTag wrapperName="redWrapper" value="Blocked" />
          </span>
        );
      }

      return {
        id,
        customerName,
        phoneTrans,
        email,
        blocked,
        isBlocked,
      };
    });
  };

  const getCustomersDetails = async () => {
    let customersPayload = await listCustomers({
      businessId: businessId,
      limit: currentLimit,
      page: currentPage,
    });
    if (customersPayload && customersPayload.customers) {
      setCustomerList(processCustomerData(customersPayload.customers.docs));
      setTotalPages(customersPayload.customers.totalPages);
      setTotalDocs(customersPayload.customers.totalDocs);
      setHasNext(customersPayload.customers.hasNextPage);
      setHasPrevious(customersPayload.customers.hasPrevPage);
      setInitialState(true);
    } else {
      setInitialState(true);
    }
  };

  const getNextPage = () => {
    if (hasNext) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPrevPage = () => {
    if (hasPrevious) {
      setCurrentPage(currentPage - 1);
    }
  };

  const navigateToCustomer = (customerId) => {
    history.push(`/dashboard/${businessId}/Customers/${customerId}`);
  };

  async function handleSubmit(event) {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let success = await addCustomer({
      businessId: businessId,
      email: event.email,
      accountNumber: event.account,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (success) {
      history.push(`${location.pathname}/${success.customerId}`);
    }
  }

  const ViewChanger = () => {
    if (small) {
      return (
        <ListTable
          identifier={"admin_orders"}
          tableHeaders={customerHeaders}
          tableRows={customerList}
          rowClick={navigateToCustomer}
        />
      );
    } else {
      return customerList.map((mobileCustomer) => {
        return (
          <ListCard
            action={() => {
              history.push(
                `/dashboard/${businessId}/Customers/${mobileCustomer.id}`
              );
            }}
            cardName={mobileCustomer.customerName}
            statusTagValue={!mobileCustomer.isBlocked}
            statusReplace={mobileCustomer.isBlocked ? null : " "}
            inactiveValue="Blocked"
            other={null}
          />
        );
      });
    }
  };

  const handleDownload = async (event) => {
    let success = await withLoading(downloadCustomerList, {
      businessId: businessId,
    });
    if (success && !success.error) {
      setDownloadedHeaders(success.customerListHeaders);
      setDownloadedData(success.customerList);
      setDownload(false);
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    }
  };

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    await getCustomersDetails();
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
  }, [_, currentPage, currentLimit]);

  const searchField = (
    <Search
      style={{
        width: small ? "200px" : "100%",
        marginLeft: small ? "0px" : "10px",
      }}
      api={customerSearch}
      businessId={businessId}
      getLabel={(customer) =>
        `${customer.customerFirstName} ${customer.customerLastName}`
      }
      getList={(response) => response.customers}
      onclick={(customer) => {
        safePush(
          `/dashboard/${businessId}/Customers/${customer.customerId}`,
          history,
          location
        );
      }}
    />
  );

  if (initialState === null) {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <React.Fragment>
        <EmailAllCustomersForm
            open={emailOpen}
            setOpen={setEmailOpen}
            refresh={refresh}
          />
      <Grid
        container
        className={
          large
            ? classes.mainWrapper
            : !large && small
            ? classes.mobilePadding
            : null
        }
      >
        <GeneralDialog
          open={download}
          close={() => {
            setDownload(false);
          }}
          title="Customers"
          text="Do you want to download a list of customers in csv format?"
          action={handleDownload}
        />
        <CSVLink
          filename="Customers.csv"
          data={downloadedData}
          headers={downloadedHeaders}
          ref={csvLinkEl}
        />
        <CModal
          open={add}
          setOpen={setAdd}
          children={
            <Container component="main" className={classes.formContainer}>
              <Form
                action={handleSubmit}
                postAction={null}
                title="New Customer"
                cancelEnabled
                cancelAction={() => {
                  setAdd(false);
                }}
                cancelText="Cancel"
                submitText="Create"
                inputFields={[
                  {
                    type: "TextField",
                    validator: [notEmpty, validEmail],
                    initialvalue: "",
                    label: "Customer Email",
                    name: "email",
                  },
                  {
                    type: "TextField",
                    validator: [notEmpty, noWhiteSpace],
                    initialvalue: "",
                    label: "Caritra Account Number",
                    name: "account",
                  },
                ]}
              ></Form>
            </Container>
          }
        />
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          direction="row"
          className={small ? null : classes.stickyHeader}
          spacing={2}
          style={{
            paddingTop: !large && small ? "15px" : undefined,
            paddingBottom: "5px",
          }}
        >
          <Grid item style={{ flexGrow: 1 }}>
            <div className={classes.headerPadding}>
              <span
                className={
                  small ? classes.topHeading : classes.mobileTopHeadingOne
                }
              >
                Customers
              </span>
            </div>
          </Grid>
          {small ? <Grid item>{searchField}</Grid> : null}
          <Grid item>
            <div className={classes.headerPadding}>
            {customerList.length > 0 ? (
                <LightTooltip title="Create Customer" arrow>
                  <span className={classes.rightElem}>
                    <Button
                      className={classes.buttonDownload}
                      style={{ backgroundColor: "#0079fc", color: "white" }}
                      onClick={() => {
                        setEmailOpen(true);
                      }}
                    >
                      <Mail />
                    </Button>
                  </span>
                </LightTooltip>
              ) : null}

              {customerList.length > 0 ? (
                <LightTooltip title="Create Customer" arrow>
                  <span className={classes.rightElem}>
                    <Button
                      className={classes.buttonDownload}
                      style={{ backgroundColor: "#0079fc", color: "white", marginRight: "10px"}}
                      onClick={() => {
                        setDownload(true);
                      }}
                    >
                      <ArrowDownwardIcon />
                    </Button>
                  </span>
                </LightTooltip>
              ) : null}

              <LightTooltip title="Create Customer" arrow>
                <span className={classes.rightElem}>
                  <Button
                    className={classes.buttonBasic}
                    style={{
                      backgroundColor: "#0079fc",
                      color: "white",
                      marginRight: customerList.length > 0 ? "10px" : "0",
                    }}
                    onClick={() => {
                      setAdd(true);
                    }}
                  >
                    Add
                  </Button>
                </span>
              </LightTooltip>
            </div>
          </Grid>
          {small ? null : (
            <Grid item xs={12}>
              {searchField}
            </Grid>
          )}
        </Grid>
        {customerList.length > 0 ? (
          <Grid
            item
            xs={12}
            className={
              !large || !small
                ? [classes.mobilePadding, classes.headerPadding]
                : classes.headerPadding
            }
          >
            <div>
              <ViewChanger />
            </div>
          </Grid>
        ) : (
          <EmptyListImage name="Customers" />
        )}
        {customerList.length > 0 ? (
          <Grid item xs={12}>
            <ListTableNavigation
              currentPage={currentPage}
              currentLimit={currentLimit}
              totalDocs={totalDocs}
              tableRows={customerList}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              getPrevPage={getPrevPage}
              getNextPage={getNextPage}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
      </React.Fragment>
    );
  }
};

export default CustomersList;
