import React, { useState, useCallback, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import SessionDialog from "../../../../../CalendarDialogs/sessionDialog";
import ClassDialog from "../../../../../CalendarDialogs/classDialog";
import InternalEventDialog from "../../../../../CalendarDialogs/internalEventDialog";
import FilterDialog from "../../../../../CalendarDialogs/filterDialog";
import {
  rescheduleClass,
  rescheduleSession,
  staffCalOrder,
  withLoading,
  dashboardPreferences,
  staffRCalendarEvents,
  getGenericDetails,
} from "../../../../../../utils";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router";
import ResourceWorkbench from "../ResourceWorkbench/ResourceWorkbench";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";

const useStyles = makeStyles((theme) => ({
  calendarBox: {
    width: "100%",
    maxHeight: "calc(100vh - 64px)",
    minHeight: "calc(100vh - 64px)",
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

const StaffCalendarHandler = () => {
  const [_, updateState] = useState(0);
  const classes = useStyles();
  const history = useHistory();
  const small = useMediaQuery("(min-width:960px)");
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const role = useSelector((state) => {
    return state.buisnessDetails.role;
  });

  const calendarRef = React.createRef();

  const [initialLoadComplete, setInitialLoad] = useState(false);
  const [openSession, setOpenSession] = useState(false);
  const [openClass, setOpenClass] = useState(false);
  const [sessionInfo, setSessionInfo] = useState(null);
  const [classInfo, setClassInfo] = useState(null);
  const [filterValue, setFilterValue] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterMode, setFilterMode] = useState(false);
  const [sessionId, setSessionId] = useState(false);
  const [classId, setClassId] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [openInternal, setOpenInternal] = useState(false);
  const [internalInfo, setInternalInfo] = useState(null);
  const [internalEventId, setInternalEventId] = useState(false);

  const [serviceIds, setServiceIds] = useState([]);
  const [siteIds, setSiteIds] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [statusTypes, setStatus] = useState([]);
  const [modeTypes, setModeTypes] = useState([]);

  const baseFieldSetRef = [
    setServiceIds,
    setSiteIds,
    setServiceTypes,
    setStatus,
    setModeTypes,
  ];

  const [serviceIdsOption, setServiceIdsOption] = useState([]);
  const [siteIdsOption, setSiteIdsOption] = useState([]);
  const [serviceTypesOption, setServiceTypesOption] = useState([]);
  const [statusTypesOption, setStatusTypesOption] = useState([]);
  const [modeTypesOption, setModeTypesOption] = useState([]);

  const [preferences, setPreferences] = useState([]);

  const dispatch = useDispatch();

  const filterList = [
    "serviceIds",
    "siteIds",
    "serviceTypes",
    "status",
    "mode",
  ];

  const pullGenericDetails = async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let genDetails = await getGenericDetails({ businessId: businessId });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (genDetails) {
      setServiceIdsOption(
        genDetails.genericDetails.serviceDetails.map((service) => {
          return {
            id: service.serviceId,
            name: service.name,
          };
        })
      );
      setSiteIdsOption(
        genDetails.genericDetails.siteDetails.map((site) => {
          return {
            id: site.siteId,
            name: `${site.name}`,
          };
        })
      );
      setServiceTypesOption([
        {
          id: "Private",
          name: "Private",
        },
        {
          id: "Group",
          name: "Group",
        },
      ]);

      setStatusTypesOption([
        {
          id: "Scheduled",
          name: "Scheduled",
        },
        {
          id: "Canceled",
          name: "Canceled",
        },
      ]);

      setModeTypesOption([
        {
          id: "In Person",
          name: "In Person",
        },
        {
          id: "Virtual",
          name: "Virtual",
        },
      ]);
    }
    setInitialLoad(true);
  };

  const getDashboardPreferences = async () => {
    let calPreferences = await dashboardPreferences({
      businessId: businessId,
    });
    if (calPreferences.dashboardPreferences.calendar) {
      setPreferences(calPreferences.dashboardPreferences.calendar);
    }
  };

  useEffect(() => {
    pullGenericDetails();
    getDashboardPreferences();
  }, []);

  const getResourceCalendarStaff = useCallback(async () => {
    try {
      let calResources = await staffCalOrder({
        businessId: businessId,
      });
      return calResources.staffMap;
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getStaffResourceCalendarEvents = useCallback(
    async (calProp) => {
      try {
        let calEvents = await staffRCalendarEvents({
          businessId: businessId,
          startStr: calProp.startStr,
          endStr: calProp.endStr,
          serviceIds,
          siteIds,
          serviceTypes,
          statusTypes,
          modeTypes,
        });
        return calEvents.events;
      } catch (error) {
        console.error(error);
      }
    },
    [serviceIds, siteIds, serviceTypes, statusTypes, modeTypes, _]
  );

  const eventClicked = useCallback((eventClickInfo) => {
    try {
      if (eventClickInfo.event.extendedProps.type === "Session") {
        setOpenSession(true);
        setSessionId(eventClickInfo.event.extendedProps.sessionId);
        setSessionInfo(eventClickInfo.event.extendedProps);
      } else if (eventClickInfo.event.extendedProps.type === "Class") {
        setOpenClass(true);
        setClassId(eventClickInfo.event.extendedProps.classId);
        setClassInfo(eventClickInfo.event.extendedProps);
      } else if (eventClickInfo.event.extendedProps.type === "Internal") {
        setOpenInternal(true);
        setInternalEventId(eventClickInfo.event.extendedProps.eventId);
        setInternalInfo(eventClickInfo.event.extendedProps);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const sessionDetails = () => {
    history.push(`/dashboard/${businessId}/Sessions/${sessionId}`);
  };

  const classDetails = () => {
    history.push(`/dashboard/${businessId}/Group-Classes/${classId}`);
  };

  const internalDetails = () => {
    history.push(`/dashboard/${businessId}/Internal-Events/${internalEventId}`);
  };

  const openFilterDialog = useCallback(() => {
    setFilterMode(true);
    setOpenFilter(true);
  }, []);

  const applyFilter = (dialogFieldRef) => {
    baseFieldSetRef[0](
      dialogFieldRef.serviceIds ? dialogFieldRef.serviceIds : serviceIdsOption
    );
    baseFieldSetRef[1](
      dialogFieldRef.siteIds ? dialogFieldRef.siteIds : siteIdsOption
    );
    baseFieldSetRef[2](
      dialogFieldRef.serviceTypes
        ? dialogFieldRef.serviceTypes
        : serviceTypesOption
    );
    baseFieldSetRef[3](
      dialogFieldRef.status ? dialogFieldRef.status : statusTypesOption
    );
    baseFieldSetRef[4](
      dialogFieldRef.mode ? dialogFieldRef.mode : modeTypesOption
    );
    setOpenFilter(false);
    setFilterMode(false);
    setFilterApplied(true);
    let num = 0;
    if (filterValue === 0) {
      filterList.map((item) => {
        if (dialogFieldRef[item].length > 0) {
          num = num + 1;
        }
        if (item === "mode") {
          setFilterValue(num);
        }
      });
    } else {
      filterList.map((item) => {
        if (dialogFieldRef[item].length > 0) {
          num = num + 1;
        }
        if (item === "mode") {
          setFilterValue(num);
        }
      });
    }
    // let calendarApi = calendarRef.current.getApi();
    // calendarApi.refetchEvents();
  };

  const clearFilter = () => {
    for (let i = 0; i < baseFieldSetRef.length; i++) {
      baseFieldSetRef[i]([]);
    }
    setOpenFilter(false);
    setFilterMode(false);
    setFilterApplied(false);
    setFilterValue(0);
  };

  return (
    <Fragment>
      <Grid
        container
        className={small ? classes.mainWrapper : classes.mobilePadding}
      >
        <Grid item xs={12}>
          <ResourceWorkbench
            calendarRef={calendarRef}
            getEvents={getStaffResourceCalendarEvents}
            getResources={getResourceCalendarStaff}
            eventClick={eventClicked}
            openFilterDialog={openFilterDialog}
            clearFilter={clearFilter}
            filterNum={filterValue}
            filters={isFilterApplied}
            prefer={preferences}
            staff={true}
            colHeader="Staff"
            sortOrder="title"
          />
        </Grid>
      </Grid>
      {sessionInfo ? (
        <SessionDialog
          open={openSession}
          sessionDetails={sessionDetails}
          sessionInfo={sessionInfo}
          close={() => {
            setOpenSession(false);
          }}
        />
      ) : (
        <div></div>
      )}

      {classInfo ? (
        <ClassDialog
          open={openClass}
          classDetails={classDetails}
          classInfo={classInfo}
          close={() => {
            setOpenClass(false);
          }}
        />
      ) : (
        <div></div>
      )}

      {internalInfo ? (
        <InternalEventDialog
          open={openInternal}
          internalDetails={internalDetails}
          internalInfo={internalInfo}
          close={() => {
            setOpenInternal(false);
          }}
        />
      ) : (
        <div></div>
      )}
      {initialLoadComplete && filterMode ? (
        <FilterDialog
          open={openFilter}
          applyFilter={applyFilter}
          page="StaffResourceMap"
          filters={isFilterApplied}
          clearFilter={isFilterApplied ? clearFilter : null}
          role={role}
          fieldOptions={[
            serviceIdsOption,
            siteIdsOption,
            serviceTypesOption,
            statusTypesOption,
            modeTypesOption,
          ]}
          isFilterApplied={isFilterApplied}
          currentFilterVal={[
            serviceIds,
            siteIds,
            serviceTypes,
            statusTypes,
            modeTypes,
          ]}
          close={() => {
            setOpenFilter(false);
            setFilterMode(false);
          }}
        />
      ) : (
        <div></div>
      )}
    </Fragment>
  );
};

export default StaffCalendarHandler;
