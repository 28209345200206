import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import DisplayAllStaff from './DisplayAllStaff';
import SingleCalendar from './SingleCalendar';
import SingleStaffSchedule from './SingleStaffSchedule';

const WorkAvailabilityHandler = () => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const basePath = `/dashboard/${businessId}/Staff-Schedules`;
    return(
        <Switch>
            <Route path = {basePath}  exact render = {()=>{return(<DisplayAllStaff businessId = {businessId}/>);}}/>
            <Route path = {`${basePath}/:staffIdURL`}  exact render = {()=>{return(<SingleStaffSchedule businessId = {businessId}/>);}}/>
            <Route path = {`${basePath}/:staffIdURL/Schedule/:calendarId`}  exact render = {()=>{return(<SingleCalendar businessId = {businessId} />)}}/>
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
        </Switch>
    );
}

export default WorkAvailabilityHandler;