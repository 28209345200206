import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getStaffList, withLoading, retrieveGetParams } from "../../../../../../utils";
import { Grid, useMediaQuery, Button, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import ListTable from "./../../../../../TableComponents/ListTable";
import StatusTag from "../../../../../TagComponents/StatusTag";
import ListCard from "../../../../../MobileCards/ListCard";
import NewStaffForm from "./NewStaffForm";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import PostFilter, { applyFilters } from '../../../../../Filter/PostFilter'
const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    rightElem: {
        float: "right",
    },
    buttonBasic: {
        fontSize: "14px",
        marginLeft: '10px',
        textTransform: "none",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingThree: {
        fontSize: "20px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeading: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    bottomSpacer: {
        height: "50px",
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    }
}));
const staffHeaders = [
    {
        name: "Status",
        columnKey: "status",
        columnWidth: "100px",
    },
    {
        name: "Member Id",
        columnKey: "memberId",
        columnWidth: "100px",
    },
    {
        name: "Role",
        columnKey: "role",
        columnWidth: "100px",
    },
    {
        name: "Name",
        columnKey: "name",
        columnWidth: "20%",
        tooltip: true
    },
    {
        name: "Email",
        columnKey: "email",
        columnWidth: "15%",
        tooltip: true
    },
    {
        name: "Phone Number",
        columnKey: "phoneNumber",
        columnWidth: "15%",
        tooltip: true
    },

];

const keyObjects = [
    {
        key: 'isActive',
        label: 'Status',
        keyLabel: 'statusName'
    },
    {
        key: 'role',
        label: 'Role',
        keyLabel: 'role'
    }
]
const StaffsList = (props) => {
    const [add, setAdd] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation()
    const queryParams = retrieveGetParams(location)
    const businessId = props.businessId

    const [staffsList, setStaffsList] = useState(null);
    const bigEnough = useMediaQuery("(min-width:750px)")
    const processStaffsData = (staffsData) => {
        return staffsData.map((staff) => {
            let {
                staffId,
                firstName,
                lastName,
                role,
                email,
                phoneNumber,
                memberId,
                isActive
            } = staff;
            phoneNumber = `${phoneNumber.slice(0, 2)} (${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`
            let status = <StatusTag wrapperName={isActive ? "greenWrapper" : "redWrapper"} value={isActive ? "Active" : "Inactive"} />;
            let id = staffId;
            let name = `${firstName} ${lastName}`

            return {
                id,
                status,
                name,
                role,
                email,
                phoneNumber,
                memberId,
                isActive,
                statusName: isActive ? 'Active' : 'Not Active'
            };
        });


    };

    const getStaffsDetails = async () => {
        let staffsPayload = await getStaffList({
            businessId: businessId,
        });
        if (Array.isArray(staffsPayload)) {
            setStaffsList(processStaffsData(staffsPayload));
        };
    };

    const navigateToSite = (staffId) => {
        history.push(`/dashboard/${businessId}/Staff/${staffId}`);
    };

    useEffect(async () => {
        await withLoading(getStaffsDetails, {}, true)
    }, []);

    const ViewChanger = () => {
        if (bigEnough) {
            return (
                <React.Fragment>
                    <Grid item xs={12}>
                        <div className={classes.contentRow}>
                            <ListTable
                                identifier={"admin_orders"}
                                tableHeaders={staffHeaders}
                                tableRows={applyFilters(staffsList, queryParams, keyObjects)}
                                rowClick={navigateToSite}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.bottomSpacer}></div>
                    </Grid>
                </React.Fragment>
            )
        }
        else {
            return (
                <Grid item xs={12} style={{ paddingTop: "10px" }}>
                    {applyFilters(staffsList, queryParams, keyObjects).map((staff) => {
                        return (
                            <ListCard
                                action={() => { history.push(`/dashboard/${businessId}/Staff/${staff.id}`); }}
                                cardName={staff.name}
                                statusReplace={
                                    <Typography
                                        noWrap
                                        style={!staff.isActive ? { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "red" } : { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "green" }}>
                                        {staff.isActive ? "Active" : "Inactive"}
                                    </Typography>
                                }
                                other={staff.role} />
                        )
                    })}
                </Grid>
            )
        }
    }

    if (staffsList !== null) {
        return (
            <React.Fragment>
                <NewStaffForm open={add} setOpen={setAdd} basePath={props.basePath} />
                <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                    <Grid item xs={12}>
                        <div className={classes.headerPadding}>
                            <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Staff List</span>
                            <span title="Add Staff" className={classes.rightElem}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.buttonBasic}
                                    onClick={() => { setAdd(true); }}
                                >Add
                                </Button>
                            </span>
                            <PostFilter allEntries={staffsList} keyObjects={keyObjects} />
                        </div>
                    </Grid>
                    {(Array.isArray(staffsList) && staffsList.length > 0 ? <ViewChanger /> : <EmptyListImage name="Staff" />)}
                </Grid>
            </React.Fragment>
        );
    }
    else {
        return (null)
    }


};

export default StaffsList;

