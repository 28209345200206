import { Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { useState } from "react";
import notEmpty from "../../../FormValidation/notEmpty";
import noWhiteSpace from "../../../FormValidation/noWhiteSpace";
import validPhoneNumber from "../../../FormValidation/phonenumber";
import validEmail from "../../../FormValidation/validEmail";
import { businessInquiry, withLoading } from '../../../utils';
import Form from "../../Form/Form";
import { formatSrc } from "../../Image/Image";
const useStyles = makeStyles(theme => ({
    hero: {
        paddingTop: '100px',
        marginBottom: '10px',
        paddingRight: '10px',
        paddingLeft: 'max(10px, 10vw)',
        backgroundImage: `url(${formatSrc('https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/business-page-hero-final.webp', {
            mobile: false,
            mobileOptions: undefined,
            desktopOptions: ['fit-in/1920x1080']
        })})`,
        backgroundPosition: 'center bottom',
        backgroundSize: "cover",
        height: "225px",

    },
    whiteText: {
        color: 'white'
    },
    cardHolder: {
        minHeight: '600px'
    },
    pricingCard: {
        width: 'min(460px, calc(100% - 40px))',
        padding: '20px',
        margin: '0 auto',
        height: 'calc(100% - 40px)',
        display: 'flex',
        flexDirection: 'column'
    },
    bold: {
        fontWeight: 'bold'
    },
    buttonText: {
        textTransform: 'none',
        fontSize: '1.5rem',
        textDecoration: 'none'
    },
    button: {
        width: 'min(100%,300px)',
        height: '60px',
        textDecoration: 'none'
    },
    paddingTop: {
        paddingTop: '20px'
    },
    paddingBottom: {
        paddingBottom: '50px'
    },
    centerH: {
        margin: '0 auto'
    },
    spacing: {
        padding: '8px'
    },
    spacer: {
        height: '100px'
    },
    container: {
        minHeight: '100vh'
    },
    contactForm: {
        width: 'min(600px, 100%)',
        margin: '0 auto',
        paddingBottom: '20px'
    },
    contactFormBorder: {
        paddingTop: '10px',
        paddingRight: '10px',
        paddingLeft: '10px',
        border: `2px solid ${theme.palette.accent1.main}`
    }
}))

const USCurrencyInfo = {
    "countryCode": "US",
    "countryShortName": "USA",
    "countryLongName": "United States Of America",
    "currencyCode": "usd",
    "currencyShortName": "USD",
    "currencyLongName": "United States Dollar",
    "currencySymbol": "$",
    "currencyBreak": 100,
    "roundingFactor": 2
}


const contactForm = [
    {
        type: "TextField",
        name: "firstName",
        validator: [notEmpty, noWhiteSpace],
        label: "First Name",
        size: {
            xs: 12,
            md: 6
        }

    },
    {
        type: "TextField",
        name: "lastName",
        validator: [notEmpty, noWhiteSpace],
        label: "Last Name",
        size: {
            xs: 12,
            md: 6
        }
    },
    {
        type: "PhoneNumber",
        name: "phoneNumber",
        validator: validPhoneNumber,
        label: "Phone Number"
    },
    {
        type: "TextField",
        name: "email",
        validator: [notEmpty, validEmail],
        label: "Email",
    },
    {
        type: "TextField",
        name: "businessName",
        label: "Business Name",
    },
    {
        type: "TextField",
        name: "businessWebsite",
        label: "Business Website",
    },
    {
        type: 'Number',
        name: 'noOfStaff',
        label: "# of Staff",
        numberProps: {
            decimalScale: 0
        },
        size: {
            xs: 12,
            md: 6
        }
    },
    {
        type: 'Currency',
        name: 'transactionVolume',
        label: "Transaction Volume",
        currencyInfo: USCurrencyInfo,
        size: {
            xs: 12,
            md: 6
        }
    },
    {
        type: 'TextArea',
        name: 'otherInfo',
        label: 'Additional Info',
        resizeEnabled: true,
    }
]

const successMessage = 'Your request has been submited. We will get back to you shortly.'

const ContactSales = (props) => {
    const classes = useStyles()
    const mobile = useMediaQuery("(max-width:750px)")

    const [success, setSuccess] = useState(false)
    return (
        <Grid container className={classes.container}>
            <Grid xs={12} item className={classes.hero} />
            <Grid item xs={12} style={{ padding: "0px 10px 20px 10px" }}>
                {success ?
                    (
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <Typography align='center' variant={mobile ? "h5" : "h4"} className={classes.paddingTop}>
                                    {successMessage}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                    : (
                        <div className={`${classes.contactForm} ${mobile ? '' : classes.contactFormBorder}`}>
                            <Typography variant={mobile ? "h5" : "h4"} align="center">
                                Contact Sales
                            </Typography>
                            <Form
                                submitText="Submit"
                                action={async (payload) => {
                                    return await withLoading(businessInquiry, {
                                        ...payload,
                                        businessName: payload.businessName ? payload.businessName : undefined,
                                        businessWebsite: payload.businessWebsite ? payload.businessWebsite : undefined,
                                        noOfStaff: Number.isInteger(payload.noOfStaff) ? payload.noOfStaff : undefined,
                                        transactionVolume: Number.isFinite(payload.transactionVolume) ? payload.transactionVolume : undefined,
                                        otherInfo: payload.otherInfo ? payload.otherInfo : undefined,

                                    })
                                }}
                                postAction={(response) => {
                                    if (response?.error === false) {
                                        setSuccess(true)
                                    }
                                }}
                                inputFields={contactForm}
                            />
                        </div>
                    )}

            </Grid>
        </Grid>
    )
}

export default ContactSales