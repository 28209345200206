import React, {useState, useEffect} from 'react'
import Form from "../../../../../Form/Form"
import CModal from "../../../../../Form/CModal";
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import { useDispatch, useSelector} from 'react-redux';
import { addServiceQuestion, getGenericDetails, withLoading } from '../../../../../../utils';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    width:"100vw",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflowY: "auto"
  }
}))


const AddQuestionForm = (props) => {

  const [allQuestions, setAllQuestions] = useState(null)
  const dispatch = useDispatch()
  const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
  const generateChoices = (allOptions) =>{
    const temp = []
    const currentChosen = new Set()
    props.serviceDetails.questions.forEach((question)=>{
      currentChosen.add(question.questionId)
    })
    allOptions.forEach((question)=>{
      if(!currentChosen.has(question.questionId) && question.isActive){
        temp.push([question.question, question.questionId])
      }
    })
    return(temp)
  }

  const setUp = async () => {
    let genericData = await withLoading(getGenericDetails,{
        businessId: businessId
      })
    if(genericData&& genericData.genericDetails && Array.isArray(genericData.genericDetails.questionDetails)){
      setAllQuestions(genericData.genericDetails.questionDetails)
    }  
  }

  useEffect(()=>{
    if(props.open){
      setAllQuestions([])
      setUp()
    }
  },[props.open])

  const classes = useStyles()
  const refresh = (success) => {
    if (success) {
        props.setOpen(false)
        props.refresh()
    }
}
return(
  <CModal open={props.open} setOpen={props.setOpen}>
          <Container component="main" className={classes.formContainer}>
              {(allQuestions !== null?
              <Form
              moreData={{ businessId: businessId, serviceId: props.serviceDetails.serviceId}}
              title="Add Questions"
              cancelEnabled
              cancelAction={() => { props.setOpen(false) }}
              submitText="Add"
              action={addServiceQuestion}
              postAction={refresh}
              inputFields={[
                  {
                      type: "MultiSelect",
                      name: "questionIds",
                      label: "Questions",
                      choices: generateChoices(allQuestions),
                      validator: (value)=>(value.length === 0?"Choose at least one question":null)
                  }

              ]}
          />
          :null)}
          </Container>
      </CModal>
)
}

export default AddQuestionForm