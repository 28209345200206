import { Container, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import CButtonPair from "../Form/CButtonPair";
import CModal from "../Form/CModal";
import CTitle from "../Form/CTitle";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "20px",
    // padding: "20px",
    maxWidth: "600px",
    width: "100vw",
    maxHeight: "80vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
}));

const ViewPRModal = (props) => {
  const classes = useStyles();
  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Grid container className={classes.formContainer}>
        <Grid container>
          <Grid item xs={12} style={{ backgroundColor: "#FFBF00", paddingTop: 15, paddingLeft: 15, paddingRight: 15}}>
            <CTitle title=" Customer and Attendee Qualifications" />
          </Grid>
          <Grid xs={12} style={{ paddingLeft: 15, paddingRight: 15, paddingTop:15}}>
            <Typography>
              Please make sure that you and your attendees meet the following
              qualifications.
            </Typography>
          </Grid>

          {props?.customerPreReqs?.length > 0 ? (
            <Grid xs={12} style={{paddingRight: 15, paddingLeft: 15}}>
              <Typography variant="h6">Customer:</Typography>
              <ul>
                {props?.customerPreReqs?.map((customerQulification, index) => (
                  <li>
                    <Typography key={`customer-quali-${index}`}>
                      <b>{customerQulification.name}</b>
                      {customerQulification.description
                        ? ` - ${customerQulification.description}`
                        : ""}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Grid>
          ) : null}

          {props?.attendeePreReqs?.length > 0 ? (
            <Grid xs={12} style={{paddingLeft: 15, paddingRight: 15}}>
              <Typography variant="h6">Attendee:</Typography>
              <ul>
                {props?.attendeePreReqs?.map((attendeeQualification, index) => (
                  <li>
                    <Typography key={`customer-quali-${index}`}>
                      <b>{attendeeQualification.name}</b>
                      {attendeeQualification.description
                        ? ` - ${attendeeQualification.description}`
                        : ""}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Grid>
          ) : null}

          <Grid item xs={12} style={{padding:15}}>
            <CButtonPair
              action={() => props.setOpen(false)}
              notSubmit
              errors={[]}
              submitText="Close"
            />
          </Grid>
        </Grid>
      </Grid>
    </CModal>
  );
};

export default ViewPRModal;
