import { makeStyles, TextField, Typography, FormHelperText } from "@material-ui/core"
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import { useState, useEffect } from 'react'
import LightTooltip from "./LightTooltip";
import CheckIcon from '@material-ui/icons/Check';
const useStyles = makeStyles((theme) => ({
    comp:
    {
        width: "100%"
    },
    formLabel: {
        fontSize: 14,
        color: "#3C4257"
    },
    fieldText: {
        fontSize: "16px !important",
        color: "#1A1F36 !important",
        padding: "2px !important",
        paddingRight: "31px !important"
    },
    showChoice: {
        fontSize: 14,
        color: "#1A1F36",
        padding: "10px",
    },
    helperText: {
        marginRight: 14,
        marginLeft: 14
    },
    selected: {
        fontSize: 16,
        color: theme.palette.primary.main,
    },
    check: {
        fontSize: 16,
        paddingRight: 7
    },
    menuPaper: {
        maxHeight: 220,
        overflowY: 'auto'
    },
    noPadding: {
        padding: 0
    }
}));




const CAutoComplete = (props) => {
    const { choices, updateGlob, onchange, errortext, label, initialvalue } = props
    const [open, setOpen] = useState(false);
    const classes = useStyles()
    useEffect(() => {
        if (props.initialvalue) {
            props.updateGlob(props.initialvalue)
        }
        else {
            props.updateGlob(undefined)
        }
    }, [])
    return (
        <div className={classes.comp}>
            <Typography className={classes.formLabel}>
                {label}
            </Typography>
            <Autocomplete
                classes={{
                    paper: classes.menuPaper
                }}
                defaultValue={initialvalue ? initialvalue : undefined}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={(event, value) => {
                    updateGlob(value)
                    onchange(event)
                }}
                popupIcon={<ArrowDropDownCircleIcon style={(open ? { color: "#0079FC" } : null)} />}
                fullWidth
                disablePortal={true}
                options={choices}
                getOptionLabel={(option) => option[0]}
                renderOption={(choice, { selected }) => (
                    <LightTooltip arrow title={choice[0]}>
                        <Typography noWrap className={(selected ? classes.selected : classes.fieldText)}>
                            {(selected ? <CheckIcon className={classes.check} /> : null)}
                            {choice[0]}
                        </Typography>
                    </LightTooltip>
                )}
                renderInput={(params) =>
                    <TextField
                        fullWidth {...params}
                        InputProps={{
                            ...params.InputProps,
                            classes: { input: classes.fieldText }
                        }}
                        variant="outlined" />}
            />
            <FormHelperText
                className={classes.helperText}
                error={(typeof errortext == "string" && errortext !== "" ? true : false)}
            >
                {errortext}
            </FormHelperText>

        </div>

    )
}

export default CAutoComplete