import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getMyBusinesses, withLoading } from "../../../../utils";
import { Grid, useMediaQuery, Button, Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import ListTable from "./../../../TableComponents/ListTable";
import ListCard from "../../../MobileCards/ListCard";
import EmptyListImage from "../../../EmptyListImage/Empty";
import PersonIcon from '@material-ui/icons/Person';
import { Avatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    rightElem: {
        float: "right",
    },
    buttonBasic: {
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    valueTextLink: {
        fontSize: "14px",
        color: "#0079fc",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingThree: {
        fontSize: "20px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeading: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    bottomSpacer: {
        height: "50px",
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    }
}));

const PreferredBusinessList = () => {
    const history = useHistory();
    const classes = useStyles();
    const error = useSelector(state => state.error);
    const errorMessage = useSelector(state => state.errorMessage);
    const staffHeaders = [
        {
            name: "",
            columnKey: "logo",
            columnWidth: "30px"
        },
        {
            name: "Business Name",
            columnKey: "businessName",
            columnWidth: "100px"
        },
        {
            name: "Phone Number",
            columnKey: "phoneNumber",
            columnWidth: "20%",
            type: "Typography",
            tooltip: true
        },
        {
            name: "Email",
            columnKey: "businessEmail",
            columnWidth: "20%",
            type: "Typography",
            tooltip: true
        }
    ];

    const [bizList, setBizList] = useState(null);
    const bigEnough = useMediaQuery("(min-width:750px)")
    const processPreferredBizData = (bizData) => {
        return bizData.map((biz) => {
            let {
                businessId,
                businessLogo,
                businessName,
                businessPhoneNumber,
                businessEmail
            } = biz;
            let id = businessId;
            let logo = <Avatar src={businessLogo ? businessLogo : <PersonIcon />} />
            let phoneNumber = `${businessPhoneNumber.slice(0, 2)} (${businessPhoneNumber.slice(2, 5)}) ${businessPhoneNumber.slice(5, 8)}-${businessPhoneNumber.slice(8, 12)}`;

            return {
                id,
                businessName,
                logo,
                businessEmail,
                phoneNumber
            };
        });


    };

    const navigateToBiz = (businessId) => {
        history.push(`/account/My-Businesses/${businessId}`);
    };

    const getBizDetails = async () => {
        let staffsPayload = await getMyBusinesses({
            limit: 20,
            page: 1
        });
        if (Array.isArray(staffsPayload.serviceProviders.docs)) {
            setBizList(processPreferredBizData(staffsPayload.serviceProviders.docs));
        };
    };
    useEffect(async () => {
        await withLoading(getBizDetails, {}, true)
    }, []);

    const ViewChanger = () => {
        if (bigEnough) {
            return (
                <React.Fragment>
                    <Grid item xs={12}>
                        <div className={classes.contentRow}>
                            <ListTable
                                identifier={"admin_orders"}
                                tableHeaders={staffHeaders}
                                tableRows={bizList}
                                rowClick={navigateToBiz}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.bottomSpacer}></div>
                    </Grid>
                </React.Fragment>
            )
        }
        else {
            return (
                <Grid item xs={12} style={{ paddingTop: "10px" }}>
                    {bizList.map((biz) => {
                        return (
                            <ListCard
                                action={() => { history.push(`/account/My-Businesses/${biz.id}`); }}
                                cardName={biz.businessName}
                                statusReplace={biz.phoneNumber}
                                other={biz.businessEmail} />
                        )
                    })}
                </Grid>
            )
        }
    }

    if (bizList !== null) {
        return (
            <React.Fragment>
                <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                    <Grid item xs={12}>
                        <div className={classes.headerPadding}>
                            <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Preferred Businesses</span>
                        </div>
                    </Grid>
                    {(Array.isArray(bizList) && bizList.length > 0 ? <ViewChanger /> : <EmptyListImage name = "Preferred Businesses" />)}
                </Grid>
            </React.Fragment>
        );
    }
    else {
        return (null)
    }


};

export default PreferredBusinessList;

