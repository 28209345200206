import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import Order from "./Order";
import OrderList from "./OrderList";

function OrdersHandler() {
    const basePath = `/account/Orders`
    return(
        <React.Fragment>
            <Switch>
            <Route path = {basePath} exact render = {() => {return(<OrderList/>)}} />
            <Route path = {`${basePath}/:orderIdURL`} render = {() => {return(<Order/>);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>

        </React.Fragment>
    );
};

export default OrdersHandler;