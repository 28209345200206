const CurrencyPreProcessor = (props) => {
  try {
    let processedAmount = Math.abs(parseFloat(props.amount / props.currencyBreak)).toFixed(props.roundingFactor);
    return `${props.currencySymbol}${processedAmount}`;
  } catch (error) {
    return null;
  }
};

export default CurrencyPreProcessor;
