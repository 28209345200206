import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";


import LocationOnIcon from "@material-ui/icons/LocationOn";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";

import parse from "autosuggest-highlight/parse";

const libraries = ["places"];

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  formLabel: {
        fontSize: 14,
        color: theme.palette.textColor2.main
    },
    fieldText: {
        fontSize: 14,
        color: theme.palette.primaryText.main,
        padding:"10px !important"
    },
    noPadding:{
      padding:"0px !important"
    }
}));

export function Search(props) {

  useEffect(()=>{
    props.panTo()
  },[])


  const combineParams = (moreProps)=>{
    const intoInputProps = (moreProps&& moreProps.InputProps? moreProps.InputProps: {})
    intoInputProps.classes = {input: classes.fieldText};
    const otherProps = {...moreProps, InputProps:intoInputProps}
    return(otherProps)
}

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: "us",
      },
    },
  });

  const classes = useStyles();

  return (
    <div>
      <Typography className = {classes.formLabel}>
        {props.label}
      </Typography>
      <Autocomplete
        className={classes.autoCompleteBox}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={status == "OK" ? data : []}
        autoComplete={false}
        classes = {{
          inputRoot:classes.noPadding,
          input: classes.fieldText
        }}
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={async (event, address) => {
          try {
            const result = await getGeocode({ address: (address?address.description:"") });
            const { lat, lng } = await getLatLng(result[0]);
            props.panTo({ lat, lng, address1 : (address?address.description:"")});
            props.onchange([(address?address.description:""), {lat:lat, lon:lng}])
          } catch (error) {
            props.onchange([(address?address.description:""), {lat:null, lon:null}])
          }
        }}
        onInputChange={(event, newInputValue) => {
          setValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField

            {...combineParams(params)}
            style= {{padding:0}}
            variant="outlined"
            fullWidth
            autoComplete= "off"
            error={(typeof props.errortext == "string" && props.errortext !== "" ? true : false)}
            helperText={(props.errortext !== "" ? props.errortext : null)}
            disabled={!ready}
          />
        )}
        renderOption={(option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );
  
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                  >
                    {part.text}
                  </span>
                ))}
  
                <Typography variant="body2">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </div>
  );
}

// export default React.memo(CAddressSearch);