import React from "react"
import Button from "@material-ui/core/Button";
import FileCopyIcon from '@material-ui/icons/FileCopy';

export const CCopyIcon = (props) => {
    return (
        <Button onClick={() => {navigator.clipboard.writeText(props.textToCopy)}}><FileCopyIcon /></Button>
    );
}

