import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    redWrapper: {
        backgroundColor: "#eb575750",
        paddingLeft: "5px",
        paddingRight: "5px",
        borderRadius: "5px",
        paddingTop: "2px",
        paddingBottom: "2px",
        width: "90px",
        textAlign: "center",
      },
      greenWrapper: {
        backgroundColor: "#6fcf9775",
        paddingLeft: "5px",
        paddingRight: "5px",
        borderRadius: "5px",
        paddingTop: "2px",
        paddingBottom: "2px",
        width: "90px",
        textAlign: "center",
      },
      grayWrapper: {
        backgroundColor: "#dbdddf",
        paddingLeft: "5px",
        paddingRight: "5px",
        borderRadius: "5px",
        paddingTop: "2px",
        paddingBottom: "2px",
        width: "90px",
        textAlign: "center",
      },
      blueWrapper: {
        backgroundColor: "#3366FF",
        color:"#FFFFFF",
        paddingLeft: "5px",
        paddingRight: "5px",
        borderRadius: "5px",
        paddingTop: "2px",
        paddingBottom: "2px",
        width: "90px",
        textAlign: "center",
      },
}));
const StatusTag = (props) => {
  const classes = useStyles();
  return (
    <div className={classes[props.wrapperName]} style={props.width ? {width: props.width}: null}>
    <span className={classes.valueText}>
      {props.value}
    </span>
  </div>
  );
};

export default StatusTag;
