export const attendeeHeaders = [
    {
        name: "id",
        columnKey: "attendeeNumber",
        columnWidth: "100px",
    },
    {
        name: "name",
        columnKey: "name",
        columnWidth: "30%",
    },
    {
        name: "email",
        columnKey: "email",
        columnWidth: "25%",
    },
    {
        name: "date of birth",
        columnKey: "dob",
        columnWidth: "100px",
    },
];

export const customerAttendeeHeaders = [
    {
        name: "id",
        columnKey: "attendeeNumber",
        columnWidth: "100px",
    },
    {
        name: "name",
        columnKey: "name",
        columnWidth: "40%",
    },
    {
        name: "email",
        columnKey: "email",
        columnWidth: "30%",
    },

];

export const attendeeHeadersM = [
    {
        name: "id",
        columnKey: "attendeeNumber",
        columnWidth: "100px",
    },
    {
        name: "name",
        columnKey: "name",
        columnWidth: "100%",
    },
];