import { Button, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { customerCreditList, safePush, withLoading } from "../../../../utils";
import EmptyListImage from "../../../EmptyListImage/Empty";
import ListCard from "../../../MobileCards/ListCard";
import ListTable from "./../../../TableComponents/ListTable";
import { extractDate } from "../../../../FormValidation/validDate";
import CurrencyPreProcessor from "../../../CurrencyFormatter/CurrencyFormatter";
const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  buttonBasic: {
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
}));

const CreditList = (props) => {
  const { basePath } = props;
  const [open, setOpen] = useState(false);
  const [creditList, setCreditList] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const bigEnough = useMediaQuery("(min-width:750px)");

  const creditHeaders = [
    {
      name: "Business Name",
      columnKey: "businessName",
      columnWidth: "30%",
    },
    {
      name: "Available Credit",
      columnKey: "availableCredit",
      columnWidth: "70%",
    },
  ];

  const getCreditList = async () => {
    const creditResponse = await withLoading(customerCreditList, {});
    if (creditResponse && !creditResponse.error) {
      setCreditList(creditResponse.businessCredits);
    }
  };

  useEffect(async () => {
    await withLoading(getCreditList, {}, true);
  }, []);

  const getProcessedCurrency = (val, currencyInfo) => {
    return (
      <CurrencyPreProcessor
        amount={val}
        currencyBreak={currencyInfo.currencyBreak}
        roundingFactor={currencyInfo.roundingFactor}
        currencySymbol={currencyInfo.currencySymbol}
      />
    );
  };

  const ViewChanger = () => {
    if (bigEnough) {
      return (
        <React.Fragment>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <ListTable
                identifier={"credit"}
                tableHeaders={creditHeaders}
                rowClick={(businessId) => {
                  safePush(`${basePath}/${businessId}`, history, location);
                }}
                tableRows={creditList.map((credit) => ({
                  id: credit.businessId,
                  businessName: credit.businessName,
                  availableCredit: getProcessedCurrency(
                    credit.availableCredit,
                    credit.currencyInfo
                  ),
                }))}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          {creditList.map((credit) => {
            return (
              <ListCard
                action={() => {
                  safePush(
                    `${basePath}/${credit.businessId}`,
                    history,
                    location
                  );
                }}
                key={credit.businessId}
                cardName={credit.businessName}
                statusReplace={"Available Credit"}
                other={
                  <Typography>
                    <CurrencyPreProcessor
                      amount={credit.availableCredit}
                      currencyBreak={credit.currencyInfo.currencyBreak}
                      roundingFactor={credit.currencyInfo.roundingFactor}
                      currencySymbol={credit.currencyInfo.currencySymbol}
                    />
                  </Typography>
                }
              />
            );
          })}
        </Grid>
      );
    }
  };

  if (creditList === null) {
    return null;
  } else {
    return (
      <React.Fragment>
        <Grid
          container
          className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
        >
          <Grid item xs={12}>
            <div className={classes.headerPadding}>
              <span
                className={
                  bigEnough ? classes.topHeading : classes.mobileTopHeadingOne
                }
              >
                Credits
              </span>
            </div>
          </Grid>
          {Array.isArray(creditList) && creditList.length > 0 ? (
            <ViewChanger />
          ) : (
            <EmptyListImage name="Credit" />
          )}
        </Grid>
      </React.Fragment>
    );
  }
};

export default CreditList;
