import { Button, Grid, Typography, makeStyles, useMediaQuery, Menu, MenuItem } from "@material-ui/core"
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import { useParams, Link, useHistory, useLocation } from "react-router-dom"
import { deleteResourceDetails, getResourceDetails, safePush, withLoading } from "../../../../../../utils"
import LightTooltip from "../../../../../Form/LightTooltip"
import { CEllipsisIcon } from "../../../../../Icons/Icons"
import StatusTag from "../../../../../TagComponents/StatusTag"
import ListTable from "../../../../../TableComponents/ListTable"
import ResourceStatusForm from "./ResourceStatusForm"
import EditResourceForm from "./EditResouceForm"
import EditIcon from '@material-ui/icons/Edit';
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog"

const useStyles = makeStyles((theme) => ({
    primary: {
        color: theme.palette.primary.main
    },
    mainWrapper: {
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    topBlock: {
        paddingBottom: "15px",
    },
    recordRow: {
        paddingLeft: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRoght: "5px",
        width: "550px"
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    rightElem: {
        float: "right",
    },
    rightElemPad: {
        float: "right",
        marginRight: "10px",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeadingOne: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    topHeadingTwo: {
        fontSize: "28px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    topHeadingThree: {
        backgroundColor: "#eb5757",
        opacity: "50%",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    topHeadingFour: {
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    buttonBasic: {
        backgroundColor: "#ffffff",
        color: "#1a1f36",
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    contentColumnFirst: {
        paddingRight: "20px",
        boxShadow: "inset -1px 0 #e3e8ee",
        display: "inline-block",
    },
    contentColumnMiddle: {
        boxShadow: "inset -1px 0 #e3e8ee",
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "inline-block",
    },
    contentColumnLast: {
        paddingLeft: "20px",
        display: "inline-block",
    },
    labelText: {
        fontSize: "14px",
        color: "#697386",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText2: {
        fontSize: "14px",
        color: "#3c4257",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextNeg: {
        fontSize: "14px",
        color: "#009211",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBG: {
        fontSize: "16px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBold: {
        fontSize: "14px",
        color: "#1a1f36",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextPad: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingLeft: "10px",
    },
    valueTextLink: {
        fontSize: "14px",
        color: "#3d5af1",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    errorText: {
        fontSize: "14px",
        color: "#ff0000",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    blockLabel: {
        paddingTop: "10px",
        paddingBottom: "5px",
    },
    blockText: {
        paddingLeft: "10px",
    },
    subHeading: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    fieldRows: {
        paddingTop: "8px"
    },
    fieldColumnOne: {
        width: "110px",
        display: "inline-block",
    },
    fieldColumnTwo: {
        display: "inline-block",
    },
    sdCustomCellOne: {
        border: "none",
        width: "10%",
        maxWidth: "200px",
        padding: "10px",
    },
    sdCustomCellTwo: {
        border: "none",
        width: "25%",
        maxWidth: "300px",
        padding: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingThree: {
        fontSize: "20px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    sdCustomCellThree: {
        border: "none",
        width: "10%",
        maxWidth: "200px",
        padding: "10px",
    },
    sdCustomCellFour: {
        border: "none",
        padding: "10px",
    },
    headerPadding: {
        padding: "10px",
        paddingLeft: "0px"
    },
    bottomSpacer: {
        height: "50px",
    },
    topMarginBlock: {
        marginTop: "10px",
    },
    loaderWrapper: {
        width: "100%",
        height: "calc(100vh - 64px)"
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    },
    media: {
        height: 500,
        objectFit: "cover"
    }
}));

const generateMapping = (resource) => {
    const fieldMapping = new Map()
    if (resource?.fields?.length > 0) {
        resource.fields.forEach(field => {
            fieldMapping.set(field.fieldId, field.value)
        })
    }
    return fieldMapping
}

const fieldHeaders = [
    {
        name: "name",
        columnKey: "name",
        columnWidth: "30%",
    },
    {
        name: "value",
        columnKey: "value",
        columnWidth: "70%",
    },
]

const ResourceDetails = (props) => {
    const classes = useStyles()
    const { basePath } = props
    const history = useHistory()
    const location = useLocation()
    const { resourceIdURL } = useParams()
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const bigEnough = useMediaQuery('(min-width: 750px)')

    const [resource, setResource] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [editResource, setEditResource] = useState(false)
    const [delResource, setDelResource] = useState(false)
    const [statusResource, setStatusResource] = useState(false)
    const resourceMapping = generateMapping(resource)
    const matches = useMediaQuery("(min-width: 1600px)");

    const fetchResourceDetails = async () => {
        const resourceResponse = await withLoading(getResourceDetails, {
            businessId,
            resourceId: resourceIdURL
        })
        if (resourceResponse?.resourceDetails && !resourceResponse.error) {
            setResource(resourceResponse.resourceDetails)
        }
    }

    useEffect(() => {
        fetchResourceDetails()
    }, [])

    if (resource) {
        return (
            <React.Fragment>
                <Menu
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    keepMounted

                    open={Boolean(anchorEl)}
                    onClose={() => { setAnchorEl(null) }}
                >
                    <MenuItem onClick={() => { setAnchorEl(null); setEditResource(true) }}>
                        <Typography >
                            Edit Resource
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => { setAnchorEl(null); setStatusResource(true) }}>
                        <Typography >
                            {resource.isActive ? "Deactivate" : "Activate"} Resource
                        </Typography>
                    </MenuItem>
                    {!resource.isActive ?
                        <MenuItem onClick={() => { setAnchorEl(null); setDelResource(true) }}>
                            <Typography >
                                Delete Resource
                            </Typography>
                        </MenuItem> : null}
                </Menu>
                <GeneralDialog
                    open={delResource}
                    close={setDelResource}
                    title="Delete Resource"
                    text="Are you sure you want to delete this resource."
                    action={async () => {
                        const deleteResponse = await withLoading(deleteResourceDetails, {
                            businessId,
                            resourceId: resource.resourceId,
                            editTag: resource.editTag
                        })
                        if (!deleteResponse?.error) {
                            safePush(basePath, history, location)
                        }
                    }}
                />
                <EditResourceForm open={editResource} setOpen={setEditResource} resource={resource} fetchResourceDetails={fetchResourceDetails} />
                <ResourceStatusForm open={statusResource} setOpen={setStatusResource} resource={resource} fetchResourceDetails={fetchResourceDetails} />
                <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                    <Grid item xs={12}>
                        <div className={classes.topBlock}>
                            <span className={classes.topHeading}>RESOURCE</span>
                            <Button onClick={(event) => { setAnchorEl(event.target); }} className={[classes.buttonBasic, classes.rightElem].join(" ")}>
                                <CEllipsisIcon />
                            </Button>
                        </div>
                    </Grid>
                    <Grid item style={(bigEnough ? { maxWidth: "50%" } : { width: "100%" })}>
                        <div className={classes.headerPadding}>
                            <LightTooltip title={resource.name} arrow>
                                <Typography className={(bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne)} noWrap>
                                    {resource.name}
                                </Typography>
                            </LightTooltip>
                        </div>
                    </Grid>
                    {(bigEnough ?
                        <Grid item>
                            <div className={classes.headerPadding} style={{ paddingLeft: "20px" }}>
                                <span className={classes.topHeadingFour}>
                                    <StatusTag wrapperName={resource.isActive ? "greenWrapper" : "redWrapper"} value={resource.isActive ? "Active" : "Inactive"} />
                                </span>
                            </div>
                        </Grid>
                        :
                        <Grid item>
                            <div>
                                <StatusTag wrapperName={resource.isActive ? "greenWrapper" : "redWrapper"} value={resource.isActive ? "Active" : "Inactive"} />
                            </div>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <div className={classes.contentRow}>
                            <div className={classes.fieldRows}>
                                <div className={classes.fieldColumnOne}>
                                    <span className={classes.labelText}>Resource Type</span>
                                </div>
                                <div className={classes.fieldColumnTwo} style={{ maxWidth: "calc(100% - 70px)" }}>
                                    <span className={classes.valueText}>
                                        <Typography style={{ textDecoration: 'none' }}>
                                            <Link style={{ textDecoration: 'none' }} to={`/dashboard/${businessId}/Resource-Type/${resource.templateId}`} className={classes.primary}>
                                                {resource.templateName}
                                            </Link>
                                        </Typography>
                                    </span>
                                </div>
                            </div>
                            <div className={classes.fieldRows}>
                                <div className={classes.fieldColumnOne}>
                                    <span className={classes.labelText}>Site</span>
                                </div>
                                <div className={classes.fieldColumnTwo} style={{ maxWidth: "calc(100% - 70px)" }}>
                                    <span className={classes.valueText}>
                                        <Typography style={{ textDecoration: 'none' }}>
                                            <Link style={{ textDecoration: 'none' }} to={`/dashboard/${businessId}/Sites/${resource.siteId}`} className={classes.primary}>
                                                {resource.siteName}
                                            </Link>
                                        </Typography>
                                    </span>
                                </div>
                            </div>
                            <div className={classes.fieldRows}>
                                <div className={classes.fieldColumnOne}>
                                    <span className={classes.labelText}>Description</span>
                                </div>
                                <div className={classes.fieldColumnTwo} style={{ maxWidth: "calc(100% - 70px)" }}>
                                    <span className={classes.valueText}>
                                    {resource.description ? resource.description : 'Not Provided'}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '40px' }}>
                        <div className={classes.headingWrapper}>
                            <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Attribute Values</span>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.contentRow}>
                            {
                                resource?.templateFields?.length > 0 ?
                                    <ListTable
                                        styling={{ maxHeight: 400, width: "100%" }}
                                        identifier={"fields"}
                                        tableHeaders={fieldHeaders}
                                        tableRows={resource.templateFields.map(field => ({
                                            id: field.fieldId,
                                            name: field.fieldName,
                                            value: resourceMapping.get(field.fieldId) ?? <b>Not Provided</b>
                                        }))}
                                    />
                                    :
                                    <span>This resource does not have any fields.</span>
                            }
                        </div>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
    else {
        return null
    }

}

export default ResourceDetails