import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import moment from "moment";
import React from "react";
import CurrencyPreProcessor from "../CurrencyFormatter/CurrencyFormatter";
import { CCloseIcon } from "../Icons/Icons";
import CDisplayArea from "../Form/CDisplayArea";
import { useState } from 'react'
import GeneralDialog from "../GeneralDialog/GeneralDialog";
import { removeManualPayment, withLoading } from "../../utils";
import RecordPayment from '../TransactionCard/RecordPayment'

const useStyles = makeStyles(theme => ({
    subHeading: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    container: {
        padding: 15,
        border: "solid 1px #e3e8ee",
        marginBottom: 15
    },
    fieldRows: {
        paddingTop: "8px"
    },
    fieldColumnOne: {
        width: "60px",
        display: "inline-block",
    },
    fieldColumnTwo: {
        paddingLeft: "10px",
        display: "inline-block",
    },
    labelText: {
        fontSize: "14px",
        color: "#697386",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    transactionSummary: {
        paddingBottom: 20
    },
    title: {
        paddingBottom: 10,
        borderBottom: "solid 1px #e3e8ee",
        minHeight: 30
    },
    refund: {
        paddingTop: 15,

    }
}))

const ManualPayment = (props) => {
    const { admin, buisnessId, orderId, currencyInfo, payment, updateState } = props
    const { type, amount, createdAt, description, paymentId } = payment
    const classes = useStyles()
    const [editOpen, setEditOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)

    return (
        <div className={classes.container}>
            <Grid container direction="row" alignItems="center">
                <Grid item style={{ width: "calc(100% - 96px)" }}>
                    <Typography variant="h5">
                        {type}
                    </Typography>
                </Grid>
                {admin ?
                    <React.Fragment>
                        <GeneralDialog
                            open={deleteOpen}
                            close={setDeleteOpen}
                            title="Remove Manual Payment"
                            text="Are you sure you want to remove this manual payment from this order?"
                            action={async () => {
                                let data = await withLoading(removeManualPayment,
                                    {
                                        businessId: buisnessId,
                                        orderId: orderId,
                                        paymentId: paymentId
                                    })
                                if (data) {
                                    setDeleteOpen(false)
                                    updateState(_ => !_)
                                }
                            }}
                        />
                        <RecordPayment
                            buisnessId={buisnessId}
                            orderId={orderId}
                            open={editOpen}
                            setOpen={setEditOpen}
                            payment={payment}
                            currencyInfo={currencyInfo}
                            reloadPage={updateState}
                        />
                        <Grid item>
                            <IconButton onClick={() => { setDeleteOpen(true) }}>
                                <CCloseIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => { setEditOpen(true) }}>
                                <EditIcon color="primary" />
                            </IconButton>
                        </Grid>
                    </React.Fragment>
                    : null}
            </Grid>
            <div className={classes.transactionSummary}>
                <div className={classes.fieldRows}>
                    <div className={classes.fieldColumnOne}>
                        <Typography className={classes.labelText}>Amount</Typography>{" "}
                    </div>
                    <div className={classes.fieldColumnTwo}>
                        <Typography className={classes.valueText}>
                            {`${CurrencyPreProcessor({
                                amount: amount,
                                ...currencyInfo
                            })}`}
                        </Typography>
                    </div>
                </div>
                <div className={classes.fieldRows}>
                    <div className={classes.fieldColumnOne}>
                        <Typography className={classes.labelText}>Date</Typography>
                    </div>
                    <div className={classes.fieldColumnTwo}>
                        <Typography
                            className={classes.valueText}
                        >{`${moment.utc(createdAt).format('MMMM D, YYYY')}`}</Typography>
                    </div>
                </div>
                <div className={classes.fieldRows}>
                    <Typography className={classes.labelText} gutterBottom>Description</Typography>
                    <CDisplayArea
                        value={description}
                        initialMaxHeight={"100px"}
                    />
                </div>
            </div>
        </div>
    )
}

export default ManualPayment