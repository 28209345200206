import React from 'react'
import {Redirect, Switch,Route} from 'react-router-dom'
import AccountDashboard from './AccountDashboard'
const AccountHandler = () => {
    return(
        <Switch>
            <Route path = '/account' exact render = {()=>(<Redirect to = '/account/Schedule'/>)}/>
            <Route path = '/account'  render = {()=>(<AccountDashboard/>)}/>
        </Switch>
    )
}

export default AccountHandler