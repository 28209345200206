import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { buisnessDashboard } from '../../../utils';
import AdminDashboard from './AdminDashboard/AdminDashboard';
import StaffDashboard from './StaffDashboard/StaffDashboard';
const BuisnessDashboard = (props) => {
    const location = useLocation()
    const dispatch = useDispatch();
    const [_, updateState] = useState(false);
    const buisnessDetails = useSelector((state) => { return (state.buisnessDetails) })
    const { id } = useParams();
    let dashboard = null;
    const setup = async () => {
        if (!buisnessDetails || buisnessDetails.businessId !== id) {
            dispatch({
                type: 'UPDATE', payload: {
                    loading: true
                }
            });
            let data = await buisnessDashboard({ businessId: id });
            dispatch({
                type: 'UPDATE', payload: {
                    loading: false,
                    buisnessDetails: data
                }
            });
        }
    }
    useEffect(() => {
        setup()
    }, [id]);

    if (buisnessDetails) {
        if (buisnessDetails.role === "Admin") {
            dashboard = <AdminDashboard />;
        }
        else if (buisnessDetails.role === "Staff") {
            dashboard = <StaffDashboard />;
        }
    }
    if (buisnessDetails && buisnessDetails.businessId === id) {
        return (
            <React.Fragment>
                {dashboard}
                {(location.pathname === `/dashboard/${id}` ? <Redirect to={
                    buisnessDetails.financeAccess && buisnessDetails.needAttention ?
                        `/dashboard/${id}/Business-Status`
                        :
                        `/dashboard/${id}/Calender`
                } /> : null)}
            </React.Fragment>);
    }
    else {
        return (null);
    }
}

export default BuisnessDashboard;