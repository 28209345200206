import React from 'react'
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    img: {
        height: 150,
        width: 150
    },
    errorText: {
        color: theme.palette.textColor3.main,
    }
}))

const EmptyMP = (props) => {
    const classes = useStyles()
    return (
        <Grid container alignItems="center" direction="column" justifyContent="center" display="flex" style={{ height: "calc(100vh - 74px)" }}>

            <img className={classes.img} src="https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/error-logo/no-data-found-marketplace.png" alt="Server Issue" />
            <Typography className={classes.errorText} style = {{paddingTop:10, textAlign: "center"}}>
                There are  no services that match your search. Please change search criteria
            </Typography>

        </Grid>
    )
}

export default EmptyMP