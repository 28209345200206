import { Container, makeStyles } from "@material-ui/core";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import maxTextArea from "../../../../../../FormValidation/maxTextArea";
import maxTextField from "../../../../../../FormValidation/maxTextField";
import noWhiteSpace from "../../../../../../FormValidation/noWhiteSpace";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import {
  createPriceGroup,
  editPriceGroup,
  safePush,
} from "../../../../../../utils";
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    width: "100vw",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflowY: "auto",
  },
}));

const PricingGroupForm = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Container className={classes.formContainer}>
        <Form
          moreData={{
            businessId: props.businessId,
            priceGroupId: props.priceGroupId,
          }}
          title={`${props.priceGroupId ? "Edit" : "Create"} Price Group`}
          cancelEnabled
          cancelAction={() => {
            props.setOpen(false);
          }}
          submitText={props.priceGroupId ? "Edit" : "Create"}
          action={async (payload) => {
            if (payload.priceGroupId) {
              return await editPriceGroup(payload);
            } else {
              return await createPriceGroup(payload);
            }
          }}
          postAction={(res) => {
            if (props.refetch) {
              props.setOpen(false);
              props.refetch();
            } else {
              safePush(
                `/dashboard/${props.businessId}/Price-Groups/${res.priceGroupId}`,
                history,
                location
              );
            }
          }}
          inputFields={[
            {
              type: "TextField",
              name: "name",
              label: "Name",
              validator: [notEmpty, noWhiteSpace, maxTextField],
              initialvalue: props.pricingGroup?.name
                ? props.pricingGroup.name
                : JSON.stringify(props.pricingGroup),
            },
            {
              type: "TextArea",
              name: "description",
              label: "Description",
              validator: [notEmpty, noWhiteSpace, maxTextArea],
              initialvalue: props.pricingGroup?.description
                ? props.pricingGroup.description
                : undefined,
            },
          ]}
        />
      </Container>
    </CModal>
  );
};

export default PricingGroupForm;
