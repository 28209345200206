import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ResourceList from './ResourceList';
import ResourceDetails from './ResourceDetails';

const ResourceHandler = () => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const basePath = `/dashboard/${businessId}/Resource`;

    return (
        <Switch>
            <Route path={basePath} exact>
                <ResourceList basePath={basePath} />
            </Route>
            <Route path={`${basePath}/:resourceIdURL`} exact>
                <ResourceDetails basePath={basePath} />
            </Route>
            <Route path='*'>
                <Redirect to='/404' />
            </Route>
        </Switch>
    );
}

export default ResourceHandler;