import {
  Button,
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  catalogDetails,
  deleteCatalog,
  detachOptionsFromCatalog,
  editCatalogStatus,
  getGenericDetails,
  safePush,
  withLoading,
} from "../../../../../../utils";
import LightTooltip from "../../../../../Form/LightTooltip";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import {
  CAddIcon,
  CCloseIcon,
  CEllipsisIcon,
} from "../../../../../Icons/Icons";
import ListTable from "../../../../../TableComponents/ListTable";
import StatusTag from "../../../../../TagComponents/StatusTag";
import CatalogForm from "./CatalogForm";
import CatalogPricingGroupForm from "./CatalogPricingGroupForm";
import CatalogPricingOptionForm from "./CatalogPricingOptionForm";

const useStyles = makeStyles((theme) => ({
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px",
  },
  headingWrapper: {
    paddingBottom: "10px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
    width: "100vw",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
    maxWidth: "160px",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    overflow: "hidden",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
  },
  fieldColumnOne: {
    width: "120px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
    maxWidth: "160px",
  },
  sdCustomCellOne: {
    border: "none",
    width: "5%",
    maxWidth: "200px",
    padding: "0px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingThree: {
    fontSize: "20px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "35%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "60%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
    paddingLeft: "0px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  media: {
    height: 200,
    width: 250,
    objectFit: "cover",
  },
}));

const pricingOptionHeaders = [
  {
    name: "",
    columnKey: "action",
    columnWidth: "100px",
    type: "Normal",
  },
  {
    name: "option",
    columnKey: "name",
    columnWidth: "40%",
    tooltip: true,
  },
  {
    name: "service",
    columnKey: "service",
    columnWidth: "30%",
    tooltip: true,
  },
  {
    name: "site",
    columnKey: "site",
    columnWidth: "30%",
    tooltip: true,
  },
];

const priceGroupHeaders = [
  {
    name: "",
    columnKey: "action",
    columnWidth: "100px",
    type: "Normal",
  },
  {
    name: "name",
    columnKey: "name",
    columnWidth: "100%",
    tooltip: true,
  },
];

const CatalogDetails = (props) => {
  const mountedRef = useRef(false);
  const bigEnough = useMediaQuery("(min-width: 750px)");
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [checkedGroups, setCheckedGroups] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { catalogIdURL } = useParams();
  const [catalog, setCatalog] = useState(null);
  const [priceGroups, setPriceGroups] = useState([]);
  const [pricingOptions, setPricingOptions] = useState([]);
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);
  const [assignOptions, setAssignOptions] = useState(false);
  const [unassignOptions, setUnassignOptions] = useState(false);
  const [assignGroups, setAssignGroups] = useState(false);
  const [unassignGroups, setUnassignGroups] = useState(false);
  const [status, setStatus] = useState(false);
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const fetchCatalogDetails = async () => {
    const res = await withLoading(catalogDetails, {
      businessId,
      catalogId: catalogIdURL,
    });
    if (res?.error === false && res.catalogDetails && mountedRef.current) {
      setCatalog(res.catalogDetails);
    }
  };

  const fetchOptionsAndGroups = async () => {
    const res = await withLoading(getGenericDetails, {
      businessId,
    });
    if (res?.error === false && mountedRef.current) {
      setPricingOptions(res?.genericDetails?.pricingOptionDetails ?? []);
      setPriceGroups(res?.genericDetails?.priceGroupDetails ?? []);
    }
  };

  useEffect(() => {
    mountedRef.current = true;
    fetchCatalogDetails();
    fetchOptionsAndGroups();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (catalog) {
    return (
      <React.Fragment>
        <CatalogForm
          open={edit}
          setOpen={setEdit}
          businessId={businessId}
          refetch={fetchCatalogDetails}
          catalog={catalog}
        />
        <GeneralDialog
          open={del}
          close={setDel}
          title="Delete Catalog"
          text="Are you sure you want to delete this catalog?"
          action={async () => {
            const response = await withLoading(deleteCatalog, {
              businessId: businessId,
              catalogId: catalogIdURL,
            });
            if (response?.error === false) {
              setDel(false);
              safePush(`/dashboard/${businessId}/Catalog`, history, location);
            }
          }}
        />
        <GeneralDialog
          open={status}
          close={setStatus}
          title={`${catalog.isActive ? "Deactivate" : "Activate"} Catalog`}
          text={`Are you sure you want to ${
            catalog.isActive ? "deactivate" : "activate"
          } this catalog?`}
          action={async () => {
            const response = await withLoading(editCatalogStatus, {
              businessId: businessId,
              catalogId: catalogIdURL,
              isActive: !catalog.isActive,
            });
            if (response?.error === false) {
              setStatus(false);
              fetchCatalogDetails();
            }
          }}
        />
        <GeneralDialog
          open={unassignOptions}
          close={setUnassignOptions}
          title="Remove Pricing Options From Catalog"
          text="Are you sure you want to remove the selected pricing options from this catalog"
          action={async () => {
            const response = await withLoading(detachOptionsFromCatalog, {
              businessId: businessId,
              catalogId: catalogIdURL,
              optionIds: checkedOptions,
            });
            if (response?.error === false) {
              setCheckedOptions([]);
              setUnassignOptions(false);
              fetchCatalogDetails();
            }
          }}
        />

        <CatalogPricingGroupForm
          addPG
          open={assignGroups}
          setOpen={setAssignGroups}
          catalog={catalog}
          businessId={businessId}
          refetch={fetchCatalogDetails}
          priceGroups={priceGroups}
        />

        <CatalogPricingGroupForm
          open={unassignGroups}
          setOpen={setUnassignGroups}
          catalog={catalog}
          businessId={businessId}
          refetch={fetchCatalogDetails}
          priceGroups={priceGroups}
        />

        <CatalogPricingOptionForm
          open={assignOptions}
          setOpen={setAssignOptions}
          catalog={catalog}
          businessId={businessId}
          refetch={fetchCatalogDetails}
          pricingOptions={pricingOptions}
        />

        <Grid
          container
          className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
        >
          <Grid item xs={12}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>CATALOG</span>
              <span className={classes.rightElem}>
                <Button
                  onClick={(event) => {
                    setAnchorEl(event.target);
                  }}
                  className={classes.buttonBasic}
                >
                  <CEllipsisIcon />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => {
                    setAnchorEl(false);
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setStatus(true);
                      setAnchorEl(false);
                    }}
                  >
                    {`${catalog.isActive ? "Deactivate" : "Activate"} Catalog`}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setEdit(true);
                      setAnchorEl(false);
                    }}
                  >
                    Edit Catalog
                  </MenuItem>
                  {catalog.isActive ? null : (
                    <MenuItem
                      onClick={() => {
                        setDel(true);
                        setAnchorEl(false);
                      }}
                    >
                      Delete Catalog
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      setAssignGroups(true);
                      setAnchorEl(false);
                    }}
                  >
                    Add Price Group to Catalog
                  </MenuItem>
                  {catalog?.priceGroups?.length > 0 ? (
                    <MenuItem
                      onClick={() => {
                        setUnassignGroups(true);
                        setAnchorEl(false);
                      }}
                    >
                      Remove Price Group from Catalog
                    </MenuItem>
                  ) : null}
                </Menu>
              </span>
            </div>
          </Grid>
          <Grid
            item
            style={bigEnough ? { maxWidth: "50%" } : { width: "100%" }}
          >
            <div className={classes.headerPadding}>
              <LightTooltip title={catalog.name} arrow>
                <Typography
                  className={
                    bigEnough
                      ? classes.topHeadingOne
                      : classes.mobileTopHeadingOne
                  }
                  noWrap
                >
                  {catalog.name}
                </Typography>
              </LightTooltip>
            </div>
          </Grid>
          {bigEnough ? (
            <Grid item>
              <div
                className={classes.headerPadding}
                style={{ paddingLeft: "20px" }}
              >
                <span className={classes.topHeadingFour}>
                  <StatusTag
                    wrapperName={
                      catalog.isActive ? "greenWrapper" : "redWrapper"
                    }
                    value={catalog.isActive ? "Active" : "Inactive"}
                  />
                </span>
              </div>
            </Grid>
          ) : (
            <Grid item>
              <div style={{ marginBottom: "10px" }}>
                <StatusTag
                  wrapperName={catalog.isActive ? "greenWrapper" : "redWrapper"}
                  value={catalog.isActive ? "Active" : "Inactive"}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={12} className={classes.headingWrapper}>
            <Typography
              className={
                bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
              }
              style={{ marginTop: "10px" }}
            >
              Description
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <Typography className={classes.valueText}>
                {catalog.description}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.headingWrapper}>
            <Grid container>
              <Grid item style={{ flexGrow: 1 }}>
                <Typography
                  className={
                    bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                  }
                >
                  Pricing Options
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (checkedOptions.length > 0) {
                      setUnassignOptions(true);
                    } else {
                      setAssignOptions(true);
                    }
                  }}
                >
                  {checkedOptions.length === 0 ? <CAddIcon /> : <CCloseIcon />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {catalog.options.length > 0 ? (
                <ListTable
                  styling={{ maxHeight: 350, width: "100%" }}
                  identifier={"options"}
                  tableHeaders={pricingOptionHeaders}
                  tableRows={catalog.options.map((option, index) => ({
                    action: (
                      <Checkbox
                        color="primary"
                        checked={checkedOptions.includes(option.optionId)}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!checkedOptions.includes(option.optionId)) {
                            setCheckedOptions([
                              ...checkedOptions,
                              option.optionId,
                            ]);
                          } else {
                            setCheckedOptions(
                              checkedOptions.filter(
                                (optionId) => optionId !== option.optionId
                              )
                            );
                          }
                        }}
                      />
                    ),
                    id: option.optionId,
                    name: option.optionName,
                    service: option.serviceName,
                    site: option.siteName,
                  }))}
                  rowClick={(id) =>
                    safePush(
                      `/dashboard/${businessId}/Pricings/${id}`,
                      history,
                      location
                    )
                  }
                />
              ) : (
                <Typography>
                  There are no pricing options associated with this catalog
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default CatalogDetails;
