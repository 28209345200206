import { Container, Grid, IconButton, makeStyles, Typography, useMediaQuery } from "@material-ui/core"
import PublishIcon from "@material-ui/icons/Publish"
import axios from 'axios'
import React, { useState, forwardRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { editAttendee, signedCustomerImageUrlUpload, withLoading } from '../../utils'
import CButtonPair from '../Form/CButtonPair'
import CModal from '../Form/CModal'
import CModalError from '../Form/CModalError'
import CTitle from '../Form/CTitle'
import LightTooltip from "../Form/LightTooltip"
import GeneralDialog from "../GeneralDialog/GeneralDialog"
import { CCloseIcon } from "../Icons/Icons"
import TwoColumn from "../TwoColumn/TwoColumn"
import { extractDate } from "../../FormValidation/validDate"

// Key, label
const rowsToDisplay = [
    ['attendeeNumber', 'Attendee ID'],
    ['email', 'Email'],
    ['phoneNumber', 'Phone'],
    ['dob', 'Date of Birth'],
    ['gender', 'Gender'],
    ['relation', 'Relation'],
    ['customerDetails', 'Customer']
]

const processDetails = (attendeeDetails, classes, buisnessId) => {
    const values = []
    const labels = []

    rowsToDisplay.forEach(([key, label]) => {
        if (attendeeDetails[key] && key === 'customerDetails' && buisnessId) {
            values.push(

                <Link to={`/dashboard/${buisnessId}/Customers/${attendeeDetails.customerDetails.customerId}`} className={classes.linkText}>
                    <LightTooltip arrow title={`${attendeeDetails.customerDetails.firstName} ${attendeeDetails.customerDetails.lastName}`}>
                        <Typography noWrap className={classes.linkText}>
                            {attendeeDetails.customerDetails.firstName} {attendeeDetails.customerDetails.lastName}
                        </Typography>
                    </LightTooltip>
                </Link>

            )
            labels.push(
                <Typography arrow noWrap className={classes.labelText}>
                    {label}
                </Typography>
            )
        }
        else if (key !== 'customerDetails') {
            values.push(
                <LightTooltip arrow title={attendeeDetails[key]}>
                    <Typography noWrap className={classes.valueText}>
                        {attendeeDetails[key] && key === 'dob' ?
                            extractDate(attendeeDetails.dob).toLocaleDateString('en-US') :
                            attendeeDetails[key] ?? 'Not Provided'}
                    </Typography>
                </LightTooltip>
            )
            labels.push(
                <Typography arrow noWrap className={classes.labelText}>
                    {label}
                </Typography>
            )
        }

    })

    return { values, labels }
}

const useStyles = makeStyles(theme => ({
    image: {
        maxWidth: 'calc(100% - 40px)',
        maxHeight: 'calc(100% - 40px)',
        height: 'auto',
        width: 'auto',
        margin: '20px',
    },
    labelText: {
        fontSize: "16px",
        color: "#697386",
    },
    valueText: {
        fontSize: '16px',
    },
    linkText: {
        fontSize: '16px',
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px"
    }
}))

const clearImage = async (attendeeDetails, setOpen, refetchDetails) => {
    const clearResponse = await withLoading(editAttendee, {
        ...attendeeDetails,
        image: null
    })
    if (clearResponse && !clearResponse.error) {
        setOpen(false)
        refetchDetails()
    }
}

const handleImage = (file, setImageObject, setAddImageOpen) => {
    if (file.target.files && file.target.files[0] && file.target.files[0].type && file.target.files[0].type.includes('image')) {
        setImageObject({
            name: file.target.files[0].name,
            data: file.target.files[0],
            localUrl: URL.createObjectURL(file.target.files[0])
        })
        setAddImageOpen(true)
    };
}

const uploadImage = async (imageObject, preSignedURL) => {
    try {
        let s3url = preSignedURL.signedData.url;
        let fields = preSignedURL.signedData.fields;
        let formData = new FormData();

        for (let k in fields) {
            formData.append(k, fields[k]);
        };

        formData.append('file', imageObject.data);


        await axios.post(s3url, formData);
        return {
            error: false,
            errorMessage: null
        }
    }
    catch (e) {
        return {
            error: true,
            errorMessage: e?.message ?? "There was an issue while uploading the image."
        }
    }
}

const addImage = async ({ imageObject, setOpen, refetchDetails, dispatch, attendeeDetails }) => {
    const preSignedURL = await signedCustomerImageUrlUpload({ fileName: imageObject.name })
    // Only continue if got preSignedURL. This will already prompt error message.
    if (!preSignedURL || preSignedURL.error) {
        return
    }

    const uploadRespone = await uploadImage(imageObject, preSignedURL)
    // Only continue if image got uploaded. This requires manually setting error message.
    if (!uploadRespone || uploadRespone.error) {
        dispatch({
            type: "UPDATE",
            payload: {
                ...uploadRespone
            },
        });
        return
    }

    const addResponse = await editAttendee({
        ...attendeeDetails,
        image: preSignedURL.imageURL
    })

    if (addResponse && !addResponse.error) {
        setOpen(false)
        refetchDetails()
    }
}

const AddImageForm = (props) => {
    const { open, setOpen, imageObject, refetchDetails, attendeeDetails } = props
    const classes = useStyles()
    const dispatch = useDispatch()

    return (
        <CModal open={open} setOpen={setOpen}>
            <Container className={classes.formContainer}>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <CTitle title={"Update Attendee Image"} />

                    </Grid>
                    <Grid item xs={12}>
                        <CModalError />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container style={{ width: '100%' }} justifyContent="center">
                            <img src={imageObject?.localUrl} style={{ maxWidth: "min(100%,400px)" }} />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <CButtonPair
                            cancelEnabled
                            cancelAction={() => { setOpen(false) }}
                            submitText="Submit Image"
                            action={async () => {
                                await withLoading(addImage, { imageObject, setOpen, refetchDetails, dispatch, attendeeDetails })
                            }}
                        />
                    </Grid>
                </Grid>

            </Container>
        </CModal>
    )
}


const AttendeeDetailsTopBlock = forwardRef ((props, ref) => {
    const { attendeeDetails, basePath, admin, refetchDetails } = props
    const buisnessId = useSelector((state) => { return (state.buisnessDetails?.businessId) })
    const classes = useStyles()
    const bigEnough = useMediaQuery("(min-width:750px )");

    const [clearImageOpen, setClearImageOpen] = useState(false)
    const [imageObject, setImageObject] = useState(null)
    const [addImageOpen, setAddImageOpen] = useState(false)
    if (attendeeDetails) {
        const { values, labels } = processDetails(attendeeDetails, classes, buisnessId)
        return (
            <Grid item xs={12} ref={ref}>
                <Grid container>
                    <Grid item style={{ width: bigEnough ? '375px' : '100%' }}>
                        <Grid container style={{ height: '100%', position: 'relative' }} justifyContent='center'>
                            <img src={attendeeDetails.image} className={classes.image} />
                            {admin ? null :
                                <div style={{ position: 'absolute', right: '30px', top: '30px' }}>
                                    <AddImageForm
                                        imageObject={imageObject}
                                        setImageObject={setImageObject}
                                        refetchDetails={refetchDetails}
                                        attendeeDetails={attendeeDetails}
                                        open={addImageOpen}
                                        setOpen={setAddImageOpen}
                                    />
                                    <input
                                        accept="image/*"
                                        onChange={(file) => { handleImage(file, setImageObject, setAddImageOpen) }}
                                        style={{ display: 'none' }}
                                        id="contained-button-file"
                                        type="file"
                                    />
                                    <label htmlFor="contained-button-file">
                                        <IconButton component="span" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
                                            <PublishIcon />
                                        </IconButton>
                                    </label>

                                    {
                                        !attendeeDetails.isDefaultImage ?
                                            <React.Fragment>
                                                <IconButton onClick={() => { setClearImageOpen(true) }} component="span" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)", marginLeft: '10px' }}>
                                                    <CCloseIcon />
                                                </IconButton>
                                                <GeneralDialog
                                                    open={clearImageOpen}
                                                    close={setClearImageOpen}
                                                    title="Remove Attendee Image"
                                                    text="Are you sure you want to remove this attendee's image?"
                                                    action={() => { clearImage(attendeeDetails, setClearImageOpen, refetchDetails) }}
                                                />
                                            </React.Fragment>
                                            : null
                                    }
                                </div>
                            }
                        </Grid>
                    </Grid>
                    <Grid item style={{ width: bigEnough ? 'calc(100% - 375px)' : '100%', }}>
                        <Grid container>
                            <Grid item xs={12} style={{ paddingLeft: bigEnough ? '0px' : '20px', paddingRight: bigEnough ? '0px' : '20px' }}>
                                <LightTooltip arrow title={`${attendeeDetails.firstName} ${attendeeDetails.lastName}`}>
                                    <Typography variant={bigEnough ? "h4" : "h5"} noWrap gutterBottom style={{ marginTop: bigEnough ? "20px" : "0px" }}>
                                        {attendeeDetails.firstName} {attendeeDetails.lastName}
                                    </Typography>
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} style={{ paddingLeft: bigEnough ? '0px' : '20px', paddingRight: bigEnough ? '0px' : '20px' }}>
                                <TwoColumn
                                    extProps={{
                                        wrap: 'nowrap',
                                        style: {
                                            width: "100%"
                                        },
                                        spacing: 0
                                    }}
                                    firstItemProps={{
                                        style: {
                                            width: '130px'
                                        }
                                    }}
                                    secondItemProps={{
                                        style: {
                                            width: 'calc(100% - 130px)'
                                        }
                                    }}
                                    first={labels}
                                    second={values}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    else {
        return null
    }
});

export default AttendeeDetailsTopBlock