import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Form from '../../../../../Form/Form'
import CModal from '../../../../../Form/CModal'
import { Container } from '@material-ui/core';
import { makeStyles} from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import { addQuestion,  editQuestion} from '../../../../../../utils';
import maxTextField from '../../../../../../FormValidation/maxTextField';

const useStyles = makeStyles((theme)=>({
    formContainer:{
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width:"100%",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))

export const questionTypes = [
    ["Text", "text"],
    ["Single Select", "singleSelect"],
    ["Multi Select", "multiSelect"],
    ["Date", "date"],
    ["Number", "number"],
    ["Confirm", "confirm"],
    ["Yes/No","yes/no"]
]

const ManageQuestionForm = (props) => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()
    const history = useHistory();
    const [type, setType] = useState(null)

    useEffect(()=>{
        if(props.open){
            setType(props.edit?props.questionDetails.type:null)
        }
    },[props.open])


    const redirect = (success) => {
        if (success) {
            history.push(`${props.basePath}/${success.questionId}`);
        }
    }

    const refresh = (success) => {
        if (success) {
            props.setOpen(false)
            props.refresh()
        }
    }
    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main"  className = {classes.formContainer}>
                <Form
                    moreData = {{businessId:businessId, questionId: (props.edit?props.questionDetails.questionId:null)}}
                    title={`${(props.edit?"Edit":"Add")} Question`}
                    cancelEnabled
                    cancelAction = {()=>{props.setOpen(false)}}
                    submitText={`${(props.edit?"Save":"Add")}`}
                    action={(props.edit?editQuestion:addQuestion)}
                    postAction = {(props.edit?refresh:redirect)}
                    inputFields={[
                        {
                            type:"TextField",
                            name: "question",
                            label: "Question",
                            validator: [notEmpty,noWhiteSpace, maxTextField],
                            initialvalue: (props.edit?props.questionDetails.question:null)
                        },
                        {
                            type:"SingleSelect",
                            name: "type",
                            label: "Question Type",
                            validator: [(value)=>setType(value),(value)=>(!value?"Choose a type":null)],
                            choices:questionTypes,
                            initialvalue: (props.edit?props.questionDetails.type:null)
                        },
                        {
                            type: "Number",
                            name:"minValue",
                            label:"Min Value",
                            size:{
                                xs:6
                            },
                            enabled: type === "number",
                            initialvalue:(props.edit && type === "number"?props.questionDetails.minValue:null)
                        },
                        {
                            type: "Number",
                            name:"maxValue",
                            label:"Max Value",
                            size:{
                                xs:6
                            },
                            enabled: type === "number",
                            initialvalue:(props.edit && type === "number"?props.questionDetails.maxValue:null)
                        },
                        {
                            type: "MultiField",
                            enabled: ["multiSelect", "singleSelect"].includes(type),
                            name:"options",
                            validator:(value)=>{
                                const errors = []
                                const checks = [noWhiteSpace,notEmpty,maxTextField]
                                value.forEach((val)=>{
                                    let err = null
                                    for(let i = 0 ; i < checks.length; i++){
                                        err = checks[i](val)
                                        if(err){
                                            break;
                                        }
                                    }
                                    errors.push(err)
                                })
                                return(errors)
                            },
                            initialvalue: (props.edit&& ["multiSelect", "singleSelect"].includes(type)?props.questionDetails.options:null)
                        }


                    ]}
                />
            </Container>
        </CModal>
    );
};

export default ManageQuestionForm