import React, { useState, useEffect } from "react";
import Form from "../../../../../Form/Form";
import CModal from "../../../../../Form/CModal";
import { makeStyles } from "@material-ui/styles";
import { Container, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { emailPOMassCustomers, getGenericDetails, withLoading } from "../../../../../../utils";
import moment from "moment";
import maxTextArea from "../../../../../../FormValidation/maxTextArea";
import noWhiteSpace from "../../../../../../FormValidation/noWhiteSpace";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    width: "600px",
    maxWidth: "100vw",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  caution: {
    fontSize: "12px",
    color: "red",
    marginTop: "10px",
  },
}));

const EmailCustomerMassForm = (props) => {
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const classes = useStyles();
  const refresh = (success) => {
    if (success) {
      props.setOpen(false);
    }
  };

  const [optionList, setOptionList] = useState([])

  const setUp = async () => {
    let data = await withLoading(getGenericDetails, { businessId: businessId })
    if (data && data.genericDetails) {
        let tempOptionList = []
        data.genericDetails.pricingOptionDetails.forEach((option) => {
          if(option.isActive){
            tempOptionList.push([option.name, option.optionId])
          };    
        })
        setOptionList(tempOptionList)
    }
}

useEffect(() => {
  if (props.open) {
      setOptionList([])

      setUp()
  }

}, [props.open])


  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Container component="main" className={classes.formContainer}>
        <Form
          moreData={{
            businessId: businessId,
          }}
          title="Email Customers"
          cancelEnabled
          cancelAction={() => {
            props.setOpen(false);
          }}
          submitText="Yes"
          cancelText="No"
          action={emailPOMassCustomers}
          postAction={refresh}
          inputFields={[
            {
              type: "MultiSelect",
              name: "optionIds",
              label: "Pricing Options",
              choices: optionList,
              validator: (value)=>(value.length === 0?"Choose at least one pricing option":null)
            },
            {
              type: "TextArea",
              name: "emailMessage",
              label: "Message Body",
              moreProps: {
                rowsMin: 15,
                maxRows: 20,
              },
              resizeEnabled: true,
              validator: [noWhiteSpace, maxTextArea, notEmpty],
              initialvalue: "",
            },   
          ]}
        />
        
      </Container>
    </CModal>
  );
};

export default EmailCustomerMassForm;
