import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Form from "../../../../../Form/Form";
import CModal from "../../../../../Form/CModal";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import noWhiteSpace from "../../../../../../FormValidation/noWhiteSpace";
import { addSite } from "../../../../../../utils";
import maxTextField from "../../../../../../FormValidation/maxTextField";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
    width: "100%",
    maxHeight: "80vh",
    overflowY: "auto",
  },
}));

const validChoices = (choices) => {
  if (Array.isArray(choices)) {
    if (choices.length !== 0) {
      choices.forEach((choice) => {
        if (!Array.isArray(choice) || choice.length !== 2) {
          return false;
        }
      });

      return true;
    } else {
      return true;
    }
  }
  return false;
};

const AddSiteForm = (props) => {
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const redirect = (success) => {
    if (success) {
      history.push(`${props.basePath}/${success.siteId}`);
    }
  };
  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Container component="main" className={classes.formContainer}>
        <Form
          moreData={{ businessId: businessId }}
          title="Add Site"
          cancelEnabled
          cancelAction={() => {
            props.setOpen(false);
          }}
          submitText="Create"
          action={async (payload) => {
            const newPayload = {
              ...payload,
              address2: payload.address2 === "" ? null : payload.address2,
              publicName: payload.publicName === "" ? null : payload.publicName,
            };
            return await addSite(newPayload);
          }}
          postAction={redirect}
          inputFields={[
            {
              type: "TextField",
              name: "name",
              label: "Site Name",
              validator: [notEmpty, noWhiteSpace, maxTextField],
            },

            {
              label: "Address 1",
              type: "AddressSearch",
              name: ["address1", "geoPoint"],
              validator: (value) => {
                if (!value[0] || !value[1].lat || !value[1].lon) {
                  return "Enter valid address";
                }
              },
            },
            {
              type: "TextField",
              name: "address2",
              label: "Address 2 (optional)",
              validator: [noWhiteSpace, maxTextField],
            },
            {
              type: "TextField",
              name: "publicName",
              label: "Public site name (optional)",
              validator: [noWhiteSpace, maxTextField],
            },
          ]}
        />
      </Container>
    </CModal>
  );
};

export default AddSiteForm;
