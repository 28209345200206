import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import CreditList from './CreditList';
import CreditBusinessList from './CreditBusinessList';

const CreditHandler = () => {
    const basePath = `/account/Credits`
    return (
        <Switch>
            <Route path={basePath} exact render={() => { return (<CreditList basePath={basePath} />) }} />
            <Route path={`${basePath}/:businessIdURL`} render={() => { return (<CreditBusinessList />); }} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
        </Switch>
    );
}

export default CreditHandler;