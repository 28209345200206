import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import Package from "./Package";
import PackageList from "./PackageList";

function PackagesHandler() {
    const basePath = "/account/Packages-and-Passes"
    return(
        <React.Fragment>
            <Switch>
            <Route path = {basePath} exact render = {() => {return(<PackageList />)}} />
            <Route path = {`${basePath}/:packIdURL`} render = {() => {return(<Package />);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default PackagesHandler;