import React, { useEffect, useState, useRef } from "react";
import { Typography, Grid, makeStyles, Checkbox, RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import { withLoading, customerCards, checkoutCard, safeQuery } from "../../utils";
import { Button, Select, MenuItem, } from "@material-ui/core";
import NewPayment from "./NewPayment";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import CheckIcon from "@material-ui/icons/Check"
const useStyles = makeStyles(theme => ({
    cardHolder: {
        maxWidth: 350,
        width: "100vh",
        padding: 8
    },
    comp:
    {
        width: "100%"
    },
    formLabel: {
        fontSize: 14,
        color: "#3C4257"
    },
    fieldText: {
        fontSize: 14,
        color: "#1A1F36",
        padding: "10px",
        paddingLeft: "31px"
    },
    showChoice: {
        fontSize: 14,
        color: "#1A1F36",
        padding: "10px",
    },
    helperText: {
        marginRight: 14,
        marginLeft: 14
    },
    selected: {
        fontSize: 14,
        color: theme.palette.primary.main,
        padding: "10px"
    },
    check: {
        fontSize: 14,
        paddingRight: 7
    },
    menuPaper: {
        maxHeight: 220,
    },
    noPadding: {
        padding: 0
    }
}))

const numToString = (num) => {
    switch (num) {
        case 2:
            return 'two'
        case 3:
            return 'three'
        case 4:
            return 'four'
        default:
            return ''
    }
}

const formatFrequency = (frequency) => {

    switch (frequency) {
        case '1 Week':
            return 'every week'
        case '2 Week':
            return 'every two weeks'
        case '1 Month':
            return 'every month'
        default:
            return ''
    }
}

const PaymentChoice = (props) => {
    const classes = useStyles()
    const { customerCard, setCustomerCard, setNewCustomerCard, setErrors, optionDetails, installment, setInstallment } = props
    const [methodsList, setMethodsList] = useState(null);
    const [width, setWidth] = useState(null)
    const comp = useRef(null);
    const [open, setOpen] = useState(false)

    const getMethodsDetails = async () => {
        let methodsPayload = await withLoading(customerCards, {}, true)
        if (methodsPayload && methodsPayload.cardDetails) {
            const cardList = methodsPayload.cardDetails.map(card => {
                return [<span><b>{card.brand}</b>{` ending in ${card.last4}`}</span>,

                {
                    primary: card.primary,
                    brand: card.brand,
                    cardId: card.cardId,
                    last4: `${card.last4}`,
                }]
            })

            cardList.push([<b>New Card</b>, { newCard: true }])
            cardList.forEach(card => {
                if (card[1].primary) {
                    setCustomerCard(card[1])
                }
            })
            if (cardList.length === 1) {
                setCustomerCard({ newCard: true })

            }
            setMethodsList(cardList);
        };
    };

    useEffect(() => {
        if (setCustomerCard) {
            getMethodsDetails()
        }
    }, [setCustomerCard]);

    useEffect(() => {
        if (comp.current) {
            setWidth(comp.current.clientWidth)
        }
    }, [comp.current])
    const intoInputProps = (props.moreProps && props.moreProps.inputProps ? props.moreProps.inputProps : {})
    intoInputProps.className = classes.showChoice
    const otherProps = { ...props.moreProps, inputProps: intoInputProps }
    if (props.enabled === false) {
        return null
    }
    else {
        return (
            <div style={{ maxWidth: 500, width: "100%" }}>

                {
                    Array.isArray(methodsList) && methodsList.length > 1
                        ?
                        <Grid container style={{ padding: 8, }} spacing={2}>
                            <Grid item xs={12}>
                                <div className={classes.comp} ref={comp}>
                                    <Typography className={classes.formLabel}>
                                        Card Choice
                                    </Typography>

                                    <Select
                                        open={open}
                                        onOpen={() => { setOpen(true) }}
                                        onClose={() => { setOpen(false) }}
                                        IconComponent={(props) => {
                                            return (
                                                <ArrowDropDownCircleIcon {...props} style={(open ? { color: "#0079FC" } : null)} />
                                            )
                                        }}
                                        error={(typeof props.errortext == "string" && props.errortext !== "" ? true : false)}
                                        className={classes.comp}
                                        variant="outlined"
                                        onChange={(event) => {
                                            setCustomerCard(event.target.value)
                                        }}
                                        MenuProps={{
                                            classes: { paper: classes.menuPaper },
                                            getContentAnchorEl: null,
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                                            transformOrigin: { vertical: 'top', horizontal: 'center' }
                                        }}
                                        renderValue={(selected) => {
                                            if (selected) {
                                                if (selected.cardId) {
                                                    return <span><b>{selected.brand}</b>{` ending in ${selected.last4}`}</span>
                                                }
                                                else if (selected.newCard === true) {
                                                    return <b>New Card</b>
                                                }
                                            }

                                            else {
                                                return ""
                                            }
                                        }}
                                        value={customerCard}
                                        inputProps={{
                                            ...intoInputProps
                                        }}
                                        {...otherProps}
                                        ref={props.toInput}
                                    >
                                        {
                                            (Array.isArray(methodsList) ? methodsList : []).map((pair, index) => {
                                                return (
                                                    <MenuItem
                                                        key={`${props.name}-${index}`}
                                                        value={pair[1]}
                                                        style={{ maxWidth: width, display: "block !important" }}
                                                        classes={{
                                                            root: classes.noPadding
                                                        }}
                                                    >
                                                        <Typography noWrap className={(false ? classes.selected : classes.fieldText)}>
                                                            {(false ? <CheckIcon className={classes.check} /> : null)}
                                                            {pair[0]}
                                                        </Typography>
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </Select>
                                </div >
                            </Grid>
                        </Grid>
                        : null
                }


                {
                    customerCard && customerCard.newCard ?
                        <NewPayment setNewCustomerCard={setNewCustomerCard} setErrors={setErrors} installment={installment} />
                        : null
                }
                {
                    optionDetails.installmentEnabled ?
                        <div style={{
                            padding: 8,
                            paddingTop: 0,
                        }}>
                            <Typography className={classes.formLabel}>
                                Payment Option
                            </Typography>
                            <div style={{
                                paddingLeft: 8,
                                border: "1px solid #dadada",
                                boxSizing: "border-box",
                                borderRadius: 4,
                            }}>


                                <Typography className={classes.fieldText} style={{ padding: 0 }}>
                                    <RadioGroup
                                        value={installment}
                                        onChange={event => { setInstallment(event.target.value === "true" ? true : false) }}
                                    >
                                        <FormControlLabel value={false} control={<Radio color="primary" />} label="Pay in full" />
                                        <FormControlLabel value={true} control={<Radio color="primary" />} label={
                                            `Pay in ${numToString(optionDetails.installmentCount)} installments, made once ${formatFrequency(optionDetails.installmentFrequency)}`} />
                                    </RadioGroup>



                                </Typography>
                            </div>
                        </div>
                        : null
                }
            </div>
        );
    }
}

export default PaymentChoice