import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Form from '../../../../../Form/Form'
import CModal from '../../../../../Form/CModal'
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import positive from '../../../../../../FormValidation/positive';
import maxTextField from '../../../../../../FormValidation/maxTextField';
import { editPricingOption } from '../../../../../../utils';
import startAfterEnd from '../../../../../../FormValidation/startAfterEnd';
import { futureDate } from '../../../../../../FormValidation/validDate'
const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))


const EditPricingForm = (props) => {
    const cancelEnabledRef = useRef(props.optionDetails.optionSettings.cancelAllowed)
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const [cancelEnabled, setCancledEnabled] = useState(props.optionDetails.optionSettings.cancelAllowed)
    const [pricingType, setPricingType] = useState(props.optionDetails.type)
    const [sellEndDate, setSellEndDate] = useState(props.optionDetails.type === "Season Pass" && props.optionDetails.optionSettings.scheduleType === "Default" ? props.optionDetails.optionSettings.sellEndDate : null)
    const classes = useStyles()
    const [validityType, setValidityType] = useState(props.optionDetails.type === "Pack" ? props.optionDetails.validityUnit : null)
    const [scheduleType, setSchedulingType] = useState(props.optionDetails.type === "Season Pass" ? props.optionDetails.optionSettings.scheduleType : null)



    if (cancelEnabled === null || pricingType === null) {
        return (null)
    }
    else {
        return (
            <CModal open={props.open} setOpen={props.setOpen}>
                <Container component="main" className={classes.formContainer}>
                    <Form
                        moreData={{
                            businessId: businessId,
                            optionId: props.optionDetails.optionId
                        }}
                        title="Edit Pricing"
                        cancelEnabled

                        cancelAction={() => { props.setOpen(false) }}
                        submitText="Save"
                        action={editPricingOption}
                        postAction={(result) => {
                            if (result) {
                                props.setOpen(false)
                                props.refresh()
                            }
                        }}
                        inputFields={[
                            {
                                type: "TextField",
                                name: "name",
                                label: "Name",
                                validator: [notEmpty, noWhiteSpace, maxTextField],
                                initialvalue: props.optionDetails.name,
                            },
                            {
                                type: "Currency",
                                name: "price",
                                label: "Price",
                                currencyInfo: props.currencyInfo,
                                initialvalue: props.optionDetails.price,
                                validator: positive,
                                size: {
                                    xs: 12
                                }
                            },
                            {
                                type: "Percent",
                                name: "maxDiscount",
                                label: "Max discount applicable",
                                initialvalue: props.optionDetails.maxDiscount,
                                numberProps: {
                                    decimalScale: 2
                                },
                                validator: [
                                    value => value && value > 99.99 ? "Max discount must be less than 100%" : null,
                                    value => value && value < 0.01 ? "Max discount must be more than 0%" : null
                                ],
                                size: {
                                    xs: 6
                                }
                            },
                            {
                                type: "Percent",
                                name: "transactionFee",
                                label: "Transaction Fee",
                                initialvalue: props.optionDetails.transactionFee,
                                numberProps: {
                                    decimalScale: 2
                                },
                                validator: [(value) => (value < 0 ? "Must be non-zero" : null), (value) => (value > 100 ? "Cannot be greater than 100%" : null)],
                                size: {
                                    xs: 6
                                }

                            },
                            {
                                type: "CheckBox",
                                name: "cancelAllowed",
                                label: "Allow Session Cancelation by Customer",
                                enabled: pricingType === "Season Pass" && scheduleType === "Default",
                                initialvalue: cancelEnabled,
                                validator: (value) => { setCancledEnabled(value); cancelEnabledRef.current = value },
                                padding: {
                                    top: 0
                                }
                            },
                            {
                                type: "Number",
                                name: "cancelDeadline",
                                initialvalue: (cancelEnabled ? props.optionDetails.optionSettings.cancelDeadline : 0),
                                label: "Customer Session Cancellation Deadline (hours)",
                                numberProps: {
                                    decimalScale: 0
                                },
                                enabled: pricingType === "Season Pass" && scheduleType === "Default" && cancelEnabled,
                                validator: value => isNaN(value) || value < 0 ? "Must be greater than or equal to zero" : null
                            }
                            ,
                            {
                                type: "Number",
                                label: "Booking Window (days from date of booking)",
                                name: "advanceBookingWindow",
                                enabled: pricingType === "Unit",
                                initialvalue: (pricingType === "Unit" ? props.optionDetails.optionSettings.advanceBookingWindow : 90),
                                validator: value => (value < 0 || value > 365 || isNaN(value) ? "Must be between 0 to 365 days" : null)
                            },

                            {
                                type: "Number",
                                name: "sessionCount",
                                label: "Number of Sessions",
                                numberProps: {
                                    decimalScale: 0
                                },
                                initialvalue: (pricingType === "Pack" || pricingType === "Season Pass" ? props.optionDetails.optionSettings.sessionCount : null),
                                enabled: pricingType === "Pack" || pricingType === "Season Pass",
                                validator: (value) => { if ((value < 0 || value > 1000) || isNaN(value)) { return ("value must be within 0 aand 1,000") } }

                            },
                            {
                                type: "Number",
                                name: "validity",
                                label: "Validity",
                                validator: positive,
                                size: {
                                    xs: 6
                                },
                                initialvalue: (pricingType === "Pack" ? props.optionDetails.optionSettings.validity : null),
                                numberProps: {
                                    decimalScale: 0
                                },
                                enabled: pricingType === "Pack",
                            },
                            {
                                type: "SingleSelect",
                                name: "validityUnit",
                                label: "Units",
                                size: {
                                    xs: 6
                                },
                                choices: [
                                    ['Days', 'days'],
                                    ['Months', 'months']
                                ],
                                enabled: pricingType === "Pack",
                                validator: [(value) => { setValidityType(value) }, (value) => (!value ? "Must choose a type" : null)],
                                initialvalue: (pricingType === "Pack" ? props.optionDetails.optionSettings.validityUnit : null),
                            },
                            {
                                type: "SingleSelect",
                                name: "scheduleType",
                                label: "Schedule Type",
                                choices: [
                                    ["Default Scheduling", "Default"],
                                    ["Fixed Scheduling", "Fixed"]
                                ],
                                enabled: pricingType === "Season Pass" && props.optionDetails.offeringType === "Group",
                                initialvalue: scheduleType,
                                validator: (value) => {
                                    setSchedulingType(value)
                                    if (value === "Fixed") {
                                        setCancledEnabled(false)
                                    }
                                    else if (cancelEnabledRef.current) {
                                        setCancledEnabled(true)
                                    }
                                }
                            },
                            {
                                type: "DateRange",
                                label: ["Start Date", "End Date"],
                                name: ["startDate", "endDate"],
                                enabled: pricingType === "Season Pass",
                                initialvalue: (pricingType === "Season Pass" ? [props.optionDetails.optionSettings.startDate, props.optionDetails.optionSettings.endDate] : null),
                                validator: [(dates) => {
                                    if (dates[1]) {
                                        const date2 = dates[1]
                                        const [year, month, day] = date2.split('/');
                                        const end = new Date(year, (month - 1) % 12, day)
                                        setSellEndDate(end)
                                        return [null, null]
                                    }

                                }, startAfterEnd]
                            },
                            {
                                type: "Date",
                                label: "Sell End Date",
                                name: "sellEndDate",
                                enabled: pricingType === "Season Pass" && scheduleType === "Default" && sellEndDate,
                                initialvalue: props.optionDetails.optionSettings.sellEndDate,
                                validator: [
                                    futureDate,
                                    (date) => {
                                        if (date) {
                                            const [year, month, day] = date.split('/');
                                            const choice = new Date(year, month - 1, day);
                                            return (!choice || sellEndDate && choice >= sellEndDate) ? "Sell end date must be before season pass ends" : null
                                        }
                                    }
                                ]
                            },


                            {
                                type: "CheckBox",
                                label: "Customer Self Check-in Enabled",
                                name: "customerCheckin",
                                enabled: pricingType === "Season Pass" && scheduleType !== "Fixed",
                                initialvalue: props.optionDetails.optionSettings.customerCheckin,
                                padding: {
                                    top: 0
                                }
                            },
                            {
                                type: "CheckBox",
                                label: "Prorate",
                                name: "proRate",
                                enabled: pricingType === "Season Pass" && scheduleType === "Fixed",
                                initialvalue: props.optionDetails.optionSettings.proRate,
                                padding: {
                                    top: 0
                                }
                            }



                        ]}
                    />
                </Container>
            </CModal>
        );
    }
};

export default EditPricingForm