import React from 'react'
import CNumber from './CNumber'
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles((theme)=>({
    code:{
        color: theme.palette.textColor3.main,
        fontSize:"16px"
    }
}))

const CCurrency = (props) => {
    const classes = useStyles()
    return (
        <CNumber
            {...props}
            initialvalue = {props.initialvalue !== "" ? props.initialvalue/props.currencyInfo.currencyBreak : props.initialvalue}
            numberProps = {{
                decimalScale:props.currencyInfo.roundingFactor
            }}
            moreProps={{...props.moreProps,InputProps:
                {
                    endAdornment:
                        <InputAdornment position="end" >
                            <Typography className = {classes.code}>
                            {props.currencyInfo.currencyShortName}
                            </Typography>
                        </InputAdornment>
                }
            }}
        />
    )
}

export default CCurrency