import React from 'react'
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  comp:
  {
    width: "100%"
  },
  formLabel: {
    fontSize: 14,
    color: theme.palette.textColor2.main
  },
  fieldText: {
    fontSize: 16,
    color: theme.palette.primaryText.main,
    padding: "10px"
  }
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      thousandSeparator={false}
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

const CNumber = (props) => {
  const classes = useStyles();
  const intoInputProps = (props.moreProps && props.moreProps.InputProps ? props.moreProps.InputProps : {})
  intoInputProps.classes = { input: classes.fieldText };
  intoInputProps.inputComponent = NumberFormatCustom

  const otherProps = { ...props.moreProps, InputProps: intoInputProps }
  return (
    <div>
      <Typography className={classes.formLabel}>
        {props.label}
      </Typography>
      <TextField
        autoComplete={(props.autoComplete === "on" ? "on" : "off")}
        error={(typeof props.errortext == "string" && props.errortext !== "" ? true : false)}
        className={classes.comp}
        onChange={props.onchange}
        defaultValue={props.initialvalue}
        helperText={(props.errortext !== "" ? props.errortext : null)}
        variant="outlined"
        name={props.name}
        {...otherProps}
        inputProps={{
          ...props.numberProps
        }}
      ></TextField>
    </div>
  );
}

export default CNumber