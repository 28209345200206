import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory} from 'react-router-dom';
import Form from '../../../../../Form/Form'
import CModal from '../../../../../Form/CModal'
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import maxTextField from '../../../../../../FormValidation/maxTextField';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';

import {  getGenericDetails, addStaffCalender, editStaffCalender, staffUpdateSchedule, staffAddSchedule, staffGenericDetails} from '../../../../../../utils';


const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"

    },

}))




const CalenderForm = (props) => {
    const history =  useHistory()
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const [siteOptions, setSiteOptions] = useState(null)
    const classes = useStyles()
    const dispatch = useDispatch()
    const setUp = async () => {
        let data = null;
        dispatch({
            type: "UPDATE",
            payload: {
                loading: true,
            },
        });
        if (props.role === "Admin") {
            data = await getGenericDetails({
                businessId: businessId
            })
        } else if(props.role === "Staff"){
            data = await staffGenericDetails({
                businessId: businessId
            })
        }
        dispatch({
            type: "UPDATE",
            payload: {
                loading: false,
            },
        });
        if (data && data.genericDetails) {
            setSiteOptions(processSites(data.genericDetails.siteDetails));
        }
    }

    const filterSites = (siteDetails) => {
        const currentSites = new Set()
        const tempSites = []
        if(!props.edit){
            props.calenderList.forEach(calender => {
                currentSites.add(calender.siteId)
            })
            siteDetails.forEach(site=>{
                if(site.isActive && !currentSites.has(site.id)){
                    tempSites.push([site.name, site.id])
                    
                }
            })
        }
        return tempSites
    }   
    
    const processSites = (siteData) => {
        return siteData.map((site) => {
          let {
            name,
            siteId,
            isActive
          } = site;
    
          let id = siteId;
    
          return {
            id, 
            isActive,
            name
          };
        });
      };

    useEffect(() => {
        setUp()
    }, [])

    const redirect = async (response) => {
        if(props.role === "Admin" && response){
            props.setOpen(false)
            history.push(`/dashboard/${businessId}/Staff-Schedules/${props.staffData.staffId}/Schedule/${response.calendarId}`)
        } if (props.role === "Staff" && response) {
            props.setOpen(false)
            history.push(`/dashboard/${businessId}/My-Schedule/${response.calendarId}`)
        }
    }

    const refresh = (response) => {
        if(response){
            props.setOpen(false)
            props.refresh()
        }
    }

    if (siteOptions === null) {
        return (null)
    }
    else {
        return (
            <CModal open={props.open} setOpen={props.setOpen}>
                <Container component="main" className={classes.formContainer}>
                        <Form
                            moreData={
                                props.role === "Admin"  && props.edit ? { businessId: businessId, staffId: props.staffData.staffId, calendarId: props.open.calendarId } 
                            :   props.role === "Admin" && !props.edit ? { businessId: businessId, staffId: props.staffData.staffId } 
                            :   props.role === "Staff" && props.edit ? { businessId: businessId, calendarId: props.open.calendarId } 
                            :   { businessId: businessId }
                            }
                            title={props.role == "Admin" ? `${(props.edit?"Edit":"Add")} Staff Schedule` : `${(props.edit?"Edit":"Add")} Schedule`}
                            cancelEnabled
                            cancelAction={() => { props.setOpen(false) }}
                            submitText={(props.edit?"Save":"Create")}
                            action={(props.role === "Admin" && props.edit ? editStaffCalender : props.role === "Admin" && !props.edit ? addStaffCalender : props.role === "Staff" && props.edit ? staffUpdateSchedule : staffAddSchedule)}
                            postAction={(props.edit?refresh:redirect)}
                            inputFields={[
                                {
                                    type:"TextField",
                                    name: "name",
                                    label: "Schedule Name",
                                    validator: [notEmpty, noWhiteSpace, maxTextField],
                                    initialvalue: (props.edit?props.open.name:null)
                                },
                                {
                                    type: "SingleSelect",
                                    name: "siteId",
                                    label: "Site",
                                    enabled: !Boolean(props.edit),
                                    validator: (value) => (value === null ? "Cannot be empty" : null),
                                    choices: filterSites(siteOptions),
                                    initialvalue: (props.edit?props.open.siteId:null)
                                },
                            ]}
                        />
                </Container>
            </CModal>
        );
    }

};

export default CalenderForm