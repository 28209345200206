import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAttendeeQualification, addCustomerQualification, listQualifications } from '../../../../../../utils';
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    width: "100%",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflowY: "auto"
  }
}))

const generateChoices = (allOptions, serviceDetails, type) => {
  const temp = []
  const currentChosen = new Set()
  serviceDetails[(type === "Customer"?"qualifications":"attendeeQualifications")].forEach((qualification) => {
    currentChosen.add(qualification.qualificationId)
  })
  allOptions.forEach((qualification) => {
    if (!currentChosen.has(qualification.qualificationId) && qualification.isActive && qualification.type === type) {
      temp.push([qualification.name, qualification.qualificationId])
    }
  })
  return (temp)
}

const QualiForm = (props) => {

  const [allQualis, setAllQualis] = useState(null)
  const dispatch = useDispatch()
  const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });



  const setUp = async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let data = await listQualifications({
      businessId: businessId
    })
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (data) {
      setAllQualis(data)
    }
  }

  useEffect(() => {
    if (props.open) {
      setAllQualis([])
      setUp()
    }

  }, [props.open])

  const classes = useStyles()
  const refresh = (success) => {
    if (success) {
      props.setOpen(false)
      props.refresh()
    }
  }
  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Container component="main" className={classes.formContainer}>
        {(allQualis !== null ?
          <Form
            moreData={{ businessId: businessId, serviceId: props.serviceDetails.serviceId }}
            title={`Add ${props.type} Qualifications`}
            cancelEnabled
            cancelAction={() => { props.setOpen(false) }}
            submitText="Add"
            action={props.type === "Customer" ? addCustomerQualification :props.type === "Attendee"? addAttendeeQualification: undefined}
            postAction={refresh}
            inputFields={[
              {
                type: "MultiSelect",
                name: "qualificationIds",
                label: "Qualifications",
                choices: generateChoices(allQualis, props.serviceDetails, props.type),
                validator: (value) => (value.length === 0 ? "Choose at least one qualification" : null)
              }

            ]}
          />
          : null)}
      </Container>
    </CModal>
  )
}

export default QualiForm