import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import Service from './Service';
import ServicesList from './ServicesList';

const ServiceHandler = () => {
    
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const basePath = `/dashboard/${businessId}/Services`
    return(
        <React.Fragment>
            <Switch>
            <Route path = {basePath} exact render = {() => {return(<ServicesList basePath = {basePath} />)}}/>
            <Route path = {`${basePath}/:serviceIdURL`} render = {() => {return(<Service/>);}}/>
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
}

export default ServiceHandler;