import React from 'react'

export const attemptConvertNumber =  (val)=>{
    const newVal = Number(val)
    if(isNaN(newVal)){
        return(undefined)
    }
    else{
        return newVal
    }
}

const filters = [
    {
        key:"dist",
        type:"PairButtons",
        title: "Distance",
        apiKey: "distance",
        dataParser:attemptConvertNumber,
        options:[
            {
                val:undefined,
                label:"Any"
            },
            {
                val:5,
                label:"5 miles"
            },
            {
                val:10,
                label:"10 miles"
            },
            {
                val:15,
                label:"15 miles"
            },
            {
                val:25,
                label:"25 miles"
            },
            {
                val:50,
                label:"50 miles"
            },

        ]
    },
    {
        key:"type",
        type:"PairButtons",
        title: "Session Type",
        apiKey: "serviceType",
        options:[
            {
                val:undefined,
                label:"Any"
            },
            {
                val:"Private",
                label:"Private"
            },
            {
                val:"Group",
                label:"Group"
            },

        ]
        
    },
    {
        key:"mode",
        type:"PairButtons",
        title: "Session Location",
        apiKey:"serviceMode",
        options:[
            {
                val:undefined,
                label:"Any"
            },
            {
                val:"Inperson",
                label:"In Person"
            },
            {
                val:"Online",
                label:"Virtual"
            },

        ]
        
    },
    {
        key:"rat",
        type:"Radio",
        apiKey:"rating",
        title: "Ratings",
        options:[

            {
                val:undefined,
                label:"Any"
            },
            {
                val:"Above4",
                label:"4 stars and up"
            },
            {
                val:"Above3",
                label:"3 stars and up"
            },
            {
                val:"Above2",
                label:"2 stars and up"
            },
            {
                val:"Above1",
                label:"1 stars and up"
            },

        ]
    }
]

export default filters