import React, {useState, useEffect} from 'react'
import Form from "../../../../../Form/Form"
import CModal from "../../../../../Form/CModal";
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import { useDispatch, useSelector} from 'react-redux';
import { getGenericDetails, addHost } from '../../../../../../utils';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    width: "600px",
    maxWidth: "100vw",
    maxHeight: "80vh",
    overflowY: "auto"
  }
}))


const AddHostForm = (props) => {

  const [allHosts, setAllHosts] = useState(null)
  const dispatch = useDispatch()
  const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });

  const generateChoices = (allOptions) =>{
    const temp = []
    const currentChosen = new Set()
    props.hostDetails.forEach((host)=>{
      currentChosen.add(host.hostId)
    })
    allOptions.forEach((host)=>{
      if(!currentChosen.has(host.staffId) && host.isActive){
        temp.push([`${host.firstName} ${host.lastName}`, host.staffId])
      }
    })
    return(temp)
  }

  const setUp = async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let data = await getGenericDetails({
      businessId: businessId
    })
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if(data){
      setAllHosts(data.genericDetails.staffDetails)
    }  
  }

  useEffect(()=>{
    setUp()
  },[])

  const classes = useStyles()
  const refresh = (success) => {
    if (success) {
        props.setOpen(false)
        props.refresh()
    }
}

return(
  <CModal open={props.open} setOpen={props.setOpen}>
          <Container component="main" className={classes.formContainer}>
              {(allHosts !== null?
              <Form
              moreData={{ businessId: businessId, classId: props.classId}}
              title="Add Hosts"
              cancelEnabled
              cancelAction={() => { props.setOpen(false) }}
              submitText="Add"
              action={addHost}
              postAction={refresh}
              inputFields={[
                  {
                      type: "MultiSelect",
                      name: "hostIds",
                      label: "Hosts",
                      choices: generateChoices(allHosts)
                  }

              ]}
          />
          :null)}
          </Container>
      </CModal>
)
}

export default AddHostForm