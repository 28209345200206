import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { deleteWidget, getGenericDetails, listWidgets, updateWidget, widgetStatus } from "../../../../../../utils";
import {
  Grid, Button, CircularProgress,
  Menu, MenuItem, Container, useMediaQuery, Typography
} from "@material-ui/core";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import { sizing } from '@material-ui/system';
import { CEllipsisIcon } from "../../../../../Icons/Icons";
import moment from "moment";
import StatusTag from "./../../../../../TagComponents/StatusTag";
import Form from "../../../../../Form/Form";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import noWhiteSpace from "../../../../../../FormValidation/noWhiteSpace";
import CModal from "../../../../../Form/CModal";
import LightTooltip from "../../../../../Form/LightTooltip";
import { ColorButton } from "material-ui-color";
import { CColor } from '../../../../../Form/CColorPicker';
import CCheckBox from "../../../../../Form/CCheckBox";
import CDisplayArea from "../../../../../Form/CDisplayArea";
import CTitle from "../../../../../Form/CTitle";
import { CCopyIcon } from "../../../../../Form/CCopyIcon";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px"
  },
  headingWrapper: {
    paddingBottom: "10px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  formOne: {
    fontSize: "20px",
    color: "#1a1f36",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
    paddingLeft: "10px",
  },
  fieldColumnOne: {
    width: "100px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  sdCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "25%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px"
  }
}));

const Widget = () => {
  const classes = useStyles();
  const history = useHistory();
  const [_, updateState] = useState(0);
  const dispatch = useDispatch();
  const small = useMediaQuery('(min-width: 750px)');
  const large = useMediaQuery('(min-width: 960px)');
  const [serviceOptions, setServiceOptions] = useState(null);
  const [siteOptions, setSiteOptions] = useState(null);
  const widgetDetails = useSelector((state) => {
    return state.widgetDetails;
  });
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const [eOpen, setEOpen] = useState(false);
  const [dW, setDW] = useState(false);
  const [aDOpen, setADOpen] = useState(false);
  const [tC, setTC] = useState(null);
  const [bC, setBC] = useState(null);
  const [bTC, setBTC] = useState(null);
  const [c, setC] = useState(null);
  const [cH, setCH] = useState(null);
  const [anchorEl, setAnchorEl] = useState(false);
  const handleClose = () => {
    setEOpen(false);
    setADOpen(false);
    setDW(false);
  };
  const handleMenuClose = () => {
    setAnchorEl(false);
  };

  async function handleSubmit(event) {
    dispatch({
      type: 'UPDATE', payload: {
        loading: true
      }
    });
    let success = await updateWidget({
      businessId: businessId,
      widgetId: widgetDetails.widgetId,
      name: event.name,
      optionTypes: event.optionTypes,
      widgetColor: {
        backgroundColor: cH ? "transparent" : c,
        buttonColor: bC,
        textColor: tC,
        buttonTextColor: bTC
      }
    })
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
    if (success) {
      updateState((_) => !_);
      setEOpen(false);
    }
  };
  async function handleClick(event) {

    dispatch({
      type: 'UPDATE', payload: {
        loading: true
      }
    });
    let success = await widgetStatus({
      businessId: businessId,
      widgetId: widgetDetails.widgetId,
      isActive: widgetDetails.isActive ? false : true,
    })
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
    if (success) {
      setADOpen(false);
      updateState((_) => !_);

    }
  }

  async function handleDelete(event) {

    dispatch({
      type: 'UPDATE', payload: {
        loading: true
      }
    });
    let success = await deleteWidget({
      businessId: businessId,
      widgetId: widgetDetails.widgetId
    })
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
    if (success && success.error === false) {
      history.replace(`/dashboard/${businessId}/Widgets`);
    }
  }

  const { widgetIdURL } = useParams();

  const setUp = async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let data = await getGenericDetails({
      businessId: businessId
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (data && data.genericDetails) {
      let tempServList = [];
      let tempSiteList = [];
      data.genericDetails.serviceDetails.forEach((service) => {
        if (service.isActive) {
          tempServList.push([service.name, service.serviceId, service.isVirtual])
        }
      });
      data.genericDetails.siteDetails.forEach((site) => {
        if (site.isActive) {
          tempSiteList.push([site.name, site.siteId])
        }
      });
      setServiceOptions(tempServList);
      setSiteOptions(tempSiteList);
    }
  };

  useEffect(async () => {
    setUp();
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });

    let data = await listWidgets({
      businessId: businessId,
      widgetId: widgetIdURL,
    });

    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
        widgetDetails: data ? data.widgets[0] : null,
      },
    });
  }, [_]);

  if (widgetDetails && widgetDetails.widgetId == widgetIdURL) {
    return (
      <Grid container className={large ? classes.mainWrapper : classes.mobilePadding}>
        <React.Fragment>
          <CModal open={eOpen} setOpen={setEOpen}
            children={
              <Container component="main" className={classes.formContainer}>
                <CTitle title="Edit widget" />
                <Grid container>
                  {cH ? null : <Grid item xs={6} sm={3} style={small ? { paddingTop: "10px", marginBottom: -25 } : { paddingTop: "10px", marginBottom: -10 }}>
                    <CColor
                      label="Background Color"
                      onchange={setC}
                      color={c}
                    />
                  </Grid>}
                  <Grid item xs={6} sm={cH ? 4 : 3} style={small ? { paddingTop: "10px", marginBottom: -25 } : { paddingTop: "10px", marginBottom: -10 }}>
                    <CColor
                      label="Text Color"
                      onchange={setTC}
                      color={tC}
                    />
                  </Grid>
                  <Grid item xs={6} sm={cH ? 4 : 3} style={{ paddingTop: "10px", marginBottom: -25 }}>
                    <CColor
                      label="Button Color"
                      onchange={setBC}
                      color={bC}
                    />
                  </Grid>
                  <Grid item xs={6} sm={cH ? 4 : 3} style={{ paddingTop: "10px", marginBottom: -25 }}>
                    <CColor
                      label="Button Text Color"
                      onchange={setBTC}
                      color={bTC}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ marginTop: 10, marginBottom: -40 }}>
                    <CCheckBox initialvalue={cH} label="Transparent Background?" onchange={() => { setCH(!cH) }} />
                  </div>
                </Grid>
                <Form
                  action={handleSubmit}
                  postAction={null}
                  cancelEnabled
                  cancelAction={() => { setEOpen(false); }}
                  cancelText="Cancel"
                  submitText="Save"
                  inputFields={
                    [
                      {
                        type: "TextField",
                        validator: [notEmpty, noWhiteSpace],
                        initialvalue: widgetDetails.name,
                        label: "Name",
                        name: "name",
                      },
                      {
                        type: "MultiSelect",
                        name: "optionTypes",
                        label: "Option Types",
                        validator: (value) => (!value ? "An option must be selected" : null),
                        choices: [
                          ["Unit", "Unit"],
                          ["Pack", "Pack"],
                          ["Season Pass", "Season Pass"]
                        ],
                        initialvalue: widgetDetails.optionTypes,
                        size: {
                          xs: 12
                        }
                      }
                    ]
                  }
                >
                </Form>
              </Container>
            }
          />
          <GeneralDialog open={dW} close={handleClose} title="Delete Widget" text="Are you sure you want to delete this widget?" action={handleDelete} />
          <GeneralDialog
            open={aDOpen}
            close={handleClose}
            title={widgetDetails.isActive ? "Deactivate Widget" : "Activate Widget"}
            text={widgetDetails.isActive ? "Are you sure you want to deactivate this widget?" : "Are you sure you want to activate this widget?"}
            action={handleClick}
          />
        </React.Fragment>
        <Grid container item xs={12} direction="row">
          <Grid item xs={6}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>WIDGET</span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.topBlock}>
              <span className={classes.rightElem}>
                <Button onClick={(event) => { setAnchorEl(event.currentTarget); }} className={classes.buttonBasic}><CEllipsisIcon /></Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  keepMounted
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => { setCH(widgetDetails.widgetColor.backgroundColor == "transparent" ? true : false); setC(widgetDetails.widgetColor.backgroundColor); setTC(widgetDetails.widgetColor.textColor); setBC(widgetDetails.widgetColor.buttonColor); setBTC(widgetDetails.widgetColor.buttonTextColor); setEOpen(true); handleMenuClose(); }}>Edit</MenuItem>
                  <MenuItem onClick={() => { setADOpen(true); handleMenuClose(); }}>{widgetDetails.isActive ? "Deactivate" : "Activate"}</MenuItem>
                  <MenuItem onClick={() => { setDW(true); handleMenuClose(); }}>Delete</MenuItem>
                </Menu>
              </span>
            </div>
          </Grid>
        </Grid>
        <Grid container item direction="row" xs={12} spacing={small ? 2 : 0} style={{ width: "100%" }}>
          <Grid item style={(small ? { maxWidth: "50%" } : { width: "100%" })}>
            <div className={classes.headerPadding} style={{ marginLeft: -10 }}>
              <LightTooltip title={widgetDetails.name} arrow>
                <Typography className={small ? classes.topHeadingOne : classes.mobileTopHeadingTwo} noWrap style={{ width: `calc(100%)` }}>
                  {widgetDetails.name}
                </Typography>
              </LightTooltip>
            </div>
          </Grid>
          <Grid item style={small ? { paddingLeft: "20px" } : { paddingLeft: "10px" }}>
            <div className={small ? classes.headerPadding : null}>
              <span className={small ? classes.topHeadingFour : null}>
                <StatusTag wrapperName={widgetDetails.isActive ? "greenWrapper" : "redWrapper"} value={widgetDetails.isActive ? "Active" : "Inactive"} />
              </span>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow} style={{ marginLeft: -10 }}>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <span className={classes.labelText}>Service</span>{" "}
              </div>
              <div className={classes.fieldColumnTwo}>
                <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                  <LightTooltip title="Service Page" arrow>
                    <Link style={{ color: "#0079fc", textDecoration: "none" }} to={`/dashboard/${businessId}/Services/${widgetDetails.serviceId}`}>{widgetDetails.serviceName}</Link>
                  </LightTooltip>
                </Typography>
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <span className={classes.labelText}>Site</span>{" "}
              </div>
              <div className={classes.fieldColumnTwo}>
                <Typography noWrap className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                  <LightTooltip title="Site Page" arrow>
                    <Link style={{ color: "#0079fc", textDecoration: "none" }} to={`/dashboard/${businessId}/Sites/${widgetDetails.siteId}`}>{widgetDetails.siteName}</Link>
                  </LightTooltip>
                </Typography>
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.fieldColumnOne}>
                <span className={classes.labelText}>Option Types</span>{" "}
              </div>
              <div className={classes.fieldColumnTwo}>
                <span className={classes.valueText}>
                  {widgetDetails.optionTypes.map((ot, index) => {
                    return (
                      index == widgetDetails.optionTypes.length - 1 ? ot : `${ot}, `
                    );
                  })}
                </span>
              </div>
            </div>
            {widgetDetails.isVirtual ?
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <span className={classes.labelText}>Virtual</span>{" "}
                </div>
              </div> : null}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={classes.labelText}>Widget Colors</span>
          </div>
        </Grid>
        <Grid container item xs={12} spacing={small ? 0 : 1} style={{ paddingTop: "10px" }}>
          <Grid container item spacing={1} xs={6} sm={3} direction="column" alignItems="center">
            <Grid item>
              <span className={classes.labelText} style={{ whiteSpace: "normal" }}>Background Color</span>{" "}
            </Grid>
            <Grid container item spacing={1} justifyContent="center">
              <Grid item>
                <ColorButton color={widgetDetails.widgetColor.backgroundColor == "transparent" ? "#00000000" : widgetDetails.widgetColor.backgroundColor} borderColor="#1a1f36" />
              </Grid>
              <Grid item>
                <Typography className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                  {widgetDetails.widgetColor.backgroundColor == "transparent" ? "#00000000" : widgetDetails.widgetColor.backgroundColor}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} item xs={6} sm={3} direction="column" alignItems="center">
            <Grid item>
              <span className={classes.labelText}>Text Color</span>{" "}
            </Grid>
            <Grid container item spacing={1} justifyContent="center">
              <Grid item>
                <ColorButton color={widgetDetails.widgetColor.textColor} borderColor="#1a1f36" />
              </Grid>
              <Grid item>
                <Typography className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                  {widgetDetails.widgetColor.textColor}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} item xs={6} sm={3} direction="column" alignItems="center">
            <Grid item>
              <span className={classes.labelText}>Button Color</span>{" "}
            </Grid>
            <Grid container item spacing={1} justifyContent="center">
              <Grid item>
                <ColorButton color={widgetDetails.widgetColor.buttonColor} borderColor="#1a1f36" />
              </Grid>
              <Grid item>
                <Typography className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                  {widgetDetails.widgetColor.buttonColor}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} item xs={6} sm={3} direction="column" alignItems="center">
            <Grid item>
              <span className={classes.labelText} style={{ whiteSpace: "normal" }}>Button Text Color</span>{" "}
            </Grid>
            <Grid container item spacing={1} justifyContent="center">
              <Grid item>
                <ColorButton color={widgetDetails.widgetColor.buttonTextColor} borderColor="#1a1f36" />
              </Grid>
              <Grid item>
                <Typography className={classes.valueText} style={{ fontFamily: "Rubik, sans-serif" }}>
                  {widgetDetails.widgetColor.buttonTextColor}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "15px" }}>
          <div className={classes.headingWrapper}>
            <span className={classes.labelText}>Widget Code</span>
          </div>
        </Grid>
        <Grid container item xs={12} style={{ paddingTop: "10px" }}>
          <Grid item xs={10} sm={11}>
            <CDisplayArea
              value={widgetDetails.embededCode ? widgetDetails.embededCode : null}
              initialMaxHeight={"calc(100vh - 360px)"}
            />
          </Grid>
          <Grid item xs={2} sm={1}>
            <CCopyIcon textToCopy={widgetDetails.embededCode} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default Widget;