import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import StaffClass from './StaffClass';
import StaffClassList from './StaffClassList';

const StaffClassHandler = () => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const basePath = `/dashboard/${businessId}/Group-Classes`
    return (
        <Switch>
            <Route exact path = {basePath} render = {()=>(<StaffClassList basePath = {basePath} />)}/>
            <Route exact path = {`${basePath}/:classIdURL`} render = {()=>(<StaffClass basePath = {basePath}/>)}/>
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
        </Switch>
    );
}

export default StaffClassHandler;