import React from "react";
import CreditCardIcon from "@material-ui/icons/CreditCard";

function CardImage(props) {

  const imageObject = {
    "American Express":
      "https://caritra-app-images.s3.us-west-1.amazonaws.com/card-logo/amex.png",
    Discover:
      "https://caritra-app-images.s3.us-west-1.amazonaws.com/card-logo/discover.png",
    "Diners Club":
      "https://caritra-app-images.s3.us-west-1.amazonaws.com/card-logo/diners.png",
    JCB: "https://caritra-app-images.s3.us-west-1.amazonaws.com/card-logo/jcb.png",
    MasterCard:
      "https://caritra-app-images.s3.us-west-1.amazonaws.com/card-logo/mastercard.png",
    Visa: "https://caritra-app-images.s3.us-west-1.amazonaws.com/card-logo/visa.png",
  };

  return (
    <div>
        {
            imageObject[props.brand] ?
            <img style = {{height : "30px"}} src={ imageObject[props.brand]} alt="card"/>:
            <CreditCardIcon fontSize="large" style={{color: "#3366FF"}}/>
        }
    </div>
  );
}

export default CardImage;
