import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector, useDispatch} from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {verificationDets} from "../../../../../../../utils";
import {verificationInitiate} from "../../../../../../../utils";
import {verificationUpdate} from "../../../../../../../utils";
import {
  Grid, CircularProgress, useMediaQuery, Typography
} from "@material-ui/core";
import LightTooltip from "../../../../../../Form/LightTooltip";
import StatusTag from "../../../../../../TagComponents/StatusTag";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
  },
  headerPadding: {
    padding: "10px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeadingOne: {
    fontFamily:"Rubik, sans-serif",
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontFamily:"Rubik, sans-serif",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  subHeading: {
    fontFamily:"Rubik, sans-serif",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  fieldRows: {
    paddingTop: "8px"
  },
  fieldColumnOne: {
    width: "140px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    alignItems: "center",
  },
  root: {
    flexGrow: 1,
    justifyContent: 'center'
  },
  container: {
    margin: "0 auto"
  },
  space: {
    marginTop: theme.spacing(3)
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    },
  loaderMain : {
    position : "absolute",
    top : "50%",
    left: "50%"
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  labelText: {
    fontFamily:"Rubik, sans-serif",
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontFamily:"Rubik, sans-serif",
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPos: {
    textTransform:"uppercase",
    fontFamily:"Rubik, sans-serif",
    fontSize: "14px",
    color: "green",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    textTransform:"uppercase",
    fontFamily:"Rubik, sans-serif",
    fontSize: "14px",
    color: "red",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#0079fc",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration:"underline"
  },
  ttCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  ttCustomCellTwo: {
    border: "none",
    width: "20%",
    maxWidth: "300px",
    padding: "10px",
  },
  ttCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  ttCustomCellFour: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  ttCustomCellFive: {
    border: "none",
    padding: "10px",
  },
  formContainer:{
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding:"20px",
    maxWidth: "600px"
  }
}));

const BusinessVerify = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [_, updateState] = useState(0);
  const large = useMediaQuery('(min-width: 960px)');
  const small = useMediaQuery('(min-width: 750px)');
    
  const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
  const [verificationDetails, setVerificationDetails] = useState(false);
  const [link,setLink] = useState("");

  useEffect(async () => {
      let data = null; 
      dispatch({
          type: 'UPDATE', payload: {
              loading: true
          }
      });
      let success = await verificationDets({businessId: businessId});
      if (!verificationDetails.chargeEnabled) {
          data = await verificationInitiate({ businessId: businessId });
        if(data) {
            setLink(data.linkInfo);
        };
      }
      if(verificationDetails.chargeEnabled) {
          data = await verificationUpdate({ businessId: businessId });
        if(data) {
            setLink(data.linkInfo);
        };
      }
      dispatch({
          type: 'UPDATE', payload: {
            loading: false,
          }
      });
      if(success) {
        setVerificationDetails(success.businessCheck);
      }
  }, [_]);

    if (verificationDetails && link) {
        return (
            <Grid container justify="center" spacing={3} className={large ? classes.mainWrapper : classes.mobilePadding}>
              <Grid container item className={classes.headerPadding} xs={12}>
                <span className={small ? classes.topHeadingOne : classes.mobileTopHeadingTwo}>
                  Business Status
                </span>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentRow}>
                  <div className={classes.fieldRows}>
                    <div className={classes.fieldColumnOne}>
                      <span className={classes.labelText} style={{whiteSpace:"normal"}}>Account Status</span>{" "}
                    </div>
                    <div className={classes.fieldColumnTwo}>
                      <StatusTag wrapperName={!verificationDetails.accountDisabled? "greenWrapper" : "redWrapper"} value={!verificationDetails.accountDisabled? "Enabled" : "Restricted"}  />
                    </div>
                  </div>
                  <div className={classes.fieldRows}>
                    <div className={classes.fieldColumnOne}>
                      <span className={classes.labelText} style={{whiteSpace:"normal"}}>Business Verification</span>{" "}
                    </div>
                    <div className={classes.fieldColumnTwo} style={{paddingRight:"15px"}}>
                      <StatusTag wrapperName={verificationDetails.showVerificationInProcess ? "greyWrapper" : verificationDetails.chargeEnabled? "greenWrapper" : "redWrapper"} value={verificationDetails.chargeEnabled? "Complete" : "Pending"}  />
                    </div>
                    {verificationDetails.showVerificationInProcess ? null : <div style={{paddingTop:"5px"}} className={verificationDetails.chargeEnabled ? classes.fiedlColumnOne : classes.fieldColumnTwo}>
                      {verificationDetails.chargeEnabled ? <div className={classes.valueText} style={{whiteSpace:"normal",fontSize: "12px"}}>
                        You can change business information by clicking <LightTooltip title="Update business verification" arrow>
                          <a style={{color:"#0079fc}"}} href= {link}> here</a>
                        </LightTooltip>
                        </div> : 
                        <div className={classes.valueText} style={{fontSize:"13px"}}>
                          <LightTooltip title="Stripe business vericiation link" arrow>
                            <a style={{color:"#0079fc"}} href={link}>Verify</a>
                          </LightTooltip>
                        </div>
                      }
                    </div>}
                  </div>
                 {!verificationDetails.payoutEnabled && !verificationDetails.preAdd ? <div className={classes.fieldRows}>
                    <div className={classes.fieldColumnOne}>
                      <span className={classes.labelText} style={{whiteSpace:"normal"}}>Payout Bank</span>{" "}
                    </div>
                    <div className={classes.fieldColumnTwo} style={{paddingRight:"15px"}}>
                      <StatusTag wrapperName="redWrapper" value= "Not Added" />
                    </div>
                    <div className={classes.fieldColumnTwo}>
                      <LightTooltip title="Verify Payout Bank" arrow>
                        <Typography className={classes.valueTextLink}  onClick = {() => {history.push(`/dashboard/${businessId}/Bank-Details`);}} noWrap style={{fontFamily:"Rubik, sans-serif", marginBottom:-6}}>
                          Add Bank
                        </Typography>
                      </LightTooltip>
                    </div>
                  </div>: null}
                  {!verificationDetails.payoutEnabled && verificationDetails.preAdd ? <div className={classes.fieldRows}>
                    <div className={classes.fieldColumnOne}>
                      <span className={classes.labelText} style={{whiteSpace:"normal"}}>Payout Enabled</span>{" "}
                    </div>
                    <div className={classes.fieldColumnTwo} style={{paddingRight:"15px"}}>
                      <StatusTag wrapperName="redWrapper" value= "Not Enabled" />
                    </div>
                  </div>: null}
                  <div className={classes.fieldRows}>
                    <div className={classes.fiedlColumnOne}>
                      <div className={classes.valueText} style={{whiteSpace:"normal",fontSize: "12px"}}>
                        You can view your business pricing agreement with Caritra
                          <span style={{color:"#0079fc", cursor:"pointer"}} onClick = {() => {history.push(`/dashboard/${businessId}/Caritra-Pricing`);}}> here</span>
                        </div>
                    </div>
                  </div>
                </div>
              </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <div style={{paddingBottom:"5px"}}>
                <span className={classes.labelText}>
                Verification FAQ
              </span>
              </div>
              <div className={classes.topMarginBlock}>
                <span className={classes.valueText} style={{whiteSpace:"normal"}}>
                  Caritra uses Stripe for our financial transaction processing. Financial transactions include the collection of money from customers, on behalf of the business, during purchase and the deposit of these funds to the business' bank account. Stripe allows financial transactions for businesses that are verified, and in good standing, as per federal law. A business has to be verified for money collection on behalf of the business and verify a valid bank account for weekly deposits to occur.
                </span>
              </div>
            </div>
          </Grid>
            </Grid>
        );
    } else {
        return(
          <Grid container className={classes.mainWrapper}>
            <Grid item xs={12}>
              <div className={classes.loaderWrapper}>
                <CircularProgress className={classes.loaderMain}/>
              </div>
            </Grid>
          </Grid>
        );
    }
  };

  export default BusinessVerify;


