import { Grid, makeStyles, useMediaQuery } from '@material-ui/core'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { encodeGetParams, getInternalEventsListAdmin, getInternalEventsListStaff, retrieveGetParams, withLoading } from '../../../../../../utils'
import EmptyListImage from '../../../../../EmptyListImage/Empty'
import Filter from '../../../../../Filter/Filter'
import ListCard from '../../../../../MobileCards/ListCard'
import ListTable from '../../../../../TableComponents/ListTable'
import ListTableNavigation from '../../../../../TableComponents/ListTableNavigation'
import CreateInternalEventForm from './CreateInternalEventForm'
import StatusTag from "./../../../../../TagComponents/StatusTag";

const currentLimit = 20
const internalEventsHeaders = [
  {
    name: "Event #",
    columnKey: "eventNumber",
    columnWidth: "100px",
    type: "Normal"
  },
  {
    name: "status",
    columnKey: "status",
    columnWidth: "100px",
    type: "Normal"
  },
  {
    name: "Name",
    columnKey: "name",
    columnWidth: "200px",
    tooltip: true
  },
  {
    name: "start date",
    columnKey: "startDate",
    columnWidth: "100px",
    tooltip: true
  },
  {
    name: "start time",
    columnKey: "startTime",
    columnWidth: "100px",
    tooltip: true
  },
  {
    name: "end time",
    columnKey: "endTime",
    columnWidth: "100px",
    tooltip: true
  },
  {
    name: "# Resources",
    columnKey: "totalResources",
    columnWidth: "100px",
    tooltip: true
  },
  {
    name: "# Staff",
    columnKey: "totalStaffs",
    columnWidth: "100px",
  },
];
const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "inset 0 -1px #e3e8ee"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  }
}));



const InternalEventsList = (props) => {
  const bigEnough = useMediaQuery("(min-width:750px)");
  const mountedRef = useRef(false)
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation()
  const queryParams = retrieveGetParams(location)
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const [internalEventsList, setInternalEventsList] = useState(null);
  const [totalDocs, setTotalDocs] = useState(null)
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  //Get query params:
  const currentPage = queryParams.pg ? Number(queryParams.pg) : 1
  const start = queryParams.start
  const end = queryParams.end
  const site = queryParams.site
  const staffId = queryParams.siteId
  const status = queryParams.status

  const isUTCPassed = (utcTime) => {
    const curMoment = new Date()
    const refMomemt = new Date(utcTime)
    return curMoment > refMomemt
  };

  const formatInternalEvents = (internalEventData) => {
    return internalEventData.map((internalEvent) => {

      

      const {
        eventId,
        name,
        eventNumber,
        startDate,
        startTime,
        endTime,
        totalStaffs,
        totalResources,
        hardBlock,
        isCanceled,
      } = internalEvent;

      let status = null
      if (isCanceled) {
        status = <StatusTag wrapperName={'redWrapper'} value={'Canceled'} />
      }
      else if (isUTCPassed(endTime)) {
        status = <StatusTag wrapperName={'grayWrapper'} value={'Completed'} />
      }
      else {
        status = <StatusTag wrapperName={'greenWrapper'} value={'Scheduled'} />
      }

      let id = eventId;

      return {
        id,
        name,
        eventNumber,
        startDate,
        startTime,
        endTime,
        totalStaffs,
        totalResources,
        hardBlock,
        isCanceled,
        status
      };
    });
  };

  const fetchInternalEventsList = async () => {
    const internalEventResponse = await withLoading(props.admin ? getInternalEventsListAdmin : getInternalEventsListStaff, {
      businessId,
      limit: currentLimit,
      page: currentPage,
      startDate: start,
      endDate: end,
      siteIdList: site ? [site] : undefined,
      staffIdList: staffId ? [staffId] : undefined,
      statusOption: status ? status : "All"

    })
    if (Array.isArray(internalEventResponse?.events?.docs) && mountedRef.current) {
      setInternalEventsList(formatInternalEvents(internalEventResponse.events.docs))
      setTotalDocs(internalEventResponse.events.totalDocs)
      setHasNext(internalEventResponse.events.hasNextPage)
      setHasPrevious(internalEventResponse.events.hasPrevPage)
    }
  }

  const navigateToEvent = (eventId) => history.push(`${props.basePath}/${eventId}`)

  const getNextPage = () => {
    if (hasNext) {
      history.push(location.pathname + '?' + encodeGetParams({ ...queryParams, pg: currentPage + 1 }))

    }
  };

  const getPrevPage = () => {
    if (hasPrevious) {
      history.push(location.pathname + '?' + encodeGetParams({ ...queryParams, pg: currentPage - 1 }))
    }
  };

  const setup = async () => {
    await fetchInternalEventsList()
  }

  useEffect((() => {
    mountedRef.current = true
    setup()
    return () => mountedRef.current = false
  }), [currentPage, start, end, site, staffId, status])


  const ViewChanger = () => {
    if (bigEnough) {
      return (
        <ListTable
          identifier={"admin_internal_events"}
          tableHeaders={internalEventsHeaders}
          tableRows={internalEventsList}
          rowClick={navigateToEvent}
        />
      );
    } else {
      return (
        internalEventsList.map((event) => {
          return (
            <ListCard
              action={() => navigateToEvent(event.id)}
              cardName={event.eventNumber}
              statusReplace={event.startDate}
              other={event.startTime}
            />
          )
        })
      );
    };
  };

  if (internalEventsList === null && totalDocs === null) {
    return (null);
  } else {
    return (
      <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
        <Grid item xs={12} className={bigEnough ? null : classes.stickyHeader}>
          <div className={classes.headerPadding}>
            <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Internal Events</span>
            {props.admin ?
              <CreateInternalEventForm refetch={fetchInternalEventsList} />
              : null}
            {props.admin === true ?
              <Filter businessId={businessId} filterFields={['staff', 'site', 'dateRange', "status"]} />
              : null}

          </div>
        </Grid>
        {internalEventsList.length > 0 ?
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <ViewChanger />
            </div>
          </Grid> : <EmptyListImage name="Internal Events" />}
        {internalEventsList.length > 0 ?
          <Grid item xs={12}>
            <ListTableNavigation
              currentPage={currentPage}
              currentLimit={currentLimit}
              totalDocs={totalDocs}
              tableRows={internalEventsList}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              getPrevPage={getPrevPage}
              getNextPage={getNextPage}
            />
          </Grid> : null}
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  }
}

export default InternalEventsList;