import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
    comp:
    {
        width: "100%"
    },
    formLabel: {
        fontSize: 14,
        color: theme.palette.textColor2.main
    },
    fieldText: {
        fontSize: 14,
        color: theme.palette.primaryText.main,
        padding: "10px"
    }
}));

const CStars = (props) => {
    const [choice, setChoice] = useState(null)

    useEffect(() => {
        if (props.initialvalue && props.initialvalue !== choice) {
            props.updateGlob(choice)
        }

        else if (props.initialvalue) {
            props.updateGlob(props.initialvalue)
        }
        else {
            props.updateGlob(null)
        }
    }, [])


    const classes = useStyles();
    return (
        <div >

            <Typography className={classes.formLabel}>
                {props.label}
            </Typography>
            <Rating
                name={props.name}

                value={choice}
                onChange={(event, newValue) => {
                    setChoice(newValue);
                    props.updateGlob(newValue)
                    props.onchange(newValue)
                }}
            />
            <FormHelperText
                className = {classes.helperText}
                error={(typeof props.errortext == "string" && props.errortext !== "" ? true : false)}
            >
                {props.errortext}
            </FormHelperText>
        </div>
    );
}

export default CStars;