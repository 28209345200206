import React, { useState } from 'react'
import CModal from '../Form/CModal'
import CTitle from '../Form/CTitle'
import { makeStyles, Container, Button, Grid, Checkbox } from '@material-ui/core'
import CDisplayArea from '../Form/CDisplayArea'
import { Typography, TextareaAutosize } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import Check from '@material-ui/icons/Check'
import CModalError from '../Form/CModalError'

const useStyles = makeStyles(theme => ({
    formContainer: {
        border: "20px",
        backgroundColor: "white",
        borderRadius: "4px",
        padding: "20px",
        width: "80vw",
        maxHeight: "80vh",
        overflowY: "auto",
    },
    buttonText: {
        textTransform: "none",
        fontSize: 14
    },
    textBox: {
        border: "none",
        resize: "none",
        whiteSpace: "word-wrap",
        textAlign: "justify",
        fontSize: 14,
        fontWeight: "normal",
        fontFamily: "'Karla', sans-serif",
        outline: "none",
        marginBottom: 10,
        width: "100%"
    },
    checkIcon: {
        color: theme.palette.success.main
    }
}))

const ShowAgreement = (props) => {
    const classes = useStyles()
    const otherClasses = props.classes
    const [open, setOpen] = useState(false)
    const { action, agreementDetails } = props
    const accepted = props.accepted
    if (agreementDetails) {
        return (

            accepted ?
                <Grid container>
                    <CheckIcon
                        className={classes.checkIcon}
                    />
                </Grid>
                :
                <React.Fragment>
                    <Typography color="primary">
                        <span className={otherClasses.buttonText} onClick = {()=>{setOpen(true)}}>View and Accept</span>
                    </Typography>
                    <CModal open={open} setOpen={setOpen}>
                        <Container className={classes.formContainer}>
                            <Grid container>
                                <CTitle title={"Agreement Details"} />
                                <CModalError/>
                                <Grid item xs={12}>
                                    <TextareaAutosize
                                        readOnly
                                        className={classes.textBox}
                                        value={agreementDetails.document}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} justifyContent="flex-end">
                                        <Grid item>
                                            <Button onClick={() => { setOpen(false) }} >
                                                <Typography className={classes.buttonText}>
                                                    Cancel
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={() => {
                                                setOpen(false)
                                                action()
                                            }}
                                                color="primary"
                                                variant="contained" >
                                                <Typography className={classes.buttonText}>
                                                    Accept
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </CModal>
                </React.Fragment>

        )
    }
    else {
        return null
    }
}

export default ShowAgreement