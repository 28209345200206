import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Form from "../../../../../Form/Form";
import CModal from "../../../../../Form/CModal";
import { Container, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import maxTextArea from "../../../../../../FormValidation/maxTextArea";
import maxTextField from "../../../../../../FormValidation/maxTextField";
import noWhiteSpace from "../../../../../../FormValidation/noWhiteSpace";
import { addService, getGenericDetails,withLoading } from "../../../../../../utils";
import { CColor } from "../../../../../Form/CColorPicker";

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: "20px",
        backgroundColor: "white",
        borderRadius: "4px",
        padding: "20px",
        maxWidth: "600px",
        width: "100%",
        maxHeight: "80vh",
        overflowY: "auto",
    },
}));

const validChoices = (choices) => {
    if (Array.isArray(choices)) {
        if (choices.length !== 0) {
            choices.forEach((choice) => {
                if (!Array.isArray(choice) || choice.length !== 2) {
                    return false;
                }
            });

            return true;
        } else {
            return true;
        }
    }
    return false;
};

const AddServiceForm = (props) => {
    const dispatch = useDispatch();
    const businessId = useSelector((state) => {
        return state.buisnessDetails.businessId;
    });
    const [categories, setCategories] = useState([]);
    const classes = useStyles();
    const [c, setC] = useState('#3366FF');
    const history = useHistory();
    const location = useLocation();

    const setUp = async () => {
        let genericData = await getGenericDetails({ businessId: businessId });
        if (genericData &&genericData.genericDetails && Array.isArray(genericData.genericDetails.categoryDetails)) {
            setCategories(
                genericData.genericDetails.categoryDetails.map((categoryObject) => {
                    const temp = [null, null];
                    temp[0] = categoryObject.name;
                    temp[1] = categoryObject.categoryId;
                    return temp;
                })
            );
        }
    };
    useEffect(() => {
        if(props.open){
            withLoading(setUp,{})
        }
    }, [props.open]);

    const redirect = (success) => {
        if (success) {
            history.push(`${props.basePath}/${success.serviceId}`);
        }
    };
    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    moreData={{ businessId: businessId, primaryColor: c }}
                    cancelEnabled
                    title="Add Service"
                    cancelAction={() => {
                        props.setOpen(false);
                        setC('#3366FF');
                    }}
                    submitText="Create"
                    comp={<Grid container>
                        <Grid item>
                            <CColor
                                label="Display Color"
                                onchange={setC}
                                color={c}
                            />
                        </Grid>
                    </Grid>
                    }
                    action={addService}
                    postAction={redirect}
                    inputFields={[
                        {
                            type: "TextField",
                            name: "name",
                            label: "Service Name",
                            validator: [notEmpty, noWhiteSpace, maxTextField],
                        },
                        {
                            type: "SingleSelect",
                            name: "categoryId",
                            label: "Category",
                            choices: validChoices(categories) ? categories : [],

                            validator: (value) => (!value ? "Chose a category" : null),
                        },

                        {
                            type: "Number",
                            name: "duration",
                            label: "Duration (mins)",
                            numberProps: 0,
                            validator: (value) => {
                                if (value <= 0) {
                                    return "Duration must be positive";
                                } else if (value % 5 !== 0) {
                                    return "Must be in increments of 5";
                                } else if (value > 1440) {
                                    return "Duration can not be greater than 1440 minutes"
                                }
                            },
                        },
                        {
                            type: "TextArea",
                            name: "description",
                            label: "Description",
                            resizeEnabled: true,
                            validator: [notEmpty, noWhiteSpace, maxTextArea],
                            moreProps: {
                                rowsMin: 4,
                            },
                        },
                        {
                            type: "RadioSet",
                            name: "type",
                            choices: [
                                ["Group", "Group"],
                                ["Private", "Private"],
                            ],
                            label: "Service Type",
                            validator: (value) => {
                                if (!value) {
                                    return "Chose a type";
                                }
                            },
                            padding:{
                                bottom:0
                            }
                        },

                        {
                            type: "Custom",
                        },


                        {
                            type: "CheckBox",
                            name: "isVirtual",
                            label: "Virtual",
                            padding:
                            {
                                top:0
                            }
                        },


                    ]}
                />
            </Container>
        </CModal>
    );
};

export default AddServiceForm;
