



export const validTime = time => time ? null : "Please enter a valid time"

export const validTimeRange = ([time1, time2]) => {
    if (time1 && time2) {
        const time1Split = time1.split(":").map(char => Number(char))
        const time2Split = time2.split(":").map(char => Number(char))
        if (time1Split[0] * 60 + time1Split[1] < time2Split[0] * 60 + time2Split[1]) {
            return [null, null]
        }
        else {
            return ["Start time must be before end time", null]
        }
    }
    else {
        return [null, null]
    }
}

export const processTime = (string) => {
    let data;
    if (typeof string === 'string') {
        data = string.split(/[:\s]/)
    }
    if (data && isNumeric(data[0]) && isNumeric(data[1]) && ['am', 'pm'].includes(data[2]) && dataInRange(data)) {
        return toArmyTime(data)
    }
    return (null)
}


const toArmyTime = (data) => {
    const hr = (data[0] % 12) + (data[2] === 'pm' ? 12 : 0)
    return `${hr < 10 ? `0${hr}` : hr}:${data[1]}`
}

export const fromArmyTime = (time) => {
    const data = time.split(':')
    const hr = parseInt(data[0]) % 12 < 10 ? `0${parseInt(data[0]) % 12}` : data[0] % 12
    if (parseInt(data[0]) % 12 === 0) {
        return `12:${data[1]}:${data[0] / 12 === 0 || data[0] / 12 === 2 ? 'am' : 'pm'}`
    }
    else {
        return `${hr}:${data[1]}:${parseInt(data[0]) / 12 > 1 ? 'pm' : 'am'}`
    }
}

const isNumeric = (string) => {
    return string.match("^\\d\\d$")
}

const dataInRange = (data) => {
    return 1 <= parseInt(data[0]) && parseInt(data[0]) <= 12 && 0 <= parseInt(data[1]) && parseInt(data[1]) <= 59
}