import React from 'react'
import { Container, makeStyles } from '@material-ui/core'
import Form from '../../../Form/Form'
import CModal from '../../../Form/CModal'
import {customerUpdatePassword } from '../../../../utils'
import validPassword from '../../../../FormValidation/password'
const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        width: "100%",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))

const ChangePassword = (props) => {
    const classes = useStyles()
    const refresh = (response) => {
        if (response) {
            props.setOpen(false)
            props.refresh()
        }
    }
    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    title="Change Password"
                    cancelEnabled
                    cancelAction={() => { props.setOpen(false) }}
                    submitText="Save"
                    action={customerUpdatePassword}
                    postAction={refresh}
                    inputFields={[
                        {
                            type: "Password",
                            name: "oldPassword",
                            label: "Old Password",
                            validator: validPassword,

                        },
                        {
                            type: "Password",
                            name: "newPassword",
                            label: "New Password",
                            validator: validPassword,


                        },


                    ]}
                />
            </Container>
        </CModal>
    )

}

export default ChangePassword