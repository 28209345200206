import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { listMemberships } from "../../../../../../utils";
import { addMembership } from "../../../../../../utils";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Button, Grid, Typography } from "@material-ui/core";
import ListTable from "./../../../../../TableComponents/ListTable";
import StatusTag from "../../../../../TagComponents/StatusTag";
import Form from "../../../../../Form/Form";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListCard from "../../../../../MobileCards/ListCard";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import CModal from "../../../../../Form/CModal";
import { CircularProgress } from "@material-ui/core";
import LightTooltip from "../../../../../Form/LightTooltip";
import PostFilter from "../../../../../Filter/PostFilter";
const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px"
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  headerPadding: {
    paddingBottom: "10px",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "inset 0 -1px #e3e8ee"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px"
  }
}));

const keyObjects = [{ key: 'isActive', label: 'Status', keyLabel: 'statusName' }]

const MembershipsList = () => {
  const [add, setAdd] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const small = useMediaQuery('(min-width: 750px)');
  const large = useMediaQuery('(min-width: 960px)');
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const membershipsHeaders = [
    {
      name: "status",
      columnKey: "status",
      columnWidth: "100px",
    },
    {
      name: "name",
      columnKey: "wrappedName",
      columnWidth: "80%",
      tooltip: true
    }
  ];

  const [membershipsList, setMembershipsList] = useState([]);
  const [initialState, setInitialState] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);



  async function handleSubmit(event) {
    dispatch({
      type: 'UPDATE', payload: {
        loading: true
      }
    });
    let success = await addMembership({
      businessId: businessId,
      name: event.name,
      description: event.description,
    })
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
    if (success) {
      history.push(`${location.pathname}/${success.membershipId}`);
    }
  };

  const processMembershipData = (membershipData) => {
    return membershipData.map((member) => {
      let {
        membershipId,
        name,
        isActive,
        description
      } = member;

      let status = <StatusTag wrapperName={isActive ? "greenWrapper" : "redWrapper"} value={isActive ? "Active" : "Inactive"} />;
      let id = membershipId;
      let wrappedName = <Typography noWrap style={{ fontSize: "14px", color: "#1a1f36", width: "100%" }}>{name}</Typography>

      return {
        id,
        status,
        isActive,
        name,
        wrappedName,
        statusName: isActive ? "Active" : "Not Active"
      };
    });
  };

  const getMembershipsDetails = async () => {
    let membershipsPayload = await listMemberships({
      businessId: businessId
    });
    if (membershipsPayload && membershipsPayload.membershipsDetails) {
      setMembershipsList(processMembershipData(membershipsPayload.membershipsDetails));
      setInitialState(true);
    }
  };

  const navigateToMembership = (membershipId) => {
    history.push(`/dashboard/${businessId}/Memberships/${membershipId}`);
  };

  const ViewChanger = () => {
    if (small) {
      return (
        <ListTable
          identifier={"admin_orders"}
          tableHeaders={membershipsHeaders}
          tableRows={membershipsList}
          rowClick={navigateToMembership}
        />
      );
    } else {
      return (
        membershipsList.map((mobileMem) => {
          return (
            <ListCard
              action={() => { history.push(`/dashboard/${businessId}/Memberships/${mobileMem.id}`); }}
              cardName={mobileMem.name}
              statusTagValue={mobileMem.isActive}
              activeValue="Active"
              inactiveValue="Inactive"
              other={null}
            />
          )
        })
      );
    };
  };

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    await getMembershipsDetails();
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
  }, [currentPage, currentLimit]);

  if (initialState === null) {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={large ? classes.mainWrapper : !large && small ? classes.mobilePadding : null}>
        <CModal open={add} setOpen={setAdd}
          children={
            <Container component="main" className={classes.formContainer}>
              <Form
                action={handleSubmit}
                postAction={null}
                title="New Membership"
                cancelEnabled
                cancelAction={() => { setAdd(false); }}
                cancelText="Cancel"
                submitText="Create"
                inputFields={
                  [
                    {
                      type: "TextField",
                      validator: [notEmpty],
                      initialvalue: "",
                      label: "Name",
                      name: "name",
                    },
                    {
                      type: "TextArea",
                      initialvalue: "",
                      label: "Description (optional)",
                      name: "description",
                      resizeEnabled: false,
                      moreProps: {
                        minRows: 4,
                        maxRows: 10
                      }
                    }
                  ]
                }
              >
              </Form>
            </Container>
          }
        />
        <Grid container direction="row" item xs={12} style={!large && small ? { paddingTop: "15px" } : null} className={small ? null : classes.stickyHeader}>
          <Grid item xs={6}>
            <div className={classes.headerPadding}>
              <span className={small ? classes.topHeading : classes.mobileTopHeadingOne}>Memberships</span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.headerPadding}>

              <LightTooltip title="Create Membership" arrow>
                <span className={classes.rightElem}>
                  <Button style={{ backgroundColor: "#0079fc", color: "white", marginLeft: '10px', textTransform: 'none' }} onClick={() => { setAdd(true); }}>Add</Button>
                </span>
              </LightTooltip>
              <PostFilter allEntries={membershipsList} keyObjects={keyObjects} />
            </div>
          </Grid>
        </Grid>
        {membershipsList.length > 0 ?
          <Grid item xs={12} className={small ? null : classes.mobilePadding}>
            <div>
              <ViewChanger />
            </div>
          </Grid> : <EmptyListImage name="Memberships" />}
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  }
};

export default MembershipsList;