import { Container, makeStyles } from "@material-ui/core";
import {
  attachPriceGroupToCatalog,
  detachPriceGroupFromCatalog,
} from "../../../../../../utils";
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    width: "100vw",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflowY: "auto",
  },
}));

const CatalogPricingGroupForm = (props) => {
  const classes = useStyles();

  return (
    <CModal open={props.open === true} setOpen={props.setOpen}>
      <Container className={classes.formContainer}>
        <Form
          moreData={{
            businessId: props.businessId,
            catalogId: props.catalog.catalogId,
          }}
          cancelEnabled
          cancelAction={() => {
            props.setOpen(false);
          }}
          submitText={props.addPG ? "Assign" : "Remove"}
          action={
            props.addPG
              ? attachPriceGroupToCatalog
              : detachPriceGroupFromCatalog
          }
          postAction={(res) => {
            if (res?.error === false) {
              props.setOpen(false);
              props.refetch();
            }
          }}
          title={`${props.addPG ? "Assign" : "Remove"} Pricing Groups ${
            props.addPG ? "to" : "from"
          } Catalog`}
          inputFields={[
            {
              type: "MultiSelect",
              label: "Price Groups",
              choices: props.addPG
                ? props.priceGroups.map((group) => [
                    group.name,
                    group.priceGroupId,
                  ])
                : props.catalog.priceGroups.map((group) => [
                    group.name,
                    group.priceGroupId,
                  ]),
              name: "priceGroupIds",
              validator: (value) =>
                value?.length > 0
                  ? null
                  : "At least one price group must be selected",
            },
          ]}
        />
      </Container>
    </CModal>
  );
};

export default CatalogPricingGroupForm;
