import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => ({
    errorContainer: {
        padding: theme.spacing(1),

        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const MessageBanner = (props) => {
    const dispatch = useDispatch()
    const location  = useLocation()
    const classes = useStyles();
    const error = useSelector(state => state.error);
    const errorMessage = useSelector(state => state.errorMessage);
    const insideModal = useSelector(state => state.insideModal);

    const clearError = () => {
        dispatch({
            type: 'UPDATE',
            payload: {
                error: false,
                errorMessage: null
            }
        });
    };

    useEffect(() => {
        clearError();
    },[location])
    
    return (
        <div className={classes.root}>
            <Collapse in={error&&!insideModal}>
                <Alert
                    variant="filled" severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                clearError();
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    );
};



export default MessageBanner;