import React from 'react'
import CModal from '../Form/CModal'
import { makeStyles, Container, Grid, Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import CDisplayArea from '../Form/CDisplayArea'
import CTitle from '../Form/CTitle'
import LightTooltip from '../Form/LightTooltip'
import CurrencyPreProcessor from '../CurrencyFormatter/CurrencyFormatter'

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '20px',
        // padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column"
    }
}))

const ViewAddOns = (props) => {
    const classes = useStyles()
    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Grid container className={classes.formContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{backgroundColor:"#FFBF00", paddingTop: 20, paddingRight: 20, paddingLeft: 20}}>
                    <CTitle title="Add Ons" />
                    </Grid>
                    {
                        Array.isArray(props.addOns) ?
                            props.addOns.map((addOn, index) => (
                                <React.Fragment key={`addOn ${index}`}>
                                    <Grid item xs={12} style={{paddingLeft: 20, paddingRight: 20}}>
                                        <LightTooltip arrow title={`${addOn.displayName} (${CurrencyPreProcessor({
                                                amount: addOn.price,
                                                currencyBreak: props.currencyInfo.currencyBreak,
                                                roundingFactor: props.currencyInfo.roundingFactor,
                                                currencySymbol: props.currencyInfo.currencySymbol
                                            })} ${addOn.sellBy.toLowerCase()})`}>
                                            <Typography variant="body1" gutterBottom noWrap style={{ maxWidth: "100%" }}>
                                                {addOn.displayName} {`(${CurrencyPreProcessor({
                                                amount: addOn.price,
                                                currencyBreak: props.currencyInfo.currencyBreak,
                                                roundingFactor: props.currencyInfo.roundingFactor,
                                                currencySymbol: props.currencyInfo.currencySymbol
                                            })} ${addOn.sellBy.toLowerCase()})`}
                                            </Typography>
                                        </LightTooltip>
  
                                        <CDisplayArea
                                            value={addOn.description}
                                            initialMaxHeight="200px"
                                        />
                                    </Grid>
                                </React.Fragment>
                            ))
                            : null
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end" style={{padding: 20}}>
                        <Button onClick={() => { props.setOpen(false) }} variant="contained" color="primary">
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </CModal>
    )
}

export default ViewAddOns