import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import moment from "moment";
import CardImage from "../CardImage/CardImage";
import CurrencyPreProcessor from "../CurrencyFormatter/CurrencyFormatter";
import ListTable from "../TableComponents/ListTable";
import RefundTransaction from "./RefundTransaction";
const useStyles = makeStyles((theme) => ({
  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  container: {
    padding: 15,
    border: "solid 1px #e3e8ee",
    marginBottom: "15px"
  },
  fieldRows: {
    paddingTop: "8px",
  },
  fieldColumnOne: {
    width: "60px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    paddingLeft: "10px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  transactionSummary: {
    paddingBottom: 20,
  },
  title: {
    paddingBottom: 10,
    borderBottom: "solid 1px #e3e8ee",
    minHeight: 30,
  },
  refund: {
    paddingTop: 15,
  },
}));

const refundHeaders = [
  {
    name: "date",
    columnKey: "date",
    columnWidth: "120px",
    tooltip: true,
  },
  {
    name: "amount",
    columnKey: "amount",
    columnWidth: "180px",
    tooltip: true,
  },
  {
    name: "",
    columnKey: "",
    columnWidth: "100%",
  },
];

const TransactionCard = (props) => {
  const classes = useStyles();
  const { orderDetails, businessId, transaction, index, refresh } = props;
  const large = useMediaQuery("(min-width: 960px)");
  return (
    <div className={classes.container}>
      <div className={classes.transactionSummary}>
        <div className={classes.fieldRows}>
          <div className={classes.fieldColumnOne}>
            <Typography className={classes.labelText}>Date</Typography>{" "}
          </div>
          <div className={classes.fieldColumnTwo}>
            <Typography className={classes.valueText}>{`${moment
              .utc(transaction.chargeDate)
              .format("MMMM D, YYYY")}`}</Typography>
          </div>
        </div>
        <div className={classes.fieldRows}>
          <div className={classes.fieldColumnOne}>
            <Typography className={classes.labelText}>Amount</Typography>{" "}
          </div>
          <div className={classes.fieldColumnTwo}>
            <Typography className={classes.valueText}>{`${CurrencyPreProcessor({
              amount: transaction.amount,
              ...orderDetails.currencyInfo,
            })}`}</Typography>
          </div>
        </div>
        {transaction.transactionSubType == "cardCharge" ? (
          <div className={classes.fieldRows}>
            <div className={classes.fieldColumnOne}>
              <Typography className={classes.labelText}>Card</Typography>{" "}
            </div>
            <div className={classes.fieldColumnTwo} style={{ width: 200 }}>
              <div style={{ float: "left", paddingRight: 5, marginTop: -5 }}>
                <CardImage brand={transaction.paymentMethod.brand} />
              </div>
              <Typography className={classes.valueText}>
                {`ending with ${transaction.paymentMethod.last4}`}
              </Typography>
            </div>
          </div>
        ) : (
          <div className={classes.fieldRows}>
            <div className={classes.fieldColumnOne}>
              <Typography className={classes.labelText}>Type</Typography>{" "}
            </div>
            <div className={classes.fieldColumnTwo}>
              <Typography className={classes.valueText}>Credit</Typography>
            </div>
          </div>
        )}
      </div>
      {transaction?.refundTransactions?.length !== 0 || businessId ? (
        <React.Fragment>
          <div className={classes.title}>
            <Typography
              className={
                large ? classes.subHeading : classes.mobileTopHeadingTwo
              }
            >
              Refunds
              {businessId ? (
                <RefundTransaction
                  refresh={refresh}
                  transaction={transaction}
                  orderDetails={orderDetails}
                  businessId={businessId}
                />
              ) : null}
            </Typography>
          </div>
          <div className={classes.refund}>
            {transaction.refundTransactions.length === 0 ? (
              <Typography align="center">No refunds made so far.</Typography>
            ) : (
              <ListTable
                identifier={`transaction-${index}-refund`}
                tableHeaders={refundHeaders}
                tableRows={transaction.refundTransactions.map((refund) => {
                  if (refund.transactionSubType == "cardRefund") {
                    return {
                      date: moment
                        .utc(refund.refundDate)
                        .format("MMMM D, YYYY"),
                      amount: CurrencyPreProcessor({
                        amount: refund.amount,
                        ...orderDetails.currencyInfo,
                      }),
                    };
                  } else {
                    return {
                      date: moment
                        .utc(refund.refundDate)
                        .format("MMMM D, YYYY"),
                      amount: `${CurrencyPreProcessor({
                        amount: refund.amount,
                        ...orderDetails.currencyInfo,
                      })} (Credit)`,
                    };
                  }
                })}
              />
            )}
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default TransactionCard;
