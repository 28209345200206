import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { logout } from '../../../utils';
import '../HeaderButton.css';

const Dropdown = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { headerClasses } = props
    const bigEnough = props.bigEnough
    const [anchorEl, setAnchorEl] = useState(null);
    const loggedIn = useSelector((state) => state.token !== null);
    const firstName = useSelector(state => state.userDetails.firstName);
    const lastName = useSelector(state => state.userDetails.lastName);
    const dashboards = useSelector(state => state.dashboards)

    const hideForBusiness = () => {
        const locArr = location.pathname.split("/");
        if (locArr.length >= 1 && locArr[1] === "dashboard") {
            return true;
        }
        if (locArr.length >= 1 && locArr[1] === "for-business") {
            return true;
        }
        return false;
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>

            {
                bigEnough && loggedIn ?
                    <Button onClick={handleClick} className={headerClasses.headerWords} style={{ textTransform: 'none' }}>
                        <div style={{ maxWidth: 150 }}>
                            {firstName}
                        </div>
                    </Button>
                    :
                    !bigEnough && loggedIn ?
                        <Grid container direction="row" style={{ width: 100 }} justifyContent="flex-end" alignItems="center">

                            <Fab size="small" color="primary" variant="extended" onClick={handleClick}>
                                <Typography className={headerClasses.headerWordsMobile}>
                                    {firstName.charAt(0)}{lastName.charAt(0)}
                                </Typography>
                            </Fab>
                        </Grid>
                        :
                        !bigEnough && !loggedIn ?
                            <Grid container direction="row" style={{ width: 100 }} justifyContent="flex-end" alignItems="center">

                                <Fab size="small" color="primary" variant="extended" onClick={handleClick}>
                                    <MenuIcon />
                                </Fab>
                            </Grid>
                            :
                            null

            }


            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    !loggedIn ?
                        <MenuItem onClick={(event) => { handleClose(event); history.push('/login') }}>
                            <Typography >
                                Login
                            </Typography>
                        </MenuItem>
                        : null
                }
                {
                    !loggedIn ?
                        <MenuItem onClick={(event) => { handleClose(event); history.push('/sign-up') }}>
                            <Typography >
                                Sign Up
                            </Typography>
                        </MenuItem>
                        : null
                }
                {loggedIn ?
                    <MenuItem onClick={(event) => { handleClose(event); history.push('/account') }}>
                        <Typography  >
                            My Account
                        </Typography>
                    </MenuItem>
                    : null
                }
                {(!bigEnough ?
                    dashboards ? dashboards.map((dashboard, index) => (
                        <MenuItem key={`main menu ${index}`} style={{ textTransform: 'none' }} onClick={(event) => { handleClose(event); history.push(`/dashboard/${dashboard.businessId}`) }}>
                            <Typography noWrap style={{ maxWidth: 150 }}>
                                {dashboard.businessName}
                            </Typography>
                        </MenuItem>
                    )) : null
                    : null)
                }

                {(!hideForBusiness() ?
                    <MenuItem onClick={(event) => { handleClose(event); history.push('/for-business') }}><Typography >
                        For Business
                    </Typography>
                    </MenuItem>
                    : null)
                }
                {(hideForBusiness() && !bigEnough ?
                    <MenuItem onClick={(event) => { handleClose(event); history.push('/') }}><Typography >
                        Marketplace
                    </Typography>
                    </MenuItem>
                    : null)
                }
                {(hideForBusiness() && !bigEnough ?
                    <MenuItem onClick={(event) => { handleClose(event); history.push('/for-business/pricing') }}><Typography >
                        Pricing
                    </Typography>
                    </MenuItem>
                    : null)
                }
                {loggedIn ?
                    <MenuItem onClick={(event) => { handleClose(event); logout(); }}>
                        <Typography >
                            Logout
                        </Typography>
                    </MenuItem>
                    : null
                }
            </Menu>
        </React.Fragment>

    );
};

export default Dropdown;