import React from 'react'
import StatusTag from "../TagComponents/StatusTag";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
      cardBasic: {
        backgroundColor: "#ffffff",
        boxShadow:
          " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
      },
      mobileListView: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#1a1f36",
      },
      floatLeft: {
        float:"left"
      }
}))

const ListCard = (props) => {
    const classes = useStyles();

    const handleClick = () => {
      if(props.action && window.getSelection().type === 'Caret'){
        props.action()
      }
    }

    return(
      <div className={classes.cardBasic}>
        <Grid container spacing={1} item xs={12}  onClick={handleClick} style={{paddingTop: "10px", paddingBottom: "15px" }}>
          <Grid item style={props.cardButton ? {width:`calc(90%)`} : {width:`calc(100%)`}}>
              <Typography className={classes.mobileListView} noWrap>
                {props.cardName}
              </Typography>
          </Grid>
          {props.cardButton ? 
            <Grid item style={{width:`calc(10%)`}}>
              {props.cardButton}
            </Grid>
          : null}
          <Grid item xs={props.other ? 6 : 12}>
            <Typography 
              style={
                props.statusTagValue == null ? {fontSize:"14px", fontweight:"light", color:"#1a1f36", float:"left"} 
              : props.statusTagValue ? {fontSize:"14px", fontWeight:"bold", color:"green", float:"left"} 
              : {fontSize:"14px", fontWeight:"bold", color:"red", float:"left"
              }}
            >
              {props.statusTagValue == null ? props.statusReplace : props.statusTagValue ? props.activeValue : props.inactiveValue}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography noWrap style={{fontSize:"14px", fontweight:"light", color:"#1a1f36", float: "right", maxWidth: "100%"}}>
              {props.other}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
};

export default ListCard;

