import { Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Drawer from "@material-ui/core/Drawer";
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import routes from './routes';

const re = new RegExp(/ /, 'g')
const drawerWidth = '250px'
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    backgroundColor: theme.palette.accent2.main,
    border: "none",
    zIndex: theme.zIndex.drawer,
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#ffffff",
  },
  selected: {
    color: theme.palette.primary.main,

  },
  unSelected: {
    color: theme.palette.primaryText.main,
  },
  listItem: {
    padding: '5px',
  },
  iconButton: {

    position: "fixed",
    zIndex: theme.zIndex.drawer + 2,
    top: 7,
    left: 7
  },
  icon: {
    color: theme.palette.primaryText.main
  },
  text: {
    paddingRight: "10px",
  },
  drawerIcon: {
    paddingLeft: "0px",
    justifyContent: "center",
  },
  avatar: {
    width: "31px",
    height: "31px"
  },
  businessName: {
    fontSize: "18px"
  },
  choices: {
    fontSize: "14px"
  }


}));

const generateRoutes = (routesList) => {
  const temp = [];
  routesList.forEach((routeObject) => {
    if (routeObject.type === "route") {
      temp.push({
        key: routeObject.route,
        path: `/account/${routeObject.route.replaceAll(re, '-')}`,
        render: () => { return (routeObject.comp) }
      })
    }
    else if (routeObject.type === "bucket") {
      temp.push(...generateRoutes(routeObject.comp))
    }
  })
  return (temp)
}

const DrawerItem = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation()
  const currentSection = location.pathname.split('/')[2];
  if (props.type === "route") {
    return (
      <ListItem button className={classes.listItem} onClick={() => { history.push(`/account/${props.route.replaceAll(re, '-')}`) }}>
        <ListItemIcon className={[(currentSection === props.route.replaceAll(re, '-') ? classes.selected : classes.unSelected), classes.drawerIcon].join(' ')}>
          {props.icon}
        </ListItemIcon>
        <ListItemText className={currentSection === props.route.replaceAll(re, '-') ? classes.selected : classes.unSelected} >
          <Typography className={classes.choices}>
            {props.route}
          </Typography>
        </ListItemText>
      </ListItem>
    );
  }
  else if (props.type === "bucket") {
    return (
      <React.Fragment>
        <ListItem
          className={classes.listItem}
          button
          onClick={() => {
            if (props.open === props.route) {
              props.setOpen(null)
            }
            else {
              props.setOpen(props.route)
            }
          }}
        >
          <ListItemIcon className={[(props.comp.map((routeObject) => { return (routeObject.route.replaceAll(re, '-')) }).includes(currentSection)
            ? classes.selected : classes.unSelected), classes.drawerIcon].join(' ')}>
            {props.icon}
          </ListItemIcon>
          <ListItemText className={classes.unSelected}>
            <Typography className={classes.choices}>
              {props.route}
            </Typography>
          </ListItemText>
        </ListItem>

        <Collapse timeout="auto" unmountOnExit in={props.open === props.route}>
          <List component="div" disablePadding>
            {props.comp.map((route) => {
              return (
                <DrawerItem key={`RouteLink: ${route.route}`} {...route} />
              )
            })}
          </List>
        </Collapse>

      </React.Fragment>
    )
  }
  else {
    return (null)
  }
}


const AccountDrawer = (props) => {
  const location = useLocation()
  const [open, setOpen] = useState(location.pathname.split('/')[3]);

  return (
    routes.map((route) => {
      return (
        <DrawerItem key={`RouteLink: ${route.route}`} {...route} setOpen={setOpen} open={open} />
      )
    })
  );
}

const AccountDashboard = () => {
  const bigEnough = useMediaQuery('(min-width:750px)', { noSsr: true });
  const width = (bigEnough ? `calc(100% - ${drawerWidth})` : `calc(100%)`)
  const location = useLocation()
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const accountNumber = useSelector(state => state.userDetails.accountNumber)
  useEffect(() => {
    setOpen(false)
  }, [location, bigEnough])

  return (

    <div style={{ display: 'flex' }}>
      {
        (bigEnough === false ?
          <IconButton className={classes.iconButton} onClick={() => { setOpen(true) }}>
            <MenuIcon className={classes.icon} />
          </IconButton>
          : null
        )
      }
      <Drawer
        className={classes.drawer}
        open={(!bigEnough ? open : null)}
        onClose={() => { setOpen(false) }}
        variant={(!bigEnough ? 'temporary' : "permanent")}
        classes={{
          paper: classes.drawer
        }}
      >
        {(bigEnough ? <Toolbar /> : null)}
        <List>
          <AccountDrawer />
        </List>
      </Drawer>

      <main className={classes.content} style={{ width: width }}>
        <Switch>
          {generateRoutes(routes).map((route, index) => {
            return (<Route key={`Route Choice: ${index}`} {...route} />)
          })}
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
        </Switch>
      </main>

    </div>

  );
};

export default AccountDashboard;