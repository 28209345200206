import { Container, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import maxTextArea from "../../../../../../FormValidation/maxTextArea";
import maxTextField from "../../../../../../FormValidation/maxTextField";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import CButtonPair from "../../../../../Form/CButtonPair";
import CModal from "../../../../../Form/CModal";
import CTitle from "../../../../../Form/CTitle";
import Form from "../../../../../Form/Form";

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "800px",
    width: "100%",
    maxHeight: "80vh",
    overflowY: "auto",
  },
}));

const PreviewFormTemplate = (props) => {
  const classes = useStyles();
  const { open, setOpen, formTemplate } = props;
  const { name, heading, subheading, fields } = formTemplate
    ? extractFormTemplateParams(formTemplate)
    : {};
  return (
    <CModal open={open} setOpen={setOpen}>
      <Container component="main" className={classes.formContainer}>
        <Grid container spacing={2}>
          {formTemplate ? (
            <PreviewTemplateContents
              name={name}
              heading={heading}
              subheading={subheading}
              fields={fields}
            />
          ) : null}
          <Grid item xs={12}>
            <CButtonPair
              submitText="Close"
              action={() => {
                setOpen(false);
              }}
              errors={[]}
            />
          </Grid>
        </Grid>
      </Container>
    </CModal>
  );
};

export const extractFormTemplateParams = (
  formTemplateDTO,
  includeErrorFields = false
) => {
  const name = formTemplateDTO.templateName;
  const heading = formTemplateDTO.templateHeading;
  const subheading = formTemplateDTO.templateSubHeading;
  const fields = formTemplateDTO.templateFields.map((field, i) => {
    const type = field.fieldType;
    const label = field.fieldName;
    const id = `${i + 1}`;
    const required = field.isRequired;
    const options = ["singleSelect", "multiSelect"].includes(type)
      ? field.options
      : undefined;
    return {
      type,
      label,
      id,
      required,
      options,
      ...(includeErrorFields
        ? {
            labelError: null,
            optionsError: ["singleSelect", "multiSelect"].includes(type)
              ? field.options.map((_) => null)
              : undefined,
          }
        : {}),
    };
  });
  return {
    name,
    heading,
    subheading,
    fields,
  };
};

export const PreviewTemplateContents = (props) => {
  const { name, heading, subheading, fields } = props;
  return (
    <React.Fragment>
      <CTitle title={heading || name} />
      {subheading ? (
        <Grid item xs={12}>
          <Typography>{subheading}</Typography>
        </Grid>
      ) : null}
      <Form inputFields={mapFieldsToFormObjects(fields)} onlyFormContents />
      <Grid item xs={12}>
        <Typography>
          *Above is a preview of the "{name}" form template.
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

export const mapFieldsToFormObjects = (fields) => {
  const formFields = [];
  for (let i = 0; i < fields.length; i++) {
    formFields.push({
      label: fields[i].label,
      name: `field-${fields[i].id}`,
      validator: typeToValidator[fields[i].type][fields[i].required ? 1 : 0],
      choices: ["singleSelect", "multiSelect"].includes(fields[i].type)
        ? fields[i].options.map((option) => [option, option])
        : undefined,
      ...typeToProps[fields[i].type],
    });
  }
  return formFields;
};

const typeToProps = {
  text: { type: "TextField" },
  textArea: {
    type: "TextArea",
    moreProps: {
      minRows: 4,
      maxRows: 10,
    },
  }, // Currently this only used for default form template
  singleSelect: { type: "SingleSelect" },
  multiSelect: { type: "MultiSelect" },
  date: { type: "Date" },
  number: { type: "Number" },
  confirm: { type: "CheckBox" },
  "yes/no": {
    type: "RadioSet",
    choices: [
      ["Yes", "yes"],
      ["No", "no"],
    ],
  },
};

// The first index in each entry is the validator when not required and the second in each entry is the validator in each entry
const typeToValidator = {
  text: [maxTextField, [maxTextField, notEmpty]],
  textArea: [maxTextArea, [maxTextArea, notEmpty]], // Currently this only used for default form template
  singleSelect: [
    undefined,
    (value) => (!value ? "Must select an option." : null),
  ],
  multiSelect: [
    undefined,
    (value) => (!value?.length ? "Must select an option." : null),
  ],
  date: [undefined, (value) => (!value ? "Must select a date." : null)],
  number: [
    undefined,
    (value) => (value === null ? "Must provide a number." : null),
  ],
  confirm: [undefined, (value) => (!value ? "Must be checked" : null)],
  "yes/no": [
    undefined,
    (value) => (value === null ? "Must select yes or no" : null),
  ],
};

export default PreviewFormTemplate;
