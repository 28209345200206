import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import CheckIcon from "@material-ui/icons/Check";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  listOfCheckInSessions,
  listOfCheckInSites,
  performCheckIn,
  performCheckOut,
  withLoading,
  performCheckInClear
} from "../../utils";
import EmptyListImage from "../EmptyListImage/Empty";
import GeneralDialog from "../GeneralDialog/GeneralDialog";
import ListTable from "../TableComponents/ListTable";

const useStyles = makeStyles((theme) => ({
  comp: {
    width: "100%",
  },
  formLabel: {
    fontSize: 14,
    color: "#3C4257",
  },
  fieldText: {
    fontSize: 16,
    color: "#1A1F36",
    padding: "10px",
  },
  selectPadding: {
    paddingLeft: "31px",
    paddingRight: "20px",
  },

  showChoice: {
    fontSize: 14,
    color: "#1A1F36",
    padding: "10px",
  },
  helperText: {
    marginRight: 14,
    marginLeft: 14,
  },
  selected: {
    fontSize: 16,
    color: theme.palette.primary.main,
    padding: "10px",
  },
  check: {
    fontSize: 14,
    paddingRight: 7,
  },
  menuPaper: {
    maxHeight: 220,
  },
  noPadding: {
    padding: 0,
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
    width: "100vw",
  },
  mainContainer: {
    backgroundColor: theme.palette.accent2.main,
    padding: "20px",
    borderRadius: "8px",
    width: "calc(100% - 40px)",
    height: "calc(100% - 40px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    paddingBottom: "20px !important",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  root: {
    margin: "30px",
    backgroundColor: "white",
  },
  cardButtonHolder: {
    display: " flex",
    justifyContent: "center",
    alignItems: "flex-end",
    paddingBottom: "5px",
    flexGrow: 1,
    paddingTop: "20px",
  },
  name: {
    fontSize: "24px",
  },
  noLeftPadding: {
    paddingLeft: "0px",
  },
  description: {
    fontSize: "16px",
  },
  nameHolder: {
    paddingBottom: "10px",
  },
  descriptionHolder: {
    maxHeight: "144px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  buttonText: {
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  labelText: {
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const logPresenceTableHeader = [
  {
    name: "Session #",
    columnKey: "sessionNumber",
    columnWidth: "150px",
  },
  {
    name: "Attendee Name",
    columnKey: "attendeeName",
    columnWidth: "300px",
    tooltip: true,
  },
  {
    name: "Service Name",
    columnKey: "serviceName",
    columnWidth: "300px",
    tooltip: true,
  },
  {
    name: "Check In Time",
    columnKey: "checkInTime",
    columnWidth: "200px",
    type: "Normal",
  },
  {
    name: "Check Out Time",
    columnKey: "checkOutTime",
    columnWidth: "200px",
    type: "Normal",
  },
  {
    name: "",
    columnKey: "action",
    columnWidth: "50px",
    type: "Normal",
  },
];

const LogPresence = (props) => {
  const mountedRef = useRef(false);
  const bigEnough = useMediaQuery("(min-width:750px)");
  const classes = useStyles();
  const [siteList, setSiteList] = useState([]);
  const [site, setSite] = useState(null);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [sessionList, setSessionList] = useState(null);
  const [name, setName] = useState("");
  const [checkInSession, setCheckInSession] = useState(null);
  const [checkOutSession, setCheckOutSession] = useState(null);
  const [checkInSessionToClear, setCheckInSessionToClear] = useState(null);
  const [clearDataModal, setClearDataModal] = useState(false);

  const handleClick = (clickedSession) => {
    if (clickedSession.actionAllowed === "CheckIn") {
      setCheckInSession(clickedSession);
    } else if (clickedSession.actionAllowed === "CheckOut") {
      setCheckOutSession(clickedSession);
    }
  };

  const handleClearClick = (clickedSession) => {
    setCheckInSessionToClear(clickedSession);
    setClearDataModal(true);
  };

  const filteredSessions = [];
  if (Array.isArray(sessionList)) {
    for (
      let sessionList_i = 0;
      sessionList_i < sessionList.length;
      sessionList_i++
    ) {
      const upperName = name.toUpperCase();
      if (
        sessionList[sessionList_i].attendeeName
          .toUpperCase()
          .includes(upperName)
      ) {
        filteredSessions.push(sessionList[sessionList_i]);
      }
    }
  }

  const fetchListOfCheckInSites = async () => {
    const response = await withLoading(listOfCheckInSites, {
      businessId: props.businessId,
    });
    if (
      mountedRef.current &&
      !response.error &&
      Array.isArray(response.siteList)
    ) {
      setSiteList(
        response.siteList.map((site) => [site.siteName, site.siteId])
      );
    }
  };
  useEffect(() => {
    mountedRef.current = true;
    fetchListOfCheckInSites(props.businessId, setSiteList, mountedRef);
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const fetchListOfCheckIns = async () => {
    const response = await withLoading(listOfCheckInSessions, {
      businessId: props.businessId,
      checkInDate: date.replaceAll("-", "/"),
      siteId: site,
    });
    if (
      mountedRef.current &&
      !response.error &&
      Array.isArray(response.sessionList)
    ) {
      setSessionList(response.sessionList);
    }
  };

  const refreshEnabled = () => {
    const momentDate = moment(date);
    return (
      site !== null &&
      date !== "" &&
      momentDate !== null &&
      momentDate.year() < 3000
    );
  };

  useEffect(() => {
    if (refreshEnabled()) {
      fetchListOfCheckIns();
    }
  }, [site, date]);

  return (
    <React.Fragment>
        <GeneralDialog
        open={clearDataModal}
        close={() => {
          setCheckInSessionToClear(null);
          setClearDataModal(false);
        }}
        title={"Clear check in/out information."}
        text={`Are you sure you want to clear check in/out information?`}
        action={async () => {
          const response = await withLoading(performCheckInClear, {
            businessId: props.businessId,
            sessionId: checkInSessionToClear.sessionId,
          });

          if (response?.error === false) {
            setCheckInSession(null);
            setClearDataModal(false);
            fetchListOfCheckIns();
          }
        }}
      />
      <GeneralDialog
        open={checkInSession}
        close={() => {
          setCheckInSession(null);
        }}
        title={"Check Attendee In"}
        text={`Are you sure you want to check the attendee in?`}
        action={async () => {
          const response = await withLoading(performCheckIn, {
            businessId: props.businessId,
            sessionId: checkInSession.sessionId,
          });

          if (response?.error === false) {
            setCheckInSession(null);
            fetchListOfCheckIns();
          }
        }}
      />
      <GeneralDialog
        open={checkOutSession}
        close={() => {
          setCheckOutSession(null);
        }}
        title={"Check Attendee Out"}
        text={`Are you sure you want to check the attendee out?`}
        action={async () => {
          const response = await withLoading(performCheckOut, {
            businessId: props.businessId,
            sessionId: checkOutSession.sessionId,
          });

          if (response?.error === false) {
            setCheckOutSession(null);
            fetchListOfCheckIns();
          }
        }}
      />
      <div className={classes.root}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} className={classes.title}>
            <div
              className={classes.headerPadding}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                className={
                  bigEnough ? classes.topHeading : classes.mobileTopHeadingOne
                }
                style={{ flexGrow: "1" }}
              >
                Log Presence
              </span>
              <span>
                <IconButton
                  color="primary"
                  disabled={!refreshEnabled()}
                  onClick={fetchListOfCheckIns}
                >
                  <RefreshIcon />
                </IconButton>
              </span>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={bigEnough ? undefined : 12}>
                <Typography className={classes.formLabel}>Date</Typography>
                <TextField
                  style={{
                    width: bigEnough ? "200px" : "100%",
                  }}
                  variant="outlined"
                  type="date"
                  value={date}
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                  className={classes.textField}
                  InputProps={{
                    classes: {
                      input: classes.fieldText,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={bigEnough ? undefined : 12}>
                <Typography className={classes.formLabel}>Site</Typography>
                <Select
                  style={{
                    width: bigEnough ? "200px" : "100%",
                  }}
                  variant="outlined"
                  MenuProps={{
                    classes: { paper: classes.menuPaper },
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    transformOrigin: { vertical: "top", horizontal: "center" },
                  }}
                  renderValue={(selected) => {
                    for (let i = 0; i < siteList.length; i++) {
                      if (siteList[i][1] === selected) {
                        return siteList[i][0];
                      }
                    }
                    return "";
                  }}
                  value={site}
                  onChange={(e) => {
                    setSite(e.target.value);
                  }}
                  inputProps={{
                    className: classes.fieldText,
                  }}
                >
                  {siteList.map((pair, index) => {
                    return (
                      <MenuItem
                        key={`${props.name}-${index}`}
                        value={pair[1]}
                        style={{ display: "block !important" }}
                        classes={{
                          root: classes.noPadding,
                        }}
                      >
                        <Typography
                          className={
                            site === pair[1]
                              ? `${classes.selected} ${classes.selectPadding}`
                              : `${classes.fieldText} ${classes.selectPadding}`
                          }
                          style={{
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {pair[1] === site ? (
                            <CheckIcon className={classes.check} />
                          ) : null}
                          {pair[0]}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={bigEnough ? undefined : 12}>
                <Typography className={classes.formLabel}>Name</Typography>
                <TextField
                  style={{
                    width: bigEnough ? "200px" : "100%",
                  }}
                  variant="outlined"
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  className={classes.textField}
                  InputProps={{
                    classes: {
                      input: `${classes.fieldText} ${classes.noLeftPadding}`,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              {site === null || date === "" || sessionList === null ? (
                <div>Please select a site and date to view logs</div>
              ) : filteredSessions.length > 0 && bigEnough ? (
                <div style={{ width: "100%", overflowX: "auto" }}>
                  <ListTable
                    styling={{ minWidth: "100%" }}
                    identifier={"Log Pressence"}
                    tableHeaders={logPresenceTableHeader}
                    tableRows={filteredSessions.map((session, index) => {
                      const checkInTime = session.checkInTime ? (
                        <Typography>{session.checkInTime}</Typography>
                      ) : session.actionAllowed === "CheckIn" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleClick(session)}
                        >
                          <Typography style={{ fontSize: 14 }}>
                            Check In
                          </Typography>
                        </Button>
                      ) : null;

                      const checkOutTime = session.checkOutTime ? (
                        <Typography>{session.checkOutTime}</Typography>
                      ) : session.actionAllowed === "CheckOut" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleClick(session)}
                        >
                          <Typography style={{ fontSize: 14 }}>
                            Check Out
                          </Typography>
                        </Button>
                      ) : null;

                      const action = session.clearCheckIn ? (
                        <IconButton
                          color="primary"
                          onClick={() => handleClearClick(session)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : null;

                      return {
                        id: session.sessionId,
                        sessionNumber: session.sessionNumber,
                        serviceName: session.serviceName,
                        attendeeName: session.attendeeName,
                        checkInTime,
                        checkOutTime,
                        action,
                      };
                    })}
                  />
                </div>
              ) : filteredSessions.length > 0 && !bigEnough ? (
                <Grid container spacing={2}>
                  {filteredSessions.map((session, index) => (
                    <Grid item xs={12} key={`Log-Pressence-Mobile-${index}`}>
                      <div
                        style={{
                          border: "1px solid black",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        onClick={() => {
                          handleClick(session);
                        }}
                      >
                        <Typography>
                          <span className={classes.labelText}>Session #:</span>{" "}
                          <span className={classes.valueText}>
                            {session.sessionNumber}
                          </span>
                        </Typography>
                        <Typography>
                          <span className={classes.labelText}>Name:</span>{" "}
                          <span className={classes.valueText}>
                            {session.attendeeName}
                          </span>
                        </Typography>
                        <Typography>
                          <span className={classes.labelText}>Service:</span>{" "}
                          <span className={classes.valueText}>
                            {session.serviceName}
                          </span>
                        </Typography>
                        <Typography>
                          <span className={classes.labelText}>Check In:</span>{" "}
                          <span className={classes.valueText}>
                            {session.checkInTime ?? "N/A"}
                          </span>
                        </Typography>
                        <Typography>
                          <span className={classes.labelText}>Check Out:</span>{" "}
                          <span className={classes.valueText}>
                            {session.checkOutTime ?? "N/A"}
                          </span>
                        </Typography>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <EmptyListImage name="Sessions" offset="200px" />
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default LogPresence;
