import { Container, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  getFormTemplateList,
  getFormTemplateListStaff,
  submitFeedback,
  submitFeedbackStaff,
  withLoading,
} from "../../../../../../utils";
import CButtonPair from "../../../../../Form/CButtonPair";
import CModal from "../../../../../Form/CModal";
import CModalError from "../../../../../Form/CModalError";
import CSingleSelect from "../../../../../Form/CSingleSelect";
import CTitle from "../../../../../Form/CTitle";
import Form from "../../../../../Form/Form";
import {
  extractFormTemplateParams,
  mapFieldsToFormObjects,
  useStyles,
} from "./PreviewFormTemplate";

const DEFAULT_TEMPLATE_DTO = {
  formTemplateId: "Default",
  templateName: <i>Default Feedback</i>,
};

const DEFAULT_TEMPLATE = {
  name: "Default Feedback",
  heading: "Default Feedback",
  subheading: "",
  fields: [
    {
      id: "0",
      label: "Notes",
      type: "textArea",
      required: true,
    },
  ],
};

const isUTCPassed = (utcTime) => {
  const curMoment = new Date();
  const refMomemt = new Date(utcTime);
  return curMoment > refMomemt;
};

export const FeedbackFormWithStarTimeGate = (props) => {
  const { open, setOpen, intent, fetchDetails, staff, eventEndTimeUTC } = props;
  const classes = useStyles();
  if (isUTCPassed(eventEndTimeUTC)) {
    return (
      <FeedbackForm
        open={open}
        setOpen={setOpen}
        intent={intent}
        fetchDetails={fetchDetails}
        staff={staff}
      />
    );
  } else {
    return (
      <CModal open={open} setOpen={setOpen}>
        <Container component="main" className={classes.formContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                Please wait until the event has completed to provide feedback.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CButtonPair
                submitText="Close"
                action={() => {
                  setOpen(false);
                }}
                errors={[]}
              />
            </Grid>
          </Grid>
        </Container>
      </CModal>
    );
  }
};

const FeedbackForm = (props) => {
  const { open, setOpen, intent, fetchDetails, staff } = props;
  const classes = useStyles();
  const [formId, setFormId] = useState(DEFAULT_TEMPLATE_DTO.formTemplateId);
  const [formTemplateList, setFormTemplateList] = useState([
    DEFAULT_TEMPLATE_DTO,
  ]);
  const [selectingForm, setSelectingForm] = useState(true);

  useEffect(() => {
    if (open) {
      setSelectingForm(true);
      setFormId(DEFAULT_TEMPLATE_DTO.formTemplateId);
      setFormTemplateList([DEFAULT_TEMPLATE_DTO]);
    }
  }, [open]);

  return (
    <CModal open={open} setOpen={setOpen}>
      <Container component="main" className={classes.formContainer}>
        <Grid container spacing={2}>
          {selectingForm ? (
            <SelectFormTemplate
              formId={formId}
              setFormId={setFormId}
              formTemplateList={formTemplateList}
              setFormTemplateList={setFormTemplateList}
              setOpen={setOpen}
              selectingForm={setSelectingForm}
              setSelectingForm={setSelectingForm}
              staff={staff}
            />
          ) : (
            <GiveFeedback
              setOpen={setOpen}
              setSelectingForm={setSelectingForm}
              formId={formId}
              formTemplateList={formTemplateList}
              intent={intent}
              fetchDetails={fetchDetails}
              staff={staff}
            />
          )}
        </Grid>
      </Container>
    </CModal>
  );
};

const SelectFormTemplate = (props) => {
  const {
    formId,
    setFormId,
    formTemplateList,
    setFormTemplateList,
    setOpen,
    selectingForm,
    setSelectingForm,
    staff,
  } = props;

  const mountedRef = useRef(false);
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const fetchFormTemplateList = async () => {
    const formTemplatePayload = await withLoading(
      staff ? getFormTemplateListStaff : getFormTemplateList,
      {
        businessId: businessId,
      }
    );

    if (
      mountedRef.current &&
      formTemplatePayload &&
      formTemplatePayload.formTemplateList
    ) {
      setFormTemplateList([
        DEFAULT_TEMPLATE_DTO,
        ...formTemplatePayload.formTemplateList,
      ]);
    }
  };

  useEffect(() => {
    mountedRef.current = true;
    if (selectingForm) {
      fetchFormTemplateList();
    }
    return () => {
      mountedRef.current = false;
    };
  }, [selectingForm]);

  return (
    <React.Fragment>
      <CTitle title={"Select Form Template"} />
      <CModalError />
      <Grid item xs={12}>
        <CSingleSelect
          onchange={(event) => {
            setFormId(event.target.value);
          }}
          name={`formId`}
          label={`Select Feedback Form Template`}
          choices={formTemplateList.map((formTemplate) => [
            formTemplate.templateName,
            formTemplate.formTemplateId,
          ])}
          enabled={true}
          initialvalue={formId}
          errortext={
            formTemplateList.find(
              (formTemplate) => formTemplate.formTemplateId === formId
            ) === undefined
              ? "Please select a form template"
              : null
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CButtonPair
          submitText="Next"
          cancelEnabled
          cancelText="Close"
          action={() => {
            setSelectingForm(false);
          }}
          cancelAction={() => {
            setOpen(false);
          }}
          errors={[]}
          disableSubmit={
            formTemplateList.find(
              (formTemplate) => formTemplate.formTemplateId === formId
            ) === undefined
          }
        />
      </Grid>
    </React.Fragment>
  );
};

const GiveFeedback = (props) => {
  const {
    setOpen,
    setSelectingForm,
    formId,
    formTemplateList,
    intent,
    fetchDetails,
    staff,
  } = props;
  const formTemplate = formTemplateList.find(
    (ft) => ft.formTemplateId === formId
  );
  if (formTemplate === undefined) {
    return <ErrorPage setOpen={setOpen} />;
  } else {
    return (
      <GiveFeedbackShared
        setOpen={setOpen}
        setSelectingForm={setSelectingForm}
        formSpec={
          formTemplate.formTemplateId === DEFAULT_TEMPLATE_DTO.formTemplateId
            ? DEFAULT_TEMPLATE
            : extractFormTemplateParams(formTemplate)
        }
        intent={intent}
        fetchDetails={fetchDetails}
        staff={staff}
      />
    );
  }
};

const GiveFeedbackShared = (props) => {
  const { setOpen, setSelectingForm, formSpec, intent, fetchDetails, staff } =
    props;
  const { name, heading, subheading, fields } = formSpec;
  const mountedRef = useRef(false);
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const formFields = mapFieldsToFormObjects(fields);

  return (
    <Form
      title={heading || name}
      subtitle={subheading}
      cancelEnabled
      cancelAction={() => {
        setOpen(false);
      }}
      clearEnabled
      clearAction={() => {
        setSelectingForm(true);
      }}
      clearText="Back"
      submitText="Submit"
      action={async (payload) => {
        const feedback = formFields.map((field, i) => ({
          fieldValue: convertFormValueToString[fields[i].type](
            payload[field.name]
          ),
          fieldName: field.label,
        }));
        return await (staff ? submitFeedbackStaff : submitFeedback)({
          businessId,
          ...intent,
          feedback,
        });
      }}
      postAction={(response) => {
        if (response && !response.error && mountedRef.current) {
          setOpen(false);
          fetchDetails();
        }
      }}
      inputFields={formFields}
    />
  );
};

const ErrorPage = (props) => {
  const { setOpen } = props;
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography>
          There was an error. Please close the form and try again later.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CButtonPair
          submitText="Close"
          action={() => {
            setOpen(false);
          }}
          errors={[]}
        />
      </Grid>
    </React.Fragment>
  );
};

export const intentForClass = (id) => ({
  intent: "Class",
  intentKey: id,
});

export const intentForSession = (id) => ({
  intent: "Session",
  intentKey: id,
});

const convertFormValueToString = {
  text: (input) => input ?? "",
  textArea: (input) => input ?? "", // Currently this only used for default form template
  singleSelect: (input) => input ?? "",
  multiSelect: (input) => (input?.length > 0 ? input.join(", ") : ""),
  date: (input) => (input ? moment(input).format("M/D/YYYY") : ""),
  number: (input) => (Number.isNaN(input) ? "" : input.toString()),
  confirm: (input) => (input ? "Confirmed" : "Not Confirmed"),
  "yes/no": (input) => (input ? "Yes" : "No"),
};

export default FeedbackForm;
