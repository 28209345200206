import { Button, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { pricing, retrieveGetParams, withLoading } from "../../../../../../utils";
import CurrencyPreProcessor from "../../../../../CurrencyFormatter/CurrencyFormatter";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import PostFilter, { applyFilters } from '../../../../../Filter/PostFilter';
import ListCard from "../../../../../MobileCards/ListCard";
import StatusTag from "../../../../../TagComponents/StatusTag";
import ListTable from "./../../../../../TableComponents/ListTable";
import AddPricingForm from "./AddPricingForm";
import EmailCustomerMassForm from "./EmailCustomerMassForm";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  buttonBasic: {
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
}));

const keyObjects = [
  {
    key: 'isActive',
    label: "Status",
    keyLabel: 'statusName'
  },
  {
    key: 'siteName',
    label: "Site",
    keyLabel: 'siteName'
  },
  {
    key: 'serviceName',
    label: "Service",
    keyLabel: 'serviceName'
  },
  {
    key: 'type',
    label: "Type",
    keyLabel: 'type'
  },
]


const pricingHeaders = [
  {
    name: "status",
    columnKey: "status",
    columnWidth: "100px",
    type: "Normal",
  },
  {
    name: "name",
    columnKey: "name",
    columnWidth: "35%",
    tooltip: true,
  },

  {
    name: "site",
    columnKey: "siteName",
    columnWidth: "30%",
    tooltip: true,
  },

  {
    name: "service",
    columnKey: "serviceName",
    columnWidth: "35%",
    tooltip: true,
  },
  {
    name: "price",
    columnKey: "transPrice",
    columnWidth: "100px",
    tooltip: true,
  },
  {
    name: "type",
    columnKey: "type",
    columnWidth: "100px",
  },
];

const PricingOptionsList = (props) => {
  const bigEnough = useMediaQuery("(min-width:750px)");
  const [add, setAdd] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation()
  const queryParams = retrieveGetParams(location)
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const [pricingList, setPricingList] = useState(null);

  const processPricingData = (priceData) => {
    return priceData.map((pricing) => {
      let { optionId, name, isActive, type, siteName, serviceName, price } =
        pricing;

      let status = (
        <StatusTag
          wrapperName={isActive ? "greenWrapper" : "redWrapper"}
          value={isActive ? "Active" : "Inactive"}
        />
      );
      let id = optionId;
      let transPrice = (
        CurrencyPreProcessor({
          amount: price,
          currencyBreak: props.currencyInfo.currencyBreak,
          roundingFactor: props.currencyInfo.roundingFactor,
          currencySymbol: props.currencyInfo.currencySymbol
        }) + ` ${props.currencyInfo.currencyShortName}`

      );

      return {
        id,
        status,
        name,
        type,
        siteName,
        statusName: isActive ? 'Active' : 'Not Active',
        serviceName,
        transPrice,
        isActive
      };
    });
  };

  const getPriceDetails = async () => {
    let pricePayload = await pricing({
      businessId: businessId,
      serviceIds: [],
      siteIds: [],
      optionTypes: [],
      optionStatus: [],
    });
    if (pricePayload && pricePayload.options) {
      setPricingList(processPricingData(pricePayload.options));
    }
  };

  const navigateToPricingOption = (optionId) => {
    history.push(`/dashboard/${businessId}/Pricings/${optionId}`);
  };

  useEffect(async () => {
    await withLoading(getPriceDetails, {}, true)
  }, []);

  const ViewChanger = () => {
    if (bigEnough) {
      return (
        <React.Fragment>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <ListTable
                identifier={"pricingOptions"}
                tableHeaders={pricingHeaders}
                tableRows={applyFilters(pricingList, queryParams, keyObjects)}
                rowClick={navigateToPricingOption}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          {applyFilters(pricingList, queryParams, keyObjects).map((pricing) => {
            return (
              <ListCard
                action={() => {
                  history.push(
                    `/dashboard/${businessId}/Pricings/${pricing.id}`
                  );
                }}
                cardName={pricing.name}
                statusReplace={
                  <Typography
                    noWrap
                    style={!pricing.isActive ? { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "red" } : { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "green" }}>
                    {pricing.isActive ? "Active" : "Inactive"}
                  </Typography>
                }
                other={pricing.type}
              />
            );
          })}
        </Grid>
      );
    }
  };

  if (pricingList !== null) {
    return (
      <React.Fragment>
        <AddPricingForm
          open={add}
          setOpen={setAdd}
          currencyInfo={props.currencyInfo}
          basePath={props.basePath}
        />
        <EmailCustomerMassForm
          open={emailOpen}
          setOpen={setEmailOpen}
        />
        <Grid
          container
          className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
        >
          <Grid item xs={12}>
            <div className={classes.headerPadding}>
              <span
                className={
                  bigEnough ? classes.topHeading : classes.mobileTopHeadingOne
                }
              >
                Pricings
              </span>
              <span title="Email Customers" className={classes.rightElem}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: '10px', textTransform: 'none' }}
                  onClick={() => {
                    setEmailOpen(true);
                  }}
                >
                  <EmailIcon />
                </Button>
              </span>
              <span title="Add Pricing Option" className={classes.rightElem}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: '10px', textTransform: 'none' }}
                  onClick={() => {
                    setAdd(true);
                  }}
                >
                  Add
                </Button>
              </span>
              <PostFilter allEntries={pricingList} keyObjects={keyObjects} />
            </div>
          </Grid>
          {Array.isArray(pricingList) && pricingList.length > 0 ? (
            <ViewChanger />
          ) : (
            <EmptyListImage name="Pricing Options" />
          )}
        </Grid>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default PricingOptionsList;
