import { Button, ButtonGroup, Container, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { convertToDate } from "../../FormValidation/validDates";
import { encodeGetParams, genericStaffInfo, getGenericDetails, retrieveGetParams, withLoading } from "../../utils";
import CModal from '../Form/CModal';
import Form from '../Form/Form';


const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: '100vw'
    },
    buttonGroup: {
        float: "right",

    },
    button: {
        height: '36.5px'
    },
    buttonText: {
        fontSize: '14px',
        textTransform: 'none'
    },
    icon: {
        height: '20px'
    }

}));


const setup = async (setGenericDetails, businessId, staff) => {
    let response
    if (staff) {
        response = await withLoading(genericStaffInfo, {
            businessId
        })
    }
    else {
        response = await withLoading(getGenericDetails, {
            businessId
        })
    }

    if (response && !response.error) {
        setGenericDetails(response.genericDetails)
    }
}
// filterOptions = array of fitler options youu want to edit. ex. ['service', site', 'dateRange']
const Filter = (props) => {
    const { filterFields, businessId, staff } = props;
    const location = useLocation()
    const classes = useStyles()
    const history = useHistory()
    const [genericDetails, setGenericDetails] = useState(null)
    const [open, setOpen] = useState()
    useEffect(() => {
        if (open) {
            setup(setGenericDetails, businessId, staff)
        }
    }, [businessId, filterFields, staff, open])
    const filterFieldSet = new Set(filterFields)
    const queryParams = retrieveGetParams(location)
    const active = ['service', 'site', 'start', 'end', 'cId', 'aId', 'hId', 'status', 'staffId'].some(param => queryParams[param])

    return (
        <React.Fragment>

            <ButtonGroup className={classes.buttonGroup} >
                <Button
                    variant="contained" color={active ? 'primary' : undefined}
                    className={classes.button}
                    onClick={() => { setOpen(true) }}
                >
                    <Typography className={classes.buttonText}>
                        Filter
                    </Typography>
                </Button>
                {active ?
                    <Button
                        style={{ width: 0, padding: 0 }}
                        color="primary"
                        variant="outlined"
                        className={classes.button}
                        onClick={async () => {
                            history.push(location.pathname + '?' + encodeGetParams({ pg: queryParams.pg ? Number(queryParams.pg) : 1 }))
                        }}
                    >
                        <CloseIcon className={classes.icon} />
                    </Button>
                    : null}
            </ButtonGroup>

            <CModal
                open={open}
                setOpen={setOpen}
            >
                <Container className={classes.formContainer}>
                    <Form

                        title="Update Filter"
                        cancelEnabled
                        clearEnabled
                        clearAction={async (newParams) => {
                            setOpen(false)
                            history.push(location.pathname + '?' + encodeGetParams({ pg: queryParams.pg ? Number(queryParams.pg) : 1 }))
                        }}
                        cancelAction={async () => { setOpen(false) }}
                        submitText="Filter"
                        action={async (newParams) => {
                            setOpen(false)
                            history.push(location.pathname + '?' + encodeGetParams({
                                pg: 1,
                                service: newParams.service ? newParams.service : undefined,
                                site: newParams.site ? newParams.site : undefined,
                                start: newParams.start ? newParams.start : undefined,
                                end: newParams.end ? newParams.end : undefined,
                                cId: newParams.customer ? newParams.customer[1] : undefined,
                                cName: newParams.customer ? newParams.customer[0] : undefined,
                                aId: newParams.attendee ? newParams.attendee[1] : undefined,
                                aName: newParams.attendee ? newParams.attendee[0] : undefined,
                                hId: newParams.host ? newParams.host[1] : undefined,
                                hName: newParams.host ? newParams.host[0] : undefined,
                                staffId: newParams.staff ? newParams.staff[1] : undefined,
                                staffName: newParams.staff ? newParams.staff[0] : undefined,
                                status: newParams.status ? newParams.status : undefined
                            }))
                        }}
                        inputFields={[
                            {
                                type: "SingleSelect",
                                name: "service",
                                label: 'Service',
                                initialvalue: queryParams.service ? queryParams.service : undefined,
                                enabled: filterFieldSet.has('service'),
                                choices: genericDetails?.serviceDetails ?
                                    genericDetails.serviceDetails.map(service => [service.name, service.serviceId])
                                    : []
                            },
                            {
                                type: "SingleSelect",
                                name: "site",
                                label: 'Site',
                                initialvalue: queryParams.site ? queryParams.site : undefined,
                                enabled: filterFieldSet.has('site'),
                                choices: genericDetails?.siteDetails ?
                                    genericDetails.siteDetails.map(site => [site.name, site.siteId])
                                    : []
                            },
                            {
                                type: "DateRange",
                                name: ['start', 'end'],
                                label: ['Start Date', 'End Date'],
                                initialvalue: queryParams.start && queryParams.end ? [queryParams.start, queryParams.end] : undefined,
                                enabled: filterFieldSet.has('dateRange'),
                                validator: [
                                    value => (Boolean(!value[0] && value[1]) ? ['Both dates must be provided', null] : [null, null]),
                                    value => (Boolean(!value[1] && value[0]) ? [null, 'Both dates must be provided'] : [null, null]),
                                    value => {

                                        if (!value[1] && !value[0]) {
                                            return [null, null]
                                        }
                                        else if (convertToDate(value[0]) > convertToDate(value[1])) {
                                            return ["Start cannot be after end", null]
                                        }
                                        else {
                                            return [null, null]
                                        }
                                    }
                                ]

                            },
                            {
                                type: "AutoComplete",
                                name: 'customer',
                                label: 'Customer',
                                initialvalue: queryParams.cId && queryParams.cName ? [queryParams.cName, queryParams.cId] : undefined,
                                enabled: filterFieldSet.has('customer'),
                                choices: genericDetails?.customerDetails ?
                                    genericDetails.customerDetails.map(customer => [`${customer.firstName} ${customer.lastName}`, customer.customerId])
                                    : [],
                                validator: []
                            },
                            {
                                type: "AutoComplete",
                                name: 'attendee',
                                label: 'Attendee',
                                initialvalue: queryParams.aId && queryParams.aName ? [queryParams.aName, queryParams.aId] : undefined,
                                enabled: filterFieldSet.has('attendee'),
                                choices: genericDetails?.attendeeDetails ?
                                    genericDetails.attendeeDetails.map(attendee => [`${attendee.firstName} ${attendee.lastName}`, attendee.shareId])
                                    : [],
                                validator: []
                            },
                            {
                                type: "AutoComplete",
                                name: 'host',
                                label: 'Host',
                                initialvalue: queryParams.hId && queryParams.hName ? [queryParams.hName, queryParams.hId] : undefined,
                                enabled: filterFieldSet.has('host') && genericDetails?.staffDetails,
                                choices: genericDetails?.staffDetails ?
                                    genericDetails.staffDetails.map(staff => [`${staff.firstName} ${staff.lastName}`, staff.staffId])
                                    : [],
                                validator: []
                            },
                            {
                                type: "AutoComplete",
                                name: 'staff',
                                label: 'Staff',
                                initialvalue: queryParams.staffId && queryParams.staffName ? [queryParams.staffName, queryParams.staffId] : undefined,
                                enabled: filterFieldSet.has('staff') && genericDetails?.staffDetails,
                                choices: genericDetails?.staffDetails ?
                                    genericDetails.staffDetails.map(staff => [`${staff.firstName} ${staff.lastName}`, staff.staffId])
                                    : [],
                                validator: []
                            },
                            {
                                type: "SingleSelect",
                                name: 'status',
                                label: 'Status',
                                initialvalue: queryParams.status ? queryParams.status : undefined,
                                enabled: filterFieldSet.has('status'),
                                choices: [
                                    ['Canceled', 'Canceled'],
                                    ['Not Canceled', 'Not Canceled']
                                ],
                                validator: []
                            },
                        ]}
                    />
                </Container>
            </CModal>
        </React.Fragment>
    )

}

export default Filter
