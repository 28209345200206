import { Button, Grid, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { customerAttendeeDetails, withLoading } from '../../../../utils';
import AttendeeDetailsTopBlock from '../../../AttendeeDetails/AttendeeDetailsTopBlock';
import ListTable from '../../../TableComponents/ListTable';
import AttendeeForm from './AttendeeForm';
const useStyles = makeStyles((theme) => ({
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    topBlock: {
        paddingBottom: "15px",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    rightElem: {
        float: "right",
    },
    buttontext: {
        textTransform: 'none'
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    subHeading: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
}))

const sharedHeaders = [
    {
        name: "name",
        columnKey: "name",
        columnWidth: "30%",
    },
    {
        name: "email",
        columnKey: "email",
        columnWidth: "30%",
    },
    {
        name: "phone",
        columnKey: "phoneNumber",
        columnWidth: "200px",
    },
    {
        name: "qualifications",
        columnKey: "qualifications",
        columnWidth: "40%",
        type: "Normal"
    }
]

const sharedHeadersM = [
    {
        name: "name",
        columnKey: "name",
        columnWidth: "30%",
    },
    {
        name: "email",
        columnKey: "email",
        columnWidth: "30%",
    },
]

const getAttendeeDetails = async (updateState, attendeeId) => {
    const detailsResponse = await withLoading(customerAttendeeDetails, { attendeeId })
    if (detailsResponse && !detailsResponse.error) {
        updateState({
            attendeeDetails: detailsResponse.attendeeDetails,
            sharedBusinessDetails: detailsResponse.sharedBusinessDetails
        })
    }
}

const AttendeeDetails = () => {

    const [details, setDetails] = useState(null)
    const attendeeDetails = details?.attendeeDetails
    const sharedBusinessDetails = details?.sharedBusinessDetails
    const [editOpen, setEditOpen] = useState(false)
    const { attendeeIdURL } = useParams();
    const classes = useStyles()
    const bigEnough = useMediaQuery("(min-width:750px )");

    useEffect(() => {
        getAttendeeDetails(setDetails, attendeeIdURL)
    }, [])

    if (attendeeDetails && sharedBusinessDetails) {
        return (
            <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                <Grid item xs={12}>
                    <div className={classes.topBlock}>
                        <span className={classes.topHeading}>ATTENDEE</span>
                        <span className={classes.rightElem}>
                            <AttendeeForm
                                reloadPage={() => { getAttendeeDetails(setDetails, attendeeIdURL) }}
                                attendee={attendeeDetails}
                                open={editOpen}
                                setOpen={setEditOpen}
                            />
                            <Button color="primary" variant="contained" onClick={() => { setEditOpen(true) }}>
                                <Typography className={classes.buttontext}>
                                    Edit
                                </Typography>
                            </Button>
                        </span>
                    </div>
                </Grid>
                <AttendeeDetailsTopBlock attendeeDetails={attendeeDetails} refetchDetails={() => { getAttendeeDetails(setDetails, attendeeIdURL) }} />
                <Grid item xs={12} style={{ paddingTop: !bigEnough ? '30px' : "0px" }}>
                    <div className={classes.headingWrapper}>
                        <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Shared With</Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        {
                            sharedBusinessDetails.length > 0
                                ?
                                <ListTable
                                    identifier={"shared"}
                                    tableHeaders={bigEnough ? sharedHeaders : sharedHeadersM}
                                    tableRows={sharedBusinessDetails.map(buisness => ({
                                        id: buisness.shareId,
                                        name: buisness.businessName,
                                        email: buisness.email,
                                        phoneNumber: buisness.phoneNumber,
                                        qualifications: buisness.qualifications?.length > 0 ? (
                                            <ul style={{ margin: 0, paddingLeft: 20 }}>
                                                {buisness.qualifications.map((qualification, index) => {
                                                    return (
                                                        <li key={`${buisness.shareId}-${index}`}>
                                                            <Typography noWrap className={classes.valueText}>
                                                                {qualification.name}
                                                            </Typography>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        ) : <Typography style={{ margin: 0, paddingLeft: 20 }}>None</Typography>
                                    }))}
                                />
                                :
                                <Typography>
                                    This attendee has not been shared with any buisnesses
                                </Typography>
                        }
                    </div>
                </Grid>
            </Grid>
        )
    }
    else {
        return null
    }
}
export default AttendeeDetails