import React, {useState, useRef, useEffect} from "react";
import FullCalendar, { isPropsEqual } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import {StyleWrapper, StyleWrapper2, StyleWrapper3} from "../Dashboard/BuisnessDashboard/AdminDashboard/AdminOptions/Packages/ScheduleCalendarStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import TodayIcon from '@material-ui/icons/Today';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {withStyles} from "@material-ui/styles";
import ListIcon from '@material-ui/icons/List';
import { CEllipsisIcon } from "../Icons/Icons";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import LightTooltip from "../Form/LightTooltip";
import { Typography } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import CModal from "../Form/CModal";


const BusinessCalendar = (props) => {
  const small = useMediaQuery('(min-width:960px)');
  const today = new Date();
  const [anchorEl, setAnchorEl]= useState(false);
  const [cView, setCView]= useState("listWeek");
  const calendarRef = useRef(null)
  const [pickerOpen, setPickerOpen] = useState(false)
  const [pickerDate, setPickerDate] = useState(moment())

  const CalTooltip = withStyles({
    arrow: {
      "&:before": {
        border: "1px solid black"
      },
      color: "white"
      
    },
    tooltip: {
      backgroundColor: "white",
    border: "1px solid black",
    color: "#4A4A4A",
    fontSize: 14
    }
  })(Tooltip);

  useEffect(() => {
    setPickerDate(moment())
  }, [small])


  if(small){
    return (
      <React.Fragment>

        
          <CModal open={pickerOpen} setOpen={() => { setPickerOpen(false) }}>
            <div style={{
              width: "100vw",
              maxWidth: "400px"
            }}>
              <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <DatePicker
                views={["date"]}
                orientation="portrait"
                variant="static"
                openTo="date"
                onChange={(val) => {
                  setPickerDate(val)
                  setPickerOpen(false)
                  calendarRef.current?.getApi().gotoDate(val.format("YYYY-MM-DD"))
                }}
                value={pickerDate}
              />
              </MuiPickersUtilsProvider>
            </div>
          </CModal>
        

      <StyleWrapper2>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          customButtons={{
            filterAButton: {
              text: 'Filter',
              click: props.openFilterDialog
            },

            filterButton: {
              text: 'Filter',
              click: props.openFilterDialog
            },
            clearFilterButton: {
              text: <Typography style={{ color: "white", backgroundColor: "#0079fc" }}>{props.filterNum}</Typography>
            },
            calendarButton: {
              text: <TodayIcon style={{ margin: -8 }} />,
              click: () => {
                setPickerDate(moment(calendarRef.current?.getApi().getDate()))
                setPickerOpen(true)

              }
            }
          }}
          scrollTime={`${today.getHours()}:${today.getMinutes()}:${today.getSeconds}`}
          scrollTimeReset={false}
          dayMaxEvents={2}
          views={{
            dayGridMonth: {
              titleFormat: {month:"long", year:"numeric"},
            },
            timeGridWeek: {
              titleFormat: {month:"long", day:"numeric", year:"numeric"}
            },
            timeGridDay: {
              titleFormat: {weekday:"short", month:"long", day:"numeric", year:"numeric"}
            },
          }}
          headerToolbar={{
            left: "prev today next calendarButton",
            center: "title",
            right: props.filters && props.filterNum > 0 ? "filterAButton,clearFilterButton dayGridMonth,timeGridWeek,timeGridDay" : "filterButton dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialView="timeGridWeek"
          slotEventOverlap={false}
          allDaySlot={false}
          slotDuration="00:30:00"
          nowIndicator={true}
          slotLabelInterval="01:00"
          events={props.getEvents}
          eventClick={props.eventClick}
          buttonText={{
            prev: <ArrowBackIosIcon style={{marginLeft:"4px", height:18, width:16}} />,
            next: <ArrowForwardIosIcon style={{height:18, width:16}} />,
            today:    'Today',
            month:    'Month',
            week:     'Week',
            day:      'Day'
          }}
          height={"calc(100vh - 128px)"}
        />
      </StyleWrapper2>
      </React.Fragment>
    );
  } else if (!small && cView === "listWeek" ) {
    return(
      <React.Fragment>



        <CModal open={pickerOpen} setOpen={() => { setPickerOpen(false) }}>
            <div style={{
              width: "100vw",
              maxWidth: "400px"
            }}>
              <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <DatePicker
                views={["date"]}
                orientation="portrait"
                variant="static"
                openTo="date"
                onChange={(val) => {
                  setPickerDate(val)
                  setPickerOpen(false)
                  calendarRef.current?.getApi().gotoDate(val.format("YYYY-MM-DD"))
                }}
                value={pickerDate}
              />
              </MuiPickersUtilsProvider>
            </div>
          </CModal>

        <StyleWrapper>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          customButtons= {{
              filterButton: {
              text: <FilterListIcon />,
              click: props.openFilterDialog
            },
            calendarButton: {
              text: <TodayIcon style={{ margin: -8 }} />,
              click: () => {
                setPickerDate(moment(calendarRef.current?.getApi().getDate()))
                setPickerOpen(true)

              }
            },
            dropdownButton: {
              text: 
                <div>
                  <Button style={{padding: "6px", marginLeft:-15, marginTop:-5}} onClick={(event)=>{setAnchorEl(event.currentTarget);}}><CEllipsisIcon /></Button>
                    <Menu
                        id="simple-menu" 
                        anchorEl={anchorEl} 
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={Boolean(anchorEl)} 
                        onClose={()=>{setAnchorEl(false);}}
                    >
                        <MenuItem style={props.filters && props.filterNum > 0 ? {backgroundColor:"#0079fc", border: "1px"} : null}>
                          <Button 
                            style={{ marginLeft:-5, marginRight:-5, textTransform:"none", fontSize:"14px", color:"#1af36"}} 
                            onClick={()=>{props.openFilterDialog(); setAnchorEl(false);}}
                          >
                            {props.filters && props.filterNum > 0 ? <font color="white">{`Filter ${props.filterNum}`}</font> : "Filter"}
                          </Button>
                        </MenuItem>
                        <MenuItem style={cView === "listWeek" ? {backgroundColor:"#E8E8E8"} : null}>
                          <Button 
                            style={{marginLeft:-5, marginRight:-5, textTransform:"none", fontSize:"14px", color:"#1af36"}} 
                            onClick={cView === "listWeek" ? ()=>{setAnchorEl(false);} : ()=>{setCView("listWeek"); props.setView("listWeek"); setAnchorEl(false);}}
                          >
                            List View
                          </Button>
                        </MenuItem>
                        <MenuItem style={cView === "timeGridDay" ? {backgroundColor:"#E8E8E8"} : null}>
                          <Button 
                            style={{marginLeft:-5, marginRight:-5, textTransform:"none", fontSize:"14px", color:"#1af36"}}
                            onClick={cView === "timeGridDay" ? ()=>{setAnchorEl(false);} : ()=>{setCView("timeGridDay"); props.setView("timeGridDay"); setAnchorEl(false);}}
                          >
                            Day View
                          </Button>
                        </MenuItem>
                    </Menu>
                </div>,
              click: null,
            }
          }}
          scrollTime={`${today.getHours()}:${today.getMinutes()}:${today.getSeconds}`}
          scrollTimeReset={false}
          views={{
            listWeek: {
              titleFormat: {month:"long", day:"numeric", year:"numeric"}
            },
            timeGridDay: {
              titleFormat: {weekday: 'short', month:"long", day:"numeric", year:"numeric"}
            },
          }}
          headerToolbar={{
            left: "calendarButton",
            center: "prev,title,next",
            right: "dropdownButton",
          }}
          initialView="listWeek"
          slotEventOverlap={false}
          allDaySlot={false}
          slotDuration="00:30:00"
          nowIndicator={true}
          slotLabelInterval="01:00"
          events={props.getEvents}
          eventClick={props.eventClick}
          buttonText={{
            prev: <ArrowBackIosIcon />,
            next: <ArrowForwardIosIcon />,
            today:    <TodayIcon />,
            week:     <ListIcon style={{height:30, width:30}} />,
            day:      <ViewDayIcon />
          }}
          height={"calc(100vh - 138px)"}
        />
      </StyleWrapper>
      </React.Fragment>
    );
  } else {
    return(
      <React.Fragment>

          <CModal open={pickerOpen} setOpen={() => { setPickerOpen(false) }}>
            <div style={{
              width: "100vw",
              maxWidth: "400px"
            }}>
              <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <DatePicker
                views={["date"]}
                orientation="portrait"
                variant="static"
                openTo="date"
                onChange={(val) => {
                  setPickerDate(val)
                  setPickerOpen(false)
                  calendarRef.current?.getApi().gotoDate(val.format("YYYY-MM-DD"))
                }}
                value={pickerDate}
              />
              </MuiPickersUtilsProvider>
            </div>
          </CModal>

          <StyleWrapper3>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            customButtons= {{
                filterButton: {
                text: <FilterListIcon />,
                click: props.openFilterDialog
              },
              calendarButton: {
                text: <TodayIcon style={{ margin: -8 }} />,
                click: () => {
                  setPickerDate(moment(calendarRef.current?.getApi().getDate()))
                  setPickerOpen(true)
  
                }
              },
              dropdownButton: {
                text: 
                  <div>
                    <Button style={{padding: "6px", marginLeft:-15, marginTop:-5}} onClick={(event)=>{setAnchorEl(event.currentTarget);}}><CEllipsisIcon /></Button>
                      <Menu
                          id="simple-menu" 
                          anchorEl={anchorEl} 
                          getContentAnchorEl={null}
                          keepMounted
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                          open={Boolean(anchorEl)} 
                          onClose={()=>{setAnchorEl(false);}}
                      >
                          <MenuItem style={props.filters && props.filterNum > 0 ? {backgroundColor:"#0079fc", border: "1px"} : null}>
                            <Button 
                              style={{ marginLeft:-5, marginRight:-5, textTransform:"none", fontSize:"14px", color:"#1af36"}} 
                              onClick={()=>{props.openFilterDialog(); setAnchorEl(false);}}
                            >
                              {props.filters && props.filterNum > 0 ? <font color="white">{`Filter ${props.filterNum}`}</font> : "Filter"}
                            </Button>
                          </MenuItem>
                          <MenuItem style={cView === "listWeek" ? {backgroundColor:"#E8E8E8"} : null}>
                            <Button 
                              style={{marginLeft:-5, marginRight:-5, textTransform:"none", fontSize:"14px", color:"#1af36"}} 
                              onClick={cView === "listWeek" ? ()=>{setAnchorEl(false);} : ()=>{setCView("listWeek"); props.setView("listWeek"); setAnchorEl(false);}}
                            >
                              List View
                            </Button>
                          </MenuItem>
                          <MenuItem style={cView === "timeGridDay" ? {backgroundColor:"#E8E8E8"} : null}>
                            <Button 
                              style={{marginLeft:-5, marginRight:-5, textTransform:"none", fontSize:"14px", color:"#1af36"}}
                              onClick={cView === "timeGridDay" ? ()=>{setAnchorEl(false);} : ()=>{setCView("timeGridDay"); props.setView("timeGridDay"); setAnchorEl(false);}}
                            >
                              Day View
                            </Button>
                          </MenuItem>
                      </Menu>
                  </div>,
                click: null,
              }
            }}
            scrollTime={`${today.getHours()}:${today.getMinutes()}:${today.getSeconds}`}
            scrollTimeReset={false}
            views={{
              listWeek: {
                titleFormat: {month:"long", day:"numeric", year:"numeric"}
              },
              timeGridDay: {
                titleFormat: {weekday: 'short', month:"long", day:"numeric", year:"numeric"}
              },
            }}
            headerToolbar={{
              left: "calendarButton",
              center: "prev,title,next",
              right: "dropdownButton",
            }}
            initialView="timeGridDay"
            slotEventOverlap={false}
            allDaySlot={false}
            slotDuration="00:30:00"
            nowIndicator={true}
            slotLabelInterval="01:00"
            events={props.getEvents}
            eventClick={props.eventClick}
            buttonText={{
              prev: <ArrowBackIosIcon />,
              next: <ArrowForwardIosIcon />,
              today:    <TodayIcon />,
              week:     <ListIcon style={{height:30, width:30}} />,
              day:      <ViewDayIcon />
            }}
            height={"calc(100vh - 138px)"}
          />
        </StyleWrapper3>
      </React.Fragment>
    );
  }
};

export default React.memo(BusinessCalendar);
