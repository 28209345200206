import MomentUtils from "@date-io/moment";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FilterListIcon from '@material-ui/icons/FilterList';
import TodayIcon from '@material-ui/icons/Today';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import CModal from "../../../../../Form/CModal";
import { StyleWrapper, StyleWrapper2 } from "../Packages/ScheduleCalendarStyle";


const useStyles = makeStyles((theme) => ({
  eventContainer: {
    height: '100%'
  }
}))

const ResourceWorkbench = (props) => {
  const classes = useStyles();
  const small = useMediaQuery('(min-width:960px)');
  const today = new Date();
  const calendarRef = useRef(null)
  const [pickerDate, setPickerDate] = useState(moment())
  const [pickerOpen, setPickerOpen] = useState(false)

  useEffect(() => {
    setPickerDate(moment())
  }, [small])


  if (small) {
    return (
      <React.Fragment>
        <CModal open={pickerOpen} setOpen={() => { setPickerOpen(false) }}>
          <div style={{
            width: "100vw",
            maxWidth: "400px"
          }}>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <DatePicker
                views={["date"]}
                orientation="portrait"
                variant="static"
                openTo="date"
                onChange={(val) => {
                  setPickerDate(val)
                  setPickerOpen(false)
                  calendarRef.current?.getApi().gotoDate(val.format("YYYY-MM-DD"))
                }}
                value={pickerDate}
              />
            </MuiPickersUtilsProvider>
          </div>
        </CModal>

        <StyleWrapper2>
          <FullCalendar
            ref={calendarRef}
            schedulerLicenseKey={process.env.REACT_APP_FULL_CALENDAR_LICENSE_KEY}
            plugins={[resourceTimelinePlugin, interactionPlugin, momentTimezonePlugin]}
            customButtons={{
              filterAButton: {
                text: 'Filter',
                click: props.openFilterDialog
              },

              filterButton: {
                text: 'Filter',
                click: props.openFilterDialog
              },
              clearFilterButton: {
                text: <Typography style={{ color: "white", backgroundColor: "#0079fc" }}>{props.filterNum}</Typography>
              },
              calendarButton: {
                text: <TodayIcon style={{ margin: -8 }} />,
                click: () => {
                  setPickerDate(moment(calendarRef.current?.getApi().getDate()))
                  setPickerOpen(true)

                }
              }
            }}
            scrollTime={`${today.getHours()}:${today.getMinutes()}:${today.getSeconds}`}
            scrollTimeReset={false}
            dayMaxEvents={2}
            views={{
              resourceTimelineDay: {
                titleFormat: { weekday: 'short', month: "long", day: "numeric", year: "2-digit" },
              },
              resourceTimelineWeek: {
                resourceTimelineWeek: { month: "long", day: "numeric", year: "numeric" }
              },

            }}
            headerToolbar={{
              left: "prev today next calendarButton",
              center: "title",
              right: props.filters && props.filterNum > 0 ? "filterAButton,clearFilterButton resourceTimelineDay,resourceTimelineWeek" : "filterButton resourceTimelineDay,resourceTimelineWeek",
            }}
            initialView="resourceTimelineDay"
            resourceAreaHeaderContent={props.colHeader}
            slotEventOverlap={false}
            allDaySlot={false}
            nowIndicator={true}
            resourceAreaWidth="20%"
            slotLabelInterval="01:00"
            resources={props.getResources}
            resourceOrder={props.resourceOrder}
            resourceLabelDidMount={props.resourceLabelDidMount}
            events={props.getEvents}
            eventClick={props.eventClick}
            buttonText={{
              prev: <ArrowBackIosIcon style={{ marginLeft: "4px", height: 18, width: 16 }} />,
              next: <ArrowForwardIosIcon style={{ height: 18, width: 16 }} />,
              today: 'Today',
              week: "Week",
              day: 'Day'
            }}
            height={"calc(100vh - 128px)"}
            expandRows={true}
            eventStartEditable={true}
            eventDrop={(info) => { props.info(info) }}
            slotMinTime={props.prefer.slotMinTime ? props.prefer.slotMinTime : '00:00:00'}
            slotMaxTime={props.prefer.slotMaxTime ? props.prefer.slotMaxTime : '24:00:00'}
            eventClassNames={classes.eventContainer}
            timeZone={props.prefer.timeZone ? props.prefer.timeZone : 'local'}
            eventMaxStack={2}
            slotDuration={props.prefer.slotDuration ? props.prefer.slotDuration : '00:60:00'}
          />
        </StyleWrapper2>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <CModal open={pickerOpen} setOpen={() => { setPickerOpen(false) }}>
          <div style={{
            width: "100vw",
            maxWidth: "400px"
          }}>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <DatePicker
                views={["date"]}
                orientation="portrait"
                variant="static"
                openTo="date"
                onChange={(val) => {
                  setPickerDate(val)
                  setPickerOpen(false)
                  calendarRef.current?.getApi().gotoDate(val.format("YYYY-MM-DD"))
                }}
                value={pickerDate}
              />
            </MuiPickersUtilsProvider>
          </div>
        </CModal>
        <StyleWrapper>
          <FullCalendar
            ref={calendarRef}
            schedulerLicenseKey={process.env.REACT_APP_FULL_CALENDAR_LICENSE_KEY}
            plugins={[resourceTimelinePlugin, interactionPlugin, momentTimezonePlugin]}
            customButtons={{
              filterButton: {
                text: <FilterListIcon />,
                click: props.openFilterDialog
              },
              calendarButton: {
                text: <TodayIcon style={{ margin: -8 }} />,
                click: () => {
                  setPickerDate(moment(calendarRef.current?.getApi().getDate()))
                  setPickerOpen(true)

                }
              }
            }}
            scrollTime={`${today.getHours()}:${today.getMinutes()}:${today.getSeconds}`}
            scrollTimeReset={false}
            views={{
              resourceTimelineDay: {
                titleFormat: { weekday: "short", month: "long", day: "numeric", year: "numeric" }
              },
            }}
            headerToolbar={{
              left: "calendarButton",
              center: "prev,title,next",
              right: "filterButton",
            }}
            initialView="resourceTimelineDay"
            resourceAreaHeaderContent={props.colHeader}
            slotEventOverlap={false}
            allDaySlot={false}
            nowIndicator={true}
            slotLabelInterval="01:00"
            resources={props.getResources}
            resourceAreaWidth="40%"
            resourceOrder={props.resourceOrder}
            resourceLabelDidMount={props.resourceLabelDidMount}
            events={props.getEvents}
            eventClick={props.eventClick}
            buttonText={{
              prev: <ArrowBackIosIcon />,
              next: <ArrowForwardIosIcon />,
              today: <TodayIcon />,
            }}
            height={"calc(100vh - 138px)"}
            eventClassNames={classes.eventContainer}
            expandRows={true}
            eventStartEditable={props.staff ? false : true}
            eventDrop={props.staff ? null : (info) => { props.info(info) }}
            slotMinTime={props.prefer.slotMinTime ? props.prefer.slotMinTime : '00:00:00'}
            slotMaxTime={props.prefer.slotMaxTime ? props.prefer.slotMaxTime : '12:00:00'}
            timeZone={props.prefer.timeZone ? props.prefer.timeZone : 'local'}
            slotDuration={props.prefer.slotDuration ? props.prefer.slotDuration : '00:30:00'}
            eventMaxStack={2}
          />
        </StyleWrapper>
      </React.Fragment>
    );
  }
};

export default React.memo(ResourceWorkbench);
