import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import BuisnessDashboard from './BuisnessDashboard/BuisnessDashboard';
const Dashboard = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path='/dashboard/:id' render={() => { return (<BuisnessDashboard />); }} />
                <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
}

export default Dashboard;