import React from 'react';
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import InternalEventsDetails from './InternalEventsDetails';
import InternalEventsList from './InternalEventsList';

function InternalEventsHandler() {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const basePath = `/dashboard/${businessId}/Internal-Events`
    return (
        <React.Fragment>
            <Switch>
                <Route path={basePath} exact >
                    <InternalEventsList basePath={basePath} admin />
                </Route>
                <Route path={`${basePath}/:eventIdURL`} exact>
                    <InternalEventsDetails admin />
                </Route>
                <Route path='*'>
                    <Redirect to='/404' />
                </Route>
            </Switch>
        </React.Fragment>
    );
};

export default InternalEventsHandler;