import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {statementDetails} from "../../../../../../utils";
import {
  Grid, Table, TableBody, 
  TableCell, TableContainer, TableHead, 
  TableRow, CircularProgress,
  Typography, useMediaQuery
} from "@material-ui/core";
import CurrencyPreProcessor from "../../../../../CurrencyFormatter/CurrencyFormatter";
import Moment from "react-moment";
import StatusTag from "../../../../../TagComponents/StatusTag";
import CModal from "../../../../../Form/CModal";
import LightTooltip from "../../../../../Form/LightTooltip";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingThree: {
    fontSize: "20px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#dbdddf",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "red",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPos: {
    fontSize: "14px",
    color: "green",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
    paddingLeft: "10px",
  },
  fieldColumnOne: {
    width: "80px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  atCustomCellOne: {
    border: "none",
    width: "20%",
    maxWidth: "300px",
    padding: "10px",
  },
  atCustomCellTwo: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  atCustomCellThree: {
    border: "none",
    padding: "10px",
  },
  ttCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  ttCustomCellTwo: {
    border: "none",
    width: "20%",
    maxWidth: "300px",
    padding: "10px",
  },
  ttCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  ttCustomCellFour: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  ttCustomCellFive: {
    border: "none",
    padding: "10px",
  },
  ptCustomCellOne: {
    border: "none",
    width: "30%",
    maxWidth: "400px",
    padding: "10px",
  },
  ptCustomCellTwo: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  ptCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  ptCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  ccBlock: {
    backgroundColor: "#1a1f71",
    textTransform: "uppercase",
    color: "#ffffff",
    fontWeight: "bold",
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "3px",
    paddingBottom: "3px",
    borderRadius: "2px",
    fontSize: "12px",
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width : "100%",
    height : "calc(100vh - 64px)"
  },
  loaderMain : {
    position : "absolute",
    top : "50%",
    left: "50%"
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingTop:"10px",
    backgroundColor: "#ffffff"
  },
  formContainer:{
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding:"20px",
    maxWidth: "600px"
  }
}));

const Payout = () => {
  const [_, updateState] = useState(0);
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const large = useMediaQuery('(min-width: 960px)');
  const matches = useMediaQuery('(min-width: 1140px)');
  const payoutDetails = useSelector((state) => {
    return state.payoutDetails;
  });
  const { recordIdURL } = useParams();
  const small = useMediaQuery('(min-width: 750px)');

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });

    let data = await statementDetails({
      businessId: businessId,
      recordId: recordIdURL,
    });

    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
        payoutDetails: data ? data : null,
      },
    });
  }, [_]);

  if (payoutDetails && payoutDetails.payoutDetails.recordId === recordIdURL) {
    return (
      <Grid container className={large ? classes.mainWrapper : classes.mobilePadding}>
        <Grid item xs={12}>
          <div className={classes.topBlock}>
            <span className={classes.topHeading}>PAYOUT STATEMENT</span>
          </div>
        </Grid>
        <Grid container direction="row" item xs={12}  spacing={2} className={classes.headingWrapper}>
          <Grid item>
            <div>
              <span className={large ? classes.topHeadingOne : classes.mobileTopHeadingTwo}>
                <CurrencyPreProcessor
                  amount={payoutDetails.payoutDetails.amount}
                  currencyBreak={payoutDetails.currencyInfo.currencyBreak}
                  roundingFactor={payoutDetails.currencyInfo.roundingFactor}
                  currencySymbol={payoutDetails.currencyInfo.currencySymbol}
                />
              </span>
              <span className={large ? classes.topHeadingTwo : classes.mobileTopHeadingThree}>
                {` ${payoutDetails.currencyInfo.currencyShortName}`}
              </span>
            </div>
          </Grid>
          <Grid item>
          <div>
              {payoutDetails.payoutDetails.status === "Paid" ? (
                <span style={matches ? {marginBottom:-5} : {marginTop:0}}>
                    <StatusTag wrapperName={payoutDetails.payoutDetails.status === "Paid" ? "greenWrapper" : "redWrapper"} value= {payoutDetails.payoutDetails.status === "Paid" ? "Complete" : "Pending"} />
                </span>
              ) : null}
            </div>
          </Grid>     
        </Grid>
        <Grid item xs={12} style={{paddingLeft:"0px"}}>
          <div className={classes.contentRow}>
            <div className={small ? classes.contentColumnFirst : classes.fieldRows}>
              <div className={small ? classes.blockLabel : classes.fieldColumnOne}>
                <span className={classes.labelText} style={{whiteSpace:"normal"}}>Intitialization Date</span>
              </div>
              <div className={small ? classes.blockText : classes.fieldColumnTwo} style = {small ? {width:"100%"} : {paddingLeft: "15px"}}>
                <span className={classes.valueText}>
                  <Moment format="lll">
                    {payoutDetails.payoutDetails.createdAt}
                  </Moment>
                </span>
              </div>
            </div>
            <div className={small ? classes.contentColumnMiddle : classes.fieldRows}>
              <div className={small ? classes.blockLabel : classes.fieldColumnOne}>
                <span className={classes.labelText} style={{whiteSpace:"normal"}}>Estimated Arrival Date</span>
              </div>
              <div className={small ? classes.blockText : classes.fieldColumnTwo} style={small ? {width:"100%"} : {paddingLeft:"15px"}}>
                <span className={classes.valueText}>
                    <Moment format="lll">
                        {payoutDetails.payoutDetails.estimatedArival}
                    </Moment>
                </span>
              </div>
            </div>
            <div className={small ? classes.contentColumnLast : classes.fieldRows}>
              <div className={small ? classes.blockLabel : classes.fieldColumnOne}>
                <span className={classes.labelText}>Type</span>
              </div>
              <div className={small ? classes.blockText : classes.fieldColumnTwo} style={small? {width:"60px"}: {paddingLeft:"15px"}}>
                <span className={classes.valueText}>
                  {payoutDetails.payoutDetails.type === "Withdrawn" ? "Withdrawl" : payoutDetails.payoutDetails.type}
                </span>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
            <div className={classes.headingWrapper}>
                <span className={large ? classes.subHeading : classes.mobileTopHeadingTwo}>Bank Details</span>
            </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <TableContainer style={{maxHeight:"271px", width:"100%"}}>
              <Table stickyHeader aria-label="simple table" style={{tableLayout:"fixed"}}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className={classes.headerPadding} style={{width:`calc(100%)`}}>
                      <Typography noWrap className={classes.labelText}>BANK NAME</Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.headerPadding} style={{width:`calc(100%)`}}>
                      <Typography noWrap className={classes.labelText}>{small ? "ACCOUNT NUMBER" : "ACCOUNT #"}</Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.headerPadding} style={{width:`calc(100%)`}}>
                      <Typography noWrap className={classes.labelText}>{small ? "ROUTING NUMBER" : "ROUTING #"}</Typography>
                    </TableCell>
                    {small ? <TableCell align="left" className={classes.headerPadding} style={{width:"70px"}}>
                      <span className={classes.labelText}>COUNTRY</span>
                    </TableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell
                            align="left"
                            className={classes.ttCustomCellThree}
                        >
                            <LightTooltip title={payoutDetails.bankDetails.bankName} arrow>
                                <Typography noWrap className={classes.valueText} style={{fontFamily:"Rubik, sans-serif"}}>
                                    {payoutDetails.bankDetails.bankName}
                                </Typography>
                            </LightTooltip>
                        </TableCell>
                        <TableCell
                            align="left"
                            className={classes.ttCustomCellOne}
                            >
                                <LightTooltip title={payoutDetails.bankDetails.accountNumber} arrow>
                                    <Typography noWrap className={classes.valueText} style={{fontFamily:"Rubik, sans-serif"}}>
                                        ********{payoutDetails.bankDetails.accountNumber}
                                    </Typography>
                                </LightTooltip>
                        </TableCell>
                        <TableCell
                            align="left"
                            className={classes.ttCustomCellOne}
                            >
                                <LightTooltip title={payoutDetails.bankDetails.routingNumber} arrow>
                                    <Typography noWrap className={classes.valueText} style={{fontFamily:"Rubik, sans-serif"}}>
                                        {payoutDetails.bankDetails.routingNumber}
                                    </Typography>
                                </LightTooltip>
                        </TableCell>
                        {small ? <TableCell
                            align="left"
                            className={classes.ttCustomCellTwo}
                        >
                            <Typography noWrap className={classes.valueText} style={{fontFamily:"Rubik, sans-serif", textTransform:"uppercase"}}>
                                {payoutDetails.bankDetails.country}
                            </Typography>
                        </TableCell> : null}
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain}/>
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default Payout;
