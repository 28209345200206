import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  addDispute,
  downloadDispute,
  updateDispute,
  deleteDispute,
  submitDispute,
  disputeDetails,
  withLoading
} from "../../../../../../utils";
import {
  Grid,
  Button,
  TextField,
  CircularProgress,
  Container,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import { CAddIcon } from "../../../../../Icons/Icons";
import Form from "../../../../../Form/Form";
import CModal from "../../../../../Form/CModal";
import CDisplayArea from "../../../../../Form/CDisplayArea";
import { TableContainer } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { ButtonGroup } from "@material-ui/core";
import LightTooltip from "../../../../../Form/LightTooltip";
import PublishIcon from "@material-ui/icons/Publish";
import { Table } from "@material-ui/core";
import CurrencyPreProcessor from "../../../../../CurrencyFormatter/CurrencyFormatter";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import Moment from "react-moment";
import StatusTag from "../../../../../TagComponents/StatusTag";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px",
  },
  headingWrapper: {
    paddingBottom: "10px",
    paddingTop: "10px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
    paddingLeft: "10px",
  },
  fieldColumnOne: {
    width: "100px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
    paddingLeft: "30px",
  },
  sdCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "25%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
  },
}));

const Dispute = () => {
  const classes = useStyles();
  const [_, updateState] = useState(0);
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);
  const [file, setFile] = useState([]);
  const [rRR, setRRR] = useState(null);
  const [update, setUpdate] = useState(null);
  const [aI, setAI] = useState(null);
  const small = useMediaQuery("(min-width: 750px)");
  const large = useMediaQuery("(min-width: 960px)");
  const screen = useMediaQuery("(min-width: 1300px)");
  const [submit, setSubmit] = useState(null);
  const disputesDetails = useSelector((state) => {
    return state.disputes;
  });
  const [pendingDocs, setPendingDocs] = useState([]);
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const handleClose = () => {
    setAdd(false);
  };

  const { disputeIdURL } = useParams();

  async function handleDownload(fileId) {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let success = await downloadDispute({
      businessId: businessId,
      disputeId: disputeIdURL,
      disputeFileId: fileId,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (success) {
      window.open(success.fileURL, "Download", "rel=noreferrer");
    }
  }

  async function handleDelete(fileId) {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let success = await deleteDispute({
      businessId: businessId,
      disputeId: disputeIdURL,
      disputeFileId: fileId,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (success) {
      updateState((_) => !_);
    }
  }

  const handleDisputeFileAdd = async (event) => {
    let formData = new FormData();
    formData.append('disputeFile', file, file.name);
    formData.append('businessId', businessId);
    formData.append('disputeId', disputeIdURL);
    formData.append('fileType', event.fileType);


    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let data = null;
    if (formData) {
      data = await addDispute(formData)
    }

    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (data) {
      setAdd(false);
      updateState((_) => !_);
    }
  };

  async function handleSubmit() {
    let success = await withLoading(submitDispute, {
      businessId: businessId,
      disputeId: disputesDetails.disputeId,
    })
    if (success) {
      setSubmit(false)
      updateState((_) => !_);
    }
  }

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });

    let data = await disputeDetails({
      businessId: businessId,
      disputeId: disputeIdURL,
    });
    setPendingDocs(data.disputeDetails.pendingDocs);

    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
        disputes: data ? data.disputeDetails : null,
      },
    });
  }, [_]);

  if (disputesDetails && disputesDetails.disputeId == disputeIdURL) {
    return (
      <Grid
        container
        className={large ? classes.mainWrapper : classes.mobilePadding}
      >
        <React.Fragment>
          <GeneralDialog
            open={submit}
            close={() => {
              setSubmit(false);
            }}
            title="Submit Dispute"
            text="Are you sure you want to submit this dispute?"
            action={handleSubmit}
          />
          <CModal
            open={update}
            setOpen={setUpdate}
            children={
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={updateDispute}
                  postAction={() => {
                    setUpdate(false);
                    updateState((_) => !_);
                  }}
                  title={rRR ? "Refund Refusal Reason" : aI ? "Additional Information" : null}
                  cancelEnabled
                  cancelAction={() => {
                    setUpdate(false);
                    setAI(false);
                    setRRR(false);
                  }}
                  cancelText="Cancel"
                  submitText={"Save"}
                  moreData={{ businessId: businessId, disputeId: disputeIdURL }}
                  inputFields={
                    rRR
                      ? [
                        {
                          type: "TextArea",
                          label: "",
                          name: "refundRefuseReason",
                          moreProps: {
                            rowsMin: 4,
                            rowsMax: 10,
                          },
                        },
                      ]
                      : aI
                        ? [
                          {
                            type: "TextArea",
                            label: "",
                            name: "additionalInformation",
                            moreProps: {
                              rowsMin: 4,
                              rowsMax: 10,
                            },
                          },
                        ]
                        : null
                  }
                ></Form>
              </Container>
            }
          />
          <CModal
            open={add}
            setOpen={setAdd}
            children={
              <Container component="main" className={classes.formContainer}>
                <Grid item xs={12} className={classes.headerPadding}>
                  <Typography className={classes.topHeadingOne}>
                    Add Dispute Document
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={9}>
                    <TextField
                      variant="outlined"
                      multiline={true}
                      defaultValue={file ? file.name : null}
                      rows={1}
                      rowsMax={1}
                      fullWidth={true}
                      inputProps={{
                        readOnly: true,
                        style: {
                          marginBottom: -10,
                          marginTop: -10,
                          fontSize: 14,
                          color: "#1a1f36",
                          fontFamily: "Rubik, sans-serif",
                          whiteSpace: "normal",
                        },
                      }}
                      readOnly={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <LightTooltip title="Reselect document" arrow>
                      <span className={classes.rightElem}>
                        <input
                          accept="application/pdf, .docx, image/*, text"
                          onChange={(file) => {
                            setAdd(true);
                            setFile(file.target.files[0]);
                          }}
                          style={{ display: "none" }}
                          id="contained-button-file"
                          type="file"
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            variant="contained"
                            component="span"
                            className={classes.buttonBasic}
                            onClick={() => {
                              setAdd(false);
                            }}
                          >
                            <PublishIcon />
                          </Button>
                        </label>
                      </span>
                    </LightTooltip>
                  </Grid>
                </Grid>
                <Form
                  action={(event) => {
                    handleDisputeFileAdd(event);
                  }}
                  postAction={null}
                  cancelEnabled
                  title={null}
                  cancelAction={handleClose}
                  cancelText="Cancel"
                  submitText="Create"
                  inputFields={[
                    {
                      type: "SingleSelect",
                      label: "Dispute Document Type",
                      choices: pendingDocs.map((choices) => {
                        return [choices, choices];
                      }),
                      name: "fileType",
                      validator: (value) =>
                        value === null ? "Cannot be empty" : null,
                      initialvalue: "",
                    },
                  ]}
                ></Form>
              </Container>
            }
          />
        </React.Fragment>
        <Grid
          container
          className={classes.topBlock}
          item
          xs={12}
          direction="row"
        >
          <Grid item xs={6}>
            <div>
              <span className={classes.topHeading}>DISPUTE</span>
            </div>
          </Grid>
          {disputesDetails.submitAllowed && disputesDetails.editAllowed ? (
            <Grid tiem xs={6}>
              <div className={classes.rightElem}>
                <Button
                  className={classes.buttonBasic}
                  style={{ color: "white", backgroundColor: "#0079fc" }}
                  onClick={() => {
                    setSubmit(true);
                  }}
                >
                  Submit
                </Button>
              </div>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={12}
          spacing={2}
          className={classes.headerPadding}
          style={{ width: "100%" }}
        >
          <Grid item>
            <div style={{ marginLeft: -10 }}>
              <Typography
                className={
                  small ? classes.topHeadingOne : classes.mobileTopHeadingTwo
                }
                noWrap
                style={{ width: `calc(100%)` }}
              >
                <CurrencyPreProcessor
                  amount={disputesDetails.disputeAmount}
                  currencyBreak={disputesDetails.currencyInfo.currencyBreak}
                  roundingFactor={disputesDetails.currencyInfo.roundingFactor}
                  currencySymbol={disputesDetails.currencyInfo.currencySymbol}
                />
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            style={small ? { paddingTop: "17px" } : { paddingTop: "10px" }}
          >
            <StatusTag
              wrapperName={
                disputesDetails.status === "Lost"
                  ? "redWrapper"
                  : disputesDetails.status === "Won"
                    ? "greenWrapper"
                    : "grayWrapper"
              }
              value={disputesDetails.status}
              width={
                disputesDetails.status === "Won" ||
                  disputesDetails.status === "Lost"
                  ? null
                  : 120
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div
            className={classes.contentRow}
            style={small ? null : { marginLeft: -10 }}
          >
            <div
              className={
                screen ? classes.contentColumnFirst : classes.fieldRows
              }
            >
              <div
                className={screen ? classes.blockLabel : classes.fieldColumnOne}
                style={small ? null : { maxWidth: "66px" }}
              >
                <span
                  style={{ whiteSpace: "normal" }}
                  className={classes.labelText}
                >
                  Customer Name
                </span>{" "}
              </div>
              <div
                className={screen ? classes.blockText : classes.fieldColumnTwo}
              >
                <LightTooltip
                  title={`${disputesDetails.customerInfo.customerFirstName} ${disputesDetails.customerInfo.customerLastName}`}
                  arrow
                >
                  <span className={classes.valueText}>
                    {`${disputesDetails.customerInfo.customerFirstName} ${disputesDetails.customerInfo.customerLastName}`}
                  </span>
                </LightTooltip>
              </div>
            </div>
            <div
              className={
                screen ? classes.contentColumnMiddle : classes.fieldRows
              }
            >
              <div
                className={screen ? classes.blockLabel : classes.fieldColumnOne}
                style={small ? null : { maxWidth: "66px" }}
              >
                <span
                  style={{ whiteSpace: "normal" }}
                  className={classes.labelText}
                >
                  Phone Number
                </span>{" "}
              </div>
              <div
                className={screen ? classes.blockText : classes.fieldColumnTwo}
              >
                <span className={classes.valueText}>
                  {`${disputesDetails.customerInfo.customerPhoneNumber.slice(
                    0,
                    2
                  )} (${disputesDetails.customerInfo.customerPhoneNumber.slice(
                    2,
                    5
                  )}) ${disputesDetails.customerInfo.customerPhoneNumber.slice(
                    5,
                    8
                  )}-${disputesDetails.customerInfo.customerPhoneNumber.slice(
                    8,
                    12
                  )}`}
                </span>
              </div>
            </div>
            <div
              className={
                screen ? classes.contentColumnMiddle : classes.fieldRows
              }
            >
              <div
                className={screen ? classes.blockLabel : classes.fieldColumnOne}
                style={small ? null : { maxWidth: "66px" }}
              >
                <span
                  style={{ whiteSpace: "normal" }}
                  className={classes.labelText}
                >
                  Email
                </span>{" "}
              </div>
              <div
                className={screen ? classes.blockText : classes.fieldColumnTwo}
              >
                <LightTooltip title="Email customer" arrow>
                  <span className={classes.valueText}>
                    <a
                      style={{ color: "#0079fc" }}
                      href={
                        "mailto:" + disputesDetails.customerInfo.customerEmail
                      }
                    >
                      {disputesDetails.customerInfo.customerEmail}
                    </a>
                  </span>
                </LightTooltip>
              </div>
            </div>
            <div
              className={
                screen ? classes.contentColumnMiddle : classes.fieldRows
              }
            >
              <div
                className={screen ? classes.blockLabel : classes.fieldColumnOne}
                style={small ? null : { maxWidth: "66px" }}
              >
                <span
                  style={{ whiteSpace: "normal" }}
                  className={classes.labelText}
                >
                  Order #
                </span>{" "}
              </div>
              <div
                className={screen ? classes.blockText : classes.fieldColumnTwo}
              >
                <span className={classes.valueText}>
                  <LightTooltip title="Order Page" arrow>
                    <Link
                      style={{ color: "#0079fc", textDecoration: "none" }}
                      to={`/dashboard/${businessId}/Orders/${disputesDetails.orderInfo.orderId}`}
                    >
                      {disputesDetails.orderInfo.orderNumber}
                    </Link>
                  </LightTooltip>
                </span>
              </div>
            </div>
            {disputesDetails.submitAllowed && disputesDetails.editAllowed ? (
              <div
                className={
                  screen ? classes.contentColumnMiddle : classes.fieldRows
                }
              >
                <div
                  className={
                    screen ? classes.blockLabel : classes.fieldColumnOne
                  }
                  style={small ? null : { maxWidth: "66px" }}
                >
                  <span
                    style={{ whiteSpace: "normal" }}
                    className={classes.labelText}
                  >
                    Created On
                  </span>{" "}
                </div>
                <div
                  className={
                    screen ? classes.blockText : classes.fieldColumnTwo
                  }
                >
                  <span className={classes.valueText}>
                    <Moment format="MMMM, DD YYYY">
                      {disputesDetails.createdOn}
                    </Moment>
                  </span>
                </div>
              </div>
            ) : (
              <div
                className={
                  screen ? classes.contentColumnLast : classes.fieldRows
                }
              >
                <div
                  className={
                    screen ? classes.blockLabel : classes.fieldColumnOne
                  }
                  style={small ? null : { maxWidth: "66px" }}
                >
                  <span
                    style={{ whiteSpace: "normal" }}
                    className={classes.labelText}
                  >
                    Created On
                  </span>{" "}
                </div>
                <div
                  className={
                    screen ? classes.blockText : classes.fieldColumnTwo
                  }
                >
                  <span className={classes.valueText}>
                    <Moment format="MMMM, DD YYYY">
                      {disputesDetails.createdOn}
                    </Moment>
                  </span>
                </div>
              </div>
            )}

            {disputesDetails.submitAllowed && disputesDetails.editAllowed ? (
              <div
                className={
                  screen ? classes.contentColumnLast : classes.fieldRows
                }
              >
                <div
                  className={
                    screen ? classes.blockLabel : classes.fieldColumnOne
                  }
                  style={small ? null : { maxWidth: "66px" }}
                >
                  <span
                    style={{ whiteSpace: "normal" }}
                    className={classes.labelText}
                  >
                    Due Date
                  </span>{" "}
                </div>
                <div
                  className={
                    screen ? classes.blockText : classes.fieldColumnTwo
                  }
                >
                  <span className={classes.valueText}>
                    <Moment format="MMMM, DD YYYY">
                      {disputesDetails.dueDate}
                    </Moment>
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </Grid>
        <Grid container item xs={12} className={classes.headingWrapper}>
          <Grid item xs={6}>
            <div>
              <span
                className={
                  small ? classes.subHeading : classes.mobileTopHeadingTwo
                }
              >
                Documents
              </span>
            </div>
          </Grid>
          {disputesDetails.pendingDocs.length > 0 &&
            disputesDetails.editAllowed &&
            disputesDetails.submitAllowed ? (
            <Grid item xs={6}>
              <div>
                <span className={classes.rightElem}>
                  <input
                    accept="application/pdf, .docx, image/*, text"
                    onChange={(file) => {
                      setFile(file.target.files[0]);
                      setAdd(true);
                    }}
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <LightTooltip title="Add Dispute Document" arrow>
                      <Button
                        variant="raised"
                        component="span"
                        className={classes.buttonBasic}
                      >
                        <CAddIcon />
                      </Button>
                    </LightTooltip>
                  </label>
                </span>
              </div>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          {disputesDetails.disputeDocs.length != 0 &&
            disputesDetails.disputeDocs.length !== "undefined" ? (
            <TableContainer>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.headerPadding}
                      style={{ width: `calc(80%)` }}
                    >
                      <Typography noWrap className={classes.labelText}>
                        Type
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerPadding}
                      style={{ width: "calc(20%)" }}
                    >
                      <Typography noWrap className={classes.labelText}>
                        {disputesDetails.editAllowed ? "Actions" : "Action"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {disputesDetails.disputeDocs.map((document, index) => {
                    return (
                      <TableRow key={document.disputeFileId}>
                        <TableCell
                          align="left"
                          className={classes.sdCustomCellOne}
                        >
                          <span className={classes.valueText}>
                            {document.fileType}
                          </span>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.sdCustomCellFour}
                        >
                          <span className={classes.valueText}>
                            <ButtonGroup
                              variant="text"
                              aria-label="text primary button group"
                            >
                              <Button
                                style={{
                                  color: "#0079fc",
                                  paddingTop: "2px",
                                  paddingBottom: "5px",
                                  textTransform: "none",
                                }}
                                onClick={() => {
                                  handleDownload(document.disputeFileId);
                                }}
                              >
                                Download
                              </Button>
                              {disputesDetails.editAllowed ? (
                                <Button
                                  style={{
                                    color: "red",
                                    paddingTop: "2px",
                                    paddingBottom: "5px",
                                    textTransform: "none",
                                  }}
                                  onClick={() => {
                                    handleDelete(document.disputeFileId);
                                  }}
                                >
                                  Delete
                                </Button>
                              ) : null}
                            </ButtonGroup>
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className={classes.topMarginBlock}>
              <span className={classes.valueTextPad}>
                No documents have been submitted.
              </span>
            </div>
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          className={classes.headingWrapper}
          style={{ paddingTop: "20px", paddingLeft: "0px" }}
        >
          <Grid item xs={6}>
            <span
              className={
                small ? classes.subHeading : classes.mobileTopHeadingTwo
              }
            >
              Refund Refusal Reason
            </span>
          </Grid>
          {disputesDetails.pendingDocs.length > 0 &&
            disputesDetails.editAllowed &&
            disputesDetails.submitAllowed ? (
            <Grid item xs={6}>
              <LightTooltip
                title={
                  disputesDetails.refundRefuseReason
                    ? "Edit Refund Refusal"
                    : "Add Refund Refusal"
                }
                arrow
              >
                <span className={classes.rightElem}>
                  <Button
                    className={classes.buttonBasic}
                    onClick={() => {
                      setRRR(true);
                      setUpdate(true);
                    }}
                  >
                    <CAddIcon />
                  </Button>
                </span>
              </LightTooltip>
            </Grid>
          ) : null}
        </Grid>
        <Grid item className={classes.headerPadding} xs={12}>
          <CDisplayArea value={disputesDetails.refundRefuseReason} />
        </Grid>
        <Grid
          item
          container
          className={classes.headingWrapper}
          style={{ paddingLeft: "0px" }}
          xs={12}
        >
          <Grid item xs={6}>
            <span
              className={
                small ? classes.subHeading : classes.mobileTopHeadingTwo
              }
            >
              Additional Information
            </span>
          </Grid>
          {disputesDetails.pendingDocs.length > 0 &&
            disputesDetails.editAllowed &&
            disputesDetails.submitAllowed ? (
            <Grid item xs={6}>
              <LightTooltip
                title={
                  disputesDetails.disputeWithdrawInfo
                    ? "Edit Additional Information"
                    : "Add Additional Information"
                }
                arrow
              >
                <span className={classes.rightElem}>
                  <Button
                    className={classes.buttonBasic}
                    onClick={() => {
                      setAI(true);
                      setUpdate(true);
                    }}
                  >
                    <CAddIcon />
                  </Button>
                </span>
              </LightTooltip>
            </Grid>
          ) : null}
        </Grid>
        <Grid item className={classes.headerPadding} xs={12}>
          <CDisplayArea
            value={disputesDetails.additionalInformation}
            initialMaxHeight={"calc(100vh - 500px)"}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default Dispute;
