import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { listAgreements, retrieveGetParams, withLoading } from "../../../../../../utils";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Typography, useMediaQuery, Button } from "@material-ui/core";
import ListTable from "./../../../../../TableComponents/ListTable";

import StatusTag from "../../../../../TagComponents/StatusTag";
import ListCard from "../../../../../MobileCards/ListCard";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import ManageAgreementForm from "./ManageAgreement";
import PostFilter, { applyFilters } from '../../../../../Filter/PostFilter'

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    rightElem: {
        float: "right",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    },
    buttonBasic: {
        fontSize: "14px",
        marginLeft: '10px',
        textTransform: "none",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeading: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    bottomSpacer: {
        height: "50px",
    },
}));

const keyObjects = [
    {
        key: 'isActive',
        label: 'Status',
        keyLabel: 'statusName'
    },
    {
        key: 'type',
        label: 'Type',
        keyLabel: 'type'
    }
]

const AgreementsList = (props) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const [agreementsList, setAgreementsList] = useState(null);
    const bigEnough = useMediaQuery("(min-width:750px)")
    const location = useLocation()
    const queryParams = retrieveGetParams(location)
    const businessId = useSelector((state) => {
        return state.buisnessDetails.businessId;
    });
    const agreementsHeaders = [
        {
            name: "status",
            columnKey: "status",
            columnWidth: "100px",
            type: "Normal"
        },
        {
            name: "type",
            columnKey: "type",
            columnWidth: "30%",
        },
        {
            name: "name",
            columnKey: "name",
            columnWidth: "70%",
            tooltip: true
        },


    ];



    const processAgreementsData = (agreementData) => {
        return agreementData.map((agreement) => {
            let {
                agreementId,
                name,
                isActive,
                type,
            } = agreement;

            let status = <StatusTag wrapperName={isActive ? "greenWrapper" : "redWrapper"} value={isActive ? "Active" : "Inactive"} />;
            let id = agreementId;
            type = type.charAt(0).toUpperCase() + type.slice(1)
            return {
                id,
                status,
                type,
                isActive,
                statusName: isActive ? 'Active' : 'Not Active',
                name
            };
        });
    };

    const getAgreementsDetails = async () => {
        let agreementsPayload = await listAgreements({
            businessId: businessId,
        });

        if (agreementsPayload) {
            setAgreementsList(processAgreementsData(agreementsPayload));
        };

    };

    const navigateToAgreement = (agreementId) => {
        history.push(`${props.basePath}/${agreementId}`);
    };

    useEffect(async () => {
        await withLoading(getAgreementsDetails, {}, true)
    }, []);



    const ViewChanger = () => {
        if (bigEnough) {
            return (<React.Fragment>
                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        <ListTable
                            identifier={"agreements"}
                            tableHeaders={agreementsHeaders}
                            tableRows={applyFilters(agreementsList, queryParams, keyObjects)}
                            rowClick={navigateToAgreement}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bottomSpacer}></div>
                </Grid>
            </React.Fragment>)
        }
        else {
            return (<Grid item xs={12} style={{ paddingTop: "10px" }}>
                {applyFilters(agreementsList, queryParams, keyObjects).map((agreement) => {
                    return (
                        <ListCard
                            key={agreement.Id}
                            action={() => { navigateToAgreement(agreement.id) }}
                            cardName={agreement.name}
                            statusReplace={
                                <Typography
                                    noWrap
                                    style={!agreement.isActive ? { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "red" } : { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "green" }}>
                                    {agreement.isActive ? "Active" : "Inactive"}
                                </Typography>
                            }
                            other={agreement.type} />
                    )
                })}
            </Grid>)
        }

    }


    if (agreementsList === null) {
        return (null)
    }
    else {
        return (
            <React.Fragment>
                <ManageAgreementForm open={open} setOpen={setOpen} basePath={props.basePath} />
                <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                    <Grid item xs={12}>
                        <div className={classes.headerPadding}>
                            <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Agreements</span>
                            <span title="Add Agreement" className={classes.rightElem}>
                                <Button
                                    className={classes.buttonBasic}
                                    onClick={() => { setOpen(true); }}
                                    color="primary"
                                    variant="contained"
                                >
                                    Add
                                </Button>
                            </span>
                            <PostFilter allEntries={agreementsList} keyObjects={keyObjects} />
                        </div>
                    </Grid>
                    {(Array.isArray(agreementsList) && agreementsList.length > 0 ?
                        <ViewChanger />
                        :
                        <EmptyListImage name="Agreements" />)}
                </Grid>
            </React.Fragment>
        );
    }

};

export default AgreementsList;