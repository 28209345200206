import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Form from '../../../../../Form/Form'
import CModal from '../../../../../Form/CModal'
import { Container } from '@material-ui/core';
import { makeStyles} from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import validDates from '../../../../../../FormValidation/validDates';
import { addTimeOff, editTimeOff, staffAddScheduleTimeOff, staffUpdateScheduleTimeOff } from '../../../../../../utils';

const useStyles = makeStyles((theme)=>({
    formContainer:{
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))

const TimeOffForm = (props) => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()
    
    const refresh = (response) => {
        if(response){
            props.setOpen(false)
            props.refresh()
        }
    }



    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main"  className = {classes.formContainer}>
                <Form
                    moreData = {{businessId:businessId, calendarId: props.calendarDetails.calendarId, timeOffId: (props.edit?props.open.timeOffId:null)}}
                    title={`${(props.edit?"Edit":"Add")} Time Off`}
                    cancelEnabled
                    cancelAction = {()=>{props.setOpen(false)}}
                    submitText={(props.edit?"Save":"Create")}
                    action={(props.role === "Admin" && props.edit? editTimeOff : props.role === "Admin" && !props.edit ?  addTimeOff : props.role === "Staff" && props.edit ? staffUpdateScheduleTimeOff : staffAddScheduleTimeOff)}
                    postAction = {refresh}
                    inputFields={[
                        {
                            type: "DateRange",
                            name: ["startDate", "endDate"],
                            label: ["Start Date", "End Date"],
                            validator: validDates,
                            initialvalue: (props.edit?[props.open.startDate, props.open.endDate]:null)
                        },
                        {
                            type: "TextArea",
                            name: "description",
                            label: "Description",
                            resizeEnabled:true,
                            validator: [notEmpty,noWhiteSpace],
                            moreProps : {
                                rowsMin:4
                            },
                            initialvalue: (props.edit?props.open.description:null)
                        }
                    ]}
                />
            </Container>
        </CModal>
    );
};

export default TimeOffForm