import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { listWidgets } from "../../../../../../utils";
import { addWidget } from "../../../../../../utils";
import { getGenericDetails, retrieveGetParams } from "../../../../../../utils";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import ListTable from "./../../../../../TableComponents/ListTable";
import StatusTag from "../../../../../TagComponents/StatusTag";
import Form from "../../../../../Form/Form";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import noWhiteSpace from "../../../../../../FormValidation/noWhiteSpace";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListCard from "../../../../../MobileCards/ListCard";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import CModal from "../../../../../Form/CModal";
import { CircularProgress } from "@material-ui/core";
import LightTooltip from "../../../../../Form/LightTooltip";
import PostFilter from '../../../../../Filter/PostFilter'
import { CColor } from '../../../../../Form/CColorPicker';
import CCheckBox from "../../../../../Form/CCheckBox";
import CTitle from "../../../../../Form/CTitle";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  topHeadingOne: {
    fontSize: "20px",
    color: "#1a1f36",
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  buttonBasic: {
    fontSize: "14px",
    marginLeft: '10px',
    textTransform: "none",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  headerPadding: {
    paddingBottom: "10px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  largeWidth: {
    maxWidth: "calc(100vh - 370px)"
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "inset 0 -1px #e3e8ee"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: "20px",
    maxWidth: "600px"
  }
}));

const keyObjects = [
  {
    key: "isActive",
    label: 'Status',
    keyLabel: 'statusName'
  },
  {
    key: "serviceName",
    label: 'Service',
    keyLabel: 'serviceName'
  },
  {
    key: "siteName",
    label: 'Site',
    keyLabel: 'siteName'
  }
]

const WidgetsList = () => {
  const classes = useStyles();
  const [add, setAdd] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams = retrieveGetParams(location)
  const dispatch = useDispatch();
  const large = useMediaQuery('(min-width: 960px)');
  const small = useMediaQuery('(min-width: 750px)');
  const [serviceOptions, setServiceOptions] = useState(null);
  const [c, setC] = useState("#000000");
  const [tC, setTC] = useState("#000000");
  const [bC, setBC] = useState("#AA0000");
  const [bTC, setBTC] = useState("#FFFFFF");
  const [cH, setCH] = useState(null);
  const [siteOptions, setSiteOptions] = useState(null);
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const widgetsHeaders = [
    {
      name: "status",
      columnKey: "status",
      columnWidth: "100px",
      type: "Normal"
    },
    {
      name: "name",
      columnKey: "wrappedName",
      columnWidth: "80%",
      tooltip: true
    },
    {
      name: "service",
      columnKey: "serviceName",
      columnWidth: "80%",
      tooltip: true
    },
    {
      name: "site",
      columnKey: "siteName",
      columnWidth: "80%",
      tooltip: true
    },
    {
      name: "option types",
      columnKey: "options",
      columnWidth: "80%",
      tooltip: true
    }
  ];

  const [initialState, setInitialState] = useState(null);
  const [widgetList, setWidgetList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);

  async function handleSubmit(event) {
    dispatch({
      type: 'UPDATE', payload: {
        loading: true
      }
    });
    let success = await addWidget({
      businessId: businessId,
      name: event.name,
      serviceId: event.serviceId,
      siteId: event.siteId,
      optionTypes: event.optionTypes,
      widgetColor: {
        backgroundColor: cH ? "transparent" : c,
        buttonColor: bC,
        textColor: tC,
        buttonTextColor: bTC
      }
    })
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
    if (success) {
      history.push(`${location.pathname}/${success.widgetId}`);
    }
  };

  const processWidgetsData = (widgetData) => {
    return widgetData.map((widget) => {
      let {
        widgetId,
        name,
        isActive,
        serviceName,
        siteName,
        optionTypes
      } = widget;

      let status = <StatusTag wrapperName={isActive ? "greenWrapper" : "redWrapper"} value={isActive ? "Active" : "Inactive"} />;
      let id = widgetId;
      let wrappedName = <Typography noWrap style={{ fontSize: "14px", color: "#1a1f36", width: "100%", fontFamily: "Rubik, sans-serif" }}>
        {name}
      </Typography>;
      let options = optionTypes.map((ot, index) => {
        return (
          index == optionTypes.length - 1 ? ot : `${ot}, `
        );
      })

      return {
        id,
        isActive,
        status,
        name,
        serviceName,
        siteName,
        optionTypes,
        wrappedName,
        options,
        statusName: isActive ? 'Active' : 'Not Active'
      };
    });
  };

  const getWidgetsDetails = async () => {
    let widgetsPayload = await listWidgets({
      businessId: businessId
    });
    if (widgetsPayload && widgetsPayload.widgets) {
      setWidgetList(processWidgetsData(widgetsPayload.widgets));
      setInitialState(true);
    }
  };

  function setTrans() {
    setCH(!cH)
  };

  const navigateToWidget = (widgetId) => {
    history.push(`/dashboard/${businessId}/Widgets/${widgetId}`);
  };

  const ViewChanger = () => {
    if (small) {
      return (
        <ListTable
          identifier={"admin_orders"}
          tableHeaders={widgetsHeaders}
          tableRows={widgetList}
          rowClick={navigateToWidget}
        />
      );
    } else {
      return (
        widgetList.map((mobileWidget) => {
          return (
            <ListCard
              action={() => { history.push(`/dashboard/${businessId}/Widgets/${mobileWidget.id}`); }}
              cardName={mobileWidget.name}
              statusTagValue={mobileWidget.isActive}
              activeValue="Active"
              inactiveValue="Inactive"
              other={mobileWidget.serviceName}
            />
          )
        })
      );
    };
  };

  const setUp = async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let data = await getGenericDetails({
      businessId: businessId
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (data && data.genericDetails) {
      let tempServList = [];
      let tempSiteList = [];
      data.genericDetails.serviceDetails.forEach((service) => {
        if (service.isActive) {
          tempServList.push([service.name, service.serviceId, service.isVirtual])
        }
      });
      data.genericDetails.siteDetails.forEach((site) => {
        if (site.isActive) {
          tempSiteList.push([site.name, site.siteId])
        }
      });
      setServiceOptions(tempServList);
      setSiteOptions(tempSiteList);
    }
  };

  useEffect(async () => {
    setUp();
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    await getWidgetsDetails();
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
  }, [currentPage, currentLimit]);

  if (initialState === null) {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={large ? classes.mainWrapper : !large && small ? classes.mobilePadding : null}>
        <CModal open={add} setOpen={setAdd}
          children={
            <Container component="main" className={classes.formContainer}>
              <CTitle title="Add Widget" />
              <Grid container>
                {cH ? null : <Grid item xs={6} sm={3} style={small ? { paddingTop: "10px", marginBottom: -25 } : { paddingTop: "10px", marginBottom: -10 }}>
                  <CColor
                    label="Background Color"
                    onchange={setC}
                    color={c}
                  />
                </Grid>}
                <Grid item xs={6} sm={cH ? 4 : 3} style={small ? { paddingTop: "10px", marginBottom: -25 } : { paddingTop: "10px", marginBottom: -10 }}>
                  <CColor
                    label="Text Color"
                    onchange={setTC}
                    color={tC}
                  />
                </Grid>
                <Grid item xs={6} sm={cH ? 4 : 3} style={{ paddingTop: "10px", marginBottom: -25 }}>
                  <CColor
                    label="Button Color"
                    onchange={setBC}
                    color={bC}
                  />
                </Grid>
                <Grid item xs={6} sm={cH ? 4 : 3} style={{ paddingTop: "10px", marginBottom: -25 }}>
                  <CColor
                    label="Button Text Color"
                    onchange={setBTC}
                    color={bTC}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginTop: 10, marginBottom: -40 }}>
                  <CCheckBox label="Transparent Background?" onchange={setTrans} />
                </div>
              </Grid>
              <Form
                action={handleSubmit}
                postAction={null}
                cancelEnabled
                cancelAction={() => { setAdd(false); }}
                cancelText="Cancel"
                submitText="Create"
                inputFields={
                  [
                    {
                      type: "TextField",
                      validator: [notEmpty, noWhiteSpace],
                      initialvalue: "",
                      label: "Name",
                      name: "name",
                    },
                    {
                      type: "MultiSelect",
                      name: "serviceId",
                      label: "Service",
                      validator: (value) => (!value ? "A service must be selected" : null),
                      choices: serviceOptions,
                      size: {
                        xs: 12,
                        sm: 6
                      }
                    },
                    {
                      type: "SingleSelect",
                      name: "siteId",
                      label: "Site",
                      validator: (value) => (!value ? "A site must be selected" : null),
                      choices: siteOptions,
                      size: {
                        xs: 12,
                        sm: 6
                      }
                    },
                    {
                      type: "MultiSelect",
                      name: "optionTypes",
                      label: "Option Types",
                      validator: (value) => (!value ? "An option must be selected" : null),
                      choices: [
                        ["Unit", "Unit"],
                        ["Pack", "Pack"],
                        ["Season Pass", "Season Pass"]
                      ],
                      size: {
                        xs: 12
                      }
                    }
                  ]
                }
              >
              </Form>
            </Container>
          }
        />
        <Grid container direction="row" item xs={12} className={small ? null : classes.stickyHeader} style={!large && small ? { paddingTop: "15px" } : null}>
          <Grid item xs={6}>
            <div className={classes.headerPadding}>
              <span className={small ? classes.topHeading : classes.mobileTopHeadingOne}>Widgets</span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.headerPadding}>
              <LightTooltip title="Create Widget" arrow>
                <span className={classes.rightElem}>
                  <Button className={classes.buttonBasic} color='primary' variant='contained' onClick={() => { setAdd(true); }}>Add</Button>
                </span>
              </LightTooltip>
              <PostFilter allEntries={widgetList} keyObjects={keyObjects} />
            </div>
          </Grid>
        </Grid>
        {widgetList.length > 0 ?
          <Grid item xs={12} className={small ? null : classes.mobilePadding}>
            <div>
              <ViewChanger />
            </div>
          </Grid> : <EmptyListImage name="Widgets" />}
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  }
};

export default WidgetsList;