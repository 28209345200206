import { Button, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { customerAttendeeList, safePush, withLoading } from "../../../../utils";
import EmptyListImage from "../../../EmptyListImage/Empty";
import ListCard from "../../../MobileCards/ListCard";
import ListTable from "./../../../TableComponents/ListTable";
import AttendeeForm from "./AttendeeForm";
import { extractDate } from '../../../../FormValidation/validDate'
const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    rightElem: {
        float: "right",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    },
    buttonBasic: {
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeading: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    bottomSpacer: {
        height: "50px",
    },
}));

const AttendeeList = (props) => {
    const { basePath } = props
    const [open, setOpen] = useState(false);
    const [attendeeList, setAttendeeList] = useState(null)
    const history = useHistory()
    const location = useLocation()
    const classes = useStyles();
    const bigEnough = useMediaQuery("(min-width:750px)")


    const attendeeHeaders = [
        {
            name: "id",
            columnKey: "attendeeNumber",
            columnWidth: "100px",
        },
        {
            name: "name",
            columnKey: "name",
            columnWidth: "30%",
        },
        {
            name: "email",
            columnKey: "email",
            columnWidth: "25%",
        },
        {
            name: "date of birth",
            columnKey: "dob",
            columnWidth: "100px",
        },
    ];



    const getAttendeeList = async () => {
        const attendeeResponse = await withLoading(customerAttendeeList, {});
        if (attendeeResponse && !attendeeResponse.error) {
            setAttendeeList(attendeeResponse.attendees);
        };

    };

    useEffect(async () => {
        await withLoading(getAttendeeList, {}, true)
    }, []);



    const ViewChanger = () => {
        if (bigEnough) {
            return (<React.Fragment>

                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        <ListTable
                            identifier={"attendee"}
                            tableHeaders={attendeeHeaders}
                            rowClick={(attendeeId) => {
                                safePush(`${basePath}/${attendeeId}`, history, location)
                            }}
                            tableRows={attendeeList.map(attendee => ({
                                id: attendee.attendeeId,
                                attendeeNumber: attendee.attendeeNumber,
                                name: `${attendee.firstName} ${attendee.lastName}`,
                                email: attendee.email,
                                dob: attendee.dob ? extractDate(attendee.dob).toLocaleDateString('en-US') : undefined
                            }))}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bottomSpacer}></div>
                </Grid>
            </React.Fragment>)
        }
        else {
            return (<Grid item xs={12} style={{ paddingTop: "10px" }}>
                {attendeeList.map((attendee) => {
                    return (
                        <ListCard
                            action={() => { safePush(`${basePath}/${attendee.attendeeId}`, history, location) }}
                            key={attendee.attendeeId}
                            cardName={`${attendee.firstName} ${attendee.lastName}`}
                            statusReplace={attendee.email}
                            other={
                                <Typography>
                                    {attendee.attendeeNumber}
                                </Typography>
                            } />
                    )
                })}
            </Grid>)
        }

    }


    if (attendeeList === null) {
        return (null)
    }
    else {
        return (
            <React.Fragment>
                <AttendeeForm open={open} setOpen={setOpen} redirect={(attendeeId) => { safePush(`${basePath}/${attendeeId}`, history, location) }} />
                <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                    <Grid item xs={12}>
                        <div className={classes.headerPadding}>
                            <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Attendees</span>
                            <span title="Add Service" className={classes.rightElem}>
                                <Button
                                    className={classes.buttonBasic}
                                    onClick={() => { setOpen(true); }}
                                    color="primary"
                                    variant="contained"
                                >
                                    Add
                                </Button>
                            </span>
                        </div>
                    </Grid>
                    {(Array.isArray(attendeeList) && attendeeList.length > 0 ?
                        <ViewChanger />
                        :
                        <EmptyListImage name="Attendees" />)}
                </Grid>
            </React.Fragment>
        );
    }

};

export default AttendeeList;