import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { listPayouts } from "../../../../../../utils";
import { getAccountBalanceDetails } from "../../../../../../utils";
import { Button, Grid, CircularProgress, useMediaQuery } from "@material-ui/core";
import ListTable from "./../../../../../TableComponents/ListTable";
import { useHistory } from "react-router-dom";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import ListCard from "../../../../../MobileCards/ListCard";
import Moment from "react-moment";
import StatusTag from "../../../../../TagComponents/StatusTag";
import CurrencyPreProcessor from "../../../../../CurrencyFormatter/CurrencyFormatter";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
    paddingLeft: "10px",
  },
  fieldColumnOne: {
    width: "70px",
    display: "inline-block",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  labelText: {
    fontFamily:"Rubik, sans-serif",
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockText: {
    paddingLeft: "10px",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingTop: "10px",
    backgroundColor: "#ffffff"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  }
}));

const PayoutList = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width: 750px)');
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const payoutHeaders = [
    {
      name: "status",
      columnKey: "tag",
      columnWidth: "100px",
      type: "Normal"
    },
    {
      name: "type",
      columnKey: "fixedType",
      columnWidth: "15%",
      tooltip: true
    },
    {
      name: "initialized",
      columnKey: "created",
      columnWidth: "20%",
      tooltip: true
    },
    {
      name: "arrival",
      columnKey: "arrival",
      columnWidth: "20%",
      tooltip: true
    },
    {
      name: "amount",
      columnKey: "statementAmount",
      columnWidth: "85px",
      type: "Normal"
    }
  ];

  const [payoutList, setPayoutList] = useState([]);
  const [balanceDetails, setBalanceDetails] = useState([]);
  const [initialState, setInitialState] = useState(null);
  const [currencyInfo, setCurrencyInfo] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [lastRecordId, setLastRecordId] = useState(null);

  const getAccountBalance = async () => {
    let payload = await getAccountBalanceDetails({
      businessId: businessId
    });
    if (payload) {
      setBalanceDetails(payload.balanceInfo);
    }
  }

  const getPayoutDetails = async () => {
    let payoutsPayload = null;
    if (!loadMore) {
      payoutsPayload = await listPayouts({
        businessId: businessId
      });
    } else {
      payoutsPayload = await listPayouts({
        businessId: businessId,
        lastRecordId: lastRecordId
      });
    }
    if (payoutsPayload && payoutsPayload.payouts && payoutsPayload.currencyInfo) {
      setPayoutList(processPayoutData(payoutsPayload.payouts));
      setCurrencyInfo(payoutsPayload.currencyInfo);
      setHasNext(payoutsPayload.hasNext);
      setLastRecordId(payoutsPayload.lastRecordId);
      setInitialState(true);
    }
  };

  const processPayoutData = (payData) => {
    return payData.map((pay) => {
      let {
        recordId,
        amount,
        status,
        createdAt,
        estimatedArival,
        type,
      } = pay;

      let id = recordId;
      let tag = <StatusTag wrapperName={status === "Paid" ? "greenWrapper" : "redWrapper"} value={status === "Paid" ? "Complete" : "Incomplete"} />;
      let fixedType = type === "Deposit" ? type : "Withdrawl";
      let statementAmount = (<div style={type === "Withdrawn" ? { color: "red" } : { color: "green" }}><CurrencyPreProcessor
        amount={amount}
        currencyBreak={props.currencyInfo.currencyBreak}
        roundingFactor={props.currencyInfo.roundingFactor}
        currencySymbol={props.currencyInfo.currencySymbol}
      /> </div>);

      let created = <Moment format="lll">{createdAt}</Moment>
      let arrival = <Moment format="lll">{estimatedArival}</Moment>

      return {
        id,
        statementAmount,
        status,
        tag,
        created,
        arrival,
        fixedType,
      };
    });
  };

  const navigateToPayout = (recordId) => {
    history.push(`/dashboard/${businessId}/Payout-Statements/${recordId}`);
  };

  const ViewChanger = () => {
    if (matches) {
      return (
        <ListTable
          payoutStyling={{ width: "100%" }}
          identifier={"admin_orders"}
          tableHeaders={payoutHeaders}
          tableRows={payoutList}
          rowClick={navigateToPayout}
        />
      );
    } else {
      
      return (
        payoutList.map((mobilePay) => {
          return (
            <ListCard
              action={() => { history.push(`/dashboard/${businessId}/Payout-Statements/${mobilePay.id}`); }}
              cardName={mobilePay.fixedType}
              statusTagValue={mobilePay.status === "Paid" ? true : false}
              activeValue="Complete"
              inactiveValue="Incomplete"
              other={mobilePay.statementAmount}
            />
          )
        })
      );
    };
  };

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    await getPayoutDetails();
    await getAccountBalance();
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    setLoadMore(false);
  }, [loadMore]);

  if (initialState === null) {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={matches ? classes.mainWrapper : classes.mobilePadding}>
        <Grid item xs={matches ? 12 : 7}>
          <div className={classes.headerPadding}>
            <span className={matches ? classes.topHeading : classes.mobileTopHeadingOne}>Payout Statements</span>
          </div>
        </Grid>
        <Grid item xs={matches ? 12 : 5} style={matches ? null : { paddingTop: "25px" }}>
          <div className={classes.contentRow} style={{ paddingTop: "25px" }}>
            <div className={matches ? classes.contentColumnFirst : classes.fieldRows}>
              <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                <span className={classes.labelText}>Current</span>
              </div>
              <div className={matches ? classes.blockText : classes.fieldColumnTwo}>
                {balanceDetails.availableBalance >= 0 ?
                  <span className={classes.valueText} style={{ color: "green" }}>
                    <CurrencyPreProcessor
                      amount={balanceDetails.availableBalance}
                      currencyBreak={currencyInfo.currencyBreak}
                      roundingFactor={currencyInfo.roundingFactor}
                      currencySymbol={currencyInfo.currencySymbol}
                    />
                  </span>
                  :
                  <span className={classes.valueText} style={{ color: "red" }}>
                    <CurrencyPreProcessor
                      amount={balanceDetails.availableBalance}
                      currencyBreak={currencyInfo.currencyBreak}
                      roundingFactor={currencyInfo.roundingFactor}
                      currencySymbol={currencyInfo.currencySymbol}
                    />
                  </span>
                }
              </div>
            </div>
            <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
              <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                <span className={classes.labelText}>Pending</span>
              </div>
              {balanceDetails.pendingBalance >= 0 ? <span className={classes.valueText} style={{ color: "green" }}>
                <CurrencyPreProcessor
                  amount={balanceDetails.pendingBalance}
                  currencyBreak={currencyInfo.currencyBreak}
                  roundingFactor={currencyInfo.roundingFactor}
                  currencySymbol={currencyInfo.currencySymbol}
                />
              </span>
                :
                <span className={classes.valueText} style={{ color: "red" }}>
                  <CurrencyPreProcessor
                    amount={balanceDetails.pendingBalance}
                    currencyBreak={currencyInfo.currencyBreak}
                    roundingFactor={currencyInfo.roundingFactor}
                    currencySymbol={currencyInfo.currencySymbol}
                  />
                </span>
              }
            </div>
            <div className={matches ? classes.contentColumnLast : classes.fieldRows}>
              <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                <span className={classes.labelText}>Available</span>
              </div>
              <div className={matches ? classes.blockText : classes.fieldColumnTwo}>
                {balanceDetails.instantAvailable >= 0 ? <span className={classes.valueText} style={{ color: "green" }}>
                  <CurrencyPreProcessor
                    amount={balanceDetails.instantAvailable}
                    currencyBreak={currencyInfo.currencyBreak}
                    roundingFactor={currencyInfo.roundingFactor}
                    currencySymbol={currencyInfo.currencySymbol}
                  />
                </span>
                  :
                  <span className={classes.valueText} style={{ color: "red" }}>
                    <CurrencyPreProcessor
                      amount={balanceDetails.instantAvailable}
                      currencyBreak={currencyInfo.currencyBreak}
                      roundingFactor={currencyInfo.roundingFactor}
                      currencySymbol={currencyInfo.currencySymbol}
                    />
                  </span>
                }
              </div>
            </div>
          </div>
          <div style={matches ? null : {paddingLeft:"10px"}}>
            <span style={{fontSize:"12px", whiteSpace:"normal"}} className={classes.valueText}>Max Overdraft <CurrencyPreProcessor 
                  amount={balanceDetails.maxNegativeAllowed}
                  currencyBreak={currencyInfo.currencyBreak}
                  roundingFactor={currencyInfo.roundingFactor}
                  currencySymbol={currencyInfo.currencySymbol}
                />
              </span>
          </div>
        </Grid>
        {payoutList.length > 0 ?
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <ViewChanger />
            </div>
          </Grid> : <EmptyListImage name = "Payouts"/>}
        {!hasNext ?
          <Grid item xs={12}>
            <Button className={classes.buttonBasic} style={{ float: "right" }} onClick={() => { setLoadMore(true); }}>Load More</Button>
          </Grid>
          : null}
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  }
};

export default PayoutList;
