import { Button, Grid, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getResourceList, retrieveGetParams, withLoading } from '../../../../../../utils';
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import PostFilter, { applyFilters } from '../../../../../Filter/PostFilter';
import ListCard from "../../../../../MobileCards/ListCard";
import StatusTag from "../../../../../TagComponents/StatusTag";
import ListTable from "./../../../../../TableComponents/ListTable";
import CreateResourceForm from './CreateResourceForm';


const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    rightElem: {
        float: "right",
    },
    buttonBasic: {
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    topHeading: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    bottomSpacer: {
        height: "50px",
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    }
}));

const resourceHeaders = [
    {
        name: "status",
        columnKey: "status",
        columnWidth: "150px",
        type: "Normal"
    },
    {
        name: "name",
        columnKey: "name",
        columnWidth: "40%",
        tooltip: true
    },
    {
        name: "site name",
        columnKey: "siteName",
        columnWidth: "30%",
        tooltip: true
    },
    {
        name: "Resource Type",
        columnKey: "templateName",
        columnWidth: "30%",
        tooltip: true
    },
];

const processResourceData = (resourceData) => {
    return resourceData.map((resource) => {
        let {
            resourceId,
            name,
            isActive,
            siteName,
            templateName
        } = resource;

        let status = <StatusTag wrapperName={isActive ? "greenWrapper" : "redWrapper"} value={isActive ? "Active" : "Inactive"} />;
        let id = resourceId;

        return {
            id,
            resourceId,
            status,
            statusName: isActive ? "Active" : "Not Active",
            name,
            isActive,
            siteName,
            templateName
        };
    });
};

const keyObjects = [
    {
        key: 'isActive',
        label: "Status",
        keyLabel: 'statusName'
    },
    {
        key: 'siteName',
        label: "Site",
        keyLabel: 'siteName'
    },
    {
        key: 'templateId',
        label: "Resource Type",
        keyLabel: 'templateName'
    },
]

const ResourceList = (props) => {
    const { basePath } = props
    const classes = useStyles();
    const history = useHistory();
    const [add, setAdd] = useState(false);
    const businessId = useSelector((state) => {
        return state.buisnessDetails.businessId;
    });
    const [resources, setResources] = useState(null);
    const bigEnough = useMediaQuery("(min-width:750px)")
    const location = useLocation()
    const queryParams = retrieveGetParams(location)


    const ViewChanger = () => {
        if (bigEnough) {
            return (<React.Fragment>
                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        <ListTable
                            identifier={"resource"}
                            tableHeaders={resourceHeaders}
                            tableRows={applyFilters(resources, queryParams, keyObjects)}
                            rowClick={navigateToResource}
                        />

                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bottomSpacer}></div>
                </Grid>
            </React.Fragment>)
        }
        else {
            return (<Grid item xs={12} style={{ paddingTop: "10px" }}>
                {applyFilters(resources, queryParams, keyObjects).map((resource) => {
                    return (
                        <ListCard
                            action={() => { history.push(`${props.basePath}/${resource.id}`); }}
                            cardName={resource.name}
                            statusReplace={
                                <Typography
                                    noWrap
                                    style={!resource.isActive ? { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "red" } : { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "green" }}>
                                    {resource.isActive ? "Active" : "Inactive"}
                                </Typography>
                            } />
                    )
                })}
            </Grid>)
        }

    }

    const fetchResourceList = async () => {
        let resourceResponse = await getResourceList({
            businessId: businessId,
        });
        if (resourceResponse) {
            setResources(processResourceData(resourceResponse.resourceList));
        };
    };

    const navigateToResource = (resourceId) => {
        history.push(`${basePath}/${resourceId}`);
    };

    useEffect(() => {
        withLoading(fetchResourceList, {}, true)
    }, []);

    if (resources === null) {
        return (null)
    }
    else {
        return (
            <React.Fragment>
                <CreateResourceForm open={add} setOpen={setAdd} basePath={basePath} />
                <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                    <Grid item xs={12}>
                        <div className={classes.headerPadding}>
                            <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Resources</span>
                            <span className={classes.rightElem}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{ marginLeft: '10px', textTransform: "none" }}
                                    onClick={() => { setAdd(true); }}
                                >
                                    Add
                                </Button>
                            </span>
                            <span className={classes.rightElem}>
                                <PostFilter allEntries={resources ?? []} keyObjects={keyObjects} />
                            </span>
                        </div>
                    </Grid>

                    {(Array.isArray(resources) && resources.length > 0 ?
                        <ViewChanger />
                        :
                        <EmptyListImage name="Resource" />)}
                </Grid>
            </React.Fragment>
        );
    }
}



export default ResourceList