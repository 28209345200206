import { useState } from "react"
import CModal from "../../../../../Form/CModal"
import { Checkbox, Container, Grid, Typography, makeStyles, FormControlLabel } from "@material-ui/core"
import CTitle from "../../../../../Form/CTitle"
import CModalError from "../../../../../Form/CModalError"
import { useSelector } from "react-redux"
import CButtonPair from "../../../../../Form/CButtonPair"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { setResourceStatus, withLoading } from "../../../../../../utils"

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100%",
        maxHeight: "80vh",
        overflowY: "auto"
    },
    empty: {
        color: theme.palette.textColor3.main
    },
    formLabel: {
        fontSize: 14,
        color: theme.palette.textColor2.main,
    },
    fieldText: {
        fontSize: 14,
        color: theme.palette.primaryText.main,
        paddingLeft: "10px"
    },
    helperText: {
        marginRight: 14,
        marginLeft: 14
    }
}))

const ResourceStatusForm = (props) => {
    const classes = useStyles()
    const { open, setOpen, resource, fetchResourceDetails } = props
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const [removeFromEvents, setRemoveFromEvents] = useState(false)

    const saveStatus = async () => {
        const statusResponse = await withLoading(setResourceStatus, {
            businessId,
            resourceId: resource.resourceId,
            editTag: resource.editTag,
            removeFromEvents: resource.isActive ? removeFromEvents : undefined,
            isActive: !resource.isActive
        })
        if (!statusResponse?.error) {
            setOpen(false)
            fetchResourceDetails()
        }
    }

    return (
        <CModal open={open} setOpen={setOpen}>
            <Container className={classes.formContainer}>
                <Grid container spacing={2} >
                    <CTitle title={`${resource.isActive ? "Deactivate" : "Activate"} Resource`} />
                    <CModalError />
                    {resource.isActive ?
                        <Grid item xs={12}>
                            <Typography component="span">
                                If you would like to have this resource removed from future events upon deactivatation, check the box below.
                            </Typography>

                        </Grid>
                        : null}
                    {
                        resource.isActive ?
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            icon={<CheckBoxOutlineBlankIcon className={classes.empty} />}
                                            checked={removeFromEvents}
                                            onChange={event => setRemoveFromEvents(event.target.checked)}
                                        />
                                    }
                                    label={
                                        <Typography className={classes.formLabel} component="span">
                                            Remove From Future Events
                                        </Typography>}
                                />
                            </Grid>
                            :
                            null
                    }
                    <Grid item xs={12}>
                        <Typography component="span">
                            Click the '{resource.isActive ? 'Deactivate' : 'Activate'}' button below to confirm that you want to {resource.isActive ? 'deactivate' : 'activate'} this resource.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CButtonPair
                            cancelEnabled
                            cancelAction={() => { setOpen(false) }}
                            submitText={resource.isActive ? 'Deactivate' : 'Activate'}
                            action={saveStatus}
                        />
                    </Grid>
                </Grid>
            </Container>
        </CModal>
    )
}

export default ResourceStatusForm