import React, { useState, useEffect } from 'react'
import { Button, Container, makeStyles, useMediaQuery, CircularProgress, Accordion, AccordionDetails, AccordionSummary, Grid, Collapse, } from '@material-ui/core'
import CModal from '../Form/CModal'
import Form from '../Form/Form'
import { withLoading, safeQuery, customerPrivateSlots, customerGroupSlots } from '../../utils'
import moment from 'moment'
import { Typography } from '@material-ui/core'
import CModalError from '../Form/CModalError'
import CButtonPair from '../Form/CButtonPair'
const useStyles = makeStyles(theme => ({

    formContainer: {
        border: "20px",
        backgroundColor: "white",
        borderRadius: "4px",
        padding: "20px",
        maxHeight: "80vh",
        overflowY: "auto",
        textAlign: "center",
    }

}))

const generateBoundDate = (openings, details) => {
    if (safeQuery(openings, ["length"]) > 0) {
        return {
            initialvalue: moment(openings[0].dateStr, "MMM, Do YYYY").utc(),
            endvalue: { utcDate: moment(openings.slice(-1).pop().dateStr, "MMM, Do YYYY").utc() }
        }
    }
    else {
        return { initialvalue: null, endvalue: null }
    }
}

const filterClasses = (classDetails, quantity) => {
    const temp = []
    classDetails.forEach(day => {
        const tempDay = {
            ...day,
            classes: []
        }
        day.classes.forEach(session => {
            if (session.availableSeats >= quantity) {
                tempDay.classes.push(session)
            }
        })
        temp.push(tempDay)
    })
    return temp
}

const SelectSession = (props) => {
    const classes = useStyles()
    const otherClasses = props.classes
    const [open, setOpen] = useState(false)
    const quantity = props.quantity
    const bigEnough = useMediaQuery("(min-width: 750px")
    const { details, staffDetails, optionDetails, bookingChoice, setBookingChoice, errors, onUpdate } = props
    const [dateChoice, setDateChoice] = useState(null)
    const [openings, setOpenings] = useState(null)
    const [calendarEditTag, setCalendarEditTag] = useState(null)
    const [firstRender, setFirstRender] = useState(false)
    const [roundLoaded, setRoundLoader] = useState(false)

    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true)
        }
        else {
            if (onUpdate && firstRender && props.dependancies) {
                onUpdate()
            }
        }
    }, [props.dependancies])

    const setUp = async () => {
        let data = null
        if (safeQuery(details, ["serviceDetails", "type"]) === "Private" && staffDetails) {
            setRoundLoader(true);
            data = await withLoading(customerPrivateSlots, {
                optionId: optionDetails.optionId,
                staffId: staffDetails.staffId
            })
            setRoundLoader(false);
            if (data && Array.isArray(data.slotDetails)) {
                setOpenings(data.slotDetails)
                setCalendarEditTag(data.calendarEditTag)
            }
        }
        else if (safeQuery(details, ["serviceDetails", "type"]) === "Group") {
            setRoundLoader(true);
            data = await withLoading(customerGroupSlots, {
                optionId: optionDetails.optionId
            })
            setRoundLoader(false);
            if (data && Array.isArray(data.classDetails)) {
                setOpenings(filterClasses(data.classDetails, quantity))
                setCalendarEditTag(data.calendarEditTag)
            }

        }

    }

    useEffect(() => {
        if (open) {
            setOpenings(null)
            setUp()
        }
    }, [open, quantity])

    const processDates = (allOpenings) => {
        const checkExists = new Set()
        const mapDateToTimes = new Map()
        if (Array.isArray(allOpenings)) {
            allOpenings.forEach(dateObj => checkExists.add(dateObj.dateStr))
            allOpenings.forEach(dateObj => mapDateToTimes.set(dateObj.dateStr, safeQuery(details, ["serviceDetails", "type"]) === "Private" ? dateObj.slots : dateObj.classes))
        }
        return [checkExists, mapDateToTimes]
    }

    const [checkExists, mapDateToTimes] = processDates(openings)
    return (
        <Accordion
            className={errors && errors.length > 0 ? otherClasses.accordionError : otherClasses.accordion}
            elevation={0}
            square
            expanded={Boolean(bookingChoice)}
            TransitionComponent={Collapse}
        >

            <AccordionSummary
                className={otherClasses.accordionSummary}
                expandIcon={bookingChoice ? null :
                    <Button >
                        <Typography color={"primary"} className={otherClasses.buttonText} onClick={() => { setOpen(true) }}>
                            View Openings
                        </Typography>
                    </Button>
                }
            >
                <Typography variant="h5" color={errors && errors.length > 0 ? "error" : undefined}>
                    {safeQuery(details, ["serviceDetails", "type"]) === "Private" ? "Select Session" : "Select Class"}
                </Typography>

            </AccordionSummary>
            <AccordionDetails
                classes={{
                    root: otherClasses.accordionContent
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        {
                            errors && errors.length > 0 ?
                                <ul style={{ marginTop: 0 }}>
                                    {errors.map((error, index) =>
                                        <Grid item xs={12} key={`${"Select Session"}-error-${index}`}>
                                            <li className={otherClasses.error}>
                                                <Typography>
                                                    {error}
                                                </Typography>
                                            </li>
                                        </Grid>
                                    )}
                                </ul>
                                : null
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {bookingChoice !== null ?
                            <React.Fragment>
                                <Typography display="inline" style={{ fontSize: 14, paddingRight: 10 }} className={otherClasses.primaryText}>
                                    {`${bookingChoice.dateChoice}, ${bookingChoice.choice.time}`}
                                </Typography>
                                <Typography display="inline" color="primary" className={`${otherClasses.buttonText} ${otherClasses.success}`} onClick={() => { setOpen(true) }}>
                                    Edit Choice
                                </Typography>
                            </React.Fragment>
                            : null}
                        <CModal
                            open={open}
                            setOpen={setOpen}
                        >
                            <Container className={classes.formContainer} style={{
                                width: (bigEnough ? "80vw" : "100vw")
                            }}
                            >
                                {
                                    openings === null ?
                                        <Grid container spacing={2} style={{ height: "calc(100%)" }} alignItems="center">
                                            <Grid item xs={12}>
                                                <CModalError />
                                            </Grid>
                                            <Grid item xs={12}>
                                                {roundLoaded ? <CircularProgress /> : null}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CButtonPair action={() => { setOpen(false) }} errors={[]} submitText="Close" />
                                            </Grid>



                                        </Grid>
                                        :
                                        openings.length === 0 ?
                                            <Typography>
                                                There are no openings listed. Please contact business
                                            </Typography>
                                            :
                                            <Form
                                                moreData={{ calendarEditTag: calendarEditTag }}
                                                title={`Book ${safeQuery(details, ["serviceDetails", "type"]) === "Private" ? "Session" : "Class"}`}
                                                submitText="Book"
                                                cancelEnabled
                                                cancelAction={() => { setOpen(false) }}
                                                action={async (payload) => {
                                                    setBookingChoice({ ...payload, dateChoice, calendarEditTag })
                                                }}
                                                postAction={async () => {
                                                    setOpen(false)
                                                }}
                                                inputFields={[
                                                    {
                                                        type: "SpecialDate",
                                                        validator: (value) => { if (value) { setDateChoice(value.format("MMM, Do YYYY")) } },
                                                        moreProps: {
                                                            shouldDisableDate: (day) => { return !checkExists.has(day.format("MMM, Do YYYY")) },
                                                        },
                                                        ...generateBoundDate(openings, details),
                                                        size: {
                                                            sm: 5,
                                                            xs: 12
                                                        }
                                                    },
                                                    {
                                                        type: "Chips",
                                                        name: "choice",
                                                        label: "Availabe Slots",
                                                        choices: (mapDateToTimes.get(dateChoice) ? mapDateToTimes.get(dateChoice).map(timeObj =>
                                                            [timeObj.time, {
                                                                time: timeObj.time,
                                                                data: safeQuery(details, ["serviceDetails", "type"]) === "Group" ? timeObj.classId : timeObj.utcDate,
                                                                classEditTag: safeQuery(details, ["serviceDetails", "type"]) === "Group" ? timeObj.classEditTag : undefined
                                                            }]) : []),
                                                        validator: [value => (!value ? "Must chose valid date and time" : null)],
                                                        size: {
                                                            sm: 7,
                                                            xs: 12
                                                        }
                                                    }
                                                ]}
                                            />
                                }
                            </Container>
                        </CModal>
                    </Grid>
                </Grid>

            </AccordionDetails>
        </Accordion>
    )
}

export default SelectSession
