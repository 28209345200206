import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import { editInternalEvent, editInternalSeries } from '../../../../../../utils';
import { CColor } from '../../../../../Form/CColorPicker';
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto"

    }
}))

const formatPayload = (payload, color) => {
    return {
        businessId: payload.businessId,
        eventId: payload.eventId,
        editTag: payload.editTag,
        seriesId: payload.seriesId,
        name: payload.name,
        description: payload.description?.length > 0 ? payload.description : null,
        primaryColor: color,
        hardBlock: payload.hardBlock,
    }
}

const EditInternalEventForm = (props) => {

    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()

    //State

    const [color, setColor] = useState(props.internalEventDetails.primaryColor)

    useEffect(() => {
        if (!props.open) {
            setColor(props.internalEventDetails.primaryColor)
        }
    }, [props.open, props.internalEventDetails.primaryColor])

    return (
        <React.Fragment>
            <CModal open={props.open} setOpen={props.setOpen}>
                <Container component="main" className={classes.formContainer}>
                    <Form
                        moreData={{
                            businessId: businessId,
                            eventId: props.internalEventDetails.eventId,
                            editTag: props.internalEventDetails.editTag,
                            seriesId: props.internalEventDetails.seriesId
                        }}
                        title="Edit Internal Event(s)"
                        cancelEnabled
                        cancelAction={() => { props.setOpen(false) }}
                        submitText="Edit"
                        action={async (payload) => {
                            const finalPayload = formatPayload(payload, color)
                            if (payload.applyAll === true) {
                                return await editInternalSeries(finalPayload)
                            }
                            else {
                                return await editInternalEvent(finalPayload)
                            }
                        }}
                        postAction={(res) => {
                            if (res && !res.error) {
                                props.setOpen(false)
                                props.fetchInternalEventDetails()
                            }
                        }}
                        inputFields={[
                            {
                                type: "TextField",
                                name: "name",
                                label: "Name",
                                validator: [notEmpty, noWhiteSpace],
                                initialvalue: props.internalEventDetails.name
                            },
                            {
                                type: "TextArea",
                                name: "description",
                                label: "Description",
                                initialvalue: props.internalEventDetails.description,
                                moreProps: {
                                    rowsMin: 1,
                                    maxRows: 4
                                },
                            },
                            {
                                type: "Custom",
                            },
                            {
                                type: "CheckBox",
                                name: "hardBlock",
                                label: "Block staff time",
                                initialvalue: props.internalEventDetails.hardBlock
                            },
                            {
                                type: "CheckBox",
                                name: "applyAll",
                                label: "Apply changes to all events in this series",
                                enabled: props.internalEventDetails.seriesId !== null
                            },

                        ]}
                        comp={
                            <Grid item xs={12}>
                                <CColor
                                    label="Display Color"
                                    onchange={setColor}
                                    color={color}
                                />
                            </Grid>
                        }
                    />
                </Container>
            </CModal>
        </React.Fragment>
    )
}

export default EditInternalEventForm