import React from 'react';
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import CustomersList from "./CustomerList";
import NewCustomer from "./NewCustomer";
import CustomerCredits from "./CustomerCredits";

function CustomersHandler() {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    return(
        <React.Fragment>
            <Switch>
            <Route path = {`/dashboard/${businessId}/Customers`} exact render = {() => {return(<CustomersList />)}} />
            <Route path = {`/dashboard/${businessId}/Customers/:customerIdURL/Credit`} render = {() => {return(<CustomerCredits />);}} />
            <Route path = {`/dashboard/${businessId}/Customers/:customerIdURL`} render = {() => {return(<NewCustomer />);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default CustomersHandler;