import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { adminReviewsList, withLoading } from "../../../../../../utils";
import { Grid, Typography, useMediaQuery, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ListTable from "./../../../../../TableComponents/ListTable";
import ListTableNavigation from "../../../../../TableComponents/ListTableNavigation";
import StatusTag from "../../../../../TagComponents/StatusTag";
import ListCard from "../../../../../MobileCards/ListCard";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import Rating from '@material-ui/lab/Rating'
import moment from 'moment'
const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    rightElem: {
        float: "right",
    },
    buttonBasic: {
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    topHeading: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    bottomSpacer: {
        height: "50px",
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    }
}));
const reviewsHeaders = [
    {
        name: "rating",
        columnKey: "rating",
        columnWidth: "150px",
        type: "Normal"
    },
    {
        name: "Service Name",
        columnKey: "serviceName",
        columnWidth: "25%",
        tooltip: true
    },
    {
        name: "type",
        columnKey: "reviewType",
        columnWidth: "100px",
    },

    {
        name: "reviewer",
        columnKey: "reviewerName",
        columnWidth: "25%",
        tooltip: true
    },
    {
        name: "Date",
        columnKey: "reviewDate",
        columnWidth: "150px",
    },


];

const ReviewsList = (props) => {
    const [add, setAdd] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const businessId = useSelector((state) => {
        return state.buisnessDetails.businessId;
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [currentLimit, setCurrentLimit] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [totalDocs, setTotalDocs] = useState(0);
    const [hasNext, setHasNext] = useState(false);
    const error = useSelector(state => state.error);
    const errorMessage = useSelector(state => state.errorMessage);
    const [hasPrevious, setHasPrevious] = useState(false);
    const [reviewsList, setReviewsList] = useState(null);
    const bigEnough = useMediaQuery("(min-width:750px)")
    const processReviewsData = (reviewData) => {
        return reviewData.map((review) => {
            let {
                reviewId,
                reviewerFirstName,
                reviewerLastName,
                reviewType,
                reviewDate,
                rating,
                serviceName
            } = review;
            return {
                id: reviewId,
                reviewerName: `${reviewerFirstName} ${reviewerLastName}`,
                reviewType,
                rating: <Rating value={rating} readOnly />,
                reviewDate: moment(reviewDate).format("MMM, Do, YYYY"),
                serviceName
            };
        });
    };

    const ViewChanger = () => {
        if (bigEnough) {
            return (<React.Fragment>
                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        <ListTable
                            identifier={"reviews"}
                            tableHeaders={reviewsHeaders}
                            tableRows={reviewsList}
                            rowClick={navigateToReview}
                        />

                    </div>
                </Grid>

            </React.Fragment>)
        }
        else {
            return (<Grid item xs={12} style={{ paddingTop: "10px" }}>
                <div className={classes.contentRow}>
                    {reviewsList.map((review) => {
                        return (
                            <ListCard
                                action={() => { history.push(`${props.basePath}/${review.id}`); }}
                                cardName={review.reviewDate}
                                statusReplace={review.rating}
                                other={review.serviceName} />
                        )
                    })}
                </div>
            </Grid>)
        }

    }



    const getReviewsDetails = async () => {
        let reviewsPayload = await adminReviewsList({
            businessId: businessId,
            limit: currentLimit,
            page: currentPage,
            staffIds: [],
            ratingTypes: []
        })


        if (reviewsPayload && reviewsPayload.reviews && Array.isArray(reviewsPayload.reviews.docs)) {
            setReviewsList(processReviewsData(reviewsPayload.reviews.docs));
            setTotalPages(reviewsPayload.reviews.totalPages);
            setTotalDocs(reviewsPayload.reviews.totalDocs);
            setHasNext(reviewsPayload.reviews.hasNextPage);
            setHasPrevious(reviewsPayload.reviews.hasPrevPage);
        }
        else {
            setReviewsList(null);
            setTotalPages(0);
            setTotalDocs(0);
            setHasNext(false);
            setHasPrevious(false);
        }
    };

    const navigateToReview = (reviewId) => {
        history.push(`${props.basePath}/${reviewId}`);
    };

    useEffect(async () => {
        withLoading(getReviewsDetails, {}, true)

    }, [currentPage, currentLimit]);

    if (reviewsList === null) {
        return (null)
    }
    else {
        return (
            <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                <Grid item xs={12}>
                    <div className={classes.headerPadding}>
                        <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Reviews</span>
                    </div>
                </Grid>

                {(Array.isArray(reviewsList) && reviewsList.length > 0 ?
                    <ViewChanger />
                    :
                    <EmptyListImage name="Reviews" />)}
                <Grid item xs={12}>
                    <ListTableNavigation
                        currentPage={currentPage}
                        currentLimit={currentLimit}
                        totalDocs={totalDocs}
                        hasNext={hasNext}
                        hasPrevious={hasPrevious}
                        getPrevPage={() => { setCurrentPage(currentPage - 1) }}
                        getNextPage={() => { setCurrentPage(currentPage + 1) }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bottomSpacer}></div>
                </Grid>

            </Grid>
        );
    }


};

export default ReviewsList;

