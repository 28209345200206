import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    recordRow: {
        paddingLeft : "5px",
        paddingTop : "10px",
        paddingBottom : "10px",
    },
    dialogHeader :{
      color: "#1a1f36",
        paddingBottom: 5,
        boxShadow: "inset 0 -1px #e3e8ee",
        fontFamily: "Rubik, sans-serif",
        fontSize:"20px"
    },
    labelText: {
        color : "#3c4257",
        fontSize : "18px"
    },
    fieldText: {
        color : "#1a1f36",
        fontSize : "18px"
    },
    timeZoneText: {
        fontSize: "10px"
    },
    buttonBasic: {
      backgroundColor: "#ffffff",
      color: "#1a1f36",
      fontSize: "14px",
      boxShadow:
        " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingTop: "3px",
      paddingBottom: "3px",
      textTransform: "none",
    },
    buttonAction: {
        color: "#1a1f36",
        fontSize: "14px",
        boxShadow: "rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;"
    }
}));

const SessionDialog = (props) => {
    const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="alert-dialog-title"
      maxWidth="xs"
      zIndex= {99999}
    >
      <Grid item style={{paddingLeft:"15px", paddingRight:"15px"}}>
        <DialogTitle style={{minWidth:"300px"}} className={classes.dialogHeader} id="alert-dialog-title">{props.sessionInfo.startTime} <span className={classes.timeZoneText}>({props.sessionInfo.timeZone})</span></DialogTitle>
      </Grid>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.recordRow}>
              <span className={classes.labelText}>Attendee : </span>
              <span className={classes.fieldText}>{`${props.sessionInfo.attendeeFirstName} ${props.sessionInfo.attendeeLastName}`}</span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.recordRow}>
              <span className={classes.labelText}>Service : </span>
              <span className={classes.fieldText}>{props.sessionInfo.serviceName}</span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.recordRow}>
              <span className={classes.labelText}>Location : </span>
              <span className={classes.fieldText}>{props.sessionInfo.siteName}</span>
            </div>
          </Grid>
          {props.role === "Staff" ? null : <Grid item xs={12}>
            <div className={classes.recordRow}>
              <span className={classes.labelText}>Staff : </span>
              <span className={classes.fieldText}>{`${props.sessionInfo.staffFirstName} ${props.sessionInfo.staffLastName}`}</span>
            </div>
          </Grid>}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogFooter}>
        <Button
          className={classes.buttonBasic}
          onClick={() => {
            props.close();
          }}
        >
          Close
        </Button>
        {props.noDetails ? null : <Button
          className={classes.buttonBasic}
          onClick={() => {
            props.sessionDetails();
          }}
          style={{color:"white", backgroundColor:"#0079fc"}}
        >
          View Details
        </Button>}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(SessionDialog);
