import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { extractDate } from "../../../../../../FormValidation/validDate";
import {
  adminAttendeeList,
  attendeeSearch,
  encodeGetParams,
  retrieveGetParams,
  safePush,
  withLoading,
} from "../../../../../../utils";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import ListCard from "../../../../../MobileCards/ListCard";
import Search from "../../../../../Search/Search";
import ListTable from "../../../../../TableComponents/ListTable";
import ListTableNavigation from "../../../../../TableComponents/ListTableNavigation";
import { attendeeHeaders } from "./attendeeTableHeaders";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  buttonBasic: {
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
}));

export const AttendeeTable = (props) => {
  const { header, basePath, attendeeList, otherProps } = props;
  const location = useLocation();
  const history = useHistory();

  return (
    <ListTable
      {...otherProps}
      identifier={"attendee"}
      tableHeaders={header}
      rowClick={(shareId) => {
        safePush(`${basePath}/${shareId}`, history, location);
      }}
      tableRows={attendeeList.map((attendee) => ({
        id: attendee.shareId,
        attendeeNumber: attendee.attendeeNumber,
        name: `${attendee.firstName} ${attendee.lastName}`,
        email: attendee.email,
        dob: attendee.dob
          ? extractDate(attendee.dob).toLocaleDateString("en-US")
          : undefined,
      }))}
    />
  );
};

const AttendeeList = (props) => {
  const { basePath } = props;
  const buisnessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const [attendees, setAttendees] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const bigEnough = useMediaQuery("(min-width:750px)");
  const queryParams = retrieveGetParams(location);
  const currentPage =
    "pg" in queryParams && Number.parseInt(queryParams.pg) > 0
      ? Number.parseInt(queryParams.pg)
      : 1;
  const pageLimit = 14;

  const getNextPage = () => {
    if (attendees?.hasNextPage) {
      history.push(
        location.pathname +
          "?" +
          encodeGetParams({ ...queryParams, pg: currentPage + 1 })
      );
    }
  };

  const getPrevPage = () => {
    if (attendees?.hasPrevPage) {
      history.push(
        location.pathname +
          "?" +
          encodeGetParams({ ...queryParams, pg: currentPage - 1 })
      );
    }
  };

  const getAttendeeList = async () => {
    let attendeeResponse = await withLoading(adminAttendeeList, {
      businessId: buisnessId,
      limit: pageLimit,
      page: currentPage,
    });
    if (attendeeResponse && !attendeeResponse.error) {
      const dataNeeded = {
        docs: attendeeResponse.attendees.docs,
        totalDocs: attendeeResponse.attendees.totalDocs,
        hasNextPage: attendeeResponse.attendees.hasNextPage,
        hasPrevPage: attendeeResponse.attendees.hasPrevPage,
      };
      setAttendees(dataNeeded);
    }
  };

  useEffect(() => {
    getAttendeeList();
  }, [currentPage]);

  const ViewChanger = () => {
    if (bigEnough) {
      return (
        <React.Fragment>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <AttendeeTable
                header={attendeeHeaders}
                basePath={basePath}
                attendeeList={attendees.docs}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            {attendees.docs.map((attendee) => {
              return (
                <ListCard
                  action={() => {
                    safePush(
                      `${basePath}/${attendee.shareId}`,
                      history,
                      location
                    );
                  }}
                  key={attendee.attendeeId}
                  cardName={`${attendee.firstName} ${attendee.lastName}`}
                  statusReplace={attendee.email}
                  other={<Typography>{attendee.attendeeNumber}</Typography>}
                />
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </React.Fragment>
      );
    }
  };

  if (attendees !== null) {
    return (
      <React.Fragment>
        <Grid
          container
          className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
        >
          <Grid item style={{ flexGrow: 1 }} xs={bigEnough ? undefined : 12}>
            <div className={classes.headerPadding}>
              <span
                className={
                  bigEnough ? classes.topHeading : classes.mobileTopHeadingOne
                }
              >
                Attendees
              </span>
            </div>
          </Grid>
          <Grid
            item
            xs={bigEnough ? undefined : 12}
            style={{ padding: undefined ? "0px" : "5px" }}
          >
            <Search
              style={{
                width: bigEnough ? "200px" : "100%",
              }}
              api={attendeeSearch}
              businessId={buisnessId}
              getLabel={(attendee) =>
                `${attendee.attendeeFirstName} ${attendee.attendeeLastName}`
              }
              getList={(response) => response.attendees}
              onclick={(attendee) => {
                safePush(
                  `/dashboard/${buisnessId}/Attendees/${attendee.shareId}`,
                  history,
                  location
                );
              }}
            />
          </Grid>
          {Array.isArray(attendees.docs) && attendees.docs.length > 0 ? (
            <React.Fragment>
              <Grid item xs={12}>
                <ViewChanger />
              </Grid>

              <Grid item xs={12}>
                <ListTableNavigation
                  currentPage={currentPage}
                  currentLimit={pageLimit}
                  totalDocs={attendees.totalDocs}
                  tableRows={attendees.docs}
                  hasNext={attendees.hasNextPage}
                  hasPrevious={attendees.hasPrevPage}
                  getPrevPage={getPrevPage}
                  getNextPage={getNextPage}
                />
              </Grid>
            </React.Fragment>
          ) : (
            <EmptyListImage name="Attendees" />
          )}
        </Grid>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default AttendeeList;
