import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import { Grid, useMediaQuery, Button, Typography } from "@material-ui/core";
import { StyleWrapper, StyleWrapper2 } from "./ScheduleCalendarStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import SessionDialog from "../../../../../CalendarDialogs/sessionDialog";
import SchedulingDialog from "./SchedulingDialog";
import { businessCalendarEvents, staffCalendarEvents } from "../../../../../../utils";
import CTitle from "../../../../../Form/CTitle";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import Form from "../../../../../Form/Form";
import ViewDayIcon from '@material-ui/icons/ViewDay';
import TodayIcon from '@material-ui/icons/Today';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ListIcon from '@material-ui/icons/List';
import "./ScheduleCalendar.css";
import CModalError from "../../../../../Form/CModalError.js";
import { validTime } from '../../../../../../FormValidation/validTime'

const useStyles = makeStyles((theme) => ({
    calendarBox: {
        width: "100%",
        maxHeight: "calc(100vh - 64px)",
        minHeight: "calc(100vh - 64px)"
    },
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    },
    buttonBasic: {
        backgroundColor: "#ffffff",
        color: "#1a1f36",
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText2: {
        fontSize: "12px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBold: {
        fontSize: "12px",
        color: "#1a1f36",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    subHeading: {
        fontSize: "20px",
        fontWeight: "normal",
        color: "#1a1f36",
    }
}));

const ScheduleCalendar = (props) => {
    const classes = useStyles();
    const today = new Date();
    const [_, updateState] = useState(0);
    const small = useMediaQuery('(min-width: 960px)');
    const [moduleId, setModuleId] = useState(false);
    const businessId = useSelector((state) => {
        return state.buisnessDetails.businessId;
    });
    const role = useSelector((state) => {
        return state.buisnessDetails.role;
    });
    const [sessionInfo, setSessionInfo] = useState([]);
    const [classInfo, setClassInfo] = useState([]);
    const [openClass, setOpenClass] = useState(false);
    const [openSession, setOpenSession] = useState(false);

    const getBisinessCalendarEvents = useCallback(async (calProp) => {
        try {
            let scheEvents = null;
            let refStaffIds = [props.staffIds];
            let refServiceIds = [props.serviceIds];
            if (props.preProcess) {
                if (props.staffIds == null) {
                    refStaffIds = []
                } else {
                    refStaffIds = [props.staffIds]
                };
                if (props.offeringType == "Private") {
                    refServiceIds = []
                } else {
                    refServiceIds = [props.serviceIds]
                };
            };

            if (props.role === "Staff") {
                scheEvents = await staffCalendarEvents({
                    businessId: businessId,
                    startStr: calProp.startStr,
                    endStr: calProp.endStr,
                    serviceIds: refServiceIds,
                    siteIds: [],
                    staffIds: refStaffIds,
                    serviceTypes: [],
                    statusTypes: ["Scheduled"],
                    modeTypes: []
                });
            } else {
                scheEvents = await businessCalendarEvents({
                    businessId: businessId,
                    startStr: calProp.startStr,
                    endStr: calProp.endStr,
                    serviceIds: refServiceIds,
                    siteIds: [],
                    staffIds: refStaffIds,
                    serviceTypes: [],
                    statusTypes: ["Scheduled"],
                    modeTypes: [],
                });
            }
            return (scheEvents.events)
        } catch (error) {
            console.error(error);
        }
    });

    const eventClick = useCallback((eventClick) => {
        if (eventClick.event.extendedProps.type === "Session") {
            setOpenSession(true);
            setModuleId(eventClick.event.id);
            setSessionInfo(eventClick.event.extendedProps);
        } else if (eventClick.event.extendedProps.type === "Class") {
            setOpenClass(true);
            setModuleId(eventClick.event.id);
            setClassInfo(eventClick.event.extendedProps);
            props.setClassId(eventClick.event.id);
        }
    });

    const SideBar = () => {
        return (
            <Grid container item spacing={2} alignItems="center" style={small ? { width: "100%", paddingTop: "100px" } : { width: "100%" }}>
                {small ? <Grid item style={{ width: `calc(100%)` }}>
                    <Typography align="left" className={classes.valueText} style={{ whiteSpace: "normal" }}>
                        {!props.mode ? `${props.serviceTypes} - In Person` : `${props.serviceTypes} - Virtual`}
                    </Typography>
                    <Typography noWrap className={classes.valueText}>
                        {props.location}
                    </Typography>
                    <Typography noWrap className={classes.valueText2} style={{ paddingTop: "10px" }}>
                        Select Session to Schedule
                    </Typography>
                    <Typography noWrap className={classes.valueTextBold}>
                        Time Zone: {props.timeZone}
                    </Typography>
                </Grid> : null}
                <Grid container spacing={1} item justifyContent="flex-start">
                    <Grid item style={{ paddingTop: "25px" }}>
                        <Form
                            moreData={{ ...props.moreData }}
                            action={props.handleSubmit}
                            postAction={null}
                            title=""
                            cancelEnabled
                            cancelAction={props.cancel}
                            cancelText="Cancel"
                            submitText="Schedule"
                            inputFields={
                                [
                                    {
                                        type: "Date",
                                        initialvalue: (props.initialdate ? props.initialdate : ""),
                                        validator: notEmpty,
                                        label: "Date",
                                        name: "startDate",
                                        size: {
                                            xs: small ? 12 : 6
                                        }
                                    },
                                    {
                                        type: "Time",
                                        label: "Time",
                                        name: "startTime",
                                        initialvalue: (props.initialtime ? props.initialtime : ""),
                                        validator: validTime,
                                        size: {
                                            xs: small ? 12 : 6
                                        }
                                    },
                                ]
                            }
                        >
                        </Form>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    if (getBisinessCalendarEvents()) {
        return (
            <Grid container spacing={1} item xs={12} direction={small ? "row" : "column"} style={{ minWidth: "350px", width: "100%" }}>
                <CTitle title={props.serviceTypes === "Private" ? (props.edit ? "Reschedule Session" : "Schedule Session") : (props.edit ? "Reschedule Class" : "Schedule Class")} />
                <CModalError />
                {props.serviceTypes !== "Group" ?
                    <Grid item xs={small ? 3 : 12}>
                        <SideBar />
                    </Grid>
                    : null
                }

                {small ? <Grid item xs={small && props.serviceTypes === "Private" ? 9 : 12} style={{ width: "100%" }}>
                    <StyleWrapper2>
                        <FullCalendar
                            plugins={[timeGridPlugin, interactionPlugin, listPlugin]}
                            headerToolbar={{
                                left: "prev today next",
                                center: "title",
                                right: "timeGridWeek,timeGridDay",
                            }}
                            scrollTime={`${today.getHours()}:${today.getMinutes()}:${today.getSeconds}`}
                            scrollTimeReset={false}
                            view={{
                                timeGridWeek: {
                                    titleFormat: { month: "long", day: "numeric", year: "numeric" }
                                },
                                timeGridDay: {
                                    titleFormat: { month: "long", day: "numeric", year: "numeric" }
                                },
                            }}
                            initialView={"timeGridWeek"}
                            eventOverlap={false}
                            allDaySlot={false}
                            slotDuration="00:30:00"
                            slotEventOverlap={false}
                            nowIndicator={true}
                            slotLabelInterval="01:00"
                            events={getBisinessCalendarEvents}
                            eventClick={eventClick}
                            buttonText={{
                                prev: <ArrowBackIosIcon style={{ marginLeft: "4px", height: 18, width: 16 }} />,
                                next: <ArrowForwardIosIcon style={{ height: 18, width: 16 }} />,
                                today: 'Today',
                                week: 'Week',
                                day: 'Day'
                            }}
                            height={small ? "calc(100vh - 128px)" : "calc(80vh - 168px)"}
                        />
                    </StyleWrapper2>
                </Grid>
                    : <Grid item xs={small && props.serviceTypes === "Private" ? 9 : 12} style={{ width: "100%" }}>
                        <StyleWrapper>
                            <FullCalendar
                                plugins={[timeGridPlugin, interactionPlugin, listPlugin]}
                                headerToolbar={{
                                    left: "today",
                                    center: "prev,title,next",
                                    right: "listWeek,timeGridDay",
                                }}
                                scrollTime={`${today.getHours()}:${today.getMinutes()}:${today.getSeconds}`}
                                scrollTimeReset={false}
                                view={{
                                    listWeek: {
                                        titleFormat: { month: "long", day: "numeric", year: "numeric" }
                                    },
                                    timeGridDay: {
                                        titleFormat: { month: "long", day: "numeric", year: "numeric" }
                                    },
                                }}
                                initialView={"listWeek"}
                                slotEventOverlap={false}
                                allDaySlot={false}
                                slotDuration="00:30:00"
                                nowIndicator={true}
                                slotLabelInterval="01:00"
                                events={getBisinessCalendarEvents}
                                eventClick={props.serviceTypes === "Group" ? eventClick : null}
                                buttonText={{
                                    prev: <ArrowBackIosIcon />,
                                    next: <ArrowForwardIosIcon />,
                                    today: <TodayIcon />,
                                    week: <ListIcon style={{ height: 30, width: 30 }} />,
                                    day: <ViewDayIcon />
                                }}
                                height={small ? "calc(100vh - 128px)" : "calc(80vh - 168px)"}
                            />
                        </StyleWrapper>
                    </Grid>
                }
                {sessionInfo ? (
                    <SessionDialog
                        open={openSession}
                        noDetails={true}
                        role={role}
                        sessionInfo={sessionInfo}
                        close={() => {
                            setOpenSession(false);
                        }}
                    />
                ) : (
                    <div></div>
                )}
                {classInfo ? (
                    <SchedulingDialog
                        open={openClass}
                        scheduleAction={props.handleSubmit}
                        classInfo={classInfo}
                        close={() => {
                            setOpenClass(false);
                        }}
                    />
                ) : (
                    <div></div>
                )}
                {small && props.serviceTypes === "Private" ? null : !small && props.serviceTypes === "Private" ?
                    null
                    :
                    <Grid item xs={12}>
                        <Button fullWidth className={classes.buttonBasic} onClick={props.cancel}>Cancel</Button>
                    </Grid>
                }
            </Grid>

        );
    } else {
        return (
            <Grid container className={classes.mainWrapper}>
                <Grid item xs={12} style={{ whiteSpace: "normal" }}>
                    No Sessions associated with this pack have been scheduled
                </Grid>
            </Grid>
        )
    }

};

export default ScheduleCalendar;
