import { useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  dashboardPreferences,
  getGenericDetails,
  rescheduleClass,
  rescheduleSession,
  resourceCalendarEvents,
  resourceOrder,
  withLoading,
} from "../../../../../../utils";
import ClassDialog from "../../../../../CalendarDialogs/classDialog";
import FilterDialog from "../../../../../CalendarDialogs/filterDialog";
import InternalEventDialog from "../../../../../CalendarDialogs/internalEventDialog";
import SessionDialog from "../../../../../CalendarDialogs/sessionDialog";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import ResourceMapDialog from "./ResourceMapDialog";
import ResourceWorkbench from "./ResourceWorkbench";

const useStyles = makeStyles((theme) => ({
  calendarBox: {
    width: "100%",
    maxHeight: "calc(100vh - 64px)",
    minHeight: "calc(100vh - 64px)",
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

const ResourceWorkbenchHandler = () => {
  const [_, updateState] = useState(0);
  const classes = useStyles();
  const history = useHistory();
  const small = useMediaQuery("(min-width:960px)");
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const role = useSelector((state) => {
    return state.buisnessDetails.role;
  });

  const calendarRef = React.createRef();

  const [initialLoadComplete, setInitialLoad] = useState(false);
  const [openSession, setOpenSession] = useState(false);
  const [openClass, setOpenClass] = useState(false);
  const [sessionInfo, setSessionInfo] = useState(null);
  const [classInfo, setClassInfo] = useState(null);
  const [filterValue, setFilterValue] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterMode, setFilterMode] = useState(false);
  const [sessionId, setSessionId] = useState(false);
  const [classId, setClassId] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [openInternal, setOpenInternal] = useState(false);
  const [internalInfo, setInternalInfo] = useState(null);
  const [internalEventId, setInternalEventId] = useState(false);

  const [serviceIds, setServiceIds] = useState([]);
  const [staffIds, setStaffIds] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [statusTypes, setStatus] = useState([]);
  const [modeTypes, setModeTypes] = useState([]);

  const baseFieldSetRef = [
    setServiceIds,
    setStaffIds,
    setServiceTypes,
    setStatus,
    setModeTypes,
  ];

  const [serviceIdsOption, setServiceIdsOption] = useState([]);
  const [staffIdsOption, setStaffIdsOption] = useState([]);
  const [serviceTypesOption, setServiceTypesOption] = useState([]);
  const [statusTypesOption, setStatusTypesOption] = useState([]);
  const [modeTypesOption, setModeTypesOption] = useState([]);

  const [drag, setDrag] = useState(false);
  const [info, setInfo] = useState([]);
  const [preferences, setPreferences] = useState([]);
  const [rDialog, setRDialog] = useState(false);

  const dispatch = useDispatch();

  const filterList = [
    "serviceIds",
    "staffIds",
    "serviceTypes",
    "status",
    "mode",
  ];

  const pullGenericDetails = async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let genDetails = await getGenericDetails({ businessId: businessId });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (genDetails) {
      setServiceIdsOption(
        genDetails.genericDetails.serviceDetails.map((service) => {
          return {
            id: service.serviceId,
            name: service.name,
          };
        })
      );
      setStaffIdsOption(
        genDetails.genericDetails.staffDetails.map((staff) => {
          return {
            id: staff.staffId,
            name: `${staff.firstName} ${staff.lastName}`,
          };
        })
      );
      setServiceTypesOption([
        {
          id: "Private",
          name: "Private",
        },
        {
          id: "Group",
          name: "Group",
        },
      ]);

      setStatusTypesOption([
        {
          id: "Scheduled",
          name: "Scheduled",
        },
        {
          id: "Canceled",
          name: "Canceled",
        },
      ]);

      setModeTypesOption([
        {
          id: "In Person",
          name: "In Person",
        },
        {
          id: "Virtual",
          name: "Virtual",
        },
      ]);
    }
    setInitialLoad(true);
  };

  const getDashboardPreferences = async () => {
    let calPreferences = await dashboardPreferences({
      businessId: businessId,
    });
    if (calPreferences.dashboardPreferences.calendar) {
      setPreferences(calPreferences.dashboardPreferences.calendar);
    }
  };

  useEffect(() => {
    pullGenericDetails();
    getDashboardPreferences();
  }, []);

  const getResourceCalendarResources = useCallback(async () => {
    try {
      let calResources = await resourceOrder({
        businessId: businessId,
      });
      return calResources.resourceMap;
    } catch (error) {
      console.error(error);
    }
  }, []);

  const resourceLabelDidMount = (info) => {
    if (info?.resource?.extendedProps?.fields) {
      let infoIconWrapper = document.createElement("span");
      infoIconWrapper.innerHTML =
        '<sup><span class="infoIconCal infoHover">i</span></sup>';
      infoIconWrapper.onclick = () => {
        setRDialog(info.resource);
      };
      info.el
        .querySelector(".fc-datagrid-cell-main")
        .appendChild(infoIconWrapper);
    }
  };

  const getResourceCalendarEvents = useCallback(
    async (calProp) => {
      try {
        let calEvents = await resourceCalendarEvents({
          businessId: businessId,
          startStr: calProp.startStr,
          endStr: calProp.endStr,
          serviceIds,
          staffIds,
          serviceTypes,
          statusTypes,
          modeTypes,
        });
        return calEvents.events;
      } catch (error) {
        console.error(error);
      }
    },
    [serviceIds, staffIds, serviceTypes, statusTypes, modeTypes, _]
  );

  const eventClicked = useCallback((eventClickInfo) => {
    try {
      if (eventClickInfo.event.extendedProps.type === "Session") {
        setOpenSession(true);
        setSessionId(eventClickInfo.event.extendedProps.sessionId);
        setSessionInfo(eventClickInfo.event.extendedProps);
      } else if (eventClickInfo.event.extendedProps.type === "Class") {
        setOpenClass(true);
        setClassId(eventClickInfo.event.extendedProps.classId);
        setClassInfo(eventClickInfo.event.extendedProps);
      } else if (eventClickInfo.event.extendedProps.type === "Internal") {
        setOpenInternal(true);
        setInternalEventId(eventClickInfo.event.extendedProps.eventId);
        setInternalInfo(eventClickInfo.event.extendedProps);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const sessionDetails = () => {
    history.push(`/dashboard/${businessId}/Sessions/${sessionId}`);
  };

  const classDetails = () => {
    history.push(`/dashboard/${businessId}/Group-Classes/${classId}`);
  };

  const internalDetails = () => {
    history.push(`/dashboard/${businessId}/Internal-Events/${internalEventId}`);
  };

  const openFilterDialog = useCallback(() => {
    setFilterMode(true);
    setOpenFilter(true);
  }, []);

  const applyFilter = (dialogFieldRef) => {
    baseFieldSetRef[0](
      dialogFieldRef.serviceIds ? dialogFieldRef.serviceIds : serviceIdsOption
    );
    baseFieldSetRef[1](
      dialogFieldRef.staffIds ? dialogFieldRef.staffIds : staffIdsOption
    );
    baseFieldSetRef[2](
      dialogFieldRef.serviceTypes
        ? dialogFieldRef.serviceTypes
        : serviceTypesOption
    );
    baseFieldSetRef[3](
      dialogFieldRef.status ? dialogFieldRef.status : statusTypesOption
    );
    baseFieldSetRef[4](
      dialogFieldRef.mode ? dialogFieldRef.mode : modeTypesOption
    );
    setOpenFilter(false);
    setFilterMode(false);
    setFilterApplied(true);
    let num = 0;
    if (filterValue === 0) {
      filterList.map((item) => {
        if (dialogFieldRef[item].length > 0) {
          num = num + 1;
        }
        if (item === "mode") {
          setFilterValue(num);
        }
      });
    } else {
      filterList.map((item) => {
        if (dialogFieldRef[item].length > 0) {
          num = num + 1;
        }
        if (item === "mode") {
          setFilterValue(num);
        }
      });
    }
  };

  const clearFilter = () => {
    for (let i = 0; i < baseFieldSetRef.length; i++) {
      baseFieldSetRef[i]([]);
    }
    setOpenFilter(false);
    setFilterMode(false);
    setFilterApplied(false);
    setFilterValue(0);
  };

  const closeDragDialog = useCallback((info) => {
    setDrag(false);
    info.revert();
  }, []);

  const dragChange = async () => {
    let success = null;
    if (info.event.extendedProps.type == "Session") {
      success = await withLoading(rescheduleSession, {
        businessId: businessId,
        sessionId: info.event.extendedProps.sessionId,
        startDate:
          info.event.start.getFullYear() +
          "/" +
          (info.event.start.getMonth() + 1 < 10
            ? "0" + (info.event.start.getMonth() + 1)
            : info.event.start.getMonth() + 1) +
          "/" +
          (info.event.start.getDate() < 10
            ? "0" + info.event.start.getDate()
            : info.event.start.getDate()),
        startTime: info.event.start.toTimeString().slice(0, 5),
      });
    } else {
      success = await withLoading(rescheduleClass, {
        businessId: businessId,
        classId: info.event.extendedProps.classId,
        startTime: info.event.start.toTimeString().slice(0, 5),
        dateRef:
          info.event.start.getFullYear() +
          "/" +
          (info.event.start.getMonth() + 1 < 10
            ? "0" + (info.event.start.getMonth() + 1)
            : info.event.start.getMonth() + 1) +
          "/" +
          (info.event.start.getDate() < 10
            ? "0" + info.event.start.getDate()
            : info.event.start.getDate()),
      });
    }

    if (success) {
      updateState((_) => !_);
      setDrag(false);
    }
  };

  const eventInfo = useCallback((info) => {
    if (
      (info.event.extendedProps.type != "Service") |
      (info.event.extendedProps.offeringType != "Group")
    ) {
      setDrag(true);
      setInfo(info);
    } else {
      info.revert();
    }
  }, []);

  return (
    <Fragment>
      <Grid
        container
        className={small ? classes.mainWrapper : classes.mobilePadding}
      >
        <Grid item xs={12}>
          <ResourceWorkbench
            getEvents={getResourceCalendarEvents}
            getResources={getResourceCalendarResources}
            resourceLabelDidMount={resourceLabelDidMount}
            eventClick={eventClicked}
            openFilterDialog={openFilterDialog}
            info={eventInfo}
            clearFilter={clearFilter}
            filterNum={filterValue}
            filters={isFilterApplied}
            prefer={preferences}
            colHeader="Resources"
            sortOrder="sortOrder,title"
          />
        </Grid>
      </Grid>
      {sessionInfo ? (
        <SessionDialog
          open={openSession}
          sessionDetails={sessionDetails}
          sessionInfo={sessionInfo}
          close={() => {
            setOpenSession(false);
          }}
        />
      ) : (
        <div></div>
      )}

      {classInfo ? (
        <ClassDialog
          open={openClass}
          classDetails={classDetails}
          classInfo={classInfo}
          close={() => {
            setOpenClass(false);
          }}
        />
      ) : (
        <div></div>
      )}

      {internalInfo ? (
        <InternalEventDialog
          open={openInternal}
          internalDetails={internalDetails}
          internalInfo={internalInfo}
          close={() => {
            setOpenInternal(false);
          }}
        />
      ) : (
        <div></div>
      )}

      <GeneralDialog
        open={drag}
        close={() => {
          closeDragDialog(info);
        }}
        setOpen={() => {
          closeDragDialog(info);
        }}
        title="Reschedule Event"
        text={
          info.event != undefined
            ? `Do you want to reschedule this event to ${info.event.start.toLocaleString(
                { dateStyle: "short" }
              )} ?`
            : null
        }
        action={dragChange}
      />
      <ResourceMapDialog
        open={rDialog}
        close={setRDialog}
        title={rDialog.title}
        text={rDialog.extendedProps}
      />

      {initialLoadComplete && filterMode ? (
        <FilterDialog
          open={openFilter}
          applyFilter={applyFilter}
          page="ResourceMap"
          filters={isFilterApplied}
          clearFilter={isFilterApplied ? clearFilter : null}
          role={role}
          fieldOptions={[
            serviceIdsOption,
            staffIdsOption,
            serviceTypesOption,
            statusTypesOption,
            modeTypesOption,
          ]}
          isFilterApplied={isFilterApplied}
          currentFilterVal={[
            serviceIds,
            staffIds,
            serviceTypes,
            statusTypes,
            modeTypes,
          ]}
          close={() => {
            setOpenFilter(false);
            setFilterMode(false);
          }}
        />
      ) : (
        <div></div>
      )}
    </Fragment>
  );
};

export default ResourceWorkbenchHandler;
