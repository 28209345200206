import noWhiteSpace from "../../FormValidation/noWhiteSpace"
import notEmpty from "../../FormValidation/notEmpty"
import { createList, createAttendee, withLoading } from '../../utils'
import React, { useEffect, useState } from "react"
import { Typography, Container, Button } from "@material-ui/core"
import CModal from "../Form/CModal"
import Form from "../Form/Form"
import { generateAttendeeFormFields, generateFilteredPayload } from "../Account/AccountOptions/Attendees/attendeeFormFields"



const processAttendeeData = async (attendeeResponse) => {
    if (attendeeResponse["using"] === "old") {
        return (
            {
                createSuccess: true,
                processedAttendeeData: {
                    attendeeId: attendeeResponse.attendeeId,
                    ...attendeeResponse
                }
            }
        )
    }
    else {
        const filteredPayload = generateFilteredPayload(attendeeResponse)
        const createAttendeeResponse = await withLoading(createAttendee, filteredPayload)
        if (createAttendeeResponse && !createAttendeeResponse.error) {
            return (
                {
                    createSuccess: true,
                    processedAttendeeData: {
                        attendeeId: createAttendeeResponse.attendeeId,
                        ...attendeeResponse
                    }
                }

            )
        }
        else {
            return {
                createSuccess: false,
                processedAttendeeData: null
            }
        }
    }
}



export const processAttendeeList = (attendeeList, addonList, questions) => {
    if (Array.isArray(attendeeList) && Array.isArray(questions)) {
        const temp = createList(attendeeList.length, Object)
        for (const index in attendeeList) {
            const attendeeResponse = attendeeList[index]
            if (attendeeResponse !== null) {
                temp[index] = {
                    attendeeId: attendeeResponse.attendeeId,
                    selectedAddOn: Array.from(addonList[index]),
                    attendeeAnswers: questions.length > 0 ?
                        questions.map(question => ({
                            questionId: question.questionId,
                            answer: attendeeResponse[question.questionId]
                        }))
                        : []
                }
            }
            else {
                temp[index] = undefined
            }
        }
        return temp
    }
    else { return undefined }
}


const updateAttendeeData = async (index, setAttendeeList, newAttendeeData, attendeeList, fetchAttendeeDetailList, setOpen) => {
    const { createSuccess, processedAttendeeData } = await processAttendeeData(newAttendeeData)
    // Stop proccessing attendee data if creating new attendee failed
    if (!createSuccess) {
        return
    }
    const fetchSuccess = await fetchAttendeeDetailList()
    if (createSuccess && fetchSuccess) {
        const temp = [...attendeeList]
        temp[index] = processedAttendeeData
        setAttendeeList(temp)
        setOpen(false)
    }
}

const filterUsedAttendees = (allAttendeeDetails, attendeeList, index) => {
    const usedSet = new Set()
    attendeeList.forEach(attendee => {
        if (attendee?.attendeeId) {
            usedSet.add(attendee.attendeeId)
        }
    })
    return allAttendeeDetails.filter(attendeeDetail => !usedSet.has(attendeeDetail.attendeeId) || attendeeDetail.attendeeId === attendeeList[index]?.attendeeId)
}


const typeMapping = {
    text: { type: "TextField", validator: [noWhiteSpace, notEmpty] },
    singleSelect: { type: "SingleSelect", validator: (value) => (!value ? "Must select a value" : null) },
    multiSelect: { type: "MultiSelect", validator: (value) => (!value ? "Must select a value" : null) },
    date: { type: "Date", validator: (value) => (!value ? "Must select a date" : null) },
    number: { type: "Number" },
    confirm: { type: "CheckBox", validator: value => !value ? "Must be checked" : null },
    "yes/no": { type: "RadioSet", choices: [["Yes", "yes"], ["No", "no"]], validator: value => value === null ? "Must select yes or no" : null },
}
const typeSpecificProps = (type, question) => {
    if (type === "singleSelect" || type === "multiSelect") {
        return ({
            choices: question.options.map(option => [option, option])
        })
    }
    else if (type === "number") {
        return ({
            validator: [
                value => isNaN(value) ? "Must provide a number" : null,
                value => question.maxValue !== null && value > question.maxValue ? `Max value is ${question.maxValue}` : null,
                value => question.minValue !== null && value < question.minValue ? `Min value is ${question.minValue}` : null
            ]
        })
    }
}
const mapQuestionToObject = (questionList, attendeeData) => {
    if (Array.isArray(questionList)) {
        const tempQuestions = createList(questionList.length)
        for (let i = 0; i < questionList.length; i++) {
            let tempQ = {
                ...typeMapping[questionList[i].type],
                label: questionList[i].question,
                name: questionList[i].questionId,
                ...typeSpecificProps(questionList[i].type, questionList[i]),
                initialvalue: attendeeData !== null ? attendeeData[questionList[i].questionId] : undefined
            }
            tempQuestions[i] = tempQ
        }
        return (tempQuestions)
    }
    else {
        return []
    }
}

const generateUsingOldAttendeeFields = (availableAttendeeDetails, attendeeId, usingOldAttendee) => {
    return [
        {
            type: "SingleSelect",
            name: "attendeeId",
            label: "Attendee",
            choices: availableAttendeeDetails.map(attendeeDetail => (
                [`${attendeeDetail.firstName} ${attendeeDetail.lastName} - ${attendeeDetail.attendeeNumber}`, attendeeDetail.attendeeId]
            )),
            validator: notEmpty,
            initialvalue: attendeeId,
            enabled: usingOldAttendee
        }
    ]
}

const generateUsingNewAttendeeFields = (usingOldAttendee) => {
    return generateAttendeeFormFields(undefined, !usingOldAttendee)
}


const AttendeeButton = (props) => {
    const { index, questionList, attendeeList, setAttendeeList, attendeeListDetails, fetchAttendeeDetailList, classes } = props
    const [open, setOpen] = useState(false);
    const availableAttendeeDetails = filterUsedAttendees(attendeeListDetails, attendeeList, index)
    const [usingOldAttendee, setUsingOldAttendee] = useState(true)

    useEffect(() => {
        if (open) {
            setUsingOldAttendee(attendeeList[index]?.attendeeId || availableAttendeeDetails.length > 0)
        }
    }, [open])

    return (
        <React.Fragment>
            <Typography >
                <Button
                    onClick={() => { setOpen(true) }}
                    className={`${attendeeList[index] ? classes.success : classes.primary} ${classes.buttonText}`}
                    variant="outlined"
                >
                    {attendeeList[index] ? "Edit Details" : "Add Details"}
                </Button>
            </Typography>
            <CModal open={open} setOpen={setOpen}>
                <Container className={classes.formContainer}>
                    <Form
                        title="Attendee Details"
                        cancelEnabled
                        cancelAction={() => { setOpen(false) }}
                        action={async payload => { await updateAttendeeData(index, setAttendeeList, payload, attendeeList, fetchAttendeeDetailList, setOpen); }}
                        inputFields={[
                            {
                                type: "RadioSet",
                                name: "using",
                                label: "Using",
                                choices: [
                                    ["Existing Attendee", "old"],
                                    ["New Attendee", "new"]
                                ],
                                initialvalue: usingOldAttendee ? "old" : "new",
                                validator: (value) => { setUsingOldAttendee(value === "old") },
                            },
                            ...generateUsingOldAttendeeFields(availableAttendeeDetails, attendeeList[index]?.attendeeId, usingOldAttendee),
                            ...generateUsingNewAttendeeFields(usingOldAttendee),
                            ...mapQuestionToObject(questionList, attendeeList[index])
                        ]}
                    />
                </Container>
            </CModal>
        </React.Fragment>
    )
}

export default AttendeeButton