import { Container, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import maxTextArea from '../../../../../../FormValidation/maxTextArea';
import maxTextField from '../../../../../../FormValidation/maxTextField';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import { createResource, getGenericDetails, safePush, withLoading } from '../../../../../../utils';
import CModal from '../../../../../Form/CModal';
import Form from '../../../../../Form/Form';
const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100%",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))
const generateInputFields = (fields) => {
    const inputFields = []
    fields.forEach(field => {
        switch (field.type) {
            case "singleSelect":
                inputFields.push({
                    type: "SingleSelect",
                    name: field.fieldId,
                    label: field.fieldName,
                    validator: [(value) => (!value ? "Select an option" : null)],
                    choices: field.options.map(option => ([option, option])),
                    initialvalue: ''
                })
                break;
            case "text":
                inputFields.push({
                    type: "TextArea",
                    name: field.fieldId,
                    label: field.fieldName,
                    validator: [notEmpty, noWhiteSpace, maxTextArea],
                    resizeEnabled: true,
                    initialvalue: ''
                })
                break;
            case "yes/no":
                inputFields.push({
                    type: "RadioSet",
                    name: field.fieldId,
                    label: field.fieldName,
                    choices: [["Yes", "yes"], ["No", "no"]],
                    validator: value => value === null ? "Select yes or no" : null,
                    initialvalue: ''
                })
                break;
            default:
                break;
        };
    })

    return inputFields
}


const CreateResourceForm = (props) => {
    const { open, basePath, setOpen } = props
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const history = useHistory()
    const location = useLocation()
    const classes = useStyles()
    const [genericDetails, setGenericDetails] = useState(null)
    const siteList = genericDetails?.siteDetails ?? []
    const templateList = genericDetails?.templateDetails ?? []
    const [basicPayload, setBasicPayload] = useState(null)
    const [next, setNext] = useState(false)

    const fetchGenericDetails = async () => {
        const genericResponse = await withLoading(getGenericDetails, { businessId })
        if (genericResponse?.genericDetails && !genericResponse.error) {
            setGenericDetails(genericResponse.genericDetails)
        }
    }

    const submitCreateResource = async (secondFormPayload) => {
        const createResponse = await withLoading(createResource, {
            businessId,
            name: basicPayload.name,
            siteId: basicPayload.siteId,
            description: basicPayload.description,
            templateId: basicPayload.template.templateId,
            templateEditTag: basicPayload.template.editTag,
            fields: basicPayload.template.fields.map(field => ({
                fieldId: field.fieldId,
                value: secondFormPayload[field.fieldId]
            }))
        })
        return createResponse
    }

    useEffect(() => {
        if (open) {
            fetchGenericDetails()
        }
        else {
            setBasicPayload(null)
            setNext(false)
        }
    }, [open])



    return (
        <CModal open={open} setOpen={setOpen}>
            <Container component="main" className={classes.formContainer}>
                {!next ?
                    <Form
                        key="firstForm"
                        title="Create Resource"
                        cancelEnabled
                        cancelAction={() => { setOpen(false) }}
                        submitText={"Next"}
                        action={(payload) => {
                            setBasicPayload(payload)
                            setNext(true)
                        }}
                        inputFields={[
                            {
                                type: "TextField",
                                name: "name",
                                label: "Resource Name",
                                validator: [notEmpty, noWhiteSpace, maxTextField],
                                initialvalue: basicPayload?.name ?? ''
                            },
                            {
                                type: "SingleSelect",
                                name: "siteId",
                                label: "Site",
                                validator: [(value) => (!value ? "Select a site" : null)],
                                choices: siteList.map(site => [site.name, site.siteId]),
                                initialvalue: basicPayload?.siteId ?? ''
                            },
                            {
                                type: "SingleSelect",
                                name: "template",
                                label: "Resource Type",
                                validator: [(value) => (!value ? "Select a resource type" : null)],
                                choices: templateList.map(template => [template.name, template]),
                                initialvalue: basicPayload?.template
                            },
                            {
                                type: "TextArea",
                                label: "Description (optional)",
                                name: "description",
                                resizeEnabled: true,
                                validator: [maxTextField],
                                initialvalue: basicPayload?.description
                            }
                        ]}
                    />
                    :
                    <Form
                        key="secondForm"
                        title="Create Resource - Fill Fields"
                        cancelEnabled
                        cancelAction={() => { setOpen(false) }}
                        submitText={"Create"}
                        clearEnabled
                        clearText="Back"
                        clearAction={() => { setNext(false) }}
                        action={submitCreateResource}
                        postAction={(response) => {
                            if (response && response.resourceId) {
                                safePush(`${basePath}/${response.resourceId}`, history, location)
                            }
                        }}
                        inputFields={generateInputFields(basicPayload?.template?.fields ?? [])}
                    />
                }
            </Container>
        </CModal>
    );
};

export default CreateResourceForm