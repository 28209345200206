import { Container } from "@material-ui/core";
import { attachCatalogsToOption } from "../../../../../../utils";
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";

const AddToCatalogForm = (props) => {
  const alreadyUsedCatalogIds = new Set(
    props?.optionDetails?.catalogDetails?.map((catalog) => catalog.catalogId) ??
      []
  );
  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Container className={props.classes.formContainer}>
        <Form
          moreData={{
            businessId: props.businessId,
            optionId: props.optionDetails.optionId,
          }}
          title="Add Pricing Option to Catalogs"
          cancelEnabled
          cancelAction={() => {
            props.setOpen(false);
          }}
          action={attachCatalogsToOption}
          postAction={(res) => {
            if (res?.error === false) {
              props.setOpen(false);
              props.refetch();
            }
          }}
          inputFields={[
            {
              type: "MultiSelect",
              name: "catalogIds",
              label: "Catalogs",
              choices: props.catalogs
                .filter(
                  (catalog) => !alreadyUsedCatalogIds.has(catalog.catalogId)
                )
                .map((catalog) => [catalog.name, catalog.catalogId]),
              validator: (value) =>
                value?.length > 0 ? null : "Please select at least one catalog",
            },
          ]}
        />
      </Container>
    </CModal>
  );
};

export default AddToCatalogForm;
