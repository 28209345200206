
import { makeStyles, Grid, useMediaQuery, Typography } from "@material-ui/core";
import { useState, useEffect, useRef } from "react";
import { getCalendarPrefs, withLoading } from "../../../../../../utils";
import { useSelector } from "react-redux";
import UpdatePreferencesForm, { formatTime } from "./UpdatePreferencesForm";
import TwoColumn from "../../../../../TwoColumn/TwoColumn";
import LightTooltip from "../../../../../Form/LightTooltip";
import { fromArmyTime } from "../../../../../../FormValidation/validTime";

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    topBlock: {
        paddingBottom: "15px",
    },
    mobilePadding: {
        paddingTop: "10px",
        width: "100%",
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    recordRow: {
        paddingLeft: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRoght: "5px",
        width: "550px"
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    rightElem: {
        float: "right",
    },
    rightElemPad: {
        float: "right",
        marginRight: "10px",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeadingOne: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    topHeadingTwo: {
        fontSize: "28px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    topHeadingThree: {
        backgroundColor: "#eb5757",
        opacity: "50%",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    buttonBasic: {
        backgroundColor: "#ffffff",
        color: "#1a1f36",
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    contentColumnFirst: {
        paddingRight: "20px",
        boxShadow: "inset -1px 0 #e3e8ee",
        display: "inline-block",
    },
    contentColumnMiddle: {
        boxShadow: "inset -1px 0 #e3e8ee",
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "inline-block",
    },
    contentColumnLast: {
        paddingLeft: "20px",
        display: "inline-block",
    },
    labelText: {
        fontSize: "14px",
        color: "#697386",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextNeg: {
        fontSize: "14px",
        color: "#009211",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBG: {
        fontSize: "16px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBold: {
        fontSize: "14px",
        color: "#1a1f36",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextPad: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingLeft: "10px",
    },
    valueTextLink: {
        fontSize: "14px",
        color: "#0079fc",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    errorText: {
        fontSize: "14px",
        color: "#ff0000",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    blockLabel: {
        paddingTop: "10px",
        paddingBottom: "5px",
    },
    blockText: {
        paddingLeft: "10px",
    },
    subHeading: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    fieldRows: {
        paddingTop: "8px"
    },
    fieldColumnOne: {
        width: "100px",
        display: "inline-block",
    },
    fieldColumnTwo: {
        display: "inline-block",
    },
    sdCustomCellOne: {
        border: "none",
        width: "10%",
        maxWidth: "200px",
        padding: "10px",
    },
    sdCustomCellTwo: {
        border: "none",
        width: "25%",
        maxWidth: "300px",
        padding: "10px",
    },
    sdCustomCellThree: {
        border: "none",
        width: "10%",
        maxWidth: "200px",
        padding: "10px",
    },
    sdCustomCellFour: {
        border: "none",
        padding: "3px",
    },
    headerPadding: {
        padding: "10px",
    },
    bottomSpacer: {
        height: "50px",
    },
    topMarginBlock: {
        marginTop: "10px",
    },
    loaderWrapper: {
        width: "100%",
        height: "calc(100vh - 64px)"
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    },
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px"
    }
}));



const generateValue = (text, classes, key) => (
    <LightTooltip arrow title={text} key={key}>
        <Typography noWrap className={classes.valueText}>
            {text}
        </Typography>
    </LightTooltip>
)

const generateLabel = (text, classes, key) => (
    <Typography noWrap className={classes.labelText} key={key}>
        {text}
    </Typography>
)

const generateRows = (preferences, classes) => {
    const labels = []
    const values = []

    //Timezone
    labels.push(generateLabel("Time Zone", classes, "label-timeZoneName"))
    values.push(generateValue(preferences.timeZoneName, classes, "value-timeZoneName"))

    //Range slot
    labels.push(generateLabel("Duration", classes, "label-duration"))
    values.push(generateValue(`${preferences.slotDuration.split(":")[1]} min`, classes, "value-duration"))

    //Range slot
    labels.push(generateLabel("Range Type", classes, "label-rangeType"))
    values.push(generateValue(preferences.slotRange, classes, "value-rangeType"))

    if (preferences.slotRange === "Fixed") {
        //Slot start
        labels.push(generateLabel("Slot Start", classes, "label-slotStart"))
        values.push(generateValue(fromArmyTime(formatTime(preferences.slotMinTime)), classes, "value-slotStart"))

        //Slot start
        labels.push(generateLabel("Slot End", classes, "label-slotend"))
        values.push(generateValue(fromArmyTime(formatTime(preferences.slotMaxTime)), classes, "value-slotEnd"))
    }

    return {
        labels,
        values
    }
}


const CalendarPreferences = () => {
    const mounted = useRef(false)
    const classes = useStyles()
    const bigEnough = useMediaQuery('(min-width: 750px)');
    const [preferences, setPreferences] = useState(null)
    const businessId = useSelector(state => state.buisnessDetails.businessId)
    const fetchPreferences = async () => {
        const response = await withLoading(getCalendarPrefs, {
            businessId
        })
        if (!response?.error && response?.dashboardPreferences?.calendar && mounted.current) {
            setPreferences(response.dashboardPreferences.calendar)
        }
    }

    useEffect(() => {
        mounted.current = true
        fetchPreferences()
        return () => mounted.current = false
    }, [])

    if (preferences) {
        const { labels, values } = generateRows(preferences, classes)
        return (
            <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                <Grid container item xs={12} direction="row">
                    <Grid item xs={12}>
                        <div className={classes.topBlock}>
                            <span className={classes.topHeading}>DASHBOARD PREFERENCES</span>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.headingWrapper}>
                        <span
                            className={
                                bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                            }
                        >
                            Calender Preferences
                        </span>
                        <span className={classes.rightElem}>
                            <UpdatePreferencesForm
                                fetchPreferences={fetchPreferences}
                                currentPrefs={preferences}
                            />
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        <TwoColumn
                            extProps={{
                                wrap: 'nowrap',
                                style: {
                                    width: "100%"
                                },
                                spacing: 0
                            }}
                            firstItemProps={{
                                style: {
                                    width: '130px'
                                }
                            }}
                            secondItemProps={{
                                style: {
                                    width: 'calc(100% - 130px)'
                                }
                            }}
                            first={labels}
                            second={values}
                        />
                    </div>
                </Grid>
            </Grid>
        )
    }
    else {
        return null
    }
}

export default CalendarPreferences