import { makeStyles } from "@material-ui/core/styles";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  forwardRef,
} from "react";
import { Link, useParams } from "react-router-dom";

import { Button, Grid, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
// import validPhoneNumber from "../../../../FormValidation/phonenumber";
import CurrencyPreProcessor from "../CurrencyFormatter/CurrencyFormatter";
import CDisplayArea from "../Form/CDisplayArea"; //src/components/CustomerDetails
// import CModal from "../../../../Form/CModal";
// import Form from "../../../../Form/Form";
import LightTooltip from "../Form/LightTooltip";
// import GeneralDialog from "../GeneralDialog/GeneralDialog";
import { CAddIcon, CCloseIcon, CEllipsisIcon } from "../Icons/Icons";
// import ListCard from "../../../../MobileCards/ListCard";
import StatusTag from "../TagComponents/StatusTag";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
  },
  emailText: {
    fontSize: "14px",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#0079fc",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
  },
  fieldColumnOne: {
    width: "100px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  sdCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "25%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "3px",
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
  },
}));

const CustomerDetailsTopBlock = forwardRef((props, ref) => {
  const {
    customerDetails,
    currencyInfo,
    small,
    large,
    setNotes,
    businessId,
    customerIdURL,
  } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      className={large ? classes.mainWrapper : classes.mobilePadding}
      xs={12}
      ref={ref}
    >
      <Grid item xs={customerDetails.isBlocked ? 6 : 12}>
        <div style={{ width: `calc(100%)` }}>
          <LightTooltip
            title={`${customerDetails.firstName} ${customerDetails.lastName}`}
            arrow
          >
            <Typography
              noWrap
              className={
                small ? classes.topHeadingOne : classes.mobileTopHeadingTwo
              }
            >
              {`${customerDetails.firstName} ${customerDetails.lastName}`}
            </Typography>
          </LightTooltip>
        </div>
      </Grid>
      <Grid item xs={6} style={small ? { marginTop: 10 } : { marginTop: 5 }}>
        <div>
          {customerDetails.isBlocked ? (
            <StatusTag wrapperName="redWrapper" value="Blocked" />
          ) : null}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.contentRow}>
          <div className={classes.fieldRows}>
            <div
              className={classes.fieldColumnTwo}
              style={{ maxWidth: "50px" }}
            >
              <Typography className={classes.labelText}>
                {"Phone Number"}
              </Typography>
            </div>
            <div
              className={classes.fieldColumnTwo}
              style={{ paddingLeft: "32px" }}
            >
              <Typography className={classes.valueText}>
                {`${customerDetails.phoneNumber.slice(
                  0,
                  2
                )} (${customerDetails.phoneNumber.slice(
                  2,
                  5
                )}) ${customerDetails.phoneNumber.slice(
                  5,
                  8
                )}-${customerDetails.phoneNumber.slice(8, 12)}`}
              </Typography>
            </div>
          </div>
          <div className={classes.fieldRows}>
            <div
              className={classes.fieldColumnTwo}
              style={{ maxWidth: "50px" }}
            >
              <Typography className={classes.labelText}>{"Email"}</Typography>
            </div>
            <div
              className={classes.fieldColumnTwo}
              style={{ paddingLeft: "40px" }}
            >
              <LightTooltip title="Email Customer" arrow>
                <span className={classes.valueText}>
                  <a
                    style={{ color: "#0079fc" }}
                    href={"mailto:" + customerDetails.email}
                  >
                    <Typography className={classes.emailText}>
                      {customerDetails.email}
                    </Typography>
                  </a>
                </span>
              </LightTooltip>
            </div>
          </div>
          <div
            className={classes.fieldRows}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              className={classes.fieldColumnTwo}
              style={{ maxWidth: "50px" }}
            >
              <Typography className={classes.labelText}>
                {"Available Credit"}
              </Typography>
            </div>
            <div
              className={classes.fieldColumnTwo}
              style={{ paddingLeft: "25px" }}
            >
              <span>
                <CurrencyPreProcessor
                  amount={customerDetails.availableCredit}
                  currencyBreak={currencyInfo.currencyBreak}
                  roundingFactor={currencyInfo.roundingFactor}
                  currencySymbol={currencyInfo.currencySymbol}
                />
              </span>
              <Link
                to={`/dashboard/${businessId}/Customers/${customerIdURL}/Credit`}
                className={classes.valueTextLink}
              >
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{ marginLeft: 10, textTransform: "capitalize" }}
                >
                  View Details
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Grid>
      <Grid
        container
        className={classes.headingWrapper}
        item
        xs={12}
        direction="row"
      >
        <Grid item xs={6}>
          <Typography className={classes.labelText}>Notes</Typography>
        </Grid>
        <Grid item xs={6}>
          <LightTooltip
            title={customerDetails.notes ? "Edit Notes" : "Add Notes"}
            arrow
          >
            {customerDetails.notes ? (
              <span className={classes.rightElem}>
                <Button
                  className={classes.buttonBasic}
                  onClick={() => {
                    setNotes(true);
                  }}
                >
                  <EditIcon />
                </Button>
              </span>
            ) : (
              <span title="Add Notes" className={classes.rightElem}>
                <Button
                  className={classes.buttonBasic}
                  onClick={() => {
                    setNotes(true);
                  }}
                >
                  <CAddIcon />
                </Button>
              </span>
            )}
          </LightTooltip>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.contentRow} style={{ paddingLeft: "10px" }}>
          <span className={classes.valueText}>
            <CDisplayArea
              value={customerDetails.notes ? customerDetails.notes : null}
              initialMaxHeight={"calc(100vh - 360px)"}
            />
          </span>{" "}
        </div>
      </Grid>
    </Grid>
  );
});

export default CustomerDetailsTopBlock;
