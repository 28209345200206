import React from 'react'
import { useSelector } from 'react-redux'
import {Switch, Route, Redirect} from 'react-router-dom'
import Reports from './Reports';
const ReportsHandler = () => {
    const businessId = useSelector(state =>  state.buisnessDetails.businessId);
    const basePath = `/dashboard/${businessId}/Reports`
    return(
        <Switch>
            <Route exact path = {basePath} render = {()=>(<Reports businessId = {businessId}/>)}/>
            <Route  path = "*" render  = {<Redirect to = "404"/>}/>
        </Switch>
    )
}

export default ReportsHandler