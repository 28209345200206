export const aboutUsData = {
    title: 'About Us',
    titleText: `We are in the business of bringing high-quality service professionals to the world, providing a worry-free experience.
    For professional service businesses, we offer a complete solution for efficient business management.
    We help boost revenue while reducing operating costs.`,
    titleTextMore: 'As a technology startup unicorn in the sports and education market, we are expanding to other professional service industries.',
    segments: [
        {
            title: 'Mission',
            description: 'Our mission is simple. We are building a community for service providers, and customers; providing a platform for buying and/or selling services on their own terms.'
        },
        {
            title: 'Values',
            description: 'Thinking out-of-box is in our DNA. Helping people succeed is our passion. These qualities guide us in everyday work to understand all members of the community at the core, and build software solutions for long-term benefits.'
        },
        {
            title: 'People',
            description: 'At Caritra, we are a well-gelled team of passionate experts with deep knowledge and hands-on experience in modern technology, business software, and the industry domain.',
            descriptionTwo: 'We encourage free thinking and creativity. Guided by our mission and values, we work together with a never-give-up attitude to build innovative solutions for our customers, and user community.'
        },
    ],
    team: [
        {
            name: 'Sujit Dalai',
            img: ''
        },
        {
            name: 'Lance Armstrong',
            img: ''
        },
        {
            name: 'Tarun Kumar Jain',
            img: ''
        }
    ]
}