import React, { useState, useEffect } from 'react';
import Form from "../../../../../Form/Form";
import CModal from "../../../../../Form/CModal";
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getGenericDetails, addStaffToOption } from '../../../../../../utils';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))


const AddPricingStaffForm = (props) => {

    const [allStaffs, setAllStaffs] = useState([])
    const dispatch = useDispatch()
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });

    const generateChoices = (allOptions) => {
        const temp = []
        const currentChosen = new Set()
        props.optionDetails.staffs.forEach((staff) => {
            currentChosen.add(staff.staffId)
        })
        allOptions.forEach((staff) => {
            if (!currentChosen.has(staff.staffId) && staff.isActive) {
                temp.push([`${staff.firstName} ${staff.lastName}`, staff.staffId])
            }
        })
        return (temp)
    }

    const setUp = async () => {
        dispatch({
            type: "UPDATE",
            payload: {
                loading: true,
            },
        });
        let data = await getGenericDetails({
            businessId: businessId
        })
        dispatch({
            type: "UPDATE",
            payload: {
                loading: false,
            },
        });
        if (data) {
            setAllStaffs(data.genericDetails.staffDetails)
        }
    }

    useEffect(() => {
        if (props.open) {
            setAllStaffs([])
            setUp()
        }
    }, [props.open])

    const classes = useStyles()
    const refresh = (success) => {
        if (success) {
            props.setOpen(false)
            props.refresh()
        }
    }
    if (allStaffs !== null) {
        return (
            <CModal open={props.open} setOpen={props.setOpen}>
                <Container component="main" className={classes.formContainer}>
                    <Form
                        moreData={{ businessId: businessId, optionId: props.optionDetails.optionId }}
                        title="Add Staffs"
                        cancelEnabled
                        cancelAction={() => { props.setOpen(false) }}
                        submitText="Add"
                        action={addStaffToOption}
                        postAction={refresh}
                        inputFields={[
                            {
                                type: "MultiSelect",
                                name: "staffIds",
                                label: "Staffs",
                                choices: generateChoices(allStaffs)
                            }

                        ]}
                    />
                </Container>
            </CModal>
        )
    }
    else { return (null) }
}

export default AddPricingStaffForm