import React from 'react';
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Order from "./Order";
import OrderList from "./OrderList";

function OrdersHandler() {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    return(
        <React.Fragment>
            <Switch>
            <Route path = {`/dashboard/${businessId}/Orders`} exact render = {() => {return(<OrderList/>)}} />
            <Route path = {`/dashboard/${businessId}/Orders/:orderIdURL`} render = {() => {return(<Order/>);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default OrdersHandler;