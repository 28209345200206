import React from 'react';
import { useSelector} from 'react-redux';
import Form from '../../../../../Form/Form'
import CModal from '../../../../../Form/CModal'
import { Container } from '@material-ui/core';
import { makeStyles} from '@material-ui/core';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import { editPrivateWebConfInfo } from '../../../../../../utils';
import { staffEditOnlineConferenceInfo} from '../../../../../../utils';

const useStyles = makeStyles((theme)=>({
    formContainer:{
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))

const WebConfForm = (props) => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()

    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main"  className = {classes.formContainer}>
                <Form
                    moreData = {{
                        businessId:businessId,
                        sessionId: props.sessionDetails.sessionId
                    }}
                    title="Edit Web Conference Info"
                    cancelEnabled
                    cancelAction = {()=>{props.setOpen(false)}}
                    submitText="Edit"
                    action={props.role === "Admin" ? editPrivateWebConfInfo : props.role==="Staff" ? staffEditOnlineConferenceInfo : null}
                    postAction = {(data)=>{
                        if(data){
                            props.setOpen(false)
                            props.refresh()
                        }
                    }}
                    inputFields={[
                        {
                            type:"TextArea",
                            initialvalue : (props.sessionDetails.onlineConferenceInfo?props.sessionDetails.onlineConferenceInfo:""),
                            name:"onlineConferenceInfo",
                            label: "Web Conference Info",
                            validator: noWhiteSpace,
                            resizeEnabled:true,
                            moreProps:{
                                rowsMin: 4
                            }
                        }
                    ]}
                />
            </Container>
        </CModal>
    );
};

export default WebConfForm