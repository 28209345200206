import { useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  makeStyles,
  useMediaQuery,
  Typography,
  IconButton,
  Container,
} from "@material-ui/core";
import { useEffect } from "react";
import {
  withLoading,
  businessCustomerCreditDetails,
  safePush,
  creditDebitTransaction,
  editAdditionalInfo,
} from "../../../../../../utils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import CurrencyPreProcessor from "../../../../../CurrencyFormatter/CurrencyFormatter";
import LightTooltip from "../../../../../Form/LightTooltip";
import ListTable from "../../../../../TableComponents/ListTable";
import Moment from "react-moment";
import Form from "../../../../../Form/Form";
import CModal from "../../../../../Form/CModal";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import notNull from "../../../../../../FormValidation/notNull";
import noWhiteSpace from "../../../../../../FormValidation/noWhiteSpace";
const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px",
  },
  headingWrapper: {
    paddingBottom: "10px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
    paddingLeft: "10px",
  },
  fieldColumnOne: {
    width: "100px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  sdCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "25%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  media: {
    height: 200,
    maxWidth: 200,
    objectFit: "cover",
  },
  avatar: {
    height: theme.spacing(20),
    width: theme.spacing(20),
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
  },
  valueCredit: {
    color: "green",
    fontWeight: "bold",
  },
  valueDebit: {
    color: "red",
    fontWeight: "bold",
  },
}));

const creditHeaders = [
  {
    name: "Amount",
    columnKey: "amount",
    columnWidth: "14%",
  },
  {
    name: "Type",
    columnKey: "type",
    columnWidth: "16%",
  },
  {
    name: "Transaction Date",
    columnKey: "createdAt",
    columnWidth: "16%",
  },
  {
    name: "Credit Restrictions",
    columnKey: "applicableToServiceType",
    columnWidth: "16%",
  },
  {
    name: "Order#",
    columnKey: "orderNumber",
    columnWidth: "16%",
  },
  {
    name: "Note",
    columnKey: "additionalInfo",
    columnWidth: "16%",
  },
  {
    name: "",
    columnKey: "button",
    columnWidth: "6%",
  },
];

const creditHeadersM = [
  {
    name: "Amount",
    columnKey: "amount",
    columnWidth: "30%",
  },
  {
    name: "Order Number",
    columnKey: "orderNumber",
    columnWidth: "30%",
  },
  {
    name: "Type",
    columnKey: "type",
    columnWidth: "40%",
  },
];

const getProcessedCurrency = (val, currencyInfo) => {
  return (
    <CurrencyPreProcessor
      amount={val}
      currencyBreak={currencyInfo.currencyBreak}
      roundingFactor={currencyInfo.roundingFactor}
      currencySymbol={currencyInfo.currencySymbol}
    />
  );
};

const getProcessedCurrencySign = (val, currencyInfo, type) => {
  if (type == "Credit") {
    return (
      <div style={{ color: "green" }}>
        {" "}
        <CurrencyPreProcessor
          amount={val}
          currencyBreak={currencyInfo.currencyBreak}
          roundingFactor={currencyInfo.roundingFactor}
          currencySymbol={currencyInfo.currencySymbol}
        />
      </div>
    );
  } else {
    return (
      <div style={{ color: "red" }}>
        {" "}
        <CurrencyPreProcessor
          amount={val}
          currencyBreak={currencyInfo.currencyBreak}
          roundingFactor={currencyInfo.roundingFactor}
          currencySymbol={currencyInfo.currencySymbol}
        />
      </div>
    );
  }
};

const getTransactionProcessed = (transaction, currencyInfo) => {
  let applicableToServiceType = "";

  if (!transaction.applicableToServiceType) {
    applicableToServiceType = "NA";
  } else if (transaction.applicableToServiceType == "All") {
    applicableToServiceType = "No restriction";
  } else if (transaction.applicableToServiceType == "Private") {
    applicableToServiceType = "Private service only";
  } else if ((transaction.applicableToServiceType = "Group")) {
    applicableToServiceType = "Group service only";
  }
  return {
    id: transaction.creditId,
    orderId: transaction.orderId,
    amount: getProcessedCurrencySign(
      transaction.amount,
      currencyInfo,
      transaction.type
    ),
    orderNumber: transaction.orderNumber,
    type: transaction.type,
    applicableToServiceType,
    createdAt: <Moment format="lll">{transaction.createdAt}</Moment>,
    additionalInfo: transaction.additionalInfo,
  };
};

const getCustomerCreditDetails = async (
  businessId,
  currencyInfo,
  customerId,
  updateCustomerDetails,
  updateCreditTransactions
) => {
  const detailsResponse = await withLoading(businessCustomerCreditDetails, {
    businessId,
    customerId,
  });
  if (detailsResponse && !detailsResponse.error) {
    let processedTransactions = new Array();

    for (
      let i = 0;
      i < detailsResponse.customerCreditDetails.creditTransactions.length;
      i++
    ) {
      processedTransactions.push(
        getTransactionProcessed(
          detailsResponse.customerCreditDetails.creditTransactions[i],
          currencyInfo
        )
      );
    }

    updateCreditTransactions(processedTransactions);
    updateCustomerDetails({
      customerName: `${detailsResponse.customerCreditDetails.customerFirstName} ${detailsResponse.customerCreditDetails.customerLastName}`,
      customerPhoneNumber:
        detailsResponse.customerCreditDetails.customerPhoneNumber,
      customerEmail: detailsResponse.customerCreditDetails.customerEmail,
      availableCredit: getProcessedCurrency(
        detailsResponse.customerCreditDetails.availableCredit,
        currencyInfo
      ),
    });
  }
};

const CustomerCredits = (props) => {
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const operatingInfo = useSelector((state) => {
    return state.buisnessDetails.operatingUnitInfo;
  });
  const dispatch = useDispatch();
  const { customerIdURL } = useParams();
  const [customerDetails, setCustomerDetails] = useState(null);
  const [creditTransactions, setCreditTransactions] = useState([]);
  const [addCredDebt, setAddCredDebt] = useState(false);
  const [credOrDebt, setCredOrDebt] = useState("Credit");
  const [editNote, setEditNote] = useState(false);
  const [creditId, setCreditId] = useState("");
  const [additionalInfoCredit, setAdditionalInfoCredit] = useState("");
  const [_, updateState] = useState(0);

  const classes = useStyles();
  const bigEnough = useMediaQuery("(min-width:750px )");
  const large = useMediaQuery("(min-width: 960px)");
  const small = useMediaQuery("(min-width: 750px)");
  const history = useHistory();
  const location = useLocation();
  const { basePath } = props;
  const currencyInfo = {
    currencyBreak: operatingInfo.currencyBreak,
    roundingFactor: operatingInfo.roundingFactor,
    currencySymbol: operatingInfo.currencySymbol,
  };
  const inputFields =
    credOrDebt == "Debit"
      ? [
          {
            type: "RadioSet",
            initialvalue: "Credit",
            label: "Type",
            name: "type",
            choices: [
              ["Credit", "Credit"],
              ["Debit", "Debit"],
            ],
            validator: [
              (value) => {
                if (value == null) return "Must select type";
                else {
                  setCredOrDebt(value);
                  return null;
                }
              },
            ],
          },
          {
            type: "Currency",
            validator: [
              notNull,
              notEmpty,
              (value) =>
                value / 100 > customerDetails.availableCredit.props.amount / 100
                  ? "Debit cannot exceed $" +
                    customerDetails.availableCredit.props.amount / 100
                  : null,
              (value) =>
                value > 500000 ? "Debit cannot exceed $5,000.00" : null,
            ],
            initialvalue: "",
            label: "Amount",
            name: "amount",
            currencyInfo: currencyInfo,
          },
          {
            type: "TextArea",
            label: "Note",
            name: "note",
            initialvalue: "",
          },
        ]
      : [
          {
            type: "RadioSet",
            initialvalue: "Credit",
            label: "Type",
            name: "type",
            choices: [
              ["Credit", "Credit"],
              ["Debit", "Debit"],
            ],
            validator: [
              (value) => {
                if (value == null) return "Must select type";
                else {
                  setCredOrDebt(value);
                  return null;
                }
              },
            ],
          },
          {
            type: "Currency",
            validator: [
              notNull,
              notEmpty,
              (value) =>
                value / 100 +
                  customerDetails.availableCredit.props.amount / 100 >
                5000
                  ? "Credit cannot exceed $" +
                    (500000 - customerDetails.availableCredit.props.amount) /
                      100
                  : null,
              (value) =>
                value / 100 + customerDetails.availableCredit.amount / 100 < 1
                  ? "Credit cannot be less than $1.00"
                  : null,
            ],
            initialvalue: "",
            label: "Amount",
            name: "amount",
            currencyInfo: currencyInfo,
          },
          {
            type: "SingleSelect",
            initialvalue: "All",
            label: "Credit Restrictions",
            name: "applicableToServiceType",
            choices: [
              ["All Services", "All"],
              ["Only Private Services", "Private"],
              ["Only Group Services", "Group"],
            ],
          },
          {
            type: "TextArea",
            label: "Note",
            name: "note",
            initialvalue: "",
          },
        ];

  useEffect(() => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    getCustomerCreditDetails(
      businessId,
      currencyInfo,
      customerIdURL,
      setCustomerDetails,
      setCreditTransactions
    );
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
  }, [_]);

  const handleAddCredDebt = async (info) => {
    let success;
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    success = await creditDebitTransaction({
      businessId: businessId,
      customerId: customerIdURL,
      amount: info.amount,
      type: info.type,
      applicableToServiceType:
        info.type == "Debit" ? "NA" : info.applicableToServiceType,
      additionalInfo: info.note ? info.note : null,
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (success) {
      setAddCredDebt(false);
      updateState((_) => !_);
    }
  };

  const handleEditClick = (id, additionalInfo) => {
    setCreditId(id);
    setAdditionalInfoCredit(additionalInfo);
    setEditNote(true);
  };

  const handleSubmit = (event) => {
    handleAddCredDebt(event);
  };

  const getTransactionDetails = (id) => {
    const transaction = creditTransactions.find((tran) => {
      return tran.id === id;
    });
    transaction.orderId
      ? safePush(
          `/dashboard/${businessId}/Orders/${transaction.orderId}`,
          history,
          location
        )
      : handleEditClick(id, transaction.additionalInfo);
  }

  const handleEditSubmit = async (event) => {
    let success;
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    success = await editAdditionalInfo({
      businessId: businessId,
      creditId: creditId,
      additionalInfo: event.additionalInfo
    });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (success) {
      setEditNote(false);
      updateState((_) => !_);
    }
  }

  
  if (customerDetails) {
    return (
      <>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disableElevation
          onClick={() => {
            history.goBack();
          }}
          style={{ marginLeft: 30 }}
        >
          <ArrowBackIcon />
        </Button>
        <Grid
          container
          className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
        >
          <CModal
            open={addCredDebt}
            setOpen={setAddCredDebt}
            children={
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={handleSubmit}
                  postAction={null}
                  title="Add Credit / Debit"
                  cancelEnabled
                  cancelAction={() => {
                    setAddCredDebt(false);
                    setCredOrDebt("Credit");
                  }}
                  cancelText="Cancel"
                  submitText="Add"
                  inputFields={inputFields}
                ></Form>
              </Container>
            }
          />
          <CModal
            open={editNote}
            setOpen={setEditNote}
            children={
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={handleEditSubmit}
                  postAction={null}
                  title="Edit Note"
                  cancelEnabled
                  cancelAction={() => {
                    setEditNote(false);
                  }}
                  cancelText="Cancel"
                  submitText="Edit"
                  inputFields={[
                    {
                      type: "TextArea",
                      initialvalue: additionalInfoCredit,
                      label: "Note",
                      name: "additionalInfo",
                    },
                  ]}
                ></Form>
              </Container>
            }
          />
          <Grid item xs={12}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>CREDIT</span>
            </div>
          </Grid>

          <Grid container item xs={12} direction="row">
            <Grid item xs={12}>
              <div
                className={classes.headerPadding}
                style={{ width: `calc(100%)`, paddingLeft: "0px" }}
              >
                <LightTooltip title={customerDetails.customerName} arrow>
                  <Typography
                    noWrap
                    className={
                      small
                        ? classes.topHeadingOne
                        : classes.mobileTopHeadingOne
                    }
                  >
                    {customerDetails.customerName}
                  </Typography>
                </LightTooltip>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            spacing={2}
            style={
              large
                ? {
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    paddingLeft: "15px",
                  }
                : { paddingTop: "25px" }
            }
            direction={small ? "row" : "column"}
          >
            <Grid
              item
              style={
                large
                  ? { paddingLeft: "0px" }
                  : { display: "flex", justifyContent: "center" }
              }
            ></Grid>
            <Grid item style={large ? null : { paddingLeft: "0px" }}>
              <div className={classes.contentRow}>
                <div className={classes.fieldRows}>
                  <div
                    className={classes.fieldColumnOne}
                    style={{ maxWidth: "50px", paddingBottom: "5px" }}
                  >
                    <span
                      className={classes.labelText}
                      style={{ whiteSpace: "normal" }}
                    >
                      Phone Number
                    </span>{" "}
                  </div>
                  <div
                    className={classes.fieldColumnTwo}
                    style={{ paddingLeft: "15px" }}
                  >
                    <span className={classes.valueText}>
                      {`${customerDetails.customerPhoneNumber.slice(
                        0,
                        2
                      )} (${customerDetails.customerPhoneNumber.slice(
                        2,
                        5
                      )}) ${customerDetails.customerPhoneNumber.slice(
                        5,
                        8
                      )}-${customerDetails.customerPhoneNumber.slice(8, 12)}`}
                    </span>
                  </div>
                </div>
                <div className={classes.fieldRows}>
                  <div
                    className={classes.fieldColumnOne}
                    style={{ maxWidth: "50px", paddingBottom: "5px" }}
                  >
                    <span
                      className={classes.labelText}
                      style={{ whiteSpace: "normal" }}
                    >
                      Email
                    </span>{" "}
                  </div>
                  <div
                    className={classes.fieldColumnTwo}
                    style={{ paddingLeft: "15px" }}
                  >
                    <LightTooltip title="Email Customer" arrow>
                      <span className={classes.valueText}>
                        <a
                          style={{ color: "#0079fc" }}
                          href={"mailto:" + customerDetails.customerEmail}
                        >
                          {customerDetails.customerEmail}
                        </a>
                      </span>
                    </LightTooltip>
                  </div>
                </div>
                <div className={classes.fieldRows}>
                  <div
                    className={classes.fieldColumnOne}
                    style={{ maxWidth: "50px", paddingBottom: "5px" }}
                  >
                    <span
                      className={classes.labelText}
                      style={{ whiteSpace: "normal" }}
                    >
                      Available Credit
                    </span>{" "}
                  </div>
                  <div
                    className={classes.fieldColumnTwo}
                    style={{ paddingLeft: "15px" }}
                  >
                    <span className={classes.valueText}>
                      {customerDetails.availableCredit}
                    </span>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ paddingTop: !bigEnough ? "30px" : "0px" }}
          >
            <div
              className={classes.headingWrapper}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                noWrap
                className={
                  bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo
                }
              >
                Credit Transactions
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "capitalize" }}
                onClick={() => {
                  setAddCredDebt(true);
                }}
              >
                Add Credit / Debit
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <ListTable
                identifier={"shared"}
                tableHeaders={bigEnough ? creditHeaders : creditHeadersM}
                rowClick={(creditId) => {
                  getTransactionDetails(creditId);
                }}
                tableRows={creditTransactions.map((transaction) => ({
                  orderId: transaction.orderId,
                  id: transaction.id,
                  amount: transaction.amount,
                  type: transaction.type,
                  orderNumber: transaction.orderNumber
                    ? transaction.orderNumber
                    : "NA",
                  applicableToServiceType: transaction.applicableToServiceType,
                  createdAt: transaction.createdAt,
                  additionalInfo: transaction.additionalInfo,
                  button: !transaction.orderNumber ? (
                    <IconButton
                      onClick={() => {
                        handleEditClick(
                          transaction.id,
                          transaction.additionalInfo
                        );
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  ) : null,
                }))}
              />
            </div>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return null;
  }
};
export default CustomerCredits;
