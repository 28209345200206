import React, { useState, useEffect } from 'react';
import { Grid, useMediaQuery, Toolbar, Button, Typography, makeStyles } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from 'moment'
import "./CSpecialDate.css"

const useStyles = makeStyles(theme => ({
    toolbarMobile: {
        backgroundColor: theme.palette.primary.main,
        padding: 12
    },
    toolbarTextMobile: {
        color: theme.palette.white.main,
        textTransform: "none",
        fontSize: "1.825rem"
    },
}))

const CutomToolbar = (props) => {
    const classes = useStyles();
    return (
        <Toolbar className={classes.toolbarMobile}>
            <Button>
                <Typography className={classes.toolbarTextMobile}>
                    {props.date.format("ddd, MMM D")}
                </Typography>
            </Button>
        </Toolbar>
    )
}

const CSpecialDate = (props) => {
    const bigEnough = useMediaQuery('(min-width: 750px)')
    const [date, setDate] = useState(null)
    useEffect(() => {
        if (props.initialvalue && !date) {
            setDate(moment.utc(props.initialvalue))
            props.updateGlob(moment.utc(props.initialvalue))
            props.onchange({ event: { target: null } })
        }
        else if(!date){
            setDate(moment())
            props.updateGlob(moment())
            props.onchange({ event: { target: null } })
        }
    }, [props.initialvalue])

    const containerSettings = (!date ? { container: true, justifyContent: "center" } : {})
    return (
        <Grid {...containerSettings}>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
                <DatePicker
                    ToolbarComponent={CutomToolbar}
                    views={["date"]}
                    orientation="portrait"
                    variant="static"
                    openTo="date"
                    disablePast={true}
                    maxDate={(props.endvalue ? moment.utc(props.endvalue.utcDate) : undefined)}
                    value={date}
                    onChange={(value) => {
                        setDate(value)
                        props.updateGlob(value)
                        props.onchange({ event: { target: null } })
                    }}
                    {...props.moreProps}
                />
            </MuiPickersUtilsProvider>
        </Grid>
    );
}

export default CSpecialDate;