import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import StaffSchedule from './StaffSchedule';
import StaffScheduleList from './StaffScheduleList';

const StaffScheduleHandler = () => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const basePath = `/dashboard/${businessId}/My-Schedule`;
    return(
        <Switch>
            <Route path = {basePath}  exact render = {()=>{return(<StaffScheduleList businessId = {businessId}/>);}}/>
            <Route path = {`${basePath}/:calendarId`}  exact render = {()=>{return(<StaffSchedule businessId = {businessId} />)}}/>
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
        </Switch>
    );
}

export default StaffScheduleHandler;