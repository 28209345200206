import { Button, Checkbox, Container, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { addClassResource, addSessionResource, getGenericDetails, removeClassResource, removeSessionResource, safePush, withLoading } from '../../utils'
import CModal from '../Form/CModal'
import Form from '../Form/Form'
import GeneralDialog from '../GeneralDialog/GeneralDialog'
import { CAddIcon, CCloseIcon } from '../Icons/Icons'
import ListTable from '../TableComponents/ListTable'

const withAdmin = [
    {
        name: "",
        columnKey: "action",
        columnWidth: "100px",
    },
]

const resouceHeader = [
    {
        name: "Resource Name",
        columnKey: "name",
        columnWidth: "100%",
    },
]


const AddResourceForm = (props) => {
    const { classes, fetchDetails, resourceDetails, eventId, eventType, siteId, businessId } = props
    const [open, setOpen] = useState(false)
    const [availableResources, setAvailableResources] = useState([])

    const fetchAllResources = async () => {
        const genericResponse = await withLoading(getGenericDetails, {
            businessId
        })
        if (!genericResponse?.error && genericResponse?.genericDetails?.resourceDetails) {
            const usedResources = new Set((resourceDetails ?? []).map(resource => resource.resourceId))
            setAvailableResources(genericResponse.genericDetails.resourceDetails.filter(resource =>
                !usedResources.has(resource.resourceId) &&
                resource.isActive &&
                resource.siteId === siteId
            ))
        }
    }

    useEffect(() => {
        if (open) {
            fetchAllResources()
        }
        else {
            setAvailableResources([])
        }
    }, [open])

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={() => setOpen(true)}>
                <CAddIcon />
            </Button>
            <CModal open={open} setOpen={setOpen}>
                <Container component="main" className={classes.formContainer}>
                    <Form
                        moreData={{}}
                        title="Add Resources"
                        cancelEnabled
                        cancelAction={() => {
                            setOpen(false)
                        }}
                        submitText="Add"
                        action={async (payload) => {
                            if (eventType === 'Session') {
                                return await addSessionResource({
                                    businessId,
                                    sessionId: eventId,
                                    resourceIds: payload.resourceIds
                                })
                            }
                            else {
                                return await addClassResource({
                                    businessId,
                                    classId: eventId,
                                    resourceIds: payload.resourceIds
                                })
                            }
                        }}
                        postAction={(response) => {
                            if (response?.error === false) {
                                setOpen(false)
                                fetchDetails()
                            }
                        }}
                        inputFields={[
                            {
                                type: "MultiSelect",
                                label: "Resource",
                                choices: availableResources.map(resource => [`${resource.name}${resource.description ? ' - ' + resource.description : ""}`, resource.resourceId]),
                                validator: value => value ? null : "Must select at least one resource",
                                name: "resourceIds"
                            },
                        ]}
                    />
                </Container>
            </CModal>
        </React.Fragment>
    )
}

const RemoveForm = (props) => {
    const { setDel, del, fetchDetails, eventType, eventId, businessId } = props
    const [open, setOpen] = useState(false)

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={() => setOpen(true)}>
                <CCloseIcon />
            </Button>
            <GeneralDialog
                open={open}
                close={setOpen}
                title="Remove Resources"
                text="Are you sure you want to delete the checked resource(s)?"
                action={async () => {
                    const deleteResponse = await withLoading(eventType === "Session" ? removeSessionResource : removeClassResource, {
                        businessId,
                        sessionId: eventType === "Session" ? eventId : undefined,
                        classId: eventType !== "Session" ? eventId : undefined,
                        resourceIds: Array.from(del)
                    })
                    if (!deleteResponse?.error) {
                        setOpen(false)
                        setDel(new Set())
                        fetchDetails()
                    }
                }}
            />
        </React.Fragment>
    )
}


const ResourceTable = (props) => {
    const { classes, resourceDetails, bigEnough, admin, eventId, eventType, fetchDetails, siteId, businessId } = props
    const [del, setDel] = useState(new Set())
    const history = useHistory()
    const location = useLocation()

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                    <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Resources Used</span>
                    {
                        admin ?
                            <div className={classes.rightElem}>
                                {del.size > 0 ?
                                    <RemoveForm
                                        del={del}
                                        setDel={setDel}
                                        fetchDetails={fetchDetails}
                                        eventId={eventId}
                                        eventType={eventType}
                                        businessId={businessId}
                                    />
                                    :
                                    <AddResourceForm
                                        classes={classes}
                                        fetchDetails={fetchDetails}
                                        resourceDetails={resourceDetails}
                                        eventId={eventId}
                                        eventType={eventType}
                                        siteId={siteId}
                                        businessId={businessId}
                                    />
                                }

                            </div>
                            : null
                    }
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.contentRow}>
                    {
                        resourceDetails.length > 0
                            ?
                            <ListTable
                                styling={{ maxHeight: 400, width: "100%" }}
                                rowClick={(admin ? (id) => {
                                    safePush(`/dashboard/${businessId}/Resource/${id}`, history, location)
                                } : undefined)}
                                identifier={"resources"}
                                tableHeaders={admin ? [...withAdmin, ...resouceHeader] : resouceHeader}
                                tableRows={resourceDetails.map(resource => ({
                                    action: (admin ? (
                                        <Checkbox
                                            onClick={(event) => {
                                                event.stopPropagation()
                                                if (del.has(resource.resourceId)) {
                                                    del.delete(resource.resourceId)
                                                }
                                                else {
                                                    del.add(resource.resourceId)
                                                }
                                                setDel(new Set(del))
                                            }}
                                            checked={del.has(resource.resourceId)}
                                        />
                                    )
                                        :
                                        undefined),
                                    id: resource.resourceId,
                                    name: resource.name,
                                }))}
                            />
                            :
                            <span className={classes.valueText}>
                                No resources are used.
                            </span>
                    }
                </div>
            </Grid>
        </React.Fragment>
    )
}


export default ResourceTable