import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
// import UpcomingList from './UpcomingList';
// import Upcoming from './Upcoming'
import Schedule from './Schedule';
import ScheduleList from './ScheduleList';
function ScheduleHandler() {
    const basePath = '/account/Schedule'
    return(
        <React.Fragment>
            <Switch>
            <Route path = {basePath} exact render = {() => {return(<ScheduleList basePath = {basePath}/>)}} />
            <Route path = {`${basePath}/:sessionIdURL`} exact render = {() => {return(<Schedule basePath = {basePath}/>)}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default ScheduleHandler;