import { SvgIcon, makeStyles } from "@material-ui/core";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';


export const faq = [
    {
        q: "How do I receive payments?",
        a: "Every week on Sunday, Caritra makes a direct deposit of the prior week earnings to professional’s bank account.",
    },
    {
        q: "Is my personal/business information secure?",
        a: "Yes. We maintain enterprise-grade information security for all our data.",
    },
    {
        q: "Do I have to pay for the customers that sign up for our services on Caritra?",
        a: "It’s free. We charge you when appointments are booked. See the pricing detail.",
    },
    {
        q: "Can I define multiple prices for my services and are those prices private?",
        a: "Yes. You will have a public price and can have separate private prices for each of your regular customers.",
    },
    {
        q: "Are there discounts for large volume accounts?",
        a: "Yes, we do. Please contact sales@caritra.com for more information.",
    },
    {
        q: "Can I have my own cancellation and reschedule policies?",
        a: "Yes. You can define your personalized policies.",
    },
];

export const testimonials = [
    {
        name: "Scott K",
        description:
            "Caritra is an excellent site. It is very reliable and easy to book a tennis lesson. I do rescheduling and cancelling online and receive text reminder. All information is online and transparent.",
        img: "https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/testimonial-1.jpg",
    },
    {
        name: "Doug V",
        description:
            "My coach is using Caritra nowadays. Everything has become a very professional and great experience. I have set up recurring agreement for my son's weekly tennis training. Appointment is scheduled automatically. ",
        img: "https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/testimonial-2.jpg",
    },
    {
        name: "Mario R",
        description:
            "All information is online and terms and conditions are very clear. Refunds are processed immediately. My money is very secured. I can book, cancel and reschedule any time. It's easy.",
        img: "https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/testimonial-3.jpg",
    },
    {
        name: "Utsab R",
        description:
            "We used Caritra for Eagle Fustar's Fremont site. As the director, my job became very easy. It freed up two hours daily.  Everything got well organized and looked professional. I had every information at a glance and complete control of everything.",
        img: "https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/testimonial-4.png",
    },
    {
        name: "Nemanja S",
        description:
            "I booked all my private sessions in Caritra. We implemented cancellation and reschedule policies properly. As a result, the customer couldn't make unnecessary cancellations.  Every month I made an additional $500 as a result of this. ",
        img: "https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/testimonial-5.png",
    },
    {
        name: "Jakub C",
        description:
            "I was evaluating Caritra when I was director at Eagle Fustar Tennis Academy.  It is exactly how I expected to be for academies and professional. It is built to drastically offload administrative work and promote coaches. It is highly recommended.",
        img: "https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/testimonial-6.jpg",
    },
];

export const features = [
    {
        img: "https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/marketplacefinal.png",
        title: "Marketplace, claim your spot",
        content: "Grab the immediate attention of tennis enthusiasts in your area, and nationwide.",
        list: [
            "Advertise in Caritra for free",
            "Present your content, and attract more customers",
            "Boost revenue with flexible and attractive pricing packages",
            "Increase brand awareness with prompt, authentic customer reviews"
        ],
        imgFirst: false
    },
    {
        img: "https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/back-office-final.png",
        title: "All-in-one solution for your operations",
        content: "Caritra’s fully integrated business application runs your business, and digitally maintains all activities, saving you time and money.",
        list: [
            "Save time with online booking, scheduling, check-in, and transparent communication",
            "Actionable business calendar - manage activities, courts, and staff, on-the-go from your phone or tablet",
            "Streamline and automate client-messaging, staff payroll, payment processing, and transaction recording",
            "Oversee your business with a 360 degree view of customer information and staff management",
            "Caritra's rule engine gets clients complying with your guidelines and policies, reducing cancellations and refunds"
        ],
        imgFirst: true,
        background: 'grey'
    },
    {
        img: "https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/tailored-for-coaching-final.png",
        title: "Tailored for the coaching business",
        content: `
        Supports the entire range of services a coaching business 
        offers: lessons, clinics, flexible pricing models, 
        customer portal, staff dashboard, and control levels`,
        list: [
            "Retain exceptional coaches by empowering them to promote their services, and manage their pricing and schedule",
            "Offer private / semi-private lessons, clinics, camps, counseling, and add-ons",
            "Delegate appropriate levels of control to coaches, directors, and assistants",
            "Outline & track assignments, and clearly communicate student improvement with parents, who crave feedback"
        ],
        imgFirst: false
    },
    {
        img: "https://caritra-app-images.s3.us-west-1.amazonaws.com/webapp/business-page/customer-support-final.png",
        title: "Custom-fit business solutions",
        content: "We partner with you for the success of your brand.",
        list: [
            "During onboarding, and as your business grows, we custom-build features for your changing needs",
            "24/7 live support to ensure the continuity of your operation",
            "Advisory service for business expansion and transformation",
        ],
        imgFirst: true,
        background: 'grey'
    },
];

const icons = {
    rocket: "m4.9 21.15 8.4-8.4q.7-.7 1.65-1 .95-.3 1.95-.1l3.85.7q-2.75 3.1-4.45 5.875Q14.6 21 13.15 24.7ZM15 25.6q1.35-3.65 3.4-6.875t4.65-5.825q4.4-4.4 10.05-6.575T43.65 5q.85 4.9-1.3 10.55T35.8 25.6q-2.6 2.6-5.85 4.65-3.25 2.05-6.9 3.4Zm14.3-6.25q1 1 2.475 1t2.475-1q1-1 1-2.475t-1-2.475q-1-1-2.475-1t-2.475 1q-1 1-1 2.475t1 2.475Zm-1.75 24.4-3.6-8.25q3.7-1.45 6.475-3.15 2.775-1.7 5.875-4.45l.7 3.85q.2 1-.1 1.975-.3.975-1 1.675ZM8.1 32.1q1.75-1.75 4.25-1.775 2.5-.025 4.25 1.725t1.75 4.25q0 2.5-1.75 4.25-1.3 1.3-4.05 2.15-2.75.85-8.2 1.6.75-5.45 1.6-8.175Q6.8 33.4 8.1 32.1Z",
    trophy: "M314.716,31.716h-28.984C283.685,13.891,268.512,0,250.147,0H113.286C94.921,0,79.748,13.891,77.701,31.716H48.716c-14.912,0-27,12.088-27,27v56c0,14.912,12.088,27,27,27h28.741v16.171c0,12.759,6.864,24.648,17.914,31.028l52.195,30.135v24.745h-4.436c-14.058,0-25.495,11.437-25.495,25.495v41.743H91.618c-14.447,0-26.2,11.753-26.2,26.199c0,14.447,11.753,26.2,26.2,26.2h180.197c14.447,0,26.2-11.753,26.2-26.2c0-14.446-11.753-26.199-26.2-26.199h-26.019v-41.743c0-14.058-11.437-25.495-25.495-25.495h-4.436v-24.745l52.195-30.135c11.05-6.379,17.914-18.269,17.914-31.028v-16.171h28.741c14.912,0,27-12.088,27-27v-56C341.716,43.805,329.628,31.716,314.716,31.716z M51.716,111.716v-50h25.741v50H51.716zM311.716,111.716h-25.741v-50h25.741V111.716z",
    personSearch: 'M10.35 14.01C7.62 13.91 2 15.27 2 18v2h9.54c-2.47-2.76-1.23-5.89-1.19-5.99zm9.08 4.01c.36-.59.57-1.28.57-2.02 0-2.21-1.79-4-4-4s-4 1.79-4 4 1.79 4 4 4c.74 0 1.43-.22 2.02-.57L20.59 22 22 20.59l-2.57-2.57zM16 18c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z',
};

const CreateIcon = (d, props, viewBox, circle) => {
    return (
        <SvgIcon {...props}>
            <svg
                viewBox={viewBox ? viewBox : "0 0 50 50"}
            >
                {circle ? <circle {...circle} /> : null}
                <path d={d} style={{ height: 100, width: 100 }}></path>
            </svg>
        </SvgIcon>
    );
};


const RocketIcon = (props) => CreateIcon(icons.rocket, props);
const TrophyIcon = (props) => CreateIcon(icons.trophy, props, "0 0 363.433 363.433");
export const PersonSearchIcon = (props) => CreateIcon(icons.personSearch, props, "0 0 24 24", {
    cx: '10',
    cy: '8',
    r: "4"
})


export const benefits = [
    {
        title: "Boost Revenue",
        text: "Greater exposure, more bookings, and fewer cancellations",
        icon: <MonetizationOnIcon color="primary" style={{ height: "45px", width: "45px" }} />,
    },
    {
        title: "Thriving Staff",
        text: "Higher earning potential, transparency in compensation, and clarity in communication",
        icon: <TrophyIcon color="primary" style={{ height: "45px", width: "45px" }} />,
    },
    {
        title: "Stay Ahead in the Game",
        text: "Give a polished look to your business and make your brand shine",
        icon: <RocketIcon color="primary" style={{ height: "45px", width: "45px" }} />,
    },
    {
        title: "Raving Customers",
        text: "Reach new level of client retention, leading to a slew of five-star reviews",
        icon: <ThumbUpAltIcon color="primary" style={{ height: "45px", width: "45px" }} />,
    },

];