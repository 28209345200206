import React, { useState, useRef, useEffect } from 'react'
import { TextareaAutosize, Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    textBox: {
        border: "none",
        resize: "none",
        whiteSpace: "word-wrap",
        textAlign: "justify",
        fontSize: 14,
        fontWeight: "normal",
        fontFamily: "'Karla', sans-serif",
        outline: "none",
        marginBottom: 10,
    },
    original:{
        width: 0,
        position: "fixed",
    }
}))


const CDisplayArea = (props) => {
    const classes = useStyles()
    const textArea = useRef(null)
    const original = useRef(null)
    const [TE, setTE] = useState(false)
    const [expand, setExpand] = useState(false)
    useEffect(() => {
        const updateTE = () => {
            if (textArea.current) {
                setTE(textArea.current.scrollHeight>original.current.offsetHeight)
            }
        }
        
        updateTE()
        

    }, [props, textArea.current, original.current])

    useEffect(() =>{
        const updateTE = () => {
            if (textArea.current) {
                setTE(textArea.current.scrollHeight>original.current.offsetHeight)
            }
        }
        window.addEventListener("resize", updateTE);
        return () => window.removeEventListener("resize",updateTE)
    },[])





    return (
        <Grid container align = "center">
            <div style = {{height: props.initialMaxHeight?props.initialMaxHeight:undefined}} className = {classes.original} ref = {original}/>
            <Grid item xs={12}>
                {(props.value!==null?
                    <TextareaAutosize
                    ref={textArea}
                    readOnly
                    style={{ width: "100%", maxHeight: (expand?null:props.initialMaxHeight) }}
                    className={classes.textBox}
                    value={props.value}
                />
                    :<Typography align = "left">
                        <b>Not Provided</b>
                    </Typography>)}
            </Grid>
            <Grid item xs={12}>
                {(!props.te & TE ? (expand?
                <Button disableRipple color =  "primary" startIcon = {<ExpandLessIcon/>} onClick = {()=>{setExpand(false)}}>Show Less</Button>
                : 
                <Button disableRipple color =  "primary"  startIcon = {<ExpandMoreIcon/>} onClick = {()=>{setExpand(true)}}>Show More</Button>
                ): null)}
            </Grid>
        </Grid>
    )
}

export default CDisplayArea