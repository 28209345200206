import React, {useState, useEffect} from "react";
import {makeStyles, useMediaQuery} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import {operatingUnits, withLoading} from "../../../../../../utils";
import {caritraAgreement} from "../../../../../../utils";
import CurrencyPreProcessor from "../../../../../CurrencyFormatter/CurrencyFormatter";
import parse from 'html-react-parser';
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme=>({
    container:{
        maxWidth:"500px",
        minHeight: "calc(100vh - 64px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    topHeading: {
        fontSize: "14px",
        color: "#1a1f36",
      },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
  rightElem: {
    float: "right",
  },
    mobilePadding: {
        margin:"auto",
        maxWidth:"1440px",
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
        alignItems: "center",
        justifyContent: "center"
    },
    textBox: {
        whiteSpace: "normal",
        textAlign: "justify",
        fontSize: 14,
        fontWeight: "normal",
        fontFamily: "'Karla', sans-serif",
        outline: "none",
        marginBottom: 10,
    },
    mainWrapper: {
        margin:"auto",
        maxWidth:"1440px",
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
        alignItems: "center",
        justifyContent: "center"
    },
    headerPadding: {
        padding: "10px",
    },
    topHeadingOne: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    media: {
        height: 200,
        width: 200,
        objectFit: "cover",
        resizeMode:"cover"
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    buttonBasic: {
        backgroundColor: "#ffffff",
        color: "#1a1f36",
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    labelText: {
        fontSize: "14px",
        color: "#697386",
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextLink: {
        fontSize: "14px",
        color: "#0079fc",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    subHeading: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    fieldRows: {
        paddingTop: "8px"
    },
    fieldColumnOne: {
        width: "100px",
        display: "inline-block"
    },
    fieldColumnTwo: {
        display: "inline-block",
    },
    formContainer:{
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding:"20px",
        minWidth:"350px",
        maxWidth: "600px"
    },
    docBG:{
        backgroundColor : "#F7FAFC",
        width : "100%"
    }
  
  }))

const CaritraPricing = () => {
    const [_, updateState] = useState(0);
    const small= useMediaQuery('(min-width: 750px)');
    const large= useMediaQuery('(min-width: 960px)');
    const classes = useStyles();
    const [amountA, setAmountA] = useState(null);
    const [fixedF, setFixedF] = useState(null);
    const [currencyInfo, setCurrencyInfo] = useState();
    const [initialState,setInitialState] = useState(false);
    const [bPP, setBPP] = useState(null);
    const [aT, setAT] = useState(null);
    const businessId = useSelector((state) => {
        return state.buisnessDetails.businessId;
      });

    useEffect(async () => {
        let success = await operatingUnits()
        let data = await withLoading(caritraAgreement, {
          businessId: businessId,
        }, true)
        if(data.businessPricePlan && success) {
            setBPP(data.businessPricePlan);
            setCurrencyInfo(success);
            setAmountA(Math.abs(parseFloat(data.businessPricePlan.disputeFixed/success.currencyBreak)).toFixed(success.roundingFactor));
            setFixedF(Math.abs(parseFloat(data.businessPricePlan.fixedFee/success.currencyBreak)).toFixed(success.roundingFactor));
            setAT(data.businessPricePlan.agreementType.slice(0,1) + data.businessPricePlan.agreementType.slice(1,data.businessPricePlan.agreementType.length).toLowerCase())
            setInitialState(true);
        }
      }, [_]);

    if(initialState && currencyInfo && bPP) {
        return (
            <React.Fragment>
            <Grid container xs={12} spacing={2} className={large ? classes.mainWrapper : classes.mobilePadding}>
                <Grid item xs={12} className={classes.headingWrapper}>
                    <span className={classes.topHeading}>
                        CARITRA PRICING
                    </span>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.headerPadding} style={{paddingLeft:"0px"}}>
                        <span className={small ? classes.topHeadingOne : classes.mobileTopHeadingTwo}>{bPP.agreementName}</span>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.contentRow} style={{paddingBottom:"0px", paddingTop:"0px"}}>
                        <div className={classes.fieldRows}>
                            <div className={classes.fieldColumnOne}>
                                <span className={classes.labelText}>
                                    Agreement Number
                                </span>
                            </div>
                            <div className={classes.fieldColumnTwo}>
                                <span className={classes.valueText}>
                                    {bPP.agreementNumber}
                                </span>
                            </div>
                        </div>
                        <div className={classes.fieldRows}>
                            <div className={classes.fieldColumnOne}>
                                <span className={classes.labelText}>
                                    Agreement Type
                                </span>
                            </div>
                            <div className={classes.fieldColumnTwo}>
                                <span className={classes.valueText}>
                                    {`${aT} Pricing`}
                                </span>
                            </div>
                        </div>
                        <div className={classes.fieldRows}>
                            <div className={classes.fieldColumnOne}>
                                <span className={classes.labelText}>
                                    Transaction Fee
                                </span>
                            </div>
                            {bPP.fixedFee ? 
                            <div className={classes.fieldColumnTwo}>
                            <span className={classes.valueText}>
                                {`${bPP.commission}% + ${currencyInfo.currencySymbol}${fixedF}`}
                            </span>
                        </div> :
                            <div className={classes.fieldColumnTwo}>
                                <span className={classes.valueText}>
                                    {`${bPP.commission}%`}
                                </span>
                            </div>}
                        </div>
                        <div className={classes.fieldRows}>
                            <div className={classes.fieldColumnOne}>
                                <span className={classes.labelText}>
                                    Commission Refundability
                                </span>
                            </div>
                            <div className={classes.fieldColumnTwo}>
                                <span className={classes.valueText}>
                                    {bPP.refundCommission ? "Refundable" : "Non-refundable"}
                                </span>
                            </div>
                        </div>
                        <div className={classes.fieldRows}>
                            <div className={classes.fieldColumnOne}>
                                <span className={classes.labelText}>
                                    Dispute Fee
                                </span>
                            </div>
                            <div className={classes.fieldColumnTwo}>
                                <span className={classes.valueText}>
                                    {`${bPP.disputePercentage}% of order + ${currencyInfo.currencySymbol}${amountA} fixed`}
                                </span>
                            </div>
                        </div>
                        <div className={classes.fieldRows}>
                            <div className={classes.fieldColumnOne}>
                                <span className={classes.labelText}>
                                    Max Connect Overdraw
                                </span>
                            </div>
                            <div className={classes.fieldColumnTwo}>
                                <span className={classes.valueText}>
                                    <CurrencyPreProcessor 
                                        amount = {bPP.maxConnectNegative}
                                        currencyBreak={currencyInfo.currencyBreak}
                                        roundingFactor={currencyInfo.roundingFactor}
                                        currencySymbol={currencyInfo.currencySymbol}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid container item xs={12} className={classes.headingWrapper} style={{paddingTop:"0px"}}>
                    <span className={classes.labelText}>
                        Agreement
                    </span>
                </Grid>
                <Grid item xs={12} className={classes.docBG}>
                    <div style={{paddingLeft:15}}>
                        <Typography className={classes.textBox}><div style={{whiteSpace:"normal", width:"80%"}}>{parse(bPP.agreement)}</div></Typography>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
        )
    } else{return(null)}
}

export default CaritraPricing;