const initialState = {
  serverError:false,
  unauthorizedError:false,
  error: false,
  insideModal:false,
  logoutError: false,
  errorMessage: null,
  loaded:false,
  token: null,
  userDetails: {
    firstName: null,
    lastName: null
  }
}

const updateObjectInArray = (array, action) => {
  return array.map((sites, index) => {
    if (index !== action.index) {
      return sites
    }
    return {
      ...sites,
      isActive: true
    };
  });
};

const removeItem = (array, action) => {
  return array.filter((item, index) => index !== action.index)
};

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case 'UPDATE':
      state = { ...state, ...action.payload };
      return state;
    case 'CLEAR':
      state = {...initialState, loaded: state.loaded};
      return state;
    case "ADD_TO_LIST_SITE":
      return {
        ...state,
        sites: updateObjectInArray(state.sites, action)
      };
    case "ADD_TO_ACTIVE_SITE":
      return {
        ...state,
        sites: updateObjectInArray(state.sites, action)
      };
    case "CLEAR_ITEMS_SITE":
      return {
        sites: []
      };

    case "REMOVE_ITEM_SITE":
      return {
        ...state,
        sites: removeItem(state.sites, action)
      };

    default:
      return state;
  }

};

export default reducer;