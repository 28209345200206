import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { updateCustomerQuali, withLoading } from "../../../../../../utils";
import { statusQualification } from "../../../../../../utils";
import { listCustomerQuali } from "../../../../../../utils";
import { deleteQualification } from "../../../../../../utils";
import {
  Grid, Button, Container, CircularProgress,
  Menu, MenuItem, useMediaQuery, Typography
} from "@material-ui/core";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import { sizing } from '@material-ui/system';
import { CEllipsisIcon } from "../../../../../Icons/Icons";
import moment from "moment";
import StatusTag from "./../../../../../TagComponents/StatusTag";
import Form from "../../../../../Form/Form";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import noWhiteSpace from "../../../../../../FormValidation/noWhiteSpace";
import CModal from "../../../../../Form/CModal";
import CDisplayArea from "../../../../../Form/CDisplayArea";
import LightTooltip from "../../../../../Form/LightTooltip";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px"
  },
  headingWrapper: {
    paddingBottom: "10px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
    paddingLeft: "10px",
  },
  fieldColumnOne: {
    width: "100px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  sdCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "25%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: "20px",
    maxWidth: "600px"
  }
}));

const CustomerQualification = () => {
  const classes = useStyles();
  const [_, updateState] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const small = useMediaQuery('(min-width: 750px)');
  const large = useMediaQuery('(min-width: 960px)');
  const customerQualiDetails = useSelector((state) => {
    return state.customerQualificationDetails;
  });
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const [eOpen, setEOpen] = useState(false);
  const [aDOpen, setADOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const handleClose = () => {
    setEOpen(false);
    setADOpen(false);
  };
  const handleMenuClose = () => {
    setAnchorEl(false);
  };
  async function handleSubmit(event) {

    dispatch({
      type: 'UPDATE', payload: {
        loading: true
      }
    });
    let success = await updateCustomerQuali({
      businessId: businessId,
      qualificationId: customerQualiDetails.qualificationId,
      name: event.name,
      description: event.description,
    })
    dispatch({
      type: 'UPDATE', payload: {
        loading: false
      }
    });
    if (success) {
      setEOpen(false);
      updateState((_) => !_);
    }
  }
  async function handleClick(event) {
    const success = await withLoading(statusQualification, {
      businessId: businessId,
      qualificationId: customerQualiDetails.qualificationId,
      isActive: customerQualiDetails.isActive ? false : true
    })
    if (success) {
      setADOpen(false);
      updateState((_) => !_);
    }
  }

  const { qualificationIdURL } = useParams();

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });

    let data = await listCustomerQuali({
      businessId: businessId,
      qualificationId: qualificationIdURL,
    });

    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
        customerQualificationDetails: data ? data.qualificationDetails[0] : null,
      },
    });
  }, [_]);

  if (customerQualiDetails && customerQualiDetails.qualificationId == qualificationIdURL) {
    return (
      <Grid container className={large ? classes.mainWrapper : classes.mobilePadding}>
        <React.Fragment>
          <CModal open={eOpen} setOpen={setEOpen}
            children={
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={handleSubmit}
                  postAction={null}
                  title="Edit Qualification"
                  cancelEnabled
                  cancelAction={() => { setEOpen(false); }}
                  cancelText="Cancel"
                  submitText="Save"
                  inputFields={
                    [
                      {
                        type: "TextField",
                        validator: [notEmpty, noWhiteSpace],
                        initialvalue: customerQualiDetails.name,
                        label: "Name",
                        name: "name",
                      },
                      {
                        type: "TextArea",
                        initialvalue: customerQualiDetails.description,
                        label: "Description",
                        name: "description",
                        resizeEnabled: false,
                        moreProps: {
                          rowsMin: 4,
                          rowsMax: 10
                        }
                      }
                    ]
                  }
                >
                </Form>
              </Container>
            }
          />
          <GeneralDialog
            open={aDOpen}
            close={handleClose}
            title={customerQualiDetails.isActive ? "Deactivate Qualification" : "Activate Qualification"}
            text={customerQualiDetails.isActive ? "Are you sure you want to deactivate this qualfication?" : "Are you sure you want to activate this qualification?"}
            action={handleClick}
          />
          <GeneralDialog
            open={deleteOpen}
            close={() => { setDeleteOpen(false) }}
            title="Delete Qualification"
            text="Are you sure you want delete this qualification permanently?"
            action={async () => {
              const success = await withLoading(deleteQualification, {
                businessId: businessId,
                qualificationId: customerQualiDetails.qualificationId
              })
              if (success && success.error === false) {
                history.replace(`/dashboard/${businessId}/Qualifications`);
              }

            }}
          />
        </React.Fragment>
        <Grid container item xs={12} direction="row" className={classes.topBlock}>
          <Grid item xs={6}>
            <div>
              <span className={classes.topHeading}>QUALIFICATION</span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <span className={classes.rightElem}>
                <Button onClick={(event) => { setAnchorEl(event.currentTarget); }} className={classes.buttonBasic}><CEllipsisIcon /></Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => { setEOpen(true); handleMenuClose(); }}>Edit</MenuItem>
                  <MenuItem onClick={() => { setADOpen(true); handleMenuClose(); }}>{customerQualiDetails.isActive ? "Deactivate" : "Activate"}</MenuItem>
                  {!customerQualiDetails.isActive ?
                    <MenuItem onClick={() => { setDeleteOpen(true); setAnchorEl(false) }}>
                      <Typography >
                        Delete Qualification
                      </Typography>
                    </MenuItem> : null}
                </Menu>
              </span>
            </div>
          </Grid>
        </Grid>
        <Grid container item direction="row" xs={12} className={classes.headerPadding}>
          <Grid item style={(small ? { marginLeft: -10, maxWidth: "50%" } : { marginLeft: -10, width: "100%" })}>
            <LightTooltip title={customerQualiDetails.name} arrow>
              <Typography className={small ? classes.topHeadingOne : classes.mobileTopHeadingTwo} noWrap style={{ width: `calc(100%)` }}>
                {customerQualiDetails.name}
              </Typography>
            </LightTooltip>
          </Grid>
          <Grid item style={small ? { paddingLeft: "20px" } : { paddingLeft: "10px" }}>
            <div className={small ? classes.headerPadding : null}>
              <span className={large ? classes.topHeadingFour : null}>
                <StatusTag wrapperName={customerQualiDetails.isActive ? "greenWrapper" : "redWrapper"} value={customerQualiDetails.isActive ? "Active" : "Inactive"} />
              </span>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} style = {{marginTop:"10px"}}>
          <div className={classes.headingWrapper}>
            <span className={classes.labelText}>Type</span>
          </div>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          <span>
            <CDisplayArea
              value={customerQualiDetails.type}
              initialMaxHeight={"calc(100vh - 360px)"}
            />
          </span>{" "}
        </Grid>
        <Grid item xs={12} style = {{marginTop:"20px"}}>
          <div className={classes.headingWrapper}>
            <span className={classes.labelText}>Description</span>
          </div>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          <span>
            <CDisplayArea
              value={customerQualiDetails.description ? customerQualiDetails.description : null}
              initialMaxHeight={"calc(100vh - 360px)"}
            />
          </span>{" "}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={12}>
          <div className={classes.loaderWrapper}>
            <CircularProgress className={classes.loaderMain} />
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default CustomerQualification;
