import {
  Grid,
  TextField,
  Typography,
  Button,
  makeStyles,
  useMediaQuery,
  FormHelperText,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  withLoading,
  getAnalyticsInfo,
  setFacebookID,
  removeFacebookID,
  setGoogleID,
  removeGoogleID,
} from "../../../../../../utils";

const useStyles = makeStyles((theme) => ({
  topBlock: {
    paddingBottom: "15px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mainWrapper: {
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  fieldText: {
    fontSize: 16,
    color: theme.palette.primaryText.main,
    padding: "10px",
  },
  buttonText: {
    textTransform: "none",
    fontSize: "14px",
  },
}));

const Analytics = (props) => {
  const { businessId } = props;
  const classes = useStyles();
  const bigEnough = useMediaQuery("(min-width: 750px)");
  const [analyticsInfo, setAnalyticsInfo] = useState(null);
  const setUp = async () => {
    const response = await withLoading(getAnalyticsInfo, {
      businessId: businessId,
    });
    if (response && !response.error) {
      setAnalyticsInfo(response.adInfo);
    }
  };

  useEffect(() => {
    setUp();
  }, []);

  if (analyticsInfo !== null) {
    return (
      <Grid
        container
        className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
        spacing={2}
        display="flex"
      >
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span
              className={
                bigEnough ? classes.topHeading : classes.mobileTopHeadingOne
              }
            >
              Analytics
            </span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <Typography gutterBottom style={{ fontSize: "20px" }}>
              Facebook Ad Tracking
            </Typography>
            <Typography style={{ flex: 1, fontSize: "14px" }} gutterBottom>
              If you're running facebook ads and want to track conversion
              through View Content, Add to Cart, and Purchase events, simply add
              your Facebook pixel ID and we'll automatically insert your
              tracking code.
            </Typography>
            <FacebookId
              businessId={businessId}
              analyticsInfo={analyticsInfo}
              setUp={setUp}
              classes={classes}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <Typography gutterBottom style={{ fontSize: "20px" }}>
              Google analytics tracking
            </Typography>
            <Typography style={{ flex: 1, fontSize: "14px" }} gutterBottom>
              If you're using Google Analytics and have a V4 Measurement ID for
              tracking events, add that here and we'll automatically insert your
              tracking code where relevant.
            </Typography>
            <GoogleId
              businessId={businessId}
              analyticsInfo={analyticsInfo}
              setUp={setUp}
              classes={classes}
            />
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

const notValidPixel = (pixelId) => {
  if (pixelId === null) {
    return true;
  } else if (
    (pixelId.length !== 15 && pixelId.length !== 16) ||
    !/^\d+$/.test(pixelId)
  ) {
    return true;
  }
  return false;
};

const notValidTrackingId = (trackingId) => {
    if (trackingId === null || trackingId === undefined) {
      return true;
    } else if (
      trackingId.length !== 12 || !trackingId.startsWith('G-')
    ) {
      return true;
    }
    return false;
  };

const FacebookId = (props) => {
  const { businessId, analyticsInfo, setUp, classes } = props;
  const [pixelId, setPixelId] = useState(null);
  const loading = useSelector((state) => state.loading);
  useEffect(() => {
    setPixelId(analyticsInfo.fbPixelId);
  }, [analyticsInfo]);

  return (
    <React.Fragment>
      <FormHelperText>Facebook Pixel ID</FormHelperText>
      <TextField
        variant={"outlined"}
        disabled={analyticsInfo.fbPixelId !== null}
        value={pixelId === null ? "" : pixelId}
        onChange={(event) => {
          setPixelId(event.target.value);
        }}
        InputProps={{
          classes: {
            input: classes.fieldText,
          },
        }}
      />
      <div style={{ paddingTop: "15px" }}>
        {analyticsInfo.fbPixelId === null ? (
          <Button
            color="primary"
            variant="contained"
            disabled={notValidPixel(pixelId) || loading}
            onClick={async () => {
              const response = await withLoading(setFacebookID, {
                businessId: businessId,
                fbPixelId: pixelId,
              });
              if (response && !response.error) {
                await setUp();
              }
            }}
          >
            <Typography className={classes.buttonText}>Set Pixel ID</Typography>
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              const response = await withLoading(removeFacebookID, {
                businessId: businessId,
              });
              if (response && !response.error) {
                await setUp();
              }
            }}
          >
            <Typography className={classes.buttonText}>
              Remove Pixel ID
            </Typography>
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

const GoogleId = (props) => {
  const { businessId, analyticsInfo, setUp, classes } = props;
  const [trackingId, setTrackingId] = useState(null);
  const loading = useSelector((state) => state.loading);
  useEffect(() => {
    setTrackingId(analyticsInfo.googleTrackingId);
  }, [analyticsInfo]);

  return (
    <React.Fragment>
      <FormHelperText>Google Measurement ID</FormHelperText>
      <TextField
        variant={"outlined"}
        disabled={analyticsInfo.googleTrackingId !== null}
        value={trackingId === null ? "" : trackingId}
        onChange={(event) => {
          setTrackingId(event.target.value);
        }}
        InputProps={{
          classes: {
            input: classes.fieldText,
          },
        }}
      />
      <div style={{ paddingTop: "15px" }}>
        {analyticsInfo.googleTrackingId === null ? (
          <Button
            color="primary"
            variant="contained"
            disabled={notValidTrackingId(trackingId) || loading}
            onClick={async () => {
              const response = await withLoading(setGoogleID, {
                businessId: businessId,
                googleTrackingId: trackingId,
              });
              if (response && !response.error) {
                await setUp();
              }
            }}
          >
            <Typography className={classes.buttonText}>
              Set Measurement ID
            </Typography>
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              const response = await withLoading(removeGoogleID, {
                businessId: businessId,
              });
              if (response && !response.error) {
                await setUp();
              }
            }}
          >
            <Typography className={classes.buttonText}>
              Remove Measurement ID
            </Typography>
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

export default Analytics;
