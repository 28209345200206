const urlRegex = /https:\/\/(.*)/
const defaultImage = new Set(['app-logo', 'card-logo', 'default-images', 'email-logo', 'webapp'])

// Assumption is made that src and optionConfig are valid
export const formatSrc = (src, optionConfig) => {
    if (!src) {
        return undefined
    }
    else {
        const { desktopOptions, mobileOptions, mobile } = optionConfig
        const options = mobile ? mobileOptions : desktopOptions
        const result = src.match(urlRegex) // ex. of result ['https://{amazonEndpoint}/{amazonFileLocation}','{amazonEndpoint}/{amazonFileLocation}']
        if (Array.isArray(result) && result.length === 2) {
            const uri = result[1] // ex. of uri {amazonEndpoint}/{amazonFileLocation}
            const uriExpanded = uri.split(/\//)
            uriExpanded.shift() //ex of uriExpanded amazonFileLocation split by '/'
            const cloudfrontSrc = defaultImage.has(uriExpanded[0]) ? process.env.REACT_APP_AWS_API_ENDPOINT_ALT : process.env.REACT_APP_AWS_API_ENDPOINT
            return cloudfrontSrc + '/' + [...options, ...uriExpanded].join('/')
        }
        else {
            return undefined
        }
    }
}





const Image = (props) => {
    const { src, optionConfig, imgProps } = props
    return <img src={formatSrc(src, optionConfig)} alt={src} {...imgProps} />
}

export default Image