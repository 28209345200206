import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import CalendarPreferences from './CalendarPreferences';



const CalendarPreferencesHandler = () => {
    const businessId = useSelector(state => state.buisnessDetails.businessId);
    const basePath = `/dashboard/${businessId}/Dashboard-Preferences`
    return (
        <Switch>
            <Route exact path={basePath}>
                <CalendarPreferences />
            </Route>
            <Route path="*" render={<Redirect to="404" />} />
        </Switch>
    )
}

export default CalendarPreferencesHandler