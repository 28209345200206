import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import Site from './Site';
import SitesList from './SitesList';

const SiteHandler = () => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const basePath = `/dashboard/${businessId}/Sites`
    return(
        <React.Fragment>
            <Switch>
            <Route path = {`/dashboard/${businessId}/Sites`} exact render = {() => {return(<SitesList basePath = {basePath}/>)}}/>
            <Route path = {`/dashboard/${businessId}/Sites/:siteIdURL`} render = {() => {return(<Site/>);}}/>
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
}

export default SiteHandler;