import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import MapContainer from "../../../../MapContainer/MapContainer";
import ListTable from "../../../../TableComponents/ListTable";
import { Grid, Button, useMediaQuery, Typography, Avatar, Container } from "@material-ui/core";
import LightTooltip from "../../../../Form/LightTooltip";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import CDisplayArea from "../../../../Form/CDisplayArea";
import moment from 'moment'
import GeneralDialog from "../../../../GeneralDialog/GeneralDialog";
import { customerCancelSession, withLoading } from "../../../../../utils";
import { CInfoIcon } from "../../../../InfoIcon/InfoIcon";
import { AttendeeInfoModal } from "../../../../AttendeeInfoModal/AttendeeInfoModal";
import CurrencyPreProcessor from "../../../../CurrencyFormatter/CurrencyFormatter";
import AddOnTable from "../AddOnTable";
import CModal from "../../../../Form/CModal";
import CTitle from "../../../../Form/CTitle";
import CButtonPair from "../../../../Form/CButtonPair";
import ResourceTable from "../../../../ResourceTable/ResourceTable";
const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "700px"
  },
  media: {
    height: 200,
    maxWidth: 200,
    objectFit: "cover"
  },
  avatar: {
    height: theme.spacing(20),
    width: theme.spacing(20),
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px"
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
    height: "100%"
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
    height: "100%"
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none",
    textTransform: "none"
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px"
  },
  fieldColumnOne: {
    width: "80px",
    display: "inline-block",

  },
  fieldColumnTwo: {
    display: "inline-block",
    maxWidth: "calc(100% - 150px)",

  },
  sdCustomCellOne: {
    border: "none",
    width: "5%",
    maxWidth: "200px",
    padding: "0px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingThree: {
    fontSize: "20px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "35%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "60%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
    paddingLeft: "0px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  media: {
    height: 200,
    width: 200,
    objectFit: "cover"
  }
}));
const addOnHeaders = [
  {
    name: "Name",
    columnKey: "name",
    columnWidth: "50%",
    type: "Typography",
    tooltip: true
  },

  {
    name: "Description",
    columnKey: "description",
    columnWidth: "50%",
    tooltip: true
  },
  {
    name: "Sell Basis",
    columnKey: "sellBy",
    columnWidth: "100px",
    type: "Normal"
  },
  {
    name: "Amount",
    columnKey: "amount",
    columnWidth: "75px",
  },
];
const hostsHeaders = [
  {
    name: "Name",
    columnKey: "name",
    columnWidth: "50%",
    type: "Normal"
  },

  {
    name: "Email",
    columnKey: "email",
    columnWidth: "50%",
    tooltip: true
  },
  {
    name: "Phone Number",
    columnKey: "phoneNumber",
    columnWidth: "150px",
  },
];
const hostsHeadersM = [
  {
    name: "Name",
    columnKey: "name",
    columnWidth: "100%",
    type: "Normal"
  },
];

const isUTCPassed = (utcTime) => {
  try {
    let curMoment = new moment();
    let refMomemt = new moment(utcTime);

    if (curMoment.isBefore(refMomemt)) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return true;
  }
};

const Upcoming = (props) => {
  const classes = useStyles();
  const [cancel, setCancel] = useState(false)
  const bigEnough = useMediaQuery("(min-width: 750px)")
  const matches = useMediaQuery("(min-width: 1240px)")
  const [aM, setAM] = useState(false);
  const [customerFirstName, customerLastName] = useSelector(state => [state.userDetails.firstName, state.userDetails.lastName])

  if (props.sessionDetails) {
    return (
      <React.Fragment>
        <AttendeeInfoModal open={aM} setOpen={setAM} attendeeQuestions={props.sessionDetails.attendeeQuestions} />

        <CModal open={cancel} setOpen={setCancel}>
          <Container component="main" className={classes.formContainer}>
            <Grid container spacing={2}>
              <CTitle title="Cancel Session" />
              <Grid item xs={12}>
                <Typography>
                  Please contact a business administrator to cancel the session.
                </Typography>
              </Grid>
              <CButtonPair submitText="Close" action={async () => setCancel(false)} />
            </Grid>
          </Container>
        </CModal>

        <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding} style={{ width: "100%" }}>
          <Grid item xs={12}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>UPCOMING SESSION</span>
              {props.sessionDetails.isCanceled ? null :
                <Button
                  color="primary"
                  variant="contained"
                  style={{ float: "right", padding: 5 }}
                  onClick={() => { setCancel(true) }}
                  disabled={!props.sessionDetails.cancelAllowed || moment() > moment.utc(props.sessionDetails.cancelDeadlineDate)}
                >
                  <Typography noWrap style={{ fontSize: 14, textTransform: "none" }}>
                    Cancel
                  </Typography>
                </Button>}
            </div>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <div className={classes.headerPadding}>
              <LightTooltip title={`#${props.sessionDetails.sessionNumber} (${props.sessionDetails.offeringType} - ${(props.sessionDetails.isVirtual ? "Online" : "In Person")})`} arrow>
                <Typography className={(bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne)} noWrap>
                  #{props.sessionDetails.sessionNumber} ({props.sessionDetails.offeringType} - {(props.sessionDetails.isVirtual ? "Online" : "In Person")})
                </Typography>
              </LightTooltip>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <div className={matches ? classes.contentColumnFirst : classes.fieldRows}>
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Start Time</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { maxWidth: 160 } : { paddingLeft: "15px", maxWidth: "calc(100% - 145px)" }}>
                  <LightTooltip arrow title={`${props.sessionDetails.startDate}: ${props.sessionDetails.startTime} ${props.sessionDetails.timeZone}`}>
                    <Typography noWrap className={classes.valueText}>
                      {`${props.sessionDetails.startDate}: ${props.sessionDetails.startTime} ${props.sessionDetails.timeZone}`}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
              <div className={matches ? classes.contentColumnMiddle : classes.fieldRows}>
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Service</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { maxWidth: "160px" } : { paddingLeft: "15px", maxWidth: "calc(100% - 145px)" }}>
                  <LightTooltip arrow title={props.sessionDetails.serviceName}>
                    <Typography noWrap className={classes.valueText}>
                      {props.sessionDetails.serviceName}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
              <div className={matches ? classes.contentColumnMiddle : classes.fieldRows} >
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Business Name</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { maxWidth: 160 } : { paddingLeft: "15px", maxWidth: "calc(100% - 145px)" }}>
                  <LightTooltip arrow title={props.sessionDetails.businessName}>
                    <Typography noWrap className={classes.valueText}>
                      {props.sessionDetails.businessName}
                    </Typography>
                  </LightTooltip>
                </div>
              </div>
              {(props.sessionDetails.offeringType === 'Group' ?
                <div className={matches ? classes.contentColumnMiddle : classes.fieldRows} >
                  <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                    <Typography noWrap className={classes.labelText}>Class #</Typography>
                  </div>
                  <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? { maxWidth: 160 } : { paddingLeft: "15px", maxWidth: "calc(100% - 145px)" }}>
                    <Typography noWrap className={classes.valueText} style={{ maxWidth: "100px" }}>
                      {props.sessionDetails.classNumber}
                    </Typography>
                  </div>
                </div>
                : null)}
              <div className={matches ? (props.sessionDetails.optionType === "Pack" || props.sessionDetails.optionType === "Season Pass" ? classes.contentColumnMiddle : classes.contentColumnLast) : classes.fieldRows}>
                <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Order #</Typography>
                </div>
                <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>

                  <Link className={classes.valueTextLink} to={`/account/Orders/${props.sessionDetails.orderId}`}>
                    <Typography noWrap className={classes.valueTextLink} >
                      {props.sessionDetails.orderNumber}
                    </Typography>
                  </Link>
                </div>
              </div>
              {(props.sessionDetails.optionType === "Pack" || props.sessionDetails.optionType === "Season Pass" ?
                <div className={matches ? classes.contentColumnLast : classes.fieldRows}>
                  <div className={matches ? classes.blockLabel : classes.fieldColumnOne}>
                    <Typography noWrap className={classes.labelText}>{props.sessionDetails.optionType} #</Typography>
                  </div>
                  <div className={matches ? classes.blockText : classes.fieldColumnTwo} style={matches ? null : { paddingLeft: "15px" }}>

                    <Typography noWrap className={classes.valueTextLink}>
                      {props.sessionDetails.packNumber}
                    </Typography>

                  </div>
                </div>
                :
                null)}

            </div>
          </Grid>

          {(props.sessionDetails.offeringType === "Group" ?
            <React.Fragment>
              <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                  <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Hosts</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentRow}>
                  <ListTable
                    styling={{ width: "100%", maxHeight: 250 }}
                    identifier={"hosts"}
                    tableHeaders={(bigEnough ? hostsHeaders : hostsHeadersM)}
                    tableRows={props.sessionDetails.hostDetails.map((host,) => {
                      return ({
                        name: (
                          <LightTooltip arrow title={`${host.hostFirstName} ${host.hostLastName}`}>
                            <Typography noWrap className={classes.valueText}>
                              {`${host.hostFirstName} ${host.hostLastName}`}
                            </Typography>
                          </LightTooltip>),
                        phoneNumber: `${host.hostPhoneNumber.slice(0, 2)} (${host.hostPhoneNumber.slice(2, 5)}) ${host.hostPhoneNumber.slice(5, 8)}-${host.hostPhoneNumber.slice(8, 12)}`,
                        email: <LightTooltip title="Email Host" arrow><a style={{ color: "#0079fc" }} href={"mailto:" + host.hostEmail}>{host.hostEmail}</a></LightTooltip>
                      })
                    })}
                    rowClick={null}
                  />
                </div>
              </Grid>
            </React.Fragment>
            :
            <React.Fragment>
              <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                  <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Host</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} className={classes.contentRow}>
                  <Grid item xs={(bigEnough ? null : 12)} >
                    <Grid container justify={(bigEnough ? "flex-start" : "center")}>
                      {bigEnough ?
                        <div>
                          <img className={classes.media} src={(props.sessionDetails.staffImage ? props.sessionDetails.staffImage : "https://www.nicepng.com/png/detail/52-521023_download-free-icon-female-vectors-blank-facebook-profile.png")} alt="smth" />
                        </div>
                        : <Avatar className={classes.avatar} src={(props.sessionDetails.staffImage ? props.sessionDetails.staffImage : "https://www.nicepng.com/png/detail/52-521023_download-free-icon-female-vectors-blank-facebook-profile.png")} alt="smth" />
                      }
                    </Grid>
                  </Grid>
                  <Grid item style={{ paddingLeft: "10px", flex: '1' }}>
                    <div className={classes.contentRow}>
                      <div className={classes.fieldRows}>
                        <div className={classes.fieldColumnOne} style={{ paddingBottom: "5px" }}>
                          <Typography noWrap className={classes.labelText}>Name</Typography>
                        </div>
                        <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
                          <LightTooltip title={`${props.sessionDetails.staffFirstName} ${props.sessionDetails.staffLastName}`} arrow>
                            <Typography noWrap className={classes.valueText}>
                              {`${props.sessionDetails.staffFirstName} ${props.sessionDetails.staffLastName}`}
                            </Typography>
                          </LightTooltip>
                        </div>
                      </div>
                      <div className={classes.fieldRows}>
                        <div className={classes.fieldColumnOne} style={{ paddingBottom: "2px" }}>
                          <Typography noWrap className={classes.labelText}>Email</Typography>
                        </div>
                        <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
                          <LightTooltip title="Email Host" arrow>
                            <Typography noWrap className={classes.valueText}>
                              <a style={{ color: "#0079fc" }} href={"mailto:" + props.sessionDetails.staffEmail}>{props.sessionDetails.staffEmail}</a>
                            </Typography>
                          </LightTooltip>
                        </div>
                      </div>
                      <div className={classes.fieldRows}>
                        <div className={classes.fieldColumnOne} style={{ paddingBottom: "2px" }}>
                          <Typography noWrap className={classes.labelText}>Phone Number</Typography>
                        </div>
                        <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px" }}>
                          <Typography noWrap className={classes.valueText}>
                            {`${props.sessionDetails.staffPhoneNumber.slice(0, 2)} (${props.sessionDetails.staffPhoneNumber.slice(2, 5)}) ${props.sessionDetails.staffPhoneNumber.slice(5, 8)}-${props.sessionDetails.staffPhoneNumber.slice(8, 12)}`}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>

          )}
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Attendee Info</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow}>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne}>
                  <Typography noWrap className={classes.labelText}>Name</Typography>
                </div>
                <div className={classes.fieldColumnTwo}>
                  <LightTooltip title="View Attendee Details" arrow>
                    <Link to={`/account/Attendees/${props.sessionDetails.attendeeId}`} className={classes.valueTextLink}>
                      <Typography noWrap className={classes.valueTextLink} >
                        {`${props.sessionDetails.attendeeFirstName} ${props.sessionDetails.attendeeLastName}`}
                      </Typography>
                    </Link>
                  </LightTooltip>
                </div>
                {props.sessionDetails.attendeeQuestions.length > 0 ? <div className={classes.fieldColumnOne} style={{ width: "25px", paddingLeft: "10px" }}>
                  <CInfoIcon setOpen={setAM} />
                </div> : null}
              </div>
            </div>
          </Grid>
          <ResourceTable
            classes={classes}
            businessId={props.sessionDetails.businessId}
            resourceDetails={(props.sessionDetails?.resourceDetails ?? [])}
            bigEnough={bigEnough}
            eventId={props.sessionDetails.sessionId}
            eventType="Session"
          />
          <AddOnTable sessionDetails={props.sessionDetails} classes={classes} bigEnough={bigEnough} />
          {(props.sessionDetails.isVirtual === true
            ?
            <React.Fragment>
              <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                  <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Web Conference Info</span>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={classes.contentRow}>
                  <CDisplayArea
                    initialMaxHeight={300}
                    value={(props.sessionDetails.onlineConferenceInfo ? props.sessionDetails.onlineConferenceInfo : null)}
                  />
                </div>
              </Grid>
            </React.Fragment>
            :
            <React.Fragment>
              <Grid item xs={12}>
                <div className={classes.headingWrapper}>
                  <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Address</Typography>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={classes.contentRow}>
                  <LightTooltip arrow title={props.sessionDetails.address2 ? `${props.sessionDetails.address1}, ${props.sessionDetails.address2}` : `${props.sessionDetails.address1}`}>
                    <Typography className={classes.valueText} style={{ paddingBottom: "10px" }} justify="center">
                      <b>Address:</b> {props.sessionDetails.address2 ? `${props.sessionDetails.address1}, ${props.sessionDetails.address2}` : `${props.sessionDetails.address1}`}
                    </Typography>
                  </LightTooltip>
                  <div style={{ height: 400, width: "100%", justify: "center" }}>
                    <MapContainer siteDetails={{
                      address1: props.sessionDetails.address1,
                      geoPoint: {
                        lat: props.sessionDetails.geoPoint[1],
                        lon: props.sessionDetails.geoPoint[0]
                      }
                    }}
                      options={{
                        disableDefaultUI: true,
                        zoomControl: false,
                        gestureHandling: 'none'


                      }} />
                  </div>

                </div>
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12}>
            <div className={classes.headingWrapper}>
              <Typography noWrap className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Timeline</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentRow} >
              <Timeline style={{ width: 0, padding: 0 }}>
                {(props.sessionDetails.isCanceled ?

                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography className={classes.valueText}>
                        Canceled By: {(props.sessionDetails.canceledBy === "Business" ?
                          `${props.sessionDetails.businessName}` :
                          `${customerFirstName} ${customerLastName}`
                        )}
                      </Typography>
                      <Typography className={classes.labelText}>
                        At: {moment(props.sessionDetails.canceledAt).format("MMM, Do, YYYY : h:mm A ")}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  :
                  null)}

                {props.sessionDetails.rescheduleHistory.map((hist) => {
                  return (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography className={classes.valueText}>
                          Rescheduled By: {props.sessionDetails.businessName}

                        </Typography>
                        <Typography className={classes.labelText}>
                          {`Original Time: ${hist.oldTime}`}
                        </Typography>
                        <Typography className={classes.labelText}>
                          At: {moment(hist.rescheduledAt).format("MMM, Do, YYYY : h:mm A ")}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  )
                })}

                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography className={classes.valueText}>
                      Created By: {(props.sessionDetails.createdBy === "Business" ? props.sessionDetails.businessName : `${customerFirstName} ${customerLastName}`)}
                    </Typography>
                    <Typography className={classes.labelText}>
                      At: {moment(props.sessionDetails.createdAt).format("MMM, Do, YYYY : h:mm A ")}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>

              </Timeline>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (null);
  }
};

export default Upcoming;
