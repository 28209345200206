import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TemplateList from './TemplateList';
import TemplateDetails from './TemplateDetails';



const TemplateHandler = () => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const basePath = `/dashboard/${businessId}/Resource-Type`;

    return (
        <Switch>
            <Route path={basePath} exact>
                <TemplateList basePath={basePath} />
            </Route>
            <Route path={`${basePath}/:templateIdURL`} exact >
                <TemplateDetails basePath={basePath} />
            </Route>
            <Route path='*'>
                <Redirect to='/404' />
            </Route>
        </Switch>
    );
}

export default TemplateHandler;