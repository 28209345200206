import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";

const useStyles = makeStyles((theme) => ({
  comp: {
    width: "100%",
  },
  formLabel: {
    fontSize: 14,
    color: theme.palette.textColor2.main,
  },
  fieldText: {
    fontSize: 16,
    color: theme.palette.primaryText.main,
    padding: "10px",
  },
}));

const CTextField = (props) => {
  const classes = useStyles();

  const intoInputProps =
    props.moreProps && props.moreProps.InputProps
      ? props.moreProps.InputProps
      : {};
  intoInputProps.classes = { input: classes.fieldText };
  const otherProps = { ...props.moreProps, InputProps: intoInputProps };
  return (
    <div>
      <Typography className={classes.formLabel}>{props.label}</Typography>
      <TextField
        className={classes.comp}
        autoComplete="off"
        onChange={(event) => {
          if (props.trim) {
            event.target.value = event.target.value.trim();
          }
          props.onchange(event);
        }}
        defaultValue={
          typeof props.initialvalue == "string" ? props.initialvalue : ""
        }
        error={
          typeof props.errortext == "string" && props.errortext !== ""
            ? true
            : false
        }
        helperText={props.errortext !== "" ? props.errortext : null}
        variant="outlined"
        name={props.name}
        {...otherProps}
      ></TextField>
    </div>
  );
};

export default CTextField;
