const re  = new RegExp(/^\s+$/)

const noWhiteSpace = (data) =>
{
    if(re.test(data))
    {
        return("Cannot be whitespace")
    }
}

export default noWhiteSpace