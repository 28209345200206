import React, { useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { resetPassword, withLoading, safePush, forgetOTP } from '../../../../utils';
import validEmail from '../../../../FormValidation/validEmail';
import validOTP from '../../../../FormValidation/OTP';
import validPassword from '../../../../FormValidation/password';
import notEmpty from '../../../../FormValidation/notEmpty';
import Form from '../../../Form/Form'
import { Grid, InputAdornment, Typography, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    buttonText: {
        fontSize: 14,
        textTransform: "none"
    },
    container: {
        maxWidth: "500px",
        minHeight: "calc(100vh - 64px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));

const RBForgot = (props) => {
    const classes = useStyles()
    const email = useRef(null)
    const history = useHistory();
    const location = useLocation()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const resendDisabled = useSelector((state) => { return (state.resendDisabled) })
    const dispatch = useDispatch();
    const loading = useSelector(state => state.loading)


    const countDown = async (seconds) => {
        for (let i = 0; i < seconds; i++) {
            setTimeout(() => {
                dispatch({
                    type: 'UPDATE', payload: {
                        resendDisabled: i
                    }
                });
            }, (seconds - i) * 1000)
        }

    }

    async function resendOTP(event) {
        let success = await withLoading(forgetOTP, {
            email: email.current.value
        })
        if (success) {
            countDown(120)
        }
    }

    const redirect = (response) => {
        if (response) {
            props.setForm("login")
        }
    }

    return (
        <React.Fragment>
            <Form
                titleCenter
                title="Forgot Password"
                submitText="Reset"
                action={resetPassword}
                formStyles={{
                    padding: 14,

                }}

                postAction={redirect}
                inputFields={[
                    {
                        type: "TextField",
                        name: "email",
                        validator: [(value) => { setButtonDisabled(Boolean(validEmail(value))) }, notEmpty, validEmail],
                        label: "Email",
                        moreProps: {
                            inputRef: email
                        }

                    },
                    {
                        type: "TextField",
                        name: "otp",
                        label: "Email Verification Code",
                        validator: [notEmpty, validOTP],
                        moreProps: {
                            InputProps: {
                                endAdornment:
                                    <InputAdornment position="end" classes={{ inputAdornedEnd: classes.endAdornment }}>
                                        <Button onClick={resendOTP} className={classes.button} disabled={Boolean(resendDisabled) || buttonDisabled || loading}>
                                            <Typography className={classes.buttonText} color = "primary">
                                                {(resendDisabled ? `Wait ${resendDisabled}s` : "Get Code")}
                                            </Typography>
                                        </Button>
                                    </InputAdornment>
                            }
                        }

                    },
                    {
                        type: "Password",
                        name: "password",
                        label: "New Password",
                        validator: [notEmpty, validPassword]
                    },

                ]}
            />
            <Grid container>
                <Grid item xs={6}>
                    <Typography align="left" onClick={() => { props.setForm("signUp") }} color = "primary" style = {{cursor:"pointer"}}>
                        Don't have an account?<br />Sign up here
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align="right" onClick = {()=>{props.setForm("login")}} color = "primary" style = {{cursor:"pointer"}}>
                        Login
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default RBForgot