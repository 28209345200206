import React, { useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { genOTP, signUp, withLoading, safePush } from '../../../../utils';
import noWhiteSpace from '../../../../FormValidation/noWhiteSpace';
import validPhoneNumber from '../../../../FormValidation/phonenumber';
import validEmail from '../../../../FormValidation/validEmail';
import validOTP from '../../../../FormValidation/OTP';
import validPassword from '../../../../FormValidation/password';
import notEmpty from '../../../../FormValidation/notEmpty';
import Form from '../../../Form/Form'
import { Grid, InputAdornment, Typography, Button } from '@material-ui/core';

const RBSignUp = (props) => {
    const email = useRef(null)
    const history = useHistory();
    const location = useLocation()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const resendDisabled = useSelector((state) => { return (state.resendDisabled) })
    const dispatch = useDispatch();
    const loading = useSelector(state => state.loading)


    const countDown = async (seconds) => {
        for (let i = 0; i < seconds; i++) {
            setTimeout(() => {
                dispatch({
                    type: 'UPDATE', payload: {
                        resendDisabled: i
                    }
                });
            }, (seconds - i) * 1000)
        }

    }

    async function resendOTP() {

        let success = await withLoading(genOTP, {
            email: email.current.value
        })

        if (success) {
            countDown(120)
        }




    }

    const redirect = (response) => {
        if (response) {
            safePush("/for-business/create", history, location)
        }
    }

    return (
        <React.Fragment>
            <Form
                titleCenter
                title="Sign Up"
                formStyles={{
                    padding: 14,
                }}
                submitText="Sign Up"
                action={signUp}
                postAction={redirect}
                inputFields={[
                    {
                        type: "TextField",
                        name: "firstName",
                        validator: [notEmpty, noWhiteSpace],
                        label: "First Name"
                    },
                    {
                        type: "TextField",
                        name: "lastName",
                        validator: [notEmpty, noWhiteSpace],
                        label: "Last Name"
                    },
                    {
                        type: "PhoneNumber",
                        name: "phoneNumber",
                        validator: validPhoneNumber,
                        label: "Phone Number"
                    },
                    {
                        type: "TextField",
                        name: "email",
                        validator: [(value) => { setButtonDisabled(Boolean(validEmail(value))) }, notEmpty, validEmail],
                        label: "Email",
                        moreProps: {
                            inputRef: email
                        }

                    },
                    {
                        type: "Password",
                        name: "password",
                        label: "Password",
                        validator: [notEmpty, validPassword]
                    },
                    {
                        type: "TextField",
                        name: "otp",
                        label: "Email Verification Code",
                        validator: [notEmpty, validOTP],
                        moreProps: {
                            InputProps: {
                                endAdornment:
                                    <InputAdornment position="end" >
                                        <Button onClick={resendOTP} style={{ padding: 0 }} disabled={Boolean(resendDisabled) || buttonDisabled || loading}>
                                            <Typography style={{ fontSize: 14 }} color="primary">
                                                {(resendDisabled ? `Wait ${resendDisabled}s` : "Get Code")}
                                            </Typography>
                                        </Button>
                                    </InputAdornment>
                            }
                        }

                    }, {
                        type: "CheckBox",
                        name: "tos",
                        label: <span>By clicking the box, I agree to <a target="_blank" href={`${window.location.origin}/document/cctou`} rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                            Caritra Terms of Use
                        </a> and <a target="_blank" href={`${window.location.origin}/document/cpp`} rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                Privacy Policy
                            </a>
                        </span>,
                        validator: (value) => { if (value === false) { return ("Please agree to Terms and Conditions") } }
                    }

                ]}
            />
            <Grid container>
                <Grid item xs={6}>
                    <Typography align="left" onClick={() => { props.setForm("login") }} style={{ cursor: "pointer" }} color="primary">
                        Already have an account? Log in here
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default RBSignUp