import {
  Avatar,
  Button,
  Container,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import CheckIcon from "@material-ui/icons/Check";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import SubjectIcon from "@material-ui/icons/Subject";
import { ArrowBack } from "@material-ui/icons";
import { Rating } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  encodeGetParams,
  formatDuration,
  retrieveGetParams,
  safeQuery,
  searchDetails,
  sendToCheckout,
  withLoading,
} from "../../utils";
import CurrencyPreProcessor from "../CurrencyFormatter/CurrencyFormatter";
import CDisplayArea from "../Form/CDisplayArea";
import CModal from "../Form/CModal";
import LightTooltip from "../Form/LightTooltip";
import { formatSrc } from "../Image/Image";
import TwoColumn from "../TwoColumn/TwoColumn";
import MPAccount from "./MPAccount";
import ViewAddOns from "./ViewAddOns";
import ViewCRModal from "./ViewCRModal";
import ViewPRModal from "./ViewPRModal";

const options = {
  autoConfig: true,
  debug: false,
};

const advancedMatching = {};

const useStyles = makeStyles((theme) => ({
  pricingCard: {
    borderColor: theme.palette.accent1.main,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 20,
  },
  imageList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  selectedImage: {
    opacity: "1 !important",
  },
  imageInList: {
    height: 48,
    width: 48,
    objectFit: "cover",
    opacity: 0.5,
    cursor: "pointer",
    border: "1px solid black",
    boxSizing: "border-box",
  },
  bottomSpacer: {
    height: "50px",
  },
  media: {
    objectFit: "contain",
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  listText: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  listTextWithIcon: {
    paddingLeft: 10,
    maxWidth: "calc(100% - 44px)",
  },
  primary: {
    color: `${theme.palette.primary.main} !important`,
  },
  primaryIcon: {
    color: `#FFFFFF`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: "50%",
  },
  textColor3: {
    color: `${theme.palette.textColor3.main} !important`,
  },
  textColor2: {
    color: `${theme.palette.textColor2.main} !important`,
  },
  primaryText: {
    color: `${theme.palette.primaryText.main} !important`,
  },
  imgContainer: {
    margin: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
    height: 400,
    boxSizing: "box-border",
  },
  spacingTop: {
    paddingTop: 20,
  },
  spacingTop2: {
    paddingTop: 10,
  },
  imgContainerMobile: {
    margin: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 400,
    boxSizing: "box-border",
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
  },
  fullWidth: {
    width: "100%",
  },
  fieldColumnOne: {
    width: "80px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
    maxWidth: "250px",
  },
  fieldRows: {
    paddingTop: "8px",
  },
  root: {
    padding: 8,
    maxWidth: 1440,
    margin: "0 auto",
  },
  formContainer: {
    border: "20px",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "600px",
    width: "100vw",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  staffContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: 200,
    height: 220,
    justifyContent: "center",
    "&.MuiButton-selected": {
      backgroundColor: "black",
    },
  },
  notSelectedStaff: {
    opacity: 0.5,
    cursor: "pointer",
  },
  selectedStaff: {
    backgroundColor: "#F7FAFC",
  },
  staffImage: {
    width: 100,
    height: 100,
    marginBottom: 10,
  },
  selectedStaffImage: {
    width: 180,
    height: 180,
    objectFit: "cover",
  },
  listImageContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: "white",
  },
  headingWrapper: {
    paddingBottom: "10px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  addOnButton: {
    backgroundColor: theme.palette.accent2.main,
    boxShadow: "none",
    color: theme.palette.primary.main,
    marginTop: "10px",
    marginBottom: "10px",
    maxWidth: 300,
    margin: "0 auto",
  },
  purchaseButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primaryText.main,
    "&:hover": {
      backgroundColor: theme.palette.primaryText.main,
      color: theme.palette.accent2.main,
    },
  },
}));

const OfferingDetails = (props) => {
  const classes = useStyles();
  const { catalogID, offeringIdURL } = useParams();
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [details, setDetails] = useState(null);
  const [optionChoice, setOptionChoice] = useState(null);
  const [showAskLogin, setShowAskLogin] = useState(false);
  const [currentImage, setCurrentImage] = useState(undefined);
  const bigEnough = useMediaQuery("(min-width: 750px)");
  const history = useHistory();

  const setUp = async () => {
    const getParams = retrieveGetParams(window.location);
    let data = await withLoading(
      searchDetails,
      {
        offeringId: offeringIdURL,
        optionId: getParams.optionId,
        staffId: getParams.staffId,
        catalogId: catalogID? catalogID : null, 
      },
      true
    );

    if (safeQuery(data, ["details"])) {
      let fbPixelId = data?.details?.businessDetails?.fbPixelId;

      if (fbPixelId) {
        ReactPixel.init(fbPixelId, advancedMatching, options);
        ReactPixel.trackSingle(fbPixelId, "ViewContent", {
          content_name: data?.details?.serviceDetails?.name,
        });
      }

      let googleTrackingId = data?.details?.businessDetails?.googleTrackingId;

      if (googleTrackingId) {
        ReactGA.initialize(googleTrackingId, {
          gaOptions: {
            debug_mode: process.env.REACT_APP_GA_DEBUG_MODE,
          },
          gtagOptions: {
            debug_mode: process.env.REACT_APP_GA_DEBUG_MODE,
          },
        });

        ReactGA.event("page_view", {
          page_title: data?.details?.serviceDetails?.name
            ? data?.details?.serviceDetails?.name
            : "Unknown Service",
        });
      }
      if (
        safeQuery(data, ["details", "serviceDetails", "type"]) === "Private" &&
        safeQuery(data, ["details", "staffDetails", "length"]) > 0
      ) {
        setSelectedStaff(safeQuery(data, ["details", "staffDetails", 0]));
      }
      setDetails(safeQuery(data, ["details"]));
      setCurrentImage(safeQuery(data, ["details", "serviceDetails", "image"]));
    }
  };

  useEffect(() => {
    setUp();
  }, []);

  if (details === null) {
    return null;
  } else {
    return (
      <div className={classes.root}>
        <MPAccount
          open={showAskLogin}
          setOpen={setShowAskLogin}
          optionChoice={optionChoice}
        />
        <IconButton
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowBack className={classes.textColor2} />
        </IconButton>
        <Grid container style={{ padding: 20 }}>
          {!bigEnough ? (
            <div style={{padding: 9, paddingBottom: 15}}>
              <Title details={details} bigEnough={bigEnough} />
            </div>
          ) : null}
          <Grid item xs={12} sm={8} style={{ padding: 8,  }}>
            <BasicDetails
              details={details}
              bigEnough={bigEnough}
              offeringId={offeringIdURL}
            />
            <Grid item xs={10}>
              <ServiceDetails details={details} />
            </Grid>
          </Grid>
          {bigEnough ? (
            <Grid item xs={12} sm={4}>
              {currentImage !== undefined ? (
                <React.Fragment>
                  <div
                    className={
                      bigEnough
                        ? `${classes.imgContainer}`
                        : classes.imgContainerMobile
                    }
                  >
                    <img
                      className={classes.media}
                      alt="Service"
                      src={formatSrc(currentImage, {
                        desktopOptions: ["fit-in/700x700"],
                      })}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = currentImage;
                      }}
                    />
                  </div>
                  {safeQuery(details, ["serviceDetails", "images", "length"]) >
                  0 ? (
                    <div>
                      <ImageList className={classes.imageList}>
                        {[
                          details.serviceDetails.image,
                          ...details.serviceDetails.images,
                        ]
                          .slice(0, 10)
                          .map((item, index) => (
                            <ImageListItem
                              key={`img ${index}`}
                              style={{ width: 60, height: 54 }}
                            >
                              <img
                                onClick={() => {
                                  setCurrentImage(item);
                                }}
                                className={`${classes.imageInList} ${
                                  currentImage === item
                                    ? classes.selectedImage
                                    : null
                                }`}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = item;
                                }}
                                alt=""
                                src={formatSrc(item, {
                                  desktopOptions: ["fit-in/50x50"],
                                })}
                              />
                            </ImageListItem>
                          ))}
                      </ImageList>
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
            </Grid>
          ) : null}
          {safeQuery(details, ["serviceDetails", "type"]) === "Private" ? (
            <React.Fragment>
              <Grid item xs={12}>
                {safeQuery(details, ["staffDetails", "length"]) > 0 ? (
                  <StaffSelection
                    details={details}
                    selectedStaff={selectedStaff}
                    setSelectedStaff={setSelectedStaff}
                    bigEnough={bigEnough}
                  />
                ) : (
                  "No assigned staff. Please contact business"
                )}
              </Grid>
              {selectedStaff ? (
                <Grid item xs={12}>
                  <StaffDetails
                    selectedStaff={selectedStaff}
                    bigEnough={bigEnough}
                    shrink={safeQuery(details, ["staffDetails", "length"]) > 1}
                  />
                </Grid>
              ) : null}
            </React.Fragment>
          ) : null}

          <Grid item xs={12}>
            <ShowPricingOptions
              setShowAskLogin={setShowAskLogin}
              setOptionChoice={setOptionChoice}
              details={details}
              selectedStaff={selectedStaff}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
          </Grid>
        </Grid>
      </div>
    );
  }
};

const ServiceDetails = (props) => {
  const classes = useStyles();
  const details = props.details;
  return (
    <div>
      <div className={`${classes.spacingTop2}`}>
        <Typography
          noWrap
          variant="h5"
          className={classes.primaryText}
          style={{ fontSize: 20 }}
        >
          <b>Description</b>
        </Typography>
      </div>
      <div className={classes.spacingTop2}>
        <CDisplayArea
          value={safeQuery(details, ["serviceDetails", "description"])}
          initialMaxHeight={"100px"}
        />
      </div>
    </div>
  );
};

const StaffModal = (props) => {
  const {
    selectedStaff,
    open,
    setOpen,
    selected,
    classes,
    bigEnough,
    setSelected,
  } = props;

  return (
    <CModal open={open} setOpen={setOpen}>
      {selectedStaff ? (
        <Container className={classes.formContainer}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={12}>
              <LightTooltip
                title={`${selectedStaff.firstName} ${selectedStaff.lastName}'s Profile`}
                arrow
              >
                <Typography noWrap variant="h5">
                  {`${selectedStaff.firstName} ${selectedStaff.lastName}`}
                </Typography>
              </LightTooltip>
            </Grid>
            <Grid item xs={bigEnough ? null : 12}>
              <Grid
                container
                justifyContent={bigEnough ? "flex-start" : "center"}
              >
                <div>
                  <img
                    className={classes.selectedStaffImage}
                    src={selectedStaff.image}
                    alt="smth"
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                flex: 1,
                paddingLeft: bigEnough ? 40 : 0,
                paddingTop: bigEnough ? 0 : 20,
              }}
            >
              <Grid container>
                <PhoneIcon className={classes.textColor3} />

                <Typography noWrap className={classes.listTextWithIcon}>
                  {selectedStaff.phoneNumber}
                </Typography>
              </Grid>
              <Grid container>
                <MailIcon
                  className={`${classes.textColor3} ${classes.listText}`}
                />

                <Typography
                  noWrap
                  gutterBottom
                  className={`${classes.listTextWithIcon} ${classes.listText}`}
                >
                  <a
                    href={"mailto:" + selectedStaff.email}
                    className={classes.link}
                    style={{ color: "inherit" }}
                  >
                    {selectedStaff.email}
                  </a>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {selectedStaff.profile ? (
                <CDisplayArea
                  initialMaxHeight={400}
                  value={selectedStaff.profile}
                />
              ) : null}
            </Grid>

            {selected === false ? (
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => {
                    setSelected(selectedStaff);
                    setOpen(false);
                  }}
                >
                  Select
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Container>
      ) : null}
    </CModal>
  );
};

const StaffDetails = (props) => {
  const { selectedStaff, bigEnough, shrink } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return shrink ? null : (
    <React.Fragment>
      <StaffModal
        open={open}
        setOpen={setOpen}
        classes={classes}
        selectedStaff={selectedStaff}
        bigEnough={bigEnough}
      />
      <Grid
        container
        className={`${classes.spacingTop}`}
        alignItems="center"
        style={{ borderRadius: 15 }}
      >
        <Grid item xs={bigEnough ? null : 12}>
          <Grid container justifyContent={bigEnough ? "flex-start" : "center"}>
            <div>
              <img
                className={classes.selectedStaffImage}
                src={selectedStaff.image}
                alt="smth"
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            flex: 1,
            paddingLeft: bigEnough ? 40 : 0,
            paddingTop: bigEnough ? 0 : 20,
          }}
        >
          <LightTooltip
            arrow
            title={`${selectedStaff.firstName} ${selectedStaff.lastName}`}
          >
            <Typography variant="h5" gutterBottom>
              {`${selectedStaff.firstName} ${selectedStaff.lastName}`}
            </Typography>
          </LightTooltip>
          <Grid container>
            <PhoneIcon className={classes.textColor3} />

            <Typography noWrap className={classes.listTextWithIcon}>
              {selectedStaff.phoneNumber}
            </Typography>
          </Grid>
          <Grid container>
            <MailIcon className={`${classes.textColor3} ${classes.listText}`} />

            <Typography
              noWrap
              gutterBottom
              className={`${classes.listTextWithIcon} ${classes.listText}`}
            >
              <a
                href={"mailto:" + selectedStaff.email}
                className={`${classes.link} ${classes.primaryText}`}
              >
                {selectedStaff.email}
              </a>
            </Typography>
          </Grid>
          <Grid container>
            <AccountBoxIcon
              className={`${classes.textColor3} ${classes.link}`}
            />
            <Typography
              noWrap
              className={`${classes.listTextWithIcon} ${classes.link}`}
              gutterBottom
              onClick={() => {
                setOpen(true);
              }}
            >
              View Profile
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const ShowPricingOptions = (props) => {
  const classes = useStyles();
  const details = props.details;
  const currencyInfo = safeQuery(details, ["businessDetails", "currencyInfo"]);
  const selectedStaff = props.selectedStaff;
  const pricingOptions =
    safeQuery(details, ["serviceDetails", "type"]) === "Private"
      ? safeQuery(selectedStaff, ["pricingOptionDetails"])
      : safeQuery(details, ["pricingOptionDetails"]);
  return (
    <div>
      {/* <Typography
        variant="h5"
        className={`${classes.spacingTop} ${classes.primaryText}`}
      >
        <b>Offerings</b>
      </Typography> */}
      <div style={{ paddingRight: 8, paddingTop: 24, paddingBottom: 8 }}>
        <Grid
          container
          spacing={2}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {pricingOptions
            ? pricingOptions.map((optionDetails, index) => (
                <PricingOptionRow
                  details={details}
                  setOptionChoice={props.setOptionChoice}
                  setShowAskLogin={props.setShowAskLogin}
                  optionDetails={optionDetails}
                  index={index}
                  key={`pricingOption-${safeQuery(details, [
                    "serviceDetails",
                    "serviceName",
                  ])}-${index}`}
                  currencyInfo={currencyInfo}
                  selectedStaff={selectedStaff}
                  z
                />
              ))
            : "no pricing options. please contact business"}
        </Grid>
      </div>
    </div>
  );
};

const PricingOptionRow = (props) => {
  const classes = useStyles();
  const bigEnough = useMediaQuery("(min-width: 750px)");
  const history = useHistory();
  const location = useLocation();
  const details = props.details;
  const staffDetails = props.selectedStaff;
  const optionDetails = props.optionDetails;
  const currencyInfo = props.currencyInfo;
  const loggedIn = useSelector((state) => state.token !== null);
  const queryParams = retrieveGetParams(window.location);

  if (optionDetails) {
    return (
      <React.Fragment>
        <Grid item xs={bigEnough ? 12 : 12} style={{ padding: 20 }}>
          <Grid
            container
            spacing={bigEnough ? 2 : 0}
            alignItems="center"
            className={classes.pricingCard}
            style={{ padding: 20 }}
          >
            <Grid item xs={12} sm={4}>
              <Typography
                variant="h5"
                className={`${classes.primary}`}
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                {optionDetails.name}
              </Typography>
              <Typography className={classes.textColor3} noWrap>
                {optionDetails.type === "Unit"
                  ? 1
                  : safeQuery(optionDetails, [
                      "optionSettings",
                      "sessionCount",
                    ])}{" "}
                {(optionDetails.type === "Unit"
                  ? 1
                  : safeQuery(optionDetails, [
                      "optionSettings",
                      "sessionCount",
                    ])) === 1
                  ? "Session"
                  : "Sessions"}
              </Typography>
              {optionDetails.type === "Pack" ? (
                <Typography className={classes.textColor3} noWrap>
                  Use within {optionDetails.optionSettings.validity}{" "}
                  {optionDetails.optionSettings.validityUnit}
                </Typography>
              ) : optionDetails.type === "Season Pass" ? (
                <LightTooltip
                  arrow
                  title={`Valid from ${optionDetails.optionSettings.startDateStr} to ${optionDetails.optionSettings.endDateStr}`}
                >
                  <Typography className={classes.textColor3} noWrap>
                    Valid from {optionDetails.optionSettings.startDateStr} to{" "}
                    {optionDetails.optionSettings.endDateStr}
                  </Typography>
                </LightTooltip>
              ) : null}
              {queryParams && queryParams.dateTime ? (
                <Typography className={classes.textColor3} noWrap>
                  {`${queryParams.dateTime}`}
                </Typography>
              ) : null}
              {optionDetails.installmentEnabled ? (
                <Typography className={classes.textColor3} noWrap>
                  Installment payment available
                </Typography>
              ) : null}
            </Grid>

            <Grid item xs={12} sm={4}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: bigEnough ? "center" : null,
                  width: "100%",
                }}
              >
                {safeQuery(optionDetails, ["discounts", "length"]) > 0 ? (
                  <React.Fragment>
                    <Typography className={classes.primaryText}>
                      <b>Applicable Discounts</b>
                    </Typography>
                    <TwoColumn
                      extProps={{ justifyContent: bigEnough ? "center" : null }}
                      firstItemProps={{
                        style: { maxWidth: "calc(100% - 54px)" },
                      }}
                      first={optionDetails.discounts.map((discount, index) => (
                        <LightTooltip
                          arrow
                          title={discount.name}
                          key={`${optionDetails.name} discount name ${index}`}
                        >
                          <Typography noWrap className={classes.textColor3}>
                            {discount.name}
                          </Typography>
                        </LightTooltip>
                      ))}
                      second={optionDetails.discounts.map((discount, index) => (
                        <Typography
                          className={classes.textColor2}
                          key={`${optionDetails.name} discount amount ${index}`}
                        >
                          {discount.percentage}%
                        </Typography>
                      ))}
                    />
                  </React.Fragment>
                ) : null}
                {optionDetails.addOns.length > 0 ? (
                  <AddOnModal
                    optionDetails={optionDetails}
                    currencyInfo={currencyInfo}
                  />
                ) : null}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              // style={{ height: 66 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: bigEnough ? "column" : "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {!bigEnough ? (
                  <Typography
                    variant="h6"
                    className={`${bigEnough ? null : classes.spacingTop} ${
                      classes.primaryText
                    }`}
                    gutterBottom={bigEnough}
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                  >
                    <CurrencyPreProcessor
                      amount={optionDetails.price}
                      currencyBreak={currencyInfo.currencyBreak}
                      roundingFactor={currencyInfo.roundingFactor}
                      currencySymbol={currencyInfo.currencySymbol}
                    />
                    {` ${currencyInfo.currencyShortName}`}
                  </Typography>
                ) : null}
                {bigEnough ? (
                  <Typography
                    variant="h6"
                    className={`${bigEnough ? null : classes.spacingTop} ${
                      classes.primaryText
                    }`}
                    gutterBottom={bigEnough}
                    style={{ fontWeight: "bold" }}
                  >
                    <CurrencyPreProcessor
                      amount={optionDetails.price}
                      currencyBreak={currencyInfo.currencyBreak}
                      roundingFactor={currencyInfo.roundingFactor}
                      currencySymbol={currencyInfo.currencySymbol}
                    />
                    {` ${currencyInfo.currencyShortName}`}
                  </Typography>
                ) : null}
                <Button
                  className={classes.purchaseButton}
                  style={{
                    height: 45,
                    ...(bigEnough
                      ? { width: "120px", float: "right" }
                      : { width: "120px", float: "right" }),
                    borderRadius: 10,
                  }}
                  variant="contained"
                  disabled={optionDetails.soldOut}
                  color="primary"
                  onClick={() => {
                    if (loggedIn) {
                      sendToCheckout(
                        { optionDetails, details, staffDetails },
                        history,
                        location
                      );
                    } else {
                      props.setShowAskLogin(true);
                      props.setOptionChoice({
                        optionDetails,
                        details,
                        staffDetails,
                      });
                    }
                  }}
                >
                  <Typography
                    style={{
                      textTransform: "none",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    variant="h5"
                  >
                    {optionDetails.soldOut ? "Sold Out" : "Purchase"}
                  </Typography>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return <div>No details for this pricing option</div>;
  }
};

const Title = (props) => {
  const classes = useStyles();
  const details = props.details;
  return (
    <React.Fragment>
      <Grid item xs={10} style={{ marginBottom: 10 }}>
        <LightTooltip arrow title={details.serviceDetails.name}>
          <Typography
            variant="h4"
            color="primary"
            style={{ fontWeight: "bold", fontSize: "24px" }}
          >
            {details.serviceDetails.name}
          </Typography>
        </LightTooltip>
      </Grid>
      <Grid item xs={12}>
        <LightTooltip arrow title={details.businessDetails.name}>
          <Link
            to={`/business/${details.businessDetails.businessId}`}
            className={classes.link}
          >
            <Typography className={classes.textColor2}>
              {details.businessDetails.name}
            </Typography>
          </Link>
        </LightTooltip>
      </Grid>
    </React.Fragment>
  );
};

const BasicDetails = (props) => {
  const [showPreReqs, setShowPreReqs] = useState(false);
  const [showReviews, setShowReviews] = useState(false);
  const classes = useStyles();
  const details = props.details;
  const bigEnough = props.bigEnough;
  return (
    <React.Fragment>
      <ViewPRModal
        open={showPreReqs}
        setOpen={setShowPreReqs}
        customerPreReqs={details?.serviceDetails?.qualifications}
        attendeePreReqs={details?.serviceDetails?.attendeeQualifications}
      />
      <ViewCRModal
        open={showReviews}
        setOpen={setShowReviews}
        offeringId={props.offeringId}
      />

      <Grid container style={{ marginTop: -20, }}>
        {bigEnough ? (
          <React.Fragment>
            <Grid item xs={12} className={classes.spacingTop2} />
            <Title details={details} />
          </React.Fragment>
        ) : null}
        <Grid container style={{ display: "flex", flexDirection: "column" }}>
          <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {details.reviewInfo.rating !== null ? (
                <Rating
                  name="read-only"
                  value={details.reviewInfo.rating}
                  readOnly
                  precision={0.1}
                />
              ) : null}
              {details.reviewInfo.totalReviews === 0 ? (
               null
              ) : (
                <Typography
                  display="inline"
                  className={`${classes.link} ${classes.primaryText}`}
                  style={{ marginLeft: 10 }}
                  onClick={() => setShowReviews(true)}
                >
                  {details.reviewInfo.totalReviews === 1
                    ? `${details.reviewInfo.totalReviews} Review `
                    : `${details.reviewInfo.totalReviews} Reviews`}
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={bigEnough ? 8 : 12}>
          <Grid item xs={12} className={classes.spacingTop} />
          {!details.serviceDetails.isVirtual ? (
            <Grid item xs={12}>
              <Grid container>
                <LocationOnIcon size="small" className={classes.textColor3} />
                <Typography
                  className={`${classes.link} ${classes.textColor3}`}
                  noWrap={bigEnough ? true: false}
                  style={{
                    paddingLeft: 10,
                    maxWidth: "calc(100% - 44px)",
                    color: "inherit",
                  }}
                >
                  <span
                    onClick={() => {
                      window.open(
                        "https://www.google.com/maps/search/?api=1&" +
                          encodeGetParams({
                            query: details.siteDetails.address1,
                          }),
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    {details.siteDetails.address1},{" "}
                    {details.siteDetails.address2}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Grid container>
              <PhoneIcon
                className={`${classes.textColor3} ${classes.listText}`}
                style={{ marginRight: 9 }}
              />

              <Typography
                noWrap
                className={`${classes.textColor3} ${classes.listText}`}
              >
                {details.businessDetails.phoneNumber}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <MailIcon className={classes.textColor3} />

              <Typography
                noWrap
                className={classes.textColor3}
                style={{ paddingLeft: 10, maxWidth: "calc(100% - 44px)" }}
              >
                <a
                  href={"mailto:" + details.businessDetails.email}
                  className={classes.link}
                  style={{ color: "inherit" }}
                >
                  {details.businessDetails.email}
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={bigEnough ? 4 : 12} className={classes.spacingTop}>
          <TwoColumn
            extProps={{
              spacing: 2,
            }}
            first={[
              <Typography
                key={`basic details left ${0}`}
                className={classes.textColor2}
                gutterBottom
              >
                Duration:
              </Typography>,
              <Typography
                key={`basic details left ${1}`}
                className={classes.textColor2}
                gutterBottom
              >
                Type:
              </Typography>,
              <Typography
                key={`basic details left ${2}`}
                className={classes.textColor2}
                gutterBottom
              >
                Mode:
              </Typography>,

              details?.serviceDetails?.qualifications?.length > 0 ||
              details?.serviceDetails?.attendeeQualifications?.length > 0 ? (
                <Typography
                  key={`basic details left ${3}`}
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPreReqs(true);
                  }}
                >
                  View pre-requisites
                </Typography>
              ) : (
                <br key={`basic details left ${3}`} />
              ),
            ]}
            second={[
              <Typography
                key={`basic details right ${0}`}
                className={classes.textColor3}
                gutterBottom
              >
                {safeQuery(details, ["serviceDetails", "duration"]) ? (
                  formatDuration(
                    safeQuery(details, ["serviceDetails", "duration"])
                  )
                ) : (
                  <br />
                )}
              </Typography>,
              <Typography
                key={`basic details right ${1}`}
                className={classes.textColor3}
                gutterBottom
              >
                {safeQuery(details, ["serviceDetails", "type"]) ? (
                  safeQuery(details, ["serviceDetails", "type"])
                ) : (
                  <br />
                )}
              </Typography>,
              <Typography
                key={`basic details right ${2}`}
                className={classes.textColor3}
                gutterBottom
              >
                {safeQuery(details, ["serviceDetails", "isVirtual"])
                  ? "Virtual"
                  : "In Person"}
              </Typography>,
            ]}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const StaffSelection = (props) => {
  const classes = useStyles();
  const { details, selectedStaff, setSelectedStaff, bigEnough } = props;
  const staffDetails = safeQuery(details, ["staffDetails"]);
  return (
    <React.Fragment>
      <div className={`${classes.spacingTop}`}>
        <Typography variant="h5" className={classes.primaryText}>
          <b>Select Instructor</b>
        </Typography>
      </div>
      {bigEnough ? (
        <StaffContainerDesktop
          initialMaxHeight={220}
          staffDetails={staffDetails}
          selectedStaff={selectedStaff}
          setSelectedStaff={setSelectedStaff}
        />
      ) : (
        <StaffContainerMobile
          staffDetails={staffDetails}
          selectedStaff={selectedStaff}
          setSelectedStaff={setSelectedStaff}
        />
      )}
    </React.Fragment>
  );
};

const StaffContainerMobile = (props) => {
  const [TE, setTE] = useState(false);
  const [expand, setExpand] = useState(false);
  const { staffDetails, selectedStaff, setSelectedStaff } = props;
  useEffect(() => {
    if (staffDetails.length >= 5) {
      setTE(true);
    }
  }, [staffDetails]);

  if (staffDetails.length > 1) {
    return (
      <Grid container direction="column">
        {(expand ? staffDetails : staffDetails.slice(0, 4)).map(
          (staff, index) => (
            <StaffButton
              bigEnough={false}
              key={`staff-${index}`}
              staff={staff}
              selected={
                selectedStaff && selectedStaff.staffId === staff.staffId
              }
              setSelectedStaff={setSelectedStaff}
            />
          )
        )}
        {TE ? (
          <Grid item xs={12} style={{ margin: "0 auto" }}>
            {expand ? (
              <Button
                onClick={() => {
                  setExpand(false);
                }}
              >
                <Typography>Show Less Instructors</Typography>
              </Button>
            ) : (
              <Button>
                <Typography
                  onClick={() => {
                    setExpand(true);
                  }}
                >
                  Show {staffDetails.length - 4} More Instructors
                </Typography>
              </Button>
            )}
          </Grid>
        ) : null}
      </Grid>
    );
  } else {
    return null;
  }
};

const StaffContainerDesktop = (props) => {
  const container = useRef(null);
  const original = useRef(null);
  const [TE, setTE] = useState(false);
  const [expand, setExpand] = useState(false);
  const { staffDetails, selectedStaff, setSelectedStaff } = props;
  useEffect(() => {
    const updateTE = () => {
      if (container.current) {
        setTE(container.current.scrollHeight > original.current.offsetHeight);
      }
    };
    updateTE();
  }, [props, container.current, original.current]);

  useEffect(() => {
    const updateTE = () => {
      if (container.current) {
        setTE(container.current.scrollHeight > original.current.offsetHeight);
      }
    };
    window.addEventListener("resize", updateTE);
    return () => window.removeEventListener("resize", updateTE);
  }, []);

  return (
    <React.Fragment>
      <div
        style={{ width: 0, position: "fixed", height: props.initialMaxHeight }}
        ref={original}
      />
      {staffDetails.length > 1 ? (
        <Grid
          container
          style={{
            maxHeight: expand ? null : props.initialMaxHeight,
            overflowY: "hidden",
          }}
          ref={container}
        >
          {staffDetails.map((staff, index) => (
            <StaffButton
              bigEnough={true}
              key={`staff-${index}`}
              staff={staff}
              selected={
                selectedStaff && selectedStaff.staffId === staff.staffId
              }
              setSelectedStaff={setSelectedStaff}
            />
          ))}
        </Grid>
      ) : null}
      <Grid container justifyContent="center">
        {TE ? (
          expand ? (
            <Button
              disableRipple
              color="primary"
              onClick={() => {
                setExpand(false);
              }}
            >
              <Typography>Show Less</Typography>
            </Button>
          ) : (
            <Button
              disableRipple
              color="primary"
              onClick={() => {
                setExpand(true);
              }}
            >
              <Typography>
                View All {staffDetails.length} Instructors
              </Typography>
            </Button>
          )
        ) : null}
      </Grid>
    </React.Fragment>
  );
};

const AddOnModal = (props) => {
  const { optionDetails, currencyInfo } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <React.Fragment>
      <Button
        size="small"
        onClick={() => {
          setOpen(true);
        }}
        className={classes.addOnButton}
        variant="contained"
        style={{ borderRadius: 15 }}
      >
        <Typography style={{ textTransform: "none" }}>
          <b>Additional Purchase Options</b>
        </Typography>
      </Button>

      <ViewAddOns
        setOpen={setOpen}
        open={open}
        addOns={optionDetails.addOns}
        currencyInfo={currencyInfo}
      />
    </React.Fragment>
  );
};

const StaffButton = (props) => {
  const { staff, selected, setSelectedStaff, bigEnough } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    if (!selected) {
      setSelectedStaff(staff);
    }
  };

  if (bigEnough) {
    return (
      <Grid
        item
        className={`${classes.staffContainer} ${
          selected ? classes.selectedStaff : null
        }`}
        style={{ paddingTop: 20, borderRadius: 15 }}
      >
        {selected ? (
          <CheckIcon
            className={classes.primaryIcon}
            style={{
              position: "absolute",
              zIndex: 1000,
              transform: "translate(250%, -300%)",
            }}
          />
        ) : null}
        <StaffModal
          open={open}
          setOpen={setOpen}
          classes={classes}
          setSelected={setSelectedStaff}
          bigEnough={bigEnough}
          selectedStaff={staff}
          selected={selected}
        />
        <Avatar
          onClick={handleClick}
          classes={{
            img: selected ? classes.selectedStaff : classes.notSelectedStaff,
          }}
          className={classes.staffImage}
          alt={`${staff.firstName} ${staff.lastName}`}
          src={formatSrc(staff.image, {
            desktopOptions: ["fit-in/300x300"],
          })}
        />
        <LightTooltip arrow title={`${staff.firstName} ${staff.lastName}`}>
          <Typography align="center" noWrap className={classes.primaryText}>
            {selected ? (
              <b>{`${staff.firstName} ${staff.lastName}`}</b>
            ) : (
              `${staff.firstName} ${staff.lastName}`
            )}
          </Typography>
        </LightTooltip>
        <Typography
          align="center"
          noWrap
          color="primary"
          className={classes.link}
        >
          <span
            onClick={() => {
              setOpen(true);
            }}
          >
            View Profile
          </span>
        </Typography>
      </Grid>
    );
  } else {
    return (
      <React.Fragment>
        <StaffModal
          open={open}
          setOpen={setOpen}
          classes={classes}
          setSelected={setSelectedStaff}
          bigEnough={bigEnough}
          selectedStaff={staff}
          selected={selected}
        />
        <Grid item xs={12} style={{ padding: 10 }}>
          <Grid container alignItems="center" spacing={2} onClick={handleClick}>
            <Grid item>
              <Avatar
                style={{ border: "1px solid black", boxSizing: "border-box" }}
                src={formatSrc(staff.image, {
                  desktopOptions: ["fit-in/300x300"],
                })}
              />
            </Grid>
            <Grid item style={{ width: "calc(100% - 112px)" }}>
              <LightTooltip
                arrow
                title={`${staff.firstName} ${staff.lastName}`}
              >
                <Typography noWrap className={classes.primaryText}>
                  {selected ? (
                    <u>
                      <b>{`${staff.firstName} ${staff.lastName}`}</b>
                    </u>
                  ) : (
                    `${staff.firstName} ${staff.lastName}`
                  )}
                </Typography>
              </LightTooltip>
            </Grid>
            <Grid item style={{ width: "56px" }}>
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <SubjectIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
};

export default OfferingDetails;
