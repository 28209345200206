import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {myBusinessDetails} from "../../../../utils";
import {Grid, CircularProgress, Avatar} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography } from "@material-ui/core";
import LightTooltip from "../../../Form/LightTooltip";
import CDisplayArea from "../../../Form/CDisplayArea";
import { TableContainer } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { Button } from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  mobilePadding: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px"
  },
  headingWrapper: {
    paddingBottom: "10px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "8px",
    paddingLeft: "10px",
  },
  fieldColumnOne: {
    width: "100px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  sdCustomCellOne: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "25%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "10%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width : "100%",
    height : "calc(100vh - 64px)"
  },
  loaderMain : {
    position : "absolute",
    top : "50%",
    left: "50%"
  },
  media: {
    height: 200,
    maxWidth: 200,
    objectFit: "cover"
  },
  avatar: {
    height: theme.spacing(20),
    width: theme.spacing(20),
  },
  formContainer:{
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding:"20px",
    maxWidth: "600px"
  }
}));

const PreferredBusiness = () => {
    const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [_, updateState] = useState(0);
  const large = useMediaQuery('(min-width: 960px)');
  const small = useMediaQuery('(min-width: 750px)');
  const [businessDetails, setBusinessDetails] = useState([]);

  const {businessIdURL} = useParams();

  useEffect(async () => {
      let data = null;
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    data = await myBusinessDetails({businessId: businessIdURL});
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if(data) {
        setBusinessDetails(data.serviceProviderDetails);
    }
  }, [_]);

  if(businessDetails && businessDetails.businessPhoneNumber) {
    return (
      <Grid container className={large ? classes.mainWrapper : classes.mobilePadding}>
        <Grid container item xs={12} direction="row">
          <Grid item xs={6}>
            <div className={classes.topBlock}>
              <span className={classes.topHeading}>My Business</span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.topBlock}>
              <Button onClick={() => {window.location.replace(businessDetails.businessPageURL)}}  className={[classes.buttonBasic, classes.rightElem]} style={{backgroundColor:"#0079fc", color:"white"}}>
                Book
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12} direction="row">
          <Grid item xs={12}>
            <div className={classes.headerPadding} style={{width:`calc(100%)`, paddingLeft:"0px"}}>
              <LightTooltip title={businessDetails.businessName} arrow>
                <Typography noWrap className={small ? classes.topHeadingOne : classes.mobileTopHeadingOne}>
                  {businessDetails.businessName}
                </Typography>
              </LightTooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={2}  style={large ? {paddingTop:"15px", paddingBottom:"15px", paddingLeft:"15px"} : {paddingTop:"25px"}} direction={small ? "row" : "column"}>
          <Grid item style={large ? {paddingLeft:"0px"} : {display: "flex", justifyContent:"center"}}>
              <div>
                {businessDetails.businessLogo ? 
                  <img className = {classes.media} src={businessDetails.businessLogo} alt="smth" /> 
                : 
                  <PersonIcon 
                  style={{ boxShadow:
                    " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
                  paddingLeft:"0px", height:"175px", width:"175px"}} className={classes.media} />
                }
              </div>
          </Grid>
          <Grid item style={large ? null : {paddingLeft:"0px"}}>
            <div className={classes.contentRow}>
                <div className={classes.fieldRows}>
                    <div className={classes.fieldColumnOne} style={{maxWidth:"50px", paddingBottom:"5px"}}>
                        <span className={classes.labelText} style={{whiteSpace:"normal"}}>Phone Number</span>{" "}
                    </div>
                    <div className={classes.fieldColumnTwo} style={{paddingLeft: "15px"}}>
                    <span className={classes.valueText} >
                    {`${businessDetails.businessPhoneNumber.slice(0,2)} (${businessDetails.businessPhoneNumber.slice(2,5)}) ${businessDetails.businessPhoneNumber.slice(5,8)}-${businessDetails.businessPhoneNumber.slice(8,12)}`}
                    </span>
                    </div>
                </div>
                <div className={classes.fieldRows}>
                    <div className={classes.fieldColumnOne} style={{maxWidth:"50px", paddingBottom:"5px"}}>
                        <span className={classes.labelText} style={{whiteSpace:"normal"}}>Email</span>{" "}
                    </div>
                    <div className={classes.fieldColumnTwo} style={{paddingLeft: "15px"}}>
                      <LightTooltip title="Email Business" arrow>
                        <span className={classes.valueText}>
                          <a style={{ color: "#0079fc" }} href={"mailto:" + businessDetails.businessEmail}>{businessDetails.businessEmail}</a>
                        </span>
                      </LightTooltip>
                    </div>
                </div>
                <div className={classes.fieldRows}>
                    <div className={classes.fieldColumnOne} style={{maxWidth:"50px", paddingBottom:"5px"}}>
                        <span className={classes.labelText} style={{whiteSpace:"normal"}}>Total Orders Booked</span>{" "}
                    </div>
                    <div className={classes.fieldColumnTwo} style={{paddingLeft: "15px"}}>
                        <span className={classes.valueText}>
                            {businessDetails.totalOrdersBooked}
                        </span>
                    </div>
                </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
            <div className={classes.headingWrapper}>
                <span className={classes.labelText}>About</span>
            </div>
        </Grid>
        <Grid item xs={12} style={{paddingTop:"10px"}}>
                <span className={classes.valueText}>
                  <CDisplayArea
                    value={businessDetails.aboutBusiness ? businessDetails.aboutBusiness : null}
                    initialMaxHeight={"200px"}
                  />
                </span>{" "}
        </Grid>
        {businessDetails.memberships.length != 0 && businessDetails.memberships.length !== 'undefined' ? ( <Grid container item xs={12} direction="row" className={classes.headerPadding}>
          <Grid item xs={12}>
            <span className={classes.subHeading} style={{paddingLeft:"0px"}}>Memberships</span>
          </Grid>
        </Grid>) : null}
        {businessDetails.memberships.length != 0 && businessDetails.memberships.length !== 'undefined' ? (<Grid item xs={12}>
          <div className={classes.contentRow}>
              <TableContainer style={{maxHeight:"231px", maxWidth:"100%"}}>
                <Table stickyHeader aria-label="simple table" style={{tableLayout:"fixed"}}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.headerPadding} style={{width:'calc(100%)'}}>
                        <Typography noWrap className={classes.labelText}>Name</Typography>
                      </TableCell>
                      <TableCell align="left" className={classes.headerPadding} style={{width:'calc(100%)'}}>
                        <Typography noWrap className={classes.labelText}>Description</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {businessDetails.memberships.map((membership) => {
                      return (
                        <TableRow key= {membership.membershipId}>
                          <TableCell align="left" className={classes.sdCustomCellOne}> 
                          <LightTooltip title={membership.name} arrow>
                            <Typography noWrap className={classes.valueText}>
                                {membership.name}
                            </Typography>
                          </LightTooltip>
                          </TableCell>
                          <TableCell align="left" className={classes.sdCustomCellOne}> 
                             <LightTooltip title={membership.description} arrow>
                                <Typography noWrap className={classes.valueText}>
                                    {membership.description}
                                </Typography>
                            </LightTooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
          </div>
        </Grid> ) : null}
        {businessDetails.qualifications.length != 0 && businessDetails.qualifications.length !== 'undefined' ? ( <Grid container item xs={12} direction="row" className={classes.headerPadding}>
          <Grid item xs={6}>
            <span className={classes.subHeading} style={{paddingLeft:"0px"}}>Qualifications</span>
          </Grid>
        </Grid>) : null}
        {businessDetails.qualifications.length != 0 && businessDetails.qualifications.length !== 'undefined' ? (<Grid item xs={12}>
          <div className={classes.contentRow}>
              <TableContainer style={{maxHeight:"231px", maxWidth:"100%"}}>
                <Table stickyHeader aria-label="simple table" style={{tableLayout:"fixed"}}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.headerPadding} style={{width:'calc(100%)'}}>
                        <Typography noWrap className={classes.labelText}>Name</Typography>
                      </TableCell>
                      <TableCell align="left" className={classes.headerPadding} style={{width:'calc(100%)'}}>
                        <Typography noWrap className={classes.labelText}>Description</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {businessDetails.qualifications.map((qualification) => {
                      return (
                        <TableRow key= {qualification.qualificationId}>
                          <TableCell align="left" className={classes.sdCustomCellOne}> 
                          <LightTooltip title={qualification.name} arrow>
                            <Typography noWrap className={classes.valueText}>
                                {qualification.name}
                            </Typography>
                          </LightTooltip>
                          </TableCell>
                          <TableCell align="left" className={classes.sdCustomCellOne}> 
                          <LightTooltip title={qualification.description} arrow>
                            <Typography noWrap className={classes.valueText}>
                                {qualification.description}
                            </Typography>
                          </LightTooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
          </div>
        </Grid>) : null}
        <Grid item xs={12}>
            <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return (
        <Grid container className={classes.mainWrapper}>
          <Grid item xs={12}>
            <div className={classes.loaderWrapper}>
              <CircularProgress className={classes.loaderMain}/>
            </div>
          </Grid>
        </Grid>
      );
  }
};

export default PreferredBusiness;
