import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react'

const ErrorTag = (props) => {
    return(
        <Snackbar
            anchorOrigin={{horizontal: "right",  vertical:"bottom"}}
            open={props.open}
            autoHideDuration={null}
            onClose={props.setOpen}
            action={props.setOpen}
        ><Alert variant="filled" severity="error">Error Message</Alert></Snackbar>
    )
}

export default ErrorTag;