import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AttendeeDetails from './AttendeeDetails';
import AttendeeList from './AttendeeList';

const AttendeeHandler = () => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const basePath = `/dashboard/${businessId}/Attendees`
    return (
        <Switch>
            <Route path={basePath} exact render={() => { return (<AttendeeList basePath={basePath} />) }} />
            <Route path={`${basePath}/:shareIdURL`} render={() => { return (<AttendeeDetails />); }} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
        </Switch>
    );
}

export default AttendeeHandler;