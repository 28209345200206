// @ts-check
import {
  InputAdornment,
  MenuItem,
  Popper,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useEffect, useRef, useState } from "react";
import { debounce, withLoading } from "../../utils";

const useStyles = makeStyles((theme) => ({
  formLabel: {
    fontSize: 14,
    //@ts-ignore
    color: theme.palette.textColor2.main,
  },
  fieldText: {
    fontSize: 16,
    // @ts-ignore
    color: theme.palette.primaryText.main,
    padding: "10px",
  },
}));

/**
 *
 * @param {string} searchTerm
 * @param {function({businessId:string, searchStr:string}):Promise<any>} api
 * @param {string | undefined} businessId
 * @param {function(any):any[]} getList
 * @returns {Promise<any[]>}
 */
const search = async (searchTerm, api, businessId = undefined, getList) => {
  if (searchTerm.length > 0) {
    try {
      const response = await withLoading(api, {
        businessId: businessId,
        searchStr: searchTerm,
      });
      if (!response.error) {
        return getList(response) ?? [];
      }
    } catch (error) {
      // Fail search silently
      return [];
    }
  }
  return [];
};

/** @type {function({style?: React.CSSProperties, api:function({businessId:string, searchStr:string}):Promise<any>, businessId?: string, getLabel: function(any):string, getList: function(any):any[], onclick: function(any):void }): import("react").ReactNode} */
const Search = (props) => {
  const classes = useStyles();
  const [input, setInput] = useState("");

  const anchorElRef = useRef(null);
  const menuRef = useRef(null);
  const mountedRef = useRef(false);

  /** @type {React.MutableRefObject<null | string>} */
  const timeoutRef = useRef(null);

  /** @type {[any[], React.Dispatch<React.SetStateAction<any[]>>]} */
  const [options, setOptions] = useState(Array());

  useEffect(() => {
    mountedRef.current = true;
    const listener = (window.onresize = () => {
      if (anchorElRef.current && menuRef.current) {
        // @ts-ignore
        menuRef.current.style.width = `${anchorElRef.current?.clientWidth}px`;
      }
    });
    return () => {
      mountedRef.current = false;
      window.removeEventListener("resize", listener);
    };
  }, []);

  const debouncedHandleInputChange = debounce(
    timeoutRef,
    (e, newValue) => {
      setInput(newValue);
      search(newValue, props.api, props.businessId, props.getList).then(
        (newOptions) => {
          if (mountedRef.current) {
            setOptions(newOptions);
          }
        }
      );
    },
    700
  );

  return (
    <div style={props.style}>
      <TextField
        fullWidth
        value={input}
        ref={anchorElRef}
        placeholder="Search"
        variant="outlined"
        onChange={(e) => {
          setInput(e.target.value);
          debouncedHandleInputChange(e, e.target.value);
        }}
        InputProps={{
          classes: { input: classes.fieldText },
          endAdornment:
            input.length > 0 ? (
              <InputAdornment position="end">
                <ClearIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setInput("");
                    setOptions([]);
                  }}
                />
              </InputAdornment>
            ) : null,
        }}
      />
      <Popper
        id="simple-menu"
        ref={menuRef}
        anchorEl={anchorElRef.current}
        open={options.length > 0}
        style={{
          // @ts-ignore
          width: `${anchorElRef.current?.clientWidth}px`,
          marginTop: "5px",
          border: "1px solid #D0D0D0",
          borderRadius: "4px",
          zIndex: 999,
          background: "white",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            style={{
              width: "100%",
              textTransform: "none",
              fontSize: "16px",
              fontFamily: "Rubik sans-serif",
            }}
            key={`${index} - option`}
            onClick={() => {
              props.onclick(option);
            }}
          >
            <Typography align="left">{props.getLabel(option)}</Typography>
          </MenuItem>
        ))}
      </Popper>
    </div>
  );
};

export default Search;
