import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Question from './Question';
import QuestionsList from './QuestionsList';
const QuestionsHandler = () => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    const basePath = `/dashboard/${businessId}/Questions`
    return (
        <Switch>
            <Route exact path = {basePath} render = {()=>(<QuestionsList basePath = {basePath}/>)}/>
            <Route exact path = {`${basePath}/:questionIdURL`} render = {()=>(<Question basePath = {basePath}/>)}/>
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
        </Switch>
    );
}

export default QuestionsHandler;