import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { operatingUnits, withLoading } from "../../../../../../utils";
import PricingOption from "./PricingOption";
import PricingOptionsList from './PricingOptionsList';

const PricingOptionsHandler = () => {
  const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
  const basePath = `/dashboard/${businessId}/Pricings`
  const [currencyInfo, setCurrencyInfo] = useState(null);
  const [_, updateState] = useState(0);
  const dispatch = useDispatch();
  const operatingDetails = async () => {
    let currency = await operatingUnits();
    if (currency) {
      setCurrencyInfo(currency);
    };
  };

  useEffect(async () => {
    await withLoading(operatingDetails,{})
  }, [_]);

  if (currencyInfo === null) {
    return (null)
  }
  else {
    return (
      <React.Fragment>
        <Switch>
          <Route path={`/dashboard/${businessId}/Pricings`} exact render={() => { return (<PricingOptionsList currencyInfo={currencyInfo} basePath = {basePath} />) }} />
          <Route path={`/dashboard/${businessId}/Pricings/:optionIdURL`} exact render={() => { return (<PricingOption currencyInfo={currencyInfo} />); }} />
          <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default PricingOptionsHandler;