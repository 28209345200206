import React, { useEffect, useState } from "react"
import { Container, makeStyles, Button } from "@material-ui/core"
import { useSelector } from "react-redux"
import { availableOptions, withLoading, changeOption } from "../../../../../../utils"
import CModal from "../../../../../Form/CModal"
import Form from "../../../../../Form/Form"
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        width: "100%",
        maxWidth: "600px",
        maxHeight: "80vh",
        overflowY: "auto"
    },
    buttonBasic: {
        backgroundColor: "#ffffff",
        color: "#1a1f36",
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
}))

const ChangeOrderOption = (props) => {
    const { orderId, fetchOrderDetails } = props
    const businessId = useSelector(state => state.buisnessDetails.businessId)
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const classes = useStyles()

    useEffect(() => {
        if (open) {
            setUp()
        }
        else {
            setOptions([])
        }
    }, [open])

    const setUp = async () => {
        const optionsResponse = await withLoading(availableOptions, {
            businessId,
            orderId
        })
        if (!optionsResponse?.error && Array.isArray(optionsResponse.availableOptions)) {
           setOptions(optionsResponse.availableOptions.map(optionObject=>[optionObject.name, optionObject.optionId]))
        }
    }

    return (
        <React.Fragment>
            <CModal open={open} setOpen={setOpen}>
                <Container className={classes.formContainer}>
                    <Form
                        moreData={{
                            businessId,
                            orderId
                        }}
                        title={`Change Order`}
                        cancelEnabled
                        cancelAction={() => { setOpen(false) }}
                        submitText={"Change"}
                        action={changeOption}
                        postAction={(response) => {
                            if(response && response.error === false){
                                setOpen(false)
                                fetchOrderDetails()
                            }
                        }}
                        inputFields={[
                            {
                                type: "SingleSelect",
                                name:"newOptionId",
                                label: "New Pricing Option",
                                validator: value => (!value ? "Must select an new option" : null),
                                choices:options
                            },
                            {
                                type: "CheckBox",
                                name:"enforceCapacity",
                                label: "Enforce Capacity",
                                initialvalue: true
                            }
                        ]}
                    />
                </Container>
            </CModal>
            <Button onClick={() => setOpen(true)} variant="outlined" className={classes.buttonBasic}>
                <EditIcon color="primary" />
            </Button>
        </React.Fragment>
    )
}

export default ChangeOrderOption