import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { sites, withLoading, retrieveGetParams } from "../../../../../../utils";
import { Grid, Typography, useMediaQuery, Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import ListTable from "./../../../../../TableComponents/ListTable";
import AddSiteForm from "./AddSiteForm";
import StatusTag from "../../../../../TagComponents/StatusTag";
import ListCard from "../../../../../MobileCards/ListCard";
import LightTooltip from "../../../../../Form/LightTooltip"
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import PostFilter, { applyFilters } from '../../../../../Filter/PostFilter'

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  buttonBasic: {
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  }
}));

const keyObjects = [{ key: 'isActive', lable: 'Status', keyLabel: 'statusName' }]

const SitesList = (props) => {
  const [add, setAdd] = useState(false);
  const history = useHistory();
  const error = useSelector(state => state.error);
  const errorMessage = useSelector(state => state.errorMessage);
  const classes = useStyles();
  const location = useLocation()
  const queryParams = retrieveGetParams(location)

  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const sitesHeaders = [
    {
      name: "status",
      columnKey: "status",
      columnWidth: "100px",
      type: "Normal"
    },
    {
      name: "name",
      columnKey: "name",
      columnWidth: "35%",
      tooltip: true
    },

    {
      name: "address",
      columnKey: "address",
      columnWidth: "65%",
      tooltip: true
    },
    {
      name: "time zone",
      columnKey: "timeZone",
      columnWidth: "150px",
    },
  ];

  const [sitesList, setSitesList] = useState(null);
  const bigEnough = useMediaQuery("(min-width:750px)")
  const processSitesData = (siteData) => {
    return siteData.map((site) => {
      let {
        siteId,
        name,
        isActive,
        address1,
        address2,
        timeZone
      } = site;

      let status = <StatusTag wrapperName={isActive ? "greenWrapper" : "redWrapper"} value={isActive ? "Active" : "Inactive"} />;
      let id = siteId;
      let address = (address2 ? `${address1}, ${address2}` : `${address1}`)

      return {
        id,
        status,
        statusName: isActive ? "Active" : "Not Active",
        name,
        address,
        timeZone,
        isActive
      };
    });
  };

  const ViewChanger = () => {
    if (bigEnough) {
      return (<React.Fragment>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <ListTable
              identifier={"sites"}
              tableHeaders={sitesHeaders}
              tableRows={applyFilters(sitesList, queryParams, keyObjects)}
              rowClick={navigateToSite}
            />

          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </React.Fragment>)
    }
    else {
      return (<Grid item xs={12} style={{ paddingTop: "10px" }}>
        {applyFilters(sitesList, queryParams, keyObjects).map((site) => {
          return (
            <ListCard
              action={() => { history.push(`${props.basePath}/${site.id}`); }}
              cardName={site.name}
              statusReplace={
                <Typography
                  noWrap
                  style={!site.isActive ? { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "red" } : { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "green" }}>
                  {site.isActive ? "Active" : "Inactive"}
                </Typography>
              }
              other={site.address} />
          )
        })}
      </Grid>)
    }

  }



  const getSitesDetails = async () => {
    let sitesPayload = await sites({
      businessId: businessId,
    });
    if (sitesPayload && sitesPayload.sites) {
      setSitesList(processSitesData(sitesPayload.sites));
    };
  };

  const navigateToSite = (siteId) => {
    history.push(`${props.basePath}/${siteId}`);
  };

  useEffect(async () => {
    withLoading(getSitesDetails, {}, true)
  }, []);

  if (sitesList === null) {
    return (null)
  }
  else {
    return (
      <React.Fragment>
        <AddSiteForm open={add} setOpen={setAdd} basePath={props.basePath} />
        <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
          <Grid item xs={12}>
            <div className={classes.headerPadding}>
              <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Sites</span>
              <LightTooltip title="Create New Site" arrow>
                <span className={classes.rightElem}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: '10px', textTransform: "none" }}
                    onClick={() => { setAdd(true); }}
                  >Add
                  </Button>
                </span>
              </LightTooltip>
              <PostFilter allEntries={sitesList} keyObjects={keyObjects} />
            </div>
          </Grid>

          {(Array.isArray(sitesList) && sitesList.length > 0 ?
            <ViewChanger />
            :
            <EmptyListImage name="Sites" />)}
        </Grid>
      </React.Fragment>
    );
  }


};

export default SitesList;

