import { Typography } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import CheckIcon from "@material-ui/icons/Check";
import React, { useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  comp: {
    width: "100%",
  },
  formLabel: {
    fontSize: 14,
    color: "#3C4257",
  },
  fieldText: {
    fontSize: 14,
    color: "#1A1F36",
    padding: "10px",
    paddingLeft: "31px",
  },
  showChoice: {
    fontSize: 14,
    color: "#1A1F36",
    padding: "10px",
  },
  helperText: {
    marginRight: 14,
    marginLeft: 14,
  },
  selected: {
    fontSize: 14,
    color: theme.palette.primary.main,
    padding: "10px",
  },
  check: {
    fontSize: 14,
    paddingRight: 7,
  },
  menuPaper: {
    maxHeight: 220,
  },
  noPadding: {
    padding: 0,
  },
}));

const CSingleSelect = (props) => {
  const [width, setWidth] = useState(null);
  const comp = useRef(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [choice, setChoice] = useState(
    props.initialvalue ? props.initialvalue : null
  );
  const handleChange = (event) => {
    setChoice(event.target.value);
  };
  useEffect(() => {
    if (props.initialvalue && props.initialvalue !== choice) {
      props.updateGlob?.(choice);
    } else if (props.initialvalue) {
      props.updateGlob?.(props.initialvalue);
    } else {
      props.updateGlob?.(undefined);
    }
  }, []);
  useEffect(() => {
    if (comp.current) {
      setWidth(comp.current.clientWidth);
    }
  }, [choice]);
  const intoInputProps =
    props.moreProps && props.moreProps.inputProps
      ? props.moreProps.inputProps
      : {};
  intoInputProps.className = classes.showChoice;
  const otherProps = { ...props.moreProps, inputProps: intoInputProps };
  if (props.enabled === false) {
    return null;
  } else {
    return (
      <div className={classes.comp} ref={comp}>
        <Typography className={classes.formLabel}>{props.label}</Typography>

        <Select
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          IconComponent={(props) => {
            return (
              <ArrowDropDownCircleIcon
                {...props}
                style={open ? { color: "#0079FC" } : null}
              />
            );
          }}
          error={
            typeof props.errortext == "string" && props.errortext !== ""
              ? true
              : false
          }
          className={classes.comp}
          variant="outlined"
          name={props.name}
          MenuProps={{
            // MenuListProps:
            // {
            //     style: { width: width - (props.choices.length >= 5 ? 20 : 0) }
            // },
            classes: { paper: classes.menuPaper },
            getContentAnchorEl: null,
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            transformOrigin: { vertical: "top", horizontal: "center" },
          }}
          renderValue={(selected) => {
            if (Array.isArray(props.choices)) {
              for (let i = 0; i < props.choices.length; i++) {
                if (props.choices[i][1] === selected) {
                  return props.choices[i][0];
                }
              }
            }
          }}
          value={choice}
          onChange={(event) => {
            props.updateGlob?.(event.target.value);
            handleChange(event);
            props.onchange(event);
          }}
          inputProps={{
            ...intoInputProps,
          }}
          {...otherProps}
          ref={props.toInput}
        >
          {(Array.isArray(props.choices) ? props.choices : []).map(
            (pair, index) => {
              return (
                <MenuItem
                  key={`${props.name}-${index}`}
                  value={pair[1]}
                  style={{ maxWidth: width, display: "block !important" }}
                  classes={{
                    root: classes.noPadding,
                  }}
                >
                  <Typography
                    className={
                      choice === pair[1] ? classes.selected : classes.fieldText
                    }
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {pair[1] === choice ? (
                      <CheckIcon className={classes.check} />
                    ) : null}
                    {pair[0]}
                  </Typography>
                </MenuItem>
              );
            }
          )}
        </Select>

        <FormHelperText
          className={classes.helperText}
          error={
            typeof props.errortext == "string" && props.errortext !== ""
              ? true
              : false
          }
        >
          {props.errortext}
        </FormHelperText>
      </div>
    );
  }
};

export default CSingleSelect;
