import React from 'react'
import Form from "../../../../../Form/Form"
import CModal from "../../../../../Form/CModal";
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import maxTextField from '../../../../../../FormValidation/maxTextField';
import { useSelector} from 'react-redux';
import { editSite } from '../../../../../../utils';

// import { editService} from '../../../../../../utils';
const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    width:"100%",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflowY: "auto"
  }
}))


const EditSiteForm = (props) => {

  const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()

  const refresh = (success) => {
    if (success) {
        props.setOpen(false)
        props.refresh()
    }
}

  return(
    <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main" className={classes.formContainer}>
                <Form
                    moreData={{ businessId: businessId, siteId: props.siteDetails.siteId }}
                    title="Edit Site"
                    cancelEnabled
                    cancelAction={() => { props.setOpen(false) }}
                    submitText="Save"
                    action={async (payload) => {
                      const newPayload = {
                        ...payload,
                        publicName: payload.publicName === "" ? null : payload.publicName,
                      };
                      return await editSite(newPayload);
                    }}
                    postAction={refresh}
                    inputFields={[
                        {
                            type: "TextField",
                            name: "name",
                            label: "Site Name",
                            validator: [notEmpty, noWhiteSpace, maxTextField],
                            initialvalue: props.siteDetails.name

                        },
                        {
                          type: "TextField",
                          name: "publicName",
                          label: "Public site name (optional)",
                          validator: [noWhiteSpace, maxTextField],
                          initialvalue: props.siteDetails.publicName
                        },

                    ]}
                />
            </Container>
        </CModal>
  )
}

export default EditSiteForm