import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getStaffList, withLoading } from "../../../../../../utils";
import { Grid, useMediaQuery, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ListTable from "./../../../../../TableComponents/ListTable";
import StatusTag from "../../../../../TagComponents/StatusTag";
import ListCard from "../../../../../MobileCards/ListCard";
import EmptyListImage from "../../../../../EmptyListImage/Empty";

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    rightElem: {
        float: "right",
    },
    buttonBasic: {
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingThree: {
        fontSize: "20px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeading: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    bottomSpacer: {
        height: "50px",
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%",
    },
}));

const DisplayAllStaff = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const businessId = props.businessId;
    const staffHeaders = [


        {
            name: "Status",
            columnKey: "status",
            columnWidth: "100px",
        },
        {
            name: "Staff Id",
            columnKey: "memberId",
            columnWidth: "200px",
        },
        {
            name: "Name",
            columnKey: "name",
            columnWidth: "50%",
            tooltip: true,
        },

    ];

    const [staffsList, setStaffsList] = useState(null);

    const bigEnough = useMediaQuery("(min-width:750px)");
    const processStaffsData = (staffsData) => {
        return staffsData.map((staff) => {
            let { staffId, firstName, lastName, isActive, memberId } = staff;

            let status = (
                <StatusTag
                    wrapperName={isActive ? "greenWrapper" : "redWrapper"}
                    value={isActive ? "Active" : "Inactive"}
                />
            );
            let id = staffId;
            let name = `${firstName} ${lastName}`;

            return {
                id,
                status,
                name,
                memberId,
                isActive,
            };
        });
    };

    const getStaffsDetails = async () => {
        let staffsPayload = await getStaffList({
            businessId: businessId,
        });
        if (Array.isArray(staffsPayload)) {
            setStaffsList(processStaffsData(staffsPayload));
        }
    };

    const navigateToSite = (staffId) => {
        history.push(`/dashboard/${businessId}/Staff-Schedules/${staffId}`);
    };

    useEffect(async () => {
        await withLoading(getStaffsDetails, {}, true)
    }, []);

    const ViewChanger = () => {
        if (bigEnough) {
            return (
                <React.Fragment>
                    <Grid item xs={12}>
                        <div className={classes.contentRow}>
                            <ListTable
                                identifier={"staffs"}
                                tableHeaders={staffHeaders}
                                tableRows={staffsList}
                                rowClick={navigateToSite}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.bottomSpacer}></div>
                    </Grid>
                </React.Fragment>
            );
        } else {
            return (
                <Grid item xs={12} style={{ paddingTop: "10px" }}>
                    {staffsList.map((staff) => {
                        return (
                            <ListCard
                                action={() => {
                                    history.push(
                                        `/dashboard/${businessId}/Staff-Schedules/${staff.id}`
                                    );
                                }}
                                cardName={staff.name}
                                statusReplace=
                                {
                                    <Typography
                                        noWrap
                                        style={!staff.isActive ? { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "red" } : { fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "green" }}>
                                        {staff.isActive ? "Active" : "Inactive"}
                                    </Typography>
                                }
                                other={staff.memberId}
                            />
                        );
                    })}
                </Grid>
            );
        }
    };

    if (staffsList !== null) {
        return (
            <React.Fragment>
                <Grid
                    container
                    className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
                >
                    <Grid item xs={12}>
                        <div className={classes.headerPadding}>
                            <span
                                className={
                                    bigEnough ? classes.topHeading : classes.mobileTopHeadingOne
                                }
                            >
                                Staff Schedules
                            </span>
                        </div>
                    </Grid>
                    {Array.isArray(staffsList) && staffsList.length > 0 ? (
                        <ViewChanger />
                    ) : (
                        <EmptyListImage name = "Staff Schedules"/>
                    )}
                </Grid>
            </React.Fragment>
        );
    } else {
        return (null);
    }
};

export default DisplayAllStaff;
