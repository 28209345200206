import React, { useState, useEffect } from "react";
import { makeStyles, Button, useMediaQuery } from "@material-ui/core";
import CDisplayArea from "../Form/CDisplayArea";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { withLoading } from "../../utils";
import { cOBusinessInfo } from "../../utils";
import { cOBusinessInfoAuth } from "../../utils";
import { onboardCustomer } from "../../utils";
import { useHistory, useParams } from "react-router";
import LightTooltip from "../Form/LightTooltip";
import { Typography } from "@material-ui/core";
import COForm from "./COForm";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "500px",
        minHeight: "calc(100vh - 64px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    topHeading: {
        fontSize: "16px",
        color: "#1a1f36",
        paddingLeft: 20,
        fontWeight: 600
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    mobilePadding: {
        margin: "auto",
        maxWidth: "1440px",
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
        alignItems: "center",
        justifyContent: "center"
    },
    mainWrapper: {
        margin: "auto",
        maxWidth: "1440px",
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
        alignItems: "center",
        justifyContent: "center"
    },
    headerPadding: {
        padding: "10px",
    },
    topHeadingOne: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    media: {
        height: 200,
        width: 200,
        objectFit: "contain",
        backgroundColor: "#F7FAFC",
        borderRadius: 4,
        border: "1px solid rgb(233, 236, 237)"
    },
    buttonBasic: {
        backgroundColor: "#ffffff",
        color: "#1a1f36",
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    labelText: {
        fontSize: "14px",
        color: "#697386",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextLink: {
        fontSize: "14px",
        color: "#0079fc",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    subHeading: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    fieldRows: {
        paddingTop: "8px"
    },
    fieldColumnOne: {
        width: "100px",
        display: "inline-block",
    },
    fieldColumnTwo: {
        display: "inline-block",
    },
    formContainer: {
        border: '0px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "0px",
        minWidth: "350px",
        maxWidth: "600px"
    }

}))

const CustomerOnboardingForm = (props) => {
    const [_, updateState] = useState(0);
    const history = useHistory();
    const error = useSelector(state => state.error);
    const errorMessage = useSelector(state => state.errorMessage);
    const dispatch = useDispatch();
    const small = useMediaQuery('(min-width: 750px)');
    const large = useMediaQuery('(min-width: 960px)');
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [initialState, setInitialState] = useState(false);
    const [oBDetails, setOBDetails] = useState(null);
    const { businessIdURL } = useParams();



    const customerOB = async () => {
        dispatch({
            type: "UPDATE",
            payload: {
                loading: true,
            },
        });
        let success = await onboardCustomer({
            businessId: businessIdURL
        })
        dispatch({
            type: "UPDATE",
            payload: {
                loading: false,
            },
        });
        if (success) {
            history.replace(`/business/${businessIdURL}`);
        }
    }

    useEffect(async () => {
        let data = null;
        if (!props.loggedIn) {
            data = await withLoading(cOBusinessInfo, {
                businessId: businessIdURL,
            }, true)
        } else {
            data = await withLoading(cOBusinessInfoAuth, {
                businessId: businessIdURL,
            }, true)
        }
        if (data.obInfo) {
            setOBDetails(data.obInfo);
            setInitialState(true);
        }
    }, [_]);

    if (initialState) {
        return (
            <React.Fragment>
                {/* <CModal open={l} setOpen={setL} children={
                    <Container style={{display:"flex", maxHeight:"410px", alignItems:"center"}} component="main" className={classes.formContainer}>
                        <Login 
                            cOForm={true} 
                            setSU={() => {change("S")}}
                            setFP={() => {change("F")}} 
                            oB={true} 
                            sB={() => {setL(false); updateState((_) => !_)}} 
                         />
                    </Container>
                } />
                <CModal open={signUp} setOpen={setSignUp} children={
                    <Container style={{display:"flex", height:"100%", paddingTop:"20px", paddingBottom:"20px", alignItems:"center"}} component="main" className={classes.formContainer}>
                        <SignUp
                            cOForm ={true}
                            setL={() => {change("L")}}
                         />
                    </Container>
                } />
                <CModal open={forgotPass} setOpen={setForgotPass} children={
                    <Container style={{display:"flex", maxHeight:"480px", alignItems:"center"}} component="main" className={classes.formContainer}>
                        <ForgotPassword
                            setSU = {() => {change("S")}}
                            setL = {() => {change("L")}}
                            cOForm={true}
                        />
                    </Container>
                } /> */}
                <COForm
                open = {open}
                setOpen = {setOpen}
                />
                <Grid container xs={12} className={small ? classes.mainWrapper : classes.mobilePadding}>
                    <Grid item xs={12} className={classes.headingWrapper}>
                        <span className={classes.topHeading} style={small ? null : { fontSize: "11px" }}>
                            {`${oBDetails.name} is inviting you to become their customer`}
                        </span>
                    </Grid>
                    <Grid container item xs={12} direction="column" spacing={2} className={classes.headerPadding} style={{ paddingTop: "10px" }}>
                        <Grid container item spacing={2} direction={small ? "row" : "column"}>
                            <Grid item xs={large ? 3 : !large && small ? 4 : null} style={small ? null : { display: "flex", justifyContent: "center" }}>
                                <img className={classes.media} src={oBDetails.logo} alt="smth" />
                            </Grid>
                            <Grid container xs={large ? 9 : !large && small ? 8 : null} item direction="column" style={small ? { display: "flex", justifyContent: "center" } : null}>
                                <Grid
                                    item
                                    style={small ? null : { display: "flex", justifyContent: "center" }}
                                >
                                    <LightTooltip title="Business Page" arrow>
                                        <Typography
                                            className={small ? classes.topHeadingOne : classes.mobileTopHeadingTwo}
                                            style={{ color: "#0079fc", cursor: "pointer" }}
                                            onClick={() => { history.push("/business/" + businessIdURL) }}
                                        >
                                            {oBDetails.name}
                                        </Typography>
                                    </LightTooltip>
                                </Grid>
                                <Grid item>
                                    <div className={classes.contentRow} style={{ paddingBottom: "0px" }}>
                                        <div className={classes.fieldRows}>
                                            <div className={classes.fieldColumnOne} style={{ maxWidth: "93px" }}>
                                                <span className={classes.labelText} style={{ whiteSpace: "normal" }}>
                                                    Phone Number
                                                </span>
                                            </div>
                                            <div className={classes.fieldColumnTwo} style={{ paddingLeft: "5px" }}>
                                                <span className={classes.valueText}>
                                                    {`${oBDetails.phoneNumber.slice(0, 2)} (${oBDetails.phoneNumber.slice(2, 5)}) ${oBDetails.phoneNumber.slice(5, 8)}-${oBDetails.phoneNumber.slice(8, 12)}`}
                                                </span>
                                            </div>
                                        </div>
                                        <div className={classes.fieldRows}>
                                            <div className={classes.fieldColumnOne}>
                                                <span className={classes.labelText}>
                                                    Email
                                                </span>
                                            </div>
                                            <div className={classes.fieldColumnTwo}>
                                                <LightTooltip title="Email Business" arrow>
                                                    <span className={classes.valueTextLink}>
                                                        <a href={"mailto:" + oBDetails.email}>{oBDetails.email}</a>
                                                    </span>
                                                </LightTooltip>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        {small ? null : <Grid item className={classes.headingWrapper} style={{ paddingTop: "0px" }}>
                            <span className={small ? null : classes.labelText}>
                                About
                            </span>
                        </Grid>}
                        <Grid item>
                            <span className={classes.valueText}>
                                <CDisplayArea value={oBDetails.about} initialMaxHeight={"200px"} />
                            </span>
                        </Grid>
                        <Grid container justifyContent="center" item className={classes.headerPadding}>
                            <Button onClick={props.loggedIn ? customerOB : () => { setOpen(true); }} className={classes.buttonBasic} style={{ backgroundColor: "#0079fc", color: "white" }}>
                                {props.loggedIn ? "Become a Customer" : "Log-in to Become a Customer"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    } else { return (null) }
}

export default CustomerOnboardingForm;