import { Typography, Button, makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import CurrencyPreProcessor from "../CurrencyFormatter/CurrencyFormatter"
import ListTable from "../TableComponents/ListTable"
import CancelPayment from "./CancelPayment"
import MakePayment from "./MakePayment"




const tableHeaders = [
    {
        name: "date",
        columnKey: "date",
        columnWidth: "100px",
        type: "Typography",
        tooltip: true
    },
    {
        name: "amount",
        columnKey: "amount",
        columnWidth: "70px",
        type: "Typography",
        tooltip: true
    },
    {
        name: "status",
        columnKey: "status",
        columnWidth: "70px",
        type: "Normal",
    },
    {
        name: "",
        columnKey: "action",
        columnWidth: "100%",
        type: "Normal",
    }
]

const useStyles = makeStyles(theme => ({
    buttonText: {
        fontSize: 14,
        textTransform: "none"
    }
}))

const InstallmentSchedule = (props) => {
    const classes = useStyles()
    const { orderDetails, businessId, refresh } = props
    const [open, setOpen] = useState(null)
    return (
        <React.Fragment>
            {businessId ?
                <CancelPayment
                    open={open}
                    setOpen={setOpen}
                    orderId={orderDetails.orderId}
                    businessId={businessId}
                    refresh={refresh}
                />
                :
                <MakePayment
                    open={open}
                    setOpen={setOpen}
                    orderDetails={orderDetails}
                    refresh={refresh}
                />
            }
            <ListTable
                identifier={"installment_schedule"}
                tableHeaders={tableHeaders}
                tableRows={orderDetails.paymentPlan.map(payment => ({
                    date: payment.transactionDateStr,
                    amount: CurrencyPreProcessor({
                        amount: payment.amount,
                        ...orderDetails.currencyInfo
                    }),
                    status: (
                        <Typography style = {{fontSize:14, color:payment.status === "Completed"?"green":payment.status === "Failed"?"red":"black"}}>
                            {payment.status}
                        </Typography>
                    ),
                    action: (payment.status === "Scheduled" && businessId) || payment.status === "Failed" ?
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setOpen(payment.planId)
                            }}
                        >
                            <Typography className={classes.buttonText}>
                                {businessId ? "Cancel" : "Pay Now"}
                            </Typography>
                        </Button> : null
                }))}
            />
        </React.Fragment>
    )
}

export default InstallmentSchedule