import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Fragment } from "react";
import SessionDialog from "../../../../../CalendarDialogs/sessionDialog";
import ClassDialog from "../../../../../CalendarDialogs/classDialog";
import InternalEventDialog from "../../../../../CalendarDialogs/internalEventDialog";
import FilterDialog from "../../../../../CalendarDialogs/filterDialog";
import BusinessCalendar from "../../../../../CalendarComponent/calendarComponent";
import { businessCalendarEvents } from "../../../../../../utils";
import { getGenericDetails } from "../../../../../../utils";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";

const useStyles = makeStyles((theme) => ({
  calendarBox: {
    width: "100%",
    maxHeight: "calc(100vh - 64px)",
    minHeight: "calc(100vh - 64px)"
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

const BusinessCalendarHandler = () => {
  const classes = useStyles();
  const history = useHistory();
  const small = useMediaQuery('(min-width:960px)');
  const [anchorEl, setAnchorEl] = useState(null);
  const [view, setView] = useState("listWeek");
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });
  const role = useSelector((state) => {
    return state.buisnessDetails.role;
  });

  const [initialLoadComplete, setInitialLoad] = useState(false);
  const [openSession, setOpenSession] = useState(false);
  const [openClass, setOpenClass] = useState(false);
  const [openInternal, setOpenInternal] = useState(false);
  const [sessionInfo, setSessionInfo] = useState(null);
  const [classInfo, setClassInfo] = useState(null);
  const [internalInfo, setInternalInfo] = useState(null);
  const [filterValue, setFilterValue] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterMode, setFilterMode] = useState(false);
  const [sessionId, setSessionId] = useState(false);
  const [classId, setClassId] = useState(false);
  const [internalEventId, setInternalEventId] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const [serviceIds, setServiceIds] = useState([]);
  const [siteIds, setSiteIds] = useState([]);
  const [staffIds, setStaffIds] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [statusTypes, setStatus] = useState([]);
  const [modeTypes, setModeTypes] = useState([]);

  const baseFieldSetRef = [
    setServiceIds,
    setSiteIds,
    setStaffIds,
    setServiceTypes,
    setStatus,
    setModeTypes,
  ];

  const [serviceIdsOption, setServiceIdsOption] = useState([]);
  const [siteIdsOption, setSiteIdsOption] = useState([]);
  const [staffIdsOption, setStaffIdsOption] = useState([]);
  const [serviceTypesOption, setServiceTypesOption] = useState([]);
  const [statusTypesOption, setStatusTypesOption] = useState([]);
  const [modeTypesOption, setModeTypesOption] = useState([]);

  const dispatch = useDispatch();

  const filterList = [
    "serviceIds",
    "siteIds",
    "staffIds",
    "serviceTypes",
    "status",
    "mode"
  ]

  const pullGenericDetails = async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    let genDetails = await getGenericDetails({ businessId: businessId });
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
    if (genDetails) {
      setServiceIdsOption(genDetails.genericDetails.serviceDetails.map((service) => {
        return {
          id: service.serviceId,
          name: service.name
        }
      }));
      setSiteIdsOption(genDetails.genericDetails.siteDetails.map((site) => {
        return {
          id: site.siteId,
          name: site.name
        }
      }));
      setStaffIdsOption(genDetails.genericDetails.staffDetails.map((staff) => {
        return {
          id: staff.staffId,
          name: `${staff.firstName} ${staff.lastName}`
        }
      }));
      setServiceTypesOption([
        {
          id: "Private",
          name: "Private",
        },
        {
          id: "Group",
          name: "Group",
        },
      ]);

      setStatusTypesOption([
        {
          id: "Scheduled",
          name: "Scheduled",
        },
        {
          id: "Canceled",
          name: "Canceled",
        },
      ]);

      setModeTypesOption([
        {
          id: "In Person",
          name: "In Person",
        },
        {
          id: "Virtual",
          name: "Virtual",
        },
      ]);
    }
    setInitialLoad(true);
  };

  useEffect(() => {
    pullGenericDetails();
  }, []);

  const getBisinessCalendarEvents = useCallback(async (calProp) => {
    try {
      let calEvents = await businessCalendarEvents({
        businessId: businessId,
        startStr: calProp.startStr,
        endStr: calProp.endStr,
        serviceIds,
        siteIds,
        staffIds,
        serviceTypes,
        statusTypes,
        modeTypes,
      });
      return calEvents.events;
    } catch (error) {
      console.error(error);
    }
  }, [serviceIds, siteIds, staffIds, serviceTypes, statusTypes, modeTypes]);

  const eventClicked = useCallback((eventClickInfo) => {
    try {
      if (eventClickInfo.event.extendedProps.type === "Session") {
        setOpenSession(true);
        setSessionId(eventClickInfo.event.id);
        setSessionInfo(eventClickInfo.event.extendedProps);
      } else if (eventClickInfo.event.extendedProps.type === "Class") {
        setOpenClass(true);
        setClassId(eventClickInfo.event.id);
        setClassInfo(eventClickInfo.event.extendedProps);
      } else if (eventClickInfo.event.extendedProps.type === "Internal") {
        setOpenInternal(true);
        setInternalEventId(eventClickInfo.event.id);
        setInternalInfo(eventClickInfo.event.extendedProps);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const sessionDetails = () => {
    history.push(`/dashboard/${businessId}/Sessions/${sessionId}`);
  };

  const classDetails = () => {
    history.push(`/dashboard/${businessId}/Group-Classes/${classId}`);
  };

  const internalDetails = () => {
    history.push(`/dashboard/${businessId}/Internal-Events/${internalEventId}`);
  };

  const openFilterDialog = useCallback(() => {
    setFilterMode(true);
    setOpenFilter(true);
  }, []);

  const dropdownOpen = useCallback(() => {
    setDropdownIsOpen(true);
    setAnchorEl(true);
  }, []);

  const applyFilter = (dialogFieldRef) => {
    baseFieldSetRef[0](dialogFieldRef.serviceIds ? dialogFieldRef.serviceIds : serviceIdsOption);
    baseFieldSetRef[1](dialogFieldRef.siteIds ? dialogFieldRef.siteIds : siteIdsOption);
    baseFieldSetRef[2](dialogFieldRef.staffIds ? dialogFieldRef.staffIds : staffIdsOption);
    baseFieldSetRef[3](dialogFieldRef.serviceTypes ? dialogFieldRef.serviceTypes : serviceTypesOption);
    baseFieldSetRef[4](dialogFieldRef.status ? dialogFieldRef.status : statusTypesOption);
    baseFieldSetRef[5](dialogFieldRef.mode ? dialogFieldRef.mode : modeTypesOption);
    setOpenFilter(false);
    setFilterMode(false);
    setFilterApplied(true);
    let num = 0;
    if (filterValue === 0) {
      filterList.map((item) => {
        if (dialogFieldRef[item].length > 0) {
          num = num + 1;
        }
        if (item === "mode") {
          setFilterValue(num)
        }
      })
    } else {
      filterList.map((item) => {
        if (dialogFieldRef[item].length > 0) {
          num = num + 1;
        }
        if (item === "mode") {
          setFilterValue(num)
        }
      })
    }

  };

  const clearFilter = () => {
    for (let i = 0; i < baseFieldSetRef.length; i++) {
      baseFieldSetRef[i]([]);
    };
    setOpenFilter(false);
    setFilterMode(false);
    setFilterApplied(false);
    setFilterValue(0);
  }

  return (
    <Fragment>
      <Grid container className={small ? classes.mainWrapper : classes.mobilePadding}>
        <Grid item xs={12}>
          <BusinessCalendar
            getEvents={getBisinessCalendarEvents}
            eventClick={eventClicked}
            openFilterDialog={openFilterDialog}
            clearFilter={clearFilter}
            filterNum={filterValue}
            filters={isFilterApplied}
            dropdown={dropdownOpen}
            setView={setView}
            view={view}
          />
        </Grid>
      </Grid>
      {sessionInfo ? (
        <SessionDialog
          open={openSession}
          sessionDetails={sessionDetails}
          sessionInfo={sessionInfo}
          close={() => {
            setOpenSession(false);
          }}
        />
      ) : (
        <div></div>
      )}

      {classInfo ? (
        <ClassDialog
          open={openClass}
          classDetails={classDetails}
          classInfo={classInfo}
          close={() => {
            setOpenClass(false);
          }}
        />
      ) : (
        <div></div>
      )}

      {internalInfo ? (
        <InternalEventDialog
          open={openInternal}
          internalDetails={internalDetails}
          internalInfo={internalInfo}
          close={() => {
            setOpenInternal(false);
          }}
        />
      ) : (
        <div></div>
      )}

      {initialLoadComplete && filterMode ? (
        <FilterDialog
          open={openFilter}
          applyFilter={applyFilter}
          filters={isFilterApplied}
          clearFilter={isFilterApplied ? clearFilter : null}
          role={role}
          fieldOptions={[
            serviceIdsOption,
            siteIdsOption,
            staffIdsOption,
            serviceTypesOption,
            statusTypesOption,
            modeTypesOption,
          ]}
          isFilterApplied={isFilterApplied}
          currentFilterVal={[
            serviceIds,
            siteIds,
            staffIds,
            serviceTypes,
            statusTypes,
            modeTypes,
          ]}
          close={() => {
            setOpenFilter(false);
            setFilterMode(false);
          }}
        />
      ) : (
        <div></div>
      )}
    </Fragment>
  );
};

export default BusinessCalendarHandler;
