import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Route, Switch, useHistory, useLocation, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import routes from './routes'
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Typography } from '@material-ui/core';
const re = new RegExp(/ /, 'g')
const drawerWidth = '250px'
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.accent2.main,
    border: "none"
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#ffffff",
  },
  selected: {
    color: theme.palette.primary.main,

  },
  unSelected: {
    color: theme.palette.primaryText.main,
  },
  listItem: {
    padding: '5px',
  },
  iconButton: {

    position: "fixed",
    zIndex: theme.zIndex.drawer + 2,
    top: 7,
    left: 7
  },
  icon: {
    color: theme.palette.primaryText.main
  },
  text: {
    paddingRight: "10px",
  },
  drawerIcon: {
    paddingLeft: "0px",
    justifyContent: "center",
  },
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius:"3px"
  },
  businessName: {
    fontSize: "18px"
  },
  choices: {
    fontSize: "14px"
  }


}));

const generateRoutes = (routesList, businessId, businessDetails) => {
  const temp = [];
  routesList.forEach((routeObject) => {
    if (routeObject.type === "route" && (routeObject.req !== undefined ? routeObject.req(businessDetails) : true)) {
      temp.push({
        key: routeObject.route,
        path: `/dashboard/${businessId}/${routeObject.route.replaceAll(re, '-')}`,
        render: () => { return (routeObject.comp) }
      })
    }
    else if (routeObject.type === "bucket" && (routeObject.req !== undefined ? routeObject.req(businessDetails) : true)) {
      temp.push(...generateRoutes(routeObject.comp, businessId))
    }
  })
  return (temp)
}

const DrawerItem = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation()

  const currentSection = location.pathname.split('/')[3];
  if (props.type === "route" && (props.req !== undefined ? props.req(props.businessDetails) : true)) {
    return (
      <ListItem button className={classes.listItem} onClick={() => { history.push(`/dashboard/${props.businessId}/${props.route.replaceAll(re, '-')}`) }}>
        <ListItemIcon className={[(currentSection === props.route.replaceAll(re, '-') ? classes.selected : classes.unSelected), classes.drawerIcon].join(' ')}>
          {props.icon}
        </ListItemIcon>
        <ListItemText className={currentSection === props.route.replaceAll(re, '-') ? classes.selected : classes.unSelected} >
          <Typography className={classes.choices}>
            {props.route}
          </Typography>
        </ListItemText>
      </ListItem>
    );
  }
  else if (props.type === "bucket" && (props.req !== undefined ? props.req(props.businessDetails) : true)) {
    return (
      <React.Fragment>
        <ListItem
          className={classes.listItem}
          button
          onClick={() => {
            if (props.open === props.route) {
              props.setOpen(null)
            }
            else {
              props.setOpen(props.route)
            }
          }}
        >
          <ListItemIcon className={[(props.comp.map((routeObject) => { return (routeObject.route.replaceAll(re, '-')) }).includes(currentSection)
            ? classes.selected : classes.unSelected), classes.drawerIcon].join(' ')}>
            {props.icon}
          </ListItemIcon>
          <ListItemText className={classes.unSelected}>
            <Typography className={classes.choices}>
              {props.route}
            </Typography>
          </ListItemText>
        </ListItem>

        <Collapse timeout="auto" unmountOnExit in={props.open === props.route}>
          <List component="div" disablePadding>
            {props.comp.map((route) => {
              return (
                <DrawerItem key={`RouteLink: ${route.route}`} {...route} businessId={props.businessId} />
              )
            })}
          </List>
        </Collapse>
      </React.Fragment>
    )
  }
  else {
    return (null)
  }
}


const AdminDrawer = (props) => {
  const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
  const businessDetails = useSelector(state => state.buisnessDetails)
  const location = useLocation()
  const [open, setOpen] = useState(location.pathname.split('/')[3]);

  return (
    routes.map((route) => {
      return (
        <DrawerItem key={`RouteLink: ${route.route}`} req = {route.req} {...route} businessId={businessId} setOpen={setOpen} open={open} businessDetails = {businessDetails}/>
      )
    })
  );
}

const AdminDashboard = () => {
  const bigEnough = useMediaQuery('(min-width:750px)');
  const width = (bigEnough ? `calc(100% - ${drawerWidth})` : `calc(100%)`)
  const businessDetails = useSelector(state => state.buisnessDetails)
  const location = useLocation()
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
  const businessName = useSelector((state) => { return (state.buisnessDetails.businessName) })
  const businessLogo = useSelector((state) => { return (state.buisnessDetails.logo) })

  useEffect(() => {
    setOpen(false)
  }, [location, bigEnough])

  return (

    <div style={{ display: 'flex' }}>

      <Drawer
        className={classes.drawer}
        open={(!bigEnough ? open : null)}
        onClose={() => { setOpen(false) }}
        variant={(!bigEnough ? 'temporary' : "permanent")}
        classes={{
          paper: classes.drawer
        }}
      >
        {(bigEnough ? <Toolbar /> : null)}
        <List>
          <ListItem className={classes.listItem}>
            {(businessLogo ?
              <ListItemIcon
                className={classes.drawerIcon}
              >
                <img className={classes.avatar} src={businessLogo} alt = {""}/>
              </ListItemIcon> : null)}
            <ListItemText className={[classes.unSelected, classes.text].join(' ')} >
              <Typography noWrap align={(businessLogo ? "left" : "center")} className={classes.businessName}>
                <b>{businessName}</b>
              </Typography>
            </ListItemText>
          </ListItem>
          <AdminDrawer />
        </List>
      </Drawer>

      <main className={classes.content} style={{ width: width }}>
        <Switch>
          {generateRoutes(routes, businessId, businessDetails).map((route, index) => {
            return (<Route key={`Route Choice: ${index}`} {...route} />)
          })}
          <Route path='*' render={() => { return (<Redirect to="/404" />) }} />
        </Switch>
      </main>
      {
        (!bigEnough ? <IconButton className={classes.iconButton} onClick={() => { setOpen(true) }}>
          <MenuIcon className={classes.icon} />
        </IconButton>
          : null)
      }
    </div>

  );
};

export default AdminDashboard;