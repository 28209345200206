import { Button, CircularProgress, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import noWhiteSpace from "../../../../../../FormValidation/noWhiteSpace";
import notEmpty from "../../../../../../FormValidation/notEmpty";
import { addCustomerQuali, listCustomerQuali, retrieveGetParams } from "../../../../../../utils";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import PostFilter, { applyFilters } from "../../../../../Filter/PostFilter";
import CModal from "../../../../../Form/CModal";
import Form from "../../../../../Form/Form";
import LightTooltip from "../../../../../Form/LightTooltip";
import ListCard from "../../../../../MobileCards/ListCard";
import StatusTag from "../../../../../TagComponents/StatusTag";
import ListTable from "./../../../../../TableComponents/ListTable";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  rightElem: {
    float: "right",
  },
  mobilePadding: {
    width:"100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  headerPadding: {
    paddingBottom: "10px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  }, 
  largeWidth: {
    maxWidth:"calc(100vh - 370px)"
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingLeft:"10px",
    paddingRight:"10px",
    paddingTop:"10px",
    paddingBottom:"10px",
    backgroundColor: "#ffffff",
    boxShadow: "inset 0 -1px #e3e8ee"
  },
  loaderMain : {
    position : "absolute",
    top : "50%",
    left: "50%"
  },
  formContainer:{
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding:"20px",
    maxWidth: "600px"
}
}));

const keyObjects = [{key:'isActive', label:'Status', keyLabel:'statusName'}]

const customerQualificationsHeaders = [
  {
    name: "status",
    columnKey: "status",
    columnWidth: "120px",
    type:"Normal"
  },
  {
    name: "type",
    columnKey: "type",
    columnWidth: "120px",
  },
  {
    name: "name",
    columnKey: "wrappedName",
    columnWidth: "80%",
    tooltip:true
  }
];

const CustomerQualificationsList = () => {
  const [add, setAdd] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const large = useMediaQuery('(min-width: 960px)');
  const small = useMediaQuery('(min-width: 750px)');
  const queryParams = retrieveGetParams(location)
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const [initialState, setInitialState] = useState(null);
  const [customerQList, setCustomerQList] = useState([]);
  const[customerQFilterList, setCustomerQFilterList] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);

  async function handleSubmit(event) {
    dispatch({
        type: 'UPDATE', payload: {
            loading: true
        }
    });
    let success = await addCustomerQuali({
        type:event.type,
        businessId: businessId,
        name: event.name,
        description: event.description,
    })
    dispatch({
        type: 'UPDATE', payload: {
            loading: false
        }
    });
    if (success) {
        history.push(`${location.pathname}/${success.qualificationId}`);
    }
};

  const processQualDataForFilter = (customerQualData) => {
    return customerQualData.map((customerQ) => {
      let {
        qualificationId,
        name,
        isActive,
        type
      } = customerQ;
      const statusName = isActive?'Active':'Not Active'
      let status = <StatusTag wrapperName={isActive ? "greenWrapper" : "redWrapper"} value={isActive ? "Active" : "Inactive"} />;
      let id = qualificationId;
      let wrappedName =  <Typography noWrap style={{ fontSize:"14px", color:"#1a1f36", width: "100%", fontFamily:"Rubik, sans-serif"}}>
            {name}
        </Typography>
      return {
        id,
        isActive,
        status,
        name, 
        wrappedName,
        statusName,
        type
      };
    });
  }

  const processCustomerQualData = (customerQualData) => {
    return customerQualData.map((customerQ) => {
      let {
        qualificationId,
        name,
        isActive
      } = customerQ;

      let status = <StatusTag wrapperName={isActive ? "greenWrapper" : "redWrapper"} value={isActive ? "Active" : "Inactive"} />;
      let id = qualificationId;
      let wrappedName =  <Typography noWrap style={{ fontSize:"14px", color:"#1a1f36", width: "100%", fontFamily:"Rubik, sans-serif"}}>
            {name}
        </Typography>
    
      return {
        id,
        isActive,
        status,
        name, 
        wrappedName
      };
    });
  };

  const getCustomerQualificationsDetails = async () => {
    let customerQsPayload = await listCustomerQuali({
      businessId: businessId
    });
    if (customerQsPayload && customerQsPayload.qualificationDetails) {
      setCustomerQList(processCustomerQualData(customerQsPayload.qualificationDetails));
      setCustomerQFilterList(processQualDataForFilter(customerQsPayload.qualificationDetails))
      setInitialState(true);
    }
  };

  const navigateToCustomerQ = (qualificationId) => {
    history.push(`/dashboard/${businessId}/Qualifications/${qualificationId}`);
  };

  const ViewChanger = () => {
    if(small) {
      return(
        <ListTable
          identifier={"admin_orders"}
          tableHeaders={customerQualificationsHeaders}
          tableRows={applyFilters(customerQFilterList, queryParams, keyObjects)}
          rowClick={navigateToCustomerQ}
        />
      );
    } else {
      return(
        applyFilters(customerQFilterList, queryParams, keyObjects).map((mobileQuali) => {
        return(
          <ListCard 
            action = {() => {history.push(`/dashboard/${businessId}/Qualifications/${mobileQuali.id}`);}} 
            cardName={mobileQuali.name} 
            statusTagValue={mobileQuali.isActive} 
            activeValue="Active"
            inactiveValue="Inactive"
            other={mobileQuali.type} 
          />
        )})
      );
    };
  };

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    await getCustomerQualificationsDetails();
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
  }, [currentPage, currentLimit]);

        if(initialState === null)  {
          return(
            <Grid container className={classes.mainWrapper}>
              <Grid item xs={12}>
                <div className={classes.loaderWrapper}>
                  <CircularProgress className={classes.loaderMain}/>
                </div>
              </Grid>
            </Grid>
          );
        } else {
        return (
          <Grid container className={large ? classes.mainWrapper : !large && small ? classes.mobilePadding : null}>
          <CModal open={add} setOpen={setAdd}
            children={
              <Container component="main" className={classes.formContainer}>
                <Form
                  action={handleSubmit}
                  postAction={null}
                  cancelEnabled
                  title="Add Customer Qualification"
                  cancelAction={() => { setAdd(false); }}
                  cancelText="Cancel"
                  submitText="Create"
                  inputFields={
                    [
                      {
                        type: "TextField",
                        validator: [notEmpty, noWhiteSpace],
                        initialvalue: "",
                        label: "Name",
                        name: "name",
                      },
                      {
                        type: "SingleSelect",
                        validator: value => !value ? "Please sleect a type":null,
                        label: "Type",
                        name: "type",
                        choices:[
                          ["Customer","Customer"],
                          ["Attendee","Attendee"]
                        ]
                      },
                      {
                        type: "TextArea",
                        initialvalue: "",
                        label: "Description (optional)",
                        name: "description",
                        resizeEnabled: false,
                        moreProps: {
                          rowsMin: 4,
                          rowsMax: 10
                        }
                      }
                    ]
                  }
                >
                </Form>
              </Container>
            }
          />
          <Grid container direction="row" item xs={12} className={small ? null : classes.stickyHeader} style={!large && small ? {paddingTop:"15px"} : null}>
            <Grid item xs={12}>
              <div className={classes.headerPadding}>
                  <span className={small ? classes.topHeading : classes.mobileTopHeadingOne}>Qualifications</span>
                  <LightTooltip title="Create Qualification" arrow>
                  <span className={classes.rightElem}>
                    <Button  style={{backgroundColor:"#0079fc", color:"white", marginLeft: "10px", textTransform:'none'}} onClick ={() => {setAdd(true);}}>Add</Button>
                  </span>
                </LightTooltip>
                  <PostFilter allEntries = {customerQFilterList} keyObjects = {keyObjects}/>
                </div>
            </Grid>
          </Grid>
          {customerQList.length > 0 ? 
            <Grid item xs={12} className={small ? null : classes.mobilePadding}>
              <div>
              <ViewChanger/>
              </div>
            </Grid> : <EmptyListImage name = "Customer Qualifications"/>}
          <Grid item xs={12}>
              <div className={classes.bottomSpacer}></div>
          </Grid> 
      </Grid> 
        );
      }
};

export default CustomerQualificationsList;