import { Container, makeStyles } from "@material-ui/core"
import notEmpty from "../../FormValidation/notEmpty"
import noWhiteSpace from "../../FormValidation/noWhiteSpace"
import { createManualPayment, editManualPayment } from "../../utils"
import CModal from "../Form/CModal"
import Form from "../Form/Form"

const useStyles = makeStyles(theme => ({
    formContainer: {
        border: "20px",
        backgroundColor: "white",
        borderRadius: "4px",
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto",
    },
    buttonText: {
        textTransform: 'none',
        fontSize: "14px"
    },
    button: {
        float: "right",
    }
}))

const reload = (setOpen, reloadPage) => {
    setOpen(false)
    reloadPage(_ => !_)
}

const RecordPayment = (props) => {
    const { buisnessId, orderId, open, setOpen, payment, currencyInfo, reloadPage } = props
    const classes = useStyles()
    return (
        <CModal
            open={open}
            setOpen={setOpen}
        >
            <Container className={classes.formContainer}>
                <Form
                    moreData={{
                        businessId: buisnessId,
                        orderId: orderId,
                        paymentId: payment ?  payment?.paymentId : undefined
                    }}
                    title={`${payment ? 'Edit' : 'Create'} Manual Payment`}
                    cancelEnabled
                    cancelAction={() => { setOpen(false) }}
                    submitText={payment ? 'Edit' : 'Create'}
                    action={payment ? editManualPayment : createManualPayment}
                    postAction={async (response) => {
                        if (response) {
                            reload(setOpen, reloadPage)
                        }
                    }}
                    inputFields={[
                        {
                            type: "SingleSelect",
                            initialvalue: payment?.type ?? undefined,
                            name: "type",
                            label: "Type",
                            choices: [
                                ["Charge", "Charge"],
                                ["Refund", "Refund"]
                            ],
                            validator: [notEmpty, value => value == null ? "Must select a type" : null]
                        },
                        {
                            type: "Currency",
                            initialvalue: payment?.amount ?? undefined,
                            name: "amount",
                            label: "Amount",
                            currencyInfo: currencyInfo,
                            validator: value => value <= 0 ? "Must be greater than 0" : null
                        },
                        {
                            type: "TextArea",
                            initialvalue: payment?.description ?? undefined,
                            name: "description",
                            label: "Description",
                            resizeEnabled: false,
                            validator: [notEmpty, noWhiteSpace],
                            moreProps: {
                                rowsMin: 4,
                                rowsMax: 10
                            }
                        }
                    ]}
                />
            </Container>
        </CModal>
    )
}

export default RecordPayment