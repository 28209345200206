import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import PaymentMethodsList from './PaymentsList';

function PaymentMethodsHandler() {
    const basePath = '/account/Payment-Methods'
    return(
        <React.Fragment>
            <Switch>
            <Route path = {basePath} exact render = {() => {return(<PaymentMethodsList basePath = {basePath}/>)}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default PaymentMethodsHandler;