import React, { useState, useEffect } from 'react';
import Form from "../../../../../Form/Form";
import CModal from "../../../../../Form/CModal";
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { assignAddon, listAddOns, withLoading } from '../../../../../../utils';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px",
    width: "100vw",
    maxHeight: "80vh",
    overflowY: "auto"

  }
}))


const AddAddOnForm = (props) => {

  const [allAddOns, setAllAddOns] = useState([])
  const dispatch = useDispatch()
  const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });

  const generateChoices = (allOptions) => {
    const temp = []
    const currentChosen = new Set()
    props.optionDetails.addOns.forEach((addOn) => {
      currentChosen.add(addOn.addOnId)
    })
    allOptions.forEach((addOn) => {
      if (!currentChosen.has(addOn.addOnId) && addOn.isActive) {
        temp.push([addOn.name, addOn.addOnId])
      }
    })
    return (temp)
  }

  const setUp = async () => {
    const addonList = await withLoading(listAddOns, {
        businessId:businessId,
    })
    if(addonList && addonList.addOnDetails){
        setAllAddOns(addonList.addOnDetails)
    }
  }

  useEffect(() => {
    if (props.open) {
      setAllAddOns([])
      setUp()
    }
  }, [props.open])

  const classes = useStyles()
  const refresh = (success) => {
    if (success) {
      props.setOpen(false)
      props.refresh()
    }
  }
  return (
    <CModal open={props.open} setOpen={props.setOpen}>
      <Container component="main" className={classes.formContainer}>
        <Form
          moreData={{ businessId: businessId, optionId: props.optionDetails.optionId }}
          title="Add Add Ons"
          cancelEnabled
          cancelAction={() => { props.setOpen(false) }}
          submitText="Add"
          action={assignAddon}
          postAction={refresh}
          inputFields={[
            {
              type: "MultiSelect",
              name: "addOnIds",
              label: "Add Ons",
              choices: generateChoices(allAddOns)
            }

          ]}
        />
      </Container>
    </CModal>
  )
}

export default AddAddOnForm