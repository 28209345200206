import React from 'react'
import Form from '../../Form/Form'
import validEmail from '../../../FormValidation/validEmail'
import notEmpty from '../../../FormValidation/notEmpty'
import { Grid, Typography } from '@material-ui/core'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { login, safePush } from '../../../utils'


const MPLogin = (props) => {

    return (
        <React.Fragment>
            <Form
                titleCenter
                title="Login"
                submitText="Login"
                action={login}
                formStyles={{
                    padding: 14,

                }}
                postAction={props.postLoginAction}
                inputFields={[
                    {
                        type: "TextField",
                        initialvalue: "",
                        validator: validEmail,
                        label: "Email",
                        name: "email",
                    },
                    {
                        type: "Password",
                        initialvalue: "",
                        validator: notEmpty,
                        label: "Password",
                        name: "password",
                    },
                ]}
            />
            <Grid container>
                <Grid item xs={6}>
                    <Typography align="left" onClick={() => { props.setForm('signUp') }} color="primary" style = {{cursor:"pointer"}}>
                        Don't have an account?<br />Sign up here
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align="right" onClick={() => { props.setForm('forgotPassword') }} color="primary" style = {{cursor:"pointer"}}>
                        Forgot password
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default MPLogin