import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import LogPresence from './LogPresence'

const LogPresenceHandler = () => {
    const businessId = useSelector(state => state.buisnessDetails.businessId);
    const basePath = `/dashboard/${businessId}/Log-Presence`
    return (
        <Switch>
            <Route exact path={basePath} render={() => (<LogPresence businessId={businessId} />)} />
            <Route path="*" render={<Redirect to="404" />} />
        </Switch>
    )
}

export default LogPresenceHandler