import {
  Button,
  Checkbox,
  Container,
  Grid,
  GridList, GridListTile,
  GridListTileBar,
  IconButton,
  Menu, MenuItem,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import PublishIcon from "@material-ui/icons/Publish";
import axios from "axios";
import { ColorButton } from "material-ui-color";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { addServiceImage, deleteService, deleteServiceImage, removeAttendeeQualification, removeCustomerQualification, removeServiceQuestion, serviceActive, serviceWaiver, services, signedAdminImageUrlUpload, updateService, withLoading } from "../../../../../../utils";
import ErrorTag from "../../../../../ErrorPages/ErrorTag";
import CDisplayArea from "../../../../../Form/CDisplayArea";
import CModal from "../../../../../Form/CModal";
import CModalError from "../../../../../Form/CModalError";
import LightTooltip from "../../../../../Form/LightTooltip";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import { CAddIcon, CCloseIcon, CEllipsisIcon } from "../../../../../Icons/Icons";
import ListTable from "../../../../../TableComponents/ListTable";
import StatusTag from "./../../../../../TagComponents/StatusTag";
import AddQuestionForm from "./AddQuestionForm";
import EditServiceForm from "./EditServiceForm";
import EmailServiceForm from "./EmailServiceForm";
import QualiForm from "./QualiForm";
import WaiverForm from "./WaiverForm";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
    width: 200,
    objectFit: "cover"
  },
  avatar: {
    height: theme.spacing(20),
    width: theme.spacing(20),
  },
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  topBlock: {
    paddingBottom: "15px",
  },
  recordRow: {
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRoght: "5px",
    width: "550px"
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
  topHeading: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  topHeadingOne: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  topHeadingTwo: {
    fontSize: "28px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  topHeadingThree: {
    backgroundColor: "#eb5757",
    opacity: "50%",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  topHeadingFour: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "2px",
    marginLeft: "10px",
    position: "absolute",
    marginTop: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  contentColumnFirst: {
    paddingRight: "20px",
    boxShadow: "inset -1px 0 #e3e8ee",
    display: "inline-block",
  },
  contentColumnMiddle: {
    boxShadow: "inset -1px 0 #e3e8ee",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "inline-block",
  },
  contentColumnLast: {
    paddingLeft: "20px",
    display: "inline-block",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  labelText: {
    fontSize: "14px",
    color: "#697386",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueText2: {
    fontSize: "14px",
    color: "#3c4257",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextNeg: {
    fontSize: "14px",
    color: "#009211",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBG: {
    fontSize: "16px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextBold: {
    fontSize: "14px",
    color: "#1a1f36",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueTextPad: {
    fontSize: "14px",
    color: "#1a1f36",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "10px",
  },
  valueTextLink: {
    fontSize: "14px",
    color: "#3d5af1",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none"
  },
  errorText: {
    fontSize: "14px",
    color: "#ff0000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockLabel: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  blockText: {
    paddingLeft: "10px",
  },
  subHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  fieldRows: {
    paddingTop: "2px"
  },
  fieldColumnOne: {
    width: "108px",
    display: "inline-block",
  },
  fieldColumnTwo: {
    display: "inline-block",
  },
  sdCustomCellOne: {
    border: "none",
    width: "5%",
    maxWidth: "200px",
    padding: "0px",
  },
  mobilePadding: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingTwo: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingThree: {
    fontSize: "20px",
    fontWeight: "light",
    color: "#1a1f36",
  },
  sdCustomCellTwo: {
    border: "none",
    width: "10%",
    maxWidth: "300px",
    padding: "10px",
  },
  sdCustomCellThree: {
    border: "none",
    width: "85%",
    maxWidth: "200px",
    padding: "10px",
  },
  sdCustomCellFour: {
    border: "none",
    padding: "10px",
  },
  headerPadding: {
    padding: "10px",
  },
  bottomSpacer: {
    height: "50px",
  },
  topMarginBlock: {
    marginTop: "10px",
  },
  loaderWrapper: {
    width: "100%",
    height: "calc(100vh - 64px)"
  },
  loaderMain: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  formContainer: {
    border: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: "20px",
    maxWidth: "600px"
  }

}));

const qualiHeaders = [
  {
    name: "",
    columnKey: "check",
    columnWidth: "50px",
    type: "Normal"
  },
  {
    name: "name",
    columnKey: "name",
    columnWidth: "35%",
    type: "Normal"
  },

  {
    name: "description",
    columnKey: "description",
    columnWidth: "65%",
    tooltip: true
  },

];

const questionHeaders = [
  {
    name: "",
    columnKey: "check",
    columnWidth: "50px",
    type: "Normal"
  },
  {
    name: "type",
    columnKey: "type",
    columnWidth: "100px",
  },
  {
    name: "question",
    columnKey: "question",
    columnWidth: "100%",
    tooltip: true
  },



];

const Service = () => {
  const classes = useStyles();
  const [_, updateState] = useState(0);
  const bigEnough = useMediaQuery("(min-width: 750px)")
  const dispatch = useDispatch();
  const history = useHistory();
  const [serviceDetails, setServiceDetails] = useState(null)
  const businessId = useSelector((state) => {
    return state.buisnessDetails.businessId;
  });

  const [eOpen, setEOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [aDOpen, setADOpen] = useState(false);
  const [wEdit, setWEdit] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [removeWaiver, setRemoveWaiver] = useState(false);
  const [addCustomerQuali, setAddCustomerQuali] = useState(false);
  const [addAttendeeQuali, setAddAttendeeQuali] = useState(false);
  const [index, setIndex] = useState(0);
  const [tag, setTag] = useState(false);
  const [customerQChecked, setCustomerQChecked] = useState([])
  const [customerQDelete, setCustomerQDelete] = useState(false)
  const [attendeeQChecked, setAttendeeQChecked] = useState([])
  const [attendeeQDelete, setAttendeeQDelete] = useState(false)
  const [QuestionChecked, setQuestionChecked] = useState(null)
  const [QuestionDelete, setQuestionDelete] = useState(false)
  const [QuestionAdd, setQuestionAdd] = useState(false)
  const [imageName, setImageName] = useState(false);
  const [dI, setDI] = useState(false);
  const [imageForm, setImageForm] = useState(false);
  const [body, setBody] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [gID, setGID] = useState(false);

  async function handleDelete(event) {
    const success = await withLoading(updateService, {
      businessId: businessId,
      serviceId: serviceIdURL,
      image: null
    })
    if (success) {
      setDI(false);
      updateState((_) => !_);

    }
  };

  async function handleImageDelete(event) {
    const success = await withLoading(deleteServiceImage, {
      businessId: businessId,
      serviceId: serviceIdURL,
      imageId: serviceDetails.images[index].imageId
    })
    if (success) {
      setGID(false);
      updateState((_) => !_);

    }
  };

  const handleImage = async (file) => {
    setImageForm(false);
    if (file.target.files && file.target.files[0] && file.target.files[0].type && file.target.files[0].type.includes('image')) {
      setFileName(file.target.files[0].name);
      setFileData(file.target.files[0]);
      setImageName(URL.createObjectURL(file.target.files[0]));
      setImageForm(true);
    }
  };

  const handleImageAdd = async () => {
    let preSignedURL = null;

    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });

    preSignedURL = await signedAdminImageUrlUpload({
      businessId,
      fileName
    });

    if (preSignedURL && !preSignedURL.error) {

      let s3url = preSignedURL.signedData.url;
      let image = preSignedURL.imageURL;
      let fields = preSignedURL.signedData.fields;
      let formData = new FormData();

      for (let k in fields) {
        formData.append(k, fields[k]);
      };

      formData.append('file', fileData);
      try {
        await axios.post(s3url, formData);
        await updateService({ businessId: businessId, serviceId: serviceIdURL, image });

        dispatch({
          type: "UPDATE",
          payload: {
            loading: false,
          },
        });
        setImageForm(false);

      } catch (error) {
        dispatch({
          type: "UPDATE",
          payload: {
            loading: false,
          },
        });
      };
    } else {
      dispatch({
        type: "UPDATE",
        payload: {
          loading: false,
        },
      });
    };
    updateState((_) => !_);
  };

  const handleImagesAdd = async () => {
    let preSignedURL = null;

    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });

    preSignedURL = await signedAdminImageUrlUpload({
      businessId,
      fileName
    });

    if (preSignedURL && !preSignedURL.error) {

      let s3url = preSignedURL.signedData.url;
      let imageURL = preSignedURL.imageURL;
      let fields = preSignedURL.signedData.fields;
      let formData = new FormData();

      for (let k in fields) {
        formData.append(k, fields[k]);
      };

      formData.append('file', fileData);
      try {
        await axios.post(s3url, formData);
        await addServiceImage({ businessId: businessId, serviceId: serviceIdURL, imageURL });

        dispatch({
          type: "UPDATE",
          payload: {
            loading: false,
          },
        });
        setImageForm(false);

      } catch (error) {
        dispatch({
          type: "UPDATE",
          payload: {
            loading: false,
          },
        });
      };
    } else {
      dispatch({
        type: "UPDATE",
        payload: {
          loading: false,
        },
      });
    };
    setBody(false);
    updateState((_) => !_);
  };

  const handleClose = () => {
    setADOpen(false);
  };
  const handleMenuClose = () => {
    setAnchorEl(false);
  }
  const { serviceIdURL } = useParams();
  const genCheckedState = (details) => {
    const temp = []
    for (let i = 0; i < details.length; i++) {
      temp.push(false)
    };
    return (temp)
  };

  useEffect(async () => {

    let data = await withLoading(services, {
      businessId: businessId,
      serviceId: serviceIdURL,
    }, true)

    if (data && Array.isArray(data.services)) {
      setQuestionChecked(genCheckedState(data.services[0].questions))
      setServiceDetails(data.services[0])

    }
  }, [_]);


  const genDelQuestions = () => {
    const temp = [];
    for (let i = 0; i < QuestionChecked.length; i++) {
      if (QuestionChecked[i] === true) {
        temp.push(serviceDetails.questions[i].questionId)
      }
    }
    return temp
  }

  if (serviceDetails && serviceDetails.serviceId == serviceIdURL) {
    return (
      <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
        <React.Fragment>
          <ErrorTag open={tag} setOpen={() => { setTag(false); }} />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => { setEOpen(true); handleMenuClose() }}>Edit Service</MenuItem>
            <MenuItem onClick={() => { setEmailOpen(true); handleMenuClose() }}>Email Customers</MenuItem>
            <MenuItem onClick={() => { setADOpen(true); handleMenuClose() }}>{serviceDetails.isActive ? "Deactivate Service" : "Activate Service"}</MenuItem>
            {!serviceDetails.isActive ?
              <MenuItem onClick={() => { setDeleteOpen(true); setAnchorEl(false) }}>
                <Typography >
                  Delete Service
                </Typography>
              </MenuItem> : null}
          </Menu>
          <CModal open={imageForm} setOpen={setImageForm}
            children={
              <Container component="main" className={classes.formContainer}>
                <Grid container spacing={1} item xs={12} direction="column" alignItems="center">
                  <CModalError />
                  <Grid item xs={12}>
                    <div className="container">
                      <img style={{ maxHeight: "300px", maxWidth: "300px" }} src={imageName ? imageName : null} alt="smth" />
                      <span className={bigEnough ? "button" : "bSmall"}>
                        <input
                          accept="image/*"
                          onChange={(file) => { handleImage(file); }}
                          style={{ display: 'none' }}
                          id="contained-button-file"
                          multiple
                          type="file"
                        />
                        <label htmlFor="contained-button-file">
                          <IconButton variant="contained" component="span" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
                            <PublishIcon style={{ color: "#1a1f36" }} />
                          </IconButton>
                        </label>
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid container item xs={12} spacing={1} justifyContent="flex-end" direction="row">
                    <Grid item >
                      <Button className={classes.buttonBasic} onClick={() => { setBody(false); setImageForm(false) }}>Cancel</Button>
                    </Grid>
                    <Grid item>
                      <Button className={classes.buttonBasic} style={{ backgroundColor: "#0079fc", color: "white" }} onClick={body ? handleImagesAdd : handleImageAdd}>Submit</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            }
          />
          <QualiForm serviceDetails={serviceDetails} open={addCustomerQuali} setOpen={setAddCustomerQuali} refresh={() => { updateState(_ => !_) }} type={"Customer"} />
          <QualiForm serviceDetails={serviceDetails} open={addAttendeeQuali} setOpen={setAddAttendeeQuali} refresh={() => { updateState(_ => !_) }} type={"Attendee"} />
          <WaiverForm serviceDetails={serviceDetails} open={wEdit} setOpen={setWEdit} refresh={() => { updateState(_ => !_) }} />
          <GeneralDialog
            open={dI}
            close={setDI}
            title="Delete Service Iamge"
            text="Are you sure you want to delete this image?"
            action={handleDelete}
          />
          <GeneralDialog
            open={gID}
            close={setGID}
            title="Delete Image"
            text="Are you sure you want to delete this image?"
            action={handleImageDelete}
          />
          <EditServiceForm serviceDetails={serviceDetails} open={eOpen} setOpen={setEOpen} refresh={() => { updateState(_ => !_) }} />
          <EmailServiceForm serviceDetails={serviceDetails} open={emailOpen} setOpen={setEmailOpen} refresh={() => { updateState(_ => !_) }} />
          <AddQuestionForm open={QuestionAdd} setOpen={setQuestionAdd} serviceDetails={serviceDetails} refresh={() => { updateState(_ => !_) }} />
          <GeneralDialog
            open={aDOpen}
            close={handleClose}
            title={serviceDetails.isActive ? "Deactivate Service" : "Activate Service"}
            text={serviceDetails.isActive ? "Are you sure you want to deactivate this service?" : "Are you sure you want to activate this service?"}
            action={async () => {
              let data = await withLoading(serviceActive,
                {
                  businessId: businessId,
                  serviceId: serviceDetails.serviceId,
                  isActive: !serviceDetails.isActive
                })
              if (data) {
                handleClose(false)
                updateState(_ => !_)
              }
            }}
          />

          <GeneralDialog
            open={deleteOpen}
            close={() => { setDeleteOpen(false) }}
            title="Delete Service"
            text="Are you sure you want to delete service permanently?"
            action={async () => {
              dispatch({
                type: 'UPDATE', payload: {
                  loading: true
                }
              });
              let success = await deleteService({
                businessId: businessId,
                serviceId: serviceDetails.serviceId
              })
              dispatch({
                type: 'UPDATE', payload: {
                  loading: false
                }
              });
              if (success && success.error === false) {
                history.replace(`/dashboard/${businessId}/Services`);
              }
            }}
          />

          <GeneralDialog
            open={QuestionDelete}
            close={() => { setQuestionDelete(false) }}
            title={"Delete Question(s)"}
            text={"Are you sure you want to delete this question(s)?"}
            action={async () => {
              let data = await withLoading(removeServiceQuestion,
                {
                  businessId: businessId,
                  serviceId: serviceDetails.serviceId,
                  questionIds: genDelQuestions()
                })
              if (data) {
                setQuestionDelete(false)
                updateState(_ => !_)
              }
            }}
          />
          <GeneralDialog
            open={removeWaiver}
            close={setRemoveWaiver}
            title={"Remove Waiver"}
            text={"Are you sure you want to remove this waiver"}
            action={async () => {
              let data = await withLoading(serviceWaiver,
                {
                  businessId: businessId,
                  serviceId: serviceDetails.serviceId,
                  waiverSignRequired: false
                })
              if (data) {
                setRemoveWaiver(false)
                updateState(_ => !_)
              }
            }}
          />
          <GeneralDialog
            open={customerQDelete}
            close={setCustomerQDelete}
            title={"Remove Customer Qualifications"}
            text={"Are you sure you want to remove these customer qualifications?"}
            action={async () => {
              let data = await withLoading(removeCustomerQualification,
                {
                  businessId: businessId,
                  serviceId: serviceDetails.serviceId,
                  qualificationIds: customerQChecked
                })
              if (data) {
                setCustomerQDelete(false)
                setCustomerQChecked([])
                updateState(_ => !_)
              }
            }}
          />
          <GeneralDialog
            open={attendeeQDelete}
            close={setAttendeeQDelete}
            title={"Remove Attendee Qualifications"}
            text={"Are you sure you want to remove these attendee qualifications?"}
            action={async () => {
              let data = await withLoading(removeAttendeeQualification,
                {
                  businessId: businessId,
                  serviceId: serviceDetails.serviceId,
                  qualificationIds: attendeeQChecked
                })
              if (data) {
                setAttendeeQDelete(false)
                setAttendeeQChecked([])
                updateState(_ => !_)
              }
            }}
          />
        </React.Fragment>
        <Grid container item xs={12} className={classes.topBlock}>
          <Grid item xs={6}>
            <span className={classes.topHeading}>SERVICE</span>
          </Grid>
          <Grid item xs={6}>
            {!serviceDetails.isDeleted ?
              <span className={classes.rightElem}><Button aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => { setAnchorEl(event.currentTarget); }} className={classes.buttonBasic}><CEllipsisIcon /></Button></span> : null}
          </Grid>
        </Grid>
        <Grid item style={(bigEnough ? { maxWidth: "50%" } : { width: "100%" })}>
          <div className={classes.headerPadding} style={{ paddingLeft: "0px" }}>
            <LightTooltip title={serviceDetails.name} arrow>
              <Typography className={(bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne)} noWrap>
                {serviceDetails.name}
              </Typography>
            </LightTooltip>
          </div>
        </Grid>
        {(bigEnough ?
          <Grid item>
            <div className={classes.headerPadding} style={{ paddingLeft: "20px" }}>
              <span className={classes.topHeadingFour}>
                <StatusTag wrapperName={serviceDetails.isActive ? "greenWrapper" : "redWrapper"} value={serviceDetails.isActive ? "Active" : "Inactive"} />
              </span>
            </div>
          </Grid>
          :
          <Grid item>
            <div style={{ paddingLeft: "10px" }}>
              <StatusTag wrapperName={serviceDetails.isActive ? "greenWrapper" : "redWrapper"} value={serviceDetails.isActive ? "Active" : "Inactive"} />
            </div>
          </Grid>
        )}
        <Grid container style={{ paddingTop: "15px" }} spacing={3}>
          <Grid item xs={(bigEnough ? null : 12)} >
            <Grid item style={bigEnough ? { paddingLeft: "0px" } : { display: "flex", justifyContent: "center" }}>
              <div className="container">
                <img className={classes.media} src={serviceDetails.image} alt="smth" />
                <span className={bigEnough ? "button" : "bSmall"} style={{ display: "inline-flex" }}>
                  <input
                    accept="image/*"
                    onChange={(file) => { handleImage(file); }}
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    type="file"
                  />
                  <label htmlFor="contained-button-file">
                    <IconButton variant="contained" component="span" style={!serviceDetails.isDefaultImage ? { height: "20px", width: "20px", right: "41px", backgroundColor: "rgba(255, 255, 255, 0.6)" } : { backgroundColor: "rgba(255, 255, 255, 0.6)" }}><EditIcon style={{ color: "#1a1f36" }} /></IconButton>
                  </label>
                  {!serviceDetails.isDefaultImage ?
                    <LightTooltip title="Delete Service Image" arrow>
                      <IconButton variant="contained" style={{ right: "40px", backgroundColor: "rgba(255, 255, 255, 0.6)", height: "44px", width: "44px", }} onClick={() => { setDI(true); }}>
                        <CCloseIcon />
                      </IconButton>
                    </LightTooltip>
                    : null}
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid item>
            <div className={classes.contentRow} style={bigEnough ? null : { paddingBottom: "1px" }}>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne} style={{ paddingBottom: "5px" }}>
                  <Typography className={classes.labelText} noWrap>Category</Typography>
                </div>
                <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px", maxWidth: "calc(100% - 108px)" }}>
                  <Typography className={classes.valueText} noWrap>
                    {serviceDetails.categoryName}
                  </Typography>
                </div>
              </div>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne} style={{ paddingBottom: "2px" }}>
                  <Typography className={classes.labelText}>Type</Typography>
                </div>
                <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px", maxWidth: "calc(100% - 108px)" }}>
                  <Typography className={classes.valueText} noWrap>
                    {serviceDetails.type}
                  </Typography>
                </div>
              </div>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne} style={{ paddingBottom: "2px" }}>
                  <Typography className={classes.labelText} noWrap>Mode</Typography>
                </div>
                <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px", maxWidth: "calc(100% - 108px)" }}>
                  <Typography className={classes.valueText} noWrap>
                    {serviceDetails.isVirtual ? "Virtual" : "In person"}
                  </Typography>
                </div>
              </div>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne} style={{ paddingBottom: "2px" }}>
                  <Typography className={classes.labelText} noWrap>Duration</Typography>
                </div>
                <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px", maxWidth: "calc(100% - 108px)" }}>
                  <Typography className={classes.valueText} noWrap>
                    {`${serviceDetails.duration} minutes`}
                  </Typography>
                </div>
              </div>
              <div className={classes.fieldRows}>
                <div className={classes.fieldColumnOne} style={{ paddingBottom: "2px" }}>
                  <Typography className={classes.labelText} noWrap>Display Color</Typography>
                </div>
                <div className={classes.fieldColumnTwo} style={{ paddingLeft: "15px", maxWidth: "calc(100% - 108px)" }}>
                  <LightTooltip arrow title={serviceDetails.primaryColor}>
                    <div>
                      <ColorButton color={serviceDetails.primaryColor} borderColor="#1a1f36" />
                    </div>
                  </LightTooltip>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper} style={bigEnough ? { paddingTop: "10px" } : null}>
            <span className={classes.labelText}>Description</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <span className={classes.valueText}>
              <CDisplayArea
                initialMaxHeight={200}
                value={serviceDetails.description ? serviceDetails.description : null}
              />
            </span>{" "}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Questions</span>
            {Array.isArray(QuestionChecked) && !QuestionChecked.includes(true) ? <span title="Add Question(s)" className={classes.rightElem}>
              <Button className={classes.buttonBasic}
                onClick={() => { setQuestionAdd(true) }}
              >
                <CAddIcon />
              </Button>
            </span> : <span title="Remove Qualification(s)" className={classes.rightElem}>
              <Button
                className={classes.buttonBasic}
                onClick={() => { setQuestionDelete(true) }}
              >
                <CCloseIcon />
              </Button>
            </span>}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            {serviceDetails.questions.length != 0 ? (
              <ListTable
                styling={{ maxHeight: 250, width: "100%" }}
                identifier={"questions"}
                tableHeaders={questionHeaders}
                tableRows={serviceDetails.questions.map((question, index) => ({
                  check: (
                    <Checkbox color="primary" checked={QuestionChecked[index]} onChange={() => {
                      let temp = [...QuestionChecked];
                      temp[index] = !temp[index]
                      setQuestionChecked(temp)
                    }} />
                  ),
                  question: (
                    <Link className={classes.valueTextLink} to={`/dashboard/${businessId}/Questions/${question.questionId}`}>
                      <LightTooltip arrow title={question.question}>
                        <Typography noWrap className={classes.valueTextLink}>
                          {question.question}
                        </Typography>
                      </LightTooltip>
                    </Link>
                  ),
                  id: question.questionId,
                  type: question.type
                }))}
                rowClick={null}
              />
            ) : (
              <div className={classes.topMarginBlock}>
                <span className={classes.valueTextPad}>
                  No Questions have been added for this service.
                </span>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Customer Qualifications</span>
            {customerQChecked.length === 0 ? <span title="Add Customer Qualification" className={classes.rightElem}>
              <Button className={classes.buttonBasic}
                onClick={() => { setAddCustomerQuali(true) }}
              >
                <CAddIcon />
              </Button>
            </span> : <span title="Remove Customer Qualification(s)" className={classes.rightElem}>
              <Button
                className={classes.buttonBasic}
                onClick={() => { setCustomerQDelete(true) }}
              >
                <CCloseIcon />
              </Button>
            </span>}
          </div>
        </Grid>
        <Grid item xs={12} >
          <div className={classes.contentRow} >
            {serviceDetails.qualifications.length !== 0 ? (
              <ListTable
                styling={{ maxHeight: 250, width: "100%" }}
                identifier={"qaulis"}
                tableHeaders={qualiHeaders}
                tableRows={serviceDetails.qualifications.map((qualification, index) => ({
                  check: (
                    <Checkbox color="primary" checked={customerQChecked.includes(qualification.qualificationId)} onChange={() => {
                      if (customerQChecked.includes(qualification.qualificationId)) {
                        const newCustomerQChecked = [...customerQChecked]
                        const index = newCustomerQChecked.indexOf(qualification.qualificationId);
                        if (index > -1) {
                          newCustomerQChecked.splice(index, 1);
                        }
                        setCustomerQChecked(newCustomerQChecked)
                      }
                      else {
                        setCustomerQChecked([...customerQChecked, qualification.qualificationId])
                      }
                    }} />
                  ),
                  name: (
                    <Link className={classes.valueTextLink} to={`/dashboard/${businessId}/Qualifications/${qualification.qualificationId}`}>
                      <LightTooltip arrow title={qualification.name}>
                        <Typography noWrap className={classes.valueTextLink}>
                          {qualification.name}
                        </Typography>
                      </LightTooltip>
                    </Link>
                  ),
                  id: qualification.qualificationId,
                  description: qualification.description,
                }))}
                rowClick={null}
              />
            ) : (
              <div className={classes.topMarginBlock}>
                <span className={classes.valueTextPad}>
                  No Customer Qualifications have been added for this service.
                </span>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Attendee Qualifications</span>
            {attendeeQChecked.length === 0 ? <span title="Add Attendee Qualification" className={classes.rightElem}>
              <Button className={classes.buttonBasic}
                onClick={() => { setAddAttendeeQuali(true) }}
              >
                <CAddIcon />
              </Button>
            </span> : <span title="Remove Attendee Qualification(s)" className={classes.rightElem}>
              <Button
                className={classes.buttonBasic}
                onClick={() => { setAttendeeQDelete(true) }}
              >
                <CCloseIcon />
              </Button>
            </span>}
          </div>
        </Grid>
        <Grid item xs={12} >
          <div className={classes.contentRow} >
            {serviceDetails.attendeeQualifications.length !== 0 ? (
              <ListTable
                styling={{ maxHeight: 250, width: "100%" }}
                identifier={"attendee quali"}
                tableHeaders={qualiHeaders}
                tableRows={serviceDetails.attendeeQualifications.map((qualification, index) => ({
                  check: (
                    <Checkbox color="primary" checked={attendeeQChecked.includes(qualification.qualificationId)} onChange={() => {
                      if (attendeeQChecked.includes(qualification.qualificationId)) {
                        const newAttendeeQChecked = [...attendeeQChecked]
                        const index = newAttendeeQChecked.indexOf(qualification.qualificationId);
                        if (index > -1) {
                          newAttendeeQChecked.splice(index, 1);
                        }
                        setAttendeeQChecked(newAttendeeQChecked)
                      }
                      else {
                        setAttendeeQChecked([...attendeeQChecked, qualification.qualificationId])
                      }
                    }} />
                  ),
                  name: (
                    <Link className={classes.valueTextLink} to={`/dashboard/${businessId}/Qualifications/${qualification.qualificationId}`}>
                      <LightTooltip arrow title={qualification.name}>
                        <Typography noWrap className={classes.valueTextLink}>
                          {qualification.name}
                        </Typography>
                      </LightTooltip>
                    </Link>
                  ),
                  id: qualification.qualificationId,
                  description: qualification.description,
                }))}
                rowClick={null}
              />
            ) : (
              <div className={classes.topMarginBlock}>
                <span className={classes.valueTextPad}>
                  No Attendee Qualifications have been added for this service.
                </span>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Images</span>
            <span>
              <input
                accept="image/*"
                onChange={(file) => { handleImage(file); }}
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" component="span" className={[classes.rightElem, classes.buttonBasic]} onClick={() => { setBody(true); }}>
                  <CAddIcon />
                </Button>
              </label>
            </span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            {serviceDetails.images.length != 0 ? (
              <GridList cellheight="300px" cols={bigEnough ? 4 : 2}>
                {serviceDetails.images.map((tile, index) => (
                  <GridListTile key={tile.imageId} cols={1}>
                    <img src={tile.url} alt={tile.imageId} />
                    <GridListTileBar style={{ backgroundColor: "rgba(255,255,255,0" }} actionIcon={<LightTooltip title="Delete Image" arrow>
                      <IconButton variant="contained" className={classes.rightElem} style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", height: "44px", width: "44px" }} onClick={() => { setIndex(index); setGID(true); }}>
                        <CCloseIcon />
                      </IconButton>
                    </LightTooltip>} />
                  </GridListTile>
                ))}
              </GridList>
            ) : (
              <div className={classes.topMarginBlock}>
                <span className={classes.valueTextPad}>
                  No images have been added for this service.
                </span>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomSpacer}></div>
        </Grid>
      </Grid>
    );
  } else {
    return (null);
  }
};

export default Service;
