import React from 'react';
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Session from "./Session";
import SessionList from "./SessionList";

function SessionsHandler() {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) })
    return(
        <React.Fragment>
            <Switch>
            <Route path = {`/dashboard/${businessId}/Sessions`} exact render = {() => {return(<SessionList/>)}} />
            <Route path = {`/dashboard/${businessId}/Sessions/:sessionIdURL`} render = {() => {return(<Session/>);}} />
            <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    );
};

export default SessionsHandler;