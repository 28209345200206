import React, { useState, useEffect } from 'react'
import { Container, makeStyles, CircularProgress, useMediaQuery, Grid, Typography } from '@material-ui/core'
import CModal from '../Form/CModal'
import Form from '../Form/Form'
import { withLoading, customerPackOpenings, customerUsePack, customerPackClasses, safeQuery } from '../../utils'
import moment from 'moment'
import CModalError from '../Form/CModalError'
import CButtonPair from '../Form/CButtonPair'
const useStyles = makeStyles(theme => ({

    formContainer: {
        border: "20px",
        backgroundColor: "white",
        borderRadius: "4px",
        padding: "20px",
        maxHeight: "80vh",
        overflowY: "auto",
        textAlign: "center",
    }

}))

const generateBoundDate = (openings) => {
    if (safeQuery(openings, ["length"]) > 0) {
        return {
            initialvalue: moment(openings[0].dateStr, "MMM, Do YYYY").utc(),
            endvalue: { utcDate: moment(openings.slice(-1).pop().dateStr, "MMM, Do YYYY").utc() }
        }
    }
    else {
        return { initialvalue: null, endvalue: null }
    }
}

const filterClasses = (classDetails, quantity) => {
    const temp = []
    classDetails.forEach(day => {
        const tempDay = {
            ...day,
            classes: []
        }
        day.classes.forEach(session => {
            if (session.availableSeats >= quantity) {
                tempDay.classes.push(session)
            }
        })
        temp.push(tempDay)
    })
    return temp
}



const BookingCalendar = (props) => {
    const classes = useStyles()
    const bigEnough = useMediaQuery("(min-width: 750px")
    const { packDetails, open, setOpen } = props
    const [dateChoice, setDateChoice] = useState(null)
    const [openings, setOpenings] = useState(null)
    const [calendarEditTag, setCalendarEditTag] = useState(null)



    const setUp = async () => {
        let data = null
        if (safeQuery(packDetails, ["offeringType"]) === "Private") {
            data = await withLoading(customerPackOpenings, {
                packId: packDetails.packId
            })
            if (data && Array.isArray(data.slotDetails)) {
                setOpenings(data.slotDetails)
                setCalendarEditTag(data.calendarEditTag)
            }
            else {
                setOpenings(undefined)
            }
        }
        else if (safeQuery(packDetails, ["offeringType"]) === "Group") {
            data = await withLoading(customerPackClasses, {
                packId: packDetails.packId
            })
            if (data && Array.isArray(data.classDetails)) {
                setOpenings(filterClasses(data.classDetails, 1))
                setCalendarEditTag(data.calendarEditTag)
            }
            else {
                setOpenings(undefined)
            }
        }

    }

    useEffect(() => {
        if (open) {
            setOpenings(null)
            setUp()
        }
    }, [open])

    const processDates = (allOpenings) => {
        const checkExists = new Set()
        const mapDateToTimes = new Map()
        if (Array.isArray(allOpenings)) {
            allOpenings.forEach(dateObj => checkExists.add(dateObj.dateStr))
            allOpenings.forEach(dateObj => mapDateToTimes.set(dateObj.dateStr, safeQuery(packDetails, ["offeringType"]) === "Private" ? dateObj.slots : dateObj.classes))
        }
        return [checkExists, mapDateToTimes]
    }

    const [checkExists, mapDateToTimes] = processDates(openings)
    return (
        <CModal
            open={open}
            setOpen={setOpen}
        >
            <Container className={classes.formContainer} style={{
                width: (bigEnough ? "80vw" : "100vw")
            }}
            >
                {
                    openings === null || openings === undefined ?
                        <Grid container spacing={2} style={{ height: "calc(100%)" }} alignItems="center">
                            <Grid item xs={12}>
                                <CModalError />
                            </Grid>
                            {
                                openings !== undefined ?
                                    <Grid item xs={12}>
                                        <CircularProgress />
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12}>
                                <CButtonPair action={() => { setOpen(false) }} errors={[]} submitText="Close" />
                            </Grid>



                        </Grid>
                        :
                        openings.length === 0 ?
                            <Typography>
                                There are no openings listed. Please contact business
                            </Typography>
                            :
                            <Form
                                moreData={{ calendarEditTag: calendarEditTag }}
                                title={`Book ${safeQuery(packDetails, ["offeringType"]) === "Private" ? "Session" : "Class"}`}
                                submitText="Book"
                                cancelEnabled
                                cancelAction={() => { setOpen(false) }}
                                action={async (payload) => {
                                    return await customerUsePack({
                                        packId: packDetails.packId,
                                        calendarEditTag: payload.calendarEditTag,
                                        utcDate: packDetails.offeringType === "Private" ? payload.choice.data : undefined,
                                        classId: packDetails.offeringType === "Group" ? payload.choice.data : undefined,
                                        classEditTag: packDetails.offeringType === "Group" ? payload.choice.classEditTag : undefined
                                    })

                                }}
                                postAction={async (response) => {
                                    if (response) {
                                        props.refresh()
                                        setOpen(false)
                                    }
                                }}
                                inputFields={[
                                    {
                                        type: "SpecialDate",
                                        validator: (value) => { if (value) { setDateChoice(value.format("MMM, Do YYYY")) } },
                                        moreProps: {
                                            shouldDisableDate: (day) => { return !checkExists.has(day.format("MMM, Do YYYY")) },
                                        },
                                        ...generateBoundDate(openings),
                                        size: {
                                            sm: 5,
                                            xs: 12
                                        }
                                    },
                                    {
                                        type: "Chips",
                                        name: "choice",
                                        label: "Availabe Slots",
                                        choices: (mapDateToTimes.get(dateChoice) ? mapDateToTimes.get(dateChoice).map(timeObj =>
                                            [timeObj.time, {
                                                time: timeObj.time,
                                                data: safeQuery(packDetails, ["offeringType"]) === "Group" ? timeObj.classId : timeObj.utcDate,
                                                classEditTag: safeQuery(packDetails, ["offeringType"]) === "Group" ? timeObj.classEditTag : undefined
                                            }]) : []),
                                        validator: [value => (!value ? "Must chose valid date and time" : null)],
                                        size: {
                                            sm: 7,
                                            xs: 12
                                        }
                                    }
                                ]}
                            />
                }
            </Container>
        </CModal>
    )
}
export default BookingCalendar