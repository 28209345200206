import React, { useEffect, useState } from 'react'
import { useMediaQuery, makeStyles, TextField } from '@material-ui/core';
import SearchBar from "../SearchBar/SearchBar";
import { useLocation } from "react-router-dom"
import CModal from '../Form/CModal';
import { Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CModalError from '../Form/CModalError';
const useStyles = makeStyles(theme => ({
    desktopContainer: {
        position: 'fixed',
        width: "calc(100% - 800px)",
        zIndex: theme.zIndex.drawer + 3,
        maxWidth: 800,
        left: 280,
        top: 10
    },
    fieldText: {
        fontSize: 14,
        color: theme.palette.primaryText.main,
        padding: "10px ! important"
    },
    inputRoot: {
        padding: "0px !important"
    },
    placeholder: {
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 60,
        width: "calc(100% - 70px)"
    },
    placeholderFull: {
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        width: "calc(100% - 20px)"
    },
    mobileContainer: {
        width: "100%",
        position: 'fixed',
        zIndex: theme.zIndex.drawer + 2,
        height: 57,
        top:64,
        backgroundColor: theme.palette.white.main,
    },
    ipad:{
        padding:10,
        width: "calc(100% - 260px)"
    },
    ipadContainer: {
        width: "100%",
        zIndex: theme.zIndex.drawer,
        position: 'fixed',
        height: 57,
        top:64,
        paddingLeft:240,

        backgroundColor: theme.palette.white.main,
    },
    formContainer: {
        border: "20px",
        backgroundColor: "white",
        borderRadius: "4px",
        padding: "20px",
        maxWidth: "600px",
        width: "100vw",
        maxHeight: "80vh",
        overflowY: "auto",
    },

}))

const MPSearchBar = (props) => {
    const classes = useStyles()
    const location = useLocation()
    const placeData = useSelector(state => state.placeData)
    const [open, setOpen] = useState(false)
    const mobile = useMediaQuery("(max-width: 750px)")
    const ipad = useMediaQuery("(max-width: 1440px)")

    useEffect(() => {
        setOpen(false)
    }, [location])

    if (mobile) {
        return (
            <React.Fragment>
                <CModal open={open} setOpen={setOpen} >
                    <Container className={classes.formContainer}>
                        <CModalError/>
                        <SearchBar useRecent={props.useRecent} />
                    </Container>
                </CModal>
                <div className={classes.mobileContainer} >
                    <TextField
                        className={props.fullWidth ? classes.placeholderFull : classes.placeholder}
                        variant="outlined"
                        value={placeData ? placeData.formatted_address : ""}
                        onClick={() => setOpen(true)}
                        inputProps={{
                            readOnly: true,
                        }}
                        InputProps={{
                            classes: { input: classes.fieldText }
                        }}
                    />
                </div>
            </React.Fragment>
        )
    }

    else if (ipad) {
        return (
            <React.Fragment>
            <CModal open={open} setOpen={setOpen} >
                <Container className={classes.formContainer}>
                    <SearchBar useRecent={props.useRecent} mobile />
                </Container>
            </CModal>
            <div className={classes.ipadContainer} >
                <TextField
                    className={classes.ipad}
                    variant="outlined"
                    value={placeData ? placeData.formatted_address : ""}
                    onClick={() => setOpen(true)}
                    inputProps={{
                        readOnly: true,
                    }}
                    InputProps={{
                        classes: { input: classes.fieldText }
                    }}
                />
            </div>
        </React.Fragment>
        )
    }
    else {
        return (
            <div className={classes.desktopContainer}>
            <SearchBar useRecent={props.useRecent} />
        </div>
        )
    }
}

export default MPSearchBar