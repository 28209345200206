import { useMediaQuery, makeStyles, Grid } from '@material-ui/core'
import { Pagination } from '@material-ui/lab';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { withLoading, searchRecords, retrieveGetParams, reverseGeoCode, safePush, encodeGetParams } from '../../utils'
import EmptyMP from './EmptyMP';
import filters, { attemptConvertNumber } from './filter'
import MPFilter from './MPFilter'
import MPSearchBar from './MPSearchBar'
import OfferingCard from './OfferingCard'

const drawerWidth = 240
const mobileTiles = 8
const desktopTiles = 8
const useStyles = makeStyles(theme => ({
    desktop: {
        paddingLeft: drawerWidth,
        width: `calc(100%-${drawerWidth}px)`,
        minHeight: "calc(100vh - 64px)",
        display: "flex",
        flexDirection: "column"
    },
    mobile: {
        width: "100%",
        minHeight: "calc(100vh - 121px)",
        display: "flex",
        flexDirection: "column"
    },
    pageNav: {
        marginTop: "auto",
        position: "sticky",
        backgroundColor: "white",
        paddingBottom: 30,
        margin: "0 auto"
    }
}))

const manageReqParams = (currentParams, placeData) => {
    return {
        categoryId: currentParams.catId,
        geoPoint: {
            lat: typeof placeData.geometry.location.lat === "function" ? placeData.geometry.location.lat() : placeData.geometry.location.lat,
            lon: typeof placeData.geometry.location.lng === "function" ? placeData.geometry.location.lng() : placeData.geometry.location.lng,
        },
        pageNo: !isNaN(attemptConvertNumber(currentParams.pn)) ? attemptConvertNumber(currentParams.pn) : 1
    }

}


const manageFilterParams = (currentParams) => {
    const filterParams = {}
    filters.forEach(filter => {
        filterParams[filter.apiKey] = (filter.dataParser ? filter.dataParser(currentParams[filter.key]) : currentParams[filter.key])
    })
    return filterParams
}

const getCurrentPage = location => {
    const currentParams = retrieveGetParams(location)
    return (!isNaN(attemptConvertNumber(currentParams.pn)) ? attemptConvertNumber(currentParams.pn) : 1)
}

const calcSize = (sizeBreakDown) => {
    if (sizeBreakDown.xl) {
        return 3
    }
    else if (sizeBreakDown.lg) {
        return 4
    }
    else if (sizeBreakDown.md) {
        return 6
    }
    else if (sizeBreakDown.sm) {
        return 6
    }
    else if (sizeBreakDown.xl) {
        return 12
    }

}

const MarketPlaceList = (props) => {
    const classes = useStyles()
    const [loaded, setLoaded] = useState(false)
    const bigEnough = useMediaQuery("(min-width: 750px)", { noSsr: true })
    const ipad = useMediaQuery("(max-width: 1440px)", { noSsr: true })
    const gridSize = calcSize(
        {
            xs: bigEnough,
            sm: useMediaQuery("(min-width: 900px)"),
            md: useMediaQuery("(min-width: 1100px)"),
            lg: useMediaQuery("(min-width: 1300px)"),
            xl: useMediaQuery("(min-width: 1600px)"),
        }
    )
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const [searchResults, setSearchResults] = useState(null)
    const placeData = useSelector(state => state.placeData)


    const storeAddressInfo = (placeData) => {
        dispatch({
            type: "UPDATE",
            payload: {
                placeData: placeData
            },
        });
    }


    const setUp = async () => {

        if (placeData) {
            const currentParams = retrieveGetParams(location)
            if (["catId", "plId"].every((key) => currentParams.hasOwnProperty(key)) && currentParams.plId === placeData.place_id) {
                const finalParams =
                {
                    ...manageReqParams(currentParams, placeData),
                    ...manageFilterParams(currentParams),
                    recordCount: (bigEnough ? desktopTiles : mobileTiles),
                }
                let data = await withLoading(searchRecords, finalParams, true)
                if (data && data.searchResult) {
                    setSearchResults(data.searchResult)
                }
            }
        }
        else {
            const currentParams = retrieveGetParams(location)
            if (["catId", "plId"].every((key) => currentParams.hasOwnProperty(key))) {
                let newPlaceData = await withLoading(reverseGeoCode, { placeId: currentParams.plId })
                if (newPlaceData) {
                    storeAddressInfo(newPlaceData)
                }
            }
        }
    }


    useEffect(() => {
        setUp()
    }, [location.search, placeData])

    useEffect(() => {
        if(loaded){
            const newURL = location.pathname + "?" + encodeGetParams({ ...retrieveGetParams(location), pn: undefined })
            if (location.pathname + location.search !== newURL) {
                history.replace(newURL)
            }
            else {
                setSearchResults(null)
                setUp()
            }
        }
        else{
            setLoaded(true)
        }
    }, [bigEnough])

    if (searchResults !== null) {
        return (
            <React.Fragment>
                <MPSearchBar />
                <MPFilter />
                {
                    searchResults.searchRecords.length === 0?
                    <EmptyMP/>
                    :
                    <div className={bigEnough ? classes.desktop : classes.mobile} style = {{marginTop:ipad && bigEnough?47:0}}>
                    <Grid container style={{ width: "100%", paddingLeft: 36, paddingTop: bigEnough?30:77 }} spacing={5}>
                        {searchResults.searchRecords.map((record, index) => (
                            <OfferingCard
                                gridSize = {gridSize}
                                key={index}
                                record={record}
                                bigEnough = {bigEnough}
                            />))}

                    </Grid>
                    <div className={classes.pageNav}>
                        {Math.ceil(searchResults.totalFound / (bigEnough ? desktopTiles : mobileTiles)) <= 1
                            ?
                            null
                            :
                            <Pagination
                                boundaryCount={1}
                                siblingCount={1}
                                count={Math.ceil(searchResults.totalFound / (bigEnough ? desktopTiles : mobileTiles))}
                                onChange={(event, value) => {
                                    if (value !== getCurrentPage(location)) {
                                        safePush(
                                            location.pathname + "?" + encodeGetParams({ ...retrieveGetParams(location), pn: value }),
                                            history,
                                            location
                                        )
                                    }
                                }}
                                page={getCurrentPage(location)}
                            />
                        }
                    </div>
                </div>
                }
            </React.Fragment>
        )
    }
    else {
        return null
    }
}

export default MarketPlaceList