import {
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  customerAttendeeList,
  encodeGetParams,
  getCustomerFeedback,
  retrieveGetParams,
  withLoading,
} from "../../../../utils";
import { useStyles } from "../../../Dashboard/BuisnessDashboard/AdminDashboard/AdminOptions/Sessions/SessionList";
import EmptyListImage from "../../../EmptyListImage/Empty";
import ProvidedFilter from "../../../Filter/ProvidedFilter";
import LightTooltip from "../../../Form/LightTooltip";
import ListTableNavigation from "../../../TableComponents/ListTableNavigation";

const PAGE_LIMIT = 6;

const useFeebackListStyles = makeStyles((theme) => ({
  accordion: {
    border: `2px solid ${theme.palette.accent1.main}`,
    boxShadow: "none",
    borderRadius: "8px",
    padding: "10px",
  },
  td: { textAlign: "left", verticalAlign: "top", padding: 0 },
  expandIcon: {
    transform: "rotate(-180deg)",
    transition: "transform 0.1s ease",
  },
  notExpandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.1s ease",
  },
  link: {
    textTransform: "none",
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
}));

const FeedbackList = () => {
  const mountedRef = useRef(false);

  const [feedback, setFeedback] = useState(null);
  const [attendeeList, setAttendeeList] = useState([]);
  const [selected, setSelected] = useState(null);
  const classes = useStyles();

  const bigEnough = useMediaQuery("(min-width:750px)");
  const history = useHistory();
  const location = useLocation();
  const queryParams = retrieveGetParams(location);
  const page =
    "pg" in queryParams && Number.parseInt(queryParams.pg) > 0
      ? Number.parseInt(queryParams.pg)
      : 1;

  const attendeeId = "aId" in queryParams ? queryParams.aId : undefined;

  const fetchFeedback = async () => {
    const response = await withLoading(getCustomerFeedback, {
      limit: PAGE_LIMIT,
      attendeeId,
      page,
    });

    if (response?.error === false && mountedRef.current) {
      setFeedback(response.feedbacks);
      setSelected(null);
    }
  };

  const fetchAttendeeList = async () => {
    const response = await withLoading(customerAttendeeList, {});
    if (response?.error === false && mountedRef.current) {
      setAttendeeList(response.attendees);
    }
  };

  const getNextPage = () => {
    if (feedback?.hasNextPage) {
      history.push(
        location.pathname +
          "?" +
          encodeGetParams({ ...queryParams, pg: page + 1 })
      );
    }
  };

  const getPrevPage = () => {
    if (feedback?.hasPrevPage) {
      history.push(
        location.pathname +
          "?" +
          encodeGetParams({ ...queryParams, pg: page - 1 })
      );
    }
  };

  useEffect(() => {
    mountedRef.current = true;
    fetchFeedback();
    return () => {
      mountedRef.current = false;
    };
  }, [attendeeId, page]);

  useEffect(() => {
    mountedRef.current = true;
    fetchAttendeeList();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (feedback === null) {
    return null;
  } else {
    return (
      <Grid
        container
        spacing={2}
        className={bigEnough ? classes.mainWrapper : classes.mobilePadding}
      >
        <Grid item xs={12} style={{ paddingBottom: "20px" }}>
          <div>
            <span className={classes.topHeading}>Sessions</span>
            <ProvidedFilter
              filterFields={[
                {
                  type: "attendee",
                  options: attendeeList.map((attendee) => [
                    `${attendee.firstName} ${attendee.lastName} (${attendee.attendeeNumber})`,
                    attendee.attendeeId,
                  ]),
                },
              ]}
            />
          </div>
        </Grid>

        {feedback.docs.length === 0 ? (
          <EmptyListImage name="Forms" />
        ) : (
          <React.Fragment>
            {feedback.docs.map((feedbackData, i) => (
              <FeedbackCard
                key={`feedback-${i}`}
                bigEnough={bigEnough}
                feedbackData={feedbackData}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
            <Grid item xs={12} className={classes.bottomSpacer} />
            <Grid item xs={12}>
              <ListTableNavigation
                currentPage={page}
                currentLimit={PAGE_LIMIT}
                totalDocs={feedback.totalDocs}
                tableRows={feedback.docs}
                hasNext={feedback.hasNextPage}
                hasPrevious={feedback.hasPrevPage}
                getPrevPage={getPrevPage}
                getNextPage={getNextPage}
              />
            </Grid>
            <Grid item xs={12} className={classes.bottomSpacer} />
          </React.Fragment>
        )}
      </Grid>
    );
  }
};

const FeedbackTopHeader = (props) => {
  const { feedbackData, bigEnough } = props;

  if (bigEnough) {
    return (
      <React.Fragment>
        <Grid container>
          <Grid
            item
            style={{ width: "calc(100% - 110px)", paddingRight: "10px" }}
          >
            <LightTooltip arrow title={feedbackData.feedbackFrom}>
              <Typography noWrap variant="h6">
                From: {feedbackData.feedbackFrom}
              </Typography>
            </LightTooltip>
            <LightTooltip arrow title={feedbackData.feedbackTo}>
              <Typography noWrap variant="h6">
                To: {feedbackData.feedbackTo}
              </Typography>
            </LightTooltip>
          </Grid>
          <Grid item style={{ width: "100px" }}>
            <Typography align="right" style={{ fontSize: "1.125rem" }}>
              {moment(feedbackData.feedbackDate).format("MM/DD/YYYY")}
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Grid container>
          <Grid item>
            <LightTooltip arrow title={feedbackData.feedbackFrom}>
              <Typography noWrap variant="h6">
                From: {feedbackData.feedbackFrom}
              </Typography>
            </LightTooltip>
            <LightTooltip arrow title={feedbackData.feedbackTo}>
              <Typography noWrap variant="h6">
                To: {feedbackData.feedbackTo}
              </Typography>
            </LightTooltip>
            <LightTooltip
              arrow
              title={moment(feedbackData.feedbackDate).format("MM/DD/YYYY")}
            >
              <Typography noWrap style={{ fontSize: "1.125rem" }}>
                {moment(feedbackData.feedbackDate).format("MM/DD/YYYY")}
              </Typography>
            </LightTooltip>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
};

const linkMapping = {
  business: (id) => `/business/${id}`,
  session: (id) => `/account/Schedule/${id}`,
};

const FeedbackMetadata = (props) => {
  const { feedbackData } = props;
  const classes = useFeebackListStyles();
  return (
    <Grid item xs={12}>
      <Typography style={{ fontSize: "1.125rem" }}>Context:</Typography>
      <table style={{ tableLayout: "fixed" }}>
        <tbody>
          {feedbackData.metaInfo.map((object, i) => (
            <tr key={`${feedbackData.feedbackId}-${i}-meta`}>
              <td className={classes.td}>
                <Typography>{object.label}: </Typography>
              </td>
              <td style={{ width: "10px" }}></td>
              <td className={classes.td}>
                <Typography color="textSecondary">
                  {object.link && object.linkType in linkMapping ? (
                    <Link
                      className={classes.link}
                      to={linkMapping[object.linkType](object.linkId)}
                    >
                      {object.value}
                    </Link>
                  ) : (
                    object.value
                  )}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Grid>
  );
};

const FeedbackContent = (props) => {
  const { feedbackData } = props;
  const classes = useFeebackListStyles();
  return (
    <Grid item xs={12}>
      <Typography style={{ fontSize: "1.125rem" }}>Feedback:</Typography>
      <table style={{ tableLayout: "fixed" }}>
        <tbody>
          {feedbackData.feedback.map((object, i) => (
            <tr key={`${feedbackData.feedbackId}-${i}-content`}>
              <td className={classes.td}>
                <Typography>{object.fieldName}: </Typography>
              </td>
              <td style={{ width: "10px" }}></td>
              <td className={classes.td}>
                <Typography color="textSecondary">
                  {object.fieldValue}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Grid>
  );
};

const FeedbackCard = (props) => {
  const { feedbackData, bigEnough, selected, setSelected } = props;
  const classes = useFeebackListStyles();
  return (
    <Grid item xs={12}>
      <Grid container className={classes.accordion}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid
              item
              style={{ width: "calc(100% - 40px)", paddingRight: "10px" }}
            >
              <FeedbackTopHeader
                feedbackData={feedbackData}
                bigEnough={bigEnough}
              />
            </Grid>
            <Grid item style={{ width: "30px" }}>
              <IconButton
                size="small"
                onClick={() => {
                  if (selected === feedbackData.feedbackId) {
                    setSelected(null);
                  } else {
                    setSelected(feedbackData.feedbackId);
                  }
                }}
              >
                <ExpandMoreIcon
                  className={
                    selected === feedbackData.feedbackId
                      ? classes.expandIcon
                      : classes.notExpandIcon
                  }
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Collapse in={selected === feedbackData.feedbackId}>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <FeedbackMetadata
              bigEnough={bigEnough}
              feedbackData={feedbackData}
            />
            <FeedbackContent
              bigEnough={bigEnough}
              feedbackData={feedbackData}
            />
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

const BusinessFeedbackTopHeader = (props) => {
  const { feedbackData, bigEnough, businessId, staff } = props;
  const classes = useFeebackListStyles();
  if (bigEnough) {
    return (
      <React.Fragment>
        <Grid container>
          <Grid
            item
            style={{ width: "calc(100% - 110px)", paddingRight: "10px" }}
          >
            <LightTooltip arrow title={feedbackData.feedbackFrom}>
              <Typography noWrap variant="h6">
                By:{" "}
                {staff ? (
                  feedbackData.staffName
                ) : (
                  <Link
                    to={`/dashboard/${businessId}/Staff/${feedbackData.staffId}`}
                    className={classes.link}
                  >
                    {feedbackData.staffName}
                  </Link>
                )}
              </Typography>
            </LightTooltip>
          </Grid>
          <Grid item style={{ width: "100px" }}>
            <Typography align="right" style={{ fontSize: "1.125rem" }}>
              {moment(feedbackData.feedbackDate).format("MM/DD/YYYY")}
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Grid container>
          <Grid item>
            <LightTooltip arrow title={feedbackData.feedbackFrom}>
              <Typography noWrap variant="h6">
                From: {feedbackData.feedbackFrom}
              </Typography>
            </LightTooltip>
            <LightTooltip arrow title={feedbackData.feedbackTo}>
              <Typography noWrap variant="h6">
                To: {feedbackData.feedbackTo}
              </Typography>
            </LightTooltip>
            <LightTooltip
              arrow
              title={moment(feedbackData.feedbackDate).format("MM/DD/YYYY")}
            >
              <Typography noWrap style={{ fontSize: "1.125rem" }}>
                {moment(feedbackData.feedbackDate).format("MM/DD/YYYY")}
              </Typography>
            </LightTooltip>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
};

const BusinessFeedbackCard = (props) => {
  const { feedbackData, bigEnough, selected, setSelected, businessId, staff } =
    props;
  const classes = useFeebackListStyles();
  return (
    <Grid item xs={12}>
      <Grid container className={classes.accordion}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid
              item
              style={{ width: "calc(100% - 40px)", paddingRight: "10px" }}
            >
              <BusinessFeedbackTopHeader
                feedbackData={feedbackData}
                bigEnough={bigEnough}
                businessId={businessId}
                staff={staff}
              />
            </Grid>
            <Grid item style={{ width: "30px" }}>
              <IconButton
                size="small"
                onClick={() => {
                  if (selected === feedbackData.feedbackId) {
                    setSelected(null);
                  } else {
                    setSelected(feedbackData.feedbackId);
                  }
                }}
              >
                <ExpandMoreIcon
                  className={
                    selected === feedbackData.feedbackId
                      ? classes.expandIcon
                      : classes.notExpandIcon
                  }
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Collapse in={selected === feedbackData.feedbackId}>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <FeedbackContent
              bigEnough={bigEnough}
              feedbackData={feedbackData}
            />
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export const BusinessFeedbackList = (props) => {
  const { bigEnough, feedback, businessId, staff } = props;
  const [selected, setSelected] = useState(null);
  return (
    <Grid container spacing={2}>
      {feedback.map((feedbackData, i) => (
        <BusinessFeedbackCard
          key={`feedback-${i}`}
          bigEnough={bigEnough}
          feedbackData={feedbackData}
          selected={selected}
          setSelected={setSelected}
          businessId={businessId}
          staff={staff}
        />
      ))}
    </Grid>
  );
};

export default FeedbackList;
