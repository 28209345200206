import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { withLoading, listAgreements, agreementStatus, deleteAgreement } from "../../../../../../utils";
import { Grid, Button, Menu, MenuItem, Typography, useMediaQuery } from "@material-ui/core";
import GeneralDialog from "../../../../../GeneralDialog/GeneralDialog";
import { CEllipsisIcon } from "../../../../../Icons/Icons";
import StatusTag from "./../../../../../TagComponents/StatusTag";
import CDisplayArea from "../../../../../Form/CDisplayArea";
import ManageAgreementForm from "./ManageAgreement";
import LightTooltip from "../../../../../Form/LightTooltip";
const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    topBlock: {
        paddingBottom: "15px",
    },
    recordRow: {
        paddingLeft: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRoght: "5px",
        width: "550px"
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    rightElem: {
        float: "right",
    },
    rightElemPad: {
        float: "right",
        marginRight: "10px",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeadingOne: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    topHeadingTwo: {
        fontSize: "28px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    topHeadingThree: {
        backgroundColor: "#eb5757",
        opacity: "50%",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    topHeadingFour: {
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "2px",
        marginLeft: "10px",
        position: "absolute",
        marginTop: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "14px",
    },
    buttonBasic: {
        backgroundColor: "#ffffff",
        color: "#1a1f36",
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    contentColumnFirst: {
        paddingRight: "20px",
        boxShadow: "inset -1px 0 #e3e8ee",
        display: "inline-block",
    },
    contentColumnMiddle: {
        boxShadow: "inset -1px 0 #e3e8ee",
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "inline-block",
    },
    contentColumnLast: {
        paddingLeft: "20px",
        display: "inline-block",
    },
    labelText: {
        fontSize: "14px",
        color: "#697386",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText2: {
        fontSize: "14px",
        color: "#3c4257",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextNeg: {
        fontSize: "14px",
        color: "#009211",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBG: {
        fontSize: "16px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextBold: {
        fontSize: "14px",
        color: "#1a1f36",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextPad: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingLeft: "10px",
    },
    valueTextLink: {
        fontSize: "14px",
        color: "#3d5af1",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    errorText: {
        fontSize: "14px",
        color: "#ff0000",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    blockLabel: {
        paddingTop: "10px",
        paddingBottom: "5px",
    },
    blockText: {
        paddingLeft: "10px",
    },
    subHeading: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    fieldRows: {
        paddingTop: "8px"
    },
    fieldColumnOne: {
        width: "80px",
        display: "inline-block",
    },
    fieldColumnTwo: {
        display: "inline-block",
    },
    sdCustomCellOne: {
        border: "none",
        width: "10%",
        maxWidth: "200px",
        padding: "10px",
    },
    sdCustomCellTwo: {
        border: "none",
        width: "25%",
        maxWidth: "300px",
        padding: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingThree: {
        fontSize: "20px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    sdCustomCellThree: {
        border: "none",
        width: "10%",
        maxWidth: "200px",
        padding: "10px",
    },
    sdCustomCellFour: {
        border: "none",
        padding: "10px",
    },
    headerPadding: {
        padding: "10px",
        paddingLeft: "0px"
    },
    bottomSpacer: {
        height: "50px",
    },
    topMarginBlock: {
        marginTop: "10px",
    },
    loaderWrapper: {
        width: "100%",
        height: "calc(100vh - 64px)"
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    },
    media: {
        height: 500,
        objectFit: "cover"
    }
}));

const Agreement = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const bigEnough = useMediaQuery('(min-width: 750px)');
    const classes = useStyles();
    const [_, updateState] = useState(0);
    const [agreementDetails, setAgreementDetails] = useState(null)
    const businessId = useSelector((state) => {
        return state.buisnessDetails.businessId;
    });
    const [eOpen, setEOpen] = useState(false);
    const [aDOpen, setADOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);
    const { agreementIdURL } = useParams();

    useEffect(async () => {
        let data = await withLoading(listAgreements, {
            businessId: businessId,
            agreementId: agreementIdURL,
        }, true)
        if (Array.isArray(data) && data.length === 1) {
            setAgreementDetails(data[0])
        }
    }, [_]);

    if (agreementDetails !== null) {
        return (
            <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                <React.Fragment>
                    <Menu
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        keepMounted

                        open={Boolean(anchorEl)}
                        onClose={() => { setAnchorEl(false) }}
                    >
                        <MenuItem onClick={() => { setEOpen(true); setAnchorEl(false) }}>
                            <Typography >
                                Edit Agreement
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={() => { setADOpen(true); setAnchorEl(false) }}>
                            <Typography >
                                {agreementDetails.isActive ? "Deactivate Agreement" : "Activate Agreement"}
                            </Typography>
                        </MenuItem>
                        {!agreementDetails.isActive ?
                            <MenuItem onClick={() => { setDeleteOpen(true); setAnchorEl(false) }}>
                                <Typography >
                                    Delete Agreement
                                </Typography>
                            </MenuItem> : null}
                    </Menu>
                    <ManageAgreementForm edit agreementDetails={agreementDetails} open={eOpen} setOpen={setEOpen} refresh={() => { updateState(_ => !_) }} />
                    <GeneralDialog
                        open={aDOpen}
                        close={() => { setADOpen(false) }}
                        title={agreementDetails.isActive ? "Deactivate Argeement" : "Activate Agreement"}
                        text={agreementDetails.isActive ? "Are you sure you want to deactivate this agreement?" : "Are you sure you want to activate this agreement?"}
                        action={async () => {
                            let data = await withLoading(agreementStatus,
                                {
                                    businessId: businessId,
                                    agreementId: agreementDetails.agreementId,
                                    isActive: !agreementDetails.isActive
                                })
                            if (data && !data.error) {
                                setADOpen(false)
                                updateState(_ => !_)
                            }
                        }}
                    />
                    <GeneralDialog
                        open={deleteOpen}
                        close={() => { setDeleteOpen(false) }}
                        title="Delete Agreement"
                        text="Are you sure you want to delete this agreement parmanently."
                        action={async () => {
                            const success = await withLoading(deleteAgreement, {
                                businessId: businessId,
                                agreementId: agreementDetails.agreementId
                            })
                            if (success && success.error === false) {
                                history.replace(`/dashboard/${businessId}/Agreements`);
                            }

                        }}
                    />
                </React.Fragment>
                <Grid item xs={12}>
                    <div className={classes.topBlock}>
                        <span className={classes.topHeading}>AGREEMENT</span>
                        <Button onClick={(event) => { setAnchorEl(event.target); }} className={[classes.buttonBasic, classes.rightElem].join(" ")}><CEllipsisIcon /></Button>
                    </div>
                </Grid>
                <Grid item style={(bigEnough ? { maxWidth: "50%" } : { width: "100%" })}>
                    <div className={classes.headerPadding}>
                        <LightTooltip title={agreementDetails.name} arrow>
                            <Typography className={(bigEnough ? classes.topHeadingOne : classes.mobileTopHeadingOne)} noWrap>
                                {agreementDetails.name}
                            </Typography>
                        </LightTooltip>
                    </div>
                </Grid>
                {(bigEnough ?
                    <Grid item>
                        <div className={classes.headerPadding} style={{ paddingLeft: "20px" }}>
                            <span className={classes.topHeadingFour}>
                                <StatusTag wrapperName={agreementDetails.isActive ? "greenWrapper" : "redWrapper"} value={agreementDetails.isActive ? "Active" : "Inactive"} />
                            </span>
                        </div>
                    </Grid>
                    :
                    <Grid item>
                        <div style={{ paddingLeft: "10px" }}>
                            <StatusTag wrapperName={agreementDetails.isActive ? "greenWrapper" : "redWrapper"} value={agreementDetails.isActive ? "Active" : "Inactive"} />
                        </div>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <div className={classes.contentRow}>
                        <div className={classes.contentColumnFirst}>
                            <div
                                className={classes.blockLabel}
                            >
                                <Typography className={classes.labelText}>Type</Typography>
                            </div>
                            <div
                                className={classes.blockText}
                            >
                                <Typography className={classes.valueText} noWrap>
                                    {agreementDetails.type.charAt(0).toUpperCase() + agreementDetails.type.slice(1)}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.contentColumnLast}>
                            <div
                                className={classes.blockLabel}
                            >
                                <Typography className={classes.labelText}>Used In</Typography>
                            </div>
                            <div
                                className={classes.blockText}
                            >
                                <Typography className={classes.valueText} noWrap>
                                    {agreementDetails.optionsUsing === 1 ? `${agreementDetails.optionsUsing} option` : `${agreementDetails.optionsUsing} options`}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.headingWrapper}>
                        <span className={bigEnough ? classes.subHeading : classes.mobileTopHeadingTwo}>Document</span>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ paddingTop: 10 }}>
                        <CDisplayArea
                            value={agreementDetails.document}
                            initialMaxHeight={"calc(100vh - 400px)"}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className={classes.bottomSpacer}></div>
                </Grid>
            </Grid >
        );
    } else {
        return (null);
    }
};

export default Agreement;
