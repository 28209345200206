import React from 'react'
import { Grid, Typography } from '@material-ui/core'
const TwoColumn = (props) => {
    const column1 = (Array.isArray(props.first) ? props.first : [])
    const column2 = (Array.isArray(props.second) ? props.second : [])
    const extProps = props.extProps
    const firstProps = props.firstProps
    const secondProps = props.secondProps
    const name = props.name
    return (
        <Grid container spacing={3} {...extProps}>
            <Grid item {...props.firstItemProps}>
                <Grid container direction="column" {...firstProps} >
                    {column1.map((element, index) => {
                        if (element) {
                            return (
                                <Grid item xs={12} key={`${name}-left-${index}`}>
                                    {element}
                                </Grid>
                            )
                        }
                        else {
                            return null
                        }
                    })}
                </Grid>
            </Grid>
            <Grid item {...props.secondItemProps}>
                <Grid container direction="column" {...secondProps}>
                    {column2.map((element, index) => {
                        if (element) {
                            return (
                                <Grid item xs={12} key={`${name}-right-${index}`}>
                                    {element}
                                </Grid>
                            )
                        }
                        else{
                            return null
                        }
                    })}
                </Grid>

            </Grid>
        </Grid>
    )
}

export default TwoColumn