import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import MarketPlaceList from './MarketPlaceList'
import OfferingDetails from './OfferingDetails'
const MarketPlaceHandler = (props) => {
    return (
        <React.Fragment>
            <Switch>
                <Route exact path='/Market-Place' render={() => <MarketPlaceList />} />
                <Route exact path = '/Market-Place/:catalogID/:offeringIdURL' render = {()=><OfferingDetails/>}/>
                <Route exact path = '/Market-Place/:offeringIdURL' render = {()=><OfferingDetails/>}/>
                <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
            </Switch>
        </React.Fragment>
    )
}

export default MarketPlaceHandler