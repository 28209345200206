import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import React, { useState } from "react";
const useStyles = makeStyles((theme) => ({
  empty: {
    color: theme.palette.textColor3.main,
  },
  comp: {
    width: "100%",
    paddingTop: 21,
  },
  formLabel: {
    fontSize: 14,
    color: theme.palette.textColor2.main,
  },
  fieldText: {
    fontSize: 14,
    color: theme.palette.primaryText.main,
    padding: "10px",
  },
  helperText: {
    marginRight: 14,
    marginLeft: 14,
  },
}));

const CCheckBox = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(
    props.initialvalue === true ? true : false
  );
  return (
    <div className={classes.comp}>
      <FormControlLabel
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon className={classes.empty} />}
            color="primary"
            checked={checked}
            onClick={(event) => {
              props.onchange(event);
              setChecked(!checked);
            }}
            name={props.name}
            {...props.moreProps}
          />
        }
        label={
          <Typography className={classes.formLabel}>{props.label}</Typography>
        }
      />
      {typeof props.errortext == "string" && props.errortext !== "" ? (
        <FormHelperText className={classes.helperText} error={true}>
          {props.errortext}
        </FormHelperText>
      ) : null}
    </div>
  );
};

export default CCheckBox;
