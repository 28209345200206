import React from 'react';
import Container from '@material-ui/core/Container';
import { login, dashboardList } from '../../../utils';
import { useHistory, Link, Router } from 'react-router-dom';
import validEmail from '../../../FormValidation/validEmail';
import notEmpty from '../../../FormValidation/notEmpty';
import Form from '../../Form/Form'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "500px",
    minHeight: "calc(100vh - 64px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

}))



function SignIn(props) {
  const dispatch = useDispatch()
  const loginPage = useSelector(state=>state.loginPage)
  const classes = useStyles()
  const history = useHistory();
  const redirect = async (success) => {
    if (success) {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('urlTo')) {
        history.replace(urlParams.get('urlTo'));
      }
      else if(loginPage) {
        dispatch({
          type:"UPDATE",
          paylaod:{
            loginPage:undefined
          }
        })
        history.replace('/')
      }
      else{
        history.goBack()
      }
    }
  }

  return (
    <div style={{minWidth : "100vw", minHeight : "calc(100vh - 64px)", backgroundColor : "#F7FAFC"}}>
    <Container component="main" className = {classes.container}>
      <div style = {{border: "1px solid #e9eced", padding: "20px", backgroundColor : "#FFFFFF"}}>
      <Form
        titleCenter
        title="Login"
        submitText="Login"
        action={login}
        formStyles={{
          padding: 14,
          
        }}
        postAction={props.oB ? props.sB : redirect}
        inputFields={[
          {
            type: "TextField",
            initialvalue: "",
            validator: validEmail,
            label: "Email",
            name: "email",
          },
          {
            type: "Password",
            initialvalue: "",
            validator: notEmpty,
            label: "Password",
            name: "password",
          },
        ]}
      />
        {props.cOForm ? <Grid container>
          <Grid item xs={6}>
            <Typography onClick={props.setSU} style={{ cursor: "pointer", textDecorationLine: "underline", color: "#551A8B" }} align="left">
              Don't have an account?<br />Sign up here
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography onClick={props.setFP} style={{ cursor: "pointer", textDecorationLine: "underline", color: "#551A8B" }} align="right">
              Forgot password
            </Typography>
          </Grid>
        </Grid>
          : <Grid container>
            <Grid item xs={6}>
              <Link to="/sign-up">
                <Typography align="left">
                  Don't have an account?<br />Sign up here
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Link to="/forgot-password">
                <Typography align="right">
                  Forgot password
                </Typography>
              </Link>
            </Grid>
          </Grid>}
      </div>
    </Container>
    </div>
  );
}

export default SignIn;