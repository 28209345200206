import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Form from '../../../../../Form/Form'
import CModal from '../../../../../Form/CModal'
import { Container } from '@material-ui/core';
import { makeStyles} from '@material-ui/core';
import notEmpty from '../../../../../../FormValidation/notEmpty';
import noWhiteSpace from '../../../../../../FormValidation/noWhiteSpace';
import { createAgreement, updateAgreement} from '../../../../../../utils';
import maxTextField from '../../../../../../FormValidation/maxTextField';
import maxTextArea from '../../../../../../FormValidation/maxTextArea';

const useStyles = makeStyles((theme)=>({
    formContainer:{
        border: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: "20px",
        maxWidth: "600px",
        width:"100%",
        maxHeight: "80vh",
        overflowY: "auto"
    }
}))


const ManageAgreementForm = (props) => {
    const businessId = useSelector((state) => { return (state.buisnessDetails.businessId) });
    const classes = useStyles()
    const history = useHistory();
    const location = useLocation();



    const redirect = (success) => {
        if (success) {
            history.push(`${props.basePath}/${success.agreementId}`);
        }
    }

    const refresh = (success) => {
        if (success) {
            props.setOpen(false)
            props.refresh()
        }
    }
    return (
        <CModal open={props.open} setOpen={props.setOpen}>
            <Container component="main"  className = {classes.formContainer}>
                <Form
                    moreData = {{businessId:businessId, agreementId: (props.edit?props.agreementDetails.agreementId:null)}}
                    title={`${(props.edit?"Edit":"Add")} Agreement`}
                    cancelEnabled
                    cancelAction = {()=>{props.setOpen(false)}}
                    submitText={`${(props.edit?"Save":"Add")}`}
                    action={(props.edit?updateAgreement:createAgreement)}
                    postAction = {(props.edit?refresh:redirect)}
                    inputFields={[
                        {
                            type:"TextField",
                            name: "name",
                            label: "Agreement Name",
                            validator: [notEmpty,noWhiteSpace, maxTextField],
                            initialvalue: (props.edit?props.agreementDetails.name:null)
                        },

                        {
                            type:"SingleSelect",
                            name: "type",
                            label: "Document Type",
                            validator: [(value)=>(!value?"Choose a type":null)],
                            choices:[
                                ["Agreement", "agreement"],
                                ["Policy", "policy"],
                                ["Waiver", "waiver"]
                            ],
                            initialvalue: (props.edit?props.agreementDetails.type:null)
                        },
                        {
                            type:"TextArea",
                            name:"document",
                            label: "Document",
                            moreProps:{
                                rowsMin:8,
                                maxRows:12
                            },
                            resizeEnabled: true,
                            validator: [notEmpty,noWhiteSpace, maxTextArea],
                            initialvalue: (props.edit?props.agreementDetails.document:null)
                            
                        }
                    ]}
                />
            </Container>
        </CModal>
    );
};

export default ManageAgreementForm