import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getWebLinkList, withLoading, retrieveGetParams } from "../../../../../../utils";
import { Grid, useMediaQuery, Button, Typography } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import ListTable from "./../../../../../TableComponents/ListTable";
import ListCard from "../../../../../MobileCards/ListCard";
import EmptyListImage from "../../../../../EmptyListImage/Empty";
import { CCopyIcon } from "../../../../../CopyIcon/Copyicon";
import PostFilter, { applyFilters } from '../../../../../Filter/PostFilter'

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: "100%",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "15px",
    },
    rightElem: {
        float: "right",
    },
    buttonBasic: {
        fontSize: "14px",
        boxShadow:
            " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "3px",
        paddingBottom: "3px",
        textTransform: "none",
    },
    labelText: {
        fontSize: "14px",
        color: "#697386",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    valueTextLink: {
        fontSize: "14px",
        color: "#0079fc",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    headingWrapper: {
        paddingBottom: "15px",
        boxShadow: "inset 0 -1px #e3e8ee",
    },
    mobilePadding: {
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    mobileTopHeadingOne: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingTwo: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    mobileTopHeadingThree: {
        fontSize: "20px",
        fontWeight: "light",
        color: "#1a1f36",
    },
    topHeading: {
        fontSize: "13px",
        color: "#1a1f36",
    },
    topHeading: {
        fontSize: "28px",
        fontWeight: "bold",
        color: "#1a1f36",
    },
    contentRow: {
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    bottomSpacer: {
        height: "50px",
    },
    loaderMain: {
        position: "absolute",
        top: "50%",
        left: "50%"
    }
}));

const keyObjects = [
    {
        key: 'serviceName',
        label: 'Service',
        keyLabel: 'serviceName'
    },
    {
        key: 'siteName',
        label: 'Site',
        keyLabel: 'siteName'
    },
    {
        key: 'virtual',
        label: 'Mode',
        keyLabel: 'virtual'
    },
    {
        key: 'offeringtype',
        label: 'Offering Type',
        keyLabel: 'offeringType'
    },
]

const WebLinksList = () => {
    const location = useLocation()
    const queryParams = retrieveGetParams(location)
    const classes = useStyles();
    const businessId = useSelector((state) => { return state.buisnessDetails.businessId; })
    const staffHeaders = [
        {
            name: "Service Name",
            columnKey: "serviceName",
            columnWidth: "20%",
            type: "Typography",
            tooltip: true
        },
        {
            name: "Site Name",
            columnKey: "siteName",
            columnWidth: "20%",
            type: "Typography",
            tooltip: true
        },
        {
            name: "Mode",
            columnKey: "virtual",
            columnWidth: "75px",
            type: "Typography",
            tooltip: true
        },
        {
            name: "Offering",
            columnKey: "offeringType",
            columnWidth: "50px",
            type: "Typography",
            tooltip: true
        },
        {
            name: "Link",
            columnKey: "url",
            columnWidth: "calc(100%)",
            type: "Typography",
            tooltip: true
        },
        {
            name: "",
            columnKey: "urlLink",
            columnWidth: "50px",
        }

    ];

    const [linksList, setLinksList] = useState(null);
    const [bookingUrl, setBookingUrl] = useState(null);
    const [onboardUrl, setOnboardUrl] = useState(null);
    const bigEnough = useMediaQuery("(min-width:750px)")
    const processLinksData = (linksData) => {
        return linksData.filter(s => s.isActive).map((link) => {
            let {
                siteName,
                serviceName,
                url,
                offeringType,
                isVirtual,
                isActive
            } = link;
            let name = isVirtual ? "Virtual" : siteName;
            let urlLink = <CCopyIcon url={url} />
            let virtual = null;
            if (isVirtual) {
                virtual = "Virtual"
            } else {
                virtual = "In-Person";
            }

            return {
                siteName,
                name,
                offeringType,
                serviceName,
                virtual,
                isVirtual,
                isActive,
                url,
                urlLink,
            };
        });


    };

    const getLinksDetails = async () => {
        let staffsPayload = await getWebLinkList({
            businessId: businessId,
        });
        if (Array.isArray(staffsPayload.directBookingURL)) {
            setLinksList(processLinksData(staffsPayload.directBookingURL));
            setBookingUrl(staffsPayload.businessPageURL);
            setOnboardUrl(staffsPayload.customerOnboardURL);
        };
    };
    useEffect(async () => {
        await withLoading(getLinksDetails, {}, true)
    }, []);

    const ViewChanger = () => {
        if (bigEnough) {
            return (
                <React.Fragment>
                    <Grid item xs={12}>
                        <div className={classes.contentRow}>
                            <ListTable
                                identifier={"admin_orders"}
                                tableHeaders={staffHeaders}
                                tableRows={applyFilters(linksList, queryParams, keyObjects)}
                                rowClick={null}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.bottomSpacer}></div>
                    </Grid>
                </React.Fragment>
            )
        }
        else {
            return (
                <Grid item xs={12} style={{ paddingTop: "10px" }}>
                    {applyFilters(linksList, queryParams, keyObjects).map((link) => {
                        return (
                            <ListCard
                                action={null}
                                cardName={link.serviceName}
                                statusReplace={<div style={{ whiteSpace: "noWrap", width: "250px", overflow: "hidden", textOverflow: "ellipsis" }}>{link.url}</div>}
                                other={link.urlLink} />
                        )
                    })}
                </Grid>
            )
        }
    }

    if (linksList !== null) {
        return (
            <React.Fragment>
                <Grid container className={bigEnough ? classes.mainWrapper : classes.mobilePadding}>
                    <Grid item xs={12}>
                        <div className={classes.headerPadding}>
                            <span className={bigEnough ? classes.topHeading : classes.mobileTopHeadingOne}>Weblinks</span>
                        </div>
                    </Grid>
                    <Grid container spacing={1} item xs={12} style={{ paddingTop: "10px", width: "100%" }}>
                        <Grid item>
                            <span className={classes.labelText}>
                                Direct Booking Url:
                            </span>

                        </Grid>
                        <Grid item style={{ maxWidth: "55%" }}>
                            <Typography noWrap className={classes.valueText}>
                                {bookingUrl}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CCopyIcon url={bookingUrl} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} item xs={12} direction="row" style={{ width: "100%" }}>
                        <Grid item>
                            <span className={classes.labelText}>
                                Customer Onboarding Url:
                            </span>
                        </Grid>
                        <Grid item style={{ maxWidth: "50%" }}>
                            <Typography noWrap className={classes.valueText}>
                                {onboardUrl}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CCopyIcon url={onboardUrl} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ paddingTop: "15px" }}>
                            <span className={classes.topHeading} style={{ fontSize: "20px" }}>Direct Booking Links</span>
                            <PostFilter allEntries={linksList} keyObjects={keyObjects} />
                        </div>

                    </Grid>
                    {(Array.isArray(linksList) && linksList.length > 0 ? <ViewChanger /> : <EmptyListImage name="Weblinks" />)}
                </Grid>
            </React.Fragment>
        );
    }
    else {
        return (null)
    }


};

export default WebLinksList;

