import React, { useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from "@material-ui/core/FormHelperText";
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
const useStyles = makeStyles((theme) => ({
    comp:
    {
        width: "100%"
    },
    formLabel: {
        fontSize: 14,
        color: "#3C4257"
    },
    fieldText: {
        fontSize: 14,
        color: "#1A1F36",
        padding: "10px"
    },
    helperText: {
        marginRight: 14,
        marginLeft: 14
    }
}));

const CSwitch = (props) => {
    const classes = useStyles();
    const [on, setOn] = useState(props.initialvalue);
    return (
        <div>
            <FormControlLabel
                control={
                    <Switch
                        color = "primary"
                        checked={on}
                        onChange={(event)=>{
                            setOn(!on)
                            props.onchange(event)
                        }}
                        name={props.name}
                    />
                }
                label={<Typography className={classes.fieldText}>{props.label}</Typography>} />

            <FormHelperText
                className={classes.helperText}
                error={(typeof props.errortext == "string" && props.errortext !== "" ? true : false)}
            >
                {props.errortext}
            </FormHelperText>
        </div>
    )
}

export default CSwitch;