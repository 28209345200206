import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from "@material-ui/lab"
import { makeStyles, Typography } from "@material-ui/core"
import moment from "moment"

const useStyles = makeStyles((theme) => ({
    valueText: {
        fontSize: "14px",
        color: "#1a1f36",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    labelText: {
        fontSize: "14px",
        color: "#697386",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}))

const OrderChangeHistory = (props) => {
    const { orderDetails, admin } = props
    const classes = useStyles()

    return (
        <Timeline style={{ width: 0, padding: 0 }}>
            {orderDetails.orderChangeHistory.sort((histA, histB) => {
                const dateA = new Date(histA.changedAt)
                const dateB = new Date(histB.changedAt)
                if (dateA < dateB) return 1;
                if (dateA > dateB) return -1;
                return 0;
            }).map((hist, index) => {
                return (
                    <TimelineItem key={`hist-${index}`}>
                        <TimelineSeparator>
                            <TimelineDot />
                            {index === orderDetails.orderChangeHistory.length - 1 ?
                                null
                                :
                                <TimelineConnector />
                            }
                        </TimelineSeparator>
                        <TimelineContent>
                            {admin ?
                                <Typography className={classes.valueText}>
                                    {`Changed By: ${hist.changedByStaffFirstName} ${hist.changedByStaffLastName}`}
                                </Typography>
                                : null}
                            <Typography className={classes.labelText}>
                                {`New Pricing Option: ${hist.newOptionName}`}
                            </Typography>
                            <Typography className={classes.labelText}>
                                {`Old Pricing Option: ${hist.oldOptionName}`}
                            </Typography>
                            <Typography className={classes.labelText}>
                                At: {moment(hist.changedAt).format("MMM, Do, YYYY : h:mm A ")}
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                )
            })}
        </Timeline>
    )
}

export default OrderChangeHistory