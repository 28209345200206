import React from 'react'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    cancel:
    {
        fontSize: 14,
        color: theme.palette.primaryText.main,
        textTransform: "none"
    },

    action: {
        fontSize: 14,
        textTransform: "none",
    },


}));


const justify = (input) => {
    if (input === "left") {
        return ("flex-start")
    }
    else {
        return ("flex-end")
    }
}






const CButtonPair = (props) => {
    const classes = useStyles();
    const loading = useSelector((state) => { return (state.loading) });
    const error = (props.errors || []).some((error, index) => {
        if (props.enabledArray[index] === false) {
            return (false)
        }
        else {
            if (typeof error.errortext == "string") {
                return error.errortext !== ""
            }
            else if (Array.isArray(error.errortext)) {
                return (error.errortext.some(
                    err => typeof err == "string" && err !== ""
                ))
            }
            else {
                return (false)
            }
        }

    })



    return (
        <Grid item xs={12}>
            <Grid container spacing={2} justifyContent={justify(props.buttonPlacement)}>
                <Grid item>
                    {(props.cancelEnabled ?
                        <Button
                            variant="outlined"
                            onClick={props.cancelAction}
                        >
                            <Typography className={classes.cancel}>
                                {(props.cancelText ? props.cancelText : "Cancel")}
                            </Typography>
                        </Button>
                        : null)}

                </Grid>
                {(props.clearEnabled ?
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={props.clearAction}
                        >
                            <Typography className={classes.cancel}>
                                {props.clearText ?? "Clear"}
                            </Typography>
                        </Button>
                    </Grid>
                    : null)}
                <Grid item>
                    <Button
                        onClick={props.action ? props.action : undefined}
                        color="primary"
                        variant="contained"
                        type={props.notSubmit ? undefined : "submit"}
                        disabled={error || loading || props.disableSubmit}
                    >
                        <Typography className={classes.action}>
                            {(props.submitText ? props.submitText : "Submit")}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default CButtonPair



