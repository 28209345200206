import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { encodeGetParams } from '../../../utils'
import ContactSales from '../ContactSales/ContactSales'
import Pricing from '../Pricing/Pricing'
import RBForm from './RBForm'
import RBPrettyPage from './RBPrettyPage'
const RBHandler = () => {
  const basePath = '/for-business'
  const loggedIn = useSelector((state) => { return (state.token != null) });
  const requireLogin = (component) => {
    return ((loggedIn ?
      <React.Fragment>
        {component}
      </React.Fragment>
      :
      <Redirect to={'/login?' + encodeGetParams({ urlTo: window.location.pathname + window.location.search })} />
    ));
  };

  return (
    <Switch>
      <Route exact path={basePath} render={() => (<RBPrettyPage basePath />)} />
      <Route path={`${basePath}/create`} exact render={() => (requireLogin(<RBForm />))} />
      <Route path={`${basePath}/pricing`} exact render={() => (<Pricing />)} />
      <Route path={`${basePath}/contact-sales`} exact render={() => (<ContactSales />)} />
      <Route path='*' render={() => { return (<Redirect to='/404' />) }} />
    </Switch>
  )
}

export default RBHandler