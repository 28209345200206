import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { customerOrders } from "../../../../utils";
import Grid from "@material-ui/core/Grid";
import CurrencyPreProcessor from "../../../CurrencyFormatter/CurrencyFormatter";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import ListTable from "./../../../TableComponents/ListTable";
import ListTableNavigation from "./../../../TableComponents/ListTableNavigation";

import ListCard from "../../../MobileCards/ListCard";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EmptyListImage from "../../../EmptyListImage/Empty";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "15px",
  },
  headingWrapper: {
    paddingBottom: "15px",
    boxShadow: "inset 0 -1px #e3e8ee",
  },
  mobilePadding: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  topHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  mobileTopHeadingOne: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#1a1f36",
  },
  contentRow: {
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  bottomSpacer: {
    height: "50px",
  },
  stickyHeader: {
    position: "sticky",
    top: 64,
    paddingLeft:"10px",
    paddingRight:"10px",
    paddingTop:"10px",
    paddingBottom:"10px",
    backgroundColor: "#ffffff",
    boxShadow: "inset 0 -1px #e3e8ee"
  },
  loaderMain : {
    position : "absolute",
    top : "50%",
    left: "50%"
  }
}));
const orderHeaders = [
  {
    name: "order #",
    columnKey: "orderNumber",
    columnWidth: "75px",
    type:"Normal"
  },
  {
    name: "booking date",
    columnKey: "bookingDateF",
    columnWidth: "100px",
    tooltip:true
  },
  {
    name: "service",
    columnKey: "serviceName",
    columnWidth: "50%",
    tooltip:true
  },
  {
    name: "pricing option",
    columnKey: "optionName",
    columnWidth: "50%",
    tooltip:true
  },
  {
    name: "amount",
    columnKey: "orderAmountF",
    columnWidth: "75px",
  },
];


const OrderList = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width: 750px)');

  
  const [orderList, setOrderList] = useState([]);
  const [initialState, setInitialState] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(13);
  const [totalPages, setTotalPages] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  const processOrderData = (orderData) => {
    return orderData.map((order) => {
      let {
        orderId,
        orderNumber,
        serviceName,
        bookingDate,
        optionName,
        orderAmount,
        currencyInfo,
      } = order;

      let id = orderId;
      let orderAmountF = (
        <CurrencyPreProcessor
          amount={orderAmount}
          currencyBreak={currencyInfo.currencyBreak}
          roundingFactor={currencyInfo.roundingFactor}
          currencySymbol={currencyInfo.currencySymbol}
        />
      );
      let bookingDateF = (
        <Moment format="MMMM, DD YYYY">{bookingDate}</Moment>
      );

      return {
        id,
        orderNumber,
        bookingDateF,
        serviceName,
        optionName,
        orderAmountF,
      };
    });
  };

  const getOrdersDetails = async () => {
    let ordersPayload = await customerOrders({
      limit: currentLimit,
      page: currentPage,
    });
    if (ordersPayload && ordersPayload.orders) {
      setOrderList(processOrderData(ordersPayload.orders.docs));
      setTotalPages(ordersPayload.orders.totalPages);
      setTotalDocs(ordersPayload.orders.totalDocs);
      setHasNext(ordersPayload.orders.hasNextPage);
      setHasPrevious(ordersPayload.orders.hasPrevPage);
      setInitialState(true);
    }
  };

  const getNextPage = () => {
    if (hasNext) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPrevPage = () => {
    if (hasPrevious) {
      setCurrentPage(currentPage - 1);
    }
  };

  const navigateToOrder = (orderId) => {
    history.push(`/account/Orders/${orderId}`);
  };

  const ViewChanger = () => {
    if(matches) {
      return(
        <ListTable
            identifier={"admin_orders"}
            tableHeaders={orderHeaders}
            tableRows={orderList}
            rowClick={navigateToOrder}
        />
      );
    } else {
      return(
      orderList.map((mobileOrder) => {
      return(
        <ListCard 
          action = {() => {history.push(`/account/Orders/${mobileOrder.id}`);}} 
          cardName={mobileOrder.bookingDateF} 
          statusReplace={mobileOrder.orderAmountF} 
          other={`#${mobileOrder.orderNumber}`} 
        />
      )})
      );
    };
  };

  useEffect(async () => {
    dispatch({
      type: "UPDATE",
      payload: {
        loading: true,
      },
    });
    await getOrdersDetails();
    dispatch({
      type: "UPDATE",
      payload: {
        loading: false,
      },
    });
  }, [currentPage, currentLimit]);

  if(initialState === null) {
    return(null);
  } else {
  return (
    <Grid container className={matches ? classes.mainWrapper : classes.mobilePadding}>
      <Grid item xs={12} className={matches ? null : classes.stickyHeader}>
        <div className={classes.headerPadding}>
          <span className={matches ? classes.topHeading : classes.mobileTopHeadingOne}>Orders</span>
        </div>
      </Grid>
      {orderList.length > 0 ?
        <Grid item xs={12}>
          <div className={classes.contentRow}>
            <ViewChanger />
          </div>
        </Grid> : <EmptyListImage name = "Orders" />}
      {orderList.length > 0 ? 
        <Grid item xs={12}>
          <ListTableNavigation
            currentPage={currentPage}
            currentLimit={currentLimit}
            totalDocs={totalDocs}
            tableRows={orderList}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            getPrevPage={getPrevPage}
            getNextPage={getNextPage}
          />
        </Grid> : null}
      <Grid item xs={12}>
        <div className={classes.bottomSpacer}></div>
      </Grid>
    </Grid>
  );
  }
};

export default OrderList;
