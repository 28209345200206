import React, { useState } from 'react'
import CTextField from "./CTextField"
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme)=>({
    icon:{
        padding:0,
        color:theme.palette.textColor3
    }
}))

const CPassword = (props) => {
    const [hidden, setHidden] = useState(true);
    const classes = useStyles();
    return (
        <CTextField
            onchange={props.onchange}
            initialvalue={props.initialvalue}
            label={props.label}
            errortext={props.errortext}
            name={props.name}
            moreProps={{
                ...props.moreProps,
                type: (hidden ? "password" : "text"),
                autoComplete:(props.new?'new-password':null),
                InputProps: {
                    endAdornment:
                        <InputAdornment position="end" >
                            <IconButton
                                className = {classes.icon}
                                aria-label="toggle password visibility"
                                onClick={() => { setHidden(!hidden) }}
                            >
                                {hidden ?<Visibility />:<VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                }
            }}


        />
    );
    ;
}

export default CPassword