import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  blockPadding: {
    paddingBottom: "15px",
  },
  infoText: {
    fontSize: "13px",
    color: "#1a1f36",
  },
  buttonBasic: {
    backgroundColor: "#ffffff",
    color: "#1a1f36",
    fontSize: "14px",
    boxShadow:
      " 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    textTransform: "none",
  },
  rightElem: {
    float: "right",
  },
  rightElemPad: {
    float: "right",
    marginRight: "10px",
  },
}));
const ListTableNavigation = (props) => {
  const classes = useStyles();
  const loading = useSelector(state=>state.loading)
  return (
    <div className={classes.blockPadding}>
      {props.totalDocs > props.currentLimit ? (
        <span className={classes.infoText}>
          {`Viewing ${props.currentPage * props.currentLimit - props.currentLimit + 1}-${
            props.currentPage * props.currentLimit - props.currentLimit + props.tableRows.length
          } of ${props.totalDocs} results`}
        </span>
      ) : (
        <span className={classes.infoText}>{`Record count: ${props.totalDocs}`}</span>
      )}
      <Button
        disabled={!props.hasNext || loading}
        className={[
          classes.buttonBasic,
          classes.rightElem,
          classes.rightElemPad,
        ].join(' ')}
        onClick={props.getNextPage}
      >
        Next
      </Button>
      <Button
        disabled={!props.hasPrevious || loading}
        className={[
          classes.buttonBasic,
          classes.rightElem,
          classes.rightElemPad,
        ].join(' ')}
        onClick={props.getPrevPage}
      >
        Previous
      </Button>
    </div>
  );
};

export default ListTableNavigation;
